import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import { Course, FullCourseSubject } from '@yandex-int/k-common/typings';
import { Me } from '@yandex-int/k-common/typings/me';
import { getSpecCourses } from '@yandex-int/k-common/utils/get-spec-courses';
import { uniqBy } from 'lodash';
import { getIsTeacher, getMe } from 'platform-components/src/components/user/selectors';
import { SUBJECTS_SLUGS_TYPE } from 'platform-components/src/typings';
import { createSelector } from 'reselect';

import { getHasNoActiveTeams } from 'common.components/user/selectors';
import { getInitialSubject, getSubjectConfigList } from 'store/subject-config/selectors';
import { getCoursesBySubjectUtil, getCurrentYearOrSearchStringCourseId } from 'utils/get-course';
import { getIsCommonCourse } from 'utils/get-is-common-course';
import { courseIdSelector, getClassIdFromProps, getSubjectFromProps } from 'utils/selectors/selectors';

import { CourseLessonCounts, CoursesStoreState, CourseSubject, LessonsCount, ReduxCoursesStore } from './typings';
import { subjectsOrderSort } from './utils';

const coursesSelector = <T extends ReduxCoursesStore>(storeState: T): CoursesStoreState => storeState.courses;

/**
 * gets courses by classId passed as second arg
 * @example getCoursesByClass(storeState, { classId })
 */
export const getCoursesByClass = createSelector(
    [coursesSelector, getClassIdFromProps],
    (state, teamId): Array<Course> => {
        if (teamId === null) {
            return [];
        }
        const classData = teamId && state?.coursesList[teamId];

        if (classData) {
            return classData.list;
        }

        return [];
    }
);

/**
 * gets course by classId and subject passed as second arg
 * @example getCoursesByClassAndSubject(storeState, { classId, subject })
 */
export const getCoursesByClassAndSubject = createSelector(
    [getCoursesByClass, getSubjectFromProps],
    (courses: Array<Course>, subject: SUBJECTS_SLUGS_TYPE): Array<Course> => getCoursesBySubjectUtil(courses, subject)
);

export const getPrevYearCourses = createSelector(
    [getCoursesByClassAndSubject],
    (courses: Array<Course>): Array<Course> => courses.filter((course: Course) => course.closed)
);

/**
 * gets subjects
 * @example getSubjects(storeState)
 */
export const getSubjects = createSelector(
    [getCoursesByClass, getSubjectConfigList, getIsTeacher],
    (courses, subjectConfig, isTeacher): Array<CourseSubject> => {
        const onlyShownCourses: Array<Course> = courses.filter((course) => getIsCommonCourse(course, isTeacher));

        return subjectsOrderSort(
            uniqBy(
                onlyShownCourses.map((course) => ({ subject: course.subject, name: course.name })),
                'subject'
            ),
            subjectConfig
        );
    }
);

/**
 * gets course by classId and courseId passed as second arg
 * @example getCourseByClassAndId(storeState, { classId, courseId })
 */
export const getCourseByClassAndId = createSelector(
    [getCoursesByClass, courseIdSelector],
    (courses: Array<Course>, courseId) => {
        if (!courseId) {
            return undefined;
        }

        return courses.find((course) => course.id === courseId);
    }
);

export const getCoursesLoadingStatus = createSelector(
    [coursesSelector, getClassIdFromProps],
    (state, classId): LOADING_STATUSES => {
        const classData = classId && state.coursesList[classId];

        if (classData) {
            return classData.loadingStatus;
        }

        return LOADING_STATUSES.UNSENT;
    }
);

export const getSelectedSubjectOrDefault = createSelector(
    [getSubjects, getSubjectFromProps, getInitialSubject],
    (subjects, currentSubject: SUBJECTS_SLUGS_TYPE | null, initialSubject): SUBJECTS_SLUGS_TYPE => {
        if (currentSubject) {
            return currentSubject;
        }

        const isValidSubject = subjects.some(({ subject }) => subject === initialSubject);

        return subjects.length && !isValidSubject ? subjects[0].subject : initialSubject;
    }
);

export const getShouldLoadCourses = createSelector(
    [getHasNoActiveTeams, getCoursesLoadingStatus],
    (hasNoTeams, loadingStatus: LOADING_STATUSES): boolean => {
        return !hasNoTeams && loadingStatus === LOADING_STATUSES.UNSENT;
    }
);

export const getDefaultCourseId = createSelector(
    [getMe, getCoursesByClassAndSubject],
    (me: Me | null, courses: Array<Course>): number | null =>
        me ? getCurrentYearOrSearchStringCourseId(me, courses) : null
);

export const getLessonsCounts = createSelector(
    [coursesSelector, courseIdSelector],
    (state: CoursesStoreState, courseId: number): LessonsCount | { [key in keyof LessonsCount]?: never } =>
        state.lessonsCounts[courseId]?.count || {}
);

export const getLessonsCountLoadingStatus = createSelector(
    [coursesSelector, courseIdSelector],
    (state: CoursesStoreState, courseId: number) =>
        state.lessonsCounts[courseId]?.loadingStatus || LOADING_STATUSES.UNSENT
);

export const getAllLessonsCounts = createSelector(
    [coursesSelector, getCoursesByClassAndSubject],
    (state: CoursesStoreState, courses: Array<Course>) => {
        const lessonsCounts = state.lessonsCounts || {};

        return (
            courses &&
            courses.reduce((acc: Record<number, CourseLessonCounts | undefined>, { id }) => {
                if (lessonsCounts[id]) {
                    acc[id] = lessonsCounts[id];
                }
                return acc;
            }, {})
        );
    }
);

export const getIsLessonCountsLoading = createSelector(
    [getAllLessonsCounts],
    (lessonsCounts: Record<number, CourseLessonCounts | undefined>) => {
        const lessonsCountsList = Object.values(lessonsCounts);
        const isEmpty = lessonsCountsList.length === 0;

        return !isEmpty && lessonsCountsList.every((item) => item?.loadingStatus === LOADING_STATUSES.LOADING);
    }
);

export const getPrevYearCoursesWithDrafts = createSelector(
    [getPrevYearCourses, getAllLessonsCounts],
    (
        prevYearCourses: Array<Course>,
        allLessonsCounts: Record<number, CourseLessonCounts | undefined>
    ): Array<Course> => {
        return prevYearCourses.filter(({ id }) => allLessonsCounts[id]?.count.unassigned !== 0);
    }
);

export const getPrevYearCoursesWithFinishedLessons = createSelector(
    [getPrevYearCourses, getAllLessonsCounts],
    (
        prevYearCourses: Array<Course>,
        allLessonsCounts: Record<number, CourseLessonCounts | undefined>
    ): Array<Course> => {
        return prevYearCourses.filter(({ id }) => allLessonsCounts[id]?.count.finished !== 0);
    }
);

export const getCoursesSubjectsByTeam = createSelector(
    [coursesSelector, getClassIdFromProps],
    (state, teamId): Array<FullCourseSubject> => {
        if (teamId === null) {
            return [];
        }
        const teamData = teamId && state?.coursesSubjectsList[teamId];

        if (teamData) {
            return teamData.list;
        }

        return [];
    }
);

export const getSpecCourseId = createSelector([getCoursesByClass], (courses) => {
    const specCourse = getSpecCourses(courses)[0];

    return specCourse?.id || null;
});

/** Получить список предметов всех активных курсов */
export const getCoursesSubjectsSlugs = createSelector(coursesSelector, (state) => state.coursesSubjectsSlugs);

/** Есть ли у учителя активный класс с переданным предметов */
export const getIsTeacherHaveSubject = createSelector(
    [getCoursesSubjectsSlugs, getSubjectFromProps],
    (slugs, subject) => slugs.list.includes(subject)
);

/** Есть ли у учителя активный класс с информатикой */
export const getIsInformaticsTeacher = createSelector(
    (store) => getIsTeacherHaveSubject(store, { subject: 'informatics' }),
    (f) => f
);
