import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import { CourseTemplate } from '@yandex-int/k-common/typings';
import { Alarm } from 'education-icons';
import { SimpleCallback, Text } from 'platform-components';
import { getMe } from 'platform-components/src/components/user/selectors';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';
import ym from 'react-yandex-metrika';

import { isMesTeam as isMesTeamUtil } from 'common.components/classes/utils';
import SubjectsForm from 'common.components/subjects-form/subjects-form';
import { getCurrentClass } from 'common.components/user/selectors';
import * as coursesTemplatesSelectors from 'store/courses-templates/selectors';
import { getTeamIdFromPath } from 'utils/path';
import { YM_LOCATION } from 'utils/ym-constants';

import { TeamBuilderConnectExternalButtons } from '../__external-buttons/team-builder__external-buttons';
import { teamBuilderActions } from '../actions';
import * as selectors from '../selectors';

import * as ownSelectors from './selectors';

interface Props {
    onAssign: SimpleCallback;
    onNext: SimpleCallback;
    loadTemplates: SimpleCallback;
    shouldLoadTemplates: boolean;
    createdTeamId?: number | null;
}

export const TeamBuilderAddSubjects = (props: Props) => {
    const { onNext, onAssign, loadTemplates, shouldLoadTemplates, createdTeamId } = props;
    const dispatch = useDispatch();
    const teamId = getTeamIdFromPath() || createdTeamId || null;
    const team = useSelector((store) => getCurrentClass(store, { classId: teamId }));
    const isMesTeam = isMesTeamUtil(team);
    const me = useSelector(getMe);
    const templates = useSelector((store) => ownSelectors.getSubjectsByNewSubject(store, { classId: teamId }));
    const templatesLoadingStatus = useSelector((store) =>
        coursesTemplatesSelectors.getCoursesTemplatesLoadingStatus(store, { classId: teamId })
    );
    const submittingStatus = useSelector(selectors.getSubmittingStatus);
    const subjectsIds = useSelector(selectors.getSelectedTemplatesIds);
    const coursesCount = useSelector((store) => ownSelectors.getCurrentTeamCoursesCount(store, { classId: teamId }));
    const newSubject = useSelector(selectors.getNewSubject);

    const grade = team?.level;
    const letter = team?.name;

    useMount(function loadTemplatesOnMount() {
        if (shouldLoadTemplates) {
            loadTemplates();
        }
    });

    useEffect(
        function setNewSubject() {
            if (templatesLoadingStatus === LOADING_STATUSES.SUCCESS && !shouldLoadTemplates) {
                const ids = (templates || []).filter((ct) => ct.subject === newSubject).map((ct) => ct.id);

                dispatch(teamBuilderActions.setSelectedTemplatesIds({ selectedTemplatesIds: ids }));
            }
        },
        [newSubject, templates, templatesLoadingStatus, shouldLoadTemplates, dispatch]
    );

    const handleSubjectsMetrika = () => {
        const subjectsAdded = (templates || [])
            .filter((template: CourseTemplate) => (subjectsIds || []).includes(template.id))
            .map((template: CourseTemplate) => template.subject);
        const subjectsShown = (templates || []).map((template: CourseTemplate) => template.subject);

        ym('reachGoal', 'teacher_modal_new_subject_add', {
            user_id: me?.id,
            subjects_added: subjectsAdded,
            subjects_shown: subjectsShown,
        });
    };

    const renderWarning = () => {
        if (isMesTeam) {
            return (
                <div className="team-builder__warning">
                    <Alarm />
                    <Text id="teamBuilder.warning.mes" size={Text.SIZE.S} type={Text.TYPE.MARKDOWN} />
                </div>
            );
        }

        return null;
    };

    const handleChangeSubjects = (selectedIds: Array<number>) => {
        dispatch(teamBuilderActions.setSelectedTemplatesIds({ selectedTemplatesIds: selectedIds }));
        onAssign();
    };

    const handleSubmitSubjects = () => {
        onNext();
    };

    return (
        <SubjectsForm
            additionalButton={
                <TeamBuilderConnectExternalButtons
                    shouldShowCodeControl={false}
                    shouldShowConnectMesControl={isMesTeam}
                />
            }
            coursesTemplates={templates || []}
            customMetrikaHandler={handleSubjectsMetrika}
            isLoading={templatesLoadingStatus === LOADING_STATUSES.LOADING}
            isSubmitting={submittingStatus === LOADING_STATUSES.LOADING}
            locationForMetrika={coursesCount > 0 ? YM_LOCATION.ONE_MORE_SUBJECT : YM_LOCATION.FROM_EMPTY_STATE}
            mix="team-builder__subjects"
            onChange={handleChangeSubjects}
            onSubmit={handleSubmitSubjects}
            selectedSubjectIds={subjectsIds || []}
            team={{ number: grade, letter: letter || '' }}
            warning={renderWarning()}
            {...(newSubject
                ? {
                      titleId: 'addSubjectModal.newSubject.title',
                      descriptionId: 'addSubjectModal.newSubject.description',
                      buttonTextId: 'addSubjectModal.newSubject.button',
                      subjectToShow: newSubject,
                  }
                : {})}
        />
    );
};
