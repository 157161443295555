import React, { useEffect, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { Interpreter } from 'xstate';

import { getIsStudentRegistrationLinkAvailableInTeam } from 'common.components/user/selectors';
import { getTeamIdFromPath } from 'utils/path';

import { TeamBuilder, TeamBuilderProps } from '../team-builder';
import { TEAM_BUILDER_INNER_STEPS } from '../team-builder-machine';
import {
    MachineContext,
    MachineEvents,
    TEAM_BUILDER_ENTRY_STEPS,
    TEAM_BUILDER_STEPS,
    TeamBuilderModeContainerInterface,
} from '../typings';

interface Props extends Omit<TeamBuilderProps, 'loadTemplates' | 'shouldLoadTemplates' | 'currentTeamInfo'> {
    machineState: TEAM_BUILDER_STEPS | TEAM_BUILDER_INNER_STEPS;
    send: Interpreter<MachineContext, any, MachineEvents>['send'];
    entryPoint: TEAM_BUILDER_ENTRY_STEPS | null;
    hasSchool: boolean;
    hasLetter: boolean;
    hasTeam: boolean;
}

export const TeamBuilderModeNoTemplatesContainer = React.forwardRef<TeamBuilderModeContainerInterface, Props>(
    (props: Props, ref) => {
        const { machineState, entryPoint, hasTeam, hasSchool, send, ...otherProps } = props;

        const classId = getTeamIdFromPath();
        const isInStudentLinkExperiment = useSelector((store) =>
            getIsStudentRegistrationLinkAvailableInTeam(store, { classId })
        );

        useEffect(
            function updateChangedData() {
                send({
                    type: 'UPDATE',
                    newContext: {
                        hasTemplates: false,
                        hasSchool,
                        hasTeam,
                        hasSubjects: false,
                        hasStudents: false,
                    },
                });
            },
            [hasSchool, hasTeam, send]
        );

        const resetMachine = () => {
            send({
                type: 'RESET',
                newContext: {
                    entryPoint,
                    hasSchool,
                    hasTeam,
                    hasLetter: false,
                    hasSubjects: false,
                    hasStudents: false,
                    hasTemplates: false,
                    grade: null,
                    hasError: false,
                    passedSteps: [],
                    hasCode: false,
                    inStudentLinkExperiment: isInStudentLinkExperiment,
                },
            });
        };

        useImperativeHandle(ref, () => ({
            resetMachine,
        }));

        return (
            <TeamBuilder
                {...otherProps}
                loadTemplates={() => {}}
                machineState={machineState as TEAM_BUILDER_STEPS | TEAM_BUILDER_INNER_STEPS}
                shouldLoadTemplates={false}
            />
        );
    }
);
