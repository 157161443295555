export function getHeight(el: HTMLElement) {
    const value = getComputedStyle(el, null).height;
    return parseFloat(value);
}

export function getOuterHeight(el: HTMLElement) {
    return el.offsetHeight;
}

export function getDocumentMaxHeight() {
    const html = document.documentElement;
    const body = document.body;

    // браузеры могут считать высоту документа по-разному, в частности, не все правильно считают scrollHeight при отсутствии прокрутки
    return Math.max(
        html.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        body.offsetHeight,
        body.clientHeight,
        body.scrollHeight
    );
}

export function getWindowHeight() {
    return window.document.documentElement.clientHeight;
}
