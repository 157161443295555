import cx from 'classnames';
import React from 'react';

import { useIntl } from '../intl/context';
import Link from '../link/link';
import Text from '../text/text';

interface Props {
    linkId: string;
    textId: string;
    mix?: string;
}

export const ExternalLink = ({ textId, linkId, mix }: Props) => {
    const intl = useIntl();

    return (
        <Link
            mix={cx('external-link', mix)}
            target="_blank"
            theme={Link.THEME.PRIMARY}
            to={intl.formatMessage({ id: linkId })}
        >
            <Text id={textId} size={Text.SIZE.L} />
        </Link>
    );
};
