import { withBemMod } from '@bem-react/core';
import { cnButton } from '@yandex-lego/components/Button/desktop';

import './Button_view_contrast.css';

export type WithViewContrastProps = {
    view?: 'contrast';
};

export const withViewContrast = withBemMod<WithViewContrastProps>(cnButton(), {
    view: 'contrast',
});
