import partial from 'lodash/partial';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import KeyboardKey from '../__key/keyboard__key';
import { keyboardKeyPropTypes } from '../__key/keyboard__key.propTypes';
import KeyboardContext from '../keyboard-context';
import { KEYBOARD_TYPES } from '../types';

function withKeyPressHandler(keys, onPress) {
    return keys.map((key) => {
        const keyWithHandler = { ...key };

        keyWithHandler.onPress = partial(onPress, keyWithHandler);

        return keyWithHandler;
    });
}

export default function KeyboardBlock({ keys, onPress, track = false }) {
    const context = useContext(KeyboardContext);
    const { handleTrackKeypress } = context[KEYBOARD_TYPES.PRIMARY];

    const handlePress = (...args) => {
        onPress(...args);

        if (track) {
            handleTrackKeypress(...args);
        }
    };

    return (
        <div className="keyboard__block">
            {withKeyPressHandler(keys, handlePress).map((key) => (
                <KeyboardKey {...key} key={`${key.value}`} />
            ))}
        </div>
    );
}

KeyboardBlock.propTypes = {
    keys: PropTypes.arrayOf(
        PropTypes.shape({
            ...keyboardKeyPropTypes,
            onPress: PropTypes.func,
        })
    ),
    onPress: PropTypes.func.isRequired,
    track: PropTypes.bool,
};
