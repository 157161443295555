export enum SPEC_COURSE_LESSON_VIEW_TABS {
    RESULTS = 'results',
    RULES = 'rules',
    DIAGNOSTICS_RESULTS = 'diagnostics_results',
}

export const SPEC_COURSE_LESSON_VIEW_DEFAULT_TAB = SPEC_COURSE_LESSON_VIEW_TABS.RULES;

export enum SPEC_COURSE_LESSON_MODE {
    NO_SCHOOL = 'no_school',
    INVITE_STUDENTS = 'invite_students',
    NO_STUDENTS = 'no_students',
    NO_STUDENTS_FINISHED = 'no_students_finished',
    NOT_SOLVED = 'not_solved',
    LESSON_ASSIGNED = 'lesson_assigned',
    LESSON_IN_PROGRESS = 'lesson_in_progress',
    LESSON_FINISHED = 'lesson_finished',
    CONTROL_WORK_ASSIGNED = 'control_work_assigned',
    CONTROL_WORK_IN_PROGRESS = 'control_work_in_progress',
    CONTROL_WORK_FINISHED = 'control_work_finished',
    WAITING_FOR_RESULTS = 'waiting_for_results',
}
