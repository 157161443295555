/* eslint-disable camelcase */
import PropTypes from 'prop-types';

export const mePropType = PropTypes.shape({
    children: PropTypes.array,
    date_joined: PropTypes.string,
    email: PropTypes.string,
    first_name: PropTypes.string,
    gender: PropTypes.number,
    id: PropTypes.number,
    is_content_manager: PropTypes.bool,
    is_parent: PropTypes.bool,
    is_personal_data_accepted: PropTypes.bool,
    is_staff: PropTypes.bool,
    is_student: PropTypes.bool,
    is_teacher: PropTypes.bool,
    is_tos_accepted: PropTypes.bool,
    last_name: PropTypes.string,
    middle_name: PropTypes.string,
    phone_number: PropTypes.string,
    public_name: PropTypes.string,
    teacher_profile: PropTypes.shape({
        subject: PropTypes.number,
        school: PropTypes.number,
        teams: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                level: PropTypes.number,
                name: PropTypes.string,
            })
        ),
        pin: PropTypes.string,
    }),
    teams: PropTypes.array,
    username: PropTypes.string,
    viewed_hints: PropTypes.object,
    yauser: PropTypes.object,
});
