import React, { Component } from 'react';
import getDisplayName from 'react-display-name';

// only one window-resize handler (first applied) for all app-components
let needApplyEffect = true;

interface MobileViewportHackProps {}

export default <T extends MobileViewportHackProps>(WrappedComponent: React.ComponentType<T>) => {
    class MobileViewportHack extends Component<T> {
        static displayName = `MobileViewportHackHoc(${getDisplayName(WrappedComponent)})`;

        componentDidMount() {
            if (needApplyEffect) {
                this.setViewportValue();
                window.addEventListener('resize', this.setViewportValue, { passive: true });
                needApplyEffect = false;
            }
        }

        setViewportValue() {
            const vh = window.innerHeight * 0.01;
            const vw = window.innerWidth * 0.01;

            document.documentElement.style.setProperty('--vh', `${vh}px`);
            document.documentElement.style.setProperty('--vw', `${vw}px`);
        }

        render() {
            return <WrappedComponent {...this.props} />;
        }
    }

    return MobileViewportHack as React.ComponentType<Omit<T, keyof MobileViewportHackProps>>;
};
