import { createErrorFromUnknown } from './create-error-from-unknown';
import { reportMessage } from './raven-message';

type WebStorageType = 'localStorage' | 'sessionStorage';

function retrieveFromStorage(type: WebStorageType, key: string) {
    try {
        return window[type].getItem(key);
    } catch (error) {
        reportMessage(`Failed to retrieve ${key} from ${type}`, createErrorFromUnknown(error));

        return null;
    }
}

export function retrieveFromLocalStorage(key: string) {
    return retrieveFromStorage('localStorage', key);
}

export function retrieveFromSessionStorage(key: string) {
    return retrieveFromStorage('sessionStorage', key);
}

function saveToStorage(type: WebStorageType, key: string, value: string) {
    try {
        window[type].setItem(key, value);
    } catch (error) {
        reportMessage(`Failed to save ${key} to ${type}`, createErrorFromUnknown(error));
    }
}

export function saveToLocalStorage(key: string, value: string) {
    return saveToStorage('localStorage', key, value);
}

export function saveToSessionStorage(key: string, value: string) {
    return saveToStorage('sessionStorage', key, value);
}

function removeFromStorage(type: WebStorageType, key: string) {
    try {
        window[type].removeItem(key);
    } catch (error) {
        reportMessage(`Failed to remove ${key} from ${type}`, createErrorFromUnknown(error));
    }
}

export function removeFromLocalStorage(key: string) {
    return removeFromStorage('localStorage', key);
}

export function removeFromSessionStorage(key: string) {
    return removeFromStorage('sessionStorage', key);
}
