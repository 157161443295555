/**
 * Проверяет, что в переданной строке только цифры
 */
export function isDigital(value: string): boolean {
    return /^\d*$/i.test(value);
}

/**
 * Проверяет, что в переданной строке только латинские буквы, цифры и дефис
 */
export function isValidSalesCode(value: string): boolean {
    return /^[a-z0-9-]+$/i.test(value);
}

export const convertString = (value: string, mapper: Record<string, string>): string =>
    value
        .split('')
        .map((char) => {
            return mapper[char] || char;
        })
        .join('');

/**
 * Проверяет, что в переданной строке валидные символы для ФИО (разрешены все виды тире)
 */
export function isValidHumanName(name: string): boolean {
    const regExp = new RegExp(/^[\wА-яёЁ\s.'\-\u2010-\u2014\u2212]+$/, 'g');

    return regExp.test(name);
}

/**
 * Проверяет, что в переданной строке валидный номер СНИЛС
 */
export function isValidSNLSNumber(snls: string): boolean {
    const regExp = new RegExp(/^\d{3}['\-\u2010-\u2014\u2212]\d{3}['\-\u2010-\u2014\u2212]\d{3}[\s]\d{2}$/, 'g');

    return regExp.test(snls);
}

/**
 * Проверяет, что в переданной строке присутствуют как минимум два слова
 */
export function isFirstNameAndLastNameExists(name?: string | null): boolean {
    if (!name) {
        return false;
    }

    return name.trim().split(/[\s]+/).length > 1;
}

export const isStrNullOrEmpty = (input: string | null): boolean => {
    if (!input) {
        return true;
    }

    return Boolean(input.trim().length === 0);
};

export const hasStudentValidLastNameAndFirstName = (student: { firstName?: string; lastName?: string }): boolean => {
    const firstName = student.firstName || '';
    const lastName = student.lastName || '';

    return isValidHumanName(firstName) && isValidHumanName(lastName);
};
