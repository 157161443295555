import { postVerifySchoolCode, postSubmitStudentLogin } from '@yandex-int/k-common/client/api';
import { showNetworkError } from 'platform-components';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { verifySchoolCodeSuccess, verifySchoolCodeError, submitLoginError, submitLoginSuccess } from './actions';
import { VERIFY_PASSWORD_REQUEST, SUBMIT_LOGIN_REQUEST } from './constants';
import { WRONG_AUTH_CODE } from './start-screen.constants';

export function* startSagasWatcher() {
    yield all([verifySchoolWatcher(), submitLoginWatcher()]);
}

function* verifySchoolWatcher() {
    yield takeLatest(VERIFY_PASSWORD_REQUEST, verifySchoolSaga);
}

function* submitLoginWatcher() {
    yield takeLatest(SUBMIT_LOGIN_REQUEST, submitLoginSaga);
}

function* verifySchoolSaga(action) {
    const { password } = action;

    try {
        const {
            school_data: schoolData,
            is_school: isSchool,
            is_parent: isParent,
        } = yield call(postVerifySchoolCode, { schoolCode: password });

        yield put(verifySchoolCodeSuccess(schoolData, isSchool, isParent));
    } catch (error) {
        console.error(error);
        if (error.status !== WRONG_AUTH_CODE) {
            yield put(showNetworkError(error));
        }
        yield put(verifySchoolCodeError(error));
    }
}

function* submitLoginSaga(action) {
    const { studentLogin } = action;

    try {
        yield call(postSubmitStudentLogin, { studentLogin });

        yield put(submitLoginSuccess());
    } catch (error) {
        console.error(error);
        if (error.status !== WRONG_AUTH_CODE) {
            yield put(showNetworkError(error));
        }
        yield put(submitLoginError(error));
    }
}
