import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CourseSubscription, Subscription } from '@yandex-int/k-common';
import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import { SimpleCallback, SUBJECTS_SLUGS_TYPE } from 'platform-components';

import { CourseSubscriptionsData, SubscriptionsStore } from 'store/subscriptions/typings';

const initialState: SubscriptionsStore = {
    availableSubscriptions: {},
    courseSubscriptions: {},
};

/* eslint-disable @typescript-eslint/no-unused-vars */
const slice = createSlice({
    name: 'SUBSCRIPTIONS',
    initialState: initialState,
    reducers: {
        getSubscriptionsByCourse: (state, action: PayloadAction<{ courseId: number }>) => {
            const { courseId } = action.payload;
            let data = state.courseSubscriptions[courseId];
            if (!data) {
                data = {} as CourseSubscriptionsData;
            }
            data.loadingStatus = LOADING_STATUSES.LOADING;
            state.courseSubscriptions[courseId] = data;
        },
        getSubscriptionsByCourseSuccess: (
            state,
            action: PayloadAction<{ courseId: number; subscriptions: Array<CourseSubscription> }>
        ) => {
            const { courseId, subscriptions } = action.payload;
            let data = state.courseSubscriptions[courseId];
            if (!data) {
                data = {} as CourseSubscriptionsData;
            }
            data.loadingStatus = LOADING_STATUSES.SUCCESS;
            data.subscriptions = subscriptions;
            state.courseSubscriptions[courseId] = data;
        },
        getSubscriptionsByCourseError: (state, action: PayloadAction<{ courseId: number }>) => {
            const { courseId } = action.payload;
            let data = state.courseSubscriptions[courseId];
            if (!data) {
                data = {} as CourseSubscriptionsData;
            }
            data.loadingStatus = LOADING_STATUSES.ERROR;
            state.courseSubscriptions[courseId] = data;
        },

        getAvailableSubscriptionsRequest: (
            state,
            action: PayloadAction<{ courseId: number; grade: number; subject: SUBJECTS_SLUGS_TYPE }>
        ) => {
            const { courseId } = action.payload;

            state.availableSubscriptions[courseId] = {
                ...state.availableSubscriptions[courseId],
                loadingStatus: LOADING_STATUSES.LOADING,
            };
        },
        getAvailableSubscriptionsSuccess: (
            state,
            action: PayloadAction<{ courseId: number; subscriptions: Subscription[] }>
        ) => {
            const { courseId, subscriptions } = action.payload;

            state.availableSubscriptions[courseId] = {
                subscriptions,
                loadingStatus: LOADING_STATUSES.SUCCESS,
            };
        },
        getAvailableSubscriptionsError: (state, action: PayloadAction<{ courseId: number }>) => {
            const { courseId } = action.payload;

            state.availableSubscriptions[courseId] = {
                ...state.availableSubscriptions[courseId],
                loadingStatus: LOADING_STATUSES.ERROR,
            };
        },

        subscribe: (
            state,
            action: PayloadAction<{
                slug: string;
                courseId: number;
                onSuccess?: SimpleCallback;
                onError?: SimpleCallback;
            }>
        ) => {
            // saga
        },
    },
});
/* eslint-enable @typescript-eslint/no-unused-vars */

export const courseSubscriptionsActions = slice.actions;
export const courseSubscriptionsReducer = slice.reducer;
