export enum SEARCH_PARAMS_INTEGRATION {
    ASYNC_CLASS_IMPORT = 'async_class_import',
    BACK_URL = 'back_url',
    CONNECTED_LMS = 'connectedLms',
    IS_TEACHER = 'is_teacher',
    LMS = 'lms',
    REJECT_RETPATH = 'rejectRetpath',
    RETPATH = 'retpath',
    SCHOOL_ID = 'school_id',
    SOCIAL_PROFILE_ID = 'profile.profile_id',
    STAFF_ID = 'staff_id',
    STATUS = 'status',
    INTEGRATION_SCENARIO = 'integration_scenario',
    LIBRARY = 'library',
    SUBJECT_ID = 'subject_id',
    CLASS_LEVEL_ID = 'class_level_id',
}

export enum INTEGRATION_SCENARIO {
    REGISTRATION = 'registration',
    ADD_CLASS = 'add_class',
    HOMEWORK = 'homework',
}

export enum SEARCH_PARAMS_MES {
    CHECKED_GROUP_IDS = 'checked_group_ids',
    CLASS_ID = 'class_id',
    MES_CLASS_IDS = 'mes_class_ids',
    TYPE = 'type',
}

export enum SEARCH_PARAMS_MES_HOMEWORK {
    BEGIN_DATE = 'begin_date',
    END_DATE = 'end_date',
    HOMEWORK_ENTRY_ID = 'homework_entry_id',
    HOMEWORK_ID = 'homework_id',
    STUDENT_LIST = 'student_list',
    SUBJECT_ID = 'subject_id',
}

export const LMS_HOMEWORK_ID_KEY = 'lms_hw_id';

export enum LIBRARY_TYPE {
    LAB = 'lab',
}
