import React, { ReactElement } from 'react';

import { SimpleCallback } from '../../typings';

export enum KEYBOARD_TYPES {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    PROBLEM_BAR = 'problem-bar',
    PROBLEM_BAR_SECONDARY = 'problem-bar-secondary',
    PROBLEM_BAR_ROVER = 'problem-bar-rover',
}

export interface Key {
    content?: React.ReactElement;
    disabled?: boolean;
    isSelected?: boolean;
    mode?: string;
    value: string | number;
    width?: string;
}

export interface KeyboardControls {
    hide: SimpleCallback;
    render: (content: ReactElement) => ReactElement;
}
