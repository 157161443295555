export const FINISH_REGISTRATION = 'INDEX.TEACHER_REGISTRATION.FINISH_REGISTRATION';
export const FINISH_REGISTRATION_ERROR = 'INDEX.TEACHER_REGISTRATION.FINISH_REGISTRATION_ERROR';
export const FINISH_REGISTRATION_SUCCESS = 'INDEX.TEACHER_REGISTRATION.FINISH_REGISTRATION_SUCCESS';

export const APPLY_CODE_REQUEST = 'INDEX.TEACHER_REGISTRATION.APPLY_CODE_REQUEST';
export const APPLY_CODE_SUCCESS = 'INDEX.TEACHER_REGISTRATION.APPLY_CODE_SUCCESS';
export const APPLY_CODE_ERROR = 'INDEX.TEACHER_REGISTRATION.APPLY_CODE_ERROR';

export const FINISH_REGISTRATION_BY_CODE = 'INDEX.TEACHER_REGISTRATION.FINISH_REGISTRATION_BY_CODE';
export const FINISH_REGISTRATION_BY_CODE_ERROR = 'INDEX.TEACHER_REGISTRATION.FINISH_REGISTRATION_BY_CODE_ERROR';
export const FINISH_REGISTRATION_BY_CODE_SUCCESS = 'INDEX.TEACHER_REGISTRATION.FINISH_REGISTRATION_BY_CODE_SUCCESS';

export const FINISH_REGISTRATION_BY_LINK = 'INDEX.TEACHER_REGISTRATION.FINISH_REGISTRATION_BY_LINK';
export const LOAD_SUBJECTS_FOR_GRADE = 'INDEX.TEACHER_REGISTRATION.LOAD_SUBJECTS_FOR_GRADE';
