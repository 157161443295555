import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';

import { COPYRIGHT_TYPES, TEXT_MARKUP_TYPE } from '../constants';
import Link from '../link/link';
import Text from '../text/text';

const FooterCopyright = ({ intl, type }, { service }) => {
    switch (type) {
        case COPYRIGHT_TYPES.SERVICE:
            return (
                <div className="footer__copyright">
                    <Text id={`footer.copyright.${service}`} type={TEXT_MARKUP_TYPE.HTML} />
                </div>
            );
        case COPYRIGHT_TYPES.YANDEX:
        default:
            const companyUrl = intl.formatMessage({ id: 'footer.companyUrl' });
            const companyName = intl.formatMessage({ id: 'footer.companyName' });
            const companyLink = (
                <Link mix="footer__link" to={companyUrl}>
                    <Text>{companyName}</Text>
                </Link>
            );

            return (
                <div className="footer__copyright">
                    <Text id="footer.copyright" values={{ companyLink }} />
                </div>
            );
    }
};

FooterCopyright.propTypes = {
    intl: PropTypes.object.isRequired,
    type: PropTypes.oneOf(Object.values(COPYRIGHT_TYPES)).isRequired,
};

FooterCopyright.contextTypes = {
    service: PropTypes.string.isRequired,
};

FooterCopyright.defaultProps = {
    type: COPYRIGHT_TYPES.YANDEX,
};

export default injectIntl(FooterCopyright);
