import { CLESSON_MODES } from '@yandex-int/k-common/constants';
import { Stack } from '@yandex-int/k-common/typings';
import { SimpleCallback } from 'platform-components';
import { InjectedSocketIoProps } from 'platform-components/src/utils/hocs/with-socket-io';
import { IntlShape } from 'react-intl';

import { PROBLEM_SIDE_PANEL_MODE } from 'common.components/lesson-view.common/constants';
import { SourceAssignmentInfo } from 'common.components/lesson/typings';

import * as constants from '../constants';
import { LESSON_VIEW_MODE, LESSON_MODAL_TABS, PROCESSABLE_ERRORS } from '../typings';

export const toggleModal = (shouldShowModal: boolean) => <const>{ type: constants.TOGGLE_MODAL, shouldShowModal };

export const assignLesson = (args: {
    lessonId: number;
    mesBackUrl: string | null;
    isTeacherFromMes: boolean;
    assignmentInfo: SourceAssignmentInfo;
    getNotificationMessageCallback: (
        assignedLessonId: number,
        studentLink?: string | null,
        mesBackUrl?: string | null,
        endDate?: Date | null
    ) => SimpleCallback | Promise<SimpleCallback>;
    notificationMessageId: string;
    onAssign: (assignedLessonId?: number) => void;
    onError?: SimpleCallback;
    onAssignError?: (error: PROCESSABLE_ERRORS) => void;
    intl: IntlShape;
    shouldShowSubscriptionModal: boolean;
}) =>
    <const>{
        type: constants.ASSIGN_LESSON_REQUEST,
        ...args,
    };

export const addStudentsToLesson = (payload: {
    clessonId: number;
    addedStudents: Array<number>;
    onSuccess?: (assignedLessonId?: number) => void;
}) => <const>{ type: constants.ADD_STUDENTS_TO_LESSON_REQUEST, payload };

export const addStudentsToLessonSuccess = () => <const>{ type: constants.ADD_STUDENTS_TO_LESSON_SUCCESS };

export const addStudentsToLessonError = () => <const>{ type: constants.ADD_STUDENTS_TO_LESSON_ERROR };

export const forceCompleteLesson = (
    courseId: number,
    clessonId: number,
    io: InjectedSocketIoProps['io'],
    teacherId?: number,
    onSuccess?: () => void,
    websocketDomain?: string
    // eslint-disable-next-line max-params
) =>
    <const>{
        type: constants.FORCE_COMPLETE_LESSON,
        courseId,
        clessonId,
        io,
        teacherId,
        onSuccess,
        websocketDomain,
    };

export const setSelectedStudentId = (selectedStudentId: number | null) =>
    <const>{ type: constants.SET_SELECTED_STUDENT_ID, selectedStudentId };

export const setShouldShowAssignmentSettings = (shouldShow: boolean) =>
    <const>{ type: constants.SET_SHOULD_SHOW_ASSIGNMENT_SETTINGS, shouldShow };

export const setSelectedTab = (selectedTab: LESSON_MODAL_TABS) =>
    <const>{ type: constants.SET_SELECTED_TAB, selectedTab };

export const toggleLessonNotification = (shouldShow: boolean, textId?: string) =>
    <const>{ type: constants.TOGGLE_LESSON_NOTIFICATION, shouldShow, textId };

export const changeProblemVariant = (oldProblemId: number, newProblemId: number) =>
    <const>{ type: constants.CHANGE_PROBLEM_VARIANT, oldProblemId, newProblemId };

export const setLessonNameByMode = ({ intl, lessonMode }: { intl: IntlShape; lessonMode: CLESSON_MODES }) =>
    <const>{ type: constants.SET_LESSON_NAME_BY_MODE, payload: { intl, lessonMode } };

export const getProblemStacksRequest = () => <const>{ type: constants.GET_PROBLEM_STACKS_REQUEST, payload: {} };

export const getProblemStacksSuccess = (payload: { stacks: Array<Stack> }) =>
    <const>{ type: constants.GET_PROBLEM_STACKS_SUCCESS, payload };

export const getProblemStacksError = () => <const>{ type: constants.GET_PROBLEM_STACKS_ERROR, payload: {} };

export const setProblemSidePanelMode = (payload: { mode: PROBLEM_SIDE_PANEL_MODE }) =>
    <const>{ type: constants.SET_PROBLEM_SIDEPANEL_MODE, payload };

export const setShouldAssignInExternalLms = (payload: boolean) =>
    <const>{ type: constants.SET_SHOULD_ASSIGN_IN_EXTERNAL_LMS, payload };

export const setMode = (mode: LESSON_VIEW_MODE) => <const>{ type: constants.SET_MODE, payload: { mode } };

export const setIsHeadless = (isHeadless: boolean) =>
    <const>{ type: constants.SET_IS_HEADLESS, payload: { isHeadless } };

export const loadLessonViewData = (payload: {
    lessonId: number;
    courseId?: number | null;
    moduleId?: number | null;
    onSuccess?: SimpleCallback;
}) => <const>{ type: constants.LOAD_LESSON_VIEW_DATA, payload };

export const setLessonCourseId = (payload: { courseId: number }) =>
    <const>{ type: constants.SET_LESSON_COURSE_ID, payload };
