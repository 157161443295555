import * as api from '@yandex-int/k-common/client/api';
import { showNetworkError } from 'platform-components';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { canBeParent } from 'common.components/child-management/saga';
import { ensureIAmParent } from 'common.components/user/saga';

import { actions } from './actions';
import * as selectors from './selectors';

export function* parentRegistrationWatcher() {
    yield all([takeLatest(actions.createChildRequest, createChildSaga)]);
}

// эта сага отличается от саги в add-child-modal/saga.ts
function* createChildSaga() {
    try {
        const canMakeMeParent: boolean = yield call(canBeParent);

        if (!canMakeMeParent) {
            return null;
        }

        yield call(ensureIAmParent);

        const name: string = yield select(selectors.getChildName);
        const grade: number = yield select(selectors.getStudentGrade);
        const { child_id: childId } = yield call(api.postCreateChild, { name, grade });

        // добавляем пустой список курсов - тогда будут выданы только автовыдаваемые курсы
        yield call(api.postAddChildCourses, { childId, templateIds: [] });

        yield put(actions.createChildSuccess(childId));

        return childId;
    } catch (error) {
        console.error(error);
        yield put(actions.createChildError());
        yield put(showNetworkError(error));
        return null;
    }
}
