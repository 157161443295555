import { Copyright } from 'platform-components';
import PropTypes from 'prop-types';
import React from 'react';

import Agreement from './__agreement/footer__agreement';
import Help from './__help/footer__help';
import DefaultFooter from './_theme_default/footer_theme_default';
import NarrowFooter from './_theme_narrow/footer_theme_narrow';
import { THEMES } from './constants';

const Footer = ({ theme, ...props }) => {
    switch (theme) {
        case THEMES.NARROW:
            return <NarrowFooter {...props} />;
        default:
            return <DefaultFooter {...props} />;
    }
};

Footer.propTypes = {
    theme: PropTypes.oneOf([THEMES.NARROW, THEMES.DEFAULT]),
};

Footer.defaultProps = {
    theme: THEMES.DEFAULT,
};

Footer.Agreement = Agreement;
Footer.Copyright = Copyright;
Footer.Help = Help;

export default Footer;
