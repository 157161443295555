import { addWeeks, set, setDay } from 'date-fns';

import { WEEK_DAYS } from 'common.components/constants';

import ru from 'date-fns/locale/ru';
import { DateFunction } from './types';

export const getMorning = (date: Date) => {
    return set(date, {
        hours: 8,
        minutes: 30,
        seconds: 0,
        milliseconds: 0,
    });
};

const getMondayDate: DateFunction = (nowDate: Date) => {
    const monday = setDay(nowDate, WEEK_DAYS.MONDAY, { locale: ru });
    return getMorning(monday);
};

export const mondayMorning: DateFunction = (nowDate: Date) => {
    const currentWeekTarget = getMondayDate(nowDate);

    if (nowDate > currentWeekTarget) {
        return addWeeks(currentWeekTarget, 1);
    }

    return currentWeekTarget;
};

export const nextMondayMorning: DateFunction = (nowDate: Date) => {
    const currentWeekTarget = getMondayDate(nowDate);

    if (nowDate > currentWeekTarget) {
        return addWeeks(currentWeekTarget, 2);
    }

    return currentWeekTarget;
};
