import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import { CertificateReward, LESSON_STATUS } from '@yandex-int/k-common/typings';
// @ts-ignore
import zeroPad from '@yandex-int/k-common/utils/zero-pad';
import { isToday } from 'date-fns';
import { isEmpty } from 'lodash';
import { IntlShape } from 'react-intl';

import { PrepLessonDetailsClientSide } from 'store/prep-lessons/typings';
import { formatDate } from 'utils/date/formatDate';
import { getParams } from 'utils/path';

export const isLessonCompleted = (lessonStatus: LESSON_STATUS) => {
    return [
        LESSON_STATUS.FINISH_FORCED,
        LESSON_STATUS.FINISHED,
        LESSON_STATUS.NOT_SOLVED,
        LESSON_STATUS.WAIT_FOR_RESULTS,
        LESSON_STATUS.CANCELLED,
    ].includes(lessonStatus);
};

export const isLessonCompletedForCert = (lessonStatus: LESSON_STATUS) => {
    return [
        LESSON_STATUS.FINISH_FORCED,
        LESSON_STATUS.FINISHED,
        LESSON_STATUS.NOT_SOLVED,
        LESSON_STATUS.CANCELLED,
    ].includes(lessonStatus);
};

export const isLessonInProgress = (lessonStatus: LESSON_STATUS) => {
    return [LESSON_STATUS.IN_PROGRESS].includes(lessonStatus);
};

export const isLessonAssigned = (lessonStatus: LESSON_STATUS) => {
    return [LESSON_STATUS.ASSIGNED, LESSON_STATUS.ASSIGNED_IN_FUTURE].includes(lessonStatus);
};

interface GetLessonDefaultName {
    intl: IntlShape;
    isEvaluable: boolean;
}

export function getLessonDefaultName({ intl, isEvaluable = false }: GetLessonDefaultName): string {
    const now = new Date();
    const date = `${zeroPad(now.getDate())}.${zeroPad(now.getMonth() + 1)}.${now.getFullYear()}`;
    const id = isEvaluable ? 'lessonEditor.evaluableLessonName' : 'lessonEditor.defaultLessonName';

    return intl.formatMessage({ id }, { date });
}

export const getIsLessonAvailableForChildren = (status: LESSON_STATUS | null): boolean =>
    status !== null && ![LESSON_STATUS.NOT_ASSIGNED, LESSON_STATUS.CANCELLED].includes(status);

export function getThemeId(): number | null {
    const params = getParams();
    const themeId = params?.themeId;

    if (themeId) {
        return Number(themeId);
    }

    return null;
}

export const getLatestLessonCertificateBySlug = (
    certificates: Array<CertificateReward>,
    slug: string | null
): CertificateReward | null => {
    if (!slug) {
        return null;
    }

    const sortedCertificatesBySlug = certificates
        .filter((c) => c.slug === slug)
        .sort((a, b) => new Date(a.date_created).getTime() - new Date(b.date_created).getTime());

    const length = sortedCertificatesBySlug.length;

    return length ? sortedCertificatesBySlug[length - 1] : null;
};

export const mapLessonsToIds = (lessons: { id: number }[]): number[] => {
    return lessons.map(({ id }) => id);
};

export const getPrepLessonsWithoutDetailsIds = (
    prepLessonsIds: number[],
    prepLessonsDetails: Record<number, PrepLessonDetailsClientSide>
) => {
    return prepLessonsIds.filter((id) => isEmpty(prepLessonsDetails[id]));
};

export const getShouldLoadPrepLessonsDetails = (
    lessonsWithoutDetailsCount: number,
    loadingStatus: LOADING_STATUSES
) => {
    // если урок скопирован с другого, то ручка prep-lesson-details возвращает пустой массив,
    // то есть ситуация, когда после успешной загрузки данных у нас hasLessonsWithoutDetails === true - валидна,
    // и не надо заново грузить данные (а то провалимся в бесонечный цикл).
    // См. https://st.yandex-team.ru/EDUCATION-18506
    const hasLessonsWithoutDetails = lessonsWithoutDetailsCount !== 0;
    const hasLoadedPrepLessonsWithoutDetails = hasLessonsWithoutDetails && loadingStatus === LOADING_STATUSES.SUCCESS;
    const shouldLoadPrepLessonsDetails =
        hasLessonsWithoutDetails && ![LOADING_STATUSES.LOADING, LOADING_STATUSES.ERROR].includes(loadingStatus);

    return !hasLoadedPrepLessonsWithoutDetails && shouldLoadPrepLessonsDetails;
};

export const getCalendarLessonStatus = (status: LESSON_STATUS) => {
    if (isLessonCompleted(status)) {
        return LESSON_STATUS.FINISHED;
    } else if (isLessonInProgress(status)) {
        return LESSON_STATUS.IN_PROGRESS;
    } else if (status === LESSON_STATUS.ASSIGNED) {
        return LESSON_STATUS.ASSIGNED;
    } else if (status === LESSON_STATUS.ASSIGNED_IN_FUTURE) {
        return LESSON_STATUS.ASSIGNED_IN_FUTURE;
    }
    return LESSON_STATUS.NOT_ASSIGNED;
};

export const getShouldLessonShowDeadline = (lessonStatus: LESSON_STATUS) =>
    [LESSON_STATUS.ASSIGNED, LESSON_STATUS.IN_PROGRESS].includes(lessonStatus);

export const getEndDate = (dateDeadline: string | number | null) => {
    if (!dateDeadline) {
        return null;
    }

    const dateFormat = isToday(new Date(dateDeadline)) ? 'HH:mm' : 'dd.MM';
    return formatDate(new Date(dateDeadline), dateFormat);
};
