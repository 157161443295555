import b from 'b_';
import { Text } from 'platform-components';
import { withRouterPath, InjectedProps as WithRouterPathProps } from 'platform-components/hocs';
import qs from 'query-string';
import React from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { accessibleClick } from '@edu-frontend/modules/src/shared/lib/a11y';

// @ts-ignore
import { SEARCH_PARAM_TEACHER_PROMO } from 'common.components/constants';
// @ts-ignore
import IconPencil from 'common.components/icon/__pencil/icon__pencil';
import * as salesCodeModalActions from 'common.components/sales-code-modal/actions';

import { RootAction, ReduxIndexStore } from '../../../typings';
import { actions as teacherRegistrationPageActions } from '../actions';
import * as teacherRegistrationPageSelectors from '../selectors';
import { TeacherRegistrationData } from '../typings';

import './teacher-registration-page__sales-code.scss';

interface iTeacherRegistrationSalesCodeProps extends WithRouterPathProps {
    toggleSalesCodeModal: (opened: boolean) => void;
    setRegistrationData: (data: TeacherRegistrationData) => void;
    intl: IntlShape;
    salesCode?: string;
}

interface iTeacherRegistrationSalesCodeState {
    formShown: boolean;
    salesCode?: string;
}

export class TeacherRegistrationSalesCode extends React.PureComponent<
    iTeacherRegistrationSalesCodeProps,
    iTeacherRegistrationSalesCodeState
> {
    constructor(props: iTeacherRegistrationSalesCodeProps) {
        super(props);

        this.state = {
            formShown: false,
            salesCode: props.salesCode,
        };
    }

    componentDidUpdate(prevProps: Readonly<iTeacherRegistrationSalesCodeProps>): void {
        const { salesCode: prevSavedSalesCode } = prevProps;
        const { salesCode: savedSalesCode } = this.props;
        const { salesCode } = this.state;

        if (savedSalesCode !== prevSavedSalesCode && salesCode !== savedSalesCode) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                salesCode: savedSalesCode,
            });
        }
    }

    showSalesCodeModal = () => {
        const { toggleSalesCodeModal } = this.props;

        toggleSalesCodeModal(true);
    };

    getText = (): React.ReactNode => {
        const {
            salesCode: savedSalesCode,
            location: { search },
        } = this.props;
        const { salesCode } = this.state;
        // в props хранится code из хинтов - сохраненный
        // а в state - тот, что пользователь вводит прямо сейчас
        // Если они совпали, считаем что code сохранен
        const isSalesCodeSaved = salesCode && salesCode === savedSalesCode;
        const teacherCode = qs.parse(search)[SEARCH_PARAM_TEACHER_PROMO];
        const isDisabled = Boolean(teacherCode);

        if (isSalesCodeSaved) {
            return (
                <div className="teacher-registration__sales-code-wrapper">
                    <span className={b('teacher-registration', 'sales-code-value', { disabled: isDisabled })}>
                        <Text>{salesCode}</Text>
                    </span>
                    <span
                        className={b('teacher-registration', 'sales-code-edit', { disabled: isDisabled })}
                        {...accessibleClick(this.showSalesCodeModal)}
                    >
                        <IconPencil />
                    </span>
                </div>
            );
        }

        return (
            <React.Fragment>
                <span className={b('teacher-registration', 'sales-code-text', { disabled: isDisabled })}>
                    <Text id="teacherRegistration.salesCode" />
                </span>
                <span
                    className={b('teacher-registration', 'sales-code-enter', { disabled: isDisabled })}
                    {...accessibleClick(this.showSalesCodeModal)}
                >
                    <Text id="teacherRegistration.salesCode.enter" />
                </span>
            </React.Fragment>
        );
    };

    render() {
        return <div className="teacher-registration__sales-code">{this.getText()}</div>;
    }
}

type DispatchProps = Pick<iTeacherRegistrationSalesCodeProps, 'setRegistrationData' | 'toggleSalesCodeModal'>;
type StateProps = Pick<iTeacherRegistrationSalesCodeProps, 'salesCode'>;

const mapStateToProps = (storeState: ReduxIndexStore): StateProps => ({
    salesCode: teacherRegistrationPageSelectors.getSalesCode(storeState),
});

const mapDispatchToProps = (dispatch: Dispatch<RootAction>): DispatchProps => {
    return bindActionCreators(
        {
            setRegistrationData: teacherRegistrationPageActions.setRegistrationData,
            toggleSalesCodeModal: salesCodeModalActions.toggleSalesCodeModal,
        },
        dispatch
    );
};

const component = withRouterPath(
    injectIntl(connect(mapStateToProps, mapDispatchToProps)(TeacherRegistrationSalesCode))
);
component.displayName = 'TeacherRegistrationSalesCode';

export default component;
