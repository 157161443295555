import memoizeOne from 'memoize-one';
import PropTypes from 'prop-types';
import React from 'react';

import { mdStatePropType } from '../../../utils/prop-types/md-state';
import KeyboardBlock from '../../keyboard/__block/keyboard__block';
import KeyboardGroup from '../../keyboard/__group/keyboard__group';
import { KeyboardGroupsWrapper } from '../../keyboard/__group/keyboard__groups-wrapper';
import KeyboardContext from '../../keyboard/keyboard-context';
import { ARROW_NEXT } from '../../keyboard/keyboard.keys';
import { KEYBOARD_TYPES } from '../../keyboard/types';
import UseKeyboard from '../../keyboard/use-keyboard';
import { getKeyboardGroupRows } from '../../keyboard/utils';
import { scrollToViewInlineInput } from '../utils';

import './inline-input_type_choice.scss';

class InlineInputTypeChoiceCompact extends React.Component {
    static contextType = KeyboardContext;

    static propTypes = {
        answer: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        className: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        intl: PropTypes.object.isRequired,
        mdState: mdStatePropType,
        onKeyDown: PropTypes.func,
        options: PropTypes.object.isRequired,
        readOnly: PropTypes.bool,
        setAnswer: PropTypes.func.isRequired,
        showCorrectAnswer: PropTypes.bool,
        theme: PropTypes.string,
        userAnswer: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        uatraits: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            isInputFocused: false,
        };

        this.ref = React.createRef();
    }

    componentDidMount() {
        const { setAnswer, userAnswer } = this.props;
        const userAnswerAsNumber = typeof userAnswer === 'number' ? userAnswer : null;

        setAnswer(userAnswerAsNumber);
    }

    componentDidUpdate(_, prevState) {
        const { isInputFocused } = this.state;

        if (isInputFocused !== prevState.isInputFocused && isInputFocused) {
            // ждем появления ref на keyboard и изменения margin-top для problem-bar
            setTimeout(() => {
                const { refs } = this.context;
                const { bottom } = this.ref.current.getBoundingClientRect();

                scrollToViewInlineInput(bottom, refs[KEYBOARD_TYPES.PRIMARY]);
            }, 50);
        }
    }

    getInputValue = ({ answer, userAnswer, showCorrectAnswer }) => {
        const userAnswerAsNumber = typeof userAnswer === 'number' ? userAnswer : null;

        return showCorrectAnswer ? answer : userAnswerAsNumber;
    };

    setNewAnswer = (value) => {
        if (value !== undefined && value !== null && value !== '') {
            value = Number(value);
        }
        this.props.setAnswer(value);
    };

    handleInputFocus = () => {
        this.setState({ isInputFocused: true });
    };

    handleInputBlur = () => {
        this.setState({ isInputFocused: false });
    };

    handleKeyboardKey = (key) => {
        const value = this.getInputValue(this.props);

        if (key.value === value) {
            this.setNewAnswer(null);
        } else {
            this.setNewAnswer(key.value);
        }
    };

    getMemoizedKeyboardKeys = memoizeOne((value, choices) => {
        const currentChoice = choices[value];

        return choices.map((choice, i) => ({
            value: i,
            content: choice,
            isSelected: currentChoice === choice,
        }));
    });

    getKeyboardKeys = () => {
        const value = this.getInputValue(this.props);
        const {
            options: { choices },
        } = this.props;

        return this.getMemoizedKeyboardKeys(value, choices);
    };

    shouldShowKeyboard = () => {
        const { readOnly } = this.props;
        const { isInputFocused } = this.state;

        return !readOnly && isInputFocused;
    };

    render() {
        const { intl, onKeyDown, options, readOnly } = this.props;
        const value = this.getInputValue(this.props);
        const inputValue = value !== null ? options.choices[value] : '';

        const keys = this.getKeyboardKeys();
        return (
            <>
                <input
                    className="inline-input_type_choice-compact"
                    data-switchable={!readOnly}
                    onBlur={this.handleInputBlur}
                    onFocus={this.handleInputFocus}
                    onKeyDown={onKeyDown}
                    placeholder={intl.formatMessage({ id: 'inlineInputTypeCompact.placeholder' })}
                    readOnly={true}
                    ref={this.ref}
                    tabIndex="-1"
                    value={inputValue}
                />
                <UseKeyboard keyboardType={KEYBOARD_TYPES.PRIMARY} show={this.shouldShowKeyboard()}>
                    <KeyboardGroupsWrapper>
                        <KeyboardGroup
                            keys={keys}
                            onPress={this.handleKeyboardKey}
                            rows={getKeyboardGroupRows(keys.length)}
                        />
                        <KeyboardBlock
                            keys={ARROW_NEXT}
                            onPress={this.context[KEYBOARD_TYPES.PRIMARY].handleKeyboardArrow}
                        />
                    </KeyboardGroupsWrapper>
                </UseKeyboard>
            </>
        );
    }
}

export default InlineInputTypeChoiceCompact;
