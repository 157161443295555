import * as React from 'react';
const SvgCheckmark = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="2 2 16 16" fill="none" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 10.003a8 8 0 1016 0 8 8 0 00-16 0zm14 0a6 6 0 11-12 0 6 6 0 0112 0z"
            fill="currentColor"
        />
        <path
            d="M12.25 6.841a1 1 0 111.504 1.318L9.87 12.737c-.055.061-.055.061-.115.118a1.5 1.5 0 01-2.12-.093l-1.372-1.59A1 1 0 017.737 9.82l.996 1.178 3.517-4.158z"
            fill="currentColor"
        />
    </svg>
);
export default SvgCheckmark;
