import cx from 'classnames';
import { Text } from 'platform-components';
import React, { useMemo } from 'react';

import { chunkString } from 'common.components/students-print/utils';

import './students-print__invitation-key.scss';

interface Props {
    label: string;
    length: number;
    mix?: string;
    value?: string | null;
}

export const StudentsPrintInvitationKey = ({ length, label, mix, value }: Props) => {
    const charsChunks = useMemo(() => {
        const chunks = chunkString(value || '', length);

        const resultChunks: string[][] = [];

        if (!chunks) {
            return resultChunks;
        }

        for (const chunk of chunks) {
            const items = chunk.split('');

            for (let i = items.length; i < length; i += 1) {
                items.push('');
            }

            resultChunks.push(items);
        }

        return resultChunks;
    }, [length, value]);

    return (
        <div className={cx('students-print__invitation-key', mix)}>
            <div className="students-print__invitation-key-label">
                <Text size={Text.SIZE.S} weight={Text.WEIGHT.L}>
                    {label}
                </Text>
            </div>
            <div className="students-print__invitation-key-chunks">
                {charsChunks.map((chars, chunkIdx) => (
                    <div className="students-print__invitation-key-chars" key={`chunk-${chunkIdx}`}>
                        {chars.map((char, idx) => (
                            <div className="students-print__invitation-key-char" key={`char-${chunkIdx}-${idx}`}>
                                <Text size={Text.SIZE.XL}>{char}</Text>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};
