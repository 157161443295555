import cx from 'classnames';
import {
    H4,
    SimpleText,
    Text,
    SimpleCallback,
    PersonalAgreement,
    BorderedInput,
    UI_KIT_TEXT_TYPOGRAPHY,
} from 'platform-components';
import { InputChangeEvent } from 'platform-components/src/typings';
import { isCyrillicKeyboardSymbols, convertString, ENG_TO_RUS_LAYOUT_MAP } from 'platform-components/utils';
import React, { useEffect } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import ym from 'react-yandex-metrika';

// @ts-ignore
import FormView from 'common.components/form-view/form-view';

import { ErrorPopup } from '../__error-popup/child-management__error-popup';
import * as actions from '../actions';
import { LOGIN_LENGTH, PARENT_CODE_LENGTH, SCHOOL_CODE_LENGTH } from '../constants';
import * as selectors from '../selectors';
import { canBeParentCode, canBeSchoolCode, getIsPasswordLengthValid } from '../utils';

import './child-management__student-code.scss';

const submitButtonTextId = 'childManagment.studentCode.submit';

const { Card, CardTitle, CardSubtitle, CardSection, CardDescription, Button } = FormView;

interface DispatchProps {
    bindChildByStudentCodeRequest: typeof actions.bindChildByStudentCodeRequest;
    bindChildByLoginRequest: typeof actions.bindChildByLoginRequest;
    verifySchoolCodeRequest: typeof actions.verifySchoolCodeRequest;
    setStudentCode: typeof actions.setStudentCode;
    setLogin: typeof actions.setLogin;
    setSchoolCode: typeof actions.setSchoolCode;
    setPasswordLength: typeof actions.setPasswordLength;
}

interface StoreProps {
    code: string;
    login: string;
    schoolCode: string;
    passwordLength: number;
    isLoadingCodeWrong: boolean;
    isStudentBindingByCodeDisabled: boolean;
    isStudentBindingByLoginDisabled: boolean;
    shouldShowButtonProgress: boolean;
    shouldShowBindingByLoginProgress: boolean;
    shouldShowErrorPopup: boolean;
}

interface OwnProps {
    childName?: string;
    bottomSection?: React.ReactElement;
    mix?: string;
    onSuccess: SimpleCallback;
    parentCode?: string;
    showAgreement?: boolean;
    isSubmitting?: boolean;
}

interface Props extends StoreProps, OwnProps, WrappedComponentProps {
    setStudentCode: (e: InputChangeEvent) => void;
    setLogin: (login: string) => void;
    setSchoolCode: (schoolCode: string, passwordLength: number) => void;
    setPasswordLength: (length: number) => void;
    verifySchoolCodeRequest: (schoolCode: string) => void;
    bindChildByStudentCodeRequest: SimpleCallback;
    bindChildByLoginRequest: SimpleCallback;
}

export const ChildManagementStudentCode = (props: Props) => {
    /* eslint complexity: [2, 9] */

    const {
        bindChildByStudentCodeRequest,
        bindChildByLoginRequest,
        bottomSection,
        childName,
        login,
        schoolCode,
        passwordLength,
        intl,
        isStudentBindingByCodeDisabled,
        isStudentBindingByLoginDisabled,
        mix,
        parentCode,
        setStudentCode,
        setLogin,
        setSchoolCode,
        setPasswordLength,
        verifySchoolCodeRequest,
        showAgreement,
        isSubmitting,
        shouldShowButtonProgress,
        shouldShowBindingByLoginProgress,
    } = props;
    const submitButtonText = intl.formatMessage({ id: submitButtonTextId });

    useEffect(() => {
        if (parentCode) {
            setStudentCode({ target: { value: parentCode } });
        }
    }, [parentCode, setStudentCode]);

    useEffect(() => {
        if (parentCode && childName) {
            ym('reachGoal', 'parent_registration_visit_admit_child');
        }
    }, [childName, parentCode]);

    const handleBindChildClick = () => {
        ym('reachGoal', 'parent_registration_admit_child');
        bindChildByStudentCodeRequest();
    };

    const handleBindByLoginClick = () => {
        ym('reachGoal', 'parent_registration_admit_child');
        bindChildByLoginRequest();
    };

    const handleLoginChange = ({ target: { value } }: InputChangeEvent) => {
        if (!isCyrillicKeyboardSymbols(value)) {
            return;
        }

        const valueToUpperCase = value && value.toUpperCase();
        const convertedLogin = value && convertString(valueToUpperCase, ENG_TO_RUS_LAYOUT_MAP);

        setLogin(convertedLogin);
    };

    const onPaste = () => {
        setPasswordLength(PARENT_CODE_LENGTH);
    };

    const onSchoolCodeChange = ({ target: { value: password } }: InputChangeEvent) => {
        let isPasswordLengthValid = false;

        if (canBeParentCode(password)) {
            isPasswordLengthValid = getIsPasswordLengthValid(password, PARENT_CODE_LENGTH);
            setPasswordLength(PARENT_CODE_LENGTH);
            const passwordToUpperCase = password && password.toUpperCase();
            const convertedPassword = password && convertString(passwordToUpperCase, ENG_TO_RUS_LAYOUT_MAP);
            password = convertedPassword;
        } else if (canBeSchoolCode(password)) {
            isPasswordLengthValid = getIsPasswordLengthValid(password, SCHOOL_CODE_LENGTH);
            setPasswordLength(SCHOOL_CODE_LENGTH);
        } else {
            return;
        }

        setSchoolCode(password, passwordLength);

        if (isPasswordLengthValid) {
            verifySchoolCodeRequest(password);
        }
    };

    if (parentCode && childName) {
        return (
            <Card mix={cx('child-management__form', 'child-management__form_confirm-by-code', mix)}>
                <CardTitle mix="form-view__card-section">
                    <H4>
                        <FormattedMessage id="childManagment.confirmByCode.title" values={{ childName }} />
                    </H4>
                </CardTitle>
                <CardSubtitle mix="form-view__card-section">
                    <Text id="childManagment.confirmByCode.description" size={Text.SIZE.XL} />
                </CardSubtitle>
                <Button
                    disabled={isStudentBindingByCodeDisabled}
                    onClick={handleBindChildClick}
                    progress={shouldShowButtonProgress || Boolean(isSubmitting)}
                >
                    <SimpleText id={submitButtonTextId} />
                </Button>
                {showAgreement && (
                    <CardDescription mix="child-management__agreement">
                        <PersonalAgreement
                            buttonText={submitButtonText}
                            linksConfig={[
                                PersonalAgreement.personalAgreementLinks.parentPdLink,
                                PersonalAgreement.personalAgreementLinks.olympLink,
                            ]}
                        />
                    </CardDescription>
                )}
            </Card>
        );
    }

    return (
        <Card mix={cx('child-management__form', mix)}>
            <CardTitle mix="form-view__card-section">
                <H4 id="childManagement.bindByLogin.title" type={Text.TYPE.MARKDOWN} />
            </CardTitle>
            <CardSubtitle mix="form-view__card-section">
                <Text id="childManagement.bindByLogin.subTitle" size={Text.SIZE.XL} />
            </CardSubtitle>
            <CardSection mix="child-management__info">
                <div className="child-management__control-row">
                    <div className="child-management__control-label">
                        <Text
                            id="childManagement.bindByLogin.login"
                            typography={UI_KIT_TEXT_TYPOGRAPHY.paragraphXLRegular}
                        />
                    </div>
                    <div className="child-management__control-login">
                        <BorderedInput
                            focused
                            keepUserValueIfCaseMismatch={true}
                            maxLength={LOGIN_LENGTH}
                            mix="child-management__login-input"
                            onChange={handleLoginChange}
                            value={login}
                        />
                    </div>
                </div>
                <div className="child-management__control-row">
                    <div className="child-management__control-label">
                        <Text
                            id="childManagement.bindByLogin.schoolCode"
                            typography={UI_KIT_TEXT_TYPOGRAPHY.paragraphXLRegular}
                        />
                    </div>
                    <div className="child-management__control-school-code">
                        <div className="child-management__school-code-wrapper">
                            <BorderedInput
                                keepUserValueIfCaseMismatch={true}
                                maxLength={passwordLength}
                                mix="child-management__school-code-input"
                                onChange={onSchoolCodeChange}
                                onPaste={onPaste}
                                value={schoolCode}
                            />
                        </div>
                    </div>
                </div>
                <div className={'child-management__message-wrapper'}>
                    <ErrorPopup />
                </div>
            </CardSection>
            <Button
                disabled={isStudentBindingByLoginDisabled}
                onClick={handleBindByLoginClick}
                progress={shouldShowBindingByLoginProgress}
            >
                <SimpleText id={submitButtonTextId} />
            </Button>
            {showAgreement && (
                <CardDescription mix="child-management__agreement">
                    <PersonalAgreement
                        buttonText={submitButtonText}
                        linksConfig={[
                            PersonalAgreement.personalAgreementLinks.parentPdLink,
                            PersonalAgreement.personalAgreementLinks.olympLink,
                        ]}
                    />
                </CardDescription>
            )}
            {bottomSection && <FormView.Footer mix="child-management__footer-section">{bottomSection}</FormView.Footer>}
        </Card>
    );
};

const mapStateToProps = (storeState: any): StoreProps => ({
    code: selectors.getStudentCode(storeState),
    login: selectors.getLogin(storeState),
    schoolCode: selectors.getSchoolCode(storeState),
    passwordLength: selectors.getPasswordLength(storeState),
    isLoadingCodeWrong: selectors.getIsBindingLoadingCodeWrong(storeState),
    isStudentBindingByCodeDisabled: selectors.getIsStudentBindingDisabled(storeState),
    isStudentBindingByLoginDisabled: selectors.getIsBindingByLoginDisabled(storeState),
    shouldShowButtonProgress: selectors.shouldShowButtonProgress(storeState),
    shouldShowBindingByLoginProgress: selectors.shouldShowBindingByLoginProgress(storeState),
    shouldShowErrorPopup: selectors.getShouldShowErrorPopup(storeState),
});

const mapDispatchToProps = {
    setStudentCode: actions.setStudentCode,
    setLogin: actions.setLogin,
    setSchoolCode: actions.setSchoolCode,
    setPasswordLength: actions.setPasswordLength,
    verifySchoolCodeRequest: actions.verifySchoolCodeRequest,
    bindChildByStudentCodeRequest: actions.bindChildByStudentCodeRequest,
    bindChildByLoginRequest: actions.bindChildByLoginRequest,
};

const mergeProps = (
    stateProps: StoreProps,
    dispatchProps: DispatchProps,
    ownProps: OwnProps & WrappedComponentProps
): Props => {
    const { code, login, schoolCode } = stateProps;
    const { onSuccess } = ownProps;

    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        setStudentCode: ({ target: { value } }) => dispatchProps.setStudentCode(value),
        bindChildByStudentCodeRequest: () => dispatchProps.bindChildByStudentCodeRequest(code, onSuccess),
        bindChildByLoginRequest: () => dispatchProps.bindChildByLoginRequest(login, schoolCode, onSuccess),
    };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps, mergeProps)(ChildManagementStudentCode));
