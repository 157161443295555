import { takeEvery, put } from 'redux-saga/effects';

import { setNotification } from './actions';
import { SHOW_NOTIFICATION, DEFAULT_NOTIFICATION_ID } from './constants';
import { notificationBodysStore } from './notificationBodysStore';

export function* showNotificationWatcher() {
    yield takeEvery(SHOW_NOTIFICATION, showNotificationSaga);
}

function* showNotificationSaga(action) {
    const { id = DEFAULT_NOTIFICATION_ID, body, theme } = action.notificationInfo;

    if (body) {
        notificationBodysStore[id] = body;
    }

    // чтобы новый notification всегда преводил к изменению в redux-store
    const { notificationInfo: patchedNotificationInfo } = action;

    patchedNotificationInfo.timeStamp = new Date().getTime();
    // не хранить лишнюю информацию в redux-store
    delete patchedNotificationInfo.body;

    if (!theme) {
        patchedNotificationInfo.theme = 'normal';
    }

    yield put(setNotification(patchedNotificationInfo));
}
