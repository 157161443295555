const get = require('lodash/get');

const { INITIAL_SUBJECT } = require('../../constants');

function getInitialSubjectFromConfig(config) {
    return get(config, 'initial_subject', INITIAL_SUBJECT);
}

module.exports = {
    getInitialSubjectFromConfig
};
