import { ErrorWithAdditional } from './create-error-from-unknown';

export function reportMessage(message: string, error?: ErrorWithAdditional) {
    console.error(message);

    if (error) {
        console.error(error);

        if (error.additional) {
            // eslint-disable-next-line no-console
            console.info(error.additional);
        }
    }

    if (typeof window !== 'undefined') {
        window?.Ya?.Rum?.logError?.({ level: 'error', message }, error);
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function captureException(error: any) {
    console.error(error);

    if (typeof window !== 'undefined') {
        window?.Ya?.Rum?.logError?.({ level: 'error' }, error);
    }
}
