import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FullCLesson } from '@yandex-int/k-common';
import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';

import { Diagnostic, DiagnosticsStoreState } from './typings';

const initialState: DiagnosticsStoreState = {};

const slice = createSlice({
    name: 'DIAGNOSTICS',
    initialState,
    reducers: {
        getDiagnosticRequest: (
            state: DiagnosticsStoreState,
            action: PayloadAction<{
                courseId: number;
                date: string;
                grade: number;
                subject: string;
                teamId: number;
            }>
        ) => {
            const { subject, teamId } = action.payload;

            if (!state[teamId]) {
                state[teamId] = {};
            }
            // @ts-ignore state[teamId] не undefined
            state[teamId][subject] = {
                diagnostic: undefined,
                loadingStatus: LOADING_STATUSES.LOADING,
            };
        },

        getDiagnosticSuccess: (
            state: DiagnosticsStoreState,
            action: PayloadAction<{
                diagnostic: Diagnostic;
                subject: string;
                teamId: number;
            }>
        ) => {
            const { diagnostic, subject, teamId } = action.payload;

            if (!state[teamId]) {
                state[teamId] = {};
            }
            // @ts-ignore state[teamId] не undefined
            state[teamId][subject] = {
                diagnostic,
                loadingStatus: LOADING_STATUSES.SUCCESS,
            };
        },

        getDiagnosticError: (
            state: DiagnosticsStoreState,
            action: PayloadAction<{
                subject: string;
                teamId: number;
            }>
        ) => {
            const { subject, teamId } = action.payload;

            if (!state[teamId]) {
                state[teamId] = {};
            }
            // @ts-ignore state[teamId] не undefined
            state[teamId][subject] = {
                diagnostic: undefined,
                loadingStatus: LOADING_STATUSES.ERROR,
            };
        },

        postDiagnosticAssignedRequest: (
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            state: DiagnosticsStoreState,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            action: PayloadAction<{
                diagnosticId: string;
                courseId: number;
                courseLessonLinkId: number;
                teamId: number;
            }>
        ) => {
            // saga
        },

        setLesson: (
            state: DiagnosticsStoreState,
            action: PayloadAction<{
                subject: string;
                lesson: FullCLesson | null;
                teamId: number;
            }>
        ) => {
            const { lesson, subject, teamId } = action.payload;

            if (!state[teamId]) {
                state[teamId] = {};
            }
            // @ts-ignore state[teamId] не undefined
            if (state[teamId][subject]) {
                // @ts-ignore state[teamId][subject] не может быть undefined из-за проверки сверху
                state[teamId][subject].lesson = lesson;
            }
        },

        clearDiagnosticStoreStateBySubject: (
            state: DiagnosticsStoreState,
            action: PayloadAction<{
                subject: string;
                teamId: number;
            }>
        ) => {
            const { subject, teamId } = action.payload;

            if (!state[teamId]) {
                state[teamId] = {};
            }
            // @ts-ignore state[teamId] не undefined
            state[teamId][subject] = undefined;
        },

        clearDiagnosticStoreStateByTeamId: (
            state: DiagnosticsStoreState,
            action: PayloadAction<{
                teamId: number;
            }>
        ) => {
            const { teamId } = action.payload;

            if (!state[teamId]) {
                return;
            }
            Object.keys(state).forEach((key) => {
                // @ts-ignore state[teamId] не undefined
                state[teamId][key] = undefined;
            });
        },
    },
});

export const diagnosticsActions = slice.actions;
export const diagnosticsReducer = slice.reducer;
