import { compose, composeU } from '@bem-react/core';
import { UserPic as UserPicBase, withSizeM, withSizeS } from '@yandex-lego/components/UserPic';
import cx from 'classnames';
import React from 'react';

import { BearWithPlus } from './_bear/bear-with-plus';
import { BearWithoutPlus } from './_bear/bear-without-plus';
import { useSafeStroke } from './_hook/useSafeStroke';

import './user-pic.scss';

const UserPicComponent = compose(composeU(withSizeS, withSizeM))(UserPicBase);
type UserPicComponentProps = React.ComponentProps<typeof UserPicComponent>;
type UserPic = Omit<UserPicComponentProps, 'addonAfter'> &
    (
        | {
              child?: true;
          }
        | {
              child?: false;
              addonAfter: UserPicComponentProps['addonAfter'];
          }
    );

export const UserPic = (props: UserPic) => {
    const safeStrokeProps = useSafeStroke();

    if (!props.child) {
        return <UserPicComponent {...props} />;
    }

    const { plus, ...passProps } = props;
    const BearComponent = plus ? BearWithPlus : BearWithoutPlus;
    return (
        <UserPicComponent
            addonAfter={<BearComponent className={cx('user-pic__bear')} {...safeStrokeProps} />}
            {...passProps}
        />
    );
};
