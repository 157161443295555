import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import b_ from 'b_';
import { Button, SimpleText, SimpleCallback } from 'platform-components';
import { UaTraits } from 'platform-components/src/typings/common';
import { withUatraits } from 'platform-components/hocs';
import React, { ReactInstance } from 'react';
import ReactDOM from 'react-dom';
import { injectIntl, WrappedComponentProps } from 'react-intl';

// @ts-ignore
import FormView from 'common.components/form-view/form-view';
import PlaceholderTextarea, { VALID_OPTIONS } from 'common.components/placeholder-textarea/placeholder-textarea';

// @ts-ignore
import HeadedFormViewCard from '../headed-form-view-card';

// @ts-ignore
import { PLACEHOLDERS_COUNT, DEFAULT_ROWS_COUNT, MOBILE_DEFAULT_ROWS_COUNT } from './students-form.constants';
// @ts-ignore
import { getValidationMessage, getPrioritizedError } from './utils';

import './students-form.scss';

interface StudentWithValidation {
    name: string;
    valid: VALID_OPTIONS;
}

interface HOCProps extends WrappedComponentProps {
    uatraits: UaTraits;
}

interface OwnProps {
    onChange?: (students: Array<string>) => void;
    onSkip?: SimpleCallback;
    onSubmit: (students: Array<string>) => void;
    students?: Array<string>;
    loadingStatus: LOADING_STATUSES;
    shouldShowSkipControl?: boolean;
}

interface Props extends HOCProps, OwnProps {}

interface DefaultProps {
    students: Array<string>;
}

type InnerProps = DefaultProps & Props;

interface State {
    students: Array<StudentWithValidation>;
    scrolledToBottom: boolean;
    scrolledToTop: boolean;
}

const b = b_.with('students-form');

export class StudentsForm extends React.Component<Props, State> {
    static displayName = 'StudentsForm';

    static defaultProps: DefaultProps = {
        students: [],
    };

    constructor(props: InnerProps) {
        super(props);

        this.state = {
            students: this.getValidatedStudentsData(props.students),
            scrolledToBottom: false,
            scrolledToTop: false,
        };
    }

    componentDidMount() {
        if (this.formRef) {
            this.formRef.scroll(0, 0);
        }
    }

    componentWillUnmount() {
        if (this.formRef) {
            this.formRef.removeEventListener('scroll', this.updateScrollPosition, {
                passive: true,
            } as EventListenerOptions);
        }
    }

    formRef: HTMLElement | null = null;

    getDefaultRowCount = () => {
        const {
            uatraits: { isMobile },
        } = this.props;

        return isMobile ? MOBILE_DEFAULT_ROWS_COUNT : DEFAULT_ROWS_COUNT;
    };

    saveStudents = () => {
        const { onChange } = this.props;
        const { students } = this.state;

        if (onChange) {
            onChange(students.map((student) => student.name));
        }
    };

    getValidatedStudentsData = (students: Array<string>): Array<StudentWithValidation> => {
        const defaultRowsCount = this.getDefaultRowCount();
        const lastStudent = students[students.length - 1];
        const isLastStudentEmpty = !(lastStudent && lastStudent.trim().length > 0);
        const emptyRowsCount = Math.max(defaultRowsCount - students.length, isLastStudentEmpty ? 0 : 1);
        const items = [...students, ...Array<string>(emptyRowsCount).fill('')];

        return items.map((student) => {
            const error = getValidationMessage(student);

            return {
                name: student,
                valid: error ? VALID_OPTIONS.NO : VALID_OPTIONS.UNCERTAIN,
            };
        });
    };

    isFormValid = () => {
        const { students } = this.state;

        const prioritizedError = students.reduce((result, student) => {
            const error = getValidationMessage(student.name);

            if (error) {
                result = getPrioritizedError(error, result);
            }

            return result;
        }, '');
        const hasNonEmptyStudents = students.some((student) => student.name !== '');
        const isFormValid = hasNonEmptyStudents && prioritizedError === '';

        return { isFormValid, errorText: prioritizedError };
    };

    handleChangeTextarea = (students: Array<string>) => {
        this.changeStudents(students);
    };

    changeStudents = (students: Array<string>) => {
        const { onChange } = this.props;

        if (onChange) {
            onChange(this.getValidatedStudentsData(students).map((student) => student.name));
        }

        this.setState({ students: this.getValidatedStudentsData(students) });
    };

    setCardRef = (ref: ReactInstance) => {
        if (ref !== null) {
            const node = ReactDOM.findDOMNode(ref);

            if (node instanceof HTMLElement) {
                this.formRef = node;
                this.formRef.addEventListener('scroll', this.updateScrollPosition, { passive: true });
            }
        } else if (this.formRef) {
            this.formRef.removeEventListener('scroll', this.updateScrollPosition, {
                passive: true,
            } as EventListenerOptions);
        }
    };

    handleSubmit = () => {
        const { onSubmit } = this.props;
        const { students } = this.state;

        onSubmit(students.map((student) => student.name));
    };

    handleSkip = () => {
        const { onSkip } = this.props;

        this.changeStudents([]);

        if (onSkip) {
            onSkip();
        }
    };

    updateScrollPosition = () => {
        if (this.formRef) {
            const scrolledToBottom = this.formRef.scrollTop === this.formRef.scrollHeight - this.formRef.offsetHeight;
            const scrolledToTop = this.formRef.scrollTop === 0;

            this.setState({ scrolledToBottom, scrolledToTop });
        }
    };

    render() {
        const { intl, loadingStatus, shouldShowSkipControl } = this.props;
        const { students, scrolledToBottom, scrolledToTop } = this.state;
        const isLoading = loadingStatus === LOADING_STATUSES.LOADING;
        const { errorText, isFormValid } = this.isFormValid();
        const defaultRowsCount = this.getDefaultRowCount();

        return (
            <React.Fragment>
                <HeadedFormViewCard
                    descriptionId="addClassForm.description.students"
                    headerId="addClassForm.header.students"
                    mix={b('form')}
                    ref={this.setCardRef}
                    theme={HeadedFormViewCard.THEMES.CLEAR}
                >
                    <div className={b('students')}>
                        <FormView.CardSection mix={b('students-list')}>
                            <PlaceholderTextarea
                                autofocus
                                errorText={errorText}
                                items={students}
                                onBlur={this.saveStudents}
                                onTextareaChange={this.handleChangeTextarea}
                                placeholderCount={PLACEHOLDERS_COUNT}
                                placeholderText={intl.formatMessage({ id: 'studentsForm.textarea.placeholder' })}
                                rowCount={defaultRowsCount}
                            />
                        </FormView.CardSection>
                    </div>
                </HeadedFormViewCard>
                <div className={b('top-gradient', { scrolled: scrolledToTop })} />
                <FormView.CardSection mix={b('button-section')}>
                    <div className={b('bottom-gradient', { scrolled: scrolledToBottom })} />
                    <FormView.Button
                        disabled={!isFormValid}
                        mix={b('finish-button')}
                        onMouseDown={this.handleSubmit}
                        progress={isLoading}
                    >
                        <SimpleText id="studentsForm.button.add" />
                    </FormView.Button>
                    {shouldShowSkipControl && (
                        <Button className={b('skip-button')} onClick={this.handleSkip} view={Button.VIEW.SECONDARY}>
                            <SimpleText id="studentsForm.button.skip" />
                        </Button>
                    )}
                </FormView.CardSection>
            </React.Fragment>
        );
    }
}

export default injectIntl(withUatraits(StudentsForm));
