import { CourseSubscriptionsData, LessonSubscriptionsData } from '../../../typings';

import { getFetch } from '../fetch';
import { apiConfig } from '../config';

export interface GetLessonSubscriptionsParams {
    lesson_ids: Array<number>
}

export interface GetCLessonSubscriptionsParams {
    clesson_ids: Array<number>
}

// TODO: ?
// export type GetSubscriptionsParams = GetLessonSubscriptionsParams | GetCLessonSubscriptionsParams;
export type GetSubscriptionsParams = { lesson_ids: string } | { clesson_ids: string } | { course_ids: string };

export function getSubscriptions(
    params: GetSubscriptionsParams
): Promise<LessonSubscriptionsData | CourseSubscriptionsData> {
    const { apiGetSubscriptions } = apiConfig.getStaticUrls();

    return getFetch(apiGetSubscriptions, params);
}
