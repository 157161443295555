export enum CUSTOM_FLOW_LESSON_VERSIONS {
    SKILLS = 'skills',
}

export enum CUSTOM_FLOW_LESSON_APPEARANCE {
    SKILLS = 'skills',
}

export interface CustomFlowLessonAppearance {
    version: CUSTOM_FLOW_LESSON_APPEARANCE;
}

export interface CustomFlowLessonStructure {
    version: CUSTOM_FLOW_LESSON_VERSIONS;
}
