import { PayloadAction } from '@reduxjs/toolkit';
import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import { CLesson } from '@yandex-int/k-common/typings';

import { LessonStore } from '../typings';

interface GetClessonWithResultsRequest {
    courseId: number;
    clessonId: number;
    onSuccess?: (clesson: { clesson: CLesson }) => void;
}

export const getClessonWithResultsRequest = (
    state: LessonStore,
    // Параметры нужны для саги
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    action: PayloadAction<GetClessonWithResultsRequest>
) => {
    state.lessonLoadingStatus = LOADING_STATUSES.LOADING;
};

export const getClessonWithResultsSuccess = (state: LessonStore) => {
    state.lessonLoadingStatus = LOADING_STATUSES.SUCCESS;
};

export const getClessonWithResultsError = (state: LessonStore) => {
    state.lessonLoadingStatus = LOADING_STATUSES.ERROR;
};
