import { getFetch } from '../fetch';
import { apiConfig } from '../config';

import { CourseTemplate } from '../../../typings';

export interface CoursesTemplatesRequest {
    classId?: number | null;
    grade?: number | null;
    show_autoassigned?: boolean;
}

export function getCoursesTemplates(requestData: CoursesTemplatesRequest): Promise<Array<CourseTemplate>> {
    const { classId, grade, show_autoassigned = false } = requestData;
    const { apiGetCoursesTemplates } = apiConfig.getStaticUrls();

    return getFetch(apiGetCoursesTemplates, {
        team_id: classId,
        grade,
        show_autoassigned,
    });
}
