import cx from 'classnames';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import React from 'react';

import { reportMessage } from '../../utils/raven-message';

import Audio from './__audio/resource__audio';
import Document from './__document/resource__document';
import ZoomableImage from './__image/resource__zoomable-image';
import Video from './__video/resource__video';

const TYPES = {
    AUDIO: ['webm', 'mp3', 'm4a', 'ogg'],
    DOCUMENT: ['pdf'],
    IMAGE: ['jpg', 'jpeg', 'png', 'svg', 'gif'],
    VIDEO: ['mp4'],
};
const COMPONENT_FOR_TYPE = {
    AUDIO: Audio,
    DOCUMENT: Document,
    IMAGE: ZoomableImage,
    VIDEO: Video,
};

class Resource extends React.Component {
    static propTypes = {
        file: PropTypes.string.isRequired,
        mix: PropTypes.string,
        type: PropTypes.string.isRequired,
        mdState: PropTypes.shape({
            showImagePreview: PropTypes.bool,
        }),
    };

    /**
     * TODO: remove if no errors
     */
    componentDidCatch(error, info) {
        reportMessage('Resource.componentDidCatch', {
            error,
            stack: info.componentStack,
        });
    }

    render() {
        const type = Object.keys(TYPES).find((t) => TYPES[t].includes(this.props.type));

        if (!type) {
            return null;
        }

        const ResourceElement = COMPONENT_FOR_TYPE[type];
        const props = omit(this.props, ['date_updated', 'id', 'mdState']);
        const className = cx('resource', this.props.mix);
        const showImagePreview = type === 'IMAGE' ? this.props.mdState?.showImagePreview : undefined;

        return (
            <ResourceElement
                {...props}
                className={className}
                showImagePreview={showImagePreview}
                src={this.props.file}
            />
        );
    }
}

export default Resource;
