export const BIND_CHILD_BY_STUDENT_CODE_REQUEST = 'CHILD_MANAGMENT.BIND_CHILD_BY_STUDENT_CODE_REQUEST';
export const BIND_CHILD_BY_STUDENT_CODE_SUCCESS = 'CHILD_MANAGMENT.BIND_CHILD_BY_STUDENT_CODE_SUCCESS';
export const BIND_CHILD_BY_STUDENT_CODE_ERROR = 'CHILD_MANAGMENT.BIND_CHILD_BY_STUDENT_CODE_ERROR';

export const BIND_CHILD_BY_LOGIN_REQUEST = 'CHILD_MANAGMENT.BIND_CHILD_BY_LOGIN_REQUEST';
export const BIND_CHILD_BY_LOGIN_SUCCESS = 'CHILD_MANAGMENT.BIND_CHILD_BY_LOGIN_SUCCESS';
export const BIND_CHILD_BY_LOGIN_ERROR = 'CHILD_MANAGMENT.BIND_CHILD_BY_LOGIN_ERROR';

export const SET_STUDENT_CODE = 'CHILD_MANAGMENT.SET_STUDENT_CODE';
export const SET_LOGIN = 'CHILD_MANAGMENT.SET_LOGIN';
export const SET_SCHOOL_CODE = 'CHILD_MANAGMENT.SET_SCHOOL_CODE';
export const SET_PASSWORD_LENGTH = 'CHILD_MANAGMENT.SET_PASSWORD_LENGTH';

export const ADD_COURSES_REQUEST = 'CHILD_MANAGMENT.ADD_COURSES_REQUEST';

export const CLEAR_FORM = 'CHILD_MANAGEMENT.CLEAR_FORM';

export const LOGIN_LENGTH = 11;
export const SCHOOL_CODE_LENGTH = 6;
export const PARENT_CODE_LENGTH = 9;
export const WRONG_AUTH_CODE = 404;
export const ERROR_TYPES = {
    WRONG_SCHOOL_CODE: 'school_code',
    WRONG_LOGIN: 'login',
} as const;

export const VERIFY_SCHOOL_CODE_REQUEST = 'CHILD_MANAGMENT.VERIFY_SCHOOL_CODE_REQUEST';
export const VERIFY_SCHOOL_CODE_SUCCESS = 'CHILD_MANAGMENT.VERIFY_SCHOOL_CODE_SUCCESS';
export const VERIFY_SCHOOL_CODE_ERROR = 'CHILD_MANAGMENT.VERIFY_SCHOOL_CODE_ERROR';
