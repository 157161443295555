export type ParamConfig = Record<string, { urls: boolean | string[] | string }>;

/** фильтрует квери параметры, оставляя только разрешенные для проброса на выбранный путь или на все пути */
export const getDrilledQueryParams = ({
    search,
    path,
    config,
}: {
    search: string;
    path?: string;
    config: ParamConfig;
}) => {
    const queryParams = new URLSearchParams(search);

    [...queryParams.entries()].forEach(([paramName]) => {
        const keyOptions = config[paramName];
        const isParamDrilled =
            keyOptions &&
            (keyOptions.urls === true ||
                keyOptions.urls === path ||
                (Array.isArray(keyOptions?.urls) && path && keyOptions.urls.includes(path)));

        if (!isParamDrilled) {
            queryParams.delete(paramName);
        }
    });
    return queryParams;
};
