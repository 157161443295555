import PropTypes from 'prop-types';
import React from 'react';

import './select-suggest__error-message-default-renderer.scss';

const ErrorMessageDefaultRenderer = ({ message }) => {
    return <div className="select-suggest__error-message-default-renderer">{message}</div>;
};

ErrorMessageDefaultRenderer.propTypes = {
    message: PropTypes.string.isRequired,
};

export default ErrorMessageDefaultRenderer;
