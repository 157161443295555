import { apiConfig } from '@yandex-int/k-common/client/api/config';
import get from 'lodash/get';
import { SUBJECTS_SLUGS_TYPE } from 'platform-components';
import { getExactPathMatch } from 'platform-components/utils';

import { getPathName } from 'common.components/pathname/selectors';

export function getParams(): Record<string, string> | null {
    const pathname = getPathName();

    if (pathname === null) {
        return null;
    }

    const urls = apiConfig.getStaticUrls();
    const match = getExactPathMatch(urls, pathname);

    return match?.params || null;
}

export const getParameter = (parameterName: string): string | null => {
    const params = getParams();

    if (!params) {
        return null;
    }

    return get(params, parameterName, null);
};

export const getTeamIdFromPath = (): number | null => {
    return Number(getParameter('classId')) || null;
};

export const getChildIdFromPath = (): number | null => {
    return Number(getParameter('childId')) || null;
};

export const getSubjectFromPath = (): SUBJECTS_SLUGS_TYPE | null => {
    return getParameter('subject') || null;
};

export const getSpecCourseLessonIdFromPath = (): string | null => {
    return getParameter('clessonId') || null;
};

export const getContainerSlugFromPath = (): string | null => {
    return getParameter('containerSlug') || null;
};
