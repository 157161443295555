import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const slice = createSlice({
    name: 'PIXELS',
    initialState: {},
    reducers: {
        // экшны нужны для саги
        /* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function */
        trackPageView: (state, action: PayloadAction<{ id: string }>) => {},
        trackAllPixelsPageView: (state, action: PayloadAction) => {},
        trackButtonClick: (state, action: PayloadAction<{ id: string; goal: string }>) => {},
        trackAllPixelsButtonClick: (state, action: PayloadAction<{ goal: string }>) => {},
        /* eslint-enable @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function */
    },
});

export const pixelsActions = slice.actions;
export const pixelsReducer = slice.reducer;
