/* eslint-disable react/no-multi-comp */
import React from 'react';
import { FormattedMessage } from 'react-intl';

const htmlConfig = {
    br: () => <br />,
    li: (chunks: string) => <li>{chunks}</li>,
    ol: (chunks: string) => <ol>{chunks}</ol>,
    span: (chunks: string) => <span>{chunks}</span>,
    b: (chunks: string) => <b>{chunks}</b>,
    a: (chunks: string) => <a>{chunks}</a>,
    ul: (chunks: string) => <ul>{chunks}</ul>,
    u: (chunks: string) => <u>{chunks}</u>,
    p: (chunks: string) => <p>{chunks}</p>,
};

type Props = React.ComponentProps<typeof FormattedMessage>;

export const FormattedHTMLMessage = ({ values, ...props }: Props) => {
    const newValues = values ? { ...htmlConfig, ...values } : htmlConfig;

    //@ts-ignore - EDUCATION-37077: [react-update]: fixme typings: Хочет ReactNode вместo JSX.Element
    return <FormattedMessage {...props} values={newValues} />;
};
