import { Me } from '@yandex-int/k-common/typings';
import { getMe } from 'platform-components/src/components/user/selectors';
import { withRouterPath } from 'platform-components/hocs';
import { connect } from 'react-redux';

import { getTeacherProfile } from 'common.components/user/selectors';
import * as externalLmsSelectors from 'store/external-lms-connect/selectors';
import { ExternalLmsConnectStore } from 'store/external-lms-connect/typings';

import { ConnectExternalButtonTypeDnevnikru } from './connect-external-button_type_dnevnikru';
import { ConnectExternalButtonTypeMes } from './connect-external-button_type_mes';
import { ConnectExternalButtonTypeTeacherCode } from './connect-external-button_type_teacher-code';

interface StoreType {
    externalLmsConnect: ExternalLmsConnectStore;
    me: Me | null;
}

const mapStateToProps = <T extends StoreType>(storeState: T) => ({
    userExternalLmsList: externalLmsSelectors.getUserExternalLmsList(storeState),
    me: getMe(storeState),
    teacherProfile: getTeacherProfile(storeState),
});

const ConnectDnevnikruButtonWrapped = connect(mapStateToProps)(ConnectExternalButtonTypeDnevnikru);
ConnectDnevnikruButtonWrapped.displayName = 'ConnectExternalButtonTypeDnevnikru';
export const ConnectDnevnikruButton = ConnectDnevnikruButtonWrapped;

const ConnectMesButtonWrapped = withRouterPath(connect(mapStateToProps)(ConnectExternalButtonTypeMes));
ConnectMesButtonWrapped.displayName = 'ConnectExternalButtonTypeMes';
export const ConnectMesButton = ConnectMesButtonWrapped;

const ConnectTeacherCodeButtonWrapped = connect(mapStateToProps)(ConnectExternalButtonTypeTeacherCode);
ConnectTeacherCodeButtonWrapped.displayName = 'ConnectExternalButtonTypeTeacherCode';
export const ConnectTeacherCodeButton = ConnectTeacherCodeButtonWrapped;
