/* eslint-disable react/no-multi-comp */
import b from 'b_';
import cx from 'classnames';
import { Pause, Play } from 'education-icons';
import React, { useCallback, useRef, useState } from 'react';
import { useAudio } from 'react-use';
import { useIntl } from 'react-intl';

import { isTouchDevice } from '../../../utils/device';
import { useProblemAnalyticsContext } from '../../problem/analytics-context';
import ProgressCircle from '../../progress-circle/progress-circle';

import { useAudioPreloadContext } from './audio-preload-context';
import useSoundSeek from './useSoundSeek';
import useStopOtherSounds from './useStopOtherSounds';

import './resource__audio.scss';

interface WithPreventDefault {
    preventDefault: () => void;
}

enum SOUND_STATUS {
    LOADING = 'loading',
    ERROR = 'error',
    IDLE = 'idle',
    PLAYING = 'playing',
    PAUSED = 'paused',
}

interface Props {
    file: string;
    type: string;
    name: string;
    className?: string;
}

// Пока загружается, показываем заполненный на четверть сектор
const LOADING_PROGRESS = 1 / 4;

export default function Audio(props: Props): React.ReactNode {
    const { className, name, file } = props;

    const [status, setStatus] = useState(SOUND_STATUS.IDLE);
    const canPlay = useRef(false);
    const preload = useAudioPreloadContext();
    const { ymOnAudioPlay } = useProblemAnalyticsContext();

    const intl = useIntl();

    const [audio, audioState, controls, audioRef] = useAudio({
        src: file,
        className: 'resource__audio-element',
        preload: preload ? 'auto' : 'none',
        onPlay: () => setStatus(canPlay.current ? SOUND_STATUS.PLAYING : SOUND_STATUS.LOADING),
        onPlaying: () => setStatus(SOUND_STATUS.PLAYING),
        onEnded: () => setStatus(SOUND_STATUS.IDLE),
        onError: () => setStatus(SOUND_STATUS.ERROR),
        onCanPlay: () => {
            canPlay.current = true;
        },
    });

    const stopOthers = useStopOtherSounds(() => {
        controls.pause();
        controls.seek(0);
        setStatus(SOUND_STATUS.IDLE);
    });

    const handlePlayButtonClick = useCallback(
        (e: WithPreventDefault) => {
            e.preventDefault();

            if (audioState.paused) {
                controls.play();
                stopOthers();
                ymOnAudioPlay();
            } else {
                controls.pause();
                setStatus(SOUND_STATUS.PAUSED);
            }
        },
        [audioState.paused, controls, stopOthers, ymOnAudioPlay]
    );

    const handlers = isTouchDevice ? { onTouchStart: handlePlayButtonClick } : { onMouseDown: handlePlayButtonClick };

    return (
        <div className={cx(className, b('resource__audio', { status }))}>
            {audio}
            <button
                aria-label={intl.formatMessage({ id: 'a11y.resourse.audio' })}
                className="resource__audio-button"
                onTouchStart={handlePlayButtonClick}
                type="button"
                {...handlers}
            >
                <Play className={b('resource__audio-button-icon', { type: 'play' })} />
                <Pause className={b('resource__audio-button-icon', { type: 'pause' })} />
                {status === SOUND_STATUS.LOADING ? (
                    <AudioProgress
                        mix={b('resource__audio-progress', { animation: 'loading' })}
                        progress={LOADING_PROGRESS}
                    />
                ) : (
                    <AnimatedProgress
                        audioRef={audioRef}
                        duration={audioState.duration}
                        enabled={status === SOUND_STATUS.PLAYING}
                    />
                )}
            </button>
            {name && <div className="resource__audio-name">{name}</div>}
        </div>
    );
}

interface AudioProgressProps {
    progress: number;
    mix?: string;
}

const AudioProgress = ({ progress, mix }: AudioProgressProps) => {
    return <ProgressCircle max={1} mix={mix} showTitle={false} size={36} strokeWidth={3} value={progress} />;
};

interface AnimatedProgressProps {
    audioRef: React.RefObject<HTMLAudioElement>;
    duration: number;
    enabled: boolean;
}

const AnimatedProgress = ({ audioRef, duration, enabled }: AnimatedProgressProps) => {
    const seek = useSoundSeek(audioRef, enabled);

    return <AudioProgress mix="resource__audio-progress" progress={seek / duration} />;
};
