import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
// @ts-ignore
import { SUBJECTS_SLUGS } from '@yandex-int/k-common/constants';
import { LessonProblem, Stack } from '@yandex-int/k-common/typings';
import { createSelector } from 'reselect';

import { getStudents } from 'common.components/classes/selectors';
import * as copyLessonSelectors from 'common.components/copy-lesson-modal/selectors';
import { getCurrentYearCourseId } from 'common.components/course/selectors';
import { PROBLEM_SIDE_PANEL_MODE } from 'common.components/lesson-view.common/constants';
import * as lessonSelectors from 'common.components/lesson/selectors';
import { getLessonCourseId } from 'common.components/lesson/selectors';
import { isLessonCompleted, isLessonInProgress } from 'common.components/lesson/utils';
import { Student } from 'common.components/typings';
import * as userSelectors from 'common.components/user/selectors';
import { getPrepLessonDetails, getPrepLessonsDetailsLoadingStatus } from 'store/prep-lessons/selectors';
import { PrepLessonDetailsClientSide } from 'store/prep-lessons/typings';
import { getSelectedProblemId } from 'store/problems-view/selectors';
import { isDictation, isOpenAnswer, isTheory } from 'store/problems/utils';
import { getSubjectsWithStacks } from 'store/subject-config/selectors';
import { getIsTeacherFromMes } from 'utils/external-lms/external-lms';
import { getSearchFromProps, getSubjectFromProps } from 'utils/selectors/selectors';

import { LessonViewStore } from './interfaces';
import { LESSON_CONTENT_MODE, LESSON_MODAL_TABS, LESSON_VIEW_MODE } from './typings';

const lessonViewStateSelector = (storeState: any): LessonViewStore | undefined => storeState.lessonView;

export const getShouldShowModal = createSelector(
    [lessonViewStateSelector],
    (state: LessonViewStore): boolean => state.shouldShowModal
);

export const getSelectedStudentId = createSelector([lessonViewStateSelector], (state): number | null =>
    state ? state.selectedStudentId : null
);

export const getSelectedStudent = createSelector(
    [getStudents, getSelectedStudentId],
    (students, selectedStudentId): Student | null => {
        if (!selectedStudentId) {
            return null;
        }

        return students?.find((student) => student.id === selectedStudentId) || null;
    }
);

export const getShouldShowAssignmentSettings = createSelector(
    [lessonViewStateSelector],
    (state: LessonViewStore): boolean => state.shouldShowAssignmentSettings
);

export const getSelectedTab = createSelector(
    [lessonViewStateSelector],
    (state: LessonViewStore): LESSON_MODAL_TABS | null => state.selectedTab
);

export const getCanAddProblemToLesson = createSelector(
    [getSubjectFromProps, getSubjectsWithStacks],
    (subject: SUBJECTS_SLUGS | null, subjectsWithStacks): boolean =>
        subject ? subjectsWithStacks.includes(subject) : false
);

export const getSelectedProblemLink = createSelector(
    [lessonSelectors.getLessonProblems, getSelectedProblemId],
    (problemLinks, problemId: number): LessonProblem | null => {
        const selectedProblemLink = problemLinks.find((problemLink) => problemLink.problem?.id === problemId);

        return selectedProblemLink || null;
    }
);

export const getIsTheoryProblemSelected = createSelector(
    [getSelectedProblemLink],
    (selectedProblem: LessonProblem | null): boolean => {
        return isTheory(selectedProblem?.problem || null);
    }
);

export const getIsOpenAnswerProblemSelected = createSelector(
    [getSelectedProblemLink],
    (selectedProblem: LessonProblem | null): boolean => {
        if (selectedProblem?.problem) {
            return isOpenAnswer(selectedProblem?.problem || null);
        }
        return false;
    }
);

export const getSelectedProblemLinkId = createSelector(
    [getSelectedProblemLink],
    (problemLink: LessonProblem | null): number | null => problemLink?.id || null
);

export const getProblemStackId = createSelector(
    [getSelectedProblemLink],
    (problemLink: LessonProblem | null): number | null => {
        if (!problemLink) {
            return null;
        }

        return problemLink.from_theme;
    }
);

export const getLessonContentMode = createSelector(
    [
        lessonSelectors.getLessonId,
        lessonSelectors.getLessonStatus,
        getSelectedProblemId,
        getSelectedStudentId,
        lessonSelectors.getIsEmptyLesson,
        copyLessonSelectors.getCopyStatus,
        lessonSelectors.getIsStream,
        lessonSelectors.getCustomLessonViewSettings,
        userSelectors.getIsInCustomLessonExperiment,
    ],
    // eslint-disable-next-line complexity
    (
        lessonId,
        status,
        selectedProblemId,
        selectedStudentId,
        isEmptyLesson,
        copyStatus,
        isStream,
        lessonViewSettings,
        isInCustomLessonExperiment
        // eslint-disable-next-line max-params
    ): LESSON_CONTENT_MODE => {
        const isLessonReadonly = status && (isLessonCompleted(status) || isLessonInProgress(status));

        if (isStream) {
            return LESSON_CONTENT_MODE.STREAM;
        }

        if (lessonViewSettings && selectedProblemId) {
            return LESSON_CONTENT_MODE.CUSTOM_PROBLEM_VIEW;
        }

        if (selectedStudentId) {
            return LESSON_CONTENT_MODE.STUDENT_RESULTS;
        }

        // так как эксперимент обратный, то все кто в него попал как раз не должны видеть модалку с хгз
        if (lessonViewSettings && !isInCustomLessonExperiment) {
            if (isLessonReadonly) {
                return LESSON_CONTENT_MODE.CUSTOM_READONLY_VIEW;
            }
            return LESSON_CONTENT_MODE.CUSTOM_VIEW;
        }

        if (copyStatus === LOADING_STATUSES.LOADING) {
            return LESSON_CONTENT_MODE.COPY;
        }

        if (!lessonId) {
            return LESSON_CONTENT_MODE.LOADING;
        }

        if (selectedProblemId) {
            return LESSON_CONTENT_MODE.PROBLEM_VIEW;
        }

        if (isLessonReadonly) {
            return LESSON_CONTENT_MODE.READONLY;
        }

        if (isEmptyLesson) {
            return LESSON_CONTENT_MODE.EDIT_EMPTY;
        }

        return LESSON_CONTENT_MODE.EDIT;
    }
);

export const getProblemStacksRequestStatus = createSelector(
    [lessonViewStateSelector],
    (state: LessonViewStore): LOADING_STATUSES => state.problemStacksRequestStatus
);

export const getProblemStacks = createSelector(
    [lessonViewStateSelector],
    (state: LessonViewStore): Array<Stack> => state.problemStacks
);

export const getProblemSidePanelMode = createSelector(
    [lessonViewStateSelector],
    (state: LessonViewStore): PROBLEM_SIDE_PANEL_MODE => state.problemSidePanelMode
);

export const getHasAnyDictationProblem = createSelector(
    [lessonSelectors.getLessonProblems],
    (problems: Array<LessonProblem>): boolean => {
        return problems.some((problemLink) => (problemLink.problem ? isDictation(problemLink.problem) : false));
    }
);

export const getShouldShowSubscriptionModal = createSelector(
    [
        lessonSelectors.getLessonInactiveSubscriptionSlug,
        lessonSelectors.getLessonActiveSubscriptionSlug,
        userSelectors.getIsInSubscriptionsExperiment,
    ],
    (inactiveSubscription, activeSubscription, isInExperiment): boolean =>
        // обратная логика эксперимента - экспериментальной группе не показываем подписки
        Boolean(inactiveSubscription) && !activeSubscription && !isInExperiment
);

export const getMode = createSelector(
    [lessonViewStateSelector],
    (state: LessonViewStore): LESSON_VIEW_MODE => state.mode
);

export const getIsAfterCopyingFromOwn = createSelector(
    [getMode],
    (mode): boolean => mode === LESSON_VIEW_MODE.AFTER_COPYING_FROM_OWN
);

export const getIsAfterCopyingAsRNO = createSelector(
    [getMode],
    (mode): boolean => mode === LESSON_VIEW_MODE.AFTER_COPYING_AS_RNO
);

export const getIsAfterCopying = createSelector(
    [getIsAfterCopyingAsRNO, getIsAfterCopyingFromOwn],
    (isAfterCopyingAsRNO, isAfterCopyingFromOwn): boolean => isAfterCopyingAsRNO || isAfterCopyingFromOwn
);

export const getIsHeadless = createSelector(
    [lessonViewStateSelector],
    (state: LessonViewStore): boolean => state.isHeadless
);

export const getIsDisabledAssignment = createSelector(
    [lessonSelectors.getIsRepeatableLesson, getPrepLessonDetails],
    (isRepeatableLesson: boolean, prepLessonDetails?: PrepLessonDetailsClientSide): boolean => {
        let disabledAssignment = false;
        if (prepLessonDetails) {
            const { assignedCount } = prepLessonDetails;
            disabledAssignment = !isRepeatableLesson && assignedCount > 0;
        }
        return disabledAssignment;
    }
);

export const getShouldLoadPrepLessonDetails = createSelector([getPrepLessonDetails], (prepLessonDetails) => {
    let shouldLoadPrepLessonDetails = true;
    if (prepLessonDetails) {
        shouldLoadPrepLessonDetails = false;
    }

    return shouldLoadPrepLessonDetails;
});

export const getIsLoadingLessonViewData = createSelector(
    [lessonSelectors.getLessonLoadingStatus, getPrepLessonsDetailsLoadingStatus],
    (lessonLoadingStatus, prepLessonsDetailsLoadingStatus): boolean => {
        return (
            lessonLoadingStatus === LOADING_STATUSES.LOADING ||
            prepLessonsDetailsLoadingStatus === LOADING_STATUSES.LOADING
        );
    }
);

export const getShouldAssignInExternalLms = createSelector(
    [lessonViewStateSelector],
    (state): boolean => state!.shouldAssignInExternalLms
);

const isCopyingEnabledSelector = (storeState: any, ownProps: { isCopyingEnabled?: boolean }) =>
    ownProps.isCopyingEnabled;

export const getShouldShowCopyLessonControl = createSelector(
    [lessonSelectors.getCanCopyLesson, getSearchFromProps, getShouldAssignInExternalLms, isCopyingEnabledSelector],
    (canCopy, search, isBeingAssignedInExternalHomework, isCopyingEnabled) => {
        const isTeacherFromMes = getIsTeacherFromMes(search);
        return canCopy && Boolean(isCopyingEnabled) && !isTeacherFromMes && !isBeingAssignedInExternalHomework;
    }
);

export const getShouldShowCanAddLessonToCourse = createSelector(
    [
        lessonSelectors.getCanAddLessonToCourse,
        getSearchFromProps,
        getShouldAssignInExternalLms,
        isCopyingEnabledSelector,
    ],
    (canAddLessonToCourse, search, isBeingAssignedInExternalHomework, isCopyingEnabled) => {
        const isTeacherFromMes = getIsTeacherFromMes(search);
        return (
            canAddLessonToCourse && Boolean(isCopyingEnabled) && !isTeacherFromMes && !isBeingAssignedInExternalHomework
        );
    }
);

export const getLessonViewProblems = (storeState: any) => {
    const isCustomFlowLesson = lessonSelectors.getIsCustomFlowLesson(storeState);
    if (isCustomFlowLesson) {
        return lessonSelectors.getCustomFlowLessonProblems(storeState);
    }
    return lessonSelectors.getLessonProblems(storeState);
};

export const getLessonViewCourseId = createSelector(
    [getLessonCourseId, getCurrentYearCourseId, lessonViewStateSelector],
    (courseIdFromLesson, currentYearCourseId, lessonViewState): number | null => {
        return courseIdFromLesson || currentYearCourseId || lessonViewState!.lessonCourseId;
    }
);
