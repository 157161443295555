import React from 'react';
import getDisplayName from 'react-display-name';

import { useScriptLoader } from '../hooks/useScriptLoader';

/**
 * @param src
 * @param isShouldLoad - функция для проверки, что подключать скрипт уже не нужно. Например, если в window уже доступен объект нужной библиотеки
 */
export function withScript(src: string | string[], isShouldLoad?: () => boolean) {
    return function withScriptHoc<T>(WrappedComponent: React.ComponentType<T>) {
        const WithScript = (props: T) => {
            const { loaded } = useScriptLoader({ src, isShouldLoad });

            if (!loaded) {
                return null;
            }

            return <WrappedComponent {...(props as any)} />;
        };

        WithScript.displayName = `WithScript(${getDisplayName(WrappedComponent)})`;

        return WithScript;
    };
}
