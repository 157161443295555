export const CLEAR_STATE = 'SCHOOL_FORM.CLEAR_STATE';
export const SET_CURRENT_ADDRESS = 'SCHOOL_FORM.SET_ADDRESS';
export const SET_CURRENT_SCHOOL = 'SCHOOL_FORM.SET_SCHOOL';
export const SET_ADDRESSES = 'SCHOOL_FORM.SET_ADDRESSES';
export const SET_SCHOOLS = 'SCHOOL_FORM.SET_SCHOOLS';
export const GET_ADDRESS_SUGGESTS_REQUEST = 'SCHOOL_FORM.GET_ADDRESS_SUGGESTS_REQUEST';
export const GET_ADDRESS_SUGGESTS_SUCCESS = 'SCHOOL_FORM.GET_ADDRESS_SUGGESTS_SUCCESS';
export const GET_ADDRESS_SUGGESTS_ERROR = 'SCHOOL_FORM.GET_ADDRESS_SUGGESTS_ERROR';
export const GET_SCHOOL_SUGGESTS_REQUEST = 'SCHOOL_FORM.GET_SCHOOL_SUGGESTS_REQUEST';
export const GET_SCHOOL_SUGGESTS_SUCCESS = 'SCHOOL_FORM.GET_SCHOOL_SUGGESTS_SUCCESS';
export const GET_SCHOOL_SUGGESTS_ERROR = 'SCHOOL_FORM.GET_SCHOOL_SUGGESTS_ERROR';
export const SET_MODE = 'SCHOOL_FORM.SET_MODE';
