import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
// @ts-ignore
import { CLESSON_MODES, PROBLEM_TYPES } from '@yandex-int/k-common/constants';
import {
    AssignmentTable,
    CLesson,
    ClessonResults,
    FullCLesson,
    LessonProblem,
    LessonSubscription,
} from '@yandex-int/k-common/typings';
import { SUBJECTS_SLUGS_TYPE } from 'platform-components';
import { IntlShape } from 'react-intl';

import { v4 as uuid } from 'uuid';
import { LessonProblemToInsert, LessonStore } from '../typings';

import { finishAddingStudentsRequest, finishAddingStudentsSuccess, finishAddingStudentsError } from './addStudents';
import {
    getClessonWithResultsError,
    getClessonWithResultsRequest,
    getClessonWithResultsSuccess,
} from './getClessonWithResultsRequest';
import {
    getFullClessonError,
    getFullClessonLoading,
    getFullClessonRequest,
    getFullClessonSuccess,
} from './getFullClessonRequest';

const initialState: LessonStore = {
    clessonResults: null,
    lesson: null,
    subscriptions: null,
    lessonLoadingStatus: LOADING_STATUSES.UNSENT,
    lessonAssignsLoadingStatus: LOADING_STATUSES.UNSENT,
    assignmentTable: {},
    assignLessonStatus: LOADING_STATUSES.UNSENT,
    restoreClessonLoadingStatus: LOADING_STATUSES.UNSENT,
    addLessonToCourseStatus: LOADING_STATUSES.UNSENT,
    fromModuleId: null,
    createLessonStatus: LOADING_STATUSES.UNSENT,
    lessonSource: null,
    lessonUpdated: '',
};

interface GetLessonAssignsSuccess {
    assignmentTable: AssignmentTable;
}

interface AssignLessonError {
    error: any;
}

// Параметры нужны для саги
/* eslint-disable @typescript-eslint/no-unused-vars */
export const slice = createSlice({
    name: 'LESSON',
    initialState: initialState,
    reducers: {
        clearEditor: () => initialState,
        lessonUpdated: (state) => {
            //saga
            // записываем новые значение uuid для возможности ручного обновления интерфейса в компонентах
            state.lessonUpdated = uuid();
        },
        getClessonResultsRequest: (state, action: PayloadAction<{ cLessonId: number; courseId: number }>) => {
            // saga
        },
        setCLessonResults: (state, action: PayloadAction<{ results: ClessonResults }>) => {
            const { results } = action.payload;

            state.clessonResults = results;
        },
        setSubscriptions: (state, action: PayloadAction<{ subscriptions: Array<LessonSubscription> }>) => {
            const { subscriptions } = action.payload;

            state.subscriptions = subscriptions;
        },

        getClessonWithResultsRequest,
        getClessonWithResultsError,
        getClessonWithResultsSuccess,

        finishAddingStudentsRequest,
        finishAddingStudentsSuccess,
        finishAddingStudentsError,

        restoreCLessonRequest: (
            state,
            action: PayloadAction<{
                cLessonId: number;
                isDraft: boolean;
            }>
        ) => {
            state.restoreClessonLoadingStatus = LOADING_STATUSES.LOADING;
        },

        restoreCLessonSuccess: (
            state,
            action: PayloadAction<{
                cLessonId: number;
            }>
        ) => {
            state.restoreClessonLoadingStatus = LOADING_STATUSES.SUCCESS;
        },

        restoreCLessonError: (state) => {
            state.restoreClessonLoadingStatus = LOADING_STATUSES.ERROR;
        },

        getFullClessonRequest,
        getFullClessonLoading,
        getFullClessonError,
        getFullClessonSuccess,

        unpublishCLesson: () => {},

        copyCLesson: (
            state,
            action: PayloadAction<{
                sourceId: number;
                newName: string;
            }>
        ) => {
            // saga
        },

        insertProblems: (
            state,
            action: PayloadAction<{
                index?: number;
                problems: Array<LessonProblem | LessonProblemToInsert>;
            }>
        ) => {
            const { index = 0, problems } = action.payload;

            if (!state.lesson) {
                state.lesson = { problems: [] } as unknown as FullCLesson;
            }

            // удалять id надо обязательно, иначе бэк не сможет сохранить такой problem
            const newProblems = problems.map((p) => ({ ...p, id: undefined }) as unknown as LessonProblem);

            state.lesson!.problems.splice(index, 0, ...newProblems);
        },
        saveLesson: (
            state,
            action: PayloadAction<{ onSuccess?: (clessonId: number, clessonData: FullCLesson) => void } | undefined>
        ) => {
            // saga
        },

        getLessonAssigns: (
            state,
            action: PayloadAction<{
                courseId: number;
                cLessonId: number;
            }>
        ) => {
            // saga
        },

        getLessonAssignsRequest: (state) => {
            state.lessonAssignsLoadingStatus = LOADING_STATUSES.LOADING;
        },
        getLessonAssignsError: (state) => {
            state.lessonAssignsLoadingStatus = LOADING_STATUSES.ERROR;
        },
        getLessonAssignsSuccess: (state, action: PayloadAction<GetLessonAssignsSuccess>) => {
            const { assignmentTable } = action.payload;

            state.lessonAssignsLoadingStatus = LOADING_STATUSES.SUCCESS;
            state.assignmentTable = assignmentTable;
        },
        setName: (state, action: PayloadAction<{ lessonName: string }>) => {
            const { lessonName } = action.payload;

            if (state.lesson) {
                state.lesson.name = lessonName;
            }
        },
        setDescription: (state, action: PayloadAction<{ description: string | null }>) => {
            const { description } = action.payload;

            if (state.lesson) {
                state.lesson.description = description;
            }
        },

        setLessonMode: (state, action: PayloadAction<{ mode: CLESSON_MODES }>) => {
            const { mode } = action.payload;

            if (state.lesson) {
                if (!state.lesson.clesson) {
                    state.lesson.clesson = {} as CLesson;
                }

                state.lesson.clesson.mode = mode;
            }
        },
        setLesson: (state, action: PayloadAction<{ lesson: FullCLesson | null }>) => {
            const { lesson } = action.payload;

            state.lesson = lesson;
        },

        setDateAssignment: (state, action: PayloadAction<string | null>) => {
            if (state.lesson && state.lesson.clesson) {
                state.lesson.clesson.date_assignment = action.payload;
            }
        },

        setDateDeadline: (state, action: PayloadAction<string | null>) => {
            if (state.lesson && state.lesson.clesson) {
                state.lesson.clesson.date_deadline = action.payload;
            }
        },
        getLessonRequest: (
            state,
            action: PayloadAction<{
                lessonId: number;
                moduleId?: number | null;
            }>
        ) => {},
        getLessonLoading: (
            state,
            action: PayloadAction<{
                lessonId: number;
                moduleId?: number | null;
            }>
        ) => {
            const { moduleId } = action.payload;
            state.fromModuleId = moduleId || null;
            state.lessonLoadingStatus = LOADING_STATUSES.LOADING;
        },
        getLessonSuccess: (state) => {
            state.lessonLoadingStatus = LOADING_STATUSES.SUCCESS;
        },
        getLessonError: (state) => {
            state.lessonLoadingStatus = LOADING_STATUSES.ERROR;
        },

        setLessonAssignmentStarted: () => {},
        lessonAssignError: (state, action: PayloadAction<AssignLessonError>) => {},
        lessonAssignSuccess: () => {},

        removeProblemFromLesson: (
            state,
            action: PayloadAction<{
                problemId: number;
                stackId?: number;
                onSuccess?: (clessonId: number, isLastProblem: boolean) => void;
            }>
        ) => {
            const { problemId } = action.payload;

            const problemIndex = state.lesson!.problems.findIndex((p) => p.problem?.id === problemId);

            if (problemIndex !== -1) {
                state.lesson!.problems.splice(problemIndex, 1);
            }
        },

        removeAllStackProblemsFromLesson: (
            state,
            action: PayloadAction<{
                problemId: number;
                stackId?: number;
                onSuccess?: (clessonId: number, isLastProblem: boolean) => void;
            }>
        ) => {
            const { stackId } = action.payload;

            if (state.lesson) {
                const remainingProblems = state.lesson.problems.filter((p) => p.from_theme !== stackId);

                state.lesson.problems = remainingProblems;
            }
        },

        changeProblemVariant: (
            state,
            action: PayloadAction<{
                oldProblemId: number;
                newProblem: LessonProblem;
                stackId: number;
            }>
        ) => {
            const { oldProblemId, newProblem, stackId } = action.payload;
            const problemIndex = state.lesson!.problems.findIndex(
                (problemLink) => problemLink.problem?.id === oldProblemId
            );

            const newProblemLink = {
                type: PROBLEM_TYPES.PROBLEM,
                problem: newProblem.problem,
                from_theme: stackId,
            };

            state.lesson!.problems[problemIndex] = newProblemLink as unknown as LessonProblem;
        },

        moveProblems: (state, action: PayloadAction<{ oldIndex: number; newIndex: number }>) => {
            const { oldIndex, newIndex } = action.payload;

            if (state.lesson) {
                const [problem] = state.lesson.problems.splice(oldIndex, 1);
                state.lesson.problems.splice(newIndex, 0, problem);
            }
        },

        setAddLessonToCourseLoading: (state) => {
            state.addLessonToCourseStatus = LOADING_STATUSES.LOADING;
        },

        setAddLessonToCourseSuccess: (state) => {
            state.addLessonToCourseStatus = LOADING_STATUSES.SUCCESS;
        },

        saveOrCreateLesson: (
            state,
            action: PayloadAction<{
                intl: IntlShape;
                lessonName?: string;
                lessonMeta?: string;
                onSuccess: (clessonId: number, clessonResult: FullCLesson) => void;
            }>
        ) => {
            // saga
        },

        createLessonByProblemIds: (
            state,
            action: PayloadAction<{
                intl: IntlShape;
                problemIds: number[];
                lessonName?: string;
                lessonMeta?: string;
                onSuccess: (clessonId: number, clessonResult: FullCLesson) => void;
            }>
        ) => {
            // saga
        },

        createLessonRequest: (state) => {
            state.createLessonStatus = LOADING_STATUSES.LOADING;
        },

        createLessonSuccess: (state) => {
            state.createLessonStatus = LOADING_STATUSES.SUCCESS;
        },

        createLessonError: (state) => {
            state.createLessonStatus = LOADING_STATUSES.ERROR;
        },
        setLessonSource: (state, action: PayloadAction<{ source: string }>) => {
            const { source } = action.payload;

            state.lessonSource = source;
        },
        clearLessonSource: (state) => {
            state.lessonSource = null;
        },

        setSubject: (state, action: PayloadAction<{ subject: SUBJECTS_SLUGS_TYPE }>) => {
            const { subject } = action.payload;

            if (state.lesson) {
                state.lesson.subject = subject;
            }
        },
    },
});
/* eslint-enable @typescript-eslint/no-unused-vars */
