import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import { SimpleCallback } from 'platform-components';
import { getIsTeacher } from 'platform-components/src/components/user/selectors';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CodeForm } from 'common.components/code-form/code-form';
import { ERROR_MESSAGES } from 'common.components/code-form/constants';

import { teamBuilderActions } from '../actions';
import * as selectors from '../selectors';

import './team-builder__code.scss';

interface Props {
    isLoading: boolean;
    onNext: SimpleCallback;
    onAssign: SimpleCallback;
    onBack?: SimpleCallback;
    shouldSubmit?: boolean;
    submit?: SimpleCallback;
}

export const TeamBuilderCode = (props: Props) => {
    const { onAssign, onNext, onBack, isLoading, shouldSubmit, submit } = props;
    const dispatch = useDispatch();
    const code = useSelector(selectors.getCode);
    const codeSubmittingStatus = useSelector(selectors.getCodeSubmittingStatus);
    const isTeacher = useSelector(getIsTeacher);
    const [canSend, setCanSend] = useState(true);
    const [error, setError] = useState<ERROR_MESSAGES | null>(null);

    useEffect(
        function handleCodeError() {
            if (codeSubmittingStatus === LOADING_STATUSES.ERROR) {
                setError(ERROR_MESSAGES.NOT_FOUND);
            }
        },
        [codeSubmittingStatus]
    );

    const handleChangeCode = (newCode: string) => {
        dispatch(teamBuilderActions.setCode({ code: newCode }));
        setError(null);
        onAssign();
    };

    const handleSubmit = () => {
        if (!code) {
            setError(ERROR_MESSAGES.REQUIRED);

            return;
        }

        // отправляем один запрос на одну отправку формы (и шаг TEAM_BUILDER_STEPS.SUBMIT_CODE)
        // иначе будут отправляться повторные запросы при перерендере из-за изменения codeSubmittingStatus на ERROR
        setCanSend(true);
        onNext();
    };

    const handleGoBack = () => {
        if (onBack) {
            onBack();
        }
    };

    useEffect(() => {
        if (shouldSubmit && submit && canSend) {
            submit();
            setCanSend(false);
        }
    }, [canSend, shouldSubmit, submit]);

    return (
        <div className="team-builder__code">
            <CodeForm
                code={code}
                error={error}
                isSubmitting={isLoading}
                onChange={handleChangeCode}
                onGoBack={handleGoBack}
                onSubmit={handleSubmit}
                shouldShowPersonalDataLink={!isTeacher}
            />
        </div>
    );
};
