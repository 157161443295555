import b from 'b_';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './icon__pencil.scss';

const IconPencil = ({ disabled, mix }) => {
    const className = b('icon', 'pencil', { disabled });

    return (
        /* eslint-disable max-len */
        <svg className={cx(className, mix)} height="24" width="24">
            <path
                d="M13.576 8.283l2.025 2.025-5.576 5.62H8v-2.026l5.576-5.619zm.704-.71L15.841 6l2.025 2.025-1.56 1.573-2.026-2.025z"
                fillRule="evenodd"
            />
        </svg>
        /* eslint-enable max-len */
    );
};

IconPencil.propTypes = {
    disabled: PropTypes.bool,
    mix: PropTypes.string,
};

export default IconPencil;
