import { select, takeLatest, all, put } from 'redux-saga/effects';

import { pixelsActions } from './actions';
import { BUTTON_CLICK_MESSAGE, PAGE_VIEW_MESSAGE } from './constants';
import * as selectors from './selectors';
import { Pixel } from './types';

function* trackPageSaga(action: ReturnType<typeof pixelsActions.trackPageView>) {
    const { id } = action.payload;

    const pixels: Record<string, Pixel> = yield select(selectors.getAllPixelsMap);
    const trackedPixel = pixels[id];

    if (!trackedPixel) {
        return;
    }

    const pixelFrame = (document?.getElementById(id) as HTMLIFrameElement)?.contentWindow;

    if (pixelFrame) {
        pixelFrame.postMessage(PAGE_VIEW_MESSAGE, trackedPixel.host);
    }
}

function* trackAllPixelsPageSaga() {
    const pixels: Array<Pixel> = yield select(selectors.getAllPixels);
    yield all(pixels.map((pixel) => [put(pixelsActions.trackPageView({ id: pixel.id }))]));
}

function* trackButtonClickSaga(action: ReturnType<typeof pixelsActions.trackButtonClick>) {
    const { id, goal } = action.payload;

    const pixels: Record<string, Pixel> = yield select(selectors.getAllPixelsMap);
    const trackedPixel = pixels[id];

    if (!trackedPixel) {
        return;
    }
    const pixelFrame = (document?.getElementById(id) as HTMLIFrameElement)?.contentWindow;

    if (pixelFrame) {
        pixelFrame.postMessage({ ...BUTTON_CLICK_MESSAGE, data: { goal } }, trackedPixel.host);
    }
}

function* trackAllPixelsButtonClickSaga(action: ReturnType<typeof pixelsActions.trackAllPixelsButtonClick>) {
    const { goal } = action.payload;
    const pixels: Array<Pixel> = yield select(selectors.getAllPixels);
    yield all(pixels.map((pixel) => [put(pixelsActions.trackButtonClick({ id: pixel.id, goal }))]));
}

export function* pixelsSagasWatcher() {
    yield all([
        takeLatest(pixelsActions.trackPageView, trackPageSaga),
        takeLatest(pixelsActions.trackAllPixelsPageView, trackAllPixelsPageSaga),
        takeLatest(pixelsActions.trackButtonClick, trackButtonClickSaga),
        takeLatest(pixelsActions.trackAllPixelsButtonClick, trackAllPixelsButtonClickSaga),
    ]);
}
