import b from 'b_';
import cx from 'classnames';
import { isNil, omit } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { inputPropTypes } from '../input.propTypes';

import './input__content.scss';

class InputContent extends React.Component {
    static propTypes = {
        ...inputPropTypes,
        state: PropTypes.object.isRequired,
    };

    // список полей, которых нет в инпуте и которые не надо передавать в input
    static customProps = [
        'errorMessage',
        'focused',
        'focusInputEnd',
        'hidden',
        'mix',
        'placeholderAsInput',
        'placeholderAsLabel',
        'successMessage',
        'theme',
        'valid',
    ];

    getValidationMessage = () => {
        const {
            state: { valid },
            successMessage,
            errorMessage,
        } = this.props;

        if (valid && valid !== 'uncertain') {
            return <span className="input__notification">{valid === 'yes' ? successMessage : errorMessage}</span>;
        }

        return null;
    };

    getInput = () => {
        const {
            disabled,
            max,
            maxLength,
            min,
            name,
            readOnly,
            type,
            value,
            defaultValue,
            state,
            onControlRef,
            onFocus,
            onBlur,
            onChange,
            onKeyUp,
            onKeyDown,
            onPaste,
            placeholderAsInput,
            ...otherProps
        } = this.props;
        const { pattern } = state;
        const filteredProps = omit(otherProps, InputContent.customProps);
        const placeholder = placeholderAsInput ? this.props.placeholder : '';

        return (
            <input
                {...filteredProps}
                autoComplete="off"
                className="input__control"
                disabled={disabled}
                max={max}
                maxLength={maxLength}
                min={min}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                onFocus={onFocus}
                onKeyDown={onKeyDown}
                onKeyUp={onKeyUp}
                onPaste={onPaste}
                pattern={pattern}
                placeholder={placeholder}
                readOnly={readOnly}
                ref={onControlRef}
                type={type}
                value={!isNil(value) ? value : defaultValue}
            />
        );
    };

    render() {
        const {
            disabled,
            hidden,
            size,
            theme,
            placeholderAsInput,
            placeholderAsLabel,
            readOnly,
            mix,
            placeholder,
            state,
        } = this.props;
        const { filled, focused, valid } = state;
        const className = b('input', {
            disabled: disabled || readOnly,
            filled,
            focused,
            hidden,
            size,
            theme,
            valid,
        });
        const shouldShowPlaceholder =
            !placeholderAsInput && placeholder && ((!focused && !filled) || placeholderAsLabel);

        return (
            <span className={cx(className, mix)}>
                <span className="input__box">
                    {this.getInput()}
                    {shouldShowPlaceholder && <label className="input__placeholder">{placeholder}</label>}
                </span>
                {this.getValidationMessage()}
            </span>
        );
    }
}

export default InputContent;
