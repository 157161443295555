import { getFetch } from '../fetch';

import { apiConfig } from '../config';

interface GetExternalClessonRequest {
    attachmentId: number;
    lms: string;
}

export interface GetExternalCLessonResponse {
    cll_id: number;
    name?: string;
}

export function getExternalClesson(request: GetExternalClessonRequest): Promise<GetExternalCLessonResponse> {
    const { apiGetExternalCLesson } = apiConfig.getStaticUrls();

    return getFetch(apiGetExternalCLesson, request);
}
