import b from 'b_';
import { SimpleCallback, Spin } from 'platform-components';
import React, { useEffect, useState } from 'react';

import './team-builder__submit.scss';

interface Props {
    shouldDisplayLogins: boolean;
    shouldSubmit: boolean;
    submit: SimpleCallback;
}

export const TeamBuilderSubmit = ({ shouldSubmit, submit, shouldDisplayLogins }: Props) => {
    const [canSend, setCanSend] = useState(true);

    useEffect(() => {
        // отправляем запрос только один раз за шаг TEAM_BUILDER_STEPS.SUBMIT
        // иначе будут отправляться повторные запросы при перерендере из-за изменения submittingStatus на ERROR
        if (shouldSubmit && submit && canSend) {
            submit();
            setCanSend(false);
        }
    }, [canSend, shouldSubmit, submit]);

    return (
        <div className={b('team-builder', 'submit', { 'with-logins': shouldDisplayLogins })}>
            <Spin center size={Spin.SIZE.L} />
        </div>
    );
};
