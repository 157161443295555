import { ReferalCode } from './../../../typings/api/defs/post-referal-code';
import { postFetch } from '../fetch';

import { apiConfig } from '../config';

export function postReferalCode({ referalCode, url }: ReferalCode): Promise<string> {
    const { apiPostReferalCode } = apiConfig.getStaticUrls();

    return postFetch(apiPostReferalCode, { ref_code: referalCode, payload: { url } });
}
