import { MapOptions } from 'react-yandex-maps';

// стилизация векторной подложки - отключает видимость на карте всех текстов и всех лейблов (кроме точек-обозначений городов)
// подробнее в документации https://doc.yandex-team.ru/maps/vector/concepts/style.html

export const VECTOR_MAP_WITHOUT_LABELS: MapOptions = {
    vector: true,
    layerVectorRevealThreshold: 0,
    layerVectorCustomization: [
        {
            types: ['point', 'polyline', 'polygon'],
            elements: 'label.text',
            tags: {
                any: ['admin', 'water', 'landscape', 'road', 'poi', 'transit', 'structure'],
            },
            stylers: {
                visibility: 'off',
            },
        },
        {
            types: ['point', 'polyline', 'polygon'],
            elements: 'label',
            tags: {
                any: ['water', 'landscape', 'road', 'poi', 'transit', 'structure'],
            },
            stylers: {
                visibility: 'off',
            },
        },
    ],
};

// задает минимальное ограничение зоны карты, если иное не указано в ее options
// 180 и -180 градусов долготы - одно и то же в картах, поэтому используем 179.999999
// -180 долготы для restrictMapArea вызывает глюк тайлов для векторной карты, поэтому ставим -179.999999

export const RESTRICT_AREA: MapOptions = {
    // @ts-ignore т.к. в апи карт restrictMapArea принимает Boolean|Number[][], а в react-yandex-maps - только boolean
    restrictMapArea: [
        [-85, -179.999999],
        [85, 179.999999],
    ],
};

export const COMMON_MODULES = [
    'control.Button',
    'control.RulerControl',
    'control.ZoomControl',
    'GeoObject',
    'geoObject.addon.balloon',
    'geoObject.addon.hint',
    'GeoObjectCollection',
    'ObjectManager',
    'objectManager.addon.objectsBalloon',
    'objectManager.addon.objectsHint',
    'option.presetStorage',
    'layout.ImageWithContent',
    'layout.Image',
    'regions',
    'borders',
];
