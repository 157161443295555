import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';

import * as constants from './constants';

const initialState = {
    isLoading: LOADING_STATUSES.LOADING,
    status: null,
};

const subscriptionHandlerReducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.APPROVE_SUBSCRIBE_REQUEST:
            return Object.assign({}, state, { isLoading: LOADING_STATUSES.LOADING });
        case constants.APPROVE_SUBSCRIBE_SUCCESS:
            return Object.assign({}, state, {
                isLoading: LOADING_STATUSES.SUCCESS,
                status: 'success',
            });
        case constants.APPROVE_SUBSCRIBE_ERROR:
            return Object.assign({}, state, {
                isLoading: LOADING_STATUSES.ERROR,
                status: action.errorType,
            });
        case constants.APPROVE_UNSUBSCRIBE_SUCCESS:
            return Object.assign({}, state, {
                isLoading: LOADING_STATUSES.SUCCESS,
                status: 'success',
            });
        default:
            return state;
    }
};

export default subscriptionHandlerReducer;
