import cx from 'classnames';
import { Button, SimpleText, Text } from 'platform-components';
import PropTypes from 'prop-types';
import React from 'react';

import './start-screen__adult-login.scss';

const StartScreenAdultLogin = ({ disabled, mix, onLoginClick }) => (
    <div className={cx('start-screen__adult-login', mix)}>
        <Button className="start-screen__teacher-button" disabled={disabled} onClick={onLoginClick}>
            <SimpleText id="startScreen.enterAsAdult" />
        </Button>
        <div className="start-screen__teacher-button-description">
            <Text id="startScreen.enterAsAdult.decription" weight={Text.WEIGHT.M} />
        </div>
    </div>
);

StartScreenAdultLogin.propTypes = {
    disabled: PropTypes.bool,
    mix: PropTypes.string,
    onLoginClick: PropTypes.func.isRequired,
};

export default StartScreenAdultLogin;
