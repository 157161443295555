import b from 'b_';
import { Play } from 'education-icons';
import React, { useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAsyncFn, useMount } from 'react-use';
import { v4 as uuid } from 'uuid';

import { useAppContext } from '../app-factory/context';
import { Button } from '../button/button';

import { Editor } from './__editor/code-runner__editor';
import { Stdio } from './__stdio/code-runner__stdio';
// @ts-ignore не понимает webpack worker-loader
import PyodideWorker from './pyodide.worker';
import { RunCodeRequest, RunCodeResponse } from './types';

import './code-runner.scss';

const cn = b.with('code-runner');

const callbacks: Record<RunCodeResponse['id'], (value: RunCodeResponse) => void> = {};

const getWorkerHandle = (worker: Worker) => ({
    async runPythonCodeInWorker(code: string, input: string): Promise<RunCodeResponse> {
        const id = uuid();
        return new Promise((resolve) => {
            callbacks[id] = resolve;
            const request: RunCodeRequest = { id, code, input };
            worker.postMessage(request);
        });
    },
});
type WorkerHandle = ReturnType<typeof getWorkerHandle>;
let pyodideWorker: WorkerHandle | null = null;

const initWorker = () => {
    if (pyodideWorker) {
        return pyodideWorker;
    }
    const worker: Worker = new PyodideWorker();
    worker.onmessage = (event: MessageEvent) => {
        const data = event.data as RunCodeResponse;
        const resolve = callbacks[data.id];
        delete callbacks[data.id];
        resolve(data);
    };
    pyodideWorker = getWorkerHandle(worker);
    return pyodideWorker;
};

export const CodeRunner = (props: { defaultCode?: string; defaultInput?: string }) => {
    const intl = useIntl();
    const codeRef = useRef(props.defaultCode || '');
    const inputRef = useRef(props.defaultInput || '');
    const isSupported = !useAppContext().uatraits.isMobile;
    useMount(() => {
        if (isSupported) {
            // warm up
            initWorker();
        }
    });
    const [runCodeState, runCodeRequest] = useAsyncFn(async () => {
        if (isSupported) {
            const { runPythonCodeInWorker } = initWorker();
            const response = await runPythonCodeInWorker(codeRef.current, inputRef.current);
            return { runId: uuid(), ...response };
        }
        return { runId: uuid(), output: '', err: intl.formatMessage({ id: 'codeRunner.notSupported' }) };
    }, []);
    return (
        <div className={cn()}>
            <div className={cn('toolbar')}>
                <Button
                    accent={Button.ACCENT.NORMAL}
                    icon={<Play className={cn('btn-run-icon')} />}
                    key={runCodeState.loading ? 'loading' : 'normal'}
                    onClick={runCodeRequest}
                    progress={runCodeState.loading}
                    size={Button.SIZE.S}
                    view={Button.VIEW.PSEUDO}
                >
                    <FormattedMessage id="codeRunner.runButton" />
                </Button>
            </div>
            <Editor onChange={(value) => (codeRef.current = value)} value={props.defaultCode} />
            <div className={cn('stdio-list')}>
                <Stdio
                    className={cn('stdio-list-item')}
                    onChange={(value) => (inputRef.current = value)}
                    title={<FormattedMessage id="problemCoding.test.input" />}
                    value={props.defaultInput}
                />
                <Stdio
                    attention={Boolean(runCodeState.value?.runId)}
                    className={cn('stdio-list-item')}
                    isException={runCodeState.value?.err !== undefined}
                    key={runCodeState.value?.runId}
                    readOnly
                    title={<FormattedMessage id="problemCoding.test.output" />}
                    value={(runCodeState.value?.output || '') + (runCodeState.value?.err || '')}
                />
            </div>
        </div>
    );
};
