import PropTypes from 'prop-types';

export const keyboardKeyPropTypes = {
    disabled: PropTypes.bool,
    mix: PropTypes.string,
    mode: PropTypes.string,
    onPress: PropTypes.func.isRequired,
    isSelected: PropTypes.bool,
    width: PropTypes.string,
    content: PropTypes.node,
};
