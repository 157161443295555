import { iClass } from 'common.components/typings';

import { MAX_LETTER_LENGTH } from './constants';

export const getClassesErrorMessage = (classes?: iClass[]) => {
    if (!classes || !classes.length) {
        return { id: 'teacherRegistration.classesForm.noClassesSelectedError' };
    }

    return null;
};

export const getNumberErrorMessage = (number: number | null | undefined) => {
    if (!number) {
        return { id: 'addClass.validation.noNumber' };
    }

    return null;
};

export const getLetterErrorMessage = (letter: string | null | undefined) => {
    if (!letter) {
        return { id: 'addClass.validation.noLetter' };
    }
    if (letter.length > MAX_LETTER_LENGTH) {
        return { id: 'validation.tooLongValue', values: { number: MAX_LETTER_LENGTH } };
    }

    return null;
};
