import { get, isNil } from 'lodash';
import React from 'react';
import SimpleMarkdown, { OptionalState, ParserRule, ParserRules, ReactOutputRule } from 'simple-markdown';

import { concatRegExps } from '../../utils/typograf/utils';

import { Rule, UnresolvedRule } from './types';

// align-items => alignItems
// -ms-flex-align => MsFlexAlign
function toCamelCase(str: string) {
    return str.replace(/-([a-z])/g, (letter) => letter[1].toUpperCase());
}

function cssPropertiesToObject(str: string | true) {
    if (typeof str !== 'string') {
        return {};
    }

    return str.split(';').reduce(
        (result, attribute) => {
            const [roughKey, roughValue] = attribute.split(':');
            const key = toCamelCase(roughKey.trim()); // css-свойства реакт просит переводить в camelCase

            if (key !== '' && typeof roughValue === 'string') {
                result[key] = roughValue.trim();
            }

            return result;
        },
        {} as Record<string, string>
    );
}

// `target="_blank" title="Ссылочка"` => {target: '_blank', title: 'Ссылочка'}
export function stringToObject(str: string, hasStyle = true) {
    const items = str.match(/\w*(="[^"]*")?/gim);
    const obj = {} as Record<string, string | true | Record<string, string>>;

    if (Array.isArray(items)) {
        items.forEach((item) => {
            const match = item.match(/(\w*)(="([^"]*)")?/i);

            if (match === null) {
                throw 1;
            }

            const key = match[1];
            const value = match[3] ? match[3] : true;

            if (match && key) {
                obj[key] =
                    key === 'style' && hasStyle
                        ? cssPropertiesToObject(value) // css-свойства нужно в словарь
                        : value; // остальное можно просто строкой или true, если есть ключ, но нет значения
            }
        });
    }

    return obj;
}

// http://stackoverflow.com/questions/3452546/javascript-regex-how-to-get-youtube-video-id-from-url
export function getYoutubeId(url: string) {
    // eslint-disable-next-line no-useless-escape
    const regExp = /^(?:.*(?:youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=))?([^#\&\?]*).*/i;
    const match = url.match(regExp);

    return match && match[1].length === 11 ? match[1] : null;
}

// генерирует готовые правила из сокращенной записи
export function resolveRules(unresolvedRulesArray: UnresolvedRule[]) {
    const resolvedRules = {} as Record<string, ParserRule & ReactOutputRule>;

    unresolvedRulesArray.forEach((rule) => {
        resolvedRules[rule.type] = {
            order: 0,
            match: SimpleMarkdown.inlineRegex(rule.regex),
            parse(capture) {
                return { content: capture[1], attrs: capture[3] && stringToObject(capture[3], false) };
            },
            react(node, output, state) {
                const hasContentInState = !isNil(get(state, [rule.source, node.content]));

                if (hasContentInState) {
                    return React.createElement(
                        rule.component,
                        Object.assign(
                            {},
                            state[rule.commonPropsName],
                            state[rule.source][node.content],
                            rule.additionalSource &&
                                state[rule.additionalSource] &&
                                state[rule.additionalSource][node.content],
                            {
                                mdState: state,
                                id: node.content,
                                key: `${rule.type}_${node.content}_${state.key}`,
                                attrs: node.attrs,
                            }
                        )
                    );
                }
                return null;
            },
        };
    });

    return resolvedRules;
}

// преобразует правила в формат правил simple-markdown
export function prepareRules(rulesArray: Record<string, Rule>) {
    const expandedRules = {} as Record<string, ParserRule>;

    Object.values(rulesArray).forEach((rule) => {
        expandedRules[rule.type] = rule.rule;
    });

    return expandedRules;
}

const CONTENT_TERMINATOR = '\n\n';
const BLOCK_QUOTE_REGEX = concatRegExps(/(^|^\n|\n\n)/, SimpleMarkdown.defaultRules.blockQuote.match.regex, 'g');

export function prepareContent(content: string, preParse: (content: string) => string) {
    const contentWithDefault = content ? `${content}${CONTENT_TERMINATOR}` : CONTENT_TERMINATOR;
    let stringContent = preParse(contentWithDefault);

    /* blockQuoter
     * на входе: список в цитате (список выделен с помощью dash)
     * на выходе: маркдаун отображает это как список в цитате
     * Но после применения типографирования, все dash в этом списке меняются на mdash - маркдаун это отображает
     * как простой текст и это ещё не всё: какое-то generic правило в типографе добавляет nbsp после символа ">"
     * Решение: убрать только этот nbsp и преобразовать mdash -> dash. Результат такого преобразования
     * используется только в маркдауне, а оригинал никак не затрагивается
     */
    stringContent = stringContent.replace(BLOCK_QUOTE_REGEX, (blockQuote) => {
        return blockQuote.replace(/((?:^|^\n|\n\n) *>|\n)([ \u00A0]*)\u2014/g, (m, startPart, spacePart) => {
            spacePart = spacePart.replace(/\u00A0/g, ' '); // nbsp -> space
            return `${startPart}${spacePart}-`;
        });
    });

    return stringContent;
}

export function getOutput(rules: ParserRules) {
    const parse = SimpleMarkdown.parserFor(rules);
    // @ts-ignore
    const output = SimpleMarkdown.reactFor(SimpleMarkdown.ruleOutput(rules, 'react'));

    return (markup: string, mdState: OptionalState) => {
        const parsedData = parse(markup);

        return output(parsedData, mdState);
    };
}
