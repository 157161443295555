import b from 'b_';
import cx from 'classnames';
import omit from 'lodash/omit';
import React from 'react';
import { NavLink } from 'react-router-dom';

import { LINK_THEME } from './constants';

import './link.scss';

const DOM_DISALLOWED_PROPNAMES = ['active', 'disabled', 'isRouter', 'mix', 'pseudo', 'theme', 'to'] as const;
const LINK_DISALLOWED_PROPNAMES = ['active', 'disabled', 'isRouter', 'mix', 'pseudo', 'theme', 'rel'] as const;

export interface LinkProps extends React.AriaAttributes {
    /**
     * Сама ссылка
     */
    to?: string | null;

    /**
     * @constant true если ссылка указана без домена (тогда используется `NavLink`)
     * @constant false если ссылка указана с доменом (тогда используется `a`)
     */
    isRouter?: boolean;

    active?: boolean;
    disabled?: boolean;
    mix?: string | string[];
    className?: string;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    onMouseEnter?: (e: React.MouseEvent<HTMLElement>) => void;
    pseudo?: boolean;
    rel?: string;
    theme?: LINK_THEME;
    target?: string;
    style?: React.CSSProperties;
    tabindex?: number;
    role?: React.AriaRole;
}

const ON_DARK_THEMES = [LINK_THEME.PRIMARY_ON_DARK, LINK_THEME.SECONDARY_ON_DARK, LINK_THEME.STEALTHY_ON_DARK];

class Link extends React.Component<React.PropsWithChildren<LinkProps>> {
    static defaultProps = {
        pseudo: false,
        rel: 'noopener noreferer',
        theme: LINK_THEME.PRIMARY,
    };

    static THEME = LINK_THEME;

    onClick = (e: React.MouseEvent<HTMLElement>) => {
        if (this.props.disabled) {
            e.preventDefault();
            return;
        }
        if (this.props.onClick) {
            this.props.onClick(e);
        }
    };

    render() {
        const { isRouter, active, disabled, theme, mix, pseudo, to } = this.props;
        const className = cx(
            b('link', {
                active,
                disabled,
                theme,
                'disabled-on-dark': disabled && ON_DARK_THEMES.includes(theme!),
            }),
            mix
        );
        const domProps = omit(this.props, DOM_DISALLOWED_PROPNAMES);
        const routerLinkProps = omit(this.props, LINK_DISALLOWED_PROPNAMES);
        const linkProps = {
            activeClassName: 'link_active',
            className: className,
            onClick: this.onClick,
            ...routerLinkProps,
        };

        if (pseudo || disabled) {
            return <span className={cx(className, 'link_pseudo')} {...domProps} onClick={this.onClick} />;
        }

        if (isRouter) {
            return <NavLink {...(linkProps as any)} />;
        }

        const stringTo = to as string | undefined;

        return <a className={className} {...domProps} href={stringTo} onClick={this.onClick} />;
    }
}

export default Link;
