// @ts-nocheck
import { SpecProjectConfigItem } from '@yandex-int/k-common';
import qs from 'query-string';

import { SEARCH_PARAM_REFERAL_CODE, SEARCH_PARAM_FROM_PROJECT } from 'common.components/constants';

const RE_PROMO = /^[\wА-яёЁ\-]+$/;

export function getIsPromoCodeValid(promo: string) {
    return Boolean(promo && RE_PROMO.test(promo));
}

export function executeFinishRegistrationRedirect(urlString: string) {
    if (isSSR) {
        return;
    }

    const baseUrl = window.location.origin;
    const redirectUrl = new URL(urlString, baseUrl);
    const searchParams = qs.parse(window.location.search);

    // eslint-disable-next-line guard-for-in
    for (const key in searchParams) {
        if (key === SEARCH_PARAM_REFERAL_CODE || key === SEARCH_PARAM_FROM_PROJECT || key.indexOf('utm_') === 0) {
            redirectUrl.searchParams.set(key, searchParams[key]);
            continue;
        }

        delete searchParams[key];
    }

    window.location.href = redirectUrl.href;
}

export function getRedirectAfterRegistrationUrl(specProjectConfig: SpecProjectConfigItem | null, defaultUrl: string) {
    return specProjectConfig?.redirectAfterRegistration || defaultUrl;
}
