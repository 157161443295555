// @ts-nocheck
import qs from 'query-string';

import { SEARCH_PARAM_FROM_PROJECT, SEARCH_PARAM_REG_REDIRECT_URL } from 'common.components/constants';

export const getSourceProject = (): string => {
    if (isSSR) {
        return '';
    }

    return qs.parse(window.location.search)[SEARCH_PARAM_FROM_PROJECT];
};

export const getRegRedirectUrl = (): string | undefined => {
    if (isSSR) {
        return undefined;
    }

    return qs.parse(window.location.search)[SEARCH_PARAM_REG_REDIRECT_URL];
};
