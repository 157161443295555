import qs from 'query-string';

import { getPassportHost } from 'utils/get-passport-host';

export const POST_REGISTRATION_PARAM = 'postregistration';
const ORIGIN = 'education';

export enum PASSPORT_MODES {
    POST_REGISTRATION = 'registration',
    AUTH = 'auth',
    AUTH_WELCOME = 'auth/welcome', // подставляет логин в Домике авторизации
    SWITCH_USER = 'passport',
}

type QueryParams = {
    retpath: string;
    backpath: string;
};

type AuthDefaultParams = {
    passportMode: PASSPORT_MODES.AUTH;
    queryParams: QueryParams;
};

type AuthRegistrationParams = {
    passportMode: PASSPORT_MODES.POST_REGISTRATION;
    queryParams: QueryParams;
};

type AuthWelcomeParams = {
    passportMode: PASSPORT_MODES.AUTH_WELCOME;
    queryParams: QueryParams & {
        login: string;
    };
};

type PassportSwitchUserParams = {
    passportMode: PASSPORT_MODES.SWITCH_USER;
    queryParams: QueryParams & {
        mode: 'embeddedauth';
        uid: number; // yauid аккаунта, на которого хотим переключиться
        action: 'change_default';
        yu: string; // yandexuid текущего акакунта ииз cookies
    };
};

type AuthParams = AuthDefaultParams | AuthRegistrationParams | AuthWelcomeParams | PassportSwitchUserParams;

function getAuthBasePath({ passportMode, queryParams }: AuthParams): string {
    const path = `/${passportMode}`;
    const passportHost = getPassportHost();
    let query: Record<string, string | number | boolean> = {
        origin: ORIGIN,
        ...queryParams,
    };

    if (passportMode === PASSPORT_MODES.POST_REGISTRATION) {
        query = {
            ...query,
            mode: POST_REGISTRATION_PARAM,
            create_login: 1,
            create_password: 1,
        };
    }

    return `${passportHost}${path}?${qs.stringify(query)}`;
}

export function getAuthPath(
    queryParams: AuthDefaultParams['queryParams'],
    passportMode: PASSPORT_MODES.AUTH | PASSPORT_MODES.POST_REGISTRATION = PASSPORT_MODES.AUTH
) {
    return getAuthBasePath({ passportMode, queryParams });
}

export function getAuthWelcomePath(queryParams: AuthWelcomeParams['queryParams']) {
    return getAuthBasePath({ passportMode: PASSPORT_MODES.AUTH_WELCOME, queryParams });
}

export function getPassportSwitchUserPath(queryParams: Omit<PassportSwitchUserParams['queryParams'], 'mode'>) {
    return getAuthBasePath({
        passportMode: PASSPORT_MODES.SWITCH_USER,
        queryParams: {
            ...queryParams,
            mode: 'embeddedauth',
        },
    });
}
