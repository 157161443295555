//@ts-ignore
import * as api from '@yandex-int/k-common/client/api';
import { Address, School } from '@yandex-int/k-common/typings';
import uniqueId from 'lodash/uniqueId';
import { showNetworkError } from 'platform-components';
import { delay, all, call, put, select, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';
import { GET_ADDRESS_SUGGESTS_REQUEST, GET_SCHOOL_SUGGESTS_REQUEST } from './constants';
import { DEBOUNCE_MS, SUGGEST_OPTIONS_LIMIT } from './school-form.constants';
import { getAreAddressesLoading, getAreSchoolsLoading, getCurrentAddress, getIsCurrentAddressSet } from './selectors';

export function* schoolFormSagasWatcher() {
    yield all([
        takeLatest(GET_ADDRESS_SUGGESTS_REQUEST, getAddressSuggestsSaga),
        takeLatest(GET_SCHOOL_SUGGESTS_REQUEST, getSchoolSuggestsSaga),
    ]);
}

function* getAddressSuggestsSaga(action: ReturnType<typeof actions.getAddressSuggests>) {
    try {
        const { text } = action;

        yield delay(DEBOUNCE_MS);

        const { items } = yield call(api.getSuggestedAddresses, text);
        // в случае, если из поля стирается все, нужно избежать гонки setAddressSuggests и getAddressSuggests
        const areAddressesLoading: boolean = yield select(getAreAddressesLoading);

        if (areAddressesLoading) {
            yield put(
                actions.getAddressSuggestsSuccess(
                    items.map((item: Array<Address>) => ({ ...item, id: uniqueId() })).slice(0, SUGGEST_OPTIONS_LIMIT)
                )
            );
        }
    } catch (error) {
        console.error(error);

        yield put(actions.getAddressSuggestsError());
        yield put(showNetworkError(error));
    }
}

function* getSchoolSuggestsSaga(action: ReturnType<typeof actions.getSchoolSuggests>) {
    try {
        const { text } = action;

        yield delay(DEBOUNCE_MS);

        const isCurrentAddressSet: boolean = yield select(getIsCurrentAddressSet);

        if (isCurrentAddressSet) {
            const { geoid } = yield select(getCurrentAddress);
            const { items } = yield call(api.getSuggestedSchoolsByGeoId, text, geoid);
            // в случае, если из поля стирается все, нужно избежать гонки setSchoolsSuggests и getSchoolsSuggests
            const areSchoolsLoading: boolean = yield select(getAreSchoolsLoading);

            if (areSchoolsLoading) {
                const list = items.slice(0, SUGGEST_OPTIONS_LIMIT).map(({ id, name, full_name: fullName }: School) => ({
                    id,
                    subtitle: fullName || '',
                    title: name,
                }));

                yield put(actions.getSchoolSuggestsSuccess(list));
            }
        }
    } catch (error) {
        console.error(error);

        yield put(actions.getSchoolSuggestsError());
        yield put(showNetworkError(error));
    }
}
