import { ISODateTime } from '../../common';

export enum SUBSCRIPTION_TYPES {
    COMMON_LESSON_TO_COURSE = 'CommonLessonToCourse',
}

export enum SUBSCRIPTION_SETTING {
    SHORT = 'short',
    LONG = 'long',
}

export interface Subscription {
    slug: string;
    name: string;
    type: SUBSCRIPTION_TYPES;
    date_created: ISODateTime;
    date_updated: ISODateTime;
    description: string;
    subscribe_text: string;
    unsubscribe_text: string;
    setting: SUBSCRIPTION_SETTING;
}

export interface CourseSubscription {
    active: boolean;
    date_created: ISODateTime
    date_updated: ISODateTime,
    id: number;
    target: number
    subscription: Subscription;
}

export interface LessonSubscription {
    id: number;
    item: number;
    subscription: Subscription;
    delivery_dt: ISODateTime;
    deprecation_dt: ISODateTime;
    date_created: ISODateTime;
    date_updated: ISODateTime;
}

export type CourseSubscriptionsData = Record<number, Array<CourseSubscription>>;
export type LessonSubscriptionsData = Record<number, Array<LessonSubscription>>;
