import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import produce from 'immer';

import * as constants from './constants';
import { ChildManagementStore, ChildManagementAction } from './typings';

const initialState: ChildManagementStore = {
    code: '',
    login: '',
    schoolCode: '',
    passwordLength: constants.SCHOOL_CODE_LENGTH,
    checkSchoolCodeStatus: LOADING_STATUSES.UNSENT,
    bindingByLoginStatus: LOADING_STATUSES.UNSENT,
    bindingLoadingStatus: LOADING_STATUSES.UNSENT,
    isBindingLoadingCodeWrong: false,
    parentCourseAssignStatus: LOADING_STATUSES.UNSENT,
    shouldShowErrorPopup: false,
    errorType: null,
};

const reducer = (state: ChildManagementStore = initialState, action: ChildManagementAction) =>
    // eslint-disable-next-line complexity, consistent-return
    produce(state, (draft) => {
        switch (action.type) {
            case constants.SET_STUDENT_CODE: {
                const { code } = action;

                draft.code = code;
                draft.isBindingLoadingCodeWrong = false;
                break;
            }

            case constants.SET_LOGIN: {
                const { login } = action;

                draft.login = login;
                draft.shouldShowErrorPopup =
                    state.checkSchoolCodeStatus === LOADING_STATUSES.SUCCESS ? false : state.shouldShowErrorPopup;
                break;
            }

            case constants.SET_SCHOOL_CODE: {
                const { schoolCode, schoolCodeLength } = action;

                draft.schoolCode = schoolCode;
                if (schoolCode.length < schoolCodeLength) {
                    draft.shouldShowErrorPopup = false;
                }
                break;
            }

            case constants.SET_PASSWORD_LENGTH:
                draft.passwordLength = action.length;
                break;

            case constants.VERIFY_SCHOOL_CODE_REQUEST: {
                draft.checkSchoolCodeStatus = LOADING_STATUSES.LOADING;
                draft.shouldShowErrorPopup = false;
                break;
            }

            case constants.VERIFY_SCHOOL_CODE_SUCCESS: {
                draft.checkSchoolCodeStatus = LOADING_STATUSES.SUCCESS;
                draft.shouldShowErrorPopup = false;
                break;
            }

            case constants.VERIFY_SCHOOL_CODE_ERROR: {
                const { error } = action;

                draft.checkSchoolCodeStatus = LOADING_STATUSES.ERROR;
                draft.shouldShowErrorPopup = error?.status === constants.WRONG_AUTH_CODE;
                draft.errorType = constants.ERROR_TYPES.WRONG_SCHOOL_CODE;
                break;
            }

            case constants.BIND_CHILD_BY_STUDENT_CODE_REQUEST: {
                draft.bindingLoadingStatus = LOADING_STATUSES.LOADING;
                break;
            }

            case constants.BIND_CHILD_BY_STUDENT_CODE_SUCCESS: {
                draft.bindingLoadingStatus = LOADING_STATUSES.SUCCESS;
                break;
            }

            case constants.BIND_CHILD_BY_STUDENT_CODE_ERROR: {
                draft.bindingLoadingStatus = LOADING_STATUSES.ERROR;
                draft.isBindingLoadingCodeWrong = action.isCodeWrong;
                break;
            }

            case constants.BIND_CHILD_BY_LOGIN_REQUEST: {
                draft.bindingByLoginStatus = LOADING_STATUSES.LOADING;
                break;
            }

            case constants.BIND_CHILD_BY_LOGIN_SUCCESS: {
                draft.bindingByLoginStatus = LOADING_STATUSES.SUCCESS;
                break;
            }

            case constants.BIND_CHILD_BY_LOGIN_ERROR: {
                const { error } = action;

                draft.bindingByLoginStatus = LOADING_STATUSES.ERROR;
                draft.shouldShowErrorPopup = error.status === constants.WRONG_AUTH_CODE;
                draft.errorType = constants.ERROR_TYPES.WRONG_LOGIN;
                break;
            }

            case constants.CLEAR_FORM: {
                return initialState;
            }

            // no default
        }
    });

export default reducer;
