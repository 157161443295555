import { ExternalLms, Me, EXTERNAL_LMS_SLUGS, TeacherProfile } from '@yandex-int/k-common/typings';
import cx from 'classnames';
import { MesLogo } from 'education-icons';
import { Button, SimpleText, SimpleCallback } from 'platform-components';
import { InjectedProps as WithRouterPathProps } from 'platform-components/hocs';
import PropTypes from 'prop-types';
import { stringify } from 'query-string';
import React, { ReactNode } from 'react';
import ym from 'react-yandex-metrika';

import { INTEGRATION_SCENARIO, SEARCH_PARAMS_INTEGRATION } from 'common.components/constants';

import './connect-external-button.scss';

interface HOCProps extends WithRouterPathProps {}

interface OwnProps {
    buttonTextId: string;
    children?: ReactNode;
    disabled?: boolean;
    isAsyncClassImport?: boolean;
    mix?: string;
    onClick?: SimpleCallback;
    onMouseEnter?: SimpleCallback;
    onMouseLeave?: SimpleCallback;
    rejectRetpath?: string;
    retpath?: string;
    schoolId: number | null;
    scenario?: INTEGRATION_SCENARIO;
    userExternalLmsList: Record<string, ExternalLms> | null;
}

interface StoreProps {
    me: Me | null;
    teacherProfile: TeacherProfile | null;
}

interface Props extends HOCProps, OwnProps, StoreProps {}

interface Context {
    basePath: string;
    prefix: string;
    staticUrls: {
        externalCreateLesson: string;
        labLessons: string;
    };
}

export const ConnectExternalButtonTypeMes = (props: Props, context: Context) => {
    const { buttonTextId, children, disabled, mix, onMouseEnter, onMouseLeave } = props;

    const handleClick = () => {
        const {
            getRouterPath,
            isAsyncClassImport,
            userExternalLmsList,
            me,
            teacherProfile,
            retpath: retpathFromProps,
            rejectRetpath,
            schoolId,
            onClick,
            scenario,
        } = props;
        const {
            basePath,
            prefix,
            staticUrls: { externalCreateLesson, labLessons },
        } = context;
        const { user_id: linkedLmsId = null } = userExternalLmsList?.mes || {};

        const { id } = me || {};

        if (onClick) {
            onClick();
        }

        ym('reachGoal', teacherProfile ? 'teacher_my_class_add_from_partner' : 'teacher_onboarding_chose_partner', {
            partner_slug: EXTERNAL_LMS_SLUGS.MES,
            user_id: id,
        });

        const path = getRouterPath(externalCreateLesson);
        const retpath = encodeURI(retpathFromProps || `${basePath}${prefix}${getRouterPath(labLessons)}`);
        const params = stringify({
            [SEARCH_PARAMS_INTEGRATION.ASYNC_CLASS_IMPORT]: isAsyncClassImport,
            [SEARCH_PARAMS_INTEGRATION.LMS]: EXTERNAL_LMS_SLUGS.MES,
            [SEARCH_PARAMS_INTEGRATION.STAFF_ID]: linkedLmsId,
            [SEARCH_PARAMS_INTEGRATION.REJECT_RETPATH]: rejectRetpath,
            [SEARCH_PARAMS_INTEGRATION.RETPATH]: retpath,
            [SEARCH_PARAMS_INTEGRATION.SCHOOL_ID]: schoolId,
            [SEARCH_PARAMS_INTEGRATION.INTEGRATION_SCENARIO]: scenario,
        });

        window.location.href = `${basePath}${prefix}${path}?${params}`;
    };

    const nodeProps = { onMouseEnter, onMouseLeave };

    return (
        <Button
            className={cx('connect-external-button', mix)}
            disabled={disabled}
            onClick={handleClick}
            view={Button.VIEW.SECONDARY}
            {...nodeProps}
        >
            <MesLogo className="connect-external-button__mes-logo" />
            <SimpleText id={buttonTextId} />
            {children}
        </Button>
    );
};

ConnectExternalButtonTypeMes.contextTypes = {
    basePath: PropTypes.string.isRequired,
    prefix: PropTypes.string.isRequired,
    staticUrls: PropTypes.object.isRequired,
};
