import React from 'react';
import { useSelector } from 'react-redux';
import { useMedia } from 'react-use';

import { UaTraits } from '../../typings';
import { getIsTeacher, getIsStudent } from '../user/selectors';

type MediaRules =
    | 'showOnlyOnMobile'
    | 'showOnlyOnVerticalMobile'
    | 'showOnlyOnHorizontalMobile'
    | 'showOnlyOnDesktop'
    | 'showOnlyForTeacher'
    | 'showOnlyForStudent'
    | 'showOnlyForNotTeacherAndNotStudent';
type RuleMap = Record<MediaRules, boolean>;

interface Props {
    rule: MediaRules;
    renderContent: () => React.ReactNode;
}

const getUaTraits = ({ uatraits }: { uatraits: UaTraits }) => uatraits;

export const MdContentBlock = ({ renderContent, rule }: Props) => {
    const uatraits = useSelector(getUaTraits);
    const isTeacher = useSelector(getIsTeacher);
    const isStudent = useSelector(getIsStudent);
    const isPortrait = useMedia('(orientation: portrait)');

    const isTablet = uatraits.isTablet;
    const isMobile = uatraits.isMobile;

    const isMobileNotTablet = isMobile && !isTablet;
    const isDesktop = !isMobile && !isTablet;

    const rulesFlags: RuleMap = {
        showOnlyOnMobile: isMobileNotTablet,
        showOnlyOnVerticalMobile: isMobileNotTablet && isPortrait,
        showOnlyOnHorizontalMobile: isMobileNotTablet && !isPortrait,
        showOnlyOnDesktop: isDesktop,
        showOnlyForTeacher: isTeacher,
        showOnlyForStudent: isStudent,
        showOnlyForNotTeacherAndNotStudent: !isTeacher && !isStudent,
    };

    if (!rulesFlags[rule]) {
        return null;
    }

    return <div className="md-content-block">{renderContent()}</div>;
};
