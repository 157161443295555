import React from 'react';

// @ts-ignore
import b from './scratch-embed.module.scss';

interface Props {
    project: string;
}

const getEmbedLink = (project: string) => {
    const prefix = 'https://scratch.mit.edu/projects/';
    const suffix = '/embed';
    if (project.startsWith(prefix)) {
        const projectId = project.slice(prefix.length).split('/')[0];
        return `${prefix}${projectId}${suffix}`;
    }
    return project;
};

export const ScratchEmbed = (props: Props) => {
    const project = props.project;
    return (
        <div className={b.responsive}>
            <iframe
                allowFullScreen
                className={b.iframe}
                frameBorder="0"
                height="402"
                scrolling="no"
                src={getEmbedLink(project)}
                width="485"
            />
        </div>
    );
};
