import 'string.prototype.matchall/auto';

import { addThousandsSeparators } from '../../utils/format-number/format-number';

const pureNumbersRegexp = /<span class="mord">((<span class="mord">\d<\/span>){5,})<\/span>/g;
const digitsRegexp = /<span class="mord">(\d)<\/span>/g;

export function formateCodeString(codeString: string): string {
    return codeString.replace(pureNumbersRegexp, (match) => {
        const splittedNumber = match.matchAll(digitsRegexp);
        const numAsArray: string[] = [];
        for (const numInSpanMatch of splittedNumber) {
            numAsArray.push(numInSpanMatch[1]);
        }
        const numAsString = numAsArray.join('');
        const numberWithSeparators = addThousandsSeparators(numAsString, ' ');

        return `<span class="mord">${numberWithSeparators}</span>`;
    });
}
