import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const IconMinusCircle = ({ mix, ...props }) => {
    return (
        <svg className={cx('icon__minus-circle', mix)} {...props} fill="#000000" viewBox="0 0 20 20">
            <path d="M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0zm5 11H5V9h10v2z" />
        </svg>
    );
};

IconMinusCircle.propTypes = {
    mix: PropTypes.string,
};

export default IconMinusCircle;
