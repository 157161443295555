import { SimpleCallback } from 'platform-components';

import type { AddNewChildSuccessCallback } from 'common.components/child-management/typings';

import * as constants from './constants';

export const bindChildByStudentCodeRequest = (code: string, successCallback: AddNewChildSuccessCallback) =>
    <const>{
        type: constants.BIND_CHILD_BY_STUDENT_CODE_REQUEST,
        code,
        successCallback,
    };

export const bindChildByStudentCodeSuccess = () =>
    <const>{
        type: constants.BIND_CHILD_BY_STUDENT_CODE_SUCCESS,
    };

export const bindChildByStudentCodeError = (isCodeWrong: boolean) =>
    <const>{
        type: constants.BIND_CHILD_BY_STUDENT_CODE_ERROR,
        isCodeWrong,
    };

export const setStudentCode = (code: string) =>
    <const>{
        type: constants.SET_STUDENT_CODE,
        code,
    };

export const bindChildByLoginRequest = (
    login: string,
    schoolCode: string,
    successCallback: AddNewChildSuccessCallback
) =>
    <const>{
        type: constants.BIND_CHILD_BY_LOGIN_REQUEST,
        login,
        schoolCode,
        successCallback,
    };

export const bindChildByLoginSuccess = () =>
    <const>{
        type: constants.BIND_CHILD_BY_LOGIN_SUCCESS,
    };

export const bindChildByLoginError = (error: any) =>
    <const>{
        type: constants.BIND_CHILD_BY_LOGIN_ERROR,
        error,
    };

export const setLogin = (login: string) =>
    <const>{
        type: constants.SET_LOGIN,
        login,
    };

export const setSchoolCode = (schoolCode: string, schoolCodeLength: number) =>
    <const>{
        type: constants.SET_SCHOOL_CODE,
        schoolCode,
        schoolCodeLength,
    };

export const setPasswordLength = (length: number) =>
    <const>{
        type: constants.SET_PASSWORD_LENGTH,
        length,
    };

export const verifySchoolCodeRequest = (schoolCode: string) =>
    <const>{
        type: constants.VERIFY_SCHOOL_CODE_REQUEST,
        schoolCode,
    };

export const verifySchoolCodeSuccess = () =>
    <const>{
        type: constants.VERIFY_SCHOOL_CODE_SUCCESS,
    };

export const verifySchoolCodeError = (error?: any) =>
    <const>{
        type: constants.VERIFY_SCHOOL_CODE_ERROR,
        error,
    };

export const addCoursesRequest = (payload: {
    childIds: number[];
    courseTemplateIds: number[];
    onSuccess?: SimpleCallback;
    onError?: SimpleCallback;
}) => <const>{ type: constants.ADD_COURSES_REQUEST, payload };

export const clearForm = () => <const>{ type: constants.CLEAR_FORM };
