export function getWidth(el: HTMLElement) {
    const value = getComputedStyle(el, null).width;
    return parseFloat(value);
}

export function getOuterWidth(el: HTMLElement, includeMargins?: boolean) {
    if (includeMargins) {
        const style = getComputedStyle(el, null);

        return el.offsetWidth + parseInt(style.marginLeft, 10) + parseInt(style.marginRight, 10);
    }

    return el.offsetWidth;
}
