import { Address } from '../../../typings/';
import { getFetch } from '../fetch';
import { apiConfig } from '../config';

export interface SuggestedAddressesData {
    items: Array<Address>;
    count: number;
}

export function getSuggestedAddresses(text: string): Promise<SuggestedAddressesData> {
    const { apiGetContactsSuggest } = apiConfig.getStaticUrls();

    return getFetch(apiGetContactsSuggest, { kind: 'locality', text });
}
