import { withBemMod } from '@bem-react/core';
import { cnButton } from '@yandex-lego/components/Button';
import React from 'react';

import { BUTTON_ACCENT } from '../types';

export interface ButtonAccentDangerProps {
    accent?: BUTTON_ACCENT.DANGER;
}

export const withAccentDanger = withBemMod<ButtonAccentDangerProps>(
    cnButton(),
    { accent: BUTTON_ACCENT.DANGER },
    (Button) =>
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ({ accent, ...props }: ButtonAccentDangerProps) => <Button {...props} />
);
