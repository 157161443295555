import type { Lesson, LessonSubscriptionsData } from '@yandex-int/k-common';
import * as api from '@yandex-int/k-common/client/api';
import { showNetworkError } from 'platform-components';
import { getIsParent } from 'platform-components/src/components/user/selectors';
import { call, put, select } from 'redux-saga/effects';

import { getCurrentYearCourseId } from 'common.components/course/selectors';
import { courseSubscriptionsActions } from 'store/subscriptions/actions';
import { getLessonSubscriptions } from 'store/subscriptions/subscriptions-loader';

import { actions as lessonActions } from '../index';

import { loadTeam } from './utils';

export function* getLessonSaga(action: ReturnType<typeof lessonActions.getLessonRequest>) {
    yield call(getLesson, action.payload);
}

export function* getLesson(args: { lessonId: number; moduleId?: number | null }) {
    try {
        const { lessonId } = args;
        const courseId: number = yield select(getCurrentYearCourseId);
        const isParent: boolean = yield select(getIsParent);

        yield loadTeam();

        yield put(lessonActions.getLessonLoading(args));

        const data: Lesson = yield call(api.getLesson, lessonId);

        if (isParent) {
            yield put(courseSubscriptionsActions.getSubscriptionsByCourse({ courseId }));

            const subscriptions: LessonSubscriptionsData = yield call(getLessonSubscriptions, [lessonId]);
            yield put(lessonActions.setSubscriptions({ subscriptions: subscriptions[lessonId] || [] }));
        }

        yield put(lessonActions.setLesson({ lesson: data } as any));
        yield put(lessonActions.getLessonSuccess());
    } catch (error) {
        console.error(error);

        yield put(showNetworkError(error));
        yield put(lessonActions.getLessonError());
    }
}
