import { ROLES } from '@yandex-int/k-common/constants';
import { Text } from 'platform-components';
import React, { ReactNode } from 'react';

import { THEMES } from 'common.components/footer/constants';
//@ts-ignore
import Footer from 'common.components/footer/footer';

import RegistrationHeader from '../../registration-header/registration-header';

interface Props {
    textId: string;
    children: ReactNode | ReactNode[];
}

const ParentRegistrationWrapper = ({ children, textId }: Props) => {
    return (
        <div className="parent-registration">
            <RegistrationHeader />
            <div className="parent-registration__content">
                <div className="parent-registration__description">
                    <Text id={textId} size={Text.SIZE.XL} type={Text.TYPE.MARKDOWN} />
                </div>
                {children}
            </div>
            <Footer mix="parent-registration__footer" role={ROLES.ANONYMOUS} theme={THEMES.DEFAULT} />
        </div>
    );
};

export default ParentRegistrationWrapper;
