import { useEffect } from 'react';

import { SimpleCallback } from '../../../typings';

const callbacks = new Set<SimpleCallback>();

export default function useStopOtherSounds(stop: SimpleCallback): SimpleCallback {
    useEffect(
        function registerCallback() {
            callbacks.add(stop);

            return function unregister() {
                callbacks.delete(stop);
            };
        },
        [stop]
    );

    return () => {
        callbacks.forEach((otherCallback) => {
            if (otherCallback !== stop) {
                otherCallback();
            }
        });
    };
}
