import { all, takeLatest } from 'redux-saga/effects';

import { actions as lessonActions } from '../index';

import { finishAddingStudentsSaga } from './addStudents';
import { createLessonByProblemIdsSaga } from './createLessonByProblemIds';
import { deleteCLessonSaga } from './deleteCLesson';
import { getCLessonResultsSaga } from './getCLessonResults';
import { getClessonWithResultsSaga } from './getClessonWithResults';
import { getFullClessonSaga } from './getFullLesson';
import { getLessonSaga } from './getLesson';
import { getLessonAssignsSaga } from './getLessonAssigns';
import { removeProblemFromLessonSaga } from './removeProblemFromLesson';
import { restoreCLessonSaga } from './restoreCLesson';
import { saveLessonSaga } from './saveLesson';
import { saveOrCreateLessonSaga } from './saveOrCreateLesson';

export function* lessonSagasWatcher() {
    yield all([
        takeLatest(lessonActions.getLessonAssigns, getLessonAssignsSaga),
        takeLatest(lessonActions.getFullClessonRequest, getFullClessonSaga),
        takeLatest(lessonActions.saveLesson, saveLessonSaga),
        takeLatest(lessonActions.getClessonResultsRequest, getCLessonResultsSaga),
        takeLatest(lessonActions.getClessonWithResultsRequest, getClessonWithResultsSaga),
        takeLatest(lessonActions.restoreCLessonRequest, restoreCLessonSaga),
        takeLatest(lessonActions.removeProblemFromLesson, removeProblemFromLessonSaga),
        takeLatest(lessonActions.removeAllStackProblemsFromLesson, removeProblemFromLessonSaga),
        takeLatest(lessonActions.getLessonRequest, getLessonSaga),
        takeLatest(lessonActions.saveOrCreateLesson, saveOrCreateLessonSaga),
        takeLatest(lessonActions.createLessonByProblemIds, createLessonByProblemIdsSaga),
        takeLatest(lessonActions.finishAddingStudentsRequest, finishAddingStudentsSaga),
    ]);
}

export function* deleteLessonSagasWatcher() {
    yield all([takeLatest(lessonActions.deleteCLesson, deleteCLessonSaga)]);
}

export * from './emitSocketForceCompleteLesson';
