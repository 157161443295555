import { apiConfig } from '@yandex-int/k-common/client/api/config';
import { AppFactory } from 'platform-components';
import * as filesMetaActions from 'platform-components/src/components/files-meta/actions';
import filesMetaReducer from 'platform-components/src/components/files-meta/reducers';
import * as uatraitsActions from 'platform-components/src/components/uatraits/actions';
import uatraitsReducer from 'platform-components/src/components/uatraits/reducers';

import * as userActions from '../user/actions';
import userReducer from '../user/reducers';

const onBeforeMount = ({ route, store }) => {
    const {
        config: { uatraits },
        data: { getMe, getTeacherProfile, getSenderProfile, getParentChildren, getParentProfile },
    } = route;
    const { dispatch } = store;

    dispatch(filesMetaActions.updateFilesMeta([]));
    dispatch(userActions.setMe(getMe));
    if (getTeacherProfile) {
        dispatch(userActions.setTeacherProfile(getTeacherProfile));
        dispatch(userActions.setTeams(getTeacherProfile.teams || []));
    }
    if (getSenderProfile) {
        dispatch(userActions.setSenderProfile(getSenderProfile.sender_profile));
    }
    if (getParentChildren) {
        dispatch(userActions.setParentChildren(getParentChildren));
    }
    if (getParentProfile) {
        dispatch(userActions.setParentProfile(getParentProfile));
    }
    dispatch(uatraitsActions.setUatraits(uatraits));
};

const reducers = {
    filesMeta: filesMetaReducer,
    uatraits: uatraitsReducer,
    user: userReducer,
};

const App = AppFactory(reducers, apiConfig, onBeforeMount);

export default App;
