import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import { SimpleCallback } from 'platform-components';
import React from 'react';
import { useDispatch } from 'react-redux';

import StudentsForm from 'common.components/students-form/students-form';

import { teamBuilderActions } from '../actions';

interface Props {
    loadingStatus: LOADING_STATUSES;
    onAssign: () => void;
    onNext: SimpleCallback;
    shouldLoadTemplates?: boolean;
    loadTemplates?: SimpleCallback;
}

export const TeamBuilderStudents = (props: Props) => {
    const { onAssign, onNext, loadingStatus, shouldLoadTemplates, loadTemplates } = props;
    const dispatch = useDispatch();

    const handleStudentsSubmit = (studentsList: Array<string>) => {
        dispatch(teamBuilderActions.setStudentsData({ students: studentsList }));
        onAssign();
        onNext();
    };

    if (shouldLoadTemplates && loadTemplates) {
        loadTemplates();
    }

    return <StudentsForm loadingStatus={loadingStatus} onSubmit={handleStudentsSubmit} />;
};
