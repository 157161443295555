import b from 'b_';
import PropTypes from 'prop-types';
import React from 'react';

import { IconArrowClassroom } from '../../icon/icon';
import { SelectSuggestTypes } from '../constants';

import './select-suggest__input.scss';

class Input extends React.Component {
    static propTypes = {
        autoFocus: PropTypes.bool,
        disabled: PropTypes.bool,
        name: PropTypes.string,
        onBlur: PropTypes.func,
        onChange: PropTypes.func,
        onClick: PropTypes.func,
        onFocus: PropTypes.func,
        opened: PropTypes.bool,
        placeholder: PropTypes.string,
        type: PropTypes.oneOf([SelectSuggestTypes.INPUT, SelectSuggestTypes.SELECT]),
        value: PropTypes.string,
    };

    state = {};

    handleChange = ({ target: { value: inputText } }) => {
        const { onChange } = this.props;

        onChange(inputText);
    };

    handleFocus = () => {
        const { onFocus } = this.props;
        this.setState({ focused: true });

        if (onFocus) {
            onFocus();
        }
    };

    handleBlur = () => {
        const { onBlur } = this.props;
        this.setState({ focused: false });

        if (onBlur) {
            onBlur();
        }
    };

    render() {
        const { autoFocus, disabled, name, onClick, opened, placeholder, type, value: currentValue } = this.props;

        const { focused } = this.state;

        const direction = opened ? 'up' : 'down';
        const shouldShowArrow = type !== SelectSuggestTypes.INPUT;
        const className = b('select-suggest', 'input-control', { focused });

        return (
            <div className={className} onClick={onClick}>
                <input
                    autoComplete="off"
                    autoFocus={autoFocus}
                    className="select-suggest__input-control-native"
                    disabled={disabled}
                    name={name}
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                    onFocus={this.handleFocus}
                    placeholder={placeholder}
                    value={currentValue}
                />
                {shouldShowArrow && (
                    <div className="select-suggest__input-control-icon">
                        <IconArrowClassroom direction={direction} />
                    </div>
                )}
            </div>
        );
    }
}

export default Input;
