import React from 'react';

import { BearProps } from './bear-types';

export const BearWithPlus = React.forwardRef<SVGSVGElement, BearProps>(({ className, withSafeStroke }, ref) => (
    <svg className={className} fill="none" ref={ref} viewBox="0 0 300 300" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M295.08 76.1059C293.126 90.1705 278.233 95.7639 270.192 97.7763C277.245 125.651 283.266 161.153 279.121 190.987C274.546 223.921 250.813 249.343 220.015 266.072C189.254 282.782 154.177 289.224 131.663 286.096C109.149 282.969 77.1573 267.209 52.1151 242.748C27.0434 218.257 11.1385 187.329 15.7136 154.395C20.6093 119.153 34.6617 88.4239 47.9107 66.0686C40.5906 61.4972 29.8734 52.4659 31.6727 39.5137C34.3673 20.1169 52.2759 6.57703 71.6727 9.27161C82.334 10.7527 88.1277 19.6689 91.1839 27.3994C91.7954 27.2517 92.3986 27.1129 92.9918 26.9821C103.901 24.5766 116.004 23.934 126.734 23.9893C137.609 24.0453 147.847 24.8278 155.309 25.583C159.059 25.9626 162.155 26.34 164.335 26.626C165.426 26.7691 166.291 26.8896 166.897 26.9766C167.2 27.0201 167.439 27.0552 167.609 27.0806L167.709 27.0956L167.813 27.1097C167.988 27.1335 168.231 27.1673 168.539 27.2112C169.155 27.2991 170.03 27.4279 171.13 27.5999C173.328 27.9437 176.435 28.4619 180.174 29.1735C187.61 30.5886 197.746 32.8056 208.286 36.0018C218.682 39.1546 230.242 43.487 240.118 49.3879L240.221 49.4495C245.191 42.5237 253.517 34.5331 264.838 36.1059C284.235 38.8005 297.775 56.7091 295.08 76.1059ZM261.561 188.548C266.249 154.8 256.224 111.017 247.487 82.3338C238.939 54.2732 165.205 44.6477 165.205 44.6477C165.205 44.6477 92.0186 33.3474 76.2687 55.1128C61.6046 75.3776 39.4319 112.508 33.2741 156.834C29.75 182.203 41.7653 207.854 64.5036 230.065C87.4198 252.45 116.076 266.032 134.103 268.536C152.129 271.04 183.403 265.784 211.553 250.493C239.484 235.321 258.037 213.916 261.561 188.548Z"
            fill="url(#paint0_radial_75_24036)"
        />
        <path
            d="M295.08 76.1059C293.126 90.1705 278.233 95.7639 270.192 97.7763C277.245 125.651 283.266 161.153 279.121 190.987C274.546 223.921 250.813 249.343 220.015 266.072C189.254 282.782 154.177 289.224 131.663 286.096C109.149 282.969 77.1573 267.209 52.1151 242.748C27.0434 218.257 11.1385 187.329 15.7136 154.395C20.6093 119.153 34.6617 88.4239 47.9107 66.0686C40.5906 61.4972 29.8734 52.4659 31.6727 39.5137C34.3673 20.1169 52.2759 6.57703 71.6727 9.27161C82.334 10.7527 88.1277 19.6689 91.1839 27.3994C91.7954 27.2517 92.3986 27.1129 92.9918 26.9821C103.901 24.5766 116.004 23.934 126.734 23.9893C137.609 24.0453 147.847 24.8278 155.309 25.583C159.059 25.9626 162.155 26.34 164.335 26.626C165.426 26.7691 166.291 26.8896 166.897 26.9766C167.2 27.0201 167.439 27.0552 167.609 27.0806L167.709 27.0956L167.813 27.1097C167.988 27.1335 168.231 27.1673 168.539 27.2112C169.155 27.2991 170.03 27.4279 171.13 27.5999C173.328 27.9437 176.435 28.4619 180.174 29.1735C187.61 30.5886 197.746 32.8056 208.286 36.0018C218.682 39.1546 230.242 43.487 240.118 49.3879L240.221 49.4495C245.191 42.5237 253.517 34.5331 264.838 36.1059C284.235 38.8005 297.775 56.7091 295.08 76.1059ZM261.561 188.548C266.249 154.8 256.224 111.017 247.487 82.3338C238.939 54.2732 165.205 44.6477 165.205 44.6477C165.205 44.6477 92.0186 33.3474 76.2687 55.1128C61.6046 75.3776 39.4319 112.508 33.2741 156.834C29.75 182.203 41.7653 207.854 64.5036 230.065C87.4198 252.45 116.076 266.032 134.103 268.536C152.129 271.04 183.403 265.784 211.553 250.493C239.484 235.321 258.037 213.916 261.561 188.548Z"
            fill="url(#paint1_radial_75_24036)"
        />
        {withSafeStroke && (
            <path
                d="M165.9 37.9822L166.068 38.0049C166.221 38.0257 166.442 38.0562 166.726 38.0968C167.295 38.1778 168.118 38.2987 169.162 38.4618C171.249 38.7878 174.224 39.283 177.813 39.9651C184.975 41.3261 194.666 43.4461 204.678 46.4778C214.632 49.492 225.211 53.4982 233.986 58.7338C242.568 63.8543 250.611 70.8545 253.512 80.3646C262.231 108.946 272.638 153.705 267.746 188.864C263.821 217.084 243.295 239.946 214.374 255.633C185.337 271.383 152.717 277.077 132.993 274.341C113.268 271.605 83.4377 257.247 59.8001 234.191C36.2561 211.225 22.7482 183.642 26.674 155.422C33.0239 109.778 55.8032 71.8059 70.7041 51.2431C76.2121 43.6422 85.9125 39.8089 95.3615 37.7283C105.121 35.5793 116.278 34.95 126.593 35.003C136.965 35.0564 146.789 35.8036 153.998 36.5322C157.61 36.8972 160.584 37.2595 162.664 37.5319C163.704 37.6681 164.522 37.782 165.085 37.8627C165.367 37.903 165.585 37.9351 165.736 37.9575L165.9 37.9822ZM210.975 249.385C238.745 234.322 257.19 213.071 260.694 187.886C265.355 154.381 255.387 110.913 246.701 82.4364C238.203 54.5778 164.895 45.0216 164.895 45.0216C164.895 45.0216 92.1316 33.8026 76.4727 55.4113C61.8934 75.5303 39.8488 112.394 33.7267 156.401C30.2229 181.586 42.1687 207.053 64.7756 229.104C87.5594 251.328 116.05 264.812 133.973 267.298C151.895 269.784 182.987 264.566 210.975 249.385Z"
                fill="currentColor"
            />
        )}
        <defs>
            <radialGradient
                cx="0"
                cy="0"
                gradientTransform="translate(299.959 40.9849) rotate(142.909) scale(376.092 569.21)"
                gradientUnits="userSpaceOnUse"
                id="paint0_radial_75_24036"
                r="1"
            >
                <stop stopColor="#48CCE0" />
                <stop offset="0.288462" stopColor="#428BEB" />
                <stop offset="0.601763" stopColor="#505ADD" />
            </radialGradient>
            <radialGradient
                cx="0"
                cy="0"
                gradientTransform="translate(-0.0406205 267.8) rotate(7.90884) scale(265.937 754.42)"
                gradientUnits="userSpaceOnUse"
                id="paint1_radial_75_24036"
                r="1"
            >
                <stop stopColor="#FCAB14" />
                <stop offset="0.159058" stopColor="#FA6641" />
                <stop offset="0.402081" stopColor="#BE40C0" />
                <stop offset="1" stopColor="#505ADD" stopOpacity="0" />
            </radialGradient>
        </defs>
    </svg>
));
