import { postFetch } from '../fetch';
import { apiConfig } from '../config';

export interface ClassPayload {
    grade?: string;
    name?: string;
}

export interface PostCreateClassBulkRequestPayload {
    classes: ClassPayload[];
}

export interface ClassResponse {
    teamId: number,
    grade?: number,
    name?: string
}

export interface PostCreateClassBulkResponse {
    classes: ClassResponse[]
}

export function postCreateClassBulk(payload: PostCreateClassBulkRequestPayload): Promise<PostCreateClassBulkResponse> {
    const { apiPostCreateClassBulk } = apiConfig.getStaticUrls();

    return postFetch(apiPostCreateClassBulk, payload);
}
