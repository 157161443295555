import b from 'b_';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './icon__arrow-classroom.scss';

const IconArrowClassroom = ({ direction, mix, onClick, theme = 'normal' }) => {
    // FIXME: arrow-classroom чтоб не сломать существующие стили. Перевести все стрелки такого вида на эту
    const className = cx(b('icon', 'arrow-classroom', { direction, theme }), mix);
    const props = { className, onClick };

    if (theme === 'normal') {
        return (
            <svg {...props} viewBox="0 0 580 1100">
                <path d="M571.021,571.021 C570.426,571.615 569.801,572.159 569.170,572.693 L50.845,1091.019 C39.198,1102.666 20.315,1102.666 8.668,1091.019 C-2.979,1079.372 -2.979,1060.489 8.668,1048.842 L507.667,549.844 L8.668,50.845 C-2.979,39.198 -2.979,20.315 8.668,8.669 C20.315,-2.979 39.198,-2.979 50.845,8.669 L569.170,526.994 C569.801,527.528 570.426,528.072 571.021,528.666 C576.866,534.512 579.776,542.181 579.753,549.843 C579.776,557.506 576.866,565.175 571.021,571.021 Z" />
            </svg>
        );
    } else if (theme === 'nav') {
        return (
            <svg {...props} viewBox="0 0 32 36">
                <path d="M16.065 33.5l-2.121-2.121 15.934-15.935L32 17.566z" />
                <path d="M13.944 3.565l2.12-2.121L32 17.378 29.88 19.5z" />
            </svg>
        );
    }

    return null;
};

IconArrowClassroom.propTypes = {
    direction: PropTypes.oneOf(['up', 'down', 'left', 'right']),
    mix: PropTypes.string,
    onClick: PropTypes.func,
    theme: PropTypes.oneOf(['normal', 'nav']),
};

export default IconArrowClassroom;
