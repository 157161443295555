import React from 'react';
import { injectIntl } from 'react-intl';

import b from 'b_';
import { Input } from 'platform-components/common';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { PRESELECTED_CLASS_LETTER, PRESELECTED_COLLEGE_GROUP_LETTER } from '../../user/class.constants';

import { CLASS_LETTER_EDITOR_VIEW } from './types';

import './class-name-editor__letter-editor.scss';

const LETTER_WIDTH_COEFFICIENT = 0.7;

export class ClassNameLetterEditor extends React.Component {
    static propTypes = {
        disabled: PropTypes.bool,
        intl: PropTypes.object.isRequired,
        onBlur: PropTypes.func,
        onChange: PropTypes.func.isRequired,
        onFocus: PropTypes.func,
        valid: PropTypes.string,
        value: PropTypes.string,
        view: PropTypes.oneOf(Object.values(CLASS_LETTER_EDITOR_VIEW)),
        isCollegeTeam: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = this.getStyleWidths(props.value);
    }

    componentDidUpdate(prevProps) {
        const { isCollegeTeam } = this.props;

        if (prevProps.isCollegeTeam !== isCollegeTeam) {
            // возвращаем ширину поля к исходному
            this.onChange({
                target: { value: isCollegeTeam ? PRESELECTED_COLLEGE_GROUP_LETTER : PRESELECTED_CLASS_LETTER },
            });
        }
    }

    getStyleWidths = (value) => {
        const val = value || '';

        return {
            inputWidth: `${(val.length * LETTER_WIDTH_COEFFICIENT).toFixed(1)}em`,
            editorWidth: `${((val.length + 1) * LETTER_WIDTH_COEFFICIENT).toFixed(1)}em`,
        };
    };

    onChange = ({ target: { value } }) => {
        const { onChange } = this.props;

        this.setState(this.getStyleWidths(value));

        onChange(value);
    };

    render() {
        const { disabled, intl, onBlur, onFocus, valid, value, view, isCollegeTeam } = this.props;

        const isPlainView = view === CLASS_LETTER_EDITOR_VIEW.PLAIN;

        const { editorWidth, inputWidth } = this.state;
        const editorStyle = isCollegeTeam ? null : { width: editorWidth };
        const inputStyle = isPlainView ? null : { width: inputWidth };

        const input = (
            <Input
                disabled={disabled}
                mix={b('class-name-editor__letter-editor-input', { view })}
                onBlur={onBlur}
                onChange={this.onChange}
                onFocus={onFocus}
                placeholder={
                    isCollegeTeam
                        ? PRESELECTED_COLLEGE_GROUP_LETTER
                        : intl.formatMessage({ id: 'classNameEditor.letterEditorInput.placeholder' })
                }
                placeholderAsInput={true}
                style={inputStyle}
                valid={valid}
                value={value}
            />
        );

        if (isPlainView) {
            return input;
        }

        return (
            <div className={b('class-name-editor__letter-editor', { isCollegeTeam })} style={editorStyle}>
                <span className="class-name-editor__letter-quote">&laquo;</span>
                {input}
                <span className="class-name-editor__letter-quote">&raquo;</span>
            </div>
        );
    }
}

export default compose(injectIntl)(ClassNameLetterEditor);
