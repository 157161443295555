import {
    STATUS_INITIAL,
    STATUS_INCORRECT,
    STATUS_CORRECT,
} from '@yandex-int/edu-components/__internal__/cjs-src/typings/answerStatus';
import { MARKER_THEMES, ANSWER_STATUS } from './marker/marker.constants';

export enum ANSWER_STATUSES {
    INCORRECT = 0,
    CORRECT = 1,
    NOT_EVALUATED = 2,
    SKIPPED_CORRECT = 3, // статус присутствует только на фронтенде
}

export enum AVATARS {
    ALISA = 'alisa',
    GIRAFFE = 'giraffe',
    PARROT = 'parrot',
    ZEBRA = 'zebra',
    YANDEX = 'yandex',
    DEDUS = 'dedus',
}

export enum BLOCK_TEXT_OPTIONS_STYLE {
    NORMAL = 'normal',
    BIG = 'big',
    ANSWER = 'answer',
    INSTRUCTION = 'instruction',
}

export const STATUS_TO_THEME = {
    [ANSWER_STATUSES.CORRECT]: MARKER_THEMES.CORRECT,
    [ANSWER_STATUSES.INCORRECT]: MARKER_THEMES.INCORRECT,
    [ANSWER_STATUSES.NOT_EVALUATED]: MARKER_THEMES.NORMAL,
};

export const ANSWER_STATUS_TO_THEME = {
    [ANSWER_STATUS.CORRECT]: MARKER_THEMES.CORRECT,
    [ANSWER_STATUS.INCORRECT]: MARKER_THEMES.INCORRECT,
    [ANSWER_STATUS.SKIPPED]: MARKER_THEMES.SKIPPED,
};

export const EDU_COMPONENTS_ANSWER_STATUS = {
    STATUS_INITIAL,
    STATUS_INCORRECT,
    STATUS_CORRECT,
} as const;

export enum GRAVITIES {
    TOP = 'top',
    TOP_RIGHT = 'top-end',
    BOTTOM_RIGHT = 'bottom-end',
    BOTTOM = 'bottom',
    LEFT = 'left',
    LEFT_BOTTOM = 'left-end',
    RIGHT = 'right',
}

export const POPUP_DIRECTIONS = GRAVITIES;

export enum POPUP_INDENTS {
    NORMAL = 8,
    SMALL = 2,
    NOINDENT = 0,
}

export enum POPUP_POSITIONS {
    CONTROL = 'control',
    PORTAL_LAYER = 'portal-layer',
    TOP = 'top',
}

export enum POPUP_THEMES {
    NORMAL = 'normal',
    DARK = 'dark',
    DARK_ROUNDED = 'dark-rounded',
    CLEAN = 'clean',
    SCHOOL = 'school',
    CLEAN_SHADOW = 'clean-shadow',
    PEARL = 'pearl',
    LIGHT_ROUNDED = 'light-rounded',
}

export enum POPUP_AXIS {
    START = 'start',
    CENTER = 'center',
    END = 'end',
    BOTH = 'both',
}

export enum KEY_CODES {
    BACKSPACE = 8,
    TAB = 9,
    ENTER = 13,
    SPACE = 32,
    ESCAPE = 27,
    ARROW_UP = 38,
    ARROW_DOWN = 40,
    ARROW_LEFT = 37,
    ARROW_RIGHT = 39,
}

export const INPUT_TYPES = {
    CHOICE: 'choice',
    COMMA: 'comma',
    FIELD: 'field',
    SEQUENCE: 'sequence',
    RATIONAL: 'rational',
    SEPARATOR: 'separator',
};

export const INPUT_THEMES = {
    NORMAL: 'normal',
    BORDERLESS: 'borderless',
    YANDEX: 'yandex',
    STUDENT: 'student',
};

export const INPUT_SIZES = {
    M: 'm',
    L: 'l',
    XL: 'xl',
};

export enum BUTTON_THEMES {
    NORMAL = 'normal',
    LIGHT = 'light',
    RECTANGLE = 'rectangle',
}

export enum BUTTON_VIEWS {
    ACTION = 'action',
    MATT = 'matt',
    TRANSPARENT = 'transparent',
    WHITE = 'white',
    DARK = 'dark',
    PASSIVE = 'passive',
    BLACK = 'black',
    BLUE = 'blue',
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    ICON = 'icon',
    LINK = 'link',
    SUCCESS = 'success',
}

export enum BUTTON_SIZES {
    M = 'm',
    L = 'l',
    XL = 'xl',
    XXL = 'xxl',
}

export enum LIST_BULLETS {
    NONE = 'none',
    NUMBER = 'number',
    DOT = 'dot',
}

export enum CHECKBOX_DIRECTIONS {
    DEFAULT = 'default',
    REVERSED = 'reversed',
}

export enum CHECKBOX_SIZES {
    MEDIUM = 'm',
    LARGE = 'l',
}

export enum CHECKBOX_TYPES {
    NORMAL = 'normal',
    GREEN = 'green',
    BLUE = 'blue',
    GREY = 'grey',
    TEXT = 'text',
}

export enum RADIO_THEMES {
    NORMAL = 'normal',
    CLEAR = 'clear',
}

export enum RADIO_SIZES {
    MEDIUM = 'm',
    LARGE = 'l',
}

export enum RADIO_TYPES {
    LINK = 'link',
    RADIO = 'radio',
    BUTTON = 'button',
    TAB = 'tab',
    TEXT = 'text',
    LIST = 'list',
    BULLET = 'bullet',
}

export enum CONTROL_GROUP_ALIGNS {
    VERTICAL = 'vertical',
    HORIZONTAL = 'horizontal',
}

export enum NOTIFICATION_THEMES {
    NORMAL = 'normal',
    FAIL = 'fail',
    SUCCESS = 'success',
    TOAST = 'toast',
}

export enum SCREEN_QUERY {
    SMALL = '(max-width: 1239px)',
    MEDIUM = '(min-width: 1240px) and (max-width: 1399px)',
    LARGE = '(min-width: 1400px)',
}

export enum PROBLEM_THEMES {
    SCHOOL = 'school',
}

export enum TEXT_SIZE {
    XS = 'xs',
    S = 's',
    M = 'm',
    L = 'l',
    XL = 'xl',
    // унаследовать размер от родительского элемента
    INHERIT = 'inherit',
    // для поддержки прежней верстки
    XXL = 'xxl',
    XXXL = 'xxxl',
}

export enum HEADER_TEXT_SIZE {
    XXS = 'xxs',
    XS = 'xs',
    S = 's',
    M = 'm',
    L = 'l',
    XL = 'xl',
    XXL = 'xxl',
}

export enum TEXT_TAG {
    LI = 'li',
    P = 'p',
    SPAN = 'span',
}

export enum TEXT_WEIGHT {
    S = 's',
    M = 'm',
    L = 'l',
}

export enum TEXT_MARKUP_TYPE {
    HTML = 'html',
    MARKDOWN = 'markdown',
    PLAIN = 'plain',
}

export const COPYRIGHT_TYPES = {
    YANDEX: 'YANDEX',
    SERVICE: 'SERVICE',
};

export type OffsetValue = number | string;

export const DEFAULT_PAGE_TO_LOAD = 1;

export { BUBBLE_THEMES, BUBBLE_TAIL_ALIGN, BUBBLE_TAIL_VERTICAL_ALIGN } from './bubble/bubble.constants';
export { ACCORDEON_THEMES } from './accordeon/accordeon.constants';
export { INLINE_INPUT_DISPLAY_TYPES } from './inline-input/constants';
export { HELP_ACTION, HELP_SIZE, HELP_THEME, HELP_TYPE } from './help/help.constants';
export { INPUT_VALID_STATE } from './input/input.constants';
export { HIT_AREA_BADGE_ALIGNMENT } from './marker-chooseimage/marker-chooseimage.constants';
export { CHOICE_TYPES } from './marker-dragimage/marker-dragimage-desktop/constants';
export { PAGINATOR_BUTTON_VIEW, PAGINATOR_ORIENTATION, PAGINATOR_THEME } from './paginator/paginator.constants';
export { MARKER_STATUS_MODE } from './marker/marker.constants';
export { SELECT_SIZES, SELECT_THEMES } from './select/constants';
export { MODAL_FADE_THEMES } from './modal/types';
export { HIT_AREA_GRAVITY } from './marker-polylines/types';
export { SPIN_SIZES, SPIN_THEMES } from './spin/constants';
export { KEYBOARD_TYPES } from './keyboard/types';
export { DEFAULT_VIEWPORT_META } from './page/constants';
export { LOGO_THEME } from './logo/types';
export { SELECT_TYPE } from './select-native/types';
export { SCREEN_RESOLUTIONS } from './image/constants';

export enum UI_KIT_HEADER_TYPOGRAPHY {
    headlineXXLBold = 'headlineXXLBold',
    headlineXXLBoldSerif = 'headlineXXLBoldSerif',
    headlineXLBold = 'headlineXLBold',
    headlineXLBoldSerif = 'headlineXLBoldSerif',
    headlineLMedium = 'headlineLMedium',
    headlineMBold = 'headlineMBold',
    headlineMMedium = 'headlineMMedium',
    headlineSMedium = 'headlineSMedium',
    headlineSRegular = 'headlineSRegular',
    headlineXSBold = 'headlineXSBold',
    headlineXSMedium = 'headlineXSMedium',
    headlineXSRegular = 'headlineXSRegular',
    headlineXXSMedium = 'headlineXXSMedium',
    headlineXXSRegular = 'headlineXXSRegular',
}

export enum UI_KIT_TEXT_TYPOGRAPHY {
    headlineLBold = 'headlineLBold',
    headlineLMedium = 'headlineLMedium',
    headlineMMedium = 'headlineMMedium',
    headlineSMedium = 'headlineSMedium',
    headlineXSMedium = 'headlineXSMedium',
    headlineXSRegular = 'headlineXSRegular',
    paragraphXLMedium = 'paragraphXLMedium',
    paragraphXLRegular = 'paragraphXLRegular',
    paragraphLMedium = 'paragraphLMedium',
    paragraphLRegular = 'paragraphLRegular',
    paragraphMMedium = 'paragraphMMedium',
    paragraphMRegular = 'paragraphMRegular',
    paragraphSMedium = 'paragraphSMedium',
    paragraphSRegular = 'paragraphSRegular',
    captionXSRegular = 'captionXSRegular',
    captionXSMediumWider = 'captionXSMediumWider',
}

export enum QR_CODE_SIZE {
    S = 's',
    M = 'm',
    L = 'l',
    XL = 'xl',
}

export enum QR_CODE_BG_COLOR {
    BLUE = '#def0ff',
    WHITE = '#ffffff',
}

// параметры query от МЭШ, которые должны оставаться в query на протяжении всей сессии учителя
export const MES_QUERY_PARAMS = {
    BACK_URL: 'back_url',
    CLASS_LEVEL_ID: 'class_level_id',
    CLASS_ID: 'class_id',
    LMS: 'lms',
    REJECT_RETPATH: 'rejectRetpath',
    RETPATH: 'retpath',
    STAFF_ID: 'staff_id',
    STUDY_LEVEL_ID: 'study_level_id',
    SUBJECT_ID: 'subject_id',
    DU_ID: 'du_id',
    LESSON_ID: 'lesson_id',
} as const;

// квери параметры plucker
export const TEACHER_QUERY_PARAMS = {
    REFERRER_LINK_ID: 'referrerLinkId',
} as const;

// query параметры, прокидываемые на все роуты
export const DRILLED_QUERY_PARAMS = {
    [TEACHER_QUERY_PARAMS.REFERRER_LINK_ID]: true,
};

// trigger build new version 6
