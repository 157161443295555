import b from 'b_';
import { SimpleCallback, Text } from 'platform-components';
import React from 'react';

import './placeholder-textarea.scss';

export enum VALID_OPTIONS {
    UNCERTAIN = 'uncertain',
    NO = 'no',
}

interface PlaceholderTextareaProps {
    autofocus?: boolean;
    errorText?: string;
    items?: Array<{ name: string; valid: VALID_OPTIONS }>;
    onBlur: SimpleCallback;
    onTextareaChange: (items: Array<string>) => void;
    placeholderCount: number;
    placeholderText: string;
    rowCount: number;
}

interface CaretPosition {
    selectionStart: number;
    selectionEnd: number;
}

export class PlaceholderTextarea extends React.Component<PlaceholderTextareaProps> {
    componentDidMount(): void {
        if (this.textareaRef) {
            this.textareaRef.selectionStart = 0;
            this.textareaRef.selectionEnd = 0;
        }
    }

    componentDidUpdate(prevProps: Readonly<PlaceholderTextareaProps>): void {
        const { items } = this.props;

        if (items !== prevProps.items && this.textareaRef !== null) {
            const { selectionEnd, selectionStart } = this.caretPosition;

            this.textareaRef.selectionStart = selectionStart;
            this.textareaRef.selectionEnd = selectionEnd;
        }
    }

    private caretPosition: CaretPosition;
    private textareaRef: HTMLTextAreaElement | null = null;

    onTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { selectionEnd, selectionStart, value } = e.target;
        const { onTextareaChange } = this.props;
        const items = value
            .replace(/\\n\\n/g, '\n')
            .replace(/↵/g, '\n')
            .replace(/\t/g, ' ') // это символ таба, бывает при вставке из excel
            .split('\n')
            .map((item) => item.trimStart());

        this.caretPosition = { selectionEnd, selectionStart };

        onTextareaChange(items);
    };

    setTextareaRef = (ref: HTMLTextAreaElement) => {
        this.textareaRef = ref;
    };

    render() {
        const { autofocus, errorText, items = [], onBlur, placeholderCount, placeholderText, rowCount } = this.props;
        const textAreaRowsCount = Math.max(rowCount, items.length);
        const rows: Array<number> = new Array(textAreaRowsCount).fill(null).map((value: null, index: number) => index);
        const itemsText = items.map((item) => item.name).join('\n');

        return (
            <div className="placeholder-textarea">
                <div className={b('placeholder-textarea', 'wrapper', { valid: errorText ? 'no' : 'yes' })}>
                    <div className="placeholder-textarea__rows">
                        {rows.map((rowNumber: number, index: number) => {
                            const item = items[index];
                            const isItemEmpty = !(item && item.name.trim().length > 0);
                            const shouldShowPlaceholder = isItemEmpty && index < placeholderCount;
                            const invalid = item ? item.valid === VALID_OPTIONS.NO : false;

                            return (
                                <span
                                    className={b('placeholder-textarea', 'number', {
                                        placeholder: shouldShowPlaceholder,
                                        invalid,
                                    })}
                                    data-placeholder={placeholderText}
                                    key={rowNumber}
                                >
                                    <Text>{rowNumber + 1}.</Text>
                                </span>
                            );
                        })}
                    </div>
                    <textarea
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        autoFocus={autofocus}
                        className="placeholder-textarea__textarea"
                        onBlur={onBlur}
                        onChange={this.onTextChange}
                        ref={this.setTextareaRef}
                        rows={textAreaRowsCount}
                        value={itemsText}
                        wrap="off"
                    />
                </div>
                {errorText && (
                    <div className="placeholder-textarea__error">
                        <Text id={errorText} />
                    </div>
                )}
            </div>
        );
    }
}

export default PlaceholderTextarea;
