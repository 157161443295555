import b from 'b_';
import { Button, Text, H4, SimpleText, SimpleCallback } from 'platform-components';
import React from 'react';
import { accessibleClick } from '@edu-frontend/modules/src/shared/lib/a11y';

import './registration_theme_default.scss';

interface OwnProps {
    toTeacherRegistration: SimpleCallback;
    onParentButtonClick: SimpleCallback;
}

const DefaultRegistration = (props: OwnProps) => {
    const { onParentButtonClick, toTeacherRegistration } = props;

    return (
        <div className={b('registration__body', { theme: 'default' })}>
            <div
                className="registration__role-column registration_type_teacher"
                {...accessibleClick(toTeacherRegistration)}
            >
                <div>
                    <div className="registration__role-title">
                        <H4 id="registration.main.role.teacher.title" />
                    </div>
                    <div className="registration__role-description">
                        <Text id="registration.main.role.teacher.description" />
                    </div>
                </div>
                <Button className="registration__button">
                    <SimpleText id="registration.main.chooseRole" />
                </Button>
            </div>
            <div
                className="registration__role-column registration_type_parent"
                {...accessibleClick(onParentButtonClick)}
            >
                <div>
                    <div className="registration__role-title">
                        <H4 id="registration.main.role.parent.title" />
                    </div>
                    <div className="registration__role-description">
                        <Text id="registration.main.role.parent.description" />
                    </div>
                </div>
                <Button className="registration__button">
                    <SimpleText id="registration.main.chooseRole" />
                </Button>
            </div>
        </div>
    );
};

export default DefaultRegistration;
