import b from 'b_';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './inline-input_type_comma.scss';
import { KEY_CODES } from '../../constants';
import { INLINE_INPUT_DISPLAY_TYPES } from '../constants';

class InlineInputTypeComma extends React.Component {
    static propTypes = {
        answer: PropTypes.bool,
        className: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        onKeyDown: PropTypes.func,
        options: PropTypes.shape({
            // eslint-disable-next-line camelcase
            type_display: PropTypes.oneOf([INLINE_INPUT_DISPLAY_TYPES.VISIBLE, INLINE_INPUT_DISPLAY_TYPES.INVISIBLE]),
        }),
        readOnly: PropTypes.bool,
        setAnswer: PropTypes.func.isRequired,
        showCorrectAnswer: PropTypes.bool,
        theme: PropTypes.string,
        userAnswer: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            correcting: false,
        };
    }

    componentDidMount() {
        const { setAnswer, userAnswer } = this.props;
        const processedUserAnswer = this.processUserAnswer(userAnswer);

        setAnswer(processedUserAnswer);
    }

    getInputValue = ({ answer, userAnswer, showCorrectAnswer }) => {
        const processedUserAnswer = this.processUserAnswer(userAnswer);

        return showCorrectAnswer ? answer : processedUserAnswer;
    };

    processUserAnswer = (userAnswer) => {
        const { options: { type_display: typeDisplay } = {} } = this.props;

        if (typeDisplay === INLINE_INPUT_DISPLAY_TYPES.INVISIBLE) {
            return Boolean(userAnswer);
        } else if (typeof userAnswer === 'boolean') {
            return userAnswer;
        }

        return null;
    };

    handleClick = () => {
        const { readOnly, setAnswer } = this.props;

        if (readOnly) {
            return;
        }

        const nextValue = !this.getInputValue(this.props);
        setAnswer(nextValue);
    };

    handleKeyDown = (e) => {
        const { onKeyDown } = this.props;
        if (e.keyCode === KEY_CODES.SPACE) {
            this.handleClick();
        }
        onKeyDown(e);
    };

    render() {
        const { answer, className, options: { type_display: typeDisplay } = {}, theme } = this.props;
        const value = this.getInputValue(this.props);
        const toggleAnswer = typeof answer === 'boolean' && theme === 'incorrect';
        const controlClass = b('inline-input', {
            'toggle-answer': toggleAnswer,
            'type-display': typeDisplay,
        });
        let state;

        if (value === true) {
            state = 'checked';
        } else if (value === false || typeDisplay === INLINE_INPUT_DISPLAY_TYPES.INVISIBLE) {
            state = 'unchecked';
        }

        return (
            <span className={cx(className, controlClass)}>
                <span
                    className={b('inline-input', 'comma-control', { state })}
                    data-switchable="true"
                    onClick={this.handleClick}
                    onKeyDown={this.handleKeyDown}
                    onMouseEnter={toggleAnswer ? this.onMouseEnter : null}
                    onMouseLeave={toggleAnswer ? this.onMouseLeave : null}
                    tabIndex="0"
                />
            </span>
        );
    }
}

export default InlineInputTypeComma;
