import b from 'b_';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './form-view__card-section.scss';

const FormViewCardSection = React.forwardRef(({ children, mix, secondary }, ref) => (
    <div className={cx(b('form-view', 'card-section', { secondary }), mix)} ref={ref}>
        {children}
    </div>
));

FormViewCardSection.propTypes = {
    children: PropTypes.node.isRequired,
    mix: PropTypes.string,
    secondary: PropTypes.bool,
};

FormViewCardSection.displayName = 'FormViewCardSection';

export default FormViewCardSection;
