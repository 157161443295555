import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './form-view__card.scss';

const FormViewCard = React.forwardRef(({ children, mix }, ref) => (
    <div className={cx('form-view__card', mix)} ref={ref}>
        {React.Children.map(children, (child) => {
            if (child === null) {
                return null;
            }

            const innerMix = cx(child.props.mix, 'form-view__card-element');
            const className = cx(child.props.className, 'form-view__card-element');

            return React.cloneElement(child, {
                mix: innerMix,
                className,
            });
        })}
    </div>
));

FormViewCard.propTypes = {
    children: PropTypes.node.isRequired,
    mix: PropTypes.string,
};

export Section from '../__card-section/form-view__card-section.jsx';
export default FormViewCard;
