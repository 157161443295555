import cx from 'classnames';
import { stringify } from 'querystring';
import React from 'react';

import './yandex-vh-player.scss';

const DEFAULT_VIDEO_PROPS = {
    from: 'partner',
    mute: 0,
    autoplay: 0,
    branding: 0,
    hidden: 'externalPlayback,live,timelinePreview',
    recommendations: 'off',
    source: 'uchebnik',
};

interface Props {
    mix?: string;
    videoId: string;
}

const YandexVhPlayer = ({ mix, videoId, ...videoProps }: Props) => {
    const query = { ...DEFAULT_VIDEO_PROPS, ...videoProps };
    const url = `https://frontend.vh.yandex.ru/player/${videoId}?${stringify(query)}`;
    const autoplayPolicy = query.autoplay ? '' : " 'none'";

    return (
        <iframe
            allow={`autoplay${autoplayPolicy}; fullscreen; accelerometer; gyroscope; picture-in-picture; encrypted-media`}
            allowFullScreen
            className={cx('yandex-vh-player', mix)}
            frameBorder="0"
            scrolling="no"
            src={url}
        />
    );
};

export default YandexVhPlayer;
