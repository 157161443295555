import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PRESELECTED_CLASS_LETTER, PRESELECTED_CLASS_NUMBER } from 'common.components/user/class.constants';

import { TeacherRegistrationData, TeacherRegistrationPageStore } from './typings';

const initialState: TeacherRegistrationPageStore = {
    registrationData: {
        class: {
            letter: PRESELECTED_CLASS_LETTER,
            number: PRESELECTED_CLASS_NUMBER,
        },
        students: [],
    },
};

const slice = createSlice({
    name: 'TEACHER_REGISTRATION_PAGE',
    initialState: initialState,
    reducers: {
        setRegistrationData: (state, action: PayloadAction<TeacherRegistrationData>) => {
            const registrationData = action.payload;

            state.registrationData = { ...state.registrationData, ...registrationData };
        },
    },
});

export const actions = slice.actions;
export const reducer = slice.reducer;
