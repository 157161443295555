import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
//@ts-ignore
import { getMe } from 'platform-components/src/components/user/selectors';
import { withRouterPath, InjectedProps as WithRouterPathProps } from 'platform-components/hocs';
import { getFromLocalStorage } from 'platform-components/utils';
import PropTypes from 'prop-types';
import qs, { stringify } from 'query-string';
import React from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { LIBRARY_TYPE, SEARCH_PARAMS_INTEGRATION } from 'common.components/constants';
import { teamBuilderActions } from 'common.components/team-builder/actions';
import { TeamBuilderContainer } from 'common.components/team-builder/team-builder.container';
import { TEAM_BUILDER_ENTRY_STEPS } from 'common.components/team-builder/typings';
import { School as SchoolType } from 'common.components/typings';

import { ReduxIndexStore, RootAction } from '../../typings';
import { actions as teacherRegistrationPageActions } from '../teacher-registration-page/actions';
import TeacherRegistrationPage from '../teacher-registration-page/teacher-registration-page';

import * as selectors from './selectors';

interface HOCProps extends WithRouterPathProps, WrappedComponentProps {}

type StoreProps = ReturnType<typeof mapStateToProps>;

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

interface Props extends HOCProps, StoreProps, DispatchProps {}

export class ExternalRegistration extends React.Component<Props> {
    static contextTypes = {
        basePath: PropTypes.string.isRequired,
        prefix: PropTypes.string.isRequired,
        staticUrls: PropTypes.object.isRequired,
    };

    constructor(props: Props) {
        super(props);

        const { me, moscowAddress, setRegistrationData } = props;
        const { id: userId } = me || {};

        const registrationData = getFromLocalStorage('registrationData', null);
        let userRegistrationData = registrationData && userId ? get(JSON.parse(registrationData), userId) : null;

        if (!userRegistrationData || !isEqual(userRegistrationData.address, moscowAddress)) {
            setRegistrationData({
                address: moscowAddress,
            });
        }
    }

    componentDidMount() {
        const { startTeamBuilder } = this.props;

        startTeamBuilder({
            entryPoint: TEAM_BUILDER_ENTRY_STEPS.SCHOOL_FOR_MES,
            // @ts-ignore
            onSuccess: this.handleSuccess,
        });
    }

    context!: React.ContextType<
        React.Context<{
            staticUrls: Record<string, string>;
            basePath: string;
            prefix: string;
        }>
    >;

    handleSuccess = ({ school }: { school: SchoolType }) => {
        const {
            getRouterPath,
            location,
            match: {
                params: { lms },
            },
        } = this.props;
        const {
            basePath,
            prefix,
            staticUrls: { externalCreateLesson, externalLibraryParams, labLibrary },
        } = this.context;
        const path = getRouterPath(externalCreateLesson);
        const searchParams = qs.parse(location.search);
        const { library } = searchParams;
        const libraryPath = LIBRARY_TYPE.LAB === library ? labLibrary : externalLibraryParams;
        const retPath = encodeURI(`${basePath}${prefix}${getRouterPath(libraryPath)}`);
        const params = stringify({
            ...searchParams,
            [SEARCH_PARAMS_INTEGRATION.LMS]: lms,
            [SEARCH_PARAMS_INTEGRATION.RETPATH]: retPath,
            [SEARCH_PARAMS_INTEGRATION.SCHOOL_ID]: school.id,
        });

        window.location.href = `${basePath}${prefix}${path}?${params}`;
    };

    render() {
        return (
            <TeacherRegistrationPage>
                <TeamBuilderContainer />
            </TeacherRegistrationPage>
        );
    }
}

const mapStateToProps = (storeState: ReduxIndexStore, { intl }: HOCProps) => ({
    me: getMe(storeState),
    moscowAddress: selectors.getMoscowAddress(storeState, { intl }),
});

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => {
    const { setRegistrationData } = teacherRegistrationPageActions;

    return bindActionCreators(
        {
            setRegistrationData,
            startTeamBuilder: teamBuilderActions.start,
        },
        dispatch
    );
};

export default injectIntl(withRouterPath(connect(mapStateToProps, mapDispatchToProps)(ExternalRegistration)));
