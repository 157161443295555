import { CampaignAwards } from '../../../typings';
import { getFetch } from '../fetch';
import { apiConfig } from '../config';

export interface ChildClessonAwardsRequestData {
    clessonId: number;
    childId: number;
}

export type ChildClessonAwardsResponseData = CampaignAwards[];

export function getChildClessonAwards(request: ChildClessonAwardsRequestData): Promise<ChildClessonAwardsResponseData> {
    const { clessonId, childId } = request;
    const { apiGetChildClessonAwards } = apiConfig.getStaticUrls();

    return getFetch(apiGetChildClessonAwards, {
        'clesson_id': clessonId,
        'child_id': childId,
    });
}
