import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Logo from 'common.components/logo/logo';
import User from 'common.components/user/user';

import './header.scss';

const Header = (props, { service }) => {
    const { children, mix, noUser, retpath, style, theme, title } = props;
    const className = cx('header', mix);

    return (
        <header className={className} style={style}>
            <div className="header__item header__service">
                <Logo service={service} theme={theme} />
            </div>
            {title && <div className="header__item header__title">{title}</div>}
            <div className="header__item header__content">{children}</div>
            {!noUser && (
                <div className="header__item header__user">
                    <User retpath={retpath} theme={theme} />
                </div>
            )}
        </header>
    );
};

Header.propTypes = {
    children: PropTypes.node,
    mix: PropTypes.string,
    noUser: PropTypes.bool,
    retpath: PropTypes.string,
    style: PropTypes.object,
    theme: PropTypes.string,
    title: PropTypes.node,
};

Header.contextTypes = {
    service: PropTypes.string.isRequired,
};

export default Header;
