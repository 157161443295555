import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import { createSelector } from 'reselect';

import { CopyLessonModalStore } from './typings';

const storeStateSelector = (storeState: any): CopyLessonModalStore => storeState.copyLessonModal;

export const getShouldShowModal = createSelector(
    storeStateSelector,
    (state: CopyLessonModalStore): boolean => state.shouldShowModal
);

export const getLessonCopyName = createSelector(
    storeStateSelector,
    (state: CopyLessonModalStore): string | null => state.newName
);

export const getLessonCopyCreationType = createSelector(
    storeStateSelector,
    (state: CopyLessonModalStore): string | null => state.creationType
);

export const getLessonSourceId = createSelector(
    storeStateSelector,
    (state: CopyLessonModalStore): number | null => state.sourceId
);

export const getCopyStatus = createSelector(
    storeStateSelector,
    (state: CopyLessonModalStore): LOADING_STATUSES => state.copyLessonLoadingStatus
);
