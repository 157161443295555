import { ComponentProps } from 'react';

import { compose, composeU } from '@bem-react/core';

import { withThemeLink, withThemeText } from '..';
import { Link as LinkBase } from '../Link';

export const Link = compose(composeU(withThemeLink, withThemeText))(LinkBase);

export type ILinkProps = ComponentProps<typeof Link>;
