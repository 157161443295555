import b_ from 'b_';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import InputNumber from '../../input-number/input-number';
import KeyboardGroup from '../../keyboard/__group/keyboard__group';
import { KeyboardGroupsWrapper } from '../../keyboard/__group/keyboard__groups-wrapper';
import KeyboardContext from '../../keyboard/keyboard-context';
import { ARROW_NEXT, BACKSPACE, DECIMAL_SEPARATOR, NUMPAD, OPERATORS } from '../../keyboard/keyboard.keys';
import { KEYBOARD_TYPES } from '../../keyboard/types';
import UseKeyboard from '../../keyboard/use-keyboard';
import { Tooltip } from '../../LegoTooltip/LegoTooltip';
import { DIVIDER_WIDTH, LETTER_WIDTH, MAX_URL_LENGTH, NUMBER_WIDTH } from '../constants';
import { scrollToViewInlineInput } from '../utils';

import './inline-input_type_field.scss';

const b = b_.with('inline-input');

class InlineInputTypeField extends React.Component {
    static contextType = KeyboardContext;

    static propTypes = {
        answer: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
        className: PropTypes.string,
        featureFlags: PropTypes.object,
        forceFocus: PropTypes.bool,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        onKeyDown: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        options: PropTypes.object.isRequired,
        readOnly: PropTypes.bool,
        setAnswer: PropTypes.func.isRequired,
        showCorrectAnswer: PropTypes.bool,
        theme: PropTypes.string,
        userAnswer: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        valid: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            isFocused: false,
        };

        this.inputRef = React.createRef();
        this.rootRef = React.createRef();
    }

    componentDidMount() {
        const { forceFocus } = this.props;

        if (forceFocus) {
            this.focus();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { forceFocus } = this.props;
        const { forceFocus: prevForceFocus } = prevProps;

        if (forceFocus && forceFocus !== prevForceFocus) {
            this.focus();
        }

        const { isFocused } = this.state;
        if (isFocused !== prevState.isFocused && isFocused) {
            // ждем появления ref на keyboard и изменения margin-top для problem-bar
            setTimeout(() => {
                const { refs } = this.context;
                const { bottom } = this.rootRef.current.getBoundingClientRect();

                // + 4.5, тк rootRef меньше inline-input
                scrollToViewInlineInput(bottom + 4.5, refs[KEYBOARD_TYPES.PRIMARY]);
            }, 50);
        }
    }

    formatAnswer = (answer) => {
        return Array.isArray(answer) ? answer[0] : answer;
    };

    getInputValue = ({ answer, userAnswer, showCorrectAnswer }) => {
        return showCorrectAnswer ? this.formatAnswer(answer) : userAnswer || '';
    };

    handleChange = ({ target: { value } }) => {
        if (this.isUrl() && typeof value === 'string') {
            this.setValue(value.trim());
        } else {
            this.setValue(value);
        }
    };

    setValue = (value) => {
        const { setAnswer, showCorrectAnswer } = this.props;

        if (!showCorrectAnswer) {
            setAnswer(value || null);
        }
    };

    isNumber = () => {
        const {
            options: { type_content: typeContent },
        } = this.props;

        return typeContent === 'number';
    };

    isUrl = () => {
        const {
            options: { type_content: typeContent },
        } = this.props;

        return typeContent === 'url';
    };

    focus = () => {
        this.inputRef.current.focus();
    };

    getInputWidth = () => {
        const { options } = this.props;
        const inputWidth = options && options.width;
        const isNumberInput = this.isNumber();
        const symbolWidth = isNumberInput ? NUMBER_WIDTH : LETTER_WIDTH;

        if (!inputWidth) {
            return undefined;
        }

        if (isNumberInput) {
            const dividersCount = inputWidth <= 4 ? 0 : Math.floor((inputWidth - 1) / 3); // число 10 000 имеет 1 разделитель
            const dividersLength = dividersCount * DIVIDER_WIDTH;

            return `${options.width * symbolWidth + dividersLength}em`;
        }

        return `${options.width * symbolWidth}em`;
    };

    handleFocus = () => {
        const { onFocus } = this.props;

        this.setState({ isFocused: true });

        if (onFocus) {
            onFocus();
        }
    };

    handleBlur = () => {
        const { onBlur } = this.props;

        this.setState({ isFocused: false });

        if (onBlur) {
            onBlur();
        }
    };

    handleKeyboardKey = (key) => {
        switch (key.value) {
            case 'Backspace':
                this.handleKeyboardBackspace();
                break;
            case 'Enter':
                this.context[KEYBOARD_TYPES.PRIMARY].handleKeyboardArrow();
                break;
            default:
                this.inputRef.current.insertText(key.value);
        }
    };

    handleKeyboardBackspace = () => {
        this.inputRef.current.backspace();
    };

    isUsingKeyboard = () => {
        const { keyboardAllowed } = this.context[KEYBOARD_TYPES.PRIMARY];

        return this.isNumber() && keyboardAllowed;
    };

    shouldBeReadOnly = () => {
        const { readOnly } = this.props;
        const { shouldDisableNativeMobileKeyboard } = this.context[KEYBOARD_TYPES.PRIMARY];

        return readOnly || (this.isUsingKeyboard() && shouldDisableNativeMobileKeyboard);
    };

    shouldShowKeyboard = () => {
        const { readOnly } = this.props;
        const { isFocused } = this.state;

        return !readOnly && isFocused && this.isUsingKeyboard();
    };

    render() {
        /* eslint-disable camelcase */
        const {
            className,
            onKeyDown,
            readOnly,
            showCorrectAnswer,
            valid,
            options: { decoration, decimal_separator, width },
        } = this.props;
        const value = this.getInputValue(this.props);

        const isNumber = this.isNumber();
        const InputComponent = isNumber ? InputNumber : 'input';

        const TABINDEX_PREVENT = -1;
        const TABINDEX_DEFAULT = 0;

        const isTypeContentUrl = this.isUrl();

        return (
            <span className={cx(b({ decoration }), className)} ref={this.rootRef}>
                {isTypeContentUrl && (
                    <Tooltip
                        anchor={this.inputRef}
                        direction="top"
                        hasTail
                        mainOffset={13}
                        scope={this.rootRef}
                        size="s"
                        view="default"
                        visible={valid === false}
                    >
                        <FormattedMessage
                            id="inlineInput.field.invalidUrlDomainsTooltip"
                            values={{ linkTo: this.props.options.invalid_url_tooltip }}
                        />
                    </Tooltip>
                )}
                <InputComponent
                    autoComplete="off"
                    data-switchable={!readOnly}
                    maxLength={isTypeContentUrl ? MAX_URL_LENGTH : width}
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                    onFocus={this.handleFocus}
                    onKeyDown={onKeyDown}
                    readOnly={this.shouldBeReadOnly()}
                    ref={this.inputRef}
                    {...(isNumber && { showCorrectAnswer })}
                    spellCheck="false"
                    style={{ width: this.getInputWidth() }}
                    tabIndex={readOnly ? TABINDEX_PREVENT : TABINDEX_DEFAULT}
                    value={value}
                />
                <UseKeyboard keyboardType={KEYBOARD_TYPES.PRIMARY} show={this.shouldShowKeyboard()}>
                    {decimal_separator && (
                        <KeyboardGroupsWrapper type="additional">
                            <KeyboardGroup
                                keys={[...DECIMAL_SEPARATOR, OPERATORS.minus]}
                                onPress={this.handleKeyboardKey}
                                track={true}
                            />
                        </KeyboardGroupsWrapper>
                    )}
                    <KeyboardGroupsWrapper>
                        <KeyboardGroup keys={NUMPAD} onPress={this.handleKeyboardKey} track={true} />
                        <KeyboardGroup keys={[...BACKSPACE, ...ARROW_NEXT]} onPress={this.handleKeyboardKey} />
                    </KeyboardGroupsWrapper>
                </UseKeyboard>
            </span>
        );
    }
}

export default InlineInputTypeField;
