import PropTypes from 'prop-types';
import React from 'react';

import { TEXT_MARKUP_TYPE } from '../../constants';
import Text from '../../text/text';

const ERROR_CODES = [0, 403, 404, 500, 502];

const NetworkErrorNotificationBody = ({ notification: { error } }) => {
    let status = error ? error.status : undefined;
    if (!ERROR_CODES.includes(status)) {
        status = 'default';
    }

    return <Text id={`page.networkError.${status}`} type={TEXT_MARKUP_TYPE.HTML} />;
};

NetworkErrorNotificationBody.propTypes = {
    notification: PropTypes.shape({
        error: PropTypes.shape({ status: PropTypes.oneOfType([PropTypes.number, PropTypes.string]) }),
    }),
};

export default NetworkErrorNotificationBody;
