import { EXTERNAL_LMS_SLUGS } from '@yandex-int/k-common/typings';
import { Button, Link, SimpleCallback, Text } from 'platform-components';
import React from 'react';

import { LESSON_EDIT_ERRORS } from 'common.components/lesson/typings';

import { EXTERNAL_DELETE_LESSON_ERROR } from '../typings';

interface Props {
    errorCode: LESSON_EDIT_ERRORS | EXTERNAL_DELETE_LESSON_ERROR;
    lms: EXTERNAL_LMS_SLUGS;
    backUrl: string;
    onRetry: SimpleCallback;
}

export const ExternalDeleteLessonError = (props: Props) => {
    const { errorCode, lms, backUrl, onRetry } = props;

    const returnToLms = () => {
        window.location.href = `${decodeURIComponent(backUrl)}`;
    };

    switch (errorCode) {
        case LESSON_EDIT_ERRORS.LESSON_HAS_ANSWERS:
            return (
                <div className="external-delete-lesson__error-panel">
                    <Text id="externalLms.deleteLesson.lessonWithStats.title" size={Text.SIZE.XXL} />
                    <div className="external-delete-lesson__error-description">
                        <Text id="externalLms.deleteLesson.lessonWithStats.description" />
                    </div>
                    <Button className="external-delete-lesson__button" onClick={returnToLms}>
                        <Text
                            id="externalLms.deleteLesson.lessonWithStats.goBack"
                            values={{ lms }}
                            weight={Text.WEIGHT.M}
                        />
                    </Button>
                </div>
            );
        default:
            return (
                <div className="external-delete-lesson__error-panel">
                    <Text id="externalLms.deleteLesson.error.title" size={Text.SIZE.XXL} />
                    <div className="external-delete-lesson__error-description">
                        <Text id="externalLms.deleteLesson.error.description" />
                    </div>
                    <Button className="external-delete-lesson__button" onClick={onRetry}>
                        <Text id="externalLms.deleteLesson.error.retry" values={{ lms }} weight={Text.WEIGHT.M} />
                    </Button>
                    <Link mix="external-delete-lesson__link" onClick={returnToLms} pseudo>
                        <Text id="externalLms.deleteLesson.error.goBack" values={{ lms }} weight={Text.WEIGHT.M} />
                    </Link>
                </div>
            );
    }
};

export default ExternalDeleteLessonError;
