import { SubjectConfigItem } from '@yandex-int/k-common';

import { CourseSubject } from 'store/courses/typings';

export const getSubjectOrder = (subject: CourseSubject, config: Array<SubjectConfigItem>): number | null =>
    Number(config.find((configItem) => configItem.subject === subject.subject)?.order) || null;

export const subjectsOrderSort = (
    subjects: Array<CourseSubject>,
    subjectConfig: Array<SubjectConfigItem>
): Array<CourseSubject> => {
    return [...subjects].sort((prevSubject, nextSubject) => {
        const prevSubjectRang = getSubjectOrder(prevSubject, subjectConfig);
        const nextSubjectRang = getSubjectOrder(nextSubject, subjectConfig);

        return prevSubjectRang && nextSubjectRang ? prevSubjectRang - nextSubjectRang : 0;
    });
};
