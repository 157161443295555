import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import { CLESSON_MODES } from '@yandex-int/k-common/constants';
import { LessonMeta, SYNC_STATUS } from '@yandex-int/k-common/typings';
import { addMinutes } from 'date-fns';
import { createSelector } from 'reselect';

import { LESSON_DATES } from 'common.components/constants';
import * as lessonViewSelectors from 'common.components/lesson-view.store/selectors';
import * as lessonSelectors from 'common.components/lesson/selectors';
import { STUDENTS_ERRORS } from 'common.components/students-list/typings';
import * as userSelectors from 'common.components/user/selectors';
import { getCurrentClassSyncStatus } from 'common.components/user/selectors';
import * as externalLmsSelectors from 'store/external-lms/selectors';
import { LessonDate } from 'utils/lesson/lesson-time/types';
import { nowSelector } from 'utils/selectors/selectors';

import {
    ACCORDION_ITEMS,
    ASSIGNMENT_STATES,
    ASSIGNMENT_DATE_TAB_LMS_DISPLAY_MODES,
    ExternalLmsAvailableDates,
    LessonAssignmentStore,
} from './typings';
import { getDateValue } from './utils';

const assignLessonModalSelector = (storeState: any): LessonAssignmentStore => storeState.lessonAssignment;

export const getCurrentAccordionItem = createSelector(
    [assignLessonModalSelector],
    (state: LessonAssignmentStore): ACCORDION_ITEMS | null => state.currentAccordionItem
);

export const getAssignmentState = createSelector(
    [assignLessonModalSelector],
    (state: LessonAssignmentStore): ASSIGNMENT_STATES => state.assignmentState
);

export const getLessonMode = createSelector(
    [assignLessonModalSelector],
    (state: LessonAssignmentStore): CLESSON_MODES | null => state.lessonMode
);

export const getAssignDate = createSelector(
    [assignLessonModalSelector],
    (state: LessonAssignmentStore): LessonDate | null => state.assignDate
);

export const getDeadlineDate = createSelector(
    [assignLessonModalSelector],
    (state: LessonAssignmentStore): LessonDate | null => state.deadlineDate
);

export const getAssignDateError = createSelector(
    [assignLessonModalSelector],
    (state: LessonAssignmentStore): string | null => state.assignDateError
);

export const getDeadlineDateError = createSelector(
    [assignLessonModalSelector],
    (state: LessonAssignmentStore): string | null => state.deadlineDateError
);

export const getDuration = createSelector(
    [assignLessonModalSelector],
    (state: LessonAssignmentStore): number | null => state.duration
);

export const getDurationError = createSelector(
    [assignLessonModalSelector],
    (state: LessonAssignmentStore): string | null => state.durationError
);

export const getSelectedStudents = createSelector(
    [assignLessonModalSelector],
    (state: LessonAssignmentStore): Array<number> => state.selectedStudents
);

export const getSelectedStudentsError = createSelector(
    [assignLessonModalSelector],
    (state: LessonAssignmentStore): STUDENTS_ERRORS | null => state.studentsError
);

export const getAssignDateValue = createSelector([getAssignDate, nowSelector], (assignDate, now): Date | null => {
    if (!now) {
        return null;
    }

    return getDateValue(now, assignDate);
});

export const getDeadlineDateValue = createSelector(
    [getDeadlineDate, getAssignDateValue, nowSelector],
    (deadlineDate, assignDateValue, now): Date | null => {
        if (!now) {
            return null;
        }

        if (deadlineDate?.type === LESSON_DATES.IN_FIFTEEN_MINUTES && assignDateValue) {
            return addMinutes(assignDateValue!, 15);
        }

        return getDateValue(now, deadlineDate);
    }
);

export const getInvalidAccordionItem = createSelector(
    [getAssignDateError, getDeadlineDateError, getDurationError, getSelectedStudentsError],
    (
        assignError: string | null,
        deadlineError: string | null,
        durationError: string | null,
        selectedStudentsError: string | null
    ): ACCORDION_ITEMS | null => {
        if (assignError || deadlineError || durationError) {
            return ACCORDION_ITEMS.DATES;
        }

        if (selectedStudentsError) {
            return ACCORDION_ITEMS.STUDENTS;
        }

        return null;
    }
);

export const getEvaluationDate = createSelector(
    [getDeadlineDateValue, getDuration, lessonSelectors.getShouldGetAssignmentDatesFromMeta, lessonSelectors.getLesson],
    (deadlineDate, duration, shouldGetFromMeta, lesson): Date | null => {
        const meta = lesson?.meta || ({} as LessonMeta);

        const evaluationDate = meta?.evaluation_date;
        if (shouldGetFromMeta && evaluationDate) {
            return new Date(evaluationDate);
        }

        if (deadlineDate && duration) {
            return addMinutes(deadlineDate, duration);
        }

        return null;
    }
);

export const getShouldShowStudentsLoader = createSelector(
    [lessonSelectors.getLessonAssignmentLoadingStatus],
    (lessonAssignmentLoadingStatus: LOADING_STATUSES): boolean =>
        lessonAssignmentLoadingStatus === LOADING_STATUSES.LOADING
);

export const getShouldShowAddSchoolControl = createSelector(
    [userSelectors.getSchoolId, userSelectors.getHasNoActiveTeams],
    (schoolId, hasNoTeams): boolean => !schoolId || hasNoTeams
);

export const getExternalLmsAvailableDates = createSelector(
    [assignLessonModalSelector],
    (state): ExternalLmsAvailableDates | null => state.externalLmsAvailableDates
);

export const getExternalLmsAvailableDatesLoadingStatus = createSelector(
    [assignLessonModalSelector],
    (state): LOADING_STATUSES => state.externalLmsAvailableDatesLoadingStatus
);

export const getLmsDisplayMode = createSelector(
    [
        getExternalLmsAvailableDatesLoadingStatus,
        getCurrentClassSyncStatus,
        externalLmsSelectors.getLmsHomeworkAssignStudentIds,
    ],
    (status, syncStatus, lmsStudents): ASSIGNMENT_DATE_TAB_LMS_DISPLAY_MODES => {
        if (syncStatus !== SYNC_STATUS.READY || lmsStudents.length === 0) {
            return ASSIGNMENT_DATE_TAB_LMS_DISPLAY_MODES.LOADING;
        }

        switch (status) {
            case LOADING_STATUSES.UNSENT:
                return ASSIGNMENT_DATE_TAB_LMS_DISPLAY_MODES.NONE;
            case LOADING_STATUSES.LOADING:
                return ASSIGNMENT_DATE_TAB_LMS_DISPLAY_MODES.LOADING;
            case LOADING_STATUSES.ERROR:
                return ASSIGNMENT_DATE_TAB_LMS_DISPLAY_MODES.NONE;
            case LOADING_STATUSES.SUCCESS:
                return ASSIGNMENT_DATE_TAB_LMS_DISPLAY_MODES.DATES;
        }

        return ASSIGNMENT_DATE_TAB_LMS_DISPLAY_MODES.NONE;
    }
);

export const getShouldShowLmsLoader = createSelector(
    [getLmsDisplayMode, lessonViewSelectors.getShouldAssignInExternalLms],
    (mode, shouldAssignInExternalLms) =>
        shouldAssignInExternalLms && mode === ASSIGNMENT_DATE_TAB_LMS_DISPLAY_MODES.LOADING
);

export const getAssignmentTable = createSelector([lessonSelectors.getAssignmentTable], (table) => Object.keys(table));

export const getExternalLmsDatesError = createSelector(
    [assignLessonModalSelector],
    (state): string | null => state.externalLmsDatesError
);
