import * as api from '@yandex-int/k-common/client/api';
import { CourseSubscriptionsData, LessonSubscriptionsData } from '@yandex-int/k-common/typings';
import { call } from 'redux-saga/effects';

export function* getLessonSubscriptions(lessonIds: Array<number>) {
    try {
        const data: LessonSubscriptionsData = yield call(api.getSubscriptions, { lesson_ids: lessonIds.join(',') });
        return data;
    } catch (error) {
        console.error(error);
        return {};
    }
}

export function* getCLessonSubscriptions(cLessonIds: Array<number>) {
    try {
        const data: LessonSubscriptionsData = yield call(api.getSubscriptions, { clesson_ids: cLessonIds.join(',') });
        return data;
    } catch (error) {
        console.error(error);
        return {};
    }
}

export function* getCourseSubscriptions(coursesIds: Array<number>) {
    try {
        const data: CourseSubscriptionsData = yield call(api.getSubscriptions, { course_ids: coursesIds.join(',') });
        return data;
    } catch (error) {
        console.error(error);
        return {};
    }
}
