import { ROLES } from '@yandex-int/k-common/constants';
import cx from 'classnames';
import { withRouter } from 'platform-components/src/components/router/router';
import { getMe } from 'platform-components/src/components/user/selectors';
import { mePropType } from 'platform-components/utils';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { THEMES } from 'common.components/footer/constants';
import Footer from 'common.components/footer/footer';
import Logo from 'common.components/logo/logo';
import User from 'common.components/user/user';

import Button from './__button/form-view__button';
import CardDescription from './__card-description/form-view__card-description';
import CardSection from './__card-section/form-view__card-section';
import CardSubtitle from './__card-subtitle/form-view__card-subtitle';
import CardTitle from './__card-title/form-view__card-title';
import Card from './__card/form-view__card';
import FooterSection from './__footer-section/form-view__footer-section';
import Input from './__input/form-view__input';
import Suggest from './__suggest/form-view__suggest';

import 'education-components/dist/user/index.css';

import './form-view.scss';

class FormView extends React.Component {
    static propTypes = {
        children: PropTypes.arrayOf(PropTypes.node),
        contentMix: PropTypes.string,
        location: PropTypes.object.isRequired,
        mix: PropTypes.string,
        me: mePropType,
    };

    static contextTypes = {
        basePath: PropTypes.string.isRequired,
        data: PropTypes.object.isRequired,
        prefix: PropTypes.string.isRequired,
        staticUrls: PropTypes.object.isRequired,
    };

    render() {
        const {
            contentMix,
            children,
            location: { pathname },
            mix,
        } = this.props;
        const { basePath, prefix } = this.context;
        const retpath = `${basePath}${prefix}${pathname}?from_passport=true`;

        return (
            <section className={cx('form-view', mix)}>
                <User mix="form-view__user" retpath={retpath} />
                <Logo mix="form-view__logo" />
                <div className={cx('form-view__content', contentMix)}>{children}</div>
                <Footer mix="form-view__footer" role={ROLES.ANONYMOUS} theme={THEMES.NARROW} />
            </section>
        );
    }
}

const mapStateToProps = (storeState) => ({
    me: getMe(storeState),
});

const FormViewComponent = compose(withRouter, connect(mapStateToProps))(FormView);

FormViewComponent.Button = Button;
FormViewComponent.Card = Card;
FormViewComponent.CardDescription = CardDescription;
FormViewComponent.CardSection = CardSection;
FormViewComponent.CardTitle = CardTitle;
FormViewComponent.CardSubtitle = CardSubtitle;
FormViewComponent.Footer = FooterSection;
FormViewComponent.Input = Input;
FormViewComponent.Suggest = Suggest;

export { SUGGEST_TYPES } from './__suggest/form-view__suggest.jsx';
export { OPTION_TYPE } from './__suggest-option/form-view__suggest-option.jsx';

export default FormViewComponent;
