import { createSelector } from 'reselect';

import { getFeatureFlags } from '../app-factory/selectors';

import { PLUS_SUBSCRIPTION_TYPES, ReduxPlusSubscriptionStore } from './typings';

const storeSelector = (storeState: ReduxPlusSubscriptionStore) => storeState.plusSubscription;

export const getPlusSubscriptionLoadingStatus = createSelector(storeSelector, (store) => store?.loadingStatus);

const getPlusSubscriptionInfo = createSelector(storeSelector, (store) => store?.subscriptionInfo || null);

export const getPlusSubscriptionType = createSelector(getPlusSubscriptionInfo, (plusInfo) => {
    if (plusInfo?.plus_info?.active) {
        return PLUS_SUBSCRIPTION_TYPES.PLUS;
    }

    return PLUS_SUBSCRIPTION_TYPES.FREE;
});

export const getPlusSubscriptionOffers = createSelector(
    [getPlusSubscriptionInfo, getFeatureFlags],
    (plusInfo, featureFlags) => {
        if (featureFlags?.hidePlusSubscriptionOffer) {
            return null;
        }

        return plusInfo?.plus_info?.offers_text?.[0] ?? null;
    }
);

export const getHasActiveSubscription = createSelector(
    getPlusSubscriptionType,
    (subscriptionType) => subscriptionType === PLUS_SUBSCRIPTION_TYPES.PLUS
);
