import b from 'b_';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { MUSIC_HEIGHT, MUSIC_SOURCE_TYPES, MUSIC_SOURCE_TYPES_ARRAY } from './yandex-music.constants';

import './yandex-music.scss';

const YandexPlayer = ({ mix, ...yaProps }) => {
    const wrong = !validateFrameProps(yaProps);
    const className = cx(b('yandex-music', { wrong }), mix);
    const { height, width, sourceType } = yaProps;

    const customHeight = `${height}px`;
    const customWidth = `${width}px`;
    const src = generateIframeSrc(yaProps);
    const defaultHeight = sourceType === MUSIC_SOURCE_TYPES.TRACK ? MUSIC_HEIGHT.SMALL : MUSIC_HEIGHT.MEDIUM;

    return (
        <div
            className={className}
            style={{ height: height ? customHeight : defaultHeight, width: width ? customWidth : undefined }}
        >
            {wrong ? (
                'Wrong player params'
            ) : (
                <iframe className={b('yandex-music__iframe', { 'source-type': sourceType })} src={src} />
            )}
        </div>
    );
};

YandexPlayer.propTypes = {
    mix: PropTypes.string,
    // yaProps
    height: PropTypes.string,
    width: PropTypes.string,
    sourceId: PropTypes.string.isRequired,
    sourceType: PropTypes.oneOf(MUSIC_SOURCE_TYPES_ARRAY).isRequired,
};

YandexPlayer.defaultProps = {
    width: '100%',
};

function validateFrameProps(props) {
    return Boolean(props.sourceId && MUSIC_SOURCE_TYPES_ARRAY.includes(props.sourceType));
}

function generateIframeSrc(yaProps) {
    const { sourceId, sourceType } = yaProps;

    return `https://music.yandex.ru/iframe/${sourceType}/${sourceId}/?mne2021=1`;
}

export default YandexPlayer;
