import * as React from 'react';
const SvgChevrone = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 8 12" fill="none" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.121.344L.464 6l5.657 5.657 1.415-1.414L3.293 6l4.243-4.243L6.121.344z"
            fill="currentColor"
        />
    </svg>
);
export default SvgChevrone;
