import { GrantRightsRequest, TeacherProfileData, TeacherProfile } from '../../../typings';
import { apiConfig } from '../config';
import { postFetch } from '../fetch';

export function patchGrantTeacherRights(data: GrantRightsRequest): Promise<TeacherProfile> {
    const { apiPatchTeacherProfile } = apiConfig.getStaticUrls();
    const { meId, userRights } = data;

    const param: TeacherProfileData = { teacher_profile: { granted_rights: userRights } };

    return postFetch(`${apiPatchTeacherProfile}${meId}/`, param);
}
