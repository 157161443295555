import noop from 'lodash/noop';

/**
 * Снимает текущее выделение и возвращает функцию, возвращающую его назад
 */
export function toggleSelection() {
    const selection = document.getSelection();

    if (!selection?.rangeCount) {
        return noop;
    }

    let active = document.activeElement as HTMLElement | null;

    const ranges: Range[] = [];

    for (let i = 0; i < selection.rangeCount; i += 1) {
        ranges.push(selection.getRangeAt(i));
    }

    switch (active?.tagName.toUpperCase()) {
        case 'INPUT':
        case 'TEXTAREA':
            active.blur();
            break;

        default:
            active = null;
            break;
    }

    selection.removeAllRanges();

    return function () {
        if (selection.type === 'Caret') {
            selection.removeAllRanges();
        }

        if (!selection.rangeCount) {
            ranges.forEach(function (range) {
                selection.addRange(range);
            });
        }

        if (active) {
            active.focus();
        }
    };
}
