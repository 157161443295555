import isEqual from 'lodash/isEqual';
import { SimpleCallback } from 'platform-components';
import { useIntl } from 'platform-components/common';
import { getFromLocalStorage } from 'platform-components/utils';
import React, { useCallback, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// import ym from 'react-yandex-metrika';
import SchoolForm from 'common.components/school-form/school-form';
import { SchoolData } from 'common.components/school-form/typings';
import { YM_LOCATION } from 'utils/ym-constants';

import { teamBuilderActions } from '../actions';
import { TEAM_BUILDER_LS } from '../constants';
import * as selectors from '../selectors';

interface Props {
    location?: YM_LOCATION;
    onAssign: () => void;
    onNext: SimpleCallback;
}

export const TeamBuilderSchoolMes = (props: Props) => {
    const { location, onAssign, onNext } = props;

    const intl = useIntl();
    const moscowAddress = useSelector((store) => selectors.getMoscowAddress(store, { intl }));

    const dispatch = useDispatch();
    const rawRegistrationData = getFromLocalStorage(TEAM_BUILDER_LS, null);

    const registrationData = rawRegistrationData ? JSON.parse(rawRegistrationData) : null;

    useLayoutEffect(() => {
        if (!registrationData || !isEqual(registrationData.address, moscowAddress)) {
            dispatch(
                teamBuilderActions.setSchoolData({
                    address: moscowAddress,
                    school: null,
                })
            );
            onAssign();
        }
    }, [registrationData, moscowAddress, onAssign, dispatch]);

    const handleChange = useCallback(
        ({ school, address }: SchoolData) => {
            dispatch(
                teamBuilderActions.setSchoolData({
                    school,
                    address,
                })
            );
            onAssign();
        },
        [onAssign]
    );

    const handleNext = useCallback(() => {
        // if (location) {
        //     ym('reachGoal', 'teacher_onboarding_chose_school', { location: location });
        // }
        onNext();
    }, [onNext]);

    const userRegistrationData = registrationData || {
        address: moscowAddress,
        school: null,
    };

    return (
        <SchoolForm
            canAddOwnSchool={false}
            onChange={handleChange}
            onSubmit={handleNext}
            shouldDisableAddress={true}
            shouldHideAlternativeRegistration={true}
            shouldShowPersonalData={false}
            value={userRegistrationData}
            ymLocation={location}
        />
    );
};
