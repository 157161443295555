import b from 'b_';
import cx from 'classnames';
import React, { useEffect, useMemo } from 'react';

import { withKatex } from '../../utils/katex/with-katex';

import { ErrorBoundary } from './error-boundary';
import { formateCodeString } from './utils';

import './formula.scss';

type ID = number | string;

export type FormulaProps = {
    className?: string;
    code: string;
    id: ID;
    isMultiline: boolean;
    onClick: (event: React.MouseEvent, options: { id: ID }) => void;
    onReady?: (ready: true) => void;
};

const Formula = ({ className, code, id, isMultiline, onClick, onReady }: FormulaProps) => {
    const handleClick = (e: React.MouseEvent) => {
        if (onClick) {
            onClick(e, { id });
        }
    };

    const [html, wrong] = useMemo(() => {
        let codeString;
        let renderCodeString;
        let isWrong = false;

        try {
            // @ts-ignore
            renderCodeString = katex.renderToString(code, { trust: true });

            codeString = formateCodeString(renderCodeString);
        } catch (err) {
            console.error(err);

            codeString = 'wrong formula';
            isWrong = true;
        }

        return [codeString, isWrong];
    }, [code]);

    useEffect(() => {
        if (onReady) {
            onReady(true);
        }
    }, [onReady]);

    return (
        <ErrorBoundary code={code}>
            <span className={cx(b('formula', { wrong, multiline: isMultiline }), className)} onClick={handleClick}>
                <span dangerouslySetInnerHTML={{ __html: html }} />
            </span>
        </ErrorBoundary>
    );
};

export default withKatex(Formula);
