export const BLOCK_CARDS_COUNT = 45;

export enum LESSON_DATES {
    NONE = 'none',
    CUSTOM = 'custom',
    AFTER_ASSIGN = 'after_assign',
    TODAY_AFTERNOON = 'today_afternoon',
    FRIDAY_AFTERNOON = 'friday_afternoon',
    TODAY_MORNING = 'today_morning',
    NEXT_WEEK_MONDAY = 'monday_morning',
    MONDAY_IN_TWO_WEEKS = 'monday_in_two_weeks',
    TOMORROW_MORNING = 'tomorrow_morning',
    IN_FIFTEEN_MINUTES = 'in_fifteen_minutes',
}
