import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import { InferValuesTypes, CourseTemplate, ExternalCourse } from '@yandex-int/k-common/typings';

import * as actions from './actions';

export interface ClassCoursesTemplates {
    loadingStatus: LOADING_STATUSES;
    list: Array<CourseTemplate>;
}

export interface CoursesTemplatesStoreState {
    classes: Record<number, ClassCoursesTemplates>; // key = class id
    grades: Record<number, ClassCoursesTemplates>; // key = class grade
}

export interface GetCoursesTemplatesByTeamIdRequestData {
    classId: number | null;
    onSuccess?: (params: { coursesTemplates: Array<CourseTemplate> }) => void;
}

export interface GetCoursesTemplatesByGradeRequestData {
    grade: number;
    onSuccess?: (params: { coursesTemplates: Array<CourseTemplate> }) => void;
}

export type GetCoursesTemplatesRequestData =
    | GetCoursesTemplatesByTeamIdRequestData
    | GetCoursesTemplatesByGradeRequestData;

export const isTemplatesRequestByTeamId = (
    value: GetCoursesTemplatesRequestData
): value is GetCoursesTemplatesByTeamIdRequestData => {
    return value.hasOwnProperty('classId');
};

export const isTemplatesRequestByGrade = (
    value: GetCoursesTemplatesRequestData
): value is GetCoursesTemplatesByGradeRequestData => {
    return value.hasOwnProperty('grade');
};

export interface GetCoursesTemplatesErrorData {
    classId?: number | null;
    grade?: number;
}

export interface GetCoursesTemplatesSuccessData {
    classId?: number | null;
    grade?: number;
    coursesTemplates: Array<CourseTemplate>;
}

export interface ReduxCourseTemplatesStore {
    coursesTemplates: CoursesTemplatesStoreState;
}

export interface CourseTemplateWithCourseData extends CourseTemplate {
    externalCourse: ExternalCourse;
}

export type CoursesTemplatesAction = ReturnType<InferValuesTypes<typeof actions>>;
