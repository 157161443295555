import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import { CertGenParams, Child, Course, LoginPair, Me, SYNC_STATUS, Team } from '@yandex-int/k-common/typings';
import { getSpecCourse, getSpecCourses } from '@yandex-int/k-common/utils/get-spec-courses';
import get from 'lodash/get';
import { createSelector } from 'reselect';
import { getExperimentFlags } from 'platform-components/common';
import {
    getDisplayName,
    getFirstName,
    getHints,
    getLastName,
    getMe,
} from 'platform-components/src/components/user/selectors';

import { getSpecProjectConfigBySourceProject } from 'common.components/spec-project.common/selectors';
import { isPrimarySchool } from 'common.components/user/utils';
import { getRegRedirectUrl } from 'utils/get-data-from-search-parameters';
import { getSavedTeamId, clearSavedTeamId } from 'utils/saved-team-id';
import { getUserName as getUserNameUtil } from 'utils/get-user-name';
import { getChildIdFromProps, getClassIdFromProps, getSubjectFromProps } from 'utils/selectors/selectors';

import { Hint, ReduxUserStore, SkillsStatsHowToCalculateHint, UserStoreState } from './typings';
import {
    ASSIGNED_LESSONS_COUNT,
    CLASS_GRADES_WITHOUT_CONTENT,
    DEFAULT_CLASS_GRADES,
    FULL_CLASS_GRADES,
    HIGH_SCHOOL_FIRST_CLASS,
    SECONDARY_SCHOOL_FIRST_CLASS,
    SKILLS_STATS_HOW_TO_CALCULATE,
    TEACHER_CERT_BANNER_WAS_CLOSED,
    VIEWED_HINTS_CERT_EXPANDED_PARAMS_KEY,
} from './user.constants';
import { getIsCollegeTeamUtil } from './utils';

export const userStateSelector = <T extends ReduxUserStore>(storeState: T): UserStoreState => storeState.user;

export const getClasses = createSelector([userStateSelector], (state) => state.classes);

export const getTeacherProfile = createSelector([userStateSelector], (state) => state.teacherProfile);

export const getNotFirstSyncingTeams = createSelector(
    [getClasses],
    (teams: Array<Team>): Array<Team> => teams.filter((team) => team.sync_status !== SYNC_STATUS.FIRST_SYNC)
);

/**
 * Помогает понять какой класс был первым – школьный или СПО,
 * чтобы немного кастомизировать логику отображения классов
 */
export const getTypeOfFirstCreatedTeam = createSelector(getClasses, (teams) => {
    if (!teams.length) {
        return null;
    }

    let earliest = teams[0];
    for (const team of teams) {
        if (new Date(team.date_created) < new Date(earliest.date_created)) {
            earliest = team;
        }
    }

    // College – группа СПО
    return getIsCollegeTeamUtil(earliest.level) ? 'College' : 'School';
});

/** Узнать наличие неархивных классов */
export const getHasNoActiveTeams = createSelector([getNotFirstSyncingTeams], (classes) => !classes.length);

/** Вернет teamId из кук, если он в списке классов, иначе – первый из списка */
export const getSavedOrFirstTeamId = createSelector([getSavedTeamId, getNotFirstSyncingTeams], (savedTeamId, teams) => {
    if (savedTeamId) {
        if (isClassInClasses(teams, savedTeamId)) {
            return Number(savedTeamId);
        }
        clearSavedTeamId();
    }

    return teams[0]?.id || null;
});

/** Вернет переданный classId, если он существует, иначе – сохраненный или первый из списка */
export const getCurrentOrFirstTeamId = createSelector(
    [getClassIdFromProps, getSavedOrFirstTeamId],
    (currentTeamId: number | null, savedOrFirstTeamId: number | null): number | null =>
        currentTeamId || savedOrFirstTeamId
);

export const getArchivedClasses = createSelector(
    userStateSelector,
    (user): Array<Team> => get(user, 'archivedClasses')
);

/** Отдает класс, `classId` которого будет передан */
export const getCurrentClass = createSelector(
    [getClassIdFromProps, getClasses, getArchivedClasses],
    (currentClassId, classes: Array<Team>, archiveClasses): Team | null => {
        if (!currentClassId) {
            return null;
        }

        // TODO: данные разнятся в зависимости от страницы захода - плохо
        const currentClass = classes.find((cl) => cl.id === currentClassId);
        const currentArchiveClass = archiveClasses.find((cl) => cl.id === currentClassId);

        return currentClass || currentArchiveClass || null;
    }
);

export const getCurrentTeamPrevLevel = createSelector(getCurrentClass, (currentClass) =>
    currentClass ? currentClass.level - 1 || 1 : null
);

export const getCurrentTeamLevel = createSelector([getCurrentClass], (team): number | null => team?.level || null);

export const getCurrentTeamLetter = createSelector([getCurrentClass], (team): string | null => {
    return team?.name || null;
});

export const getCurrentTeamEnteringCode = createSelector(
    [getCurrentClass],
    (team): string | null => team?.team_entering_code || null
);

export const getCurrentTeamExternalLms = createSelector(
    [getCurrentClass],
    (currentTeam): Record<string, string> | null => currentTeam?.external_lms || null
);

export const getIsCurrentClassExternal = createSelector([getCurrentTeamExternalLms], (teamExternalLms): boolean =>
    Boolean(teamExternalLms)
);

const isClassInClasses = (classes: Array<Team>, classId: number | string | null): boolean =>
    Boolean(classes.find((classObj) => classObj.id === Number(classId)));

export const getIsInOwnClass = createSelector(
    [getClassIdFromProps, getClasses],
    (currentClassId, classes: Array<Team>): boolean => {
        if (!currentClassId) {
            return false;
        }

        return isClassInClasses(classes, currentClassId);
    }
);

export const getIsClassInAvailableClasses = createSelector(
    [getClassIdFromProps, getNotFirstSyncingTeams],
    (classId, classes: Array<Team>): boolean => {
        if (!classId) {
            return false;
        }

        return isClassInClasses(classes, classId);
    }
);

export const getAssignedLessonsCount = createSelector([getHints], (hints): number =>
    Number(get(hints, ASSIGNED_LESSONS_COUNT, 0))
);

export const getHasAssignedLessons = createSelector([getAssignedLessonsCount], (count) => count > 0);

export const getIsTeacherCertBannerWasClosed = createSelector(getHints, (hints: Hint): number =>
    get(hints, TEACHER_CERT_BANNER_WAS_CLOSED, false)
);

// TODO: не использовать currntClass, а передавать явно classId и искать в списках архивных и обычных классов
export const getIsSupervisorInCurrentClass = createSelector(getCurrentClass, (currentClass): boolean =>
    get(currentClass, 'is_supervisor', true)
);

export const getCanEditClass = <T extends ReduxUserStore>(storeState: T, classId: number | null): boolean => {
    const classes: Team[] = [...getClasses(storeState), ...getArchivedClasses(storeState)];

    const properClass = classes.find((c) => c.id === classId);

    if (!properClass) {
        return false;
    }

    return properClass.is_supervisor && !properClass.external_lms?.mes;
};

export const getIsOnlyTeacherInCurrentClass = createSelector(getCurrentClass, (currentClass): boolean =>
    get(currentClass, 'is_only_teacher', true)
);

export const getIsCurrentClassArchived = createSelector([getArchivedClasses, getClassIdFromProps], (classes, classId) =>
    Boolean(isClassInClasses(classes, classId))
);

/** Вернет переданный teamId, если он не архивный, иначе – сохраненный или первый из списка */
export const getFirstActiveTeamId = createSelector(
    [getClassIdFromProps, getIsCurrentClassArchived, getSavedOrFirstTeamId],
    (classIdFromProps, isCurrentClassArchived, firstClassId) =>
        isCurrentClassArchived ? firstClassId : classIdFromProps
);

export const getCurrentClassSyncStatus = createSelector(
    getCurrentClass,
    (currentClass): SYNC_STATUS | null => currentClass?.sync_status || null
);

export const getTeamGrade = createSelector(
    [getClasses, getClassIdFromProps],
    (classes: Array<Team>, classId: number | null): number | null => {
        if (!classId) {
            return null;
        }

        const currentClass = classes.find((classItem) => classItem.id === Number(classId));
        return currentClass?.level || null;
    }
);

/**
 * Ищет класс по `id` и определяет, что он является группой СПО
 *
 * Лучше использовать `./utils/getIsCollegeTeamUtil`, если уже известен грейд
 */
export const getIsCollegeTeam = createSelector([getTeamGrade], (grade) => getIsCollegeTeamUtil(grade));

export const getIsPrimarySchool = createSelector([getTeamGrade], (grade: number | null): boolean =>
    isPrimarySchool(grade)
);

export const getIsSecondarySchool = createSelector([getTeamGrade], (grade: number | null): boolean => {
    return Boolean(grade && grade >= SECONDARY_SCHOOL_FIRST_CLASS && grade < HIGH_SCHOOL_FIRST_CLASS);
});

export const getIsHighSchool = createSelector([getTeamGrade], (grade: number | null): boolean => {
    return Boolean(grade && grade >= HIGH_SCHOOL_FIRST_CLASS);
});

export const getIsCurrentClassWithoutContent = createSelector([getCurrentClass], (currentClass): boolean =>
    Boolean(currentClass && CLASS_GRADES_WITHOUT_CONTENT.includes(currentClass.level))
);

export const getWizardVariablesWhiteList = (): Array<string> => {
    if (isSSR) {
        return [];
    }

    return window._data.wizardVariablesWhitelist;
};

export const getEventCodesWithDates = createSelector(
    [getTeacherProfile],
    (teacherProfile) => teacherProfile?.events_codes || []
);

export const getLastEventCode = createSelector(
    [getTeacherProfile, getEventCodesWithDates],
    (teacherProfile, eventsCodes) => {
        if (!eventsCodes.length) {
            return get(teacherProfile, 'promo', null);
        }

        return eventsCodes[0].event_code;
    }
);

export const getEventCodes = createSelector(getEventCodesWithDates, (eventsCodes) =>
    eventsCodes.map((eventCode) => eventCode.event_code)
);

export const getAllEventCodes = createSelector([getEventCodes, getLastEventCode], (allCodes, lastCode) => {
    let codes = allCodes ? allCodes : [];
    if (lastCode) {
        codes.push(lastCode);
    }
    return codes;
});

export const getCertParams = createSelector(
    [getTeacherProfile],
    (teacherProfile): CertGenParams | null => teacherProfile?.cert_gen_params || null
);

export const getDateJoined = createSelector(getMe, (me) => new Date(get(me, 'date_joined')));

export const getChildren = createSelector([userStateSelector], (state) => state.parentChildren || []);

export const getParentProfile = createSelector([userStateSelector], (state) => state.parentProfile);

export const getIsParentWithoutChildren = createSelector([getChildren], (children) => !children?.length);

export const getCurrentChild = createSelector(
    [getChildren, getChildIdFromProps],
    (children: Array<Child>, currentChildId: number | null): Child | null => {
        const childId = Number(currentChildId);

        if (childId === null) {
            return null;
        }

        const currentChild = children.find((child) => child.id === childId);

        return currentChild ? currentChild : null;
    }
);

export const getChildrenSortedByAccountType = createSelector([getChildren], (children) => {
    const allowedChildrenAccounts: typeof children = [];
    const schoolbookAuthAccounts: typeof children = [];

    children.forEach((child) => {
        if (child.yauid) {
            allowedChildrenAccounts.push(child);
        } else {
            schoolbookAuthAccounts.push(child);
        }
    });

    return [...allowedChildrenAccounts, ...schoolbookAuthAccounts];
});

export const getFirstChild = createSelector([getChildren], (children): Child | null => {
    return children && children[0];
});

export const getCurrentChildLoginPair = createSelector(
    getCurrentChild,
    (child): LoginPair => get(child, 'login_pairs[0]', {})
);

export const getCurrentChildAllCourses = createSelector(getCurrentChild, (child): Course[] => child?.courses || []);

export const getBlockedPlusCourse = createSelector(
    getCurrentChild,
    (child): Course | undefined => child?.blocked_plus_course || undefined
);

export const getCurrentChildCommonCourses = createSelector(getCurrentChildAllCourses, (courses) =>
    courses.filter((course) => !getSpecCourse(course))
);

export const getCurrentChildSpecCourses = createSelector(getCurrentChildAllCourses, (courses): Course[] =>
    getSpecCourses(courses)
);

export const getCurrentChildGrade = createSelector(getCurrentChild, (child) => child?.student_profile.grade || null);

export const getFirstChildCourseId = createSelector(
    [getFirstChild, getSubjectFromProps],
    (child, initialSubject): number => get(child, 'courses.[0].id', initialSubject)
);

export const getUserName = createSelector(
    [getFirstName, getLastName, getDisplayName],
    (firstName: string, lastName: string, displayName: string): string => {
        return getUserNameUtil(firstName, lastName, displayName);
    }
);

export const getAvailableClassNumbers = createSelector(
    [getSpecProjectConfigBySourceProject, getRegRedirectUrl],
    (config, regRedirectUrl): Array<number> => {
        if (config) {
            return config.grades;
        }

        if (regRedirectUrl) {
            return FULL_CLASS_GRADES;
        }

        return DEFAULT_CLASS_GRADES;
    }
);

export const getIsCurrentClassNumberAvailable = createSelector(
    [getCurrentClass, getAvailableClassNumbers],
    (currenClass, availableClassNumbers): boolean => {
        const level = currenClass?.level;

        return level ? availableClassNumbers.includes(level) : false;
    }
);

export const getSchoolId = createSelector([getTeacherProfile], (teacherProfile) => teacherProfile?.school || null);

export const getSchoolGeoId = createSelector([getMe], (me: Me): number => me?.locality_geoid);

export const getLocalityName = createSelector(userStateSelector, (state) => state.locality);

export const getSchoolName = createSelector([getSchoolId, getClasses], (schoolId, classes) =>
    schoolId && classes.length ? classes[0].school_name : null
);

const getTeamSyncLoadingStatuses = createSelector(userStateSelector, (state) => state.teamSyncLoadingStatuses);

export const getIsCurrentClassSyncing = createSelector(
    [getCurrentClass, getTeamSyncLoadingStatuses],
    (currentClass, teamSyncLoadingStatuses): boolean => {
        if (!currentClass) {
            return false;
        }

        if (currentClass.sync_status === SYNC_STATUS.SYNCING) {
            return true;
        }

        return teamSyncLoadingStatuses[currentClass.id] === LOADING_STATUSES.LOADING;
    }
);

export const getIsInShowcaseExperiment = createSelector([getExperimentFlags], (flags: object | null): boolean =>
    get(flags, 'experimental_library', false)
);

export const getIsInSubscriptionsExperiment = createSelector([getExperimentFlags], (flags: object | null): boolean =>
    get(flags, 'subscriptions_experimental', false)
);

export const getIsInRNOExperiment = createSelector([getExperimentFlags], (flags: object | null): boolean =>
    get(flags, 'rno_experimental', false)
);

export const getGrantRightsLoadingStatus = createSelector(
    [userStateSelector],
    (state) => state.grantRightsLoadingStatus
);

export const getIsInCustomLessonExperiment = createSelector([getExperimentFlags], (flags: object | null): boolean =>
    get(flags, 'custom_lesson_experimental', false)
);

export const getIsInBaseCertificateExperiment = createSelector([getExperimentFlags], (flags: object | null): boolean =>
    get(flags, 'switch_off_base_cert', false)
);

export const getGradesLevels = createSelector([getClasses], (teams) => {
    const grades = new Set<number>();

    teams.map((team) => grades.add(team.level));

    return [...grades];
});

export const getIsInStudentRegistrationLinkExperiment = createSelector([getExperimentFlags], (flags): boolean => {
    if (!isSSR && window.location.host === 'crowd.testing.schoolbook.yandex.ru') {
        /**
         * Не включаем эксперимент на crowd testing
         * @see https://st.yandex-team.ru/EDUCATION-23293
         */
        return false;
    }

    return get(flags, 'student_registration_link', false);
});

export const getIsStudentRegistrationLinkAvailableInTeam = createSelector(
    [getIsInStudentRegistrationLinkExperiment, getCurrentClass],
    (isInStudentRegistrationLinkExperiment, currentTeam): boolean => {
        const hasRegistrationCode = Boolean(currentTeam?.team_entering_code);

        return isInStudentRegistrationLinkExperiment && hasRegistrationCode;
    }
);

export const getSkillsStatsHowToCalculateHint = createSelector(
    [getHints],
    (hints): SkillsStatsHowToCalculateHint => get(hints, SKILLS_STATS_HOW_TO_CALCULATE, {})
);

export const getCertExpandedParamsFromViewedHints = createSelector([getHints], (hints) =>
    get(hints, VIEWED_HINTS_CERT_EXPANDED_PARAMS_KEY)
);

export const getIsInNewPublicShowcaseExperiment = createSelector(
    [getExperimentFlags],
    (flags: object | null): boolean => !!get(flags, 'showNewPublicShowcase', false)
);

export const getIsPublicShowcase = createSelector(
    [getSavedOrFirstTeamId],
    (savedOrFirstTeamId): boolean => !savedOrFirstTeamId
);

export const getIsEnableTeacherStoriesFromCms = createSelector(
    [getExperimentFlags],
    (flags: object | null): boolean => !!get(flags, 'enableTeacherStoriesFromCms', false)
);
