import { getFetch } from '../fetch';

import { apiConfig } from '../config';

import { ClessonResults } from '../../../typings/api/models';

export interface FullClessonResults {
    data:    ClessonResults;
    csv_url: string | null;
}

export function getFullClessonResults(clessonId: number): Promise<FullClessonResults> {
    const { apiGetFullClessonResults } = apiConfig.getStaticUrls();

    return getFetch(apiGetFullClessonResults, { clessonId });
}
