import React from 'react';

import { useLocalStorage } from '../hooks/useLocalStorage';

export interface WithLocalStorageProps {
    localStorage: ReturnType<typeof useLocalStorage>;
}

export const withLocalStorage = <T extends WithLocalStorageProps>(Component: React.ComponentType<T>) => {
    const WithLocalStorage = (props: T) => {
        const localStorage = useLocalStorage();

        return <Component {...props} localStorage={localStorage} />;
    };

    WithLocalStorage.displayName = `withLocalStorage(${Component.displayName})`;

    return WithLocalStorage as React.ComponentType<Omit<T, keyof WithLocalStorageProps>>;
};
