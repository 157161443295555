import { Link, Spin, Text } from 'platform-components';
import { withLocalStorage, WithLocalStorageProps } from 'platform-components/src/utils/hocs/withLocalStorage';
import { withRouterPath, InjectedProps as WithRouterPathProps } from 'platform-components/hocs';
import PropTypes from 'prop-types';
import qs from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import ym from 'react-yandex-metrika';
import { compose } from 'redux';

import ChildManagement from 'common.components/child-management/child-management';
import { NewChildIds } from 'common.components/child-management/typings';
import { SPEC_COURSE_LESSON_VIEW_TABS } from 'common.components/spec-course-lesson-view/typings';
import { getIsParentRegistrationAvailable } from 'common.components/spec-project.common/selectors';
import { getSourceProject } from 'utils/get-data-from-search-parameters';

import RegistrationWrapper from '../__wrapper/parent-registration__wrapper';
import { ReduxIndexStore } from '../../../typings';
import * as selectors from '../selectors';

type StoreProps = ReturnType<typeof mapStateToProps>;

interface HOCProps extends WithRouterPathProps, WithLocalStorageProps {}

interface Props extends StoreProps, HOCProps {}

interface SearchParams {
    childName?: string;
    parentCode?: string;
    returnPath?: string;
    fromOlymp?: string;
}

class RegistrationByCode extends React.Component<Props> {
    static contextTypes = {
        staticUrls: PropTypes.object.isRequired,
    };
    context!: React.ContextType<React.Context<{ staticUrls: Record<string, string> }>>;

    onAddChildByCode = (childIds: NewChildIds) => {
        // при привязке добавляется один ученик, который в колбеке возвращается обернутым в массив
        const newChildId = childIds?.[0];
        const { staticUrls } = this.context;
        const {
            getRouterPath,
            location: { search },
            localStorage,
        } = this.props;

        if (newChildId) {
            let path = getRouterPath(staticUrls.parentMain);

            ym('reachGoal', 'platform_parent_registration_finished', { from_project: getSourceProject() });

            ym('reachGoal', 'parent_add_child', {
                place: 'onboarding',
                type: 'parent_code',
            });

            const searchParams: SearchParams | undefined = qs.parse(search);

            if (searchParams?.fromOlymp) {
                path = getRouterPath(staticUrls.parentChildSpecCourseStub, { childId: newChildId });

                const query = qs.stringify({
                    fromOlymp: searchParams.fromOlymp,
                    tab: SPEC_COURSE_LESSON_VIEW_TABS.RESULTS,
                });

                window.location.href = `${path}?${query}`;

                return;
            }

            localStorage.set('shouldShowWelcomeParentModal', true);

            if (searchParams?.returnPath) {
                window.location.href = searchParams?.returnPath;

                return;
            }

            window.location.href = `${path}${search}`;
        }
    };

    render() {
        const { staticUrls } = this.context;
        const {
            isParentRegistrationAvailable,
            location: { search },
            isChildCreationSuccess,
        } = this.props;

        const searchParams: SearchParams | undefined = qs.parse(search);
        const childName = searchParams?.childName;
        const parentCode = searchParams?.parentCode;

        let bottomSection;
        let descriptionTextId = 'parentRegistration.description';

        if (parentCode) {
            descriptionTextId = 'parentRegistration.welcomeText';
        } else if (isParentRegistrationAvailable) {
            bottomSection = (
                <Link
                    isRouter
                    mix="parent-registration__link"
                    theme={Link.THEME.PRIMARY}
                    to={`${staticUrls.parentRegistrationCreateChild}${search}`}
                >
                    <Text id="parentRegistration.noParentCode" weight={Text.WEIGHT.M} />
                </Link>
            );
            descriptionTextId = 'parentRegistration.welcomeText';
        }

        if (isChildCreationSuccess) {
            return <Spin center size={Spin.SIZE.L} />;
        }

        return (
            <RegistrationWrapper textId={descriptionTextId}>
                <ChildManagement
                    bottomSection={bottomSection}
                    childName={childName}
                    mix="parent-registration__form"
                    onSuccess={this.onAddChildByCode}
                    parentCode={parentCode}
                    showAgreement={true}
                />
            </RegistrationWrapper>
        );
    }
}

const mapStateToProps = (storeState: ReduxIndexStore) => {
    return {
        isParentRegistrationAvailable: getIsParentRegistrationAvailable(storeState),
        specCourseId: selectors.getChildSpecCourseId(storeState),
        isChildCreationSuccess: selectors.getIsChildCreationSuccess(storeState),
    };
};

export default compose(withRouterPath, withLocalStorage, connect(mapStateToProps))(RegistrationByCode);
