// @ts-nocheck
import { AwardItem, CampaignAwards } from '@yandex-int/k-common';
import { getAwardLink, SEARCH_PARAMS_GET_AWARD } from '@yandex-int/k-common/utils/awards';
import { useGeneratePath, useStaticUrls } from 'platform-components';
import { actions as awardsActions } from 'platform-components/src/components/awards/actions';
import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import ym from 'react-yandex-metrika';

import ChildManagement from 'common.components/child-management/child-management';
import { getSourceProject } from 'utils/get-data-from-search-parameters';

import RegistrationWrapper from '../__wrapper/parent-registration__wrapper';
import { getChildId } from '../selectors';

export const ParentRegistrationGetAward = () => {
    const dispatch = useDispatch();
    const staticUrls = useStaticUrls();
    const { search } = useLocation();
    const generatePath = useGeneratePath();
    const childId = useSelector(getChildId);
    const { clessonId, [SEARCH_PARAMS_GET_AWARD]: awardSource } = qs.parse(search);
    const [isAwardLoading, setAwardLoading] = useState(false);

    useEffect(() => {
        if (childId) {
            dispatch(
                awardsActions.getChildAvailableAwards({
                    childId,
                    clessonId,
                    onSuccess: takeAward,
                })
            );
            setAwardLoading(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [childId]);

    const takeAward = (items: CampaignAwards[]) => {
        const availableAwards = items.reduce((result: AwardItem[], campaignAwards: CampaignAwards) => {
            return result.concat(campaignAwards.award_items);
        }, []);
        const award = availableAwards.find((item) => item.award.view_details?.source === awardSource);

        if (childId && award) {
            dispatch(
                awardsActions.takeAward({
                    id: award.id,
                    onSuccess: (receivedAward: AwardItem) => {
                        window.location.href = getAwardLink(receivedAward);
                    },
                    onError: () => {
                        window.location.href = generatePath(staticUrls.parentChildSpecCourseStub, { childId });
                    },
                })
            );
        }
    };

    const handleAddChildByCode = () => {
        ym('reachGoal', 'platform_parent_registration_finished', { from_project: getSourceProject() });

        ym('reachGoal', 'parent_add_child', {
            place: 'onboarding',
            type: 'parent_code',
        });
    };

    return (
        <RegistrationWrapper textId="parentRegistration.description.getPrize">
            <ChildManagement
                isSubmitting={isAwardLoading}
                mix="parent-registration__form"
                onSuccess={handleAddChildByCode}
                showAgreement={true}
            />
        </RegistrationWrapper>
    );
};
