import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import { getPlusSubscriptionStatus } from '@yandex-int/k-common/client/api/defs/get-plus-subscription-status';
import { PlusSubscriptionInfo } from '@yandex-int/k-common/typings/api/models/plus-subscription';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { getIsAuthenticated } from '../user/selectors';

import { showNetworkError } from '../notification/actions';

import { plusSubscriptionActions } from './actions';

export function* getPlusSubscriptionInfoRequestSaga(
    action?: ReturnType<typeof plusSubscriptionActions.getPlusSubscriptionInfoRequest>
) {
    try {
        // блокируем запрос для не аутентифицированных пользователей
        const isAuthenticatedUser: boolean = yield select(getIsAuthenticated);
        if (!isAuthenticatedUser) {
            return;
        }

        const onSuccess = action?.payload?.onSuccess;
        yield put(plusSubscriptionActions.setPlusSubscriptionInfoLoadingStatus(LOADING_STATUSES.LOADING));

        const response: PlusSubscriptionInfo = yield call(getPlusSubscriptionStatus);
        yield put(plusSubscriptionActions.setPlusSubscriptionInfo(response));
        yield put(plusSubscriptionActions.setPlusSubscriptionInfoLoadingStatus(LOADING_STATUSES.SUCCESS));

        if (onSuccess) {
            onSuccess();
        }
    } catch (error) {
        yield put(plusSubscriptionActions.setPlusSubscriptionInfoLoadingStatus(LOADING_STATUSES.ERROR));
        showNetworkError();
    }
}

export function* plusSubscriptionSagasWatcher() {
    yield takeLatest(plusSubscriptionActions.getPlusSubscriptionInfoRequest, getPlusSubscriptionInfoRequestSaga);
}
