import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import { Button, SimpleText } from 'platform-components';
import PropTypes from 'prop-types';
import React from 'react';

const StartScreenStudentButton = ({ mix, status, ...otherProps }) => (
    <Button className={mix} progress={status === LOADING_STATUSES.LOADING} rounded {...otherProps}>
        <SimpleText id="startScreen.studentBubbleText" />
    </Button>
);

StartScreenStudentButton.propTypes = {
    mix: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    status: PropTypes.oneOf(Object.values(LOADING_STATUSES)),
};

export default StartScreenStudentButton;
