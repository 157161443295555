//@ts-ignore
import { MAIL_HOST, DISK_HOST, TUNE_HOST } from '@yandex-int/k-common/constants';
import { Close as IconClose } from 'education-icons';
import { Text } from 'platform-components';
import { Route, Switch, withRouter } from 'platform-components/src/components/router/router';
import {
    getDisplayName,
    getFirstName,
    getLastName,
    getUserAvatarUrl,
    getUserEmail,
} from 'platform-components/src/components/user/selectors';
import { getMe } from 'platform-components/src/components/user/selectors';
import { InjectedProps as WithRouterPathProps } from 'platform-components/hocs';
import PropTypes from 'prop-types';
import { stringify } from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';
import { accessibleClick } from '@edu-frontend/modules/src/shared/lib/a11y';

import * as salesCodeModalActions from 'common.components/sales-code-modal/actions';
import { getPassportHost } from 'utils/get-passport-host';
import { getUserName } from 'utils/get-user-name';

import { UserIcon } from '../__icon/user__icon';

import './user__menu.scss';

type StoreProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

interface OwnProps {
    retpath?: string;
}

interface HOCProps extends WithRouterPathProps {}

interface Props extends OwnProps, HOCProps, StoreProps, DispatchProps {}

function getLogoutUrl(passportHost: string, retpath: string): string {
    const matches = global.document && document.cookie.match(/(?:^|; )yandexuid=([^;]*)/);
    const yu = matches ? matches[1] : undefined;

    const stringArgs = stringify({
        mode: 'logout',
        yu,
        retpath,
    });

    return `${passportHost}/passport?${stringArgs}`;
}

export class UserMenu extends React.Component<Props> {
    static contextTypes = {
        staticUrls: PropTypes.object.isRequired,
        basePath: PropTypes.string.isRequired,
        prefix: PropTypes.string.isRequired,
    };

    context!: React.ContextType<
        React.Context<{
            staticUrls: Record<string, string>;
            basePath: string;
            prefix: string;
        }>
    >;

    handleSalesCodeMenuClick = () => {
        const { toggleSalesCodeModal } = this.props;

        toggleSalesCodeModal(true);
    };

    renderSalesCodeItem = () => {
        return (
            <span className="user__menu-item" {...accessibleClick(this.handleSalesCodeMenuClick)}>
                <Text id="user.menu.salesCode" />
            </span>
        );
    };

    render() {
        const { displayName, firstName, lastName, location, retpath, userEmail, me } = this.props;
        const isParent = me?.is_parent;
        const isTeacher = me?.is_teacher;
        const { basePath, prefix, staticUrls } = this.context;
        const { pathname, search } = location;
        const logoutRetpath =
            isParent || pathname === staticUrls.parentSpecCourse
                ? `${basePath}${prefix}${staticUrls.parentMain}`
                : retpath || `${basePath}${prefix}${pathname}${search}`;

        if (isSSR) {
            return null;
        }

        const passportHost = getPassportHost();

        return (
            <div className="user__menu">
                <div className="user__menu-header">
                    <UserIcon />
                    {/*те же элементы, что и в user__info, но с другими стилями и компоновкой */}
                    {/*не хочется много флагов, поэтому дублируем здесь*/}
                    <div className="user__menu-header-info">
                        <span className="user__menu-header-name">
                            <Text ellipsis weight={Text.WEIGHT.M}>
                                {getUserName(firstName, lastName, displayName)}
                            </Text>
                        </span>
                        <span className="user__menu-header-email">
                            <Text ellipsis size={Text.SIZE.S} weight={Text.WEIGHT.M}>
                                {userEmail}
                            </Text>
                        </span>
                    </div>
                    <IconClose className="user__close" />
                </div>
                <div className="user__menu-items">
                    <a className="user__menu-item" href={`${MAIL_HOST}/compose`}>
                        <Text id="user.menu.mail" />
                    </a>
                    <Switch>
                        <Route
                            path={[staticUrls.parent, staticUrls.parentMain]}
                            render={() => (
                                <>
                                    {!isParent && (
                                        <a
                                            className="user__menu-item"
                                            href={`${basePath}${prefix}${staticUrls.registration}`}
                                        >
                                            <Text id="user.menu.iAmTeacher" />
                                        </a>
                                    )}
                                </>
                            )}
                        />
                        <Route path={staticUrls.teacherRegistration} render={() => this.renderSalesCodeItem()} />
                        <Route
                            path={staticUrls.labHome}
                            render={() => (isTeacher ? this.renderSalesCodeItem() : null)}
                        />
                    </Switch>
                    <a className="user__menu-item" href={DISK_HOST}>
                        <Text id="user.menu.disk" />
                    </a>
                    <a className="user__menu-item" href={TUNE_HOST}>
                        <Text id="user.menu.tune" />
                    </a>
                    <a className="user__menu-item" href={passportHost}>
                        <Text id="user.menu.passport" />
                    </a>
                    <a className="user__menu-item" href={getLogoutUrl(passportHost, logoutRetpath)}>
                        <Text id="user.menu.logout" />
                    </a>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (storeState: any) => {
    return {
        avatarUrl: getUserAvatarUrl(storeState),
        displayName: getDisplayName(storeState),
        firstName: getFirstName(storeState),
        lastName: getLastName(storeState),
        userEmail: getUserEmail(storeState),
        me: getMe(storeState),
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return bindActionCreators(
        {
            toggleSalesCodeModal: salesCodeModalActions.toggleSalesCodeModal,
        },
        dispatch
    );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserMenu)) as React.ComponentType<OwnProps>;
