import { postFetch } from '../fetch';
import { apiConfig } from '../config';

export function putSaveCLesson(lesson: any, lessonArgs: any) {
    const { apiPutSaveCLesson } = apiConfig.getStaticUrls();

    return postFetch(apiPutSaveCLesson, {
        lesson: JSON.stringify(lesson),
        ...lessonArgs,
    });
}
