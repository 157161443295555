/* eslint-disable @typescript-eslint/ban-types */
import { assign, interpret, Interpreter, Machine, MachineOptions, StateMachine } from 'xstate';
import { AssignMeta } from 'xstate/lib/types';

import { allGuards } from './guards';
import { AssignTeam, MachineContext, MachineEvents, Next, Reset, TEAM_BUILDER_STEPS, Update } from './typings';

// используется в тестах
export enum TEAM_BUILDER_INNER_STEPS {
    START = 'start',
    FINAL = 'final',
}

interface MachineStateSchema {
    states: {
        [TEAM_BUILDER_INNER_STEPS.START]: {};
        [TEAM_BUILDER_STEPS.TEAM]: {};
        [TEAM_BUILDER_STEPS.SCHOOL]: {};
        [TEAM_BUILDER_STEPS.SCHOOL_FOR_MES]: {};
        [TEAM_BUILDER_STEPS.MES_SUBMIT]: {};
        [TEAM_BUILDER_STEPS.LETTER]: {};
        [TEAM_BUILDER_STEPS.STUDENTS]: {};
        [TEAM_BUILDER_STEPS.TEMPLATES_LOADING]: {};
        [TEAM_BUILDER_STEPS.ERROR]: {};
        [TEAM_BUILDER_STEPS.ADDING_SUBJECTS]: {};
        [TEAM_BUILDER_STEPS.SUBJECTS]: {};
        [TEAM_BUILDER_STEPS.CODE]: {};
        [TEAM_BUILDER_STEPS.SUBMIT_CODE]: {};
        [TEAM_BUILDER_STEPS.SUBMIT]: {};
        [TEAM_BUILDER_STEPS.LOGINS]: {};
        [TEAM_BUILDER_STEPS.FALLBACK_POLL]: {};
        [TEAM_BUILDER_STEPS.STUDENT_LINK]: {};
        [TEAM_BUILDER_INNER_STEPS.FINAL]: {};
    };
}

export class TeamBuilderMachine {
    private readonly builder: StateMachine<MachineContext, MachineStateSchema, MachineEvents>;
    private interpreter: Interpreter<MachineContext, any, MachineEvents>;

    constructor(context?: MachineContext) {
        this.builder = TeamBuilderMachine.initBuilder(context);
    }

    private static initBuilder(initialContext?: MachineContext) {
        return Machine<MachineContext, MachineStateSchema, MachineEvents>(
            {
                id: 'builder',
                context: initialContext,
                initial: TEAM_BUILDER_INNER_STEPS.START,
                states: {
                    [TEAM_BUILDER_INNER_STEPS.START]: {
                        always: [
                            {
                                target: TEAM_BUILDER_STEPS.TEAM,
                                cond: {
                                    type: 'everyGuard',
                                    guards: ['hasSchool', 'isTeamEntryPoint'],
                                },
                            },
                            {
                                target: TEAM_BUILDER_STEPS.LETTER,
                                cond: {
                                    type: 'everyGuard',
                                    guards: [
                                        'isStudentsEntryPoint',
                                        'hasTeam',
                                        'hasSchool',
                                        { guardName: 'hasLetter', negative: true },
                                    ],
                                },
                            },
                            {
                                target: TEAM_BUILDER_STEPS.STUDENTS,
                                cond: {
                                    type: 'everyGuard',
                                    guards: ['isStudentsEntryPoint', 'hasTeam', 'hasSchool'],
                                },
                            },
                            {
                                target: TEAM_BUILDER_STEPS.ADDING_SUBJECTS,
                                cond: {
                                    type: 'everyGuard',
                                    guards: ['isSubjectsEntryPoint', 'hasTeam', 'hasSchool'],
                                },
                            },
                            /* проверить работу cond для публичного кабинета учителя при добавлении первого самого класса - https://ab.yandex-team.ru/testid/687682 */
                            {
                                target: TEAM_BUILDER_STEPS.SCHOOL,
                                cond: {
                                    type: 'everyGuard',
                                    guards: [
                                        'shouldEnterSchool',
                                        { guardName: 'isSchoolForMesEntryPoint', negative: true },
                                    ],
                                },
                            },
                            {
                                target: TEAM_BUILDER_STEPS.SCHOOL_FOR_MES,
                                cond: {
                                    type: 'everyGuard',
                                    guards: ['isSchoolForMesEntryPoint', { guardName: 'hasSchool', negative: true }],
                                },
                            },
                            {
                                target: TEAM_BUILDER_STEPS.SCHOOL,
                                cond: {
                                    type: 'everyGuard',
                                    guards: ['isSchoolEntryPoint', { guardName: 'hasSchool', negative: true }],
                                },
                            },
                            {
                                target: TEAM_BUILDER_STEPS.STUDENT_LINK,
                                cond: 'isStudentLinkEntryPoint',
                            },
                            {
                                target: TEAM_BUILDER_STEPS.FALLBACK_POLL,
                                cond: 'isFallbackStudentsEntryPoint',
                            },
                            {
                                target: TEAM_BUILDER_STEPS.ERROR,
                            },
                        ],
                        on: {
                            BACK: [
                                {
                                    target: TEAM_BUILDER_STEPS.ERROR,
                                },
                            ],
                        },
                    },
                    [TEAM_BUILDER_STEPS.SCHOOL]: {
                        on: {
                            ASSIGN: {
                                actions: ['assignSchool'],
                            },
                            NEXT: [
                                {
                                    target: TEAM_BUILDER_STEPS.TEAM,
                                    cond: 'isTeamEntryPoint',
                                    actions: ['setStep'],
                                },
                                {
                                    target: TEAM_BUILDER_STEPS.LETTER,
                                    cond: {
                                        type: 'everyGuard',
                                        guards: [{ guardName: 'hasLetter', negative: true }],
                                    },
                                    actions: ['setStep'],
                                },
                                {
                                    target: TEAM_BUILDER_STEPS.STUDENTS,
                                    cond: {
                                        type: 'everyGuard',
                                        guards: ['isStudentsEntryPoint', 'isInOldAddStudentFlow'],
                                    },
                                    actions: ['setStep'],
                                },
                                {
                                    target: TEAM_BUILDER_STEPS.ADDING_SUBJECTS,
                                    cond: 'isSubjectsEntryPoint',
                                    actions: ['setStep'],
                                },
                                {
                                    target: TEAM_BUILDER_STEPS.FALLBACK_POLL,
                                    cond: 'isFallbackStudentsEntryPoint',
                                    actions: ['setStep'],
                                },
                                {
                                    target: TEAM_BUILDER_STEPS.SUBMIT,
                                    cond: {
                                        type: 'everyGuard',
                                        guards: ['isSchoolEntryPoint', 'hasSchool'],
                                    },
                                    actions: ['setStep'],
                                },
                                {
                                    target: TEAM_BUILDER_STEPS.SUBMIT,
                                    cond: {
                                        type: 'everyGuard',
                                        guards: ['isStudentsEntryPoint'],
                                    },
                                    actions: ['setStep'],
                                },
                                {
                                    target: TEAM_BUILDER_STEPS.ERROR,
                                    actions: ['setStep'],
                                },
                            ],
                            BACK: [
                                {
                                    target: TEAM_BUILDER_STEPS.ERROR,
                                },
                            ],
                        },
                    },
                    [TEAM_BUILDER_STEPS.SCHOOL_FOR_MES]: {
                        on: {
                            ASSIGN: {
                                actions: ['assignSchool'],
                            },
                            NEXT: [
                                {
                                    target: TEAM_BUILDER_STEPS.MES_SUBMIT,
                                    cond: {
                                        type: 'everyGuard',
                                        guards: ['hasSchool'],
                                    },
                                    actions: ['setStep'],
                                },
                                {
                                    target: TEAM_BUILDER_STEPS.ERROR,
                                    cond: {
                                        type: 'everyGuard',
                                        guards: [{ guardName: 'hasSchool', negative: true }],
                                    },
                                    actions: ['setStep'],
                                },
                            ],
                            BACK: [
                                {
                                    target: TEAM_BUILDER_STEPS.ERROR,
                                },
                            ],
                        },
                    },
                    [TEAM_BUILDER_STEPS.MES_SUBMIT]: {
                        on: {
                            NEXT: [{ target: TEAM_BUILDER_INNER_STEPS.FINAL }],
                            BACK: [
                                {
                                    target: TEAM_BUILDER_STEPS.SCHOOL_FOR_MES,
                                    cond: {
                                        type: 'everyGuard',
                                        guards: ['hasSchool'],
                                    },
                                    actions: ['unsetStep'],
                                },
                                {
                                    target: TEAM_BUILDER_STEPS.ERROR,
                                },
                            ],
                        },
                    },
                    [TEAM_BUILDER_STEPS.TEAM]: {
                        on: {
                            ASSIGN_TEAM: {
                                actions: ['assignTeam'],
                            },
                            ASSIGN: {
                                target: TEAM_BUILDER_STEPS.ERROR,
                            },
                            NEXT: [
                                {
                                    target: TEAM_BUILDER_STEPS.SUBJECTS,
                                    cond: {
                                        type: 'everyGuard',
                                        guards: ['isSecondary', 'isInStudentLinkExperiment'],
                                    },
                                    actions: ['setStep'],
                                },
                                {
                                    target: TEAM_BUILDER_STEPS.SUBMIT,
                                    cond: {
                                        type: 'everyGuard',
                                        guards: ['isInStudentLinkExperiment'],
                                    },
                                    actions: ['setStep'],
                                },
                                {
                                    target: TEAM_BUILDER_STEPS.STUDENTS,
                                    actions: ['setStep'],
                                },
                            ],
                            TO_CODE: {
                                target: TEAM_BUILDER_STEPS.CODE,
                                actions: ['clearTeamData', 'setStep'],
                            },
                            BACK: [
                                {
                                    target: TEAM_BUILDER_STEPS.SCHOOL,
                                    cond: 'isPrevStepSchool',
                                    actions: ['unsetStep'],
                                },
                            ],
                        },
                    },
                    [TEAM_BUILDER_STEPS.LETTER]: {
                        on: {
                            ASSIGN: {
                                actions: ['assignInfo'],
                            },
                            NEXT: [
                                {
                                    target: TEAM_BUILDER_STEPS.STUDENTS,
                                    cond: {
                                        type: 'everyGuard',
                                        guards: ['isStudentsEntryPoint', 'isInOldAddStudentFlow'],
                                    },
                                    actions: ['setStep'],
                                },
                                {
                                    target: TEAM_BUILDER_STEPS.ADDING_SUBJECTS,
                                    cond: 'isSubjectsEntryPoint',
                                    actions: ['setStep'],
                                },
                                {
                                    target: TEAM_BUILDER_STEPS.FALLBACK_POLL,
                                    cond: 'isFallbackStudentsEntryPoint',
                                    actions: ['setStep'],
                                },
                                {
                                    target: TEAM_BUILDER_STEPS.SUBMIT,
                                    cond: {
                                        type: 'everyGuard',
                                        guards: ['isSchoolEntryPoint', 'hasSchool'],
                                    },
                                    actions: ['setStep'],
                                },
                                {
                                    target: TEAM_BUILDER_STEPS.SUBMIT,
                                    cond: {
                                        type: 'everyGuard',
                                        guards: ['isStudentsEntryPoint'],
                                    },
                                    actions: ['setStep'],
                                },
                                {
                                    target: TEAM_BUILDER_STEPS.ERROR,
                                    actions: ['setStep'],
                                },
                            ],
                            BACK: [
                                {
                                    target: TEAM_BUILDER_STEPS.SCHOOL,
                                    cond: 'isPrevStepSchool',
                                    actions: ['unsetStep'],
                                },
                            ],
                        },
                    },
                    [TEAM_BUILDER_STEPS.STUDENTS]: {
                        on: {
                            ASSIGN: {
                                actions: ['assignStudents'],
                            },
                            NEXT: [
                                {
                                    target: TEAM_BUILDER_STEPS.SUBMIT,
                                    cond: {
                                        type: 'everyGuard',
                                        guards: ['isPrimary', 'isTeamEntryPoint'],
                                    },
                                    actions: ['setStep'],
                                },
                                {
                                    target: TEAM_BUILDER_STEPS.SUBMIT,
                                    cond: 'isStudentsEntryPoint',
                                    actions: ['setStep'],
                                },
                                {
                                    target: TEAM_BUILDER_STEPS.SUBMIT,
                                    cond: 'isFallbackStudentsEntryPoint',
                                    actions: ['setStep'],
                                },
                                {
                                    target: TEAM_BUILDER_STEPS.SUBMIT,
                                    cond: {
                                        type: 'everyGuard',
                                        guards: ['isInStudentLinkExperiment', 'isStudentLinkEntryPoint'],
                                    },
                                    actions: ['setStep'],
                                },
                                {
                                    target: TEAM_BUILDER_STEPS.TEMPLATES_LOADING,
                                    cond: {
                                        type: 'everyGuard',
                                        guards: ['isSecondary', 'shouldLoadTemplates'],
                                    },
                                    actions: ['setStep'],
                                },
                                {
                                    target: TEAM_BUILDER_STEPS.SUBJECTS,
                                    cond: 'isSecondary',
                                    actions: ['setStep'],
                                },
                            ],
                            BACK: [
                                {
                                    target: TEAM_BUILDER_STEPS.LETTER,
                                    cond: 'isPrevStepLetter',
                                    actions: ['unsetStep'],
                                },
                                {
                                    target: TEAM_BUILDER_STEPS.TEAM,
                                    cond: 'isPrevStepTeam',
                                    actions: ['unsetStep'],
                                },
                            ],
                        },
                    },
                    [TEAM_BUILDER_STEPS.TEMPLATES_LOADING]: {
                        on: {
                            NEXT: {
                                target: TEAM_BUILDER_STEPS.SUBJECTS,
                                // технический статус, на него нельзя возвращаться по кнопке "назад"
                                // поэтому setStep не делаем
                            },
                            BACK: {
                                target: TEAM_BUILDER_STEPS.ERROR,
                            },
                        },
                    },
                    [TEAM_BUILDER_STEPS.SUBJECTS]: {
                        on: {
                            ASSIGN: {
                                actions: ['assignSubjects'],
                            },
                            NEXT: [
                                {
                                    target: TEAM_BUILDER_STEPS.SUBMIT,
                                    actions: ['setStep'],
                                },
                            ],
                            BACK: [
                                {
                                    target: TEAM_BUILDER_STEPS.TEAM,
                                    cond: 'isPrevStepTeam',
                                    actions: ['unsetStep'],
                                },
                                {
                                    target: TEAM_BUILDER_STEPS.STUDENTS,
                                    cond: 'isPrevStepStudents',
                                    actions: ['unsetStep'],
                                },
                                {
                                    target: TEAM_BUILDER_STEPS.ERROR,
                                    cond: 'isPrevStepCode',
                                },
                            ],
                        },
                    },
                    [TEAM_BUILDER_STEPS.ADDING_SUBJECTS]: {
                        on: {
                            ASSIGN: {
                                actions: ['assignSubjects'],
                            },
                            NEXT: [
                                {
                                    target: TEAM_BUILDER_STEPS.SUBMIT,
                                    actions: ['setStep'],
                                },
                            ],
                            BACK: [
                                {
                                    target: TEAM_BUILDER_STEPS.SCHOOL,
                                    cond: 'isPrevStepSchool',
                                    actions: ['unsetStep'],
                                },
                                {
                                    target: TEAM_BUILDER_STEPS.CODE,
                                    cond: 'isPrevStepCode',
                                    actions: ['unsetStep'],
                                },
                            ],
                        },
                    },
                    [TEAM_BUILDER_STEPS.SUBMIT]: {
                        on: {
                            NEXT: [
                                {
                                    target: TEAM_BUILDER_STEPS.LOGINS,
                                    cond: {
                                        type: 'everyGuard',
                                        guards: ['shouldDisplayLogins'],
                                    },
                                    // не записываем step в passedSteps, так как туда нельзя вернуться
                                },
                                { target: TEAM_BUILDER_INNER_STEPS.FINAL },
                            ],
                            BACK: [
                                {
                                    target: TEAM_BUILDER_STEPS.SCHOOL,
                                    cond: 'isPrevStepSchool',
                                    actions: ['unsetStep'],
                                },
                                {
                                    target: TEAM_BUILDER_STEPS.TEAM,
                                    cond: 'isPrevStepTeam',
                                    actions: ['unsetStep'],
                                },
                                {
                                    target: TEAM_BUILDER_STEPS.STUDENTS,
                                    cond: 'isPrevStepStudents',
                                    actions: ['unsetStep'],
                                },
                                {
                                    target: TEAM_BUILDER_STEPS.SUBJECTS,
                                    cond: 'isPrevStepSubjects',
                                    actions: ['unsetStep'],
                                },
                                {
                                    target: TEAM_BUILDER_STEPS.ADDING_SUBJECTS,
                                    cond: 'isPrevStepAddingSubjects',
                                    actions: ['unsetStep'],
                                },
                                {
                                    target: TEAM_BUILDER_STEPS.ERROR,
                                },
                            ],
                        },
                    },
                    [TEAM_BUILDER_STEPS.LOGINS]: {
                        on: {
                            NEXT: {
                                target: TEAM_BUILDER_INNER_STEPS.FINAL,
                                actions: ['setStep'],
                            },
                            BACK: [
                                {
                                    target: TEAM_BUILDER_STEPS.STUDENTS,
                                    cond: 'isPrevStepStudents',
                                    actions: ['unsetStep'],
                                },
                                {
                                    target: TEAM_BUILDER_STEPS.SUBJECTS,
                                    cond: 'isPrevStepSubjects',
                                    actions: ['unsetStep'],
                                },
                            ],
                        },
                    },
                    [TEAM_BUILDER_STEPS.CODE]: {
                        on: {
                            ASSIGN_TEAM: {
                                target: TEAM_BUILDER_STEPS.ERROR,
                            },
                            ASSIGN: {
                                actions: 'assignCode',
                            },
                            NEXT: {
                                target: TEAM_BUILDER_STEPS.SUBMIT_CODE,
                                actions: 'setStep',
                            },
                            BACK: [
                                {
                                    target: TEAM_BUILDER_STEPS.SCHOOL,
                                    cond: 'isPrevStepSchool',
                                    actions: ['unsetStep'],
                                },
                                {
                                    target: TEAM_BUILDER_STEPS.TEAM,
                                    cond: 'isPrevStepTeam',
                                    actions: ['unsetStep'],
                                },
                            ],
                        },
                    },
                    [TEAM_BUILDER_STEPS.SUBMIT_CODE]: {
                        on: {
                            ASSIGN_TEAM: {
                                target: TEAM_BUILDER_STEPS.ERROR,
                            },
                            ASSIGN: {
                                target: TEAM_BUILDER_STEPS.ERROR,
                            },
                            NEXT: {
                                target: TEAM_BUILDER_STEPS.ADDING_SUBJECTS,
                            },
                            BACK: {
                                target: TEAM_BUILDER_STEPS.CODE,
                                actions: ['unsetStep'],
                            },
                        },
                    },
                    [TEAM_BUILDER_STEPS.STUDENT_LINK]: {
                        on: {
                            NEXT: {
                                target: TEAM_BUILDER_STEPS.FALLBACK_POLL,
                            },
                        },
                    },
                    [TEAM_BUILDER_STEPS.FALLBACK_POLL]: {
                        on: {
                            NEXT: [
                                {
                                    target: TEAM_BUILDER_STEPS.STUDENTS,
                                    actions: ['setStep'],
                                },
                            ],
                        },
                    },
                    [TEAM_BUILDER_STEPS.ERROR]: {
                        on: {
                            NEXT: {
                                target: TEAM_BUILDER_INNER_STEPS.FINAL,
                                actions: ['setHasError', 'setStep'],
                            },
                        },
                    },
                    [TEAM_BUILDER_INNER_STEPS.FINAL]: {},
                },
                on: {
                    BACK: {
                        target: TEAM_BUILDER_INNER_STEPS.FINAL,
                        cond: 'isPrevStepEmpty',
                    },
                    UPDATE: {
                        actions: 'updateContext',
                    },
                    RESET: {
                        target: TEAM_BUILDER_INNER_STEPS.START,
                        actions: 'resetContext',
                    },
                },
            },
            {
                guards: allGuards,
                actions: {
                    assignTeam: assign({
                        hasTeam: true,
                        grade: (unusedContext, event: AssignTeam) => {
                            return event.grade;
                        },
                        hasLetter: false,
                        hasStudents: false,
                        hasSubjects: false,
                    }),
                    assignInfo: assign({
                        hasLetter: true,
                    }),
                    clearTeamData: assign({
                        hasTeam: false,
                        grade: null,
                        hasStudents: false,
                        hasSubjects: false,
                    }),
                    assignSchool: assign({
                        hasSchool: true,
                    }),
                    assignStudents: assign({
                        hasStudents: true,
                    }),
                    assignSubjects: assign({
                        hasSubjects: true,
                    }),
                    assignCode: assign({
                        hasCode: true,
                    }),
                    setHasError: assign({
                        hasError: true,
                    }),
                    setStep: assign({
                        passedSteps: (context, event: Next, meta: AssignMeta<MachineContext, Next>) => {
                            const resolvedValue = meta.state?.value;
                            if (typeof resolvedValue === 'string') {
                                context.passedSteps.push(resolvedValue as TEAM_BUILDER_STEPS);
                            }
                            return context.passedSteps;
                        },
                    }),
                    unsetStep: assign({
                        passedSteps: (context) => {
                            context.passedSteps.pop();
                            return context.passedSteps;
                        },
                    }),
                    updateContext: assign((context, event: Update) => {
                        return { ...context, ...event.newContext };
                    }),
                    resetContext: assign((unusedContext, event: Reset) => {
                        return event.newContext;
                    }),
                },
            } as Partial<MachineOptions<MachineContext, MachineEvents>>
        );
    }

    // используется в компоненте, для хука
    public getMachine = () => {
        return this.builder;
    };

    // используются в тестах, в реакт-компоненте используется хук
    public start() {
        this.interpreter = interpret(this.builder);
        this.interpreter.start();
    }

    public stop() {
        this.interpreter.stop();
    }

    public getState() {
        return this.interpreter.state.value;
    }

    public getContext() {
        return this.interpreter.state.context;
    }

    public trigger(event: MachineEvents) {
        this.interpreter.send(event);
    }
}
