import { postFetch } from '../fetch';
import { apiConfig } from '../config';

import { FullCLesson, Lesson } from '../../../typings/';

export type PostCreateLessonRequestData = Pick<Lesson, 'problems' | 'name' | 'scenario' | 'owner' | 'meta'>;

export function postCreateClesson(lesson: PostCreateLessonRequestData): Promise<FullCLesson> {
    const { apiPostCreateCLesson } = apiConfig.getStaticUrls();

    return postFetch(apiPostCreateCLesson, {
        lesson: JSON.stringify(lesson),
    });
}
