import b from 'b_';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { NOTIFICATION_THEMES, TEXT_MARKUP_TYPE } from '../constants';
import { IconClose } from '../icon/icon';
import Text from '../text/text';

import { hideNotification as hideNotificationAction } from './actions';
import { DEFAULT_NOTIFICATION_ID } from './constants';
import { notificationBodysStore } from './notificationBodysStore';
import { getNotification } from './selectors';

import './page-notification.scss';

class PageNotification extends React.Component {
    static propTypes = {
        closeDelay: PropTypes.number,
        hideNotification: PropTypes.func.isRequired,
        notification: PropTypes.shape({
            icon: PropTypes.node,
            id: PropTypes.string,
            theme: PropTypes.oneOf(Object.values(NOTIFICATION_THEMES)),
            notificationAction: PropTypes.func,
            notificationMessageId: PropTypes.string,
            mix: PropTypes.string,
            withExplicitClose: PropTypes.bool,
            closeDelay: PropTypes.number,
            textType: PropTypes.oneOf(Object.values(TEXT_MARKUP_TYPE)),
        }),
        notificationAction: PropTypes.func,
        notificationMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        withExplicitClose: PropTypes.bool,
    };

    onActionClick = () => {
        const {
            notification: { notificationAction },
            hideNotification,
        } = this.props;

        hideNotification();
        notificationAction();
    };

    render() {
        const { notification, hideNotification } = this.props;
        const {
            notificationAction,
            notificationMessageId,
            theme,
            mix,
            icon,
            id,
            withExplicitClose,
            closeDelay,
            textType,
        } = notification;
        const className = cx(b('page', 'notification', { theme }), mix);
        const notificationBody = notificationBodysStore[id || DEFAULT_NOTIFICATION_ID];
        const withAction = Boolean(notificationAction && notificationMessageId);
        const withIcon = Boolean(icon);

        // сбрасываем delay от предыдущего уведомления
        // Делается, чтобы избежать гонок уведомлений
        // особенно яркий кейс, когда первое уведомление с задержкой, а второе без:
        // второе исчезает, когда истекает задержка первого
        if (this.delayTimeout) {
            clearTimeout(this.delayTimeout);
        }

        if (closeDelay) {
            this.delayTimeout = setTimeout(hideNotification, closeDelay);
        }

        return (
            <div className={className} onClick={withExplicitClose ? undefined : hideNotification}>
                <div className={b('page', 'notification-body', { 'with-action': withAction })}>
                    {withIcon && <div className="page__notification-body-icon">{icon}</div>}
                    <div className="page__notification-body-content-wrapper">
                        <div className="page__notification-body-content">
                            {React.cloneElement(notificationBody, { notification })}
                        </div>
                        {withAction && (
                            <div className="page__notification-undo" onClick={this.onActionClick}>
                                <Text id={notificationMessageId} type={textType} weight={Text.WEIGHT.M} />
                            </div>
                        )}
                    </div>
                    <IconClose mix="page__notification-close" onClick={hideNotification} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    let notification = getNotification(state);

    if (!notification.id) {
        notification = ownProps.notification;
    }

    return { notification };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ hideNotification: hideNotificationAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PageNotification);
