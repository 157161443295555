import * as constants from './constants';

export const authByCodeRequest = (code: string, callback: (classId: number) => void) =>
    <const>{
        type: constants.AUTH_BY_CODE_REQUEST,
        code,
        callback,
    };

export const authByCodeSuccess = () =>
    <const>{
        type: constants.AUTH_BY_CODE_SUCCESS,
    };

export const authByCodeError = () =>
    <const>{
        type: constants.AUTH_BY_CODE_ERROR,
    };
