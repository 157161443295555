import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './form-view__card-subtitle.scss';

const FormViewCardSubtitle = ({ children, mix }) => (
    <div className={cx('form-view__card-subtitle', mix)}>{children}</div>
);

FormViewCardSubtitle.propTypes = {
    children: PropTypes.node.isRequired,
    mix: PropTypes.string,
};

export default FormViewCardSubtitle;
