import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import { Team } from '@yandex-int/k-common/typings';
import get from 'lodash/get';
import { getFeatureFlags } from 'platform-components/src/components/app-factory/selectors';
import { createSelector } from 'reselect';

import { ReduxIndexStore } from '../../typings';

import { TeacherRegistrationStore } from './typings';

const teacherRegistrationStateSelector = (storeState: ReduxIndexStore): TeacherRegistrationStore =>
    get(storeState, 'teacherRegistration');

export const getLoadingStatus = createSelector(
    teacherRegistrationStateSelector,
    (state: TeacherRegistrationStore): LOADING_STATUSES => state.loadingStatus
);

export const getApplyCodeLoadingStatus = createSelector(
    teacherRegistrationStateSelector,
    (state: TeacherRegistrationStore): LOADING_STATUSES => state.applyCodeStatus
);

export const getCreatedClass = createSelector(
    teacherRegistrationStateSelector,
    (state: TeacherRegistrationStore): Team | undefined => state.createdClass
);

export const shouldForceSelectingAllSubjectsForGrade = (
    store: ReduxIndexStore,
    grade: number | null | undefined
): boolean => {
    if (grade === null || grade === undefined) {
        return false;
    }

    const coursesTemplates = store.coursesTemplates.grades[grade];

    if (!coursesTemplates) {
        return false;
    }

    // Если при регистрации доступен всего один предмет - сразу выбираем его.
    // Такая логика нужна в первую очередь для учителей 6-11 классов,
    // у которых есть только "Свой предмет"
    return coursesTemplates.list.length === 1;
};

export const getIsSubjectTeachersFeatureEnabled = createSelector([getFeatureFlags], (featureFlags): boolean =>
    Boolean(featureFlags?.subjectTeachersEnabled)
);
