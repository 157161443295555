import { Me } from '@yandex-int/k-common/typings/me';
import { Close as IconClose } from 'education-icons';
import { Button, Link, SimpleText, Text } from 'platform-components';
// @ts-ignore
import { getMe, getUserAvatarUrl } from 'platform-components/src/components/user/selectors';
import { InjectedProps as WithRouterPathProps, withRouterPath } from 'platform-components/hocs';
import { setToLocalStorage } from 'platform-components/utils';
import PropTypes from 'prop-types';
import { stringify } from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import ym from 'react-yandex-metrika';
import { accessibleClick } from '@edu-frontend/modules/src/shared/lib/a11y';

import { getUserName } from 'common.components/user/selectors';
import { getPassportHost } from 'utils/get-passport-host';

import { UserIcon } from '../user/__icon/user__icon';

import { LOCAL_STORAGE_KEY } from './constants';
import { shouldShowUserPopup } from './utils';

import './user-popup.scss';

function getPassportUrl(retpath: string): string {
    const passportHost = getPassportHost();
    const stringArgs = stringify({
        mode: 'list',
        origin: 'education',
        retpath,
    });

    return `${passportHost}/passport?${stringArgs}`;
}

interface StoreProps {
    avatarUrl?: string;
    userName?: string;
    me: Me | null;
}

interface Props extends StoreProps, WithRouterPathProps {}

interface State {
    shouldShowUserPopup: boolean;
}

export class UserPopup extends React.Component<Props, State> {
    static contextTypes = {
        staticUrls: PropTypes.object.isRequired,
        basePath: PropTypes.string.isRequired,
        prefix: PropTypes.string.isRequired,
    };

    state: State = {
        shouldShowUserPopup: false,
    };

    componentDidMount(): void {
        const { me } = this.props;

        if (shouldShowUserPopup(me)) {
            this.reachGoal('teacher_enter_modal_view');
            setToLocalStorage(LOCAL_STORAGE_KEY, null);
            // eslint-disable-next-line react/no-did-mount-set-state
            this.setState({ shouldShowUserPopup: true });
        }
    }
    context!: React.ContextType<
        React.Context<{
            basePath: string;
            prefix: string;
        }>
    >;

    reachGoal = (goal: string) => {
        const { me } = this.props;
        const location = me?.is_teacher || me?.is_parent ? 'platform' : 'onboarding';

        ym('reachGoal', goal, {
            location,
            user_id: me?.id,
        });
    };

    handleClickContinueButton = () => {
        const { me } = this.props;

        this.reachGoal('teacher_enter_modal_continue');
        setToLocalStorage(LOCAL_STORAGE_KEY, me?.id);
        this.setState({ shouldShowUserPopup: false });
    };

    handleClickChangeAccountButton = () => {
        this.reachGoal('teacher_enter_modal_сhange_account');
    };

    render() {
        const {
            avatarUrl,
            userName,
            me,
            location: { pathname, search },
        } = this.props;
        const { basePath, prefix } = this.context;

        if (!this.state.shouldShowUserPopup) {
            return null;
        }

        const retpath = `${basePath}${prefix}${pathname}${search}`;
        // @ts-ignore
        const email = me?.email;

        return (
            <div className="user-popup">
                <div className="user-popup__close-wrapper">
                    <span className="user-popup__close-tapzone" {...accessibleClick(this.handleClickContinueButton)}>
                        <IconClose />
                    </span>
                </div>
                <div className="user-popup__content">
                    {avatarUrl && <UserIcon mix="user-popup__avatar" />}
                    <div className="user-popup__user-name">
                        <div className="user-popup__name">
                            <Text
                                id="userPopup.enter"
                                size={Text.SIZE.XL}
                                values={{ username: userName }}
                                weight={Text.WEIGHT.M}
                            />
                        </div>
                        <div className="user-popup__email">
                            <Text size={Text.SIZE.S} weight={Text.WEIGHT.M}>
                                {email}
                            </Text>
                        </div>
                    </div>
                </div>

                <div className="user-popup__buttons">
                    <Button className="user-popup__button-ok" onClick={this.handleClickContinueButton}>
                        <SimpleText id="userPopup.button.ok" />
                    </Button>
                    <Link to={getPassportUrl(retpath)}>
                        <Button
                            className="user-popup__button-cancel"
                            onClick={this.handleClickChangeAccountButton}
                            view={Button.VIEW.SECONDARY}
                        >
                            <SimpleText id="userPopup.button.cancel" />
                        </Button>
                    </Link>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (storeState: any): StoreProps => {
    return {
        avatarUrl: getUserAvatarUrl(storeState),
        userName: getUserName(storeState),
        me: getMe(storeState),
    };
};

export default withRouterPath(connect(mapStateToProps)(UserPopup));
