import * as api from '@yandex-int/k-common/client/api';
import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import { FullCLesson, Me } from '@yandex-int/k-common/typings';
import { getMe } from 'platform-components/src/components/user/selectors';
import { IntlShape } from 'react-intl';
import { call, put, select, spawn, take } from 'redux-saga/effects';

import * as courseSelectors from 'store/courses/selectors';
import { getSubjectFromPath, getTeamIdFromPath } from 'utils/path';

import { actions as lessonActions } from '../index';
import * as lessonSelectors from '../selectors';
import { getLessonDefaultName } from '../utils';

import { handleDeleteOrEditError, mergeClesson } from './utils';

// здесь бэкенд обрабатывает lessonMeta, в отличии от saveOrCreateLesson
export function* createLessonByProblemIdsSaga(action: ReturnType<typeof lessonActions.createLessonByProblemIds>) {
    try {
        const { onSuccess } = action.payload;
        const isNewLesson: boolean = yield select(lessonSelectors.getIsNew);
        const createLessonStatus: LOADING_STATUSES = yield select(lessonSelectors.getCreateLessonStatus);

        if (isNewLesson) {
            if (createLessonStatus === LOADING_STATUSES.LOADING) {
                yield take(lessonActions.createLessonSuccess);
                yield put(lessonActions.saveLesson({ onSuccess }));
            } else {
                yield spawn(createLesson, action.payload);
                return;
            }
        } else {
            yield put(lessonActions.saveLesson({ onSuccess }));
        }
    } catch (error) {
        console.error(error);

        const clesson: FullCLesson = yield select(lessonSelectors.getLesson);
        yield handleDeleteOrEditError(error, clesson.clesson?.id!);
    }
}

interface CreateLessonParams {
    intl: IntlShape;
    problemIds: number[];
    lessonName?: string;
    lessonMeta?: string;
    onSuccess: (clessonId: number, clessonData: FullCLesson) => void;
}

function* createLesson(params: CreateLessonParams) {
    try {
        yield put(lessonActions.createLessonRequest());

        const { intl, problemIds, lessonName, lessonMeta, onSuccess } = params;
        const name = lessonName || getLessonDefaultName({ intl, isEvaluable: false });

        const teamId = getTeamIdFromPath();
        const subject = getSubjectFromPath();
        const courseId: number = yield select(courseSelectors.getDefaultCourseId, { classId: teamId, subject });
        const me: Me = yield select(getMe);

        const createdLesson = {
            problems: problemIds,
            owner_id: me ? me.id : null!, // TODO: null
            name,
            lesson_meta: lessonMeta,
            course_id: courseId,
        };
        const lessonData: FullCLesson = yield call(api.postCreateClessonByProblemIds, createdLesson);
        const cLessonId: number = lessonData.clesson?.id!;

        yield call(mergeClesson, lessonData);
        yield put(lessonActions.createLessonSuccess());
        yield put(lessonActions.getLessonAssigns({ cLessonId, courseId }));
        yield put(lessonActions.lessonUpdated());
        onSuccess(cLessonId, lessonData);
    } catch (e) {
        console.error(e);
        yield put(lessonActions.createLessonError());
    }
}
