import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const IconPlusCircle = ({ mix, ...props }) => {
    return (
        <svg className={cx('icon__plus-circle-path', mix)} {...props} viewBox="0 0 20 20">
            <path d="M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0zm5 11h-4v4H9v-4H5V9h4V5h2v4h4v2z" />
        </svg>
    );
};

IconPlusCircle.propTypes = {
    mix: PropTypes.string,
};

export default IconPlusCircle;
