import { InferValuesTypes } from '@yandex-int/k-common';

import * as actions from './actions';
import * as constants from './constants';

export type UaTraitsActions = ReturnType<InferValuesTypes<typeof actions>>;

const initialState = {};

const uatraits = (state = initialState, action: UaTraitsActions) => {
    switch (action.type) {
        case constants.SET_UATRAITS:
            return Object.assign({}, state, action.uatraits);

        default:
            return state;
    }
};

export default uatraits;
