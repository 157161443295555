import cx from 'classnames';
import React from 'react';

import './dot-notification.scss';

interface Props {
    mix?: string;
}

const DotNotification = (props: Props) => {
    const { mix } = props;

    return <div className={cx('dot-notification', mix)} />;
};

export default DotNotification;
