import { Team } from '@yandex-int/k-common/typings';
import { SimpleCallback } from 'platform-components';

import * as constants from './constants';

export const loadSubjectsForGrade = (grade: number) => <const>{ type: constants.LOAD_SUBJECTS_FOR_GRADE, grade };

export const finishRegistration = (callback: SimpleCallback) =>
    <const>{ type: constants.FINISH_REGISTRATION, callback };

export const finishRegistrationSuccess = () => <const>{ type: constants.FINISH_REGISTRATION_SUCCESS };

export const finishRegistrationError = () => <const>{ type: constants.FINISH_REGISTRATION_ERROR };

export const finishRegistrationByCode = (callback: SimpleCallback) =>
    <const>{ type: constants.FINISH_REGISTRATION_BY_CODE, callback };

export const finishRegistrationByLink = (classId: number, callback: SimpleCallback) =>
    <const>{ type: constants.FINISH_REGISTRATION_BY_LINK, classId, callback };

export const finishRegistrationByCodeSuccess = () => <const>{ type: constants.FINISH_REGISTRATION_BY_CODE_SUCCESS };

export const finishRegistrationByCodeError = () => <const>{ type: constants.FINISH_REGISTRATION_BY_CODE_ERROR };

export const applyCodeRequest = (code: string) => <const>{ type: constants.APPLY_CODE_REQUEST, code };

export const applyCodeSuccess = (team: Team) => <const>{ type: constants.APPLY_CODE_SUCCESS, team };

export const applyCodeError = (error: string) => <const>{ type: constants.APPLY_CODE_ERROR, error };
