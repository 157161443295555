import * as React from 'react';

type Props = {
    code: string;
    children: React.ReactNode;
};
type State = {
    error: boolean;
};

export class ErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            error: false,
        };
    }

    componentDidCatch() {
        this.setState({ error: true });
    }

    render() {
        return this.state.error ? this.props.code : this.props.children;
    }
}
