import b from 'b_';
import cx from 'classnames';
import { Text, Link } from 'platform-components';
import { TEXT_MARKUP_TYPE } from 'platform-components/src/components/constants';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

// @ts-ignore
import FormView from 'common.components/form-view/form-view';
// @ts-ignore
import HeadedFormViewCard from 'common.components/headed-form-view-card';

interface OwnProps extends WrappedComponentProps {
    mix?: string;
}

interface Props extends OwnProps {}

export const SubjectsFormEmptyState = ({ mix, intl }: Props) => {
    return (
        <HeadedFormViewCard headerId="subjectsForm.title.empty" mix={cx(b('subjects-form', { empty: true }), mix)}>
            <div className="subjects-form__content">
                <FormView.CardSection mix="subjects-form__empty">
                    <Text id="subjectsForm.description.empty" size={Text.SIZE.XL} type={TEXT_MARKUP_TYPE.MARKDOWN} />
                </FormView.CardSection>
                <FormView.CardSection>
                    <Link
                        target="_blank"
                        theme={Link.THEME.INHERIT}
                        to={intl.formatMessage({ id: 'subjectsForm.link.aboutYaTeacher' })}
                    >
                        <FormView.Button>
                            <Text
                                id="subjectsForm.button.moreAboutYaTeacher"
                                size={Text.SIZE.XL}
                                type={TEXT_MARKUP_TYPE.MARKDOWN}
                            />
                        </FormView.Button>
                    </Link>
                </FormView.CardSection>
            </div>
        </HeadedFormViewCard>
    );
};

export default injectIntl(SubjectsFormEmptyState);
