import { withBemMod } from '@bem-react/core';
import { cnButton } from '@yandex-lego/components/Button';

import { BUTTON_SIZE } from '../types';

export interface ButtonSizeAdaptiveProps {
    size?: BUTTON_SIZE.ADAPTIVE;
}

export const withSizeAdaptive = withBemMod<ButtonSizeAdaptiveProps>(cnButton(), { size: BUTTON_SIZE.ADAPTIVE });
