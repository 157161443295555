import { getFetch } from '../fetch';
import { apiConfig } from '../config';

import { ClassTeacher } from '../../../typings/api/defs/get-class-teachers';

export function getClassTeachers(classId: any, pageSize: any): Promise<ClassTeacher[]> {
    const { apiGetClassTeachers } = apiConfig.getStaticUrls();

    return getFetch(apiGetClassTeachers, { classId, page_size: pageSize });
}
