import b from 'b_';
import cx from 'classnames';
import { CloseKit } from 'education-icons';
import React from 'react';

import { ModalCloseProps } from '../../modal/types';

import './close-button_view_outer.scss';

enum POSITION {
    STICKY = 'sticky',
    ABSOLUTE = 'absolute',
}

export interface CloseButtonProps extends ModalCloseProps {
    position?: POSITION;
}

export const CloseButton = (props: CloseButtonProps) => {
    const { className, position = POSITION.STICKY, ...restProps } = props;
    const innerClassName = b('close-button', 'close', { position });

    return position === POSITION.ABSOLUTE ? (
        <CloseKit {...restProps} className={cx(className, innerClassName)} />
    ) : (
        <div className="close-button__close-wrapper">
            <CloseKit {...restProps} className={cx(className, innerClassName)} />
        </div>
    );
};

CloseButton.POSITION = POSITION;
