import { ClessonResults, FullCLesson } from '../../../typings';
import { apiConfig } from '../config';
import { getFetch } from '../fetch';

export interface FullClessonResult {
    clesson: FullCLesson;
    clessonResults: {
        data: ClessonResults;
    };
}

export function getFullClessonWithResults(
    clessonId: number,
    courseId: number
): Promise<FullClessonResult> {
    const { apiGetFullClessonWithResults } = apiConfig.getStaticUrls();

    return getFetch(apiGetFullClessonWithResults, { clessonId, courseId });
}
