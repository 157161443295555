import SimpleMarkdown from 'simple-markdown';

import { concatRegExps } from '../../utils/typograf/utils';

const tableRule = SimpleMarkdown.defaultRules.table;

export const LINK_INSIDE = '(?:\\[[^\\]]*\\]|[^\\]]|\\](?=[^\\[]*\\]))*';
export const LINK_HREF_AND_TITLE = '\\s*<?([^\\s]*?)>?(?:\\s+[\'"]([\\s\\S]*?)[\'"])?\\s*';
// не знаю насколько тут экранирование избыточно
// eslint-disable-next-line no-useless-escape
export const ATTRIBUTES_REGEX = '?:{:((?:\\w*(="[^"]*")?(?=(?:\\s|}))\\s?)*)}';
export const EXTENDED_TABLE_REGEX = concatRegExps(/^ *((?:[\w,: ]+\n)?)/, tableRule.match.regex);

export const ALIGNMENT = {
    '<--': 'left',
    '>-<': 'center',
    '-->': 'right',
    '<->': 'justify',
};
