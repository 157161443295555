export enum CHOICES_MODE {
    SIMPLE = 'simple',
    ID = 'id',
}

interface ChoiceWithId {
    id: string;
    name: string;
}

export function getChoicesMode(choices: string[] | ChoiceWithId[]): CHOICES_MODE {
    if (choices.length > 0 && typeof choices[0] === 'string') {
        return CHOICES_MODE.SIMPLE;
    }

    return CHOICES_MODE.ID;
}
