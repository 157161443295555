import { SimpleText, Text, SimpleCallback, PersonalAgreement, useIntl, Link } from 'platform-components';
import React from 'react';

import ClassSharingCode from 'common.components/class-sharing-code/class-sharing-code';

// @ts-ignore
import FormView from '../form-view/form-view';
// @ts-ignore
import HeadedFormViewCard from '../headed-form-view-card';

import { ERROR_MESSAGES } from './constants';

import './code-form.scss';

const submitButtonTextId = 'codeForm.sendButton';

interface Props {
    code: string | null;
    onSubmit: (code: string) => void;
    onChange: (code: string) => void;
    onGoBack: SimpleCallback;
    shouldShowPersonalDataLink?: boolean;
    error: ERROR_MESSAGES | null;
    isSubmitting?: boolean;
    showPersonalAgreement?: boolean;
}

export const CodeForm = (props: Props) => {
    const { code, onGoBack, onChange, onSubmit, shouldShowPersonalDataLink, error, isSubmitting } = props;
    const intl = useIntl();
    const submitButtonText = intl.formatMessage({ id: submitButtonTextId });

    return (
        <HeadedFormViewCard descriptionId="codeForm.description" headerId="codeForm.header" mix="code-form">
            <FormView.CardSection mix="code-form__code">
                <ClassSharingCode code={code} errorMsgId={error} onChange={onChange} />
            </FormView.CardSection>
            <FormView.CardSection>
                <FormView.Button onClick={onSubmit} progress={isSubmitting}>
                    <SimpleText id={submitButtonTextId} />
                </FormView.Button>
            </FormView.CardSection>
            {shouldShowPersonalDataLink && (
                <FormView.CardDescription mix="code-form__pd">
                    <PersonalAgreement
                        buttonText={submitButtonText}
                        linksConfig={[
                            PersonalAgreement.personalAgreementLinks.teacherPdLink,
                            PersonalAgreement.personalAgreementLinks.olympLink,
                        ]}
                    />
                </FormView.CardDescription>
            )}
            <FormView.Footer mix="code-form__footer">
                <Link onClick={onGoBack} pseudo theme={Link.THEME.PRIMARY}>
                    <Text id="codeForm.goBack" />
                </Link>
            </FormView.Footer>
        </HeadedFormViewCard>
    );
};
