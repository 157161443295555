import {
    CUSTOM_FLOW_LESSON_APPEARANCE,
    CUSTOM_FLOW_LESSON_VERSIONS,
    CustomFlowLessonAppearance,
    CustomFlowLessonStructure,
    SkillsLessonAppearance,
    SkillsLessonStructure,
} from '../../../../typings';

export function isSkillsLessonStructure(structure: CustomFlowLessonStructure | null)
    : structure is SkillsLessonStructure {

    return structure?.version === CUSTOM_FLOW_LESSON_VERSIONS.SKILLS;
}

export function isSkillsLessonAppearance(appearance: CustomFlowLessonAppearance): appearance is SkillsLessonAppearance {
    return appearance.version === CUSTOM_FLOW_LESSON_APPEARANCE.SKILLS;
}
