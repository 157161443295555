import { createContext } from 'react';

import { MENU_SIZES, MENU_THEMES } from './typings';

export interface ContextProps {
    disabled?: boolean;
    size?: MENU_SIZES;
    theme?: MENU_THEMES;
}

export const MenuContext = createContext<ContextProps>({
    disabled: false,
    size: MENU_SIZES.M,
    theme: MENU_THEMES.NORMAL,
});
