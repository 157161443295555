import { Copy, CopyButton, Link, Modal, Text } from 'platform-components/common';
import React from 'react';

import { useTeamRegistrationLink, useRegistrationLinkMetrika } from 'common.components/user/hooks';
import { YM_LOCATION } from 'utils/ym-constants';

import './team-builder__student-link.scss';

interface Props {
    onAddManuallyClick: () => void;
    location?: YM_LOCATION;
}

export const TeamBuilderStudentLink = ({ onAddManuallyClick, location }: Props) => {
    const link = useTeamRegistrationLink();
    const { ymTeacherLinkCopy, ymTeacherBadLink } = useRegistrationLinkMetrika();
    const linkText = link ? link : '';

    const ymCopyLocation = location === YM_LOCATION.LESSON_VIEW ? 'lesson_before_assign' : 'lesson_after_assign';

    const onInputCopy = () => ymTeacherLinkCopy(ymCopyLocation, 'copy_link');
    const onButtonCopy = () => ymTeacherLinkCopy(ymCopyLocation, 'list_copy');
    const onManuallyAdd = () => {
        ymTeacherBadLink(ymCopyLocation);
        onAddManuallyClick();
    };

    const titleId =
        location === YM_LOCATION.LESSON_VIEW ? 'studentLinkBlock.title' : 'studentLinkBlock.lessonCreatedTitle';

    return (
        <div className="team-builder__student-link">
            <Modal.Header>
                <Text id={titleId} size={Text.SIZE.XXXL} weight={Text.WEIGHT.M} />
            </Modal.Header>
            <Modal.Content>
                <Text id="studentLinkBlock.description" type={Text.TYPE.MARKDOWN} />
            </Modal.Content>
            <Modal.Content>
                <div className="team-builder__student-link-controls">
                    <div className="team-builder__student-link-url">
                        <Copy onClick={onInputCopy} text={linkText} />
                    </div>
                    <div className="team-builder__student-link-button-block">
                        <CopyButton onButtonClick={onButtonCopy} text={linkText} />
                    </div>
                </div>
            </Modal.Content>
            <Modal.Footer mix="team-builder__student-link-footer">
                <Link isRouter onClick={onManuallyAdd} pseudo theme={Link.THEME.PRIMARY}>
                    <Text id="studentLinkBlock.cantInviteByLink" weight={Text.WEIGHT.M} />
                </Link>
            </Modal.Footer>
        </div>
    );
};
