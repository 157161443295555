export class ErrorWithAdditional extends Error {
    public readonly additional?: object;

    constructor(message: string, additional?: object) {
        super(message);

        // RUM error-counter read this field
        // https://nda.ya.ru/t/a7Kc9x2Y3zHk9N
        this.additional = additional;
    }
}

export function createErrorFromUnknown(error: unknown): Error {
    if (error instanceof Error) {
        return error;
    }

    if (typeof error === 'object' && error !== null) {
        try {
            const serializedError = JSON.stringify(error);

            return new ErrorWithAdditional(serializedError, error);
        } catch (_) {
            const serializedError = String(error);

            return new ErrorWithAdditional(serializedError, error);
        }
    }

    const serializedError = String(error);

    return new Error(serializedError);
}
