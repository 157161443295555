import React from 'react';

export interface Props
    extends React.DetailedHTMLProps<React.IframeHTMLAttributes<HTMLIFrameElement>, HTMLIFrameElement> {
    id: string;
    url: string;
}

export const HiddenIframe = React.memo(({ id, url, ...otherProps }: Props) => {
    if (typeof window === 'undefined') {
        return null;
    }

    return <iframe hidden id={id} src={url} {...otherProps} />;
});
