import { postFetch } from '../fetch';

import { apiConfig } from '../config';
import { EXTERNAL_LMS_SLUGS } from '../../../typings';

export function postCreateTeams(
    externalTeamIds: Array<number>,
    lms: EXTERNAL_LMS_SLUGS,
    subjectId?: number,
    classLevelId?: number
): Promise<{ team_ids: Array<number> }> {
    const { apiPostCreateTeams } = apiConfig.getStaticUrls();

    return postFetch(apiPostCreateTeams, {
        external_team_ids: externalTeamIds,
        lms,
        subject_id: subjectId,
        class_level_id: classLevelId,
    });
}
