import { postFetch } from '../fetch';

import { apiConfig } from '../config';

interface AddChildCoursesRequest {
    childId: string | number;
    templateIds: Array<number>;
}

export function postAddChildCourses(requestData: AddChildCoursesRequest):Promise<any> {
    const { childId, templateIds } = requestData;
    const { apiPostAddChildCourses } = apiConfig.getStaticUrls();

    return postFetch(apiPostAddChildCourses, {
        child_id: childId,
        template_ids: templateIds,
    });
}
