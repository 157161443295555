import {
    plusSubscriptionSagasWatcher,
    getPlusSubscriptionInfoRequestSaga,
} from 'platform-components/src/components/plus-subscription/saga';
import { showNotificationWatcher } from 'platform-components/index';
import { takeLatest } from 'redux-saga/effects';

import { SET_ME } from 'common.components/user/constants';

import { redirectToErrorPageWatcher, reportNetworkErrorWatcher } from './defs/network-errors';

function* updatePlusSubscriptionInfoWatcher() {
    yield takeLatest(SET_ME, getPlusSubscriptionInfoRequestSaga);
}

export default [
    redirectToErrorPageWatcher(),
    reportNetworkErrorWatcher(),
    showNotificationWatcher(),
    plusSubscriptionSagasWatcher(),
    updatePlusSubscriptionInfoWatcher(),
];
