import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import { Text, SimpleText, Link, Button, Spin } from 'platform-components';
import { getIsTeacher } from 'platform-components/src/components/user/selectors';
import { withRouterPath, InjectedProps as WithRouterPathHocProps } from 'platform-components/hocs';
import PropTypes from 'prop-types';
import qs from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import { SEARCH_JOINED_BY_CODE } from 'common.components/constants';
import { Logo } from 'common.components/logo/logo';

import { ReduxIndexStore } from '../../typings';

import * as actions from './actions';
import * as selectors from './selectors';

import './apply-code-page.scss';

interface OwnProps {}

interface StoreProps {
    loadingStatus: LOADING_STATUSES;
    isTeacher: boolean;
}

interface DispatchProps {
    authByCodeRequest: typeof actions.authByCodeRequest;
}

interface HOCProps extends WithRouterPathHocProps {}

interface Props extends OwnProps, HOCProps, StoreProps, DispatchProps {}

export class ApplyCodePage extends React.Component<Props> {
    static contextTypes = {
        staticUrls: PropTypes.object.isRequired,
    };

    componentDidMount() {
        const {
            authByCodeRequest,
            match: {
                params: { code },
            },
        } = this.props;

        authByCodeRequest(code, this.applyCodeRedirect);
    }
    context!: React.ContextType<React.Context<{ staticUrls: Record<string, string> }>>;

    applyCodeRedirect = (classId: number) => {
        const { staticUrls } = this.context;
        const { isTeacher, getRouterPath } = this.props;

        const classSubjectsPath = `${getRouterPath(staticUrls.labClassSubjects, { classId })}?${qs.stringify({
            [SEARCH_JOINED_BY_CODE]: true,
        })}`;

        const registrationPath = getRouterPath(staticUrls.teacherRegistrationLink, { classId });

        const path = isTeacher ? classSubjectsPath : registrationPath;

        if (!isSSR) {
            window.location.href = path;
        }
    };

    render() {
        const { staticUrls } = this.context;
        const { loadingStatus } = this.props;

        return (
            <div className="apply-code-page">
                {loadingStatus !== LOADING_STATUSES.ERROR && <Spin />}
                {loadingStatus === LOADING_STATUSES.ERROR && (
                    <>
                        <Logo mix="apply-code-page__logo" />
                        <div className="apply-code-page__error-title">
                            <Text id="applyCodePage.title" size={Text.SIZE.XXXL} weight={Text.WEIGHT.M} />
                        </div>
                        <div className="apply-code-page__error-message">
                            <Text id="applyCodePage.errorMessage" />
                        </div>
                        <Link to={staticUrls.home}>
                            <Button accent={Button.ACCENT.NORMAL} view={Button.VIEW.CLEAR}>
                                <SimpleText id="applyCodePage.link" />
                            </Button>
                        </Link>
                    </>
                )}
            </div>
        );
    }
}

const mapStateToProps = (storeState: ReduxIndexStore): StoreProps => ({
    isTeacher: getIsTeacher(storeState),
    loadingStatus: selectors.getLoadingStatus(storeState),
});

const mapDispatchToProps = (dispatch: any): DispatchProps => {
    const { authByCodeRequest } = actions;

    return bindActionCreators(
        {
            authByCodeRequest,
        },
        dispatch
    );
};

export default compose(
    withRouterPath,
    connect(mapStateToProps, mapDispatchToProps)
)(ApplyCodePage) as React.ComponentType<OwnProps>;
