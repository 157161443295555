import { CampaignAwards } from '../../../typings';
import { getFetch } from '../fetch';
import { apiConfig } from '../config';

export interface StudentsCourseAwardsRequestData {
    courseId: number;
    campaignSlugs: string[];
}

export function getStudentsCourseAwards(request: StudentsCourseAwardsRequestData): Promise<CampaignAwards[]> {
    const { courseId, campaignSlugs } = request;
    const { apiGetStudentsCourseAwards } = apiConfig.getStaticUrls();

    return getFetch(apiGetStudentsCourseAwards, {
        course_id: courseId,
        campaign_slugs: campaignSlugs.join(','),
    });
}
