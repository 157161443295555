import * as constants from './constants';
import { Hint } from './typings';

export const setConfig = (config: Array<Hint>) =>
    ({
        type: constants.SET_CONFIG,
        config,
    }) as const;

export const setMatchedHints = (hints: Array<Hint>) =>
    ({
        type: constants.SET_MATCHED_HINTS,
        hints,
    }) as const;

export const addViewedHintSuccess = () =>
    ({
        type: constants.ADD_VIEWED_HINT_SUCCESS,
    }) as const;
