// @ts-ignore
import { PROBLEM_INNER_TYPES, PROBLEM_STATUS_DISPLAY_NAMES } from '@yandex-int/k-common/constants';
import { FullCLesson, LessonProblem, Problem, StudentProblemResult } from '@yandex-int/k-common/typings';
import get from 'lodash/get';
import { RobotProblem } from 'platform-components/src/components/robot/robot.types';

import { ProblemInfo } from './typings';

export function getProblemInfo(problem: Problem): ProblemInfo {
    const solution = get(problem, ['markup', 'solution']);
    const comment = get(problem, ['markup', 'cm_comment']);
    const publicSolution = get(problem, ['markup', 'public_solution']);

    const result: ProblemInfo = {};

    if (solution) {
        result.solution = solution;
    }

    if (comment) {
        result.comment = comment;
    }

    if (publicSolution) {
        result.publicSolution = publicSolution;
    }

    return result;
}

export function isDictation(problem: Problem | null): boolean {
    return Boolean(problem?.markup?.layout?.some(({ content }) => content.type === 'dictation'));
}

export function isOpenAnswer(problem: Problem | null): boolean {
    if (!problem) {
        return false;
    }

    return problem?.markup?.layout?.some(({ content }) => content.type === 'diskurl');
}

export function isRobot(problem: Problem | RobotProblem | null): problem is RobotProblem {
    return problem?.type === PROBLEM_INNER_TYPES.ROBOT;
}

export function isTheory(problem: Problem | StudentProblemResult | null): boolean {
    return problem?.type === PROBLEM_INNER_TYPES.THEORY;
}

export function getProblemMap(lesson: FullCLesson) {
    const result: Record<number, LessonProblem> = {};
    return (
        lesson.problems?.reduce((map, problemLink) => {
            if (problemLink.problem?.id) {
                map[problemLink.problem?.id] = problemLink;
            }

            return map;
        }, result) || result
    );
}

export function isStudentProblemCorrect(problemLink: LessonProblem, result: Record<number, StudentProblemResult>) {
    if (isTheory(problemLink.problem)) {
        return false;
    }
    return result?.[problemLink.id].status === PROBLEM_STATUS_DISPLAY_NAMES.CORRECT;
}

export function isStudentProblemIncorrect(problemLink: LessonProblem, result: Record<number, StudentProblemResult>) {
    if (isTheory(problemLink.problem)) {
        return false;
    }
    return result?.[problemLink.id].status === PROBLEM_STATUS_DISPLAY_NAMES.INCORRECT;
}

export function isStudentProblemWithAttempts(problemLink: LessonProblem, result: Record<number, StudentProblemResult>) {
    return result?.[problemLink.id].attempt_number > 0;
}

export function isStudentProblemSeen(problemLink: LessonProblem, result: Record<number, StudentProblemResult>) {
    return result?.[problemLink.id].time > 0 || isStudentProblemWithAttempts(problemLink, result);
}
