import { AnyEventObject } from 'xstate';

import { SECONDARY_SCHOOL_FIRST_CLASS } from 'common.components/user/user.constants';

import { MachineContext, TEAM_BUILDER_ENTRY_STEPS, TEAM_BUILDER_STEPS } from './typings';
import { shouldDisplayLogins as shouldDisplayLoginsUtil } from './utils';

const isTeamEntryPoint = (context: MachineContext) => {
    return context.entryPoint === TEAM_BUILDER_ENTRY_STEPS.TEAM;
};

const isStudentsEntryPoint = (context: MachineContext) => {
    return context.entryPoint === TEAM_BUILDER_ENTRY_STEPS.STUDENTS && context.hasSchool;
};

const isSchoolEntryPoint = (context: MachineContext) => {
    return context.entryPoint === TEAM_BUILDER_ENTRY_STEPS.SCHOOL;
};

const isSchoolForMesEntryPoint = (context: MachineContext) => {
    return context.entryPoint === TEAM_BUILDER_ENTRY_STEPS.SCHOOL_FOR_MES;
};

const isFallbackStudentsEntryPoint = (context: MachineContext) => {
    return context.entryPoint === TEAM_BUILDER_ENTRY_STEPS.FALLBACK_STUDENTS;
};

const isStudentLinkEntryPoint = (context: MachineContext) => {
    return context.entryPoint === TEAM_BUILDER_ENTRY_STEPS.STUDENT_LINK;
};

const isInOldAddStudentFlow = (context: MachineContext) => {
    return !context.inStudentLinkExperiment;
};

const isInStudentLinkExperiment = (context: MachineContext) => {
    return Boolean(context.inStudentLinkExperiment);
};

const isCodeInSteps = (context: MachineContext) => {
    return context.passedSteps.includes(TEAM_BUILDER_STEPS.CODE);
};
const isSubjectsEntryPoint = (context: MachineContext) => {
    return context.entryPoint === TEAM_BUILDER_ENTRY_STEPS.SUBJECTS && context.hasSchool && context.hasTeam;
};
const isPrimary = (context: MachineContext) => Boolean(context.grade && context.grade < SECONDARY_SCHOOL_FIRST_CLASS);
const isSecondary = (context: MachineContext) =>
    Boolean(context.grade && context.grade >= SECONDARY_SCHOOL_FIRST_CLASS);
const hasGrade = (context: MachineContext) => Boolean(context.grade);
const hasSubjects = (context: MachineContext) => context.hasSubjects;
const hasTeam = (context: MachineContext) => context.hasTeam;
const hasLetter = (context: MachineContext) => context.hasLetter;
const hasSchool = (context: MachineContext) => context.hasSchool;
const hasCode = (context: MachineContext) => context.hasCode;

const shouldLoadTemplates = (context: MachineContext) => !context.hasSubjects && !context.hasTemplates;
const shouldDisplayLogins = (context: MachineContext) => shouldDisplayLoginsUtil(context);
// начинаем добавляеть школу, если
// нет школы и есть класс
// нет школы и точка входа - добавление класса
const shouldEnterSchool = (context: MachineContext) => {
    return !context.hasSchool && (context.entryPoint === TEAM_BUILDER_ENTRY_STEPS.TEAM || context.hasTeam);
};

const isPrevStepSchool = (context: MachineContext) =>
    context.passedSteps[context.passedSteps.length - 1] === TEAM_BUILDER_STEPS.SCHOOL;
const isPrevStepTeam = (context: MachineContext) =>
    context.passedSteps[context.passedSteps.length - 1] === TEAM_BUILDER_STEPS.TEAM;
const isPrevStepLetter = (context: MachineContext) =>
    context.passedSteps[context.passedSteps.length - 1] === TEAM_BUILDER_STEPS.LETTER;
const isPrevStepCode = (context: MachineContext) =>
    context.passedSteps[context.passedSteps.length - 1] === TEAM_BUILDER_STEPS.CODE;
const isPrevStepStudents = (context: MachineContext) =>
    context.passedSteps[context.passedSteps.length - 1] === TEAM_BUILDER_STEPS.STUDENTS;
const isPrevStepSubjects = (context: MachineContext) =>
    context.passedSteps[context.passedSteps.length - 1] === TEAM_BUILDER_STEPS.SUBJECTS;
const isPrevStepAddingSubjects = (context: MachineContext) =>
    context.passedSteps[context.passedSteps.length - 1] === TEAM_BUILDER_STEPS.ADDING_SUBJECTS;
const isPrevStepLogins = (context: MachineContext) =>
    context.passedSteps[context.passedSteps.length - 1] === TEAM_BUILDER_STEPS.LOGINS;
const isPrevStepEmpty = (context: MachineContext) => context.passedSteps.length === 0;

const machineGuards: Record<string, (context: MachineContext, event: AnyEventObject) => boolean> = {
    isTeamEntryPoint,
    isStudentsEntryPoint,
    isSubjectsEntryPoint,
    isSchoolEntryPoint,
    isSchoolForMesEntryPoint,
    isFallbackStudentsEntryPoint,
    isStudentLinkEntryPoint,

    isInOldAddStudentFlow,
    isInStudentLinkExperiment,

    hasSubjects,
    hasLetter,
    hasTeam,
    hasSchool,
    hasCode,
    hasGrade,
    shouldLoadTemplates,
    shouldDisplayLogins,
    shouldEnterSchool,

    isPrimary,
    isSecondary,

    isPrevStepSchool,
    isPrevStepTeam,
    isPrevStepLetter,
    isPrevStepCode,
    isPrevStepStudents,
    isPrevStepSubjects,
    isPrevStepAddingSubjects,
    isPrevStepLogins,
    isPrevStepEmpty,

    isCodeInSteps,
};
const everyGuard = (
    context: MachineContext,
    event: AnyEventObject,
    { cond }: { cond: Record<string, any> | string }
) => {
    // не получается нормально расширить тайпинг на guard
    //@ts-ignore
    const guards: Array<string | { guardName: string; negative: true }> = cond.guards;
    return guards.every((guard: string | Record<string, any>) => {
        if (typeof guard === 'string') {
            return machineGuards[guard](context, event);
        }
        if (guard.guardName) {
            const res = machineGuards[guard.guardName](context, event);
            return guard.negative ? !res : res;
        }
        throw Error('Invalid guard format');
    });
};

export const allGuards = { ...machineGuards, everyGuard };
