import { SimpleCallback } from 'platform-components';
import React from 'react';
import ym from 'react-yandex-metrika';

import { LoginsFormContainer } from 'common.components/logins-form/logins-form.container';

interface Props {
    addedStudentsIds: Array<number> | null;
    createdTeamId: number | null;
    onNext: SimpleCallback;
}

export const TeamBuilderLogins = ({ createdTeamId, addedStudentsIds, onNext }: Props) => {
    ym('reachGoal', 'teacher_olymp_students_logins_modal', { action: 'close' });

    return <LoginsFormContainer addedStudentsIds={addedStudentsIds} createdClassId={createdTeamId} onPrint={onNext} />;
};
