import { SCREEN_RESOLUTIONS } from '../components/image/constants';
import { getSrcForResolution, getSrcSet } from '../components/image/utils';
import { MarkdownImageResource } from '../typings';

export const ILLUSTRATION_IMAGE_RESOURCE_SCALE = 1 / 4;
const NON_SCALE_RESOURCE_VERSION = 0;

export function loadImage(src: string, resolution?: SCREEN_RESOLUTIONS): Promise<HTMLImageElement> {
    const img = new Image();

    if (resolution) {
        img.src = getSrcForResolution(src, resolution);
    } else {
        const srcset = getSrcSet(src);

        if (srcset) {
            img.srcset = srcset;
        }

        img.src = src;
    }

    img.crossOrigin = 'anonymous';

    return new Promise((resolve, reject) => {
        img.onload = () => resolve(img);
        // @ts-ignore
        img.onerror = (error) => reject(error);
    });
}

export function getResourceImageWidth(resourceImage: MarkdownImageResource): number {
    if (!resourceImage) {
        return NaN;
    }

    if (resourceImage.version === NON_SCALE_RESOURCE_VERSION) {
        return resourceImage.image_width;
    }

    return resourceImage.image_width * ILLUSTRATION_IMAGE_RESOURCE_SCALE;
}

export function getResourceImageDisableSrcSet(resourceImage?: Partial<MarkdownImageResource>): boolean {
    return resourceImage?.version === NON_SCALE_RESOURCE_VERSION;
}
