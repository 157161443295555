import { ContainerElement } from '@yandex-lego/components/Button';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { Modal } from '@edu-frontend/modules/src/features/modal';
import { GestureContainer } from '@edu-frontend/modules/src/shared/ui/GestureContainer';

import { MarkdownImageResource } from '../../../typings';
import { MEDIA_QUERY_DESKTOP_XS } from '../../../utils/von-dyck-media-queries';

import ResourceImage, { ImageProps } from './resource__image';

import './resource__zoomable-image.scss';

type Props = ImageProps &
    MarkdownImageResource & {
        src: string;
        showImagePreview?: boolean;
    };

const ZoomableImage = (props: Props) => {
    const { attrs, className, src, showImagePreview, ...restProps } = props;
    const [isActive, setActive] = useState<boolean>(false);
    const [imageScalingEnabled, setImageScalingEnabled] = useState<boolean>(false);
    const isMobile = useMedia(MEDIA_QUERY_DESKTOP_XS);

    useEffect(() => {
        if (showImagePreview) {
            setImageScalingEnabled(true);
        }
    }, [showImagePreview]);

    const onClick = (event?: React.MouseEvent<ContainerElement | HTMLDivElement, MouseEvent>) => {
        const parentTagName = (event?.target as HTMLElement)?.parentElement?.tagName;

        // скейл не нужен в картинках, которые используются в кнопках или ссылках
        if (!imageScalingEnabled || parentTagName === 'A' || parentTagName === 'BUTTON') {
            return;
        }

        if (event && event.preventDefault) {
            event.preventDefault();
        }

        setActive(!isActive);
    };
    //
    return (
        <>
            <ResourceImage
                attrs={{ src, ...attrs }}
                className={cx({ 'hoverable-image': imageScalingEnabled && !isActive })}
                mix={className}
                onClick={onClick}
                {...restProps}
            />
            {imageScalingEnabled && isActive && (
                <Modal disableDisplayTable onClose={onClick} visible={isActive}>
                    <GestureContainer
                        dragWithinBounds
                        initialScale={isMobile ? 1 : 0.6}
                        initialScaleDimension="byWidth"
                        scaleBounds={{ min: 0.6, max: 5 }}
                    >
                        <div style={{ width: props?.image_width, height: props?.image_height }}>
                            <ResourceImage
                                attrs={{ src, ...attrs }}
                                className="no-user-events"
                                {...restProps}
                                version={0}
                            />
                        </div>
                    </GestureContainer>
                </Modal>
            )}
        </>
    );
};

export default ZoomableImage;
