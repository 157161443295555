import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AwardItem, CampaignAwards } from '@yandex-int/k-common';
import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';

import { AwardsStoreState } from './typings';

const initialState: AwardsStoreState = {
    awards: [],
    awardsLoadingStatus: LOADING_STATUSES.UNSENT,
    studentsAwards: {},
    studentsAwardsLoadingStatus: LOADING_STATUSES.UNSENT,
};

/* eslint-disable @typescript-eslint/no-unused-vars */
const slice = createSlice({
    name: 'AWARDS',
    initialState: initialState,
    reducers: {
        clearAwards: () => {
            return initialState;
        },
        getChildAvailableAwards: (
            state: AwardsStoreState,
            action: PayloadAction<{
                childId: number;
                clessonId: number;
                onSuccess?: (campaignAwards: CampaignAwards[]) => void;
            }>
        ) => {
            // saga
            state.awardsLoadingStatus = LOADING_STATUSES.LOADING;
        },
        getMyAvailableAwards: (state: AwardsStoreState, action: PayloadAction<{ clessonId: number }>) => {
            // saga
            state.awardsLoadingStatus = LOADING_STATUSES.LOADING;
        },
        setAvailableAwards: (state: AwardsStoreState, action: PayloadAction<{ awards: CampaignAwards[] }>) => {
            const { awards } = action.payload;

            state.awards = awards;
            state.awardsLoadingStatus = LOADING_STATUSES.SUCCESS;
        },
        getStudentsAvailableAwards: (
            state: AwardsStoreState,
            action: PayloadAction<{
                campaignSlugs: string[];
                courseId: number;
                onSuccess?: (campaignAwards: CampaignAwards[]) => void;
            }>
        ) => {
            // saga
            state.studentsAwardsLoadingStatus = LOADING_STATUSES.LOADING;
        },
        setStudentsAvailableAwards: (
            state: AwardsStoreState,
            action: PayloadAction<{ courseId: number; awards: CampaignAwards[] }>
        ) => {
            const { courseId, awards } = action.payload;

            state.studentsAwards[courseId] = awards;
            state.studentsAwardsLoadingStatus = LOADING_STATUSES.SUCCESS;
        },
        takeAward: (
            state: AwardsStoreState,
            action: PayloadAction<{ id: number; onSuccess: (award: AwardItem) => void; onError?: () => void }>
        ) => {
            // saga
        },
    },
});
/* eslint-enable @typescript-eslint/no-unused-vars */

export const actions = slice.actions;
export const awardsReducer = slice.reducer;
