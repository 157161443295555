//@ts-ignore - EDUCATION-37077: [react-update]: fixme typings: ???
import React, { ChangeEvent, ReactElement, ReactType } from 'react';

import { UaTraits } from '../../typings';
import withUatraits from '../../utils/hocs/with-uatraits';
import { POPUP_INDENTS } from '../constants';
// TODO: EDUCATION-11293 ts fix
// @ts-ignore
import SelectSuggest from '../select-suggest/select-suggest';
import Select from '../select/select';

import './select-native.scss';
import { SELECT_TYPE } from './types';

interface Option {
    [key: string]: string;
}

interface Props {
    disabled?: boolean;
    indent: POPUP_INDENTS;
    mix: string | string[];
    name: string;
    optionRenderer: React.ReactNode;
    placeholder: string;
    shown: boolean;
    uatraits: UaTraits;
    onChange: (e: object) => void;
    labelKey: keyof Option;
    valueKey: keyof Option;
    options: Option[];
    value: string | number;
    children?: ReactElement[];
    selectType?: SELECT_TYPE;
    withEmptyOption?: boolean;
}

interface RenderOptions {
    component: ReactType;
    props: object;
}

/*
 * Компонент обертка над SelectSuggest
 * На мобильниках поверх SelectSuggest рисуется прозрачный нативный селект
 * Это позволяет выбирать из нативного меню: которое удобнее для пользователя*/

export class SelectNative extends React.Component<Props> {
    static defaultProps = {
        withEmptyOption: true,
    };

    handleChange = (e: ChangeEvent<HTMLSelectElement>): void => {
        const { valueKey, onChange } = this.props;

        onChange({
            [valueKey]: e.target.value,
        });
    };

    getNativeOptions = (): React.ReactNode[] => {
        const { labelKey, valueKey, options, withEmptyOption } = this.props;

        const result = options.map((option, i) => (
            <option key={i} value={option[valueKey]}>
                {option[labelKey]}
            </option>
        ));

        if (withEmptyOption) {
            result.unshift(
                <option key="empty" value="">
                    &mdash;
                </option>
            );
        }

        return result;
    };

    getSelectRenderOptions = (): RenderOptions => {
        const { selectType } = this.props;

        switch (selectType) {
            case SELECT_TYPE.SELECT:
                return {
                    component: Select,
                    props: { onChange: this.handleChange },
                };

            case SELECT_TYPE.SELECT_SUGGEST:
            default:
                return {
                    component: SelectSuggest,
                    props: {},
                };
        }
    };

    render() {
        const { uatraits, value, children, disabled } = this.props;

        const { isMobile } = uatraits;
        const { component: Component, props } = this.getSelectRenderOptions();

        return (
            <div className="select-suggest-native">
                {isMobile && (
                    <select
                        className="select-suggest-native__select"
                        disabled={disabled}
                        onChange={this.handleChange}
                        value={value}
                    >
                        {this.getNativeOptions()}
                    </select>
                )}
                <Component {...this.props} {...props}>
                    {children}
                </Component>
            </div>
        );
    }
}

export default withUatraits(SelectNative);
