import * as React from 'react';
const SvgCopy = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 2.003a1 1 0 001 1h7.5a.5.5 0 01.5.5V11a1 1 0 002 0V3.503a2.5 2.5 0 00-2.5-2.5H5a1 1 0 00-1 1zm-1.5 2h8a1.5 1.5 0 011.5 1.5v8a1.5 1.5 0 01-1.5 1.5h-8a1.5 1.5 0 01-1.5-1.5v-8a1.5 1.5 0 011.5-1.5zm.5 2v7h7v-7H3z"
            fill="currentColor"
        />
    </svg>
);
export default SvgCopy;
