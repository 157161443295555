import * as React from 'react';
const SvgRefreshKids = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 16 18" fill="none" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 .817a.625.625 0 01.98-.513l3.694 2.557a.624.624 0 010 1.028L8.981 6.446A.625.625 0 018 5.933V4a5.833 5.833 0 105.833 5.833.833.833 0 111.667 0 7.5 7.5 0 11-7.5-7.5V.817z"
            fill="currentColor"
        />
    </svg>
);
export default SvgRefreshKids;
