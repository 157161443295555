import { Course } from '@yandex-int/k-common/typings';

import * as constants from './constants';

export const getTeacherCoursesByClassRequest = (classIds: Array<number>) =>
    <const>{
        type: constants.GET_TEACHER_COURSES_BY_CLASS_REQUEST,
        classIds,
    };

export const getTeacherCoursesByClassSuccess = (classIds: Array<number>, courses: Array<Course>) =>
    <const>{
        type: constants.GET_TEACHER_COURSES_BY_CLASS_SUCCESS,
        classIds,
        courses,
    };

export const getTeacherCoursesByClassError = (classIds: Array<number>) =>
    <const>{
        type: constants.GET_TEACHER_COURSES_BY_CLASS_ERROR,
        classIds,
    };

export const clearTeacherCoursesByClass = (classId: number) =>
    <const>{
        type: constants.CLEAR_TEACHER_COURSES_BY_CLASS,
        classId,
    };
