import b from 'b_';
import React from 'react';
import SimpleMarkdown, { parseInline } from 'simple-markdown';

import { addThousandsSeparators } from '../../utils/format-number/format-number';
import { CodeRunner } from '../code-runner/code-runner';
import { DialogMessage } from '../dialog-message/dialog-message';
import { linkRule } from '../EduMarkdown/EduMarkdown.hocs/withRuleLink/withRuleLink';
import { FractionFormula as Formula } from '../fraction-formula/fraction-formula';
import GeoMap from '../geo-map/geo-map';
import Image from '../image/image';
import InlineInput from '../inline-input/inline-input';
import { JsonResourseParser } from '../json-resourse-parser/json-resourse-parser';
import { MdContentBlock } from '../md-conent-block/md-content-block';
import Resource from '../resource/resource';
import { ScratchEmbed } from '../scratch-embed/scratch-embed';
import Spoiler from '../spoiler/spoiler';
import YaForm from '../ya-form/ya-form';
import YandexMusic from '../yandex-music/yandex-music';
import { MUSIC_SOURCE_TYPES_ARRAY } from '../yandex-music/yandex-music.constants';
import YandexPlayer from '../yandex-player/yandex-player';
import YandexVhPlayer from '../yandex-vh-player/yandex-vh-player';
import YouTube from '../youtube/youtube';

import { LINK_INSIDE, LINK_HREF_AND_TITLE, ATTRIBUTES_REGEX, ALIGNMENT } from './constants';
import * as extendedTableSyntax from './extended-table';
import { getYoutubeId, resolveRules, prepareRules, stringToObject } from './utils';

/* eslint-disable react/no-multi-comp */
const rules = {
    image: {
        type: 'image',
        rule: {
            order: 0,
            match: SimpleMarkdown.inlineRegex(
                new RegExp(`^!\\[(${LINK_INSIDE})\\]\\(${LINK_HREF_AND_TITLE}\\)(${ATTRIBUTES_REGEX})?`)
            ),
            parse(capture) {
                return {
                    alt: capture[1],
                    src: capture[2],
                    title: capture[3],
                    attributes: (capture[4] && stringToObject(capture[4])) || {},
                };
            },
            react(node, output, state) {
                return <Image alt={node.alt} key={state.key} src={node.src} title={node.title} {...node.attributes} />;
            },
        },
    },

    imageFloat: {
        type: 'imageFloat',
        rule: {
            order: 0,
            match: SimpleMarkdown.inlineRegex(
                new RegExp('^!([<>])\\[(' + LINK_INSIDE + ')\\]\\(' + LINK_HREF_AND_TITLE + '\\)')
            ),
            parse(capture) {
                return {
                    float: capture[1] === '<' ? 'left' : 'right',
                    alt: capture[2],
                    src: capture[3],
                    title: capture[4],
                };
            },
            react(node, output, state) {
                return (
                    <Image
                        alt={node.alt}
                        key={state.key}
                        src={node.src}
                        style={{ float: node.float }}
                        title={node.title}
                    />
                );
            },
        },
    },

    input: {
        type: 'input',
        rule: {
            order: 0,
            match: SimpleMarkdown.inlineRegex(/^\{input:(\d+)}/),
            parse(capture) {
                return { id: capture[1] };
            },
            react(node, output, state) {
                const inputData = state.options.inputs[node.id] || {};
                const inputId = Number(node.id);
                const key = `input_${node.id}_${state.id}_${state.key}`;
                const InputWrapper = state.InputWrapper;
                const answerStatus = state.answer_status || {};
                const Input = React.createElement(InlineInput, {
                    ...inputData,
                    answer: state.answer && state.answer[node.id],
                    groupCorrect: answerStatus[inputData.group],
                    id: inputId,
                    key,
                    mdState: state,
                    markerTheme: state.theme,
                    noAttemptsLeft: state.noAttemptsLeft,
                    onInputPressEnter: state.onInputPressEnter,
                    readOnly: state.readOnly,
                    setInputAnswer: state.setInputAnswer,
                    showCorrectAnswer: state.showCorrectAnswer,
                    statusMode: state.statusMode,
                    showCurrentAnswersStatus: state.showCurrentAnswersStatus,
                    userAnswer: state.userAnswer[node.id],
                    valid: state.validationResult[node.id],
                    isShuffledAnswers: state.isShuffledAnswers,
                });

                return InputWrapper ? (
                    <InputWrapper
                        group={inputData.group}
                        id={Number(node.id)}
                        key={key}
                        markerId={Number(state.id)}
                        onClick={state.onInputClick}
                    >
                        {Input}
                    </InputWrapper>
                ) : (
                    Input
                );
            },
        },
    },

    geoMap: {
        type: 'geoMap',
        rule: {
            order: 0,
            match: SimpleMarkdown.inlineRegex(/^\{geoMap:(\d+)(:(\d+))?}/),
            parse(capture) {
                return { resource: capture[1], outlines: capture[3] };
            },
            react(node, _, state) {
                const geoMap = (
                    <JsonResourseParser resource={state.resources[node.resource]} resourceName={'jsonData'}>
                        <GeoMap />
                    </JsonResourseParser>
                );

                return node.outlines ? (
                    <JsonResourseParser resource={state.resources[node.outlines]} resourceName={'outlinesData'}>
                        {geoMap}
                    </JsonResourseParser>
                ) : (
                    geoMap
                );
            },
        },
    },

    brSign: {
        type: 'brSign', // правило с ключом "br" - уже существует в стандартном наборе
        rule: {
            order: 0,
            match: SimpleMarkdown.inlineRegex(new RegExp('^{br}')),
            parse() {
                return {};
            },
            react(node, output, state) {
                return <br key={state.key} />;
            },
        },
    },

    youtube: {
        type: 'youtube',
        rule: {
            order: 0,
            match: SimpleMarkdown.inlineRegex(new RegExp('^{youtube\\:\\s*(\\S*?)}')),
            parse(capture) {
                return {
                    videoId: getYoutubeId(capture[1]),
                };
            },
            react(node, output, state) {
                return <YouTube key={state.key} videoId={node.videoId} />;
            },
        },
    },

    yandexVh: {
        type: 'yandexVh',
        rule: {
            order: 0,
            match: SimpleMarkdown.inlineRegex(new RegExp('^{yandexVh\\:\\s*(\\S*?)}')),
            parse(capture) {
                return {
                    videoId: capture[1],
                };
            },
            react(node, output, state) {
                return <YandexVhPlayer key={state.key} videoId={node.videoId} />;
            },
        },
    },

    link: {
        type: 'link',
        rule: linkRule,
    },

    sup: {
        type: 'sup',
        rule: {
            order: 0,
            match: SimpleMarkdown.inlineRegex(/^{sup:(.*?)}/),
            parse(capture, parse, state) {
                return {
                    content: parse(capture[1], state),
                };
            },
            react(node, output, state) {
                return <sup key={state.key}>{output(node.content, state)}</sup>;
            },
        },
    },

    sub: {
        type: 'sub',
        rule: {
            order: 0,
            match: SimpleMarkdown.inlineRegex(/^{sub:(.*?)}/),
            parse(capture, parse, state) {
                return {
                    content: parse(capture[1], state),
                };
            },
            react(node, output, state) {
                return <sub key={state.key}>{output(node.content, state)}</sub>;
            },
        },
    },

    tab: {
        type: 'tab',
        rule: {
            order: 0,
            match: SimpleMarkdown.inlineRegex(/^\\tab/),
            parse() {
                return {};
            },
            react(node, output, state) {
                return <span className="markdown_tabulation" key={`tab_key_${state.key}`} />;
            },
        },
    },

    author: {
        type: 'author',
        rule: {
            order: 0,
            match: SimpleMarkdown.inlineRegex(/^\{author:(.*?)}/),
            parse(capture) {
                return {
                    author: capture[1],
                };
            },
            react(node, output, state) {
                return (
                    <div className="signature" key={state.key}>
                        {node.author}
                    </div>
                );
            },
        },
    },

    small: {
        type: 'small',
        rule: {
            order: 0,
            match: SimpleMarkdown.inlineRegex(/^{small:(.*?)}/),
            parse(capture, parse, state) {
                return {
                    content: parse(capture[1], state),
                };
            },
            react(node, output, state) {
                return (
                    <span className="text_size_small" key={state.key}>
                        {output(node.content, state)}
                    </span>
                );
            },
        },
    },

    sizedText: {
        type: 'sizedText',
        rule: {
            order: 0,
            match: SimpleMarkdown.inlineRegex(/^{sizedText:([\w-]*?)}(.*?){\\sizedText}/),
            parse(capture, parse, state) {
                return {
                    size: capture[1],
                    content: parse(capture[2], state),
                };
            },
            react(node, output, state) {
                return (
                    <span className={`text_size_${node.size}`} key={state.key}>
                        {output(node.content, state)}
                    </span>
                );
            },
        },
    },

    medium: {
        type: 'medium',
        rule: {
            order: 0,
            match: SimpleMarkdown.inlineRegex(/^{medium}(.*?){\\medium}/),
            parse(capture, parse, state) {
                return {
                    content: parse(capture[1], state),
                };
            },
            react(node, output, state) {
                return (
                    <span className="text_weight_medium" key={state.key}>
                        {output(node.content, state)}
                    </span>
                );
            },
        },
    },

    coloredText: {
        type: 'coloredText',
        rule: {
            order: 0,
            match: SimpleMarkdown.inlineRegex(/^{coloredText:([\w-]*?)}(.*?){\\coloredText}/),
            parse(capture, parse, state) {
                return {
                    color: capture[1],
                    content: parse(capture[2], state),
                };
            },
            react(node, output, state) {
                return (
                    <span className={`colored-text_${node.color}`} key={state.key}>
                        {output(node.content, state)}
                    </span>
                );
            },
        },
    },

    customText: {
        type: 'customText',
        rule: {
            order: 0,
            match: SimpleMarkdown.inlineRegex(/^{customText:c-([\w-]*?)&s-([\w-]*?)}(.*?){\\customText}/),
            parse(capture, parse, state) {
                return {
                    color: capture[1],
                    size: capture[2],
                    content: parse(capture[3], state),
                };
            },
            react(node, output, state) {
                return (
                    <span className={b('custom-text', { color: node.color, size: node.size })} key={state.key}>
                        {output(node.content, state)}
                    </span>
                );
            },
        },
    },

    yaForm: {
        type: 'yaForm',
        rule: {
            order: 0,
            match: SimpleMarkdown.inlineRegex(/^{yaform(:w-([\d]+)&h-([\d]+))?:name-([\w]+):(.*?)}/),
            parse(capture) {
                return {
                    width: Number(capture[2]),
                    height: Number(capture[3]),
                    name: capture[4],
                    content: 'https://' + capture[5],
                };
            },
            react(node, output, state) {
                return (
                    <YaForm
                        height={node.height}
                        key={state.key}
                        name={node.name}
                        url={node.content}
                        width={node.width}
                    />
                );
            },
        },
    },

    horizontalAlign: {
        type: 'horizontalAlign',
        rule: {
            order: 0,
            // регулярка, ловящая спецификатор выравнивания, окружающий блок в терминах маркдауна:
            // (любой из спецификаторов выравнивания)
            // (не ноль не переводов строки, завершающихся переводом строки)
            // (ноль или больше переводов строк)
            // (дальше должен быть повтор группы 1)
            // (повтор группы 1)
            match: SimpleMarkdown.blockRegex(/^(<--|<->|>-<|-->)((?:[^\n]*?\n?)+?(?:\n*))(?=\1)\1/),

            parse(capture, parse, state) {
                return {
                    alignment: ALIGNMENT[capture[1]],
                    content: parse(capture[2].trim() + '\n\n', parse, state),
                };
            },

            react(node, output, state) {
                return (
                    <div className={b('markdown', 'aligned-block', { alignment: node.alignment })} key={state.key}>
                        {output(node.content, state)}
                    </div>
                );
            },
        },
    },

    verticalAlign: {
        type: 'verticalAlign',
        rule: {
            order: 0,
            match: SimpleMarkdown.inlineRegex(/^\{valign:([\w-]*?):(.*?)}/),

            parse(capture, parse, state) {
                return {
                    verticalAlign: capture[1],
                    content: parse(capture[2], state),
                };
            },

            react(node, output, state) {
                const { content, ...style } = node;

                return (
                    <span key={state.key} style={style}>
                        {output(content, state)}
                    </span>
                );
            },
        },
    },

    spoiler: {
        type: 'spoiler',
        rule: {
            order: 0,
            match: SimpleMarkdown.blockRegex(/^\!\>(?:[^\n]*?\n)+?(?:\n+)*(?=\!\<\n)\!\<\n/),
            parse(capture, parse, state) {
                const content = capture[0]
                    .replace(/^\!\>/, '')
                    .replace(/\!\<\n$/, '')
                    .split('\n');
                const innerContent = content.slice(1).join('\n') + '\n\n';
                const title = content[0].replace(/^\!/, '');

                const titleSplit = title.split('|');
                const titleOpen =
                    titleSplit.length >= 2
                        ? parseInline(parse, titleSplit[0], state)
                        : parseInline(parse, title, state);
                const titleClose =
                    titleSplit.length >= 2
                        ? parseInline(parse, titleSplit[1], state)
                        : parseInline(parse, title, state);
                const innerData = parse(innerContent, parse, state);

                return {
                    caption: titleOpen,
                    closeCaption: titleClose,
                    innerItems: innerData,
                    spoiled: content[0].indexOf('!') === 0,
                };
            },
            react(node, output, state) {
                return (
                    <Spoiler
                        caption={output(node.caption, state)}
                        closeCaption={output(node.closeCaption)}
                        initialSpoiled={node.spoiled}
                        key={state.key}
                    >
                        <div className="markdown">{output(node.innerItems, state)}</div>
                    </Spoiler>
                );
            },
        },
    },

    formattedNumber: {
        type: 'formattedNumber',
        rule: {
            order: 0,
            match: SimpleMarkdown.inlineRegex(/^{(\d*)}/),
            parse(capture) {
                return {
                    formattedNumber: addThousandsSeparators(capture[1]),
                };
            },
            react(node) {
                return node.formattedNumber;
            },
        },
    },

    ...extendedTableSyntax,

    /**
     * {yamusic:track=21728923/2493089:theme=white:showTitle:...}
     */
    yandexMusic: {
        type: 'yandexMusic',
        rule: {
            order: 0,
            match: SimpleMarkdown.inlineRegex(/^{yamusic\:(.*)}/),
            parse(capture) {
                const params = capture[1].split(':');
                const props = params.reduce((acc, param) => {
                    const [key, value] = param.split('=');
                    const isSourceTypeParam = MUSIC_SOURCE_TYPES_ARRAY.includes(key);

                    // параметр `track=21728923/2493089` (или `playlist=user/13245`) на самом деле несет сразу два
                    // пропа: `sourceType` и `sourceId`. Расщепляем на два пропа, когда встречаем
                    if (isSourceTypeParam) {
                        acc.sourceType = key;
                        acc.sourceId = value;
                    } else {
                        let processedValue = value;
                        const paramKeyWithoutValue = Boolean(key) && (value === undefined || value === null);

                        if (paramKeyWithoutValue) {
                            processedValue = true;
                        }

                        acc[key] = processedValue;
                    }

                    return acc;
                }, {});

                return props;
            },
            react(yaProps, output, state) {
                return <YandexMusic {...yaProps} key={state.key} />;
            },
        },
    },

    /**
     * Парсим три возомжных варианта синтаксиса (syntaxVar):
     *     1. {yplayer|URL}
     *     2. {yplayer|URL|propA=X;propB=Y;propC=Z;...}
     *     3. {yplayer|URL_PROP=URL;propA=X;propB=Y;...}
     */
    yandexPlayer: {
        type: 'yandexPlayer',
        rule: {
            order: 0,
            match: SimpleMarkdown.inlineRegex(/^{yaplayer\|(.*)}/),
            parse(capture) {
                // Определяем какой вариант синтаксиса использовался и подготовливаем параметры
                const paramsWithUrl = capture[1].split('|');
                const syntaxVar2 = paramsWithUrl.length === 2;
                let params;
                let url;

                if (syntaxVar2) {
                    url = paramsWithUrl[0];
                    params = paramsWithUrl[1].split(';');
                } else {
                    const paramsOrUrl = paramsWithUrl[0].split(';');
                    const syntaxVar1 = paramsOrUrl.length === 1;

                    if (syntaxVar1) {
                        url = paramsOrUrl[0];
                        params = [];
                    } /* syntaxVar3 */ else {
                        params = paramsOrUrl;
                    }
                }

                // Приводим к API компонетна YandexPlayer
                const yaProps = params.reduce((acc, param) => {
                    const [paramKey, paramValue] = param.split('=');
                    acc[paramKey] = paramValue;
                    return acc;
                }, {});

                yaProps.hd_url = yaProps.hd_url || url; // eslint-disable-line camelcase

                return yaProps;
            },
            react(yaProps, output, state) {
                return <YandexPlayer {...yaProps} key={state.key} />;
            },
        },
    },

    /**
     * Специальный элемент разметки для ликвидации отсупами между двумя inline-input
     *
     * {input:1}{&}{input:2} - между input:1 и input:2 не будет отступа
     */
    marginRemover: {
        type: 'marginRemover',
        rule: {
            order: 0,
            match: SimpleMarkdown.inlineRegex(/^{\&}/),
            parse() {
                return {};
            },
            react(node, output, state) {
                return <span className="inline-input-margin-remover" key={state.key} />;
            },
        },
    },

    /**
     * {latex: \displaystyle {{6400}\over{4900}} * 8x-7y+20 = {{64}\over{49}}}
     */
    latex: {
        type: 'latex',
        rule: {
            order: 0,
            match: SimpleMarkdown.blockRegex(new RegExp(/^{latex:((.|\n)+)}/)),
            parse(capture) {
                const content = capture[1];
                return { content };
            },
            react(node, output, state) {
                return <Formula code={node.content} isMultiline key={state.key} />;
            },
        },
    },

    /**
     * {message:Катина мама:eucalyptus}Hello markdown{/message}
     */
    dialogMessage: {
        type: 'dialogMessage',
        rule: {
            order: 0,
            match: SimpleMarkdown.inlineRegex(/^{message:(.*?):(.*?)}(.*?){\/message}/),
            parse(capture, parse, state) {
                return {
                    name: capture[1],
                    color: capture[2],
                    content: parse(capture[3], state),
                };
            },
            react(node, output, state) {
                return (
                    <DialogMessage color={node.color} name={node.name}>
                        {output(node.content, state)}
                    </DialogMessage>
                );
            },
        },
    },

    /**
     * {ContentVisibility:showOnlyOnMobile}some content{/ContentVisibility}
     */
    contentVisibility: {
        type: 'contentVisibility',
        rule: {
            order: 0,
            match: SimpleMarkdown.inlineRegex(new RegExp(/^{ContentVisibility:(.*?)}([\s\S]*?){\/ContentVisibility}/)),
            parse(capture, parse, state) {
                return {
                    rule: capture[1],
                    content: parse(capture[2], state),
                };
            },
            react(node, output, state) {
                return (
                    <MdContentBlock
                        action={node.action}
                        renderContent={() => output(node.content, state)}
                        rule={node.rule}
                    />
                );
            },
        },
    },

    /**
     * @example
     * ```run_python
     * print(1 + int(input()))
     * ---
     * 1
     * ```
     */
    codeRunner: {
        type: 'codeRunner',
        rule: {
            order: 0,
            // regex reference https://github.com/Khan/simple-markdown/blob/de4c4f6a5346814ed005be0ad61a1f1fe49ae194/src/index.js#L1041
            match: SimpleMarkdown.blockRegex(/^ *(`{3,}|~{3,}) *run_(\S+) *\n([\s\S]+?)\n?\1 *(?:\n *)+\n/),
            parse(capture) {
                return {
                    lang: capture[2] || undefined,
                    content: capture[3],
                };
            },
            react(node) {
                const [code, input] = node.content.split('\n---\n');
                return <CodeRunner defaultCode={code} defaultInput={input} />;
            },
        },
    },

    /**
     * @@example
     * {scratch:https://scratch.mit.edu/projects/771548678}
     */
    scratchEmbed: {
        type: 'scratchEmbed',
        rule: {
            order: 0,
            match: SimpleMarkdown.blockRegex(/^\s*{\s*scratch\s*:\s*(.*)\s*}/),
            parse: (capture) => ({ project: capture[1] }),
            react: (node) => <ScratchEmbed project={node.project} />,
        },
    },
};
/* eslint-enable react/no-multi-comp */

const unresolvedRules = [
    {
        component: Formula,
        type: 'formula',
        regex: /^\{formula:(\d+)}/,
        source: 'formulas',
        commonPropsName: 'formulasCommonProps',
    },
    {
        component: Resource,
        type: 'resource',
        regex: /^{resource:(\d+)}({((?:\w*(="[^"]*")?\s?)*)})?/,
        source: 'resources',
        commonPropsName: 'resourcesCommonProps',
    },
];

export default Object.assign({}, prepareRules(rules), resolveRules(unresolvedRules));
