import { useRef } from 'react';

/**
 * Возвращает setTimeout, отменяющий запланированные колбэки при вызове нового
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function useLatestTimeout<T extends Function>() {
    const timeoutRef = useRef<null | number>(null);

    return function takeLatestTimeout(cb: T, delay: number) {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(cb, delay);
    };
}
