/* eslint-disable camelcase */
import { Me } from '@yandex-int/k-common';
import { useSelector } from 'react-redux';

export function useMe(): Me | undefined;
export function useMe<K extends keyof Me>(key: K): Me[K] | undefined;

/**
 * Use me or specific key from me
 */
export function useMe<K extends keyof Me>(key?: K) {
    const me: Me | undefined = useSelector((state: any) => state.user?.me);

    if (me && key) {
        return me[key];
    }

    return me;
}

export function useMyName() {
    const me = useMe();

    return me?.yauser?.display_name || me?.public_name;
}

export function useIsMeSet(): boolean {
    return useSelector((state: any) => !!state.user?.isMeSet);
}
