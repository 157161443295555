import { useCallback, useEffect, useRef } from 'react';

/**
 * Оборачивает функцию в стабильный референс
 * Осторожно с undefined - при нём это не stable!
 */
export function useStableCallback<T extends ((...args: any) => any) | undefined>(cb: T): T {
    const cbRef = useRef(cb);

    useEffect(() => void (cbRef.current = cb));

    const stableCallback = useCallback((...args: unknown[]) => cbRef.current?.(...args), []);

    return (cb ? stableCallback : undefined) as T;
}
