import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
// @ts-ignore
import { SUBJECTS_SLUGS } from '@yandex-int/k-common/constants';
import { SimpleCallback } from 'platform-components';

import { AddressWithId, SchoolData } from 'common.components/school-form/typings';
import { School } from 'common.components/typings';
import { YM_LOCATION } from 'utils/ym-constants';

export enum TEAM_BUILDER_ENTRY_STEPS {
    TEAM = 'team',
    STUDENTS = 'students',
    SUBJECTS = 'subjects',
    SCHOOL = 'school',
    SCHOOL_FOR_MES = 'school-for-mes',
    FALLBACK_STUDENTS = 'fallback-students',
    STUDENT_LINK = 'student-link',
}

export enum TEAM_BUILDER_STEPS {
    SCHOOL = 'school',
    TEAM = 'team',
    LETTER = 'letter',
    STUDENTS = 'students',
    TEMPLATES_LOADING = 'templates-loading',
    SUBJECTS = 'subjects',
    ADDING_SUBJECTS = 'adding-subjects',
    CODE = 'code',
    SUBMIT_CODE = 'submit-code',
    SUBMIT = 'submit',
    LOGINS = 'logins',
    ERROR = 'error',
    FALLBACK_POLL = 'fallback-poll',
    STUDENT_LINK = 'student-link',
    SCHOOL_FOR_MES = 'school-for-mes',
    MES_SUBMIT = 'school-for-mes-submit',
}

export interface MachineContext {
    entryPoint: TEAM_BUILDER_ENTRY_STEPS | null;
    hasSchool: boolean;
    hasTeam: boolean;
    hasLetter: boolean;
    grade: number | null;
    hasStudents: boolean;
    hasError: boolean;
    hasSubjects: boolean;
    passedSteps: Array<TEAM_BUILDER_STEPS>;
    hasTemplates: boolean;
    hasCode: boolean;
    inStudentLinkExperiment?: boolean;
    canEditGrade?: boolean;
}

export type AssignTeam = { type: 'ASSIGN_TEAM'; grade: number | null };
type Assign = { type: 'ASSIGN' };
export type Next = { type: 'NEXT' };
type Back = { type: 'BACK' };
export type Update = { type: 'UPDATE'; newContext: Partial<MachineContext> };
export type Reset = { type: 'RESET'; newContext: MachineContext };
type ToCode = { type: 'TO_CODE' };
export type MachineEvents = Assign | AssignTeam | Next | Back | Update | Reset | ToCode;
export type OnSuccess = (addedStudentsIds?: Array<number> | null, createdTeamId?: number) => void;
export type OnMesSuccess = (schoolData: SchoolData | null) => void;

export interface TeamBuilderModeContainerInterface {
    resetMachine: SimpleCallback;
}

export interface TeamBuilderTeamData {
    teamLetter: string;
    teamLevel: number;
}

interface SingleBuildParameters {
    entryPoint: TEAM_BUILDER_ENTRY_STEPS | null;
    onSuccess: OnSuccess | OnMesSuccess | null;
    location?: YM_LOCATION;
    newSubject: SUBJECTS_SLUGS | null;
}

interface SingleBuildData {
    school: School | null;
    address: AddressWithId | null;
    submittingStatus: LOADING_STATUSES;
    teamData: TeamBuilderTeamData | null;
    teamPatchData: TeamBuilderTeamData | null;
    students: Array<string> | null;
    selectedTemplatesIds: Array<number> | null;
    code: string | null;
    codeSubmittingStatus: LOADING_STATUSES;
}

export interface TeamBuilderStore extends SingleBuildData, SingleBuildParameters {
    started: boolean;
    canEditGrade: boolean;
    defaultGrade?: number;
}
