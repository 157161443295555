import { getFetch } from '../fetch';

import { apiConfig } from '../config';

export function getLessonAssigns({ clessonId, courseId }: any) {
    const { apiGetCLessonAssigns } = apiConfig.getStaticUrls();

    return getFetch(`${apiGetCLessonAssigns}`, {
        courseId,
        clessonId,
    });
}
