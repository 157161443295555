import * as constants from './constants';

export const verifyPasswordRequest = (password) => ({ type: constants.VERIFY_PASSWORD_REQUEST, password });

export const verifySchoolCodeSuccess = (schoolData, isSchool, isParent) => ({
    type: constants.VERIFY_SCHOOL_CODE_SUCCESS,
    schoolData,
    isSchool,
    isParent,
});

export const verifySchoolCodeError = (error) => ({ type: constants.VERIFY_SCHOOL_CODE_ERROR, error });

export const submitLoginRequest = (studentLogin) => ({ type: constants.SUBMIT_LOGIN_REQUEST, studentLogin });

export const submitLoginSuccess = () => ({ type: constants.SUBMIT_LOGIN_SUCCESS });

export const submitLoginError = (error) => ({ type: constants.SUBMIT_LOGIN_ERROR, error });

export const setPassword = (password) => ({ type: constants.SET_PASSWORD, password });

export const setLogin = (login) => ({ type: constants.SET_LOGIN, login });

export const setPasswordLength = (length) => ({ type: constants.SET_PASSWORD_LENGTH, length });
