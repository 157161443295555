import cx from 'classnames';
import get from 'lodash/get';
import React from 'react';
import SimpleMarkdown from 'simple-markdown';

import { EXTENDED_TABLE_REGEX } from './constants';
import {
    parseTable,
    parseNpTable,
    getTableOccupiedColSpace,
    getTableOccupiedCols,
    getTableColSize,
} from './parse-table';

const getAttribute = (parsedTable, name) => parsedTable.attrs[name];

const NPTABLE_REGEX = /^ *(\S.*\|.*)\n *([-:]+ *\|[-| :]*)\n((?:.*\|.*(?:\n|$))*)\n*/;

const TABLE_WRAP_CLASSES = [
    ['headerless', (parsedTable) => getAttribute(parsedTable, 'headerless')],
    ['borderless', (parsedTable) => getAttribute(parsedTable, 'borderless')],
    ['borderless-clear', (parsedTable) => getAttribute(parsedTable, 'borderlessClear')],
    ['nowrap', (parsedTable) => getAttribute(parsedTable, 'nowrap')],
    ['has-width', (parsedTable) => getTableWidth(parsedTable) !== null],
    ['same-col-width', (parsedTable) => getAttribute(parsedTable, 'sameColWidth')],
];

const CSS_VARIABLES = [
    [
        'table-width',
        (parsedTable) => {
            const tableWidth = getTableWidth(parsedTable);

            return tableWidth !== null ? `${tableWidth}px` : null;
        },
    ],
    [
        'col-width',
        (parsedTable) => {
            const percentCols = getAttribute(parsedTable, 'percentCols');

            if (percentCols) {
                const firstRow = parsedTable.cells[0];

                if (!firstRow) {
                    return null;
                }

                const numberOfCells = firstRow.length;

                return `${100 / numberOfCells}%`;
            }

            const width = getTableWidth(parsedTable);

            if (width === null) {
                return null;
            }

            const colSize = getTableColSize(parsedTable);
            const occupiedSpace = getTableOccupiedColSpace(parsedTable);
            const occupiedCols = getTableOccupiedCols(parsedTable);

            return `${(width - occupiedSpace) / (colSize - occupiedCols)}px`;
        },
    ],
];

const getWrapClasses = (parsedTable) =>
    TABLE_WRAP_CLASSES.filter(([_, predicate]) => predicate(parsedTable)) // eslint-disable-line no-unused-vars
        .map(([ruleName]) => ruleName);

const getCssVariables = (parsedTable) =>
    CSS_VARIABLES.reduce((acc, [name, getVariable]) => ({ ...acc, [`--${name}`]: getVariable(parsedTable) }), {});

const getTableWidth = ({ attrs: { width } }) => (width !== undefined ? parseInt(width, 10) : null);
const getTableBgColor = ({ attrs: { bgColor } }) => (bgColor !== undefined ? bgColor : null);

const getHeaderWidth = (parsedTable, index) => {
    const tableWidth = getTableWidth(parsedTable);
    const headerWidth = get(parsedTable, ['header', index, 'attrs', 'width'], null);

    if (headerWidth === null || tableWidth === null) {
        return null;
    }

    const headerWidthPercentage = parseInt(headerWidth, 10);

    return (tableWidth * headerWidthPercentage) / 100;
};

const renderTable = (parsedTableNode, output, state) => {
    const getStyle = (colIndex, rowIndex) => {
        const textAlignStyle =
            parsedTableNode.align[colIndex] === null
                ? {}
                : {
                      textAlign: parsedTableNode.align[colIndex],
                  };

        const headerWidth = getHeaderWidth(parsedTableNode, colIndex);

        const widthStyle =
            headerWidth === null
                ? {}
                : {
                      width: headerWidth,
                      // display table-cell width hack
                      minWidth: headerWidth,
                      maxWidth: headerWidth,
                  };

        const cells = parsedTableNode.cells;
        const cell = Number(rowIndex) >= 0 && cells[rowIndex][colIndex];
        const cellValign = cell.attrs && cell.attrs.valign;

        const valignStyle = cellValign && {
            verticalAlign: cellValign,
        };

        return {
            ...textAlignStyle,
            ...widthStyle,
            ...valignStyle,
        };
    };

    const headers = parsedTableNode.header.map((node, i) => (
        <th key={i} scope="col" style={getStyle(i)}>
            {output(node.content, state)}
        </th>
    ));

    const rows = parsedTableNode.cells.map((row, r) => {
        return (
            <tr key={r}>
                {row.map((node, c) => {
                    const colSpan = node.attrs.colspan ? Number(node.attrs.colspan) : null;
                    const rowSpan = node.attrs.rowspan ? Number(node.attrs.rowspan) : null;

                    let className = null;
                    if (rowSpan !== null) {
                        const isRowSpanTouchLastRow = r + rowSpan >= parsedTableNode.cells.length;

                        if (isRowSpanTouchLastRow) {
                            // если ячейка с rowspan касается низа таблицы нужно стереть
                            // нижнюю границу тк стандартные css правила не срабатывают
                            className = 'without-bottom-border';
                        }
                    }

                    return (
                        <td className={className} colSpan={colSpan} key={c} rowSpan={rowSpan} style={getStyle(c, r)}>
                            {output(node.content, state)}
                        </td>
                    );
                })}
            </tr>
        );
    });

    const bgColor = getTableBgColor(parsedTableNode);

    const wrapStyles = bgColor
        ? {
              backgroundColor: `#${bgColor}`,
          }
        : {};

    return (
        <table key={state.key} style={wrapStyles}>
            <thead key="thead">
                <tr>{headers}</tr>
            </thead>
            <tbody key="tbody">{rows}</tbody>
        </table>
    );
};

export const table = {
    type: 'table', // заменяет стандартную таблицу
    rule: {
        order: 0,
        match: SimpleMarkdown.blockRegex(EXTENDED_TABLE_REGEX),
        parse(capture, parse, state) {
            return parseTable(capture.slice(1), parse, state);
        },
        // eslint-disable-next-line react/no-multi-comp
        react(parsedTableNode, output, state) {
            const markdownTable = renderTable(parsedTableNode, output, state);
            const wrapClasses = [...getWrapClasses(parsedTableNode), 'table-wrapper'];

            const wrapStyles = {
                ...getCssVariables(parsedTableNode),
            };

            return (
                <div className={cx(...wrapClasses)} key={state.key} style={wrapStyles}>
                    {markdownTable}
                </div>
            );
        },
    },
};

export const nptable = {
    type: 'nptable', // заменяет стандартную nptable таблицу
    rule: {
        order: 0,
        match: SimpleMarkdown.blockRegex(NPTABLE_REGEX),
        parse: parseNpTable,
    },
};
