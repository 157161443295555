import b from 'b_';
import cx from 'classnames';
import React from 'react';

import withActiveClass from '../../../utils/hocs/with-active-class';

import { keyboardKeyPropTypes } from './keyboard__key.propTypes';

import './keyboard__key.scss';

const ButtonWithActiveClass = withActiveClass('button');

class KeyboardKey extends React.Component {
    static propTypes = keyboardKeyPropTypes;

    static defaultProps = {
        disabled: false,
    };

    render() {
        const { disabled, mode, mix, isSelected: selected, onPress, width, content } = this.props;

        return (
            <ButtonWithActiveClass
                className={cx(mix, b('keyboard', 'key', { width, mode, selected }))}
                disabled={disabled}
                onClick={onPress}
            >
                <span className="keyboard__key-content">{content}</span>
            </ButtonWithActiveClass>
        );
    }
}

export default KeyboardKey;
