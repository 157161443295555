import { AwardItem, CampaignAwards } from '@yandex-int/k-common';
import * as api from '@yandex-int/k-common/client/api';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { showNetworkError } from '../notification/actions';

import { actions } from './actions';

function* getChildAvailableAwardsSaga(action: ReturnType<typeof actions.getChildAvailableAwards>) {
    const { childId, clessonId, onSuccess } = action.payload;

    try {
        const awardsData: CampaignAwards[] = yield call(api.getChildClessonAwards, {
            childId,
            clessonId,
        });
        yield put(actions.setAvailableAwards({ awards: awardsData }));

        if (onSuccess) {
            onSuccess(awardsData);
        }
    } catch (error) {
        console.error(error);
        yield put(showNetworkError(error));
    }
}

function* getMyAvailableAwardsSaga(action: ReturnType<typeof actions.getMyAvailableAwards>) {
    const { clessonId } = action.payload;

    try {
        const awardsData: CampaignAwards[] = yield call(api.getClessonAwards, { clessonId });
        yield put(actions.setAvailableAwards({ awards: awardsData }));
    } catch (error) {
        console.error(error);
        yield put(showNetworkError(error));
    }
}

function* getStudentsAvailableAwardsSaga(action: ReturnType<typeof actions.getStudentsAvailableAwards>) {
    const { courseId, campaignSlugs, onSuccess } = action.payload;

    try {
        const awardsData: CampaignAwards[] = yield call(api.getStudentsCourseAwards, {
            courseId,
            campaignSlugs,
        });
        yield put(actions.setStudentsAvailableAwards({ courseId, awards: awardsData }));

        if (onSuccess) {
            onSuccess(awardsData);
        }
    } catch (error) {
        console.error(error);
        yield put(showNetworkError(error));
    }
}

function* takeAwardSaga(action: ReturnType<typeof actions.takeAward>) {
    const { id, onSuccess, onError } = action.payload;

    try {
        const awardItem: AwardItem = yield call(api.postTakeAward, { awardItemId: id });

        onSuccess(awardItem);
    } catch (error) {
        if (onError) {
            onError();
        }

        if (error.status !== 409) {
            console.error(error);
            yield put(showNetworkError(error));
        }
    }
}

export function* awardsSagasWatcher() {
    yield all([
        takeLatest(actions.getChildAvailableAwards, getChildAvailableAwardsSaga),
        takeLatest(actions.getMyAvailableAwards, getMyAvailableAwardsSaga),
        takeLatest(actions.getStudentsAvailableAwards, getStudentsAvailableAwardsSaga),
        takeLatest(actions.takeAward, takeAwardSaga),
    ]);
}
