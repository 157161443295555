// @ts-nocheck
import { Button2 as Button, Link, Text } from 'platform-components';
import React from 'react';

import ExternalError from '../../external-error/external-error';
import { EXTERNAL_CLASSES_ERROR } from '../typings';

import './external-classes__error.scss';

interface Props {
    backUrl: string;
    error: EXTERNAL_CLASSES_ERROR;
    lms: string;
}

export const ExternalClassesError = ({ backUrl, error, lms }: Props) => {
    return (
        <ExternalError
            descriptionTextId={`externalLms.importTeams.error.${error}.description`}
            headerTextId={`externalLms.importTeams.error.${error}.header`}
        >
            <Link to={decodeURIComponent(backUrl)}>
                <Button mix="external-classes__error-button" theme={Button.THEMES.ACTION}>
                    <Text
                        id={`externalLms.importTeams.error.${error}.button`}
                        values={{ lms }}
                        weight={Text.WEIGHT.M}
                    />
                </Button>
            </Link>
        </ExternalError>
    );
};
