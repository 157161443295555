import type { Me } from '@yandex-int/k-common';
import { getMe } from 'platform-components/src/components/user/selectors';
import { setToLocalStorage } from 'platform-components/utils';
import { all, select, takeLatest } from 'redux-saga/effects';

import { actions } from './actions';
import { getRegistrationData } from './selectors';
import type { TeacherRegistrationData } from './typings';

export function* teacherRegistrationPageSagasWatcher() {
    yield all([takeLatest(actions.setRegistrationData, saveRegistrationDataSaga)]);
}

function* saveRegistrationDataSaga(action: ReturnType<typeof actions.setRegistrationData>) {
    try {
        const registrationData = action.payload;
        const savedData: TeacherRegistrationData = yield select(getRegistrationData);
        const dataToSave = { ...savedData, ...registrationData };

        const { id: userId }: Me = yield select(getMe);
        setToLocalStorage('registrationData', JSON.stringify({ [userId]: dataToSave }));
    } catch (error) {
        console.error(error);
    }
}
