import b from 'b_';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useIsomorphicLayoutEffect } from 'react-use';
import ym from 'react-yandex-metrika';
import { compose, bindActionCreators } from 'redux';

import { isInternetExplorer } from '../../utils/client-browser';
import withUatraits from '../../utils/hocs/with-uatraits';
import withMobileViewportHack from '../../utils/hocs/withMobileViewportHack';
import { setUserSessionId, extendUserSessionParamsFromUrl } from '../../utils/user-session';
import AnimatedNotification from '../animated-notification/animated-notification';
import { hideNotification } from '../notification/actions';
import notificationReducer from '../notification/reducer';
import { getNotification } from '../notification/selectors';
import { withRouter } from '../router/router';

import PageMetrika from './__metrika/page__metrika';
import PageTitle from './__title/page__title';

import './page.scss';
import './page.print.scss';

// eslint-disable-next-line react/prop-types
const UserSessionCreator = ({ userSessionParams }) => {
    useIsomorphicLayoutEffect(() => {
        setUserSessionId();

        extendUserSessionParamsFromUrl(userSessionParams);
    }, [userSessionParams]);

    return null;
};

// eslint-disable-next-line react/no-multi-comp
class Page extends Component {
    static propTypes = {
        children: PropTypes.node,
        hideNotification: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired,
        lastNetworkError: PropTypes.object,
        location: PropTypes.object.isRequired,
        mix: PropTypes.string,
        notification: PropTypes.object.isRequired,
        theme: PropTypes.string,
        uatraits: PropTypes.object,
        userSessionParams: PropTypes.arrayOf(PropTypes.string),
        withMedia: PropTypes.bool,
    };

    static contextTypes = {
        staticUrls: PropTypes.object.isRequired,
        store: PropTypes.object.isRequired,
    };

    static childContextTypes = {
        withMedia: PropTypes.bool,
    };

    getChildContext() {
        const { withMedia } = this.props;

        return { withMedia };
    }

    UNSAFE_componentWillMount() {
        const { history } = this.props;
        const { store } = this.context;
        store.addReducers({
            notification: notificationReducer,
        });

        this.removeHistoryListener = history.listen(() => {
            if (!this.props.notification.closeDelay) {
                this.props.hideNotification();
            }
        });
    }

    componentDidUpdate({ location: { pathname: prevPathname } }) {
        const {
            location: { pathname },
        } = this.props;

        if (prevPathname !== pathname) {
            ym('hit', pathname, { referer: prevPathname });
        }
    }

    componentWillUnmount() {
        if (this.removeHistoryListener) {
            this.removeHistoryListener();
        }
    }

    render() {
        const {
            children,
            mix,
            theme,
            notification,
            notification: { gravity, visible },
            userSessionParams,
        } = this.props;
        const { uatraits, withMedia } = this.props;
        const className = cx(
            b('page', {
                theme,
                ie: isInternetExplorer(uatraits),
                engine: (uatraits.BrowserEngine || '').toLowerCase(),
                mobile: Boolean(uatraits.isMobile),
                ['with-media']: withMedia,
            }),
            mix
        );

        return (
            <div className={className}>
                <UserSessionCreator userSessionParams={userSessionParams} />
                <PageTitle />
                <AnimatedNotification gravity={gravity} staticProps={{ notification }} visible={visible} />
                <PageMetrika />
                {children}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    notification: getNotification(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ hideNotification }, dispatch);

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withUatraits,
    withMobileViewportHack
)(Page);

export { default as Content } from './__content/page__content';
export { default as Up } from './__up/page__up';
