import React from 'react';

const SvgOk = (props) => (
    <svg fill="#201E1E" height={20} width={20} {...props}>
        <path d="M18 0H2a2 2 0 00-2 2v16a2 2 0 002 2h16a2 2 0 002-2V2a2 2 0 00-2-2z" />
        <path
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M9.729 5.447c-.776 0-1.407.63-1.407 1.406 0 .775.63 1.406 1.407 1.406.774 0 1.406-.63 1.406-1.406 0-.775-.632-1.406-1.406-1.406zm0 4.802a3.4 3.4 0 01-3.397-3.396 3.4 3.4 0 013.397-3.397 3.4 3.4 0 013.397 3.397 3.4 3.4 0 01-3.397 3.396zM8.354 13.02a6.362 6.362 0 01-1.972-.817.995.995 0 011.06-1.685 4.313 4.313 0 004.573 0 .995.995 0 011.06 1.685 6.373 6.373 0 01-1.972.817l1.9 1.9a.995.995 0 01-1.409 1.407L9.73 14.46l-1.867 1.867a.995.995 0 11-1.407-1.408l1.9-1.899z"
            fill="#fff"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgOk;
