import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './icon__close.scss';

const IconClose = ({ theme, mix, ...props }) => {
    if (theme === 'thin') {
        return (
            <svg className={cx('icon__close', mix)} {...props} height="18" viewBox="0 0 18 18" width="18">
                <path d="M9 8.293l5.303-5.303.707.707L9.707 9l5.303 5.303-.707.707L9 9.707 3.697 15.01l-.707-.707L8.293 9 2.99 3.697l.707-.707L9 8.293z" />
            </svg>
        );
    }

    return (
        // jscs:disable maximumLineLength
        <svg className={cx('icon__close', mix)} {...props} height="14" viewBox="0 0 14 14" width="14">
            <path d="M13.707 1.707L12.293.293 7 5.586 1.707.293.293 1.707 5.586 7 .293 12.293l1.414 1.414L7 8.414l5.293 5.293 1.414-1.414L8.414 7z" />
        </svg>
        // jscs:enable maximumLineLength
    );
};

IconClose.propTypes = {
    mix: PropTypes.string,
    theme: PropTypes.oneOf(['normal', 'thin']),
};

export default IconClose;
