export const AVATARS_SCHOOLBOOK_NAMESPACE = 'schoolbook-images';
export const AVATARS_URL_REGEXP = new RegExp(
    `.*avatars\\.mds[t]*\\.yandex\\.net\\/get-${AVATARS_SCHOOLBOOK_NAMESPACE}\\/([^/]+)\\/([^/]+)`
);

export enum AVATARS_THUMBNAILS {
    X1 = 'x1',
    X2 = 'x2',
    X3 = 'x3',
    ORIG = 'orig',
}

export enum SCREEN_RESOLUTIONS {
    X1 = '1x',
    X2 = '2x',
    X3 = '3x',
    X4 = '4x',
}

export type ScaleStrategy = 'resolution' | 'imageSize';
