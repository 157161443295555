import { apiConfig } from '@yandex-int/k-common/client/api/config';
import { HTTP_METHODS } from '@yandex-int/k-common/client/api/constants';
import get from 'lodash/get';
import { SHOW_NETWORK_ERROR } from 'platform-components/src/components/notification/constants';
import { reportNetworkError } from 'platform-components/utils';
import { stringify } from 'query-string';
import { takeEvery } from 'redux-saga/effects';

export function* redirectToErrorPageWatcher() {
    yield takeEvery(shouldRedirectToErrorPage, redirectToErrorPageSaga);
}

function shouldRedirectToErrorPage(action) {
    const httpMethod = get(action, 'error.httpMethod');
    return action.type === SHOW_NETWORK_ERROR && httpMethod === HTTP_METHODS.GET;
}

function* redirectToErrorPageSaga(action) {
    const status = get(action, 'error.status');
    const { notFound, forbidden } = apiConfig.getStaticUrls();

    if (status === 403) {
        const { pathname } = window.location;
        window.location.href = `${forbidden}?${stringify({ retpath: pathname })}`;
    }

    if (status === 404) {
        window.location.href = notFound;
    }
}

export function* reportNetworkErrorWatcher() {
    yield takeEvery(SHOW_NETWORK_ERROR, reportNetworkErrorSaga);
}

function* reportNetworkErrorSaga({ error }) {
    reportNetworkError(error);
}
