import b from 'b_';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import sortByIntersection from '../../../utils/sort-by-intersection/sort-by-intersection';
import { KEY_CODES, INPUT_TYPES, ANSWER_STATUSES, STATUS_TO_THEME } from '../../constants';
import IconCloseCircle from '../../icon/__close-circle/icon__close-circle';
import IconMinusCircle from '../../icon/__minus-circle/icon__minus-circle';
import IconPlusCircle from '../../icon/__plus-circle/icon__plus-circle';
import Popup from '../../popup/popup';

import './inline-input_type_sequence.scss';

const ANSWER_EMPTY = [null];
const POPUP_OFFSET_LEFT = -4;
const POPUP_OFFSET_TOP = -8;

const THEME_NORMAL = STATUS_TO_THEME[ANSWER_STATUSES.NOT_EVALUATED];
const THEME_CORRECT = STATUS_TO_THEME[ANSWER_STATUSES.CORRECT];
const THEME_INCORRECT = STATUS_TO_THEME[ANSWER_STATUSES.INCORRECT];

class InlineInputTypeSequence extends React.Component {
    static propTypes = {
        answer: PropTypes.array,
        children: PropTypes.func,
        childrenType: PropTypes.string,
        elements: PropTypes.object,
        onKeyDown: PropTypes.func,
        options: PropTypes.object,
        setAnswer: PropTypes.func.isRequired,
        showCorrectAnswer: PropTypes.bool,
        userAnswer: PropTypes.array,
        readOnly: PropTypes.bool,
        theme: PropTypes.string,
    };

    constructor(props) {
        super(props);
        const {
            userAnswer,
            answer,
            options: { ordered },
        } = this.props;
        const userAnswers = userAnswer || ANSWER_EMPTY;
        let correctAnswers = [];

        if (answer) {
            correctAnswers = ordered ? answer : sortByIntersection(answer, [...userAnswers]);
        }

        this.state = {
            userAnswers,
            correctAnswers,
            focusedAnswerIndex: -1,
            mouseEntered: false,
        };

        this.iconComponent = null;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { userAnswers: userAnswersFromState } = this.state;
        const {
            userAnswer,
            answer,
            options: { ordered },
        } = this.props;
        const {
            userAnswer: nextUserAnswer,
            answer: nextAnswer,
            options: { ordered: nextOrdered },
        } = nextProps;
        let userAnswers;

        if (nextUserAnswer !== userAnswer) {
            userAnswers = nextUserAnswer || ANSWER_EMPTY;
            this.setState({
                userAnswers,
            });
        } else {
            userAnswers = userAnswersFromState;
        }

        if (nextAnswer && (nextAnswer !== answer || ordered !== nextOrdered)) {
            this.setState({
                correctAnswers: nextOrdered ? nextAnswer : sortByIntersection(nextAnswer, [...userAnswers]),
            });
        }
    }

    componentDidUpdate(prevProps, { userAnswers: prevUserAnswers }) {
        const { userAnswers } = this.state;
        const { setAnswer } = this.props;

        if (userAnswers !== prevUserAnswers) {
            setAnswer(userAnswers);
        }

        if (this.isIconComponentChanged && this.popupComponent && this.iconComponent) {
            this.isIconComponentChanged = false;

            this.popupComponent.setOwner(this.iconComponent);
        }
    }

    handleAnswerAdd = () => {
        let { userAnswers } = this.state;
        userAnswers = userAnswers.push(null);

        this.setState({
            userAnswers: userAnswers,
            focusedAnswerIndex: userAnswers.length - 1,
        });

        this.isIconComponentChanged = true;
    };

    handleAnswerChange = (index, value) => {
        const { userAnswers } = this.state;
        const newUserAnswers = [...userAnswers];
        newUserAnswers[index] = value;

        this.setState({ userAnswers: newUserAnswers });
    };

    handleAnswerDelete = (index, focusedAnswerIndex = -1) => {
        const { userAnswers } = this.state;

        if (!Number.isInteger(index)) {
            index = userAnswers.length - 1;
        }

        const newUserAnswers = userAnswers.filter((_, itemIndex) => index !== itemIndex);
        this.setState({
            userAnswers: newUserAnswers,
            focusedAnswerIndex,
        });

        this.isIconComponentChanged = true;
    };

    handleKeyDown(index, event, ...restArgs) {
        const { userAnswers } = this.state;
        const { onKeyDown } = this.props;

        if (event.keyCode === KEY_CODES.BACKSPACE && !userAnswers[index] && this.moreThanOne()) {
            event.preventDefault();
            this.handleAnswerDelete(index, index - 1);

            return;
        }

        onKeyDown(event, ...restArgs);
    }

    handleChildFocus(index) {
        // это присвоение стейта вызывает повторный фокус на элемент.
        this.setState({ focusedAnswerIndex: index });
    }

    handleChildBlur() {
        this.setState({ focusedAnswerIndex: -1 });
    }

    handleMouseEnter = () => {
        this.setState({ mouseEntered: true });
    };

    handleMouseLeave = () => {
        this.setState({ mouseEntered: false });
    };

    moreThanOne() {
        const { userAnswers } = this.state;

        return userAnswers.length > 1;
    }

    hasMaxLength() {
        const { userAnswers } = this.state;
        const {
            options: { max_length: maxLength },
        } = this.props;

        return userAnswers.length >= maxLength;
    }

    shouldShowDeleteControl(index) {
        const { readOnly } = this.props;
        const { userAnswers, focusedAnswerIndex } = this.state;

        const isEmpty = !userAnswers[index];
        const isNowBlurred = focusedAnswerIndex !== index;
        const isRationalChildrenType = this.isRationalChildrenType();

        if (!this.moreThanOne()) {
            return false;
        }

        return !readOnly && !isEmpty && (!isRationalChildrenType || isNowBlurred); // rational - скрываем контрол в фокусе
    }

    isRationalChildrenType() {
        const { childrenType } = this.props;

        return childrenType === INPUT_TYPES.RATIONAL;
    }

    shouldShowAddButton() {
        const { readOnly } = this.props;
        const { userAnswers } = this.state;
        const lastUserAnswerItem = userAnswers[userAnswers.length - 1];

        return !this.shouldShowDeleteButton() && lastUserAnswerItem && !this.hasMaxLength() && !readOnly;
    }

    shouldShowDeleteButton() {
        const { readOnly } = this.props;
        const { userAnswers } = this.state;
        const lastUserAnswerItem = userAnswers[userAnswers.length - 1];

        return this.moreThanOne() && !lastUserAnswerItem && !readOnly;
    }

    setPopupComponent = (component) => {
        if (this.popupComponent && !component) {
            this.popupComponent.setOwner(null);
        }

        this.popupComponent = component;
    };

    showPopup = () => {
        if (this.popupComponent) {
            this.popupComponent.show();
        }
    };

    hidePopup = () => {
        if (this.popupComponent) {
            this.popupComponent.hide();
        }
    };

    setIconComp = (component) => {
        this.isIconComponentChanged = this.iconComponent !== component;
        this.iconComponent = component;
    };

    isAnsweredMode() {
        const { theme } = this.props;

        return theme !== THEME_NORMAL;
    }

    isIncorrect() {
        const { theme } = this.props;

        return theme === THEME_INCORRECT;
    }

    getChildrenTheme() {
        const { mouseEntered } = this.state;
        const { theme } = this.props;
        const isAnsweredMode = this.isAnsweredMode();

        if (isAnsweredMode && mouseEntered) {
            return THEME_CORRECT;
        }

        return theme;
    }

    // eslint-disable-next-line max-params
    getAnswerNodes(answers, setAnswerHandler, childrenProps, isHidden = false, indexOffset = 0) {
        const { focusedAnswerIndex } = this.state;
        const { children } = this.props;

        const { readOnly, type } = childrenProps;

        const childrenTheme = this.getChildrenTheme();
        const childrenClassName = b('inline-input', {
            'read-only': readOnly,
            theme: childrenTheme,
            type,
        });

        return answers.map((userAnswer, index) => {
            const nodeIndex = index + indexOffset;

            const shouldShowDeleteControl = this.shouldShowDeleteControl(nodeIndex);
            const className = b('inline-input', 'sequence-item', {
                'has-delete-control': shouldShowDeleteControl,
                hidden: isHidden,
            });
            const shouldShowIconEmpty = !shouldShowDeleteControl && !this.isRationalChildrenType();
            const childAnswer = (childrenProps.answer && childrenProps.answer[nodeIndex]) || null;

            return (
                <span className={className} key={nodeIndex}>
                    {React.createElement(children, {
                        ...childrenProps,
                        answer: childAnswer,
                        className: childrenClassName,
                        userAnswer,
                        setAnswer: setAnswerHandler.bind(null, nodeIndex),
                        onKeyDown: this.handleKeyDown.bind(this, nodeIndex),
                        onFocus: this.handleChildFocus.bind(this, nodeIndex),
                        onBlur: this.handleChildBlur.bind(this, nodeIndex),
                        forceFocus: focusedAnswerIndex === nodeIndex,
                        theme: childrenTheme,
                    })}
                    {shouldShowDeleteControl && (
                        <span className="inline-input__sequence-icon-wrap">
                            <IconCloseCircle
                                mix="inline-input__sequence-icon-button"
                                onClick={() => this.handleAnswerDelete(nodeIndex)}
                            />
                        </span>
                    )}
                    {shouldShowIconEmpty && <span className="inline-input__sequence-icon-empty" />}
                </span>
            );
        });
    }

    addEmptyInputs(answers, childrenProps, count) {
        const isHidden = true;
        const hiddenAnswers = new Array(count).fill(undefined);

        return answers.concat(this.getAnswerNodes(hiddenAnswers, noop, childrenProps, isHidden, answers.length));
    }

    render() {
        const { userAnswers, correctAnswers, mouseEntered } = this.state;
        const { elements, ...restProps } = this.props;
        const { showCorrectAnswer } = restProps;

        const childrenProps = {
            ...restProps,
            ...elements,
        };

        const shouldShowAddButton = this.shouldShowAddButton();
        const shouldShowDeleteButton = this.shouldShowDeleteButton();
        const isAnsweredMode = this.isAnsweredMode();
        const shouldShowRightAnswer = (this.isIncorrect() && mouseEntered) || showCorrectAnswer;

        let renderAnswers;
        let answersLengthDiff;
        let setAnswer;

        if (shouldShowRightAnswer) {
            renderAnswers = correctAnswers;
            answersLengthDiff = correctAnswers.length - userAnswers.length;
            setAnswer = noop;
        } else {
            renderAnswers = userAnswers;
            answersLengthDiff = userAnswers.length - correctAnswers.length;
            setAnswer = this.handleAnswerChange;
        }

        const popupMessage = shouldShowAddButton
            ? 'inlineInput.sequence.popup.addAnswer'
            : 'inlineInput.sequence.popup.deleteAnswer';

        const iconWrapperUnderlineClassName = b('inline-input', 'sequence-icon-wrap', { underline: true });

        let answerNodes = this.getAnswerNodes(renderAnswers, setAnswer, childrenProps);

        // добавляем невидимые инпуты, если разница ответов в другом режиме отрицательная
        // например:
        // correctAnswers [1,2,3]; userAnswers [1,2]. Показываем userAnswers. Разница -1. Дорисовываем пустышки.
        if (isAnsweredMode && answersLengthDiff < 0) {
            answerNodes = this.addEmptyInputs(answerNodes, childrenProps, Math.abs(answersLengthDiff));
        }

        return (
            <span
                className="inline-input_type_sequence"
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
            >
                {answerNodes}
                <span className={iconWrapperUnderlineClassName} ref={this.setIconComp}>
                    {shouldShowAddButton && (
                        <IconPlusCircle
                            mix="inline-input__sequence-icon-button"
                            onClick={this.handleAnswerAdd}
                            onMouseEnter={this.showPopup}
                            onMouseLeave={this.hidePopup}
                        />
                    )}
                    {shouldShowDeleteButton && (
                        <IconMinusCircle
                            mix="inline-input__sequence-icon-button"
                            onClick={this.handleAnswerDelete}
                            onMouseEnter={this.showPopup}
                            onMouseLeave={this.hidePopup}
                        />
                    )}
                    <Popup
                        arrowOffsetLeft="16px"
                        autoclosable
                        axis="left"
                        direction="top"
                        hasArrow
                        mix="inline-input__hint"
                        offsetLeft={POPUP_OFFSET_LEFT}
                        offsetTop={POPUP_OFFSET_TOP}
                        ref={this.setPopupComponent}
                        width="144px"
                    >
                        <FormattedMessage id={popupMessage} />
                    </Popup>
                </span>
            </span>
        );
    }
}

export default injectIntl(InlineInputTypeSequence);
