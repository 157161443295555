import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import get from 'lodash/get';
import { createSelector } from 'reselect';

import { ChildManagementStore, ErrorTypes } from './typings';

const getChildManagementState = (storeState: any): ChildManagementStore => get(storeState, 'childManagement', {});

export const getStudentCode = createSelector(
    getChildManagementState,
    (childManagement: ChildManagementStore): string => childManagement.code
);

export const getLogin = createSelector(
    getChildManagementState,
    (childManagement: ChildManagementStore): string => childManagement.login
);

export const getSchoolCode = createSelector(
    getChildManagementState,
    (childManagement: ChildManagementStore): string => childManagement.schoolCode
);

export const getPasswordLength = createSelector(
    getChildManagementState,
    (childManagement: ChildManagementStore): number => childManagement.passwordLength
);

export const getBindingByLoginStatus = createSelector(
    getChildManagementState,
    (childManagement: ChildManagementStore): LOADING_STATUSES => childManagement.bindingByLoginStatus
);

export const getCheckSchoolCodeStatus = createSelector(
    getChildManagementState,
    (childManagement: ChildManagementStore): LOADING_STATUSES => childManagement.checkSchoolCodeStatus
);

export const getBindingLoadingStatus = createSelector(
    getChildManagementState,
    (childManagement: ChildManagementStore): LOADING_STATUSES => childManagement.bindingLoadingStatus
);

export const getIsBindingLoadingCodeWrong = createSelector(
    getChildManagementState,
    (childManagement: ChildManagementStore): boolean => childManagement.isBindingLoadingCodeWrong
);

export const getIsStudentBindingDisabled = createSelector(
    [getStudentCode, getBindingLoadingStatus],
    (code: string, loadingStatus: LOADING_STATUSES): boolean => !code || loadingStatus === LOADING_STATUSES.LOADING
);

export const getShouldShowErrorPopup = createSelector(
    getChildManagementState,
    (childManagement: ChildManagementStore): boolean => childManagement.shouldShowErrorPopup
);

export const getIsBindingByLoginDisabled = createSelector(
    [getLogin, getSchoolCode, getCheckSchoolCodeStatus, getPasswordLength],
    (login: string, schoolCode: string, loadingSchoolCodeStatus: LOADING_STATUSES, passwordLength: number): boolean =>
        loadingSchoolCodeStatus !== LOADING_STATUSES.SUCCESS || !login || schoolCode.length < passwordLength
);

export const getParentCourseAssignStatus = createSelector(
    getChildManagementState,
    (childManagement: ChildManagementStore): LOADING_STATUSES => childManagement.parentCourseAssignStatus
);

export const shouldShowBindingByLoginProgress = createSelector(
    [getBindingByLoginStatus, getParentCourseAssignStatus],
    (bindingByLoginStatus, parentCourseAssignStatus): boolean =>
        parentCourseAssignStatus === LOADING_STATUSES.LOADING || bindingByLoginStatus === LOADING_STATUSES.LOADING
);

export const shouldShowButtonProgress = createSelector(
    [getBindingLoadingStatus, getParentCourseAssignStatus],
    (bindingLoadingStatus, parentCourseAssignStatus): boolean =>
        parentCourseAssignStatus === LOADING_STATUSES.LOADING || bindingLoadingStatus === LOADING_STATUSES.LOADING
);

export const getErrorType = createSelector(
    getChildManagementState,
    (childManagement: ChildManagementStore): ErrorTypes | null => childManagement.errorType
);
