import { postApproveSubscribe } from '@yandex-int/k-common/client/api';
import { call, put, takeLatest } from 'redux-saga/effects';

import { approveSubscribeError, approveSubscribeSuccess } from './actions';
import { APPROVE_SUBSCRIBE_REQUEST } from './constants';

const ERROR_TYPES = {
    WRONG_PARAMS: 'wrongParams',
    NOT_FOUND: 'notFound',
};

export function* approveSubscribeWatcher() {
    yield takeLatest(APPROVE_SUBSCRIBE_REQUEST, approveSubscribeSaga);
}

function* approveSubscribeSaga(action) {
    const { search } = action;

    try {
        yield call(postApproveSubscribe, search);
        yield put(approveSubscribeSuccess());
    } catch (error) {
        const errorType = handleError(error);
        yield put(approveSubscribeError(errorType));
    }
}

function handleError(e) {
    if (e.status === 400) {
        return ERROR_TYPES.WRONG_PARAMS;
    }

    return ERROR_TYPES.NOT_FOUND;
}
