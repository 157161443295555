import { CampaignAwards } from '../../../typings';
import { getFetch } from '../fetch';
import { apiConfig } from '../config';

export interface ClessonAwardsRequestData {
    clessonId: number;
}

export type ClessonAwardsResponseData = CampaignAwards[];

export function getClessonAwards(request: ClessonAwardsRequestData): Promise<ClessonAwardsResponseData> {
    const { clessonId } = request;
    const { apiGetClessonAwards } = apiConfig.getStaticUrls();

    return getFetch(apiGetClessonAwards, {
        'clesson_id': clessonId,
    });
}
