import { SelectSuggest } from 'platform-components';
import { POPUP_INDENTS } from 'platform-components/constants';
import PropTypes from 'prop-types';
import React from 'react';

import SuggestError from '../__suggest-error/form-view__suggest-error';
import SuggestOption from '../__suggest-option/form-view__suggest-option';

import './form-view__suggest.scss';

class FormViewSuggest extends React.Component {
    static propTypes = {
        errorMessage: PropTypes.string,
        hint: PropTypes.node,
        onMouseEnter: PropTypes.func,
        onMouseLeave: PropTypes.func,
        optionRenderer: PropTypes.func,
        scrollOnOpen: PropTypes.bool,
    };

    state = {
        shown: false,
    };

    fieldRef = React.createRef();

    handleRequestHide = () => {
        this.setState({ shown: false });
    };

    handleRequestShow = () => {
        const { scrollOnOpen } = this.props;

        if (scrollOnOpen) {
            // тут скроллим так, чтобы инпут оказался вверху видимой области
            this.fieldRef.current.scrollIntoView(true);
        }

        this.setState({ shown: true });
    };

    render() {
        const { onMouseEnter, onMouseLeave, optionRenderer, hint, errorMessage, ...props } = this.props;
        const { shown } = this.state;

        const shouldShowHint = hint && errorMessage === null;

        return (
            <div
                className="form-view__suggest"
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                ref={this.fieldRef}
            >
                <SelectSuggest
                    errorMessage={errorMessage}
                    errorRenderer={SuggestError}
                    indent={POPUP_INDENTS.NOINDENT}
                    onRequestHide={this.handleRequestHide}
                    onRequestShow={this.handleRequestShow}
                    optionRenderer={optionRenderer || SuggestOption}
                    shown={shown}
                    {...props}
                />
                {shouldShowHint ? <div className="form-view__hint">{hint}</div> : null}
            </div>
        );
    }
}

export default FormViewSuggest;

export { SelectSuggestTypes as SUGGEST_TYPES } from 'platform-components';
