'use strict';

import 'element-remove';
import closestPolyfill from 'element-closest';
import React from 'react';
import client from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import smoothscroll from 'smoothscroll-polyfill';

export default function (routes) {
    function app() {
        const data = window._data || {};

        // Инициализируем полифил для scrollTo и для scrollIntoView
        smoothscroll.polyfill();

        if (!isSSR) {
            closestPolyfill(window);
        }

        const prefix = (data.config && data.config.prefix) || '';

        const App = (props) => {
            return routes.props.render({ ...props, ...routes.props, route: data });
        };

        const root = client.createRoot(document.getElementById('react-root'));
        root.render(
            <BrowserRouter basename={prefix}>
                <App />
            </BrowserRouter>
        );
    }

    if (!global.Intl) {
        Promise.all([import('intl'), import('intl/locale-data/jsonp/ru.js')]).then(() => app());
    } else {
        app();
    }
}
