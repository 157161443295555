import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import { SimpleCallback } from 'platform-components';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ClassChangeForm } from 'common.components/class-change-form/class-change-form';
import { TeamBuilderBackButton } from 'common.components/team-builder/__back-button/team-builder__back-button';
import { getCurrentTeamData, getTeamPatchData } from 'common.components/team-builder/selectors';
import { TeamBuilderTeamData } from 'common.components/team-builder/typings';
import { PRESELECTED_CLASS_LETTER, PRESELECTED_CLASS_NUMBER } from 'common.components/user/class.constants';
import { getTeamIdFromPath } from 'utils/path';

import { teamBuilderActions } from '../actions';

import './team-builder__change-team.scss';

interface Props {
    hasBackButton?: boolean;
    loadingStatus: LOADING_STATUSES;
    onAssign: () => void;
    onBack?: SimpleCallback;
    onNext: SimpleCallback;
}

export const TeamBuilderChangeInfo = (props: Props) => {
    const { onAssign, onNext, loadingStatus, hasBackButton, onBack } = props;
    const dispatch = useDispatch();

    const teamData = useSelector(getTeamPatchData);

    const teamId = getTeamIdFromPath();
    const { teamLetter: currentTeamLetter, teamLevel: currentTeamLevel } = useSelector((store) =>
        getCurrentTeamData(store, { classId: teamId })
    );

    let defaultData: TeamBuilderTeamData;

    if (currentTeamLevel !== null && currentTeamLetter !== null) {
        defaultData = { teamLetter: currentTeamLetter, teamLevel: currentTeamLevel };
    } else {
        defaultData = {
            teamLevel: PRESELECTED_CLASS_NUMBER,
            teamLetter: PRESELECTED_CLASS_LETTER,
        };
    }
    const { teamLevel, teamLetter } = teamData || defaultData;

    useEffect(() => {
        const { teamLetter: letter, teamLevel: number } = defaultData;
        dispatch(teamBuilderActions.setTeamPatchData({ letter, number }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, teamId]);

    const onTeamChange = (data: { letter: string; number: number }) => {
        dispatch(teamBuilderActions.setTeamPatchData(data));
        onAssign();
    };

    const onTeamSubmit = () => {
        const data = { letter: teamLetter, number: teamLevel };
        onTeamChange(data);
        onNext();
    };

    const isLoading = loadingStatus === LOADING_STATUSES.LOADING;

    return (
        <ClassChangeForm
            backButton={
                hasBackButton ? (
                    <TeamBuilderBackButton
                        mix="class-change-form__footer-link"
                        onClick={onBack}
                        textId="classForm.goBack"
                    />
                ) : undefined
            }
            buttonTextId="addClassForm.sendButton.saveInfo"
            descriptionTextId="addClassForm.description.info"
            headerTextId="addClassForm.header.info"
            isLoading={isLoading}
            letter={teamLetter}
            mix="team-builder__class-change-form"
            number={teamLevel}
            onChange={onTeamChange}
            onSubmit={onTeamSubmit}
        />
    );
};
