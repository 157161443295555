import b from 'b_';
import React, { useRef, useState } from 'react';
import {
    YMaps,
    Map,
    ObjectManager,
    MapState,
    MapOptions,
    ObjectManagerFeatures,
    YMapsApi,
    AnyObject,
} from 'react-yandex-maps';

import Spin from '../spin/spin';

import { COMMON_MODULES, RESTRICT_AREA, VECTOR_MAP_WITHOUT_LABELS } from './constants';

import './geo-map.scss';

interface Props {
    jsonData: {
        mapSettings: {
            state: MapState;
            options: MapOptions;
            hideLabels: boolean;
            size: MapSizes;
        };
        features?: ObjectManagerFeatures;
        countries?: AnyObject;
    };
    onInit?: (ymaps: YMapsApi, mapInstance: AnyObject | null) => void;
    outlinesData?: ObjectManagerFeatures;
    requiredModules?: string[];
}

type MapSizes = 'small' | 'large' | 'auto';

export const GeoMap = ({ jsonData, onInit, outlinesData, requiredModules }: Props) => {
    const { mapSettings, features, countries } = jsonData;
    const initOptions = {
        ...RESTRICT_AREA,
        ...mapSettings.options,
        ...(mapSettings.hideLabels ? VECTOR_MAP_WITHOUT_LABELS : {}),
    };
    const modules = requiredModules ? [...COMMON_MODULES, ...requiredModules] : COMMON_MODULES;
    const [loading, setLoading] = useState(true);

    const mapInstance = useRef<AnyObject | null>(null);
    const setMapInstance = (element: AnyObject | null) => {
        mapInstance.current = element;
    };

    const size = mapSettings.size;
    const containerClassName = b('geo-map', { size });
    const contentClassName = b('geo-map', 'content', { size });

    const init = (ymaps: YMapsApi) => {
        setLoading(false);

        if (countries) {
            ymaps.borders
                .load('001', {
                    lang: 'ru',
                    quality: 1,
                })
                .then(function (geojson: AnyObject) {
                    const regions = new ymaps.GeoObjectCollection(null, {
                        outline: false,
                        hasHint: false,
                        cursor: 'default',
                    });

                    for (const feature of geojson.features) {
                        if (countries[feature.properties.name]) {
                            const region = new ymaps.GeoObject(feature);
                            region.options.set('fillColor', countries[feature.properties.name]);
                            regions.add(region);
                        }
                    }

                    if (mapInstance.current) {
                        mapInstance.current.geoObjects.add(regions);
                    }
                });
        }

        if (onInit) {
            onInit(ymaps, mapInstance);
        }
    };

    return (
        <div className={containerClassName}>
            <div className={contentClassName}>
                <YMaps query={{ ns: 'ymaps' }}>
                    {loading && <Spin center />}
                    <Map
                        defaultState={mapSettings.state}
                        height={'100%'}
                        instanceRef={setMapInstance}
                        modules={modules}
                        onLoad={init}
                        options={initOptions}
                        width={'100%'}
                    >
                        {features && <ObjectManager features={features} />}
                        {outlinesData && <ObjectManager features={outlinesData} />}
                    </Map>
                </YMaps>
            </div>
        </div>
    );
};

export default GeoMap;
