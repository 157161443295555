import { withBemMod } from '@bem-react/core';
import { cnButton } from '@yandex-lego/components/Button/desktop';

import './Button_view_primary.css';

export type WithViewPrimaryProps = {
    view?: 'primary';
};

export const withViewPrimary = withBemMod<WithViewPrimaryProps>(cnButton(), {
    view: 'primary',
});
