import { useLayoutEffect, useRef, useState } from 'react';
import { useMedia } from 'react-use';
import { v4 as uuid } from 'uuid';

import { useStableCallback } from '../../utils/hooks';

export function useRefreshOnOrientationChange(options: {
    initialValue?: string;
    getCurrentValue: () => string | undefined;
}) {
    const [initialValue, setInitialValue] = useState(options.initialValue);
    const isPortrait = useMedia('(orientation: portrait)');
    const [key, setKey] = useState(uuid);
    const skipInitialEffect = useRef(true);
    const getCurrentValue = useStableCallback(options.getCurrentValue);
    useLayoutEffect(() => {
        if (skipInitialEffect.current) {
            skipInitialEffect.current = false;
            return;
        }
        const value = getCurrentValue();
        setKey(uuid());
        setInitialValue(value);
    }, [getCurrentValue, isPortrait]);
    return { key, initialValue };
}
