import { SECONDARY_SCHOOL_FIRST_CLASS } from 'common.components/user/user.constants';

export function isPrimarySchool(grade: number | null) {
    return Boolean(grade && grade < SECONDARY_SCHOOL_FIRST_CLASS);
}

/** Узнать является ли грейд СПОшным */
export function getIsCollegeTeamUtil(grade?: number | null) {
    return grade === 99;
}
