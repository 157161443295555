import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const Video = ({ className, ...props }) => (
    <video className={cx(className, 'resource__video')} {...props}>
        <source src={props.file} type={`video/${props.type}`} />
    </video>
);

Video.propTypes = {
    controls: PropTypes.bool,
    className: PropTypes.string,
    file: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Video.defaultProps = {
    controls: true,
    width: 650,
};

export default Video;
