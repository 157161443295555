import { withBemMod } from '@bem-react/core';
import { cnButton } from '@yandex-lego/components/Button/desktop';

import './Button_view_brand.css';

export type WithViewBrandProps = {
    view?: 'brand';
};

export const withViewBrand = withBemMod<WithViewBrandProps>(cnButton(), {
    view: 'brand',
});
