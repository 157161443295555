import type { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
//@ts-ignore
import { AVATAR_HOST, ROLES } from '@yandex-int/k-common/constants';
import type { Me, ParentCode, SenderProfile, StudentProfile } from '@yandex-int/k-common/typings';
import get from 'lodash/get';
import type { WrappedComponentProps } from 'react-intl';
import { createSelector } from 'reselect';

import { STUDENTS_WITH_REGENERATED_PINS_FIELD_NAME, HINTS, VARIABLES, TEAMS, STUDENTS } from './user.constants';

export interface UserState {
    me: Me | null;
    /** Был ли вообще задан пользователь (на некоторых страницах пользователь не нужен) */
    isMeSet: boolean;
    locality?: string;
    parentCode?: ParentCode;
    addViewedHintLoadingStatus: LOADING_STATUSES | null;
    studentProfile: StudentProfile | null;
}

export interface UserStateWithSenderProfile extends UserState {
    senderProfile: SenderProfile | null;
}

export interface StoreWithUserState {
    user: UserState;
}

export const userStateSelector = <T extends StoreWithUserState>(storeState: T) => get(storeState, 'user');
const intlSelector = <T extends StoreWithUserState>(storeState: T, ownProps: WrappedComponentProps) =>
    get(ownProps, 'intl');

/**
 * Selectors Prop Types
 *
 * @typedef PropsWithHintId
 * @property {Number} hintId
 *
 * @typedef PropsWithIntl
 * @property {Object} intl
 */

export const getMe = createSelector(userStateSelector, (state) => state?.me);
export const getStudentProfile = createSelector(userStateSelector, (state) => state?.studentProfile);

export const getParentCode = createSelector(userStateSelector, (state) => state?.parentCode);

/**
 * @param {Object} storeState
 */
export const getAddViewedHintLoadingStatus = createSelector(userStateSelector, (state) =>
    get(state, 'addViewedHintLoadingStatus')
);

/**
 * @param {Object} storeState
 */
export const getIsAuthenticated = createSelector(getMe, (me) => !!me?.id);

/**
 * @param {Object} storeState
 * @return Boolean
 */
export const getIsContentManager = createSelector(getMe, (me) => Boolean(get(me, 'is_content_manager')));

/**
 * @param {Object} storeState
 */
export const getIsTeacher = createSelector(getMe, (me) => Boolean(get(me, 'is_teacher')));

/**
 * @param {Object} storeState
 */
export const getIsParent = createSelector(getMe, (me) => Boolean(get(me, 'is_parent')));

/**
 * @param {Object} storeState
 */
export const getIsStudent = createSelector(
    [getIsContentManager, getIsTeacher, getIsParent],
    (isContentManager, isTeacher, isParent) => !(isContentManager || isParent || isTeacher)
);

/**
 * @param {Object} storeState
 */
export const getCurrentRole = createSelector(
    [getIsContentManager, getIsParent, getIsTeacher],
    (isCM, isParent, isTeacher) => {
        if (isCM || isTeacher) {
            return ROLES.TEACHER;
        }
        if (isParent) {
            return ROLES.PARENT;
        }
        return ROLES.STUDENT;
    }
);

/**
 * @param {Object} storeState
 */
export const getFirstName = createSelector(getMe, (me) => get(me, 'yauser.first_name'));

/**
 * @param {Object} storeState
 */
export const getUserId = createSelector(getMe, (me) => get(me, 'id'));

/**
 * @param {Object} storeState
 */
export const getHints = createSelector(getMe, (me) => get(me, 'viewed_hints'));

export const getCertificates = createSelector(getHints, (hints) => hints?.certificates || {});

export const getCertificateSuggestions = createSelector(
    getCertificates,
    // eslint-disable-next-line camelcase
    (certificates) => certificates?.certificate_suggestions || []
);

export const getCertificateExpandedSuggestions = createSelector(
    getCertificates,
    // eslint-disable-next-line camelcase
    (certificates) => ({ ...certificates?.training_course_suggestions, ...certificates?.hr_reserve_suggestions })
);

/**
 * @param {Object} storeState
 */
export const getStudentsWithRegeneratedPins = createSelector(getHints, (hints) =>
    get(hints, STUDENTS_WITH_REGENERATED_PINS_FIELD_NAME, [])
);

export const getWizard = createSelector(getMe, (me) => get(me, 'viewed_hints.wizard'));

/**
 * @param {Object} storeState
 */
export const getWizardHints = createSelector([getWizard], (wizard) => get(wizard, HINTS));

/**
 * @param {Object} storeState
 */
export const getWizardVariables = createSelector([getWizard], (wizard) => get(wizard, VARIABLES));

/**
 * @param {Object} storeState
 */
export const getOlympResults = createSelector(getMe, (me) => get(me, 'viewed_hints.olymp_winter_2019_results_seen'));

/**
 * @param {Object} storeState
 */
export const getOlympResultsTeams = createSelector(getOlympResults, (olympResults) => get(olympResults, TEAMS));

/**
 * @param {Object} storeState
 */
export const getOlympResultsStudents = createSelector(getOlympResults, (olympResults) => get(olympResults, STUDENTS));

/**
 * @param {Object} storeState
 */
export const getIsWizardEnabled = createSelector(getWizard, (wizard) =>
    Boolean(get(wizard, [VARIABLES, 'enabled'], true))
);

/**
 * @param {Object} storeState
 */
export const getAccessSplashscreen = createSelector(getMe, (me) =>
    Boolean(get(me, 'viewed_hints.new_student_authorization_splashscreen_shown', true))
);

/**
 * @param {Object} storeState
 * @return {Object}
 */
export const getSenderProfile = <T extends { user: UserStateWithSenderProfile }>(
    storeState: T
): SenderProfile | null => {
    return storeState.user?.senderProfile || null;
};

/**
 * @param {Object} storeState
 * @param {PropsWithIntl} ownProps
 * @return {Boolean}
 */
export const getShouldShowEmailModal = createSelector([getSenderProfile, intlSelector], (profile, intl) =>
    Boolean(
        intl &&
            intl.formatMessage({ id: 'teacherLab.emailModal.disabled' }) !== '1' &&
            (!profile || get(profile, 'should_show_email_modal', false))
    )
);

/**
 * @param {Object} storeState
 * @return {String}
 */
export const getFavoriteEmail = createSelector(getSenderProfile, (profile) => get(profile, 'email', ''));

/**
 * @param {Object} storeState
 * @return Boolean
 */
export const getIsFavoriteEmailApproved = createSelector(getSenderProfile, (profile) =>
    get(profile, 'is_email_approved', false)
);

/**
 * @param {Object} storeState
 * @param {PropsWithIntl} ownProps
 * @return {Boolean}
 */
export const getShouldShowWizard = createSelector(
    [getIsWizardEnabled, getShouldShowEmailModal],
    (enabled, shouldShowEmailModal) => enabled && !shouldShowEmailModal
);

/**
 * @param {Object} storeState
 *
 * @return {string}
 */
export const getDisplayName = createSelector(getMe, (me) => {
    if (!me /* anonymous user */) {
        return '';
    }

    if (me.yauser /* userWithYandexPassport */) {
        return me.yauser.display_name || null;
    }

    return me.public_name || null;
});

export const getUserAvatarUrl = createSelector(getMe, (me) => {
    const avatarId = get(me, 'yauser.default_avatar_id', '0/0-0');
    return `${AVATAR_HOST}/get-yapic/${avatarId}/islands-200`;
});

/**
 * @param {Object} storeState
 *
 * @return {string}
 */
export const getLastName = createSelector(getMe, (me) => get(me, 'yauser.last_name'));

export const getMiddleName = createSelector(getMe, (me) => get(me, 'middle_name'));

export const getFullName = createSelector(
    [getFirstName, getMiddleName, getLastName],
    (firstName, middleName, lastName) => `${lastName || ''} ${firstName || ''} ${middleName || ''}`.trim()
);

/**
 * @param {Object} storeState
 *
 * @return {string}
 */
export const getUserEmail = createSelector(getMe, (me) => me?.email || null);

/**
 * @param {Object} storeState
 */
export const getStudentTeams = createSelector(getMe, (me) => get(me, 'student_teams', []));

/**
 * @param {Object} storeState
 */
export const getIsPersonalDataAccepted = createSelector(getMe, (me) => get(me, 'is_personal_data_accepted', false));

/**
 * @param {Object} storeState
 */
export const getIsTosAccepted = createSelector(getMe, (me) => get(me, 'is_tos_accepted', false));

/**
 * @param {Object} storeState
 *
 * @return {boolean}
 */
export const getMeHasTeacher = createSelector(getMe, (me) => get(me, 'has_teacher', false));

export const getMyGrades = createSelector(getMe, (me) => get(me, 'grades', [] as number[]));

export const getMyGrade = createSelector([getIsStudent, getMe, getStudentProfile], (isStudent, me, studentProfile) => {
    if (!isStudent) {
        return null;
    }

    const grades: number[] = get(me, 'grades', []);

    if (grades.length > 0) {
        return grades[0];
    }

    if (studentProfile?.grade !== undefined) {
        return studentProfile?.grade;
    }

    const grade: number | null = get(me, 'student_profile.grade', null);

    return grade;
});

export const getHintViewedCount = createSelector(
    getWizardHints,
    (hints) => (id: string) => hints?.[id]?.viewedCount || 0
);
