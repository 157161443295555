import { Button, H3, SimpleCallback, SimpleText, Spin, useIntl } from 'platform-components';
import { TEXT_WEIGHT, HEADER_TEXT_SIZE } from 'platform-components/constants';
import React, { useCallback, useRef } from 'react';
import { useMount, useUnmount } from 'react-use';
import ym from 'react-yandex-metrika';

// eslint-disable-next-line max-len
import { StudentsPrintInvitationCard } from 'common.components/students-print/__invitation-card/students-print__invitation-card';
import { BaseStudent } from 'common.components/typings';
import { useRegistrationLinkMetrika } from 'common.components/user/hooks';

import './logins-form__content.scss';
import './logins-form__content.print.scss';

interface Props {
    studentsWereAdded: boolean;
    students: Array<BaseStudent> | null;
    schoolCode: string | null;
    classId: number | null;
    onPrint: SimpleCallback;
}

export const LoginsFormContent = ({ students, schoolCode, classId, onPrint, studentsWereAdded }: Props) => {
    const intl = useIntl();
    const isPrinted = useRef(false);
    const { ymTeacherStudentsLoginsModal } = useRegistrationLinkMetrika();

    useMount(() => ymTeacherStudentsLoginsModal('view'));
    useUnmount(() => ymTeacherStudentsLoginsModal(isPrinted.current ? 'print' : 'close'));

    const print = useCallback(() => {
        const classLength = students && students.length;

        if (!classId) {
            return;
        }

        isPrinted.current = true;

        ym('reachGoal', 'print_invitation', { studentsCount: classLength, registration: true });
        ym('reachGoal', 'teacher_olymp_students_logins_modal', { action: 'print' });

        window.print();
        onPrint();
    }, [students, classId, onPrint]);

    const isLoading = students && students.length === 0;

    return (
        <div className="logins-form-content">
            {isLoading ? (
                <Spin size={Spin.SIZE.L} />
            ) : (
                <>
                    <div className="logins-form-content__wrapper">
                        <H3 size={HEADER_TEXT_SIZE.L} weight={TEXT_WEIGHT.L}>
                            {intl.formatMessage({
                                id: `loginsFormContent.title.${studentsWereAdded ? 'added' : 'created'}`,
                            })}
                        </H3>
                        <div className="logins-form-content__cards">
                            {students &&
                                students.map((student) => (
                                    <StudentsPrintInvitationCard
                                        key={student.id}
                                        schoolCode={student.code || schoolCode}
                                        shouldShowParentInvitation={true}
                                        student={student}
                                    />
                                ))}
                        </div>
                    </div>
                    <div className="logins-form-content__btn-section">
                        <Button className="logins-form-content__print-button" onClick={print}>
                            <SimpleText id="class.print" />
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
};
