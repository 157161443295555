import { PostCreateChildParams } from '../../../typings/api/defs/post-create-child';
import { postFetch } from '../fetch';

import { apiConfig } from '../config';

export function postCreateChild(params: PostCreateChildParams): Promise<number> {
    const { name, grade } = params;
    const { apiPostCreateChild } = apiConfig.getStaticUrls();

    return postFetch(apiPostCreateChild, {
        full_name: name,
        student_profile: { grade },
    });
}
