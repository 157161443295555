import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import produce from 'immer';

import * as constants from './constants';
import { SalesCodeModalStoreState, SalesCodeModalAction } from './typings';

const initialState: SalesCodeModalStoreState = {
    salesCode: null,
    shouldShowModal: false,
    loadingStatus: LOADING_STATUSES.UNSENT,
};

const salesModalReducer = (state: SalesCodeModalStoreState = initialState, action: SalesCodeModalAction) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case constants.SHOULD_SHOW_MODAL:
                draft.shouldShowModal = action.showShowModal;
                break;

            case constants.SET_SALES_CODE:
                draft.salesCode = action.salesCode;
                break;

            default:
                break;
        }
    });
};

export default salesModalReducer;
