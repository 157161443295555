import { StoreWithExperiments } from './typings';

export const getExperimentFlags = <T extends StoreWithExperiments>(storeState: T): object | null =>
    storeState.experimentData.experimentFlags || null;

export const getExperimentTestId = <T extends StoreWithExperiments>(storeState: T): string | null =>
    storeState.experimentData.experimentTestId || null;

export const getExperimentTestIds = <T extends StoreWithExperiments>(storeState: T): number[] =>
    storeState.experimentData.experimentTestIds || [];
