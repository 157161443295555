const get = require('lodash/get');
const { BUNKER_DEFAULT_VALUES } = require('../../constants');

function getBunkerNode(data, name, logger = console.error, expFlags) {
    const nodesConfig = get(data, 'config.bunker.nodes', {});
    const expNodes = get(nodesConfig, `${name}.exp.nodes`, null);

    // If there are experimental versions of the node,
    // select the first appropriate one according to the experimental flags
    if (expFlags && expNodes) {
        for (const expNodeName of expNodes) {
            const isThereDataInExpNode = Boolean(data[expNodeName]);

            if (!isThereDataInExpNode) {
                continue;
            }

            const expNodeFlags = get(
                nodesConfig,
                `${expNodeName}.exp.flags`,
                {}
            );

            const isEveryExpNodeFlagInExpFlags = Object
                .entries(expNodeFlags)
                .every(
                    ([ flag, value ]) =>
                        expFlags[flag] &&
                        expFlags[flag] === value
                );

            if (isEveryExpNodeFlagInExpFlags) {
                return data[expNodeName];
            }
        }
    }

    if (!data[name]) {
        if (BUNKER_DEFAULT_VALUES[name]) {
            logger(`[BUNKER] Node ${name} not found, using default`);

            return BUNKER_DEFAULT_VALUES[name];
        }

        logger(`[BUNKER] Default value for node ${name} not found`);

        return null;
    }

    return data[name];
}

module.exports = {
    getBunkerNode
};

