import b from 'b_';
import PropTypes from 'prop-types';
import React from 'react';

import AnimatedMountUnmount from '../animated-mount-unmount/animated-mount-unmount';
import { GRAVITIES } from '../constants';
import PageNotification from '../notification/page-notification';

import './animated-notification.scss';

const AnimatedNotification = ({ gravity, staticProps, visible }) => (
    <AnimatedMountUnmount
        mix={b('animated-notification', { gravity })}
        name="animated-notification"
        staticProps={staticProps}
        unmountAfterAnimation={`animated-notification-hide_gravity_${gravity}`}
        visible={visible}
    >
        <PageNotification />
    </AnimatedMountUnmount>
);

AnimatedNotification.propTypes = {
    children: PropTypes.node,
    gravity: PropTypes.oneOf(Object.values(GRAVITIES)),
    staticProps: PropTypes.object,
    visible: PropTypes.bool,
};

AnimatedNotification.defaultProps = {
    gravity: GRAVITIES.TOP,
};

export default AnimatedNotification;
