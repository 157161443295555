import React from 'react';

import { ExternalLink } from '../external-link/external-link';
import Text from '../text/text';

import { personalAgreementLinks, PersonalAgreementLink } from './linksData';

interface Props {
    buttonText: string;
    linksConfig: PersonalAgreementLink[];
}

export const PersonalAgreement = ({ buttonText, linksConfig }: Props) => {
    const linksCount = linksConfig.length;
    let linksBatch;

    if (linksCount === 1) {
        linksBatch = <ExternalLink linkId={linksConfig[0].linkId} textId={linksConfig[0].textId} />;
    } else if (linksCount === 2) {
        linksBatch = (
            <>
                <ExternalLink linkId={linksConfig[0].linkId} textId={linksConfig[0].textId} />
                <Text id="common.personAgreement.ampersand" size={Text.SIZE.L} />
                <ExternalLink linkId={linksConfig[1].linkId} textId={linksConfig[1].textId} />
            </>
        );
    } else if (linksCount >= 3) {
        const tailLink = linksConfig[linksCount - 1];
        linksConfig.pop();

        const linksByComa = linksConfig.map((link) => {
            return (
                <>
                    <ExternalLink linkId={link.linkId} textId={link.textId} />
                    <Text id="common.personAgreement.comma" size={Text.SIZE.L} />
                </>
            );
        });

        linksBatch = (
            <>
                {linksByComa}
                <Text id="common.personAgreement.ampersand" size={Text.SIZE.L} />
                <ExternalLink linkId={tailLink.linkId} textId={tailLink.textId} />
            </>
        );
    }

    return (
        <>
            <Text id="common.personAgreement.defaultDescription" size={Text.SIZE.L} values={{ button: buttonText }} />
            {linksBatch}
        </>
    );
};

PersonalAgreement.personalAgreementLinks = personalAgreementLinks;
