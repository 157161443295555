import { AttributesType } from '../../../typings';
import { postFetch } from '../fetch';
import { apiConfig } from '../config';

interface AddLessonToCourseRequest {
    courseId: number,
    lessonId: number,
    themeId?: number,
    moduleId?: number,
    containerSlug: string | null,
    attributes?: AttributesType,
}

export function addLessonToCourse(request: AddLessonToCourseRequest): Promise<{ clesson_id: number }> {
    const { courseId, lessonId, themeId, moduleId, containerSlug, attributes } = request;
    const { apiPutAddCLessonV2 } = apiConfig.getStaticUrls();
    const secretKey = apiConfig.getSecretKey();

    return postFetch(apiPutAddCLessonV2, {
        courseId,
        lessonId,
        themeId,
        moduleId,
        containerSlug,
        attributes,
        sk: secretKey,
    });
}
