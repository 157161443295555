import cx from 'classnames';
import React from 'react';

import './background-image-container.scss';

interface BackgroundImageContainerProps extends Omit<React.HTMLProps<HTMLDivElement>, 'className'> {
    mix?: string;
    children: React.ReactNode;
}

export const BackgroundImageContainer = (props: BackgroundImageContainerProps) => {
    const { mix, children, ...restProps } = props;

    return (
        <div {...restProps} className={cx('background-image-container', mix)}>
            {children}
        </div>
    );
};
