/* eslint-disable react/no-multi-comp */
import b from 'b_';
import cx from 'classnames';
import isNil from 'lodash/isNil';
import throttle from 'lodash/throttle';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { IntlProvider, useIntl } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';

import { getPrefix } from '../../../utils/get-prefix';
import { getHeight } from '../../../utils/style-properties/height';
import { getOffset } from '../../../utils/style-properties/offset';
import { getScrollTop } from '../../../utils/style-properties/scroll-top';
import { POPUP_AXIS, POPUP_INDENTS, POPUP_POSITIONS } from '../../constants';
import Popup from '../../popup/popup';
import { SELECT_THEMES } from '../constants';
import { heightValidate } from '../utils';

import './select__popup.scss';

const BORDER_WINDOW_OFFSET = 40;
const THROTTLE_WAIT = 300;

/*
 * Попап для селекта, которй скрывает всю логику рендеринга
 */
class SelectPopup extends React.Component {
    static propTypes = {
        disabled: PropTypes.bool,
        inLayer: PropTypes.bool,
        children: PropTypes.node,
        direction: PropTypes.string,
        minWidth: PropTypes.string,
        optionHeight: PropTypes.number,
        height: heightValidate,
        theme: PropTypes.string,
        popupSizeCallback: PropTypes.func.isRequired,
        popupHeight: PropTypes.number,
        popupTheme: PropTypes.string,
        indent: PropTypes.oneOf(Object.values(POPUP_INDENTS)),
        popupRef: PropTypes.func,
        popupPosition: PropTypes.oneOf(Object.values(POPUP_POSITIONS)),
        popupMix: PropTypes.string,
        popupMinWidth: PropTypes.string,
        popupMaxWidth: PropTypes.string,
        popupAxis: PropTypes.oneOf(Object.values(POPUP_AXIS)),
        selectedOption: PropTypes.object,
        style: PropTypes.object,
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        control: PropTypes.object,
        intl: PropTypes.object.isRequired,
    };

    state = {
        maxHeight: null,
    };

    componentDidMount() {
        if (this.props.popupPosition === POPUP_POSITIONS.PORTAL_LAYER) {
            this.createPopupPortalLayer();
        } else {
            this.removePopupPortalLayer();
        }

        window.addEventListener('resize', this.resizeHandler, { passive: true });
        window.addEventListener('scroll', this.scrollHandler, { passive: true, capture: true });

        if (this.shouldCalculateWidth()) {
            this.calculateRealPopupWidth();
        }
    }

    componentDidUpdate(prevProps) {
        const { popupPosition, disabled } = this.props;
        const { disabled: prevDisabled } = prevProps;

        // при задизейбленном инпуте не надо рисовать попап,
        // порождает баги типа https://st.yandex-team.ru/EDUCATION-6670
        if (popupPosition === POPUP_POSITIONS.PORTAL_LAYER) {
            // попап был, но теперь компонент задизейблен
            const shouldDeletePopup = disabled && !prevDisabled;

            if (shouldDeletePopup) {
                this.removePopupPortalLayer();
            }
        }
    }

    componentWillUnmount() {
        this.removePopupPortalLayer();
        window.removeEventListener('resize', this.resizeHandler, { passive: true });
        window.removeEventListener('scroll', this.scrollHandler, { passive: true, capture: true });
    }

    needToOpen = false;

    calculateRealPopupWidth = () => {
        // тут мы отрендерим в специальный контейнер и замереем ширину
        // контейнер не сможет тянуться и навредить странице
        const {
            direction,
            inLayer,
            minWidth,
            popupTheme,
            popupMix,
            popupPosition,
            popupSizeCallback,
            popupMinWidth,
            popupMaxWidth,
            popupAxis,
            intl,
        } = this.props;

        const { maxHeight } = this.state;

        let targetDomNode = document.createElement('div');

        const portalClass = popupPosition === POPUP_POSITIONS.PORTAL_LAYER ? 'popup-portal-layer' : null;

        targetDomNode.className = cx(portalClass, 'select-popup-test-container');

        const layerParent = document.querySelector('.page');
        layerParent.appendChild(targetDomNode);

        // Так как тут мы отсоединяемся от общего реакт-рута, все общие обертки недолетают до сюда и надо оборачивать
        // отдельно, если обертка требуется. В идеале переписать все на попап из лего, так как ему такие костыли не нужны
        //
        // 1. MemoryRouter - если внутри рисуется компонент с подключением react-router то мы упадем, так как отсутствует
        //    роутерный контекст, добавляем заглушку роутера, так как это место измеряет размер и реальные урлы для его
        //    измерения не нужны
        //
        // 2. IntlProvider - если внутри рисуется что-то использующее react-intl упадем из-за его отсутствия
        //
        let popup = (
            <BrowserRouter prefix={getPrefix()}>
                <IntlProvider {...intl}>
                    <Popup
                        axis={popupAxis || POPUP_AXIS.START}
                        direction={direction}
                        inLayer={inLayer}
                        maxHeight={maxHeight}
                        maxWidth={popupMaxWidth}
                        minWidth={popupMinWidth || minWidth}
                        mix={popupMix}
                        shown={true}
                        theme={popupTheme}
                    >
                        {this.props.children}
                    </Popup>
                </IntlProvider>
            </BrowserRouter>
        );

        const rootRender = createRoot(targetDomNode);
        rootRender.render(popup);

        // аналог старого апи ReactDOM.render(element, container[, callback])
        // вызов колбека после рендера элемента
        setTimeout(() => {
            const portalLayerSelector = portalClass ? `.${portalClass}` : '';
            let domNode = document.querySelector(`${portalLayerSelector}.select-popup-test-container .popup`);

            if (!isNil(domNode) && !isNil(popupSizeCallback)) {
                const { clientWidth, scrollWidth } = domNode;

                popupSizeCallback({ clientWidth, scrollWidth });
            }

            rootRender.unmount();

            // Вместо targetDomNode.remove() для поддержки IE
            if (targetDomNode.parentNode) {
                targetDomNode.parentNode.removeChild(targetDomNode);
            }
        });
    };

    createPopupPortalLayer = () => {
        if (this.props.disabled === true) {
            return;
        }

        this.portalLayer = document.createElement('div');
        // так как только на .page есть флаг with-media то он должен быть родителем, иначе не применяются медиа-правила
        const layerParent = document.querySelector('.page');

        if (isNil(layerParent)) {
            this.portalLayer = null;
            return;
        }

        layerParent.appendChild(this.portalLayer);
        this.portalLayer.className = 'popup-portal-layer';
    };

    removePopupPortalLayer = () => {
        if (this.portalLayer) {
            this.portalLayer.parentNode.removeChild(this.portalLayer);
            this.portalLayer = null;
        }
    };

    getPopupOffsetControl = () => {
        const { optionHeight } = this.props;

        return optionHeight && -optionHeight;
    };

    /**
     * при наличии достаточного пространства снизу — открываем попап снизу
     * иначе, при наличии достаточного пространства сверху — открываем попап сверху
     * иначе(нигде нет пространства), открываем попап снизу
     *
     * @returns {null|number}
     */
    getPopupOffsetPortalLayer = () => {
        const { control, indent } = this.props;

        const popupNode = ReactDOM.findDOMNode(this.selectPopup);
        const popupHeight = popupNode ? popupNode.offsetHeight : 0;

        if (!(popupHeight && control)) {
            return null;
        }

        const controlTop = getOffset(control).top;
        const controlHeight = getHeight(control);

        const popupBottom = controlTop + controlHeight + popupHeight + indent;
        const windowBottom = window.scrollY + window.innerHeight;
        const enoughSpaceAtBottom = popupBottom <= windowBottom;

        const popupTop = controlTop - popupHeight - indent;
        const enoughSpaceAtTop = popupTop >= window.scrollY;

        if (enoughSpaceAtBottom || !enoughSpaceAtTop) {
            // popup at the bottom
            return controlTop;
        }

        // popup at the top
        return controlTop - controlHeight - popupHeight - indent * 2;
    };

    getPopupOffsetTop = () => {
        if (!this.control) {
            return null;
        }

        return getHeight(this.control);
    };

    getPopupOffset = () => {
        const { popupPosition } = this.props;

        switch (popupPosition) {
            case POPUP_POSITIONS.CONTROL:
                return this.getPopupOffsetControl();
            case POPUP_POSITIONS.PORTAL_LAYER:
                return this.getPopupOffsetPortalLayer();
            case POPUP_POSITIONS.TOP:
                return this.getPopupOffsetTop();
            // Если не указано нестандартное позиционирование попапа, то ничего не делаем
            default:
                return null;
        }
    };

    openPopup = () => {
        // для того чтобы правильно расчитать макс. высоту
        // нужно иметь попап в раскрытом виде, после расчета
        // высоты нужно дождаться обновления DOM
        if (!isNil(this.selectPopup)) {
            this.selectPopup.setOwner(this.props.control).show(() => {
                // нужно сделать update чтобы попап не дергался при первом открытии
                this.selectPopup
                    .updatePositions()
                    // без setTimeout скроллится только при втором открытии
                    .then(() => this.setMaxHeight(() => setTimeout(this.scrollToSelected, 0)));
            });

            this.needToOpen = false;
        } else {
            // бывает, то к моменту открытия рефа на попап еще нет
            // оставляем флаг, чтобы открыть попап когда реф появится
            this.needToOpen = true;
        }
    };

    closePopup = () => {
        if (!isNil(this.selectPopup)) {
            this.selectPopup.setOwner(this.props.control).hide();
        }
    };

    setMaxHeight = (callback) => {
        let maxHeight;
        const { height, popupPosition } = this.props;

        if (popupPosition === POPUP_POSITIONS.PORTAL_LAYER) {
            maxHeight = window.innerHeight;
        } else if (height === 'adaptive') {
            const selectPopupNode = this.selectPopup?.popup;
            if (isNil(selectPopupNode)) {
                return;
            }
            const popupOffset = getOffset(selectPopupNode);

            const offsetTop = popupOffset.top;
            const offsetFromWindowTop = offsetTop - getScrollTop();
            maxHeight = window.innerHeight - offsetFromWindowTop - BORDER_WINDOW_OFFSET;
        } else {
            maxHeight = Number(this.props.height) * this.props.optionHeight;
        }
        this.setState({ maxHeight }, callback ? callback : undefined);
    };

    setPopupRef = (ref) => {
        const { popupRef } = this.props;

        if (!isNil(popupRef)) {
            popupRef(ref);
        }

        this.selectPopup = ref;
        this.setMaxHeight();

        if (this.needToOpen) {
            this.openPopup();
        }
    };

    shouldCalculateWidth = () => {
        return this.props.theme !== SELECT_THEMES.LINK;
    };

    scrollToSelected = () => {
        const { selectedOption } = this.props;

        if (selectedOption && this.selectPopup) {
            const popupDOM = this.selectPopup?.popup;
            if (!popupDOM) {
                return;
            }
            const selectedRect = selectedOption.getBoundingClientRect();
            const selectedHalfHeight = (selectedRect.bottom - selectedRect.top) / 2;
            const shiftToCenter = popupDOM.clientHeight / 2 - selectedHalfHeight;
            const popupRect = popupDOM.getBoundingClientRect();
            // граница видимой области меню
            const popupVisibleBottom = popupRect.top + popupDOM.clientHeight;

            // если выбранная option не в видимой зоне popup -> скроллим, чтобы она оказалась по возможности в центре
            if (selectedRect.top < popupRect.top) {
                popupDOM.scrollTop -= popupRect.top - selectedRect.top + shiftToCenter;
            }

            if (selectedRect.bottom > popupVisibleBottom) {
                popupDOM.scrollTop += selectedRect.bottom - popupVisibleBottom + shiftToCenter;
            }
        }
    };

    updateMaxHeight = () => {
        const { height, popupPosition } = this.props;

        if (height === 'adaptive' || popupPosition === POPUP_POSITIONS.PORTAL_LAYER) {
            this.setMaxHeight();
        }
    };

    resizeHandler = throttle(() => {
        this.updateMaxHeight();

        if (this.shouldCalculateWidth()) {
            this.calculateRealPopupWidth();
        }
    }, THROTTLE_WAIT);

    scrollHandler = throttle(() => {
        this.updateMaxHeight();
    }, THROTTLE_WAIT);

    renderPopup() {
        const {
            direction,
            inLayer,
            minWidth,
            theme,
            popupMix,
            popupPosition,
            popupTheme,
            width,
            control,
            indent,
            popupMinWidth,
            popupMaxWidth,
            popupAxis,
        } = this.props;

        const { maxHeight } = this.state;

        return (
            <Popup
                axis={popupAxis || POPUP_AXIS.START}
                direction={direction}
                inLayer={inLayer}
                indent={indent}
                maxHeight={maxHeight}
                maxWidth={popupMaxWidth}
                minWidth={popupMinWidth || minWidth}
                mix={cx(popupMix, b('select__popup', { theme }))}
                ref={this.setPopupRef}
                style={{
                    marginTop: this.getPopupOffset(),
                    marginLeft: popupPosition === POPUP_POSITIONS.PORTAL_LAYER ? getOffset(control).left : 0,
                }}
                theme={popupTheme}
                width={width}
            >
                {this.props.children}
            </Popup>
        );
    }

    isReadyToRender = () => {
        const { control, disabled } = this.props;

        // если control и не disabled - можно рендерить
        return !isNil(control) && !disabled;
    };

    render() {
        if (!this.isReadyToRender()) {
            return null;
        }

        if (this.props.popupPosition === POPUP_POSITIONS.PORTAL_LAYER) {
            if (isNil(this.portalLayer)) {
                // нужно именно в рендере, чтобы не порождать лишних обновлений
                this.createPopupPortalLayer();
            }

            return ReactDOM.createPortal(this.renderPopup(), this.portalLayer);
        }

        return this.renderPopup();
    }
}

export default forwardRef((props, ref) => {
    const intl = useIntl();

    return <SelectPopup {...props} intl={intl} ref={ref} />;
});
