const RUS_LAYOUT = [
    'й',
    'ц',
    'у',
    'к',
    'е',
    'н',
    'г',
    'ш',
    'щ',
    'з',
    'х',
    'ъ',
    'ф',
    'ы',
    'в',
    'а',
    'п',
    'р',
    'о',
    'л',
    'д',
    'ж',
    'э',
    'ё',
    'я',
    'ч',
    'с',
    'м',
    'и',
    'т',
    'ь',
    'б',
    'ю',
    'ё',
    'ж',
    'э',
    'б',
    'ю',
    'х',
    'ъ',
    'ё',
    'ё',
];
const ENG_LAYOUT = [
    'q',
    'w',
    'e',
    'r',
    't',
    'y',
    'u',
    'i',
    'o',
    'p',
    null,
    null,
    'a',
    's',
    'd',
    'f',
    'g',
    'h',
    'j',
    'k',
    'l',
    null,
    null,
    null,
    'z',
    'x',
    'c',
    'v',
    'b',
    'n',
    'm',
    null,
    null,
];
const ENG_LAYOUT_EXTRA = [
    'q',
    'w',
    'e',
    'r',
    't',
    'y',
    'u',
    'i',
    'o',
    'p',
    '[',
    ']',
    'a',
    's',
    'd',
    'f',
    'g',
    'h',
    'j',
    'k',
    'l',
    ';',
    "'",
    '\\',
    'z',
    'x',
    'c',
    'v',
    'b',
    'n',
    'm',
    ',',
    '.',
    '~',
    ':',
    '"',
    '<',
    '>',
    '{',
    '}',
    '|',
    '`',
];

const getLayoutMap = (sourceLayout: string[], targetLayout: (string | null)[], includeLowerCase = true) => {
    return sourceLayout.reduce((state, value, index) => {
        const char = targetLayout[index];

        const resultMap: Record<string, string | null> = {
            ...state,
            [value.toUpperCase()]: char && char.toUpperCase(),
        };

        if (includeLowerCase) {
            resultMap[value] = char;
        }

        return resultMap;
    }, {});
};

export const RUS_TO_ENG_LAYOUT_MAP = getLayoutMap(RUS_LAYOUT, ENG_LAYOUT);
export const RUS_TO_ENG_LAYOUT_EXTRA_MAP = getLayoutMap(RUS_LAYOUT, ENG_LAYOUT_EXTRA);
export const ENG_TO_RUS_LAYOUT_MAP = getLayoutMap(ENG_LAYOUT_EXTRA, RUS_LAYOUT, false);
