import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import sagaMiddlewareFactory from 'redux-saga';
import thunkMiddleware from 'redux-thunk';

import { reduxDevTools } from '../dev-tools';

function experimentData(state = {}) {
    return state;
}

function pixelStore(state = {}) {
    return state;
}
export default function (initialReducers, rootSaga, initialState = {}) {
    const reducers = Object.assign({ experimentData, pixelStore }, initialReducers);

    const sagaMiddleware = sagaMiddlewareFactory();

    const store = createStore(
        combineReducers(reducers),
        initialState,
        compose(applyMiddleware(thunkMiddleware, sagaMiddleware), reduxDevTools())
    );

    if (rootSaga) {
        sagaMiddleware.run(rootSaga);
    }

    store.addReducers = function (newReducers) {
        Object.assign(reducers, newReducers);
        store.replaceReducer(combineReducers(reducers));
    };

    return store;
}
