import PropTypes from 'prop-types';
import React from 'react';
import ym from 'react-yandex-metrika';

import AdultColumn from './__adult';
import StudentColumn from './__student';

import './start-screen.scss';

class StartScreen extends React.Component {
    static propTypes = {
        registerAdult: PropTypes.func.isRequired,
    };

    onAdultClick = () => {
        const { registerAdult } = this.props;

        ym('reachGoal', 'main_enter');

        registerAdult();
    };

    render() {
        return (
            <div className="start-screen">
                <AdultColumn mix="start-screen__column" onAdultClick={this.onAdultClick} />
                <StudentColumn mix="start-screen__column" onAdultClick={this.onAdultClick} />
            </div>
        );
    }
}

export default StartScreen;
