import { compose, composeU } from '@bem-react/core';
import { logClick, withBaobabPure } from '@edu-frontend/baobab';
import { LogNode } from '@edu-frontend/baobab/src/types';
import { cnButton } from '@yandex-lego/components/Button';
import {
    Button as ButtonDesktop,
    IButtonProps,
    withPinCircleCircle,
    withSizeS,
    withSizeM,
    withSizeL,
    withTypeLink,
    withViewAction,
    withViewRaised,
    withViewClear,
    withViewPseudo,
} from '@yandex-lego/components/Button/desktop/';
import cx from 'classnames';
import React, { ReactElement } from 'react';

import { withAccentDanger } from './_accent/button_accent_danger';
import { withAccentNormal } from './_accent/button_accent_normal';
import { withSizeAdaptive } from './_size/button_size_adaptive';
import { withTransparencyThemeDark } from './_transparency-theme/button_transparency-theme_dark';
import { withTransparencyThemeLight } from './_transparency-theme/button_transparency-theme_light';
import { withViewLite } from './_view/button_view_lite';
import { withViewPlus } from './_view/button_view_plus';
import { withViewSecondary } from './_view/button_view_secondary';
import {
    BUTTON_ACCENT,
    BUTTON_ICON_POSITION,
    BUTTON_SIZE,
    BUTTON_TRANSPARENCY_THEME,
    BUTTON_TYPE,
    BUTTON_VIEW,
} from './types';

import './button.scss';

type OmittedProps = 'icon' | 'iconRight' | 'iconLeft' | 'pin';

export interface ButtonProps extends Omit<IButtonProps, OmittedProps> {
    accent?: BUTTON_ACCENT;
    icon?: ReactElement;
    iconPosition?: BUTTON_ICON_POSITION;
    onMouseEnter?: (event: React.MouseEvent) => void;
    rounded?: boolean;
    size?: BUTTON_SIZE;
    target?: string;
    transparencyTheme?: BUTTON_TRANSPARENCY_THEME;
    type?: BUTTON_TYPE;
    url?: string;
    view?: BUTTON_VIEW;
    logNode?: LogNode;
}

const CustomButton = compose(
    composeU(withSizeS, withSizeM, withSizeL, withSizeAdaptive),
    composeU(
        withViewAction,
        withViewPseudo,
        withViewClear,
        withViewRaised,
        withViewLite,
        withViewPlus,
        withViewSecondary
    ),
    composeU(withAccentDanger, withAccentNormal),
    composeU(withTransparencyThemeDark, withTransparencyThemeLight),
    composeU(withTypeLink),
    withPinCircleCircle
)(ButtonDesktop);

const ButtonWithBaobab = withBaobabPure(
    {
        name: 'Button',
        events: {
            onClick: logClick(),
        },
    },
    CustomButton
);

export const ROUNDED_BUTTON_PIN_VALUE = 'circle-circle';

export const Button = ({
    addonAfter,
    addonBefore,
    size = BUTTON_SIZE.M,
    view = BUTTON_VIEW.ACTION,
    icon,
    iconPosition = BUTTON_ICON_POSITION.LEFT,
    rounded,
    className,
    children,
    ...otherProps
}: ButtonProps): JSX.Element => {
    const buttonIconPosition = children ? iconPosition : undefined;

    const buttonIcon = icon ? (
        <span className={cnButton('Icon2', { position: buttonIconPosition })}>{icon}</span>
    ) : null;

    const newAddonAfter =
        buttonIconPosition === BUTTON_ICON_POSITION.RIGHT ? (
            <>
                {buttonIcon}
                {addonAfter}
            </>
        ) : (
            addonAfter
        );
    const newAddonBefore =
        buttonIconPosition !== BUTTON_ICON_POSITION.RIGHT ? (
            <>
                {addonBefore}
                {buttonIcon}
            </>
        ) : (
            addonBefore
        );

    return (
        <ButtonWithBaobab
            addonAfter={newAddonAfter}
            addonBefore={newAddonBefore}
            children={children}
            className={cx('button', className, cnButton({ 'icon-only': !children }))}
            pin={rounded ? ROUNDED_BUTTON_PIN_VALUE : undefined}
            size={size}
            view={view}
            {...otherProps}
        />
    );
};

Button.SIZE = BUTTON_SIZE;
Button.VIEW = BUTTON_VIEW;
Button.ACCENT = BUTTON_ACCENT;
Button.ICON_POSITION = BUTTON_ICON_POSITION;
Button.TRANSPARENCY_THEME = BUTTON_TRANSPARENCY_THEME;
Button.TYPE = BUTTON_TYPE;
