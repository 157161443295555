export interface Promo {
    promo: string;
    promo_campaign_name: string;
    deprecation_dt: string;
}

export interface StudentCertificate {
    certificate: string;
    user: number;
}

export interface Award {
    award_type: AWARD_TYPE;
    view_details: AwardViewDetails | null;
    slug: string;
    deprecation_dt: string;
}

export interface AwardViewDetails {
    source?: AWARD_SOURCE;
    duration?: number;
    value?: string;
    studentModalParams?: AwardsStudentModalParams;
    parentBannerParams?: AwardsParentBannerParams;
    type?: CERT_TYPE;
    target?: '_blank' | '_self';
}

export interface AwardsStudentModalParamsButton {
    text: string;
    view?: string;

    // ссылка
    url?: string;
    target?: string;

    // если указан - по клику открывается модалка с iframe
    iframe?: string;
    // если указаг вместе с iframe, то добавляет в скрытый вопрос яндекс формы id ученика
    childIdQuestion?: string;

    goalId?: string;
}

export interface AwardsStudentModalParams {
    title?: string;
    description?: string;
    shouldShowAwardImage?: boolean;
    customCertImageSrc?: string;
    buttons?: AwardsStudentModalParamsButton[];
}

export interface AwardsParentBannerParams {
    headerText?: string;
    descriptionText?: string;
    buttonText?: string;
    bannerTheme?: 'default' | 'yellow' | 'purple' | 'blue';
    previewImageSrc?: string;
    imageParams?: Record<string, string | number>;
    buttonView?: string;
}

export interface AwardItem {
    id: number;
    status: AWARD_STATUS;
    promo: Promo | null;
    certificates: StudentCertificate[] | null;
    shared_string?: string;
    award: Award;
}

export interface Campaign {
    date_updated: string;
    finish_dt: string;
    slug: string;
    variant: string;
    view_details: object;
}

export interface CampaignAwards {
    campaign: Campaign;
    award_items: AwardItem[];
}

export enum AWARD_STATUS {
    ALLOWED = 'Allowed',
    GRANTED = 'Granted',
}

export enum AWARD_TYPE {
    PROMO_CODE = 'PromoCode',
    LINK = 'SharedString',
    OLYMP_STUDENT_CERTIFICATE = 'StudentCertificateForOlymp',
}

export enum AWARD_SOURCE {
    PLUS = 'plus',
    FOXFORD = 'foxford',
    SYNCHRONIZE = 'synchronize',
    ROVER = 'rover-link',
}

export enum CERT_TYPE {
    CERT_FOR_ONE_LESSON = 'certForOneLesson',
    AWARD_CERT = 'awardCertForSeveralLessons',
}
