import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

function addEventListenerOnce(target, eventName, handler, options = false) {
    const once = (...args) => {
        target.removeEventListener(eventName, once, options);
        handler(...args);
    };
    target.addEventListener(eventName, once, options);
}

/**
 * В Firefox не работает селектор :active, если в событии mousedown вызывается preventDefault.
 * Этот HOC добавляет класс .active, когда надо показывать активное нажатое состояние.
 */
function withActiveClass(WrappedComponent) {
    class WithActiveClass extends React.Component {
        static propTypes = {
            className: PropTypes.string,
            onMouseDown: PropTypes.func,
            onTouchStart: PropTypes.func,
        };

        static defaultProps = {
            onMouseDown: () => {},
            onTouchStart: () => {},
        };

        state = {
            active: false,
        };

        handleMouseDown = (...args) => {
            const { onMouseDown } = this.props;

            onMouseDown(...args);

            this.setState({ active: true });
            addEventListenerOnce(document, 'mouseup', () => {
                this.setState({ active: false });
            });
        };

        handleTouchStart = (...args) => {
            const { onTouchStart } = this.props;

            onTouchStart(...args);

            this.setState({ active: true });
            addEventListenerOnce(document, 'touchend', () => {
                this.setState({ active: false });
            });
        };

        render() {
            const { active } = this.state;
            const { className } = this.props;

            return (
                <WrappedComponent
                    {...this.props}
                    className={cx(className, { active })}
                    onMouseDown={this.handleMouseDown}
                    onTouchStart={this.handleTouchStart}
                />
            );
        }
    }

    WithActiveClass.displayName = `withActiveClass(${WrappedComponent.displayName})`;

    return WithActiveClass;
}

export default withActiveClass;
