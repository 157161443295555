export enum PersonalAgreementEnum {
    TEACHER_PD_LINK = 'teacherPdLink',
    PARENT_PD_LINK = 'parentPdLink',
    OLYMP_LINK = 'olympLink',
}

export interface PersonalAgreementLink {
    textId: string;
    linkId: string;
}

export const personalAgreementLinks: Record<PersonalAgreementEnum, PersonalAgreementLink> = {
    olympLink: {
        linkId: 'common.longTermOlympAgreementUrl',
        textId: 'common.olympAgreementText',
    },
    parentPdLink: {
        linkId: 'common.licenseAgreementUrl',
        textId: 'common.licenseAgreementText',
    },
    teacherPdLink: {
        linkId: 'common.licenseAgreementUrl',
        textId: 'common.licenseAgreementText',
    },
};
