/* eslint-disable @typescript-eslint/ban-types */
import get from 'lodash/get';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route as ReactRoute, Switch as ReactSwitch, RouteProps } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';

import * as routerActions from './actions';

const ROUTE_PROPS = [
    'children',
    'component',
    'computedMatch',
    'exact',
    'location',
    'path',
    'render',
    'sensitive',
    'strict',
];

const STORE_PROPS = ['addRouteToTree', 'removeRouteFromTree'];

interface Props extends RouteProps {
    computedMatch?: Record<string, unknown>; // private, from <Switch>
    switchProps?: {};
    /** not for server side */
    addRouteToTree?: typeof routerActions.addRouteToTree;
    removeRouteFromTree?: typeof routerActions.removeRouteFromTree;
    replaceRouteInTree?: typeof routerActions.replaceRouteInTree;

    /** В данный компонент можно прокинуть любой дополнительный проп (см additionalProps) */
    [key: string]: unknown;
}

export class CustomRoute extends Component<Props> {
    UNSAFE_componentWillMount() {
        const { addRouteToTree, computedMatch, ...props } = this.props;
        const additionalProps = omit(props, [...ROUTE_PROPS, ...STORE_PROPS]);

        const search = props.location?.search || '';
        const query = parse(search);

        if (addRouteToTree && computedMatch) {
            addRouteToTree({ ...computedMatch, ...additionalProps, query });
        }
    }

    UNSAFE_componentWillReceiveProps({ computedMatch, replaceRouteInTree, ...newProps }: Props) {
        const pathname = get(newProps, 'location.pathname');
        const prevPathname = get(this.props, 'location.pathname');

        const additionalProps = omit(newProps, [...ROUTE_PROPS, ...STORE_PROPS]);

        if (typeof pathname !== 'undefined' && replaceRouteInTree && prevPathname !== pathname) {
            replaceRouteInTree({ ...computedMatch, ...additionalProps });
        }
    }

    componentWillUnmount() {
        const { removeRouteFromTree, computedMatch } = this.props;

        if (removeRouteFromTree) {
            removeRouteFromTree({ ...computedMatch });
        }
    }

    render() {
        const {
            children,
            component,
            computedMatch,
            exact,
            location,
            path,
            render,
            sensitive,
            strict,
            switchProps = {},
            ...props
        } = this.props;
        const additionalProps = omit(props, STORE_PROPS);

        return (
            <ReactRoute
                // @ts-ignore
                computedMatch={computedMatch}
                exact={exact}
                location={location}
                path={path}
                render={(routeProps) => {
                    if (render) {
                        return render({
                            // @ts-ignore
                            route: additionalProps,
                            ...switchProps,
                            ...routeProps,
                        });
                    }

                    if (component) {
                        return React.createElement(component, {
                            route: additionalProps,
                            ...switchProps,
                            ...routeProps,
                        });
                    }

                    if (children) {
                        //@ts-ignore - EDUCATION-37077: [react-update]: fixme typings: ???
                        return React.Children.map(children, (child) =>
                            // @ts-ignore
                            React.cloneElement(child, {
                                route: additionalProps,
                                ...switchProps,
                                ...routeProps,
                            })
                        );
                    }

                    return null;
                }}
                sensitive={sensitive}
                strict={strict}
            />
        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    const { addRouteToTree, removeRouteFromTree, replaceRouteInTree } = routerActions;

    return bindActionCreators({ addRouteToTree, removeRouteFromTree, replaceRouteInTree }, dispatch);
};

// при серверном рендеринге нам стор не нужен
export const Route = typeof window === 'undefined' ? CustomRoute : connect(null, mapDispatchToProps)(CustomRoute);

// eslint-disable-next-line react/no-multi-comp
export const Switch = ({ children, ...props }: React.PropsWithChildren<any>) => {
    return (
        <ReactSwitch>
            {React.Children.map(children, (child) => React.cloneElement(child, { switchProps: props }))}
        </ReactSwitch>
    );
};

Switch.propTypes = {
    children: PropTypes.node,
};

export { BrowserRouter, Redirect, withRouter } from 'react-router-dom';
