import { InferValuesTypes } from '@yandex-int/k-common';

import * as actions from '../actions';
import * as constants from '../constants';
import { StateWithRoutes } from '../types';

const initialState: StateWithRoutes['router'] = {
    tree: [],
};

type Actions = ReturnType<InferValuesTypes<typeof actions>>;

export const router = (state = initialState, action: Actions) => {
    switch (action.type) {
        case constants.ADD_ROUTE_TO_TREE: {
            const { route } = action;

            return { ...state, tree: [...state.tree, route] };
        }
        case constants.REMOVE_ROUTE_FROM_TREE: {
            const { route } = action;
            const { tree } = state;
            const newTree = [...tree].reverse();
            const index = newTree.findIndex((treeRoute) => treeRoute.url === route.url);

            newTree.splice(index, 1);

            return { ...state, tree: newTree.reverse() };
        }
        case constants.REPLACE_ROUTE_IN_TREE: {
            const { route } = action;
            const { tree } = state;

            const newTree = [...tree].reverse();
            const index = newTree.findIndex((treeRoute) => treeRoute.path === route.path);

            newTree.splice(index, 1, route);

            return { ...state, tree: newTree.reverse() };
        }
        default:
            return state;
    }
};

export default router;
