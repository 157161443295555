import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import { createSelector } from 'reselect';

import { TeacherRegistrationStore } from '../teacher-registration/typings';

import { StoreType } from './typings';

const applyCodeState = (storeState: any): StoreType => storeState?.applyTeacherCode;

export const getLoadingStatus = createSelector(
    applyCodeState,
    (state: TeacherRegistrationStore): LOADING_STATUSES => state?.loadingStatus
);
