import { getFromLocalStorage, setToLocalStorage } from 'platform-components/utils';

import {
    DEFAULT_PASSWORD_EXPIRE_TIME,
    LOCAL_STORAGE_STUDENTS_KEY,
    NEW_STUDENT_ID,
} from 'common.components/students/students.constants';

let studentIdCounter = NEW_STUDENT_ID; // генератор guid-ов для списка имен студентов

/**
 * TODO: выпилить как легаси
 * @deprecated
 */
export const createNewStudent = (studentId?: number) => {
    let id = studentId;

    if (!id) {
        studentIdCounter = studentIdCounter + 1;
        id = studentIdCounter;
    }

    return {
        id,
        name: '',
    };
};

export const getStudentPasswordFromLocalStorageWithExpireTime = (id: number) => {
    const storageStr = getFromLocalStorage(LOCAL_STORAGE_STUDENTS_KEY, null);

    if (!storageStr) {
        return null;
    }

    const storage = JSON.parse(storageStr);

    const studentData = storage[id];

    if (!studentData) {
        return null;
    }

    if (Date.now() > studentData.expireTime) {
        delete storage[id];
        setToLocalStorage(LOCAL_STORAGE_STUDENTS_KEY, JSON.stringify(storage));
        return null;
    }

    return studentData.value;
};

export const setStudentPasswordToLocalStorageWithExpireTime = (
    id: number,
    value: unknown,
    ttl: string = DEFAULT_PASSWORD_EXPIRE_TIME
) => {
    const convertedTTL = Number(ttl);

    const expireTime = !isNaN(convertedTTL) ? convertedTTL : 0;

    const storageObjectStr = getFromLocalStorage(LOCAL_STORAGE_STUDENTS_KEY, null);

    const storage = storageObjectStr ? JSON.parse(storageObjectStr) : {};

    const item = {
        value: value,
        expireTime: Date.now() + expireTime,
    };

    setToLocalStorage(LOCAL_STORAGE_STUDENTS_KEY, JSON.stringify({ ...storage, [id]: item }));
};
