import React from 'react';
import { connect } from 'react-redux';

import { UaTraits } from '../../typings/common';

export interface InjectedProps {
    uatraits: UaTraits;
}

const mapStateToProps = ({ uatraits }: { uatraits: UaTraits }) => ({ uatraits });

export default function withUatraits<T extends InjectedProps>(WrappedComponent: React.ComponentType<T>) {
    class WithUatraits extends React.Component<T> {
        render() {
            return <WrappedComponent {...this.props} />;
        }
    }

    // TODO: EDUCATION-11293 ts fix
    // @ts-ignore
    WithUatraits.displayName = `withUatraits(${WrappedComponent.displayName})`;

    // TODO: EDUCATION-11293 ts fix
    // @ts-ignore
    const connected = connect(mapStateToProps)(WithUatraits);

    return connected as unknown as React.ComponentType<Omit<T, keyof InjectedProps>>;
}
