import cx from 'classnames';
import { Button, SimpleText, SimpleCallback } from 'platform-components';
import React from 'react';

import './connect-external-button.scss';

interface OwnProps {
    buttonTextId: string;
    mix?: string;
    onClick?: SimpleCallback;
}

interface Props extends OwnProps {}

export const ConnectExternalButtonTypeTeacherCode = (props: Props) => {
    const { buttonTextId, mix, onClick } = props;

    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    };

    return (
        <Button className={cx('connect-external-button', mix)} onClick={handleClick} view={Button.VIEW.SECONDARY}>
            <SimpleText id={buttonTextId} />
        </Button>
    );
};
