//@ts-ignore
import { Route, Switch } from 'platform-components/src/components/router/router';
import { withRouterPath, InjectedProps as WithRouterPathProps } from 'platform-components/hocs';
import PropTypes from 'prop-types';
import React from 'react';

import RegistrationByCode from './__by-code/parent-registration__by-code';
import RegistrationCreateChild from './__create-child/parent-registration__create-child';
import { ParentRegistrationGetAward } from './__get-award/parent-registration__get-award';
import { ParentRegistrationOptions } from './__options/parent-registration__options';

import './parent-registration.scss';

interface Props extends WithRouterPathProps {}

class ParentRegistration extends React.Component<Props> {
    static contextTypes = {
        staticUrls: PropTypes.object.isRequired,
    };
    context!: React.ContextType<React.Context<{ staticUrls: Record<string, string> }>>;

    render() {
        const { staticUrls } = this.context;

        return (
            <Switch>
                <Route component={RegistrationByCode} exact path={staticUrls.parentRegistrationByCode} />
                <Route component={RegistrationCreateChild} exact path={staticUrls.parentRegistrationCreateChild} />
                <Route component={ParentRegistrationGetAward} path={staticUrls.parentRegistrationGetAward} />
                <Route component={ParentRegistrationOptions} path={staticUrls.parentRegistration} />
            </Switch>
        );
    }
}

export default withRouterPath(ParentRegistration);
