import cx from 'classnames';
import React from 'react';

import DotNotification from 'common.components/dot-notification/dot-notification';
// @ts-ignore
import SvgHamburger from 'common.components/icon3/Hamburger';

import './hamburger-button.scss';

interface Props extends React.HTMLProps<HTMLButtonElement> {
    mix?: string;
    shouldShowDotNotification?: boolean;
}

const HamburgerButton = ({ mix, shouldShowDotNotification, ...otherProps }: Props) => {
    return (
        <button {...otherProps} className={cx('hamburger-button', mix)} type="button">
            <SvgHamburger />
            {shouldShowDotNotification && <DotNotification mix="hamburger-button__dot-notification" />}
        </button>
    );
};

export default HamburgerButton;
