import { useMachine } from '@xstate/react';
import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import isEmpty from 'lodash/isEmpty';
import { useAppContext, useGeneratePath, useStaticUrls } from 'platform-components';
import { getExactPathMatch, getFromLocalStorage } from 'platform-components/utils';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useHistory, useLocation } from 'react-router';
import { useLifecycles } from 'react-use';

import { SchoolData } from 'common.components/school-form/typings';
import { getTeamIdFromPath } from 'utils/path';

import { redirectToAuth } from 'utils/redirect-to-auth';
import { getIsAuthenticated } from 'platform-components/src/components/user/selectors';
import { getTeacherDrilledQueryParams } from 'utils/getTeacherDrilledQueryParams';
import { TeamBuilderModeCreateTeamContainer } from './_mode/team-builder_mode_create-team.container';
import { TeamBuilderModeNoTemplatesContainer } from './_mode/team-builder_mode_no_templates.container';
import { TeamBuilderModeUpdateTeamContainer } from './_mode/team-builder_mode_update-team.container';
import { teamBuilderActions } from './actions';
import { TEAM_BUILDER_LS } from './constants';
import * as selectors from './selectors';
import { TEAM_BUILDER_INNER_STEPS, TeamBuilderMachine } from './team-builder-machine';
import { TEAM_BUILDER_ENTRY_STEPS, TEAM_BUILDER_STEPS, TeamBuilderModeContainerInterface } from './typings';
import { getWasSchoolAdded, shouldDisplayLogins } from './utils';

const machine = new TeamBuilderMachine();

const TEAM_BUILDER_ENTRY_POINT_QUERY_PARAM = 'team_builder_entry_point';
const TEAM_BUILDER_DISABLE_EDIT_GRADE_QUERY_PARAM = 'team_builder_disable_edit_grade';

interface Props {
    unavailableStep?: TEAM_BUILDER_STEPS;
}

// eslint-disable-next-line complexity
export const TeamBuilderContainer = (props: Props) => {
    const generatePath = useGeneratePath();
    const { labClasses, labClassStudents } = useStaticUrls();
    const { staticUrls } = useAppContext();
    const { pathname } = useLocation();
    const history = useHistory();
    const { search } = useLocation();
    const dispatch = useDispatch();

    const { unavailableStep } = props;

    // для обработки ошибок
    const codeSubmittingStatus = useSelector(selectors.getCodeSubmittingStatus);
    const dataSubmitStatus = useSelector(selectors.getSubmittingStatus);

    const entryPoint = useSelector(selectors.getEntryPoint);
    const isStarted = useSelector(selectors.getIsStarted);
    const onSuccess = useSelector(selectors.getOnSuccess);

    const [createdTeamId, setCreatedTeamId] = useState<number | null>(null);
    const [wasInCode, setWasInCode] = useState<boolean>(false);
    const [addedStudentsIds, setAddedStudentsIds] = useState<Array<number> | null>(null);
    const [addedSchoolData, setSchoolData] = useState<SchoolData | null>(null);
    const [machineState, send, service] = useMachine(machine.getMachine());
    const innerContainer = useRef<TeamBuilderModeContainerInterface | null>(null);

    const teamId = createdTeamId || getTeamIdFromPath();

    // селекторы для submit
    const dataForSubmit = useSelector((store) => selectors.getDataForSubmit(store, { classId: teamId }));
    // селекторы для прокидки в компоненты ниже
    const dataForProps = useSelector((store) => selectors.getDataForProps(store, { classId: teamId }));
    // селектор для submit-code
    const code = useSelector(selectors.getCode);

    const isAuthenticatedUser = useSelector(getIsAuthenticated);

    useLifecycles(
        () => {
            service.start();
            const value = JSON.parse(getFromLocalStorage(TEAM_BUILDER_LS, '{}')) as SchoolData;
            if (!isEmpty(value)) {
                dispatch(teamBuilderActions.setSchoolData(value));
            }
            // открываем entryPoint, если он есть в query параметрах, после чего удаляем параметр
            const queryParams = new URLSearchParams(search);
            const entryPointParam = queryParams.get(TEAM_BUILDER_ENTRY_POINT_QUERY_PARAM) as TEAM_BUILDER_ENTRY_STEPS;
            const disableEditGradeParam = queryParams.get(TEAM_BUILDER_DISABLE_EDIT_GRADE_QUERY_PARAM);
            const isValidEntryPointName = Object.values(TEAM_BUILDER_ENTRY_STEPS).includes(entryPointParam);
            if (isValidEntryPointName) {
                dispatch(
                    teamBuilderActions.start({
                        entryPoint: entryPointParam,
                        canEditGrade: !disableEditGradeParam,
                    })
                );
                queryParams.delete(TEAM_BUILDER_ENTRY_POINT_QUERY_PARAM);
                queryParams.delete(TEAM_BUILDER_DISABLE_EDIT_GRADE_QUERY_PARAM);
                history.replace({ search: queryParams.toString() });
            }
        },
        () => {
            service.stop();
        }
    );

    useEffect(
        function openModal() {
            if (isStarted) {
                if (innerContainer?.current) {
                    innerContainer.current.resetMachine();
                }
                dispatch(teamBuilderActions.clearData());
                clearState();
            }
        },
        [isStarted, dispatch]
    );

    useEffect(
        function handleCodeError() {
            // сбрасываем код и возвращаем на предыдущий шаг
            if (codeSubmittingStatus === LOADING_STATUSES.ERROR) {
                send({
                    type: 'UPDATE',
                    newContext: {
                        hasCode: false,
                    },
                });
                send({ type: 'BACK' });
            }
        },
        [send, codeSubmittingStatus]
    );

    useEffect(
        function handleSubmitError() {
            // при ошибке возвращаем на предыдущий шаг
            if (dataSubmitStatus === LOADING_STATUSES.ERROR) {
                send({ type: 'BACK' });
            }
        },
        [send, dataSubmitStatus]
    );

    const clearState = () => {
        setCreatedTeamId(null);
        setWasInCode(false);
    };

    const handleTemplatesLoaded = (templatesLoadingStatus: LOADING_STATUSES) => {
        if (
            templatesLoadingStatus === LOADING_STATUSES.SUCCESS &&
            [TEAM_BUILDER_STEPS.TEMPLATES_LOADING].includes(machineState.value as TEAM_BUILDER_STEPS)
        ) {
            handleGoNext();
        }
    };

    const handleAssignTeam = useCallback(
        (newGrade: number) => {
            send({ type: 'ASSIGN_TEAM', grade: newGrade });
        },
        [send]
    );

    const handleAssign = useCallback(() => {
        send({ type: 'ASSIGN' });
    }, [send]);

    const handleClose = () => {
        // на логинах нет CTA, передвигающего на следующий шаг, там просто закрывают модалку по крестику,
        // поэтому двигаем руками
        if (machineState.value === TEAM_BUILDER_STEPS.LOGINS) {
            handleGoNext();
        } else {
            if (innerContainer?.current) {
                innerContainer.current.resetMachine();
            }
            dispatch(teamBuilderActions.stop());
        }
    };

    const handleGoBack = () => {
        send({ type: 'BACK' });
    };

    const handleGoNext = () => {
        send({ type: 'NEXT' });
    };

    const handleGoToCode = () => {
        send({ type: 'TO_CODE' });
        setWasInCode(true);
    };

    const generateTeamPath = (classId: number): string => {
        const match = getExactPathMatch(staticUrls, pathname);
        const params = match?.params;
        const path = match?.path || '';
        let redirectPath = path;

        if (path === labClasses) {
            redirectPath = labClassStudents;
        }

        // внутри библиотеки своя таблица редиректов
        if (matchPath(pathname, staticUrls.labLibrary)) {
            const libraryRedirectMap = {
                [staticUrls.labLibraryMain]: staticUrls.labLibraryTeamMain,
                [staticUrls.labLibraryCalendarPlanShowcaseLesson]: staticUrls.labClassLibraryCalendarPlanShowcaseLesson,
                [staticUrls.labLibrarySubjectTab]: staticUrls.labClassLibrarySubjectTab,
                [staticUrls.labLibrarySubjectCalendarPlan]: staticUrls.labClassLibrarySubjectCalendarPlan,
                [staticUrls.labLibrarySubjectCalendarPlanTheme]: staticUrls.labClassLibrarySubjectCalendarPlanTheme,
                [staticUrls.labLibrarySubjectCommonCourseContainer]: staticUrls.labLibrarySubjectCollection,
                [staticUrls.deepLabThemeLessons]: staticUrls.labLibrarySubjectThemeLessons,
                [staticUrls.deepLabStacks]: staticUrls.labLibrarySubjectThemeStacks,
                [staticUrls.deepLabTheme]: staticUrls.labLibrarySubjectTheme,
                [staticUrls.deepLabPromoTabSection]: staticUrls.labLibrarySubjectPromo,
            };

            if (libraryRedirectMap[path]) {
                redirectPath = libraryRedirectMap[path];
            }
        }
        const drilledQueryParams = getTeacherDrilledQueryParams(search);

        return generatePath(redirectPath, { ...params, classId }) + `?${drilledQueryParams.toString()}`;
    };

    const handleTeamCreateSuccess = (newTeamId: number) => {
        if (teamId !== newTeamId) {
            setCreatedTeamId(newTeamId);
            history.push(generateTeamPath(newTeamId));
        }
    };

    const handleSubmitSuccess = (newTeamId: number, newStudentsIds: Array<number> | null) => {
        setAddedStudentsIds(newStudentsIds);
        setCreatedTeamId(newTeamId);
        handleGoNext();
    };

    const handleCodeSubmitSuccess = (newTeamId: number) => {
        history.push(generateTeamPath(newTeamId));
        // null - потому что по коду добавляется весь класс
        handleSubmitSuccess(newTeamId, null);
    };

    const submitMesRequest = () => {
        dispatch(
            teamBuilderActions.submitMesRequest({
                ...dataForSubmit,
                onSuccess: (schoolData) => {
                    setSchoolData(schoolData);
                    handleGoNext();
                },
            })
        );
    };

    const submit = () => {
        dispatch(
            teamBuilderActions.submitRequest({
                ...dataForSubmit,
                onSuccess: handleSubmitSuccess,
                onTeamCreateSuccess: handleTeamCreateSuccess,
            })
        );
    };

    const submitCode = () => {
        dispatch(
            teamBuilderActions.submitCode({
                code,
                onSuccess: handleCodeSubmitSuccess,
            })
        );
    };

    if (machineState.value === TEAM_BUILDER_INNER_STEPS.FINAL) {
        handleClose();

        if (onSuccess && entryPoint === TEAM_BUILDER_ENTRY_STEPS.SCHOOL_FOR_MES) {
            // @ts-ignore
            onSuccess(addedSchoolData);
        } else if (onSuccess) {
            // @ts-ignore
            onSuccess(addedStudentsIds, createdTeamId || undefined);
        }
    }

    const builderProps = {
        ...dataForProps,
        createdTeamId,
        addedStudentsIds,
        handleAssign,
        handleAssignTeam,
        handleClose,
        handleGoBack,
        handleGoNext,
        handleGoToCode,
        handleTemplatesLoaded,
        ref: innerContainer,
        wasSchoolAdded: getWasSchoolAdded(machineState.context),
        shouldDisplayLogins: shouldDisplayLogins(machineState.context),
        machineState: machineState.value as TEAM_BUILDER_STEPS | TEAM_BUILDER_INNER_STEPS,
        send,
        isStarted,
        submitCode,
        submitMesRequest,
        submit,
    };

    // всех не аутентифицированных пользователей при попытке взаимодействия с логикой сервиса, перенаправляем в passport
    // так же добавляем entryPoint в query, для сохранения пути пользователя
    if (entryPoint && !isAuthenticatedUser && isStarted) {
        // при браузерной "назад" в кеше остается открытый entryPoint
        dispatch(teamBuilderActions.stop());

        const url = new URL(window.location.href);
        url.searchParams.append(TEAM_BUILDER_ENTRY_POINT_QUERY_PARAM, entryPoint);
        const retPath = url.toString();
        redirectToAuth(window.location.href, retPath);
        return null;
    }

    if (
        entryPoint &&
        unavailableStep &&
        [TEAM_BUILDER_STEPS.SUBJECTS, TEAM_BUILDER_STEPS.ADDING_SUBJECTS].includes(unavailableStep)
    ) {
        return <TeamBuilderModeNoTemplatesContainer {...builderProps} />;
    }

    if (entryPoint === TEAM_BUILDER_ENTRY_STEPS.TEAM && !wasInCode) {
        return <TeamBuilderModeCreateTeamContainer {...builderProps} />;
    }

    if (entryPoint) {
        return <TeamBuilderModeUpdateTeamContainer {...builderProps} />;
    }

    return null;
};
