import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import { PlusSubscriptionInfo } from '@yandex-int/k-common/typings/api/models/plus-subscription';

import { SimpleCallback } from '../../typings';

import { PlusSubscriptionStoreState } from './typings';

const initialState: PlusSubscriptionStoreState = {
    loadingStatus: LOADING_STATUSES.UNSENT,
    subscriptionInfo: null,
};

// для саги
/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function */
const slice = createSlice({
    name: 'PLUS_SUBSCRIPTION',
    initialState: initialState,
    reducers: {
        getPlusSubscriptionInfoRequest: (
            state,
            action: PayloadAction<{ onSuccess?: SimpleCallback } | undefined>
        ) => {},

        setPlusSubscriptionInfoLoadingStatus: (state, action: PayloadAction<LOADING_STATUSES>) => {
            state.loadingStatus = action.payload;
        },

        setPlusSubscriptionInfo: (state, action: PayloadAction<PlusSubscriptionInfo>) => {
            state.subscriptionInfo = action.payload;
        },
    },
});
/* eslint-enable @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function */

export const { actions: plusSubscriptionActions, reducer: plusSubscriptionReducer } = slice;
