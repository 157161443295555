import { getFetch } from '../fetch';
import { apiConfig } from '../config';

import { GEOBASE_REQUEST_TYPE, GeobaseResponseData } from '../../../typings';

export function getGeobaseData(
    geoId: number,
    requestType: GEOBASE_REQUEST_TYPE
): Promise<GeobaseResponseData> {
    const { apiGetGeobaseData } = apiConfig.getStaticUrls();

    // eslint-disable-next-line camelcase
    return getFetch(`${apiGetGeobaseData}${requestType}/`, { id: geoId });
}
