import cx from 'classnames';
import { Link } from 'platform-components';
import PropTypes from 'prop-types';
import React from 'react';

import './form-view__card-description.scss';

const FormViewCardDescription = ({ children, mix, link }) => (
    <div className={cx('form-view__card-description', mix)}>
        {children}{' '}
        {link && (
            <Link mix="form-view__card-description-link" target={link.target} theme={Link.THEME.PRIMARY} to={link.url}>
                {link.text}
            </Link>
        )}
    </div>
);

FormViewCardDescription.propTypes = {
    children: PropTypes.node.isRequired,
    mix: PropTypes.string,
    link: PropTypes.shape({
        url: PropTypes.string,
        text: PropTypes.node,
        target: PropTypes.string,
    }),
};

export default FormViewCardDescription;
