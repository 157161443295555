import { Chevrone as IconChevrone } from 'education-icons';
import { Link, TEXT_MARKUP_TYPE, Text, useAppContext, Button } from 'platform-components';
import React from 'react';
import { useLocation } from 'react-router';

import { Logo } from 'common.components/logo/logo';
import { getMesHost } from 'utils/external-lms/external-lms';
import { getAuthPath } from 'utils/get-auth-path';

import ExternalBackground from '../../external-background/external-background';
import type { State } from '../external-lms';

import './external-lms__error.scss';

interface Props {
    errorId: State['errorId'];
    errorType: State['errorType'];
}

export const ExternalLmsError = ({ errorId, errorType }: Props) => {
    const textId = errorId ? errorId : 'page.networkError.default';
    const { basePath, prefix } = useAppContext();
    const { search, pathname } = useLocation();

    let Bottom: React.ReactNode = null;
    if (errorType === 'passport') {
        const retpath = `${basePath}${prefix}${pathname}${search}`;
        const authPath = getAuthPath({ retpath, backpath: retpath });

        Bottom = (
            <Link className="external-lms__error-auth-button" isRouter={false} to={authPath}>
                <Button>
                    <Text id="userPopup.button.cancel" />
                </Button>
            </Link>
        );
    }

    return (
        <div className="external-lms__error">
            <ExternalBackground />
            <div className="external-lms__error-modal-wrapper">
                <div className="external-lms__error-modal">
                    <div className="external-lms__error-modal-row external-lms__error-header">
                        <Link mix="external-lms__error-back-wrapper" theme={Link.THEME.INHERIT} to={getMesHost()}>
                            <IconChevrone />
                        </Link>
                    </div>
                    <div className="external-lms__error-modal-row external-lms__error-logo">
                        <Logo mix="external-lms__error-logo" theme={Logo.THEME.BLACK} />
                    </div>
                    <div className="external-lms__error-modal-row external-lms__error-text">
                        <Text id={textId} type={TEXT_MARKUP_TYPE.HTML} />
                        {Bottom}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExternalLmsError;
