export const scrollToViewInlineInput = (inlineBottom?: number, keyboardElement?: HTMLElement) => {
    if (!inlineBottom || !keyboardElement || !window) {
        return;
    }

    let keyboardTop = Infinity;
    const keyboardWrapper = keyboardElement.offsetParent;
    if (keyboardWrapper instanceof HTMLElement) {
        keyboardTop = window.innerHeight - keyboardWrapper.offsetHeight;
    }

    if (inlineBottom > keyboardTop) {
        window.scrollTo({ top: window.scrollY + inlineBottom - keyboardTop + 12, behavior: 'smooth' });
    }
};
