import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import { BackgroundImage, BorderedInput } from 'platform-components';
import { setToLocalStorage, isCyrillicKeyboardSymbols, convertString } from 'platform-components/utils';
import { ENG_TO_RUS_LAYOUT_MAP } from 'platform-components/utils';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import ym from 'react-yandex-metrika';
import { bindActionCreators, compose } from 'redux';

import { LOGGED_IN_SUCCESSFULLY } from 'common.components/constants';
import { loadingStatusPropType } from 'utils/data-prop-types';

import StartScreenStudentButton from '../__student-button/start-screen__student-button';
import * as actions from '../actions';
import {
    getAuthCodeStatus,
    getCanSubmitLogin,
    getIsParent,
    getIsSchoolCodeChecked,
    getIsSchool,
    getLogin,
    getLoginStatus,
    getPassword,
    getSchoolData,
    getShouldRedirectToClassroom,
    getPasswordLength,
} from '../selectors';
import { SCHOOL_CODE_LENGTH, LOGIN_LENGTH, PARENT_CODE_LENGTH } from '../start-screen.constants.js';
import {
    canBeParentCode,
    fomatParentCode,
    canBeSchoolCode,
    isPasswordLengthValid as getIsPasswordLengthValid,
} from '../utils';

import './start-screen__student-login.scss';

export class StudentLogin extends React.Component {
    static propTypes = {
        authCodeStatus: loadingStatusPropType,
        canSubmitLogin: PropTypes.bool.isRequired,
        intl: PropTypes.object.isRequired,
        isParent: PropTypes.bool,
        isSchool: PropTypes.bool,
        isSchoolCodeChecked: PropTypes.bool.isRequired,
        login: PropTypes.string.isRequired,
        loginStatus: loadingStatusPropType,
        password: PropTypes.string.isRequired,
        passwordLength: PropTypes.string.isRequired,
        schoolData: PropTypes.object,
        setLogin: PropTypes.func.isRequired,
        setPassword: PropTypes.func.isRequired,
        setPasswordLength: PropTypes.func.isRequired,
        shouldRedirectToClassroom: PropTypes.bool,
        submitLoginRequest: PropTypes.func.isRequired,
        uatraits: PropTypes.object.isRequired,
        verifyPasswordRequest: PropTypes.func.isRequired,
    };

    static contextTypes = {
        basePath: PropTypes.string.isRequired,
        prefix: PropTypes.string.isRequired,
        staticUrls: PropTypes.object.isRequired,
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { authCodeStatus: nextAuthCodeStatus, shouldRedirectToClassroom: nextShouldRedirectToClassroom } =
            nextProps;
        const { shouldRedirectToClassroom, authCodeStatus } = this.props;
        const {
            staticUrls: { classroomCourses },
            basePath,
            prefix,
        } = this.context;
        const { login } = this.props;
        const authCodeSuccess = nextAuthCodeStatus === LOADING_STATUSES.SUCCESS;
        const hasLogin = login && login.length > 0;

        if (nextShouldRedirectToClassroom && !shouldRedirectToClassroom) {
            setToLocalStorage(LOGGED_IN_SUCCESSFULLY, true);
            window.location.href = `${basePath}${prefix}${classroomCourses}`;
        }
        if (authCodeSuccess && authCodeStatus !== nextAuthCodeStatus && hasLogin) {
            this.setState({ canSubmit: true });
        }
    }

    handleLoginChange = ({ target: { value } }) => {
        const { setLogin } = this.props;

        if (!isCyrillicKeyboardSymbols(value)) {
            return;
        }

        const valueToUpperCase = value && value.toUpperCase();
        const convertedLogin = value && convertString(valueToUpperCase, ENG_TO_RUS_LAYOUT_MAP);

        const { selectionStart } = this.loginInput;

        setLogin(convertedLogin);

        // при вводе букв из-за подъема в uppercase и ререндера фокус сдвигается на конец
        setTimeout(() => {
            if (this.loginInput) {
                this.loginInput.setSelectionRange(selectionStart, selectionStart);
            }
        }, 0);
    };

    onPaste = () => {
        const { setPasswordLength } = this.props;

        setPasswordLength(PARENT_CODE_LENGTH);
    };

    onPasswordChange = ({ target: { value } }) => {
        const { setPassword, verifyPasswordRequest, setPasswordLength } = this.props;
        let isPasswordLengthValid = false;
        let password = value;

        if (canBeParentCode(password)) {
            isPasswordLengthValid = getIsPasswordLengthValid(password, PARENT_CODE_LENGTH);
            setPasswordLength(PARENT_CODE_LENGTH);
            password = fomatParentCode(password);
        } else if (canBeSchoolCode(password)) {
            isPasswordLengthValid = getIsPasswordLengthValid(password, SCHOOL_CODE_LENGTH);
            setPasswordLength(SCHOOL_CODE_LENGTH);
        } else {
            return;
        }

        setPassword(password);

        if (isPasswordLengthValid) {
            verifyPasswordRequest(password);
        }
    };

    submitLogin = () => {
        const { submitLoginRequest, login, password } = this.props;

        ym('reachGoal', 'student_login', { from: 'main', login, schoolCode: password });

        submitLoginRequest(login);
    };

    getAuthMessage = () => {
        const { authCodeStatus, isParent, password, schoolData, passwordLength } = this.props;
        const isAuthCodeValid =
            authCodeStatus === LOADING_STATUSES.SUCCESS && getIsPasswordLengthValid(password, passwordLength);

        if (!isAuthCodeValid || isParent) {
            return null;
        }

        return (
            <div className="start-screen__control-row">
                <div className="start-screen__control-label" />
                <div className="start-screen__control">
                    <div className="start-screen__auth-info">
                        {schoolData.city ? (
                            <FormattedMessage
                                id="startScreen.schoolData"
                                values={{ city: schoolData.city, name: schoolData.name }}
                            />
                        ) : (
                            schoolData.name
                        )}
                    </div>
                </div>
            </div>
        );
    };

    setLoginControlRef = (ref) => {
        this.loginInput = ref;
    };

    render() {
        const { intl, password, loginStatus, login, canSubmitLogin, passwordLength } = this.props;
        const image = intl.formatMessage({ id: 'startScreen.studentImage.main' });

        return (
            <div className="start-screen__student-login">
                <BackgroundImage mix="start-screen__image-main" objectFit="cover" src={image} />

                <div className="start-screen__login-controls">
                    <div className="start-screen__control-row">
                        <div className="start-screen__control-label">
                            <FormattedMessage id="startScreen.login" />
                        </div>
                        <div className="start-screen__control">
                            <BorderedInput
                                focused
                                keepUserValueIfCaseMismatch={true}
                                maxLength={LOGIN_LENGTH}
                                mix="start-screen__login-input"
                                onChange={this.handleLoginChange}
                                onControlRef={this.setLoginControlRef}
                                value={login}
                            />
                        </div>
                    </div>
                    <div className="start-screen__control-row">
                        <div className="start-screen__control-label">
                            <FormattedMessage id="startScreen.schoolCode" />
                        </div>
                        <div className="start-screen__control">
                            <div className="start-screen__school-code-wrapper">
                                <BorderedInput
                                    keepUserValueIfCaseMismatch={true}
                                    maxLength={passwordLength}
                                    mix="start-screen__school-code-input"
                                    onChange={this.onPasswordChange}
                                    onPaste={this.onPaste}
                                    value={password}
                                />
                            </div>
                        </div>
                    </div>
                    {this.getAuthMessage()}
                </div>
                <div className="start-screen__button-container">
                    <StartScreenStudentButton
                        disabled={!canSubmitLogin}
                        onClick={this.submitLogin}
                        status={loginStatus}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (storeState) => ({
    authCodeStatus: getAuthCodeStatus(storeState),
    canSubmitLogin: getCanSubmitLogin(storeState),
    isParent: getIsParent(storeState),
    isSchoolCodeChecked: getIsSchoolCodeChecked(storeState),
    isSchool: getIsSchool(storeState),
    login: getLogin(storeState),
    loginStatus: getLoginStatus(storeState),
    shouldRedirectToClassroom: getShouldRedirectToClassroom(storeState),
    password: getPassword(storeState),
    schoolData: getSchoolData(storeState),
    passwordLength: getPasswordLength(storeState),
});

const mapDispatchToProps = (dispatch) => {
    const { verifyPasswordRequest, submitLoginRequest, setPassword, setLogin, setPasswordLength } = actions;

    return bindActionCreators(
        {
            verifyPasswordRequest,
            submitLoginRequest,
            setPassword,
            setLogin,
            setPasswordLength,
        },
        dispatch
    );
};

export default compose(injectIntl, connect(mapStateToProps, mapDispatchToProps))(StudentLogin);
