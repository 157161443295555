import { CancelClear } from 'education-icons';
import { Text } from 'platform-components';
import React from 'react';

import './connect-external-error.scss';

const ConnectExternalError = () => (
    <div className="connect-external-error">
        <CancelClear />
        <Text id="connectExternal.error" size={Text.SIZE.S} />
    </div>
);

export default ConnectExternalError;
