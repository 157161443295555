// @ts-nocheck
import { EXTERNAL_LMS_SLUGS } from '@yandex-int/k-common';
import { SimpleCallback, Spin, Text } from 'platform-components';
import React from 'react';

import { LESSON_EDIT_ERRORS } from 'common.components/lesson/typings';

import ExternalBackground from '../external-background/external-background';

import ExternalDeleteLessonError from './__error/external-delete-lesson__error';
import { EXTERNAL_DELETE_LESSON_ERROR } from './typings';

import './external-delete-lesson.scss';

interface Props {
    backUrl: string;
    errorCode: LESSON_EDIT_ERRORS | EXTERNAL_DELETE_LESSON_ERROR | null;
    lessonName: string;
    lms: EXTERNAL_LMS_SLUGS;
    onRetry: SimpleCallback;
    shouldShowLoader: boolean;
}

export const ExternalDeleteLesson = React.memo((props: Props) => {
    const { errorCode, lms, shouldShowLoader } = props;

    if (errorCode) {
        const { backUrl, onRetry } = props;

        return (
            <div className="external-delete-lesson__wrapper">
                <ExternalBackground />
                <ExternalDeleteLessonError backUrl={backUrl} errorCode={errorCode} lms={lms} onRetry={onRetry} />
            </div>
        );
    }

    if (shouldShowLoader) {
        const { lessonName } = props;

        return (
            <div className="external-delete-lesson__wrapper">
                <ExternalBackground />
                <div className="external-delete-lesson__loader-panel">
                    <Spin mix="external-delete-lesson__spin" />
                    <Text
                        id="externalLms.deleteLesson.loader"
                        type={Text.TYPE.MARKDOWN}
                        values={{
                            lessonName,
                            lms,
                        }}
                    />
                </div>
            </div>
        );
    }

    return null;
});
