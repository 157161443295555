import { LessonMeta, LESSON_STATUS, LessonViewSettings } from '@yandex-int/k-common/typings';
import get from 'lodash/get';

import * as lessonUtils from 'common.components/lesson/utils';
import { getIsLessonAvailableForChildren } from 'common.components/lesson/utils';

export interface Lesson {
    status?: LESSON_STATUS;
    // id оригинала занятия - актуально только для редактируемых копий ГЗ
    copyOf?: number | null;
    isEvaluable: boolean;
    evaluationDate: Date;
    stream: string | null;
}

interface Course {
    id: number;
    closed: boolean;
}

// Здесь все данные, по которым мы можем определить, что мы можем увидеть и как изменить занятие
export interface LessonPermissionsRequestData {
    lesson: Lesson;
    hasLesson: boolean; // есть ли вообще урок
    lessonMeta: LessonMeta;

    // курс, которому принадлежит занятие - только для своих
    // Готовое и спец занятия курса не имеют - они "чужие"
    course?: Course;

    isInExternalHomework: boolean;

    now?: Date | null;
}

// "Права" на различные контролы(отображение информации или действия) занятия
export interface LessonPermissions {
    // Завершить принудительно
    closeControlAvailable: boolean;

    // Копирование своего занятия в свои классы
    copyOwnLessonControlAvailable: boolean;

    // Удаление занятия
    deleteControlAvailable: boolean;

    // Вывести занятие на доску
    isPutOnBoardAvailable: boolean;

    // Копирование занятия (через кнопочку-скрепку или пункт в three-dots)
    isCopyLessonLinkAvailable: boolean;
}

export const getIsEvaluatedLesson = (isEvaluable: boolean, evaluationDate: Date, now: Date | null): boolean =>
    now ? isEvaluable && evaluationDate.getTime() !== 0 && evaluationDate <= now : false;

export const getIsAccessibleLesson = (accessibleToTeacher: Date, now: Date | null): boolean => {
    return now ? accessibleToTeacher.getTime() !== 0 && accessibleToTeacher <= now : false;
};

export const getCanViewProblemContent = (lessonMeta: LessonMeta, isEvaluated: boolean): boolean => {
    return !(lessonMeta.hide_problems_till_evaluation && !isEvaluated);
};

export const getLessonViewSettings = (lessonMeta: LessonMeta): LessonViewSettings | null => {
    return lessonMeta.lesson_view_settings || null;
};

export const getIsPutOnBoardControlAvailable = (
    hasLesson: boolean,
    lesson: Lesson,
    lessonMeta: LessonMeta,
    now: Date | null
): boolean => {
    const { evaluationDate, isEvaluable } = lesson;
    const isEvaluated = getIsEvaluatedLesson(isEvaluable, evaluationDate, now);
    const isLessonWithCustomSettings = Boolean(getLessonViewSettings(lessonMeta));

    if (isLessonWithCustomSettings) {
        return false;
    }

    if (lessonMeta.hide_put_on_board_till_evaluation && !isEvaluated) {
        return false;
    }

    return hasLesson && getCanViewProblemContent(lessonMeta, isEvaluated) && !lesson?.stream;
};

export const getAreDatesEditable = (lessonMeta: LessonMeta): boolean => get(lessonMeta, 'are_dates_editable', true);

export const getIsLessonModeEditable = (lessonMeta: LessonMeta): boolean =>
    get(lessonMeta, 'is_lesson_mode_editable', true);

export const getIsLessonContentEditable = (lessonMeta: LessonMeta): boolean =>
    get(lessonMeta, 'is_content_editable', true);

export const getIsRepeatable = (lessonMeta: LessonMeta): boolean => lessonMeta.is_repeatable;

export const getIsCopiable = (lessonMeta: LessonMeta): boolean => get(lessonMeta, 'is_copiable', true);

export const getAccessibleToTeacher = (lessonMeta: LessonMeta): string | null => lessonMeta.accessible_to_teacher;

export const getCanCopyOwnLesson = (
    isCopiable: boolean,
    isCourseLesson: boolean,
    isStream: boolean,
    isInExternalHomework: boolean
) => isCourseLesson && isCopiable && !isStream && !isInExternalHomework;

export const getIsModeReadonly = (lessonMeta: LessonMeta): boolean => lessonMeta.is_mode_readonly;

export const getIsForceCompleteAllowed = (lessonMeta: LessonMeta): boolean =>
    get(lessonMeta, 'allow_forced_completion', true);

export const getIsIndividualAssignmentAllowed = (lessonMeta: LessonMeta): boolean =>
    get(lessonMeta, 'allow_individual_assignment', true);

export const getIsLessonCloseAvailable = (isLessonInProgress: boolean, isRepeatable: boolean): boolean =>
    isLessonInProgress && isRepeatable;

// eslint-disable-next-line complexity
export const getLessonPermissions = (data: LessonPermissionsRequestData): LessonPermissions => {
    const { lesson, lessonMeta, course, hasLesson, isInExternalHomework, now } = data;
    const { copyOf, status, stream } = lesson;

    const isStream = Boolean(stream);
    const isCourseLesson = Boolean(course);
    const isPreparedLessonCopy = isCourseLesson && Boolean(copyOf);
    const isCompleted = status ? lessonUtils.isLessonCompleted(status) : false;
    const isLessonInProgress = status ? lessonUtils.isLessonInProgress(status) : false;
    const withStats = isCompleted || isLessonInProgress;
    const isCopiable = getIsCopiable(lessonMeta);
    const isRepeatable = getIsRepeatable(lessonMeta);
    const isLessonCloseAvailable = getIsLessonCloseAvailable(isLessonInProgress, isRepeatable);

    const isCopyLessonLinkAvailable = !!status && getIsLessonAvailableForChildren(status) && !course?.closed;
    const isPutOnBoardAvailable = getIsPutOnBoardControlAvailable(true, lesson, lessonMeta, now || new Date());

    return {
        closeControlAvailable: hasLesson && isLessonCloseAvailable && getIsForceCompleteAllowed(lessonMeta),
        copyOwnLessonControlAvailable:
            hasLesson && getCanCopyOwnLesson(isCopiable, isCourseLesson, isStream, isInExternalHomework),
        deleteControlAvailable: hasLesson && !withStats && isCourseLesson && !isPreparedLessonCopy,
        isCopyLessonLinkAvailable,
        isPutOnBoardAvailable,
    };
};
