import React from 'react';

import { useProblemAnalyticsContext } from '../../problem/analytics-context';

interface SpoilerAnalyticsProps {
    onToggle?: (open: boolean) => void;
}

export const withSpoilerAnalytics = <T extends SpoilerAnalyticsProps>(WrappedComponent: React.ComponentType<T>) => {
    const Component = (props: T) => {
        const { ymOnSpoilerToggle } = useProblemAnalyticsContext();

        const onToggle = (open: boolean) => {
            if (props.onToggle) {
                props.onToggle(open);
            }

            ymOnSpoilerToggle(open);
        };

        return <WrappedComponent {...props} onToggle={onToggle} />;
    };

    Component.displayName = `withSpoilerAnalytics(${WrappedComponent.displayName})`;

    return Component;
};
