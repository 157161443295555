export enum LINK_THEME {
    PRIMARY = 'primary',
    PRIMARY_ON_DARK = 'primary-on-dark',
    STEALTHY = 'stealthy',
    STEALTHY_ON_DARK = 'stealthy-on-dark',
    SECONDARY = 'secondary',
    SECONDARY_ON_DARK = 'secondary-on-dark',
    ABSTRACT = 'abstract',
    INHERIT = 'inherit',
}
