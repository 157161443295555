import b from 'b_';
import cx from 'classnames';
import { SelectSuggestTypes as SUGGEST_TYPES, SelectNative, Select } from 'platform-components/common';
import { SELECT_THEMES, SELECT_TYPE } from 'platform-components/constants';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';

import NumberOption from './__number-option/class-number-selector__number-option';
import { CLASS_NUMBER_SELECTOR_MODE, CLASS_NUMBER_SELECTOR_VIEW } from './types';

import './class-number-selector.scss';

export class ClassNumberSelector extends React.Component {
    static propTypes = {
        availableNumbers: PropTypes.arrayOf(PropTypes.number).isRequired,
        disabled: PropTypes.bool,
        intl: PropTypes.object.isRequired,
        mix: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        mode: PropTypes.oneOf(Object.values(CLASS_NUMBER_SELECTOR_MODE)),
        onChange: PropTypes.func.isRequired,
        onHide: PropTypes.func.isRequired,
        shouldPreventAutoopen: PropTypes.bool,
        valid: PropTypes.string,
        value: PropTypes.number,
        view: PropTypes.oneOf(Object.values(CLASS_NUMBER_SELECTOR_VIEW)),
    };

    constructor(props) {
        super(props);

        this.state = {
            shown: !props.shouldPreventAutoopen && !this.props.value,
        };
    }

    handleChange = ({ title }) => {
        const { onChange } = this.props;

        // нативный селект возвращает строку
        onChange(Number(title));
    };

    handleRequestHide = () => {
        const { onHide } = this.props;

        onHide();
        this.setState({ shown: false });
    };

    handleRequestShow = () => {
        this.setState({ shown: true });
    };

    getSelectNativeContent() {
        const { view, availableNumbers } = this.props;

        switch (view) {
            case CLASS_NUMBER_SELECTOR_VIEW.PLAIN:
                return availableNumbers.map((num) => (
                    <Select.Option key={num} value={num}>
                        {num}
                    </Select.Option>
                ));

            case CLASS_NUMBER_SELECTOR_VIEW.DEFAULT:
            default:
                return null;
        }
    }

    getSelectNativeProps() {
        const { availableNumbers, disabled, intl, mix, view, value, valid, mode } = this.props;
        const { shown } = this.state;
        const insertValue = value ? value.toString() : '';
        const className = b('class-number-selector', { valid });

        const commonProps = {
            valueKey: 'title',
            labelKey: 'title',
            options: availableNumbers.map((title) => ({ title: title.toString() })),
            onChange: this.handleChange,
            withEmptyOption: mode === CLASS_NUMBER_SELECTOR_MODE.CREATE,
        };

        switch (view) {
            case CLASS_NUMBER_SELECTOR_VIEW.PLAIN:
                return {
                    theme: SELECT_THEMES.TEACHER,
                    popupMix: 'class-number-selector__select-popup',
                    closeMenuOnOuterScrollAndResize: true,
                    inLayer: true,
                    height: 'adaptive',
                    width: '93px',
                    name: 'title',
                    value: insertValue,
                    selectType: SELECT_TYPE.SELECT,
                    ...commonProps,
                };

            case CLASS_NUMBER_SELECTOR_VIEW.DEFAULT:
            default:
                return {
                    disabled,
                    mix: cx(mix, className),
                    onRequestHide: this.handleRequestHide,
                    onRequestShow: this.handleRequestShow,
                    optionRenderer: NumberOption,
                    placeholder: intl.formatMessage({ id: 'classNumberSelector.placeholder' }),
                    shown,
                    type: SUGGEST_TYPES.SELECT,
                    value: insertValue.toString(),
                    ...commonProps,
                };
        }
    }

    render() {
        const content = this.getSelectNativeContent();
        const selectNativeProps = this.getSelectNativeProps();

        return <SelectNative {...selectNativeProps}>{content}</SelectNative>;
    }
}

const wrapped = injectIntl(ClassNumberSelector);
wrapped.displayName = 'ClassNumberSelector';

export default wrapped;
