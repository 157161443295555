const MAX_CLASS_NAME_LENGTH = 10;

/** Сокращает название класса до maxLength (дефолтно 10) */
export const getTrimmedName = (name: string | null | undefined, maxLength: number = MAX_CLASS_NAME_LENGTH) => {
    if (name && name.length > maxLength) {
        return `${name.substring(0, maxLength)}...`;
    }

    return name || '';
};
