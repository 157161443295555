import { Uatraits } from '@yandex-int/k-common';

export function isInternetExplorer(uatraits: Uatraits = {} as Uatraits) {
    return uatraits.BrowserName === 'MSIE';
}

export function isEdge(uatraits: Uatraits = {} as Uatraits) {
    return uatraits.BrowserName === 'Edge';
}

export function getInternetExplorerVersion(uatraits: Uatraits) {
    let rv = -1;

    if (uatraits.BrowserName === 'MSIE') {
        rv = Number(uatraits.BrowserVersion);
    }

    return rv;
}

export function getIOSVersion(uatraits: Uatraits) {
    let rv = -1;

    if (uatraits.OSFamily === 'iOS') {
        rv = Number(uatraits.OSVersion);
    }

    return rv;
}

export function getDocumentHiddenPropName() {
    if (typeof document.hidden !== 'undefined') {
        return 'hidden';
    }

    // @ts-ignore
    if (typeof document.msHidden !== 'undefined') {
        return 'msHidden';
    }

    // @ts-ignore
    if (typeof document.webkitHidden !== 'undefined') {
        return 'webkitHidden';
    }

    return null;
}

export function getVisibilityChangeEventName() {
    if (typeof document.hidden !== 'undefined') {
        return 'visibilitychange';
    }

    // @ts-ignore
    if (typeof document.msHidden !== 'undefined') {
        return 'msvisibilitychange';
    }

    // @ts-ignore
    if (typeof document.webkitHidden !== 'undefined') {
        return 'webkitvisibilitychange';
    }

    return null;
}
