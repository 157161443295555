import { Student } from 'common.components/typings';

import { MachineContext, TEAM_BUILDER_STEPS } from './typings';

export const getWasSchoolAdded = (context?: MachineContext): boolean => {
    return (context?.passedSteps || []).includes(TEAM_BUILDER_STEPS.SCHOOL);
};

export const shouldDisplayLogins = (context?: MachineContext): boolean => {
    return Boolean(
        (context?.passedSteps || []).some((step) =>
            [TEAM_BUILDER_STEPS.STUDENTS, TEAM_BUILDER_STEPS.CODE].includes(step)
        )
    );
};

export const mapStudentsIds = (students: Array<Student>): Array<number> => {
    return students.map(({ id }) => id);
};
