//@ts-ignore
import * as api from '@yandex-int/k-common/client/api';
import { ExternalLms, GEOBASE_REQUEST_TYPE, GeobaseParentsResponse } from '@yandex-int/k-common/typings';
import { showNetworkError } from 'platform-components';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { externalLmsConnectActions } from './actions';
import { findLmsByRegion } from './utils';

export function* externalLmsSagasWatcher() {
    yield all([
        takeLatest(externalLmsConnectActions.getLmsByRegionRequest, getLmsByRegionSaga),
        takeLatest(externalLmsConnectActions.getUserExternalLmsListRequest, getUserExternalLmsListSaga),
    ]);
}

export function* getLmsByRegionSaga(action: ReturnType<typeof externalLmsConnectActions.getLmsByRegionRequest>) {
    const { geoId } = action.payload;
    try {
        const geoParents: GeobaseParentsResponse = yield call(api.getGeobaseData, geoId, GEOBASE_REQUEST_TYPE.PARENTS);
        const lmsByGeoIds: Record<string, Array<string>> = !isSSR ? window._data.lmsByGeoId : {};

        const lms: Array<string> = yield call(findLmsByRegion, geoParents, lmsByGeoIds);

        yield put(externalLmsConnectActions.setLmsByRegion(lms));
        yield put(externalLmsConnectActions.getLmsByRegionSuccess());
    } catch (error) {
        console.error(error);

        yield put(externalLmsConnectActions.getLmsByRegionError());
        yield put(showNetworkError(error));
    }
    return null;
}

export function* getUserExternalLmsListSaga() {
    try {
        const externalLmsList: Record<string, ExternalLms> = yield api.getUserExternalLms();

        yield put(externalLmsConnectActions.getUserExternalLmsListSuccess({ lms: externalLmsList }));
    } catch (e) {
        console.error(e);

        yield put(externalLmsConnectActions.getUserExternalLmsListError());
        yield put(showNetworkError(e));
    }
}
