import cx from 'classnames';
import React from 'react';

import './ya-form.scss';

interface Props {
    mix?: string;
    url: string;
    name: string;
    width?: number;
    height?: number;
}

const YaForm = ({ url, name, mix, width, height }: Props) => {
    const formWidth = width ? width : '100%';
    const formHeight = height ? height : '100%';
    const formUrl = new URL(url);

    const handleFrameError = () => {
        console.error('Ошибка с iframe');
    };

    return (
        <>
            <div className={cx('ya-form', mix)}>
                <iframe
                    className="ya-form__iframe"
                    frameBorder="0"
                    height={formHeight}
                    name={name}
                    onError={handleFrameError}
                    src={formUrl.href}
                    title={' '}
                    width={formWidth}
                />
            </div>
        </>
    );
};

export default YaForm;
