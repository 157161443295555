import {
    getMe,
    postAddChild,
    postAddChildByLoginCode,
    getParentProfile,
    getParentChildren,
    postAddChildCourses,
    postVerifySchoolCode,
} from '@yandex-int/k-common/client/api';
import { postAddChildCoursesBulk } from '@yandex-int/k-common/client/api/defs/post-add-child-courses-bulk';
import { Me, ParentProfile, Child } from '@yandex-int/k-common/typings';
import { showNetworkError } from 'platform-components';
import { NOTIFICATION_THEMES } from 'platform-components/constants';
import { showIntlMessageNotification } from 'platform-components/src/components/notification/actions';
import { reportMessage } from 'platform-components/utils';
import { takeLatest, all, call, put, select } from 'redux-saga/effects';

import * as userActions from 'common.components/user/actions';
import { ensureIAmParent } from 'common.components/user/saga';
import { getChildren } from 'common.components/user/selectors';

import {
    bindChildByStudentCodeRequest,
    bindChildByStudentCodeSuccess,
    bindChildByStudentCodeError,
    addCoursesRequest,
    bindChildByLoginRequest,
    bindChildByLoginSuccess,
    bindChildByLoginError,
    verifySchoolCodeRequest,
    verifySchoolCodeSuccess,
    verifySchoolCodeError,
} from './actions';
import {
    BIND_CHILD_BY_STUDENT_CODE_REQUEST,
    ADD_COURSES_REQUEST,
    VERIFY_SCHOOL_CODE_REQUEST,
    WRONG_AUTH_CODE,
    BIND_CHILD_BY_LOGIN_REQUEST,
} from './constants';

export function* childManagementWatcher() {
    yield all([
        takeLatest(BIND_CHILD_BY_STUDENT_CODE_REQUEST, bindChildByStudentCodeSaga),
        takeLatest(ADD_COURSES_REQUEST, addCoursesSaga),
        takeLatest(BIND_CHILD_BY_LOGIN_REQUEST, bindChildByLoginSaga),
        takeLatest(VERIFY_SCHOOL_CODE_REQUEST, verifySchoolSaga),
    ]);
}

export function* canBeParent() {
    const { is_teacher: isTeacher } = yield call(getMe);

    if (isTeacher) {
        yield put(
            showIntlMessageNotification({
                messageId: 'registration.alreadyRegistered.teacher',
                theme: NOTIFICATION_THEMES.FAIL,
            })
        );
        return false;
    }

    return true;
}

// eslint-disable-next-line complexity
function* bindChildByStudentCodeSaga({ code, successCallback }: ReturnType<typeof bindChildByStudentCodeRequest>) {
    try {
        const canMakeMeParent: boolean = yield call(canBeParent);
        const oldChildren: Child[] | null = yield select(getChildren);

        if (!canMakeMeParent) {
            yield put(bindChildByStudentCodeError(false));
            return;
        }

        yield call(ensureIAmParent);
        const me: Me = yield call(postAddChild, { code });
        const profile: ParentProfile = yield call(getParentProfile);
        const children: Array<Child> = yield call(getParentChildren);
        yield put(userActions.setMe(me));
        yield put(userActions.setParentProfile(profile));
        yield put(userActions.setParentChildren(children));

        const newChild = children.find((child) => oldChildren?.every((oldChild) => oldChild.id !== child.id));
        const childIds = newChild ? [newChild.id] : undefined;

        if (newChild?.id && Boolean(newChild.student_profile?.grade)) {
            // добавляем пустой список курсов - тогда будут выданы только автовыдаваемые курсы
            yield call(postAddChildCourses, { childId: newChild.id, templateIds: [] });
        }

        if (successCallback) {
            successCallback(childIds);
        }

        yield put(bindChildByStudentCodeSuccess());
    } catch (error) {
        const isCodeWrong = error.status === 404;

        console.error(error);
        yield put(bindChildByStudentCodeError(isCodeWrong));

        if (!isCodeWrong) {
            yield put(showNetworkError(error));
        }
    }
}

function* bindChildByLoginSaga({ login, schoolCode, successCallback }: ReturnType<typeof bindChildByLoginRequest>) {
    try {
        const canMakeMeParent: boolean = yield call(canBeParent);
        const oldChildren: Child[] | null = yield select(getChildren);

        if (!canMakeMeParent) {
            yield put(bindChildByLoginError(false));
            return;
        }

        yield call(ensureIAmParent);
        const me: Me = yield call(postAddChildByLoginCode, { login, code: schoolCode });
        const profile: ParentProfile = yield call(getParentProfile);
        const children: Array<Child> = yield call(getParentChildren);
        yield put(userActions.setMe(me));
        yield put(userActions.setParentProfile(profile));
        yield put(userActions.setParentChildren(children));

        const newChild = children.find((child) => oldChildren?.every((oldChild) => oldChild.id !== child.id));
        const childId = newChild ? [newChild.id] : undefined;

        if (newChild?.id && Boolean(newChild.student_profile?.grade)) {
            // добавляем пустой список курсов - тогда будут выданы только автовыдаваемые курсы
            yield call(postAddChildCourses, { childId: newChild.id, templateIds: [] });
        }

        if (successCallback) {
            successCallback(childId);
        }

        yield put(bindChildByLoginSuccess());
    } catch (error) {
        if (error.status !== WRONG_AUTH_CODE) {
            yield put(showNetworkError(error));
        }
        yield put(bindChildByLoginError(error));
    }
}

function* verifySchoolSaga(action: ReturnType<typeof verifySchoolCodeRequest>) {
    const { schoolCode } = action;

    try {
        yield call(postVerifySchoolCode, { schoolCode });

        yield put(verifySchoolCodeSuccess());
    } catch (error) {
        if (error.status !== WRONG_AUTH_CODE) {
            yield put(showNetworkError(error));
        }
        yield put(verifySchoolCodeError(error));
    }
}

function* addCoursesSaga(action: ReturnType<typeof addCoursesRequest>) {
    const { childIds, courseTemplateIds, onSuccess, onError } = action.payload;
    try {
        yield call(postAddChildCoursesBulk, { childIds, templateIds: courseTemplateIds });

        const children: Array<Child> = yield call(getParentChildren);
        yield put(userActions.setParentChildren(children));

        if (onSuccess) {
            onSuccess();
        }
    } catch (error) {
        reportMessage(error);
        yield put(showNetworkError(error));

        if (onError) {
            onError();
        }
    }
}
