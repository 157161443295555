import * as React from 'react';
const SvgMesLogo = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 23 16" fill="none" {...props}>
        <path
            d="M20.536 13.57c.66-.636.66-1.666 0-2.3l-.97-.93-2.398 2.302.966.927h2.402zm0 0a1.753 1.753 0 01-2.402 0h2.402zM2.962 10.3v.05H8.322V9.148H2.962V10.3zm0 2.207v.05H8.322V11.355H2.962v1.153zm15.636-4.795h0c-1.15-1.102-3.017-1.102-4.165 0a2.756 2.756 0 00-.864 2.001c0 .756.307 1.467.864 2.001l2.82 2.707.034-.037-.034.037a3 3 0 002.082.826 3 3 0 002.082-.826 2.754 2.754 0 000-4.003l-2.82-2.706zm-3.284 3.15h0a1.581 1.581 0 010-2.299 1.732 1.732 0 011.201-.476c.435 0 .87.159 1.2.476l.967.928-2.398 2.302-.97-.931zm1.099-2.096h0a.642.642 0 00-.882 0 .585.585 0 000 .852l.84.806a.634.634 0 00.441.175c.16 0 .32-.058.441-.175h0a.586.586 0 000-.852l-.84-.806zm-1.76-4.528h.05V3.036h-.05c-2.903 0-5.268 2.266-5.268 5.056v.05h1.249v-.05c0-2.123 1.801-3.854 4.019-3.854zm.05 1.075v-.05h-.05c-1.623 0-2.946 1.268-2.946 2.83v.05h1.249v-.05c0-.895.76-1.627 1.697-1.627h.05V5.313zm-.05-4.506c-3.114 0-5.797 1.811-6.965 4.395C6.52 2.618 3.838.807.723.807h-.05v.432h-.01V14.764h7.659V13.562H1.913V2.123c2.93.536 5.15 3.008 5.15 5.97v.05H8.314v-.05c0-3.352 2.842-6.083 6.34-6.083h.05V.807h-.05z"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth={0.1}
        />
    </svg>
);
export default SvgMesLogo;
