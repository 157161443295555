import { useEffect } from 'react';

export const useIFrameMessageLogger = () => {
    return useEffect(() => {
        if (typeof window === 'undefined' || process.env.NODE_ENV !== 'local') {
            return () => null;
        }

        const listener = (event: MessageEvent) => {
            if (process.env.IFRAME_MESSAGE_LOGGER_DEBUG === 'true') {
                // eslint-disable-next-line no-console
                console.log('received from iframe', event.data);
            }
        };

        window.addEventListener('message', listener, false);
        return () => window.removeEventListener('message', listener, false);
    }, []);
};
