import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import { ClassTeacher } from '@yandex-int/k-common/typings/api/defs/get-class-teachers';
import get from 'lodash/get';
import { createSelector } from 'reselect';

import { TeacherRow } from 'common.components/classes/typings';
import { iClass } from 'common.components/typings';
import { getClassIdFromProps } from 'utils/selectors/selectors';

import { ClassData, ClassesState } from './typings';

const classesSelectors = (storeState: unknown): ClassesState => get(storeState, 'classes');

// eslint-disable-next-line @typescript-eslint/ban-types
export const getClassData = createSelector([classesSelectors, getClassIdFromProps], (state, classId): ClassData | {} =>
    classId ? get(state, classId, {}) : {}
);

export const getClassById = createSelector(getClassData, (classData): iClass => get(classData, 'classData', {}));

export const getLoadingStatus = createSelector(
    getClassData,
    (classData): LOADING_STATUSES => get(classData, 'loadingStatus', LOADING_STATUSES.UNSENT)
);

export const getShouldLoadTeamData = createSelector(
    [getLoadingStatus],
    (loadingStatus) => loadingStatus === LOADING_STATUSES.UNSENT
);

export const getLetter = createSelector(getClassById, (classData): string | null => get(classData, 'letter', null));

export const getNumber = createSelector(getClassById, (classData): number | null => get(classData, 'number', null));

export const getStudents = createSelector(getClassById, (classData) => classData.students || null);

export const getUsePassportAccounts = createSelector(
    getClassById,
    (classData): boolean | null => classData.use_passport_accounts || null
);

export const getStudentsCount = createSelector(getStudents, (students): number => students?.length || 0);

export const getSchoolCode = createSelector(getClassById, (classData): string | null =>
    get(classData, 'school_code', null)
);

export const getNameSettingStatus = createSelector(
    getClassData,
    (classData): LOADING_STATUSES => get(classData, 'nameSettingStatus', LOADING_STATUSES.UNSENT)
);

export const getTeacherList = createSelector(
    [classesSelectors, getClassIdFromProps],
    (state, classId): Array<TeacherRow> => {
        return classId
            ? get(state, [classId, 'teachers'], [] as Array<ClassTeacher>).map((teacher) => ({
                  id: teacher.teacher_id,
                  name: teacher.personal_data.teacher_name,
                  isSupervisor: teacher.is_supervisor,
              }))
            : [];
    }
);

export const getIsTeamLoaded = createSelector(
    [getLoadingStatus],
    (status) => status === LOADING_STATUSES.SUCCESS || status === LOADING_STATUSES.ERROR
);

export const getIsEmptyTeam = createSelector(
    [getStudents, getIsTeamLoaded],
    (students, isTeamLoaded) => students?.length === 0 && isTeamLoaded
);
