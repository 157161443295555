import { postFetch } from '../fetch';
import { apiConfig } from '../config';

import { FullCLesson } from '../../../typings/';

interface PostCreateLessonByProblemIdsReq {
    problems: number[];
    owner_id: string | number,
    name: string;
    lesson_meta?: string;
    course_id: number;
    subject?: string;
}

export function postCreateClessonByProblemIds(lesson: PostCreateLessonByProblemIdsReq): Promise<FullCLesson> {
    const { apiPostCreateClessonByProblemIds } = apiConfig.getStaticUrls();

    return postFetch(apiPostCreateClessonByProblemIds, lesson);
}
