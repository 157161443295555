import { ComponentProps } from 'react';

import { compose, composeU } from '@bem-react/core';
import {
    Button as BaseButton,
    withBaseline,
    withSizeL,
    withSizeM,
    withSizeS,
    withWidthMax,
} from '@yandex-lego/components/Button/desktop';

import {
    withViewBrand,
    withViewContrast,
    withViewGhost,
    withViewMedia,
    withViewOutline,
    withViewPrimary,
    withViewSecondary,
} from './index';
export const Button = compose(
    composeU(
        withViewBrand,
        withViewPrimary,
        withViewSecondary,
        withViewOutline,
        withViewGhost,
        withViewContrast,
        withViewMedia,
    ),
    composeU(withSizeL, withSizeM, withSizeS),
    composeU(withBaseline, withWidthMax),
)(BaseButton);

export type ButtonProps = ComponentProps<typeof Button>;
