import cx from 'classnames';
import React from 'react';

import './progress-circle.scss';

interface Props {
    mix?: string;
    max: number;
    value: number;
    size?: number;
    strokeWidth?: number;
    showTitle?: boolean;
    hideMax?: boolean;
}

const MAX_PERCENT = 100;

const ProgressCircle = (props: Props) => {
    const { size = 56, strokeWidth = 4, showTitle = true, max, mix, value, hideMax } = props;

    const radius = size / 2 - strokeWidth / 2;
    const perimeter = 2 * Math.PI * radius;
    const percent = (value / max) * MAX_PERCENT;
    const offset = ((MAX_PERCENT - percent) / MAX_PERCENT) * perimeter;

    const barStrokeDashoffset = isNaN(offset) ? undefined : offset;

    return (
        <div className={cx('progress-circle', mix)}>
            {showTitle && (
                <div className="progress-circle__title" style={{ padding: strokeWidth }}>
                    {value}
                    {!hideMax && (
                        <>
                            <div className="progress-circle__title-separator">/</div>
                            {max}
                        </>
                    )}
                </div>
            )}
            <svg
                className="progress-circle__shape"
                fill="none"
                height={size}
                viewBox={`0 0 ${size} ${size}`}
                width={size}
            >
                <circle
                    className="progress-circle__track"
                    cx={size / 2}
                    cy={size / 2}
                    r={radius}
                    strokeDasharray={perimeter}
                    strokeDashoffset="0"
                    strokeWidth={strokeWidth}
                />
                <circle
                    className="progress-circle__bar"
                    cx={size / 2}
                    cy={size / 2}
                    r={radius}
                    strokeDasharray={perimeter}
                    strokeDashoffset={barStrokeDashoffset}
                    strokeWidth={strokeWidth}
                />
            </svg>
        </div>
    );
};

export default React.memo(ProgressCircle);
