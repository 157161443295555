import React from 'react';

import { useIFrameMessageLogger } from '../../utils/hooks/useIFrameMessageLogger';
import { HiddenIframe } from '../hidden-iframe/hidden-iframe';

import { Pixel } from './types';

interface Props {
    pixels: Array<Pixel> | null;
}

export const PixelsComponent = ({ pixels }: Props) => {
    useIFrameMessageLogger();

    if (!pixels || pixels.length === 0) {
        return null;
    }

    return (
        <>
            {pixels.map(({ id, pixelUrl }) => (
                <HiddenIframe id={id} key={id} url={pixelUrl} />
            ))}
        </>
    );
};
