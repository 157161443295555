export const GET_TEAM = 'LAB.CLASS.GET_TEAM';
export const GET_CLASS_REQUEST = 'LAB.CLASS.GET_CLASS_REQUEST';
export const GET_CLASS_SUCCESS = 'LAB.CLASS.GET_CLASS_SUCCESS';
export const GET_CLASS_ERROR = 'LAB.CLASS.GET_CLASS_ERROR';

export const SET_NAME_REQUEST = 'LAB.CLASS.SET_NAME_REQUEST';
export const SET_NAME_SUCCESS = 'LAB.CLASS.SET_NAME_SUCCESS';
export const SET_NAME_ERROR = 'LAB.CLASS.SET_NAME_ERROR';

export const GET_TEACHERS_REQUEST = 'LAB.CLASS.GET_TEACHERS_REQUEST';
export const GET_TEACHERS_SUCCESS = 'LAB.CLASS.GET_TEACHERS_SUCCESS';
export const GET_TEACHERS_ERROR = 'LAB.CLASS.GET_TEACHERS_ERROR';

export const REMOVE_TEACHER = 'LAB.CLASS.REMOVE_TEACHER';
export const RESTORE_TEACHER = 'LAB.CLASS.RESTORE_TEACHER';
