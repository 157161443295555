import b from 'b_';
import cx from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';

import { shuffle } from 'lodash';
import withKeyboardContext from '../../../utils/hocs/with-keyboard-context';
import { mdStatePropType } from '../../../utils/prop-types/md-state';
import { POPUP_DIRECTIONS, POPUP_POSITIONS, SELECT_THEMES } from '../../constants';
import MarkdownExtXContent from '../../markdown/_extension/markdown_extension_xcontent';
import Select, { Option } from '../../select/select';

import InlineInputTypeChoiceCompact from './inline-input_type_choice-compact';
import './inline-input_type_choice.scss';
import { getChoicesMode, CHOICES_MODE } from './inline-input_type_choice.utils';

const LETTER_WIDTH = 13;
const MAX_SELECT_WIDTH = '22em'; // если итем шире -> он становится многострочным

class InlineInputTypeChoice extends React.Component {
    static propTypes = {
        answer: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        className: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        intl: PropTypes.object.isRequired,
        keyboardContext: PropTypes.shape({
            keyboardAllowed: PropTypes.bool,
        }),
        mdState: mdStatePropType,
        onKeyDown: PropTypes.func,
        onResize: PropTypes.func,
        options: PropTypes.shape({
            placeholder: PropTypes.string,
            //eslint-disable-next-line camelcase
            virtual_keyboard: PropTypes.bool,
            choices: PropTypes.array,
        }),
        readOnly: PropTypes.bool,
        setAnswer: PropTypes.func.isRequired,
        showCorrectAnswer: PropTypes.bool,
        theme: PropTypes.string,
        userAnswer: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        isShuffledAnswers: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = { choices: [], choicesRealToShuffled: {}, choicesShuffledToReal: {} };
    }

    componentDidMount() {
        this.setChoices();
    }

    componentDidUpdate(prevProps) {
        if (this.props.options.choices !== prevProps.options.choices) {
            this.setChoices();
        }
    }

    setChoices = () => {
        const realChoices = this.props.options.choices ?? [];
        const shuffledChoices = this.props.isShuffledAnswers ? shuffle(this.props.options.choices) : realChoices;
        const choicesMap = realChoices.reduce(
            (acc, realChoice, indexRealChoice) => {
                const indexShuffledChoice = shuffledChoices.findIndex(
                    (shuffledChoice) => shuffledChoice === realChoice
                );
                acc.choicesRealToShuffled[indexRealChoice] = indexShuffledChoice;
                acc.choicesShuffledToReal[indexShuffledChoice] = indexRealChoice;
                return acc;
            },
            { choicesRealToShuffled: {}, choicesShuffledToReal: [] }
        );
        this.setState({
            choices: shuffledChoices,
            ...choicesMap,
        });
    };

    getInputValue = ({ answer, userAnswer, showCorrectAnswer }) => {
        const userAnswerAsNumber = typeof userAnswer === 'number' || typeof userAnswer === 'string' ? userAnswer : null;

        const realAnswerIndex = showCorrectAnswer ? answer : userAnswerAsNumber;
        return this.state.choicesRealToShuffled[realAnswerIndex];
    };

    getPlaceholder = () => {
        const { options, intl } = this.props;

        const placeholder = get(options, 'placeholder', null);

        if (placeholder === null) {
            return intl.formatMessage({ id: 'select.placeholder.short' });
        }

        return placeholder;
    };

    setNewAnswer = (value) => {
        const {
            options: { choices },
        } = this.props;
        const choicesMode = getChoicesMode(choices);

        if (value !== undefined && value !== null && value !== '' && choicesMode === CHOICES_MODE.SIMPLE) {
            value = Number(value);
        }

        const realValue = this.state.choicesShuffledToReal[value];
        this.props.setAnswer(realValue);
    };

    onChange = ({ target: { value } }) => {
        this.setNewAnswer(value);
    };

    isUsingKeyboardSelect() {
        const {
            options: { virtual_keyboard: virtualKeyboard },
            keyboardContext: { keyboardAllowed },
        } = this.props;

        return virtualKeyboard && keyboardAllowed;
    }

    render() {
        const { className, onKeyDown, options, readOnly, mdState, theme = 'normal', onResize } = this.props;
        const value = this.getInputValue(this.props);
        const choicesMode = getChoicesMode(options.choices);

        // при первичном рендере select рассчитывает свои размеры, используя дочерние компоненты
        if (this.state.choices.length === 0) {
            return null;
        }

        const choicesData = this.state.choices.map((choice, i) =>
            choicesMode === CHOICES_MODE.SIMPLE ? { id: i, name: choice } : choice
        );

        const controlClass = b('inline-input', {
            'read-only': readOnly,
            theme,
        });

        return (
            <span className={cx(className, controlClass)}>
                {this.isUsingKeyboardSelect() ? (
                    <InlineInputTypeChoiceCompact {...this.props} />
                ) : (
                    <Select
                        disabled={readOnly}
                        height="5"
                        letterWidth={LETTER_WIDTH}
                        maxWidth={MAX_SELECT_WIDTH}
                        notFocusAfterUpdate={true}
                        onChange={this.onChange}
                        onKeyDown={onKeyDown}
                        onResize={onResize}
                        placeholder={this.getPlaceholder()}
                        popupDirection={POPUP_DIRECTIONS.BOTTOM}
                        popupMix="inline-input__popup"
                        popupPosition={POPUP_POSITIONS.PORTAL_LAYER}
                        theme={SELECT_THEMES.MARKER}
                        value={value}
                    >
                        {choicesData.map((choice) => (
                            <Option key={choice.id} value={choice.id}>
                                <MarkdownExtXContent mdState={mdState}>{choice.name}</MarkdownExtXContent>
                            </Option>
                        ))}
                    </Select>
                )}
            </span>
        );
    }
}

export default compose(withKeyboardContext, injectIntl)(InlineInputTypeChoice);
