import React, { useEffect, useState } from 'react';

export default function useSoundSeek(ref: React.RefObject<HTMLAudioElement>, enabled: boolean): number {
    const [seek, setSeek] = useState(0);
    useEffect(() => {
        let didCancel = false;

        function updateSeek(): void {
            if (!didCancel) {
                if (ref.current) {
                    setSeek(ref.current.currentTime);
                }
                requestAnimationFrame(updateSeek);
            }
        }

        if (enabled) {
            requestAnimationFrame(updateSeek);
        }

        return (): void => {
            didCancel = true;
        };
    }, [ref, enabled]);

    return seek;
}
