import { InferValuesTypes } from '@yandex-int/k-common';

import * as actions from './actions';
import * as constants from './constants';
import { FileMeta } from './types';

const GET_META_RETRY_NUMBER = 5;

export const initialState = {
    files: {} as Record<string, { retriesLeft?: number } & FileMeta>,
};

export type FilesMetaActions = ReturnType<InferValuesTypes<typeof actions>>;

const filesMeta = (state = initialState, action: FilesMetaActions) => {
    switch (action.type) {
        case constants.UPDATE_META: {
            const newFilesMeta = action.files.reduce((prev, file) => {
                if (file.previewUrl) {
                    prev[file.key] = file;
                } else {
                    prev[file.key] = state.files[file.key] || file;

                    if (typeof prev[file.key].retriesLeft === 'undefined') {
                        prev[file.key].retriesLeft = GET_META_RETRY_NUMBER;
                    } else if (Number(prev[file.key].retriesLeft) > 0) {
                        prev[file.key].retriesLeft = Number(prev[file.key].retriesLeft) - 1;
                    }
                }

                return prev;
            }, state.files);

            return Object.assign({}, state, { files: newFilesMeta });
        }

        default:
            return state;
    }
};

export default filesMeta;
