import { AWARD_SOURCE, AwardItem } from '../typings';

// eslint-disable-next-line complexity
export function getAwardLink(awardItem: AwardItem): string {
    const source = awardItem.award.view_details?.source;

    switch (source){
        case AWARD_SOURCE.PLUS:
            const promoCode = awardItem.promo ? awardItem.promo.promo : '';
            return `https://plus.yandex.ru/gift?promocode=${promoCode}`;
        case AWARD_SOURCE.SYNCHRONIZE:
            return awardItem.shared_string || '';
        case AWARD_SOURCE.FOXFORD:
            return awardItem.promo ? awardItem.promo.promo : '';
        case AWARD_SOURCE.ROVER:
            return awardItem.shared_string || '';
        default:
            return '';
    }
}

export const SEARCH_PARAMS_GET_AWARD = 'getPrize';
