const get = require('lodash/get');

const { BUNKER_NODE_KEYS, FROM_PROJECT } = require('../constants');

const { getBunkerNode } = require('./bunker-nodes/get-bunker-node');

function findConfigByProject(bunkerNodes, project) {
    return bunkerNodes
        ? Object.values(bunkerNodes.list).find((item) => {
            return item.slug === project;
        })
        : null;
}

function getRedirectUrlByProject(req, query) {
    const nodes = get(req, 'bunker.nodes');
    const logger = get(req, 'app.locals.logger');

    const specProjectConfig = nodes ? getBunkerNode(nodes, BUNKER_NODE_KEYS.SPEC_PROJECT_CONFIG, logger.error) : null;
    const specProjectConfigData = specProjectConfig ? specProjectConfig.data : null;
    const project = new URLSearchParams(query).get(FROM_PROJECT);

    const configByProject = findConfigByProject(specProjectConfigData, project);
    return configByProject ? configByProject.redirectAfterRegistration : null;
}

module.exports = {
    getRedirectUrlByProject,
    findConfigByProject
};
