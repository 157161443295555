import PropTypes from 'prop-types';
import React from 'react';

import { formatNumber, formatWithCaret, getCurrentCaretPosition, setCaretPosition } from './utils';

export const NUMBER_REGEXP = /^-?[\d ]*[.,]?[\d ]*$/;

class InputNumber extends React.Component {
    static propTypes = {
        onChange: PropTypes.func,
        readOnly: PropTypes.bool,
        value: PropTypes.string,
        maxLength: PropTypes.number,
        showCorrectAnswer: PropTypes.bool,
    };

    state = {
        caretPositionToSet: null,
    };

    getSnapshotBeforeUpdate() {
        if (this.isFocused()) {
            // Keep caret position if change is rejected, e.g. when allow only numbers
            // Reference https://github.com/facebook/react/issues/955#issuecomment-160831548
            const oldLength = this.inputRef.current.value.length;
            const oldIdx = getCurrentCaretPosition(this.inputRef.current);
            const caretPositionToSet = Math.max(0, this.getValue().length - oldLength + oldIdx);

            return { caretPositionToSet };
        }

        return null;
    }

    componentDidUpdate(_prevProps, _prevState, snapshot) {
        const { caretPositionToSet } = this.state;

        if (caretPositionToSet !== null) {
            this.setCaretPosition(caretPositionToSet);
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ caretPositionToSet: null });
        } else if (snapshot && 'caretPositionToSet' in snapshot) {
            this.setCaretPosition(snapshot.caretPositionToSet);
        }

        this.formatNumber();
    }

    inputRef = React.createRef();

    focus = () => {
        this.inputRef.current.focus();
    };

    isFocused = () => {
        return this.inputRef.current && document.activeElement === this.inputRef.current;
    };

    setCaretPosition = (caretPosition) => {
        if (this.isFocused()) {
            setCaretPosition(this.inputRef.current, caretPosition);
        }
    };

    formatNumber = () => {
        const caretPosition = this.isFocused() ? getCurrentCaretPosition(this.inputRef.current) : null;
        const formattedWithCaret = formatWithCaret(this.getValue(), caretPosition);

        if (formattedWithCaret) {
            this.updateValue(formattedWithCaret.value, formattedWithCaret.caretPosition);
        }
    };

    getValue = () => {
        const { value, showCorrectAnswer } = this.props;

        if (showCorrectAnswer) {
            return formatNumber(value) || '';
        }

        return value;
    };

    updateValue = (value, caretPositionToSet = null) => {
        const { maxLength } = this.props;

        if (caretPositionToSet !== null) {
            this.setState({ caretPositionToSet });
        }

        const onlyDigits = value.replace(/[^\d]/g, '');

        if (onlyDigits.length <= maxLength) {
            this.props.onChange({ target: { value } });
        }
    };

    checkInputFormat = (value) => {
        return NUMBER_REGEXP.test(value);
    };

    handleChange = ({ target }) => {
        if (this.checkInputFormat(target.value)) {
            this.updateValue(target.value);
        } else {
            const value = this.getValue();
            this.updateValue(value);
        }
    };

    getCaretPosition = () => {
        const { readOnly } = this.props;
        const value = this.getValue();

        if (readOnly === true) {
            return [value.length, value.length];
        }

        const { selectionStart, selectionEnd } = this.inputRef.current;

        return [selectionStart, selectionEnd];
    };

    insertText = (text) => {
        const value = this.getValue();
        const [selectionStart, selectionEnd] = this.getCaretPosition();

        const beforeSelection = value.slice(0, selectionStart);
        const afterSelection = value.slice(selectionEnd, value.length);
        const totalValue = beforeSelection + text + afterSelection;

        if (this.checkInputFormat(totalValue)) {
            this.updateValue(totalValue, selectionStart + 1);
        }
    };

    backspace = () => {
        const value = this.getValue();
        const [selectionStart, selectionEnd] = this.getCaretPosition();

        let start = selectionStart;
        if (start === selectionEnd) {
            start -= 1;
        }
        start = Math.max(0, start);

        const beforeSelection = value.slice(0, start);
        const afterSelection = value.slice(selectionEnd, value.length);

        this.updateValue(beforeSelection + afterSelection, start);
    };

    render() {
        const props = { ...this.props };
        delete props.showCorrectAnswer;

        return (
            <input
                {...props}
                maxLength={undefined}
                onChange={this.handleChange}
                ref={this.inputRef}
                type="text"
                value={this.getValue()}
            />
        );
    }
}

export default InputNumber;
