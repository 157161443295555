import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import produce from 'immer';

import * as constants from './constants';
import { TeacherRegistrationActions, TeacherRegistrationStore } from './typings';

const initialState: TeacherRegistrationStore = {
    loadingStatus: LOADING_STATUSES.UNSENT,
    applyCodeStatus: LOADING_STATUSES.UNSENT,
};

const teacherRegistrationReducer = (
    state: TeacherRegistrationStore = initialState,
    action: TeacherRegistrationActions
) => {
    // eslint-disable-next-line complexity
    return produce(state, (draft) => {
        switch (action.type) {
            case constants.FINISH_REGISTRATION:
            case constants.FINISH_REGISTRATION_BY_CODE:
            case constants.FINISH_REGISTRATION_BY_LINK:
                draft.loadingStatus = LOADING_STATUSES.LOADING;
                break;

            case constants.FINISH_REGISTRATION_SUCCESS:
            case constants.FINISH_REGISTRATION_BY_CODE_SUCCESS:
                draft.loadingStatus = LOADING_STATUSES.SUCCESS;
                break;

            case constants.FINISH_REGISTRATION_ERROR:
            case constants.FINISH_REGISTRATION_BY_CODE_ERROR:
                draft.loadingStatus = LOADING_STATUSES.ERROR;
                break;

            case constants.APPLY_CODE_REQUEST:
                draft.applyCodeStatus = LOADING_STATUSES.LOADING;
                break;

            case constants.APPLY_CODE_SUCCESS:
                draft.applyCodeStatus = LOADING_STATUSES.SUCCESS;
                draft.createdClass = action.team;
                break;

            case constants.APPLY_CODE_ERROR:
                draft.applyCodeStatus = LOADING_STATUSES.ERROR;
                break;

            // no default
        }
    });
};

export default teacherRegistrationReducer;
