export const SET_ME = 'USER.SET_ME';
export const SET_TEACHER_PROFILE = 'USER.SET_TEACHER_PROFILE';
export const SET_SENDER_PROFILE = 'USER.SET_SENDER_PROFILE';
export const SET_PARENT_CHILDREN = 'USER.SET_PARENT_CHILDREN';
export const SET_PARENT_PROFILE = 'USER.SET_PARENT_PROFILE';
export const SET_TEAMS = 'USER.SET_CLASSES';
export const SET_HINTS_VIEWED_MAP = 'USER.SET_HINTS_VIEWED_MAP';
export const SET_WIZARD_VARIABLES = 'USER.SET_WIZARD_VARIABLES';
export const CLEAR_WIZARD_VARIABLES = 'USER.CLEAR_WIZARD_VARIABLES';
export const SET_WIZARD_HINTS = 'USER.SET_WIZARD_HINTS';

export const ADD_VIEWED_HINT_REQUEST = 'USER.ADD_VIEWED_HINT_REQUEST';
export const ADD_VIEWED_HINT_SUCCESS = 'USER.ADD_VIEWED_HINT_SUCCESS';
export const ADD_VIEWED_HINT_ERROR = 'USER.ADD_VIEWED_HINT_ERROR';

export const DELETE_CLASS_REQUEST = 'USER.DELETE_CLASS_REQUEST';
export const DELETE_CLASS_SUCCESS = 'USER.DELETE_CLASS_SUCCESS';

export const SET_ME_REQUEST = 'USER.SET_ME_REQUEST';
export const SET_ME_SUCCESS = 'USER.SET_ME_SUCCESS';

export const GET_LOCALITY_NAME_REQUEST = 'USER.GET_LOCALITY_NAME_REQUEST';
export const GET_LOCALITY_NAME_SUCCESS = 'USER.GET_LOCALITY_NAME_SUCCESS';

export const SET_CLASSES_REQUEST = 'USER.SET_CLASSES_REQUEST';
export const SET_CLASSES_SUCCESS = 'USER.SET_CLASSES_SUCCESS';

export const GET_ARCHIVED_CLASSES_REQUEST = 'USER.GET_ARCHIVED_CLASSES_REQUEST';
export const GET_ARCHIVED_CLASSES_SUCCESS = 'USER.GET_ARCHIVED_CLASSES_SUCCESS';

export const ARCHIVATE_CLASS_REQUEST = 'USER.ARCHIVATE_CLASS_REQUEST';
export const ARCHIVATE_CLASS_SUCCESS = 'USER.ARCHIVATE_CLASS_SUCCESS';
export const RECOVER_CLASS_REQUEST = 'USER.RECOVER_CLASS_REQUEST';
export const RECOVER_CLASS_SUCCESS = 'USER.RECOVER_CLASS_SUCCESS';
export const SYNC_TEAM_REQUEST = 'USER.SYNC_TEAM_REQUEST';
export const SYNC_TEAM_SUCCESS = 'USER.SYNC_TEAM_SUCCESS';
export const SYNC_TEAM_ERROR = 'USER.SYNC_TEAM_ERROR';
export const TEAM_SYNC_STATUSES_SUCCESS = 'USER.TEAM_SYNC_STATUSES_SUCCESS';

export const SAVE_SALES_CODE = 'USER.SAVE_SALES_CODE';

export const REFERAL_CODE_REQUEST = 'USER.REFERAL_CODE_REQUEST';
export const REFERAL_CODE_SUCCESS = 'USER.REFERAL_CODE_SUCCESS';
export const REFERAL_CODE_ERROR = 'USER.REFERAL_CODE_ERROR';

export const GRANT_USER_RIGHTS_REQUEST = 'USER.GRANT_USER_RIGHTS_REQUEST';
export const GRANT_USER_RIGHTS_SUCCESS = 'USER.GRANT_USER_RIGHTS_SUCCESS';
export const GRANT_USER_RIGHTS_ERROR = 'USER.GRANT_USER_RIGHTS_ERROR';
