import React from 'react';

import IntlMessageNotificationBody from './body/intl-message-notification-body';
import NetworkErrorNotificationBody from './body/network-error-notification-body';
import SimpleStringNotificationBody from './body/simple-string-notification-body';
import {
    NETWORK_ERROR_NOTIFICATION_ID,
    SIMPLE_STRING_NOTIFICATION_ID,
    INTL_MESSAGE_NOTIFICATION_ID,
} from './constants';

export const notificationBodysStore = {
    [NETWORK_ERROR_NOTIFICATION_ID]: <NetworkErrorNotificationBody />,
    [INTL_MESSAGE_NOTIFICATION_ID]: <IntlMessageNotificationBody />,
    [SIMPLE_STRING_NOTIFICATION_ID]: <SimpleStringNotificationBody />,
};
