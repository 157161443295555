import PropTypes from 'prop-types';
import React from 'react';

import './class-number-selector__number-option.scss';

const ClassNumberSelectorNumberOption = ({ value: { title } }) => (
    <div className="class-number-selector__number-option">{title}</div>
);

ClassNumberSelectorNumberOption.propTypes = {
    value: PropTypes.shape({
        title: PropTypes.string.isRequired,
    }),
};

export default ClassNumberSelectorNumberOption;
