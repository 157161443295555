import b from 'b_';
import { Modal, ModalCloseProps, SimpleCallback } from 'platform-components';
import { CloseButton } from 'platform-components/src/components/close-button/_view/close-button_view_outer';
import React from 'react';

import './team-builder__content.scss';

interface Props {
    canClose: boolean;
    state: string;
    handleClose: SimpleCallback;
    isStarted: boolean;
    component: JSX.Element | null;
}

export const TeamBuilderContent = ({ component, state, handleClose, isStarted, canClose }: Props) => {
    return (
        <Modal
            Close={(closeProps: ModalCloseProps) => (
                <CloseButton {...closeProps} position={CloseButton.POSITION.ABSOLUTE} />
            )}
            hasClose={canClose}
            mix={b('team-builder-content', 'modal', { state })}
            onRequestClose={handleClose}
            opened={isStarted}
        >
            {component}
        </Modal>
    );
};
