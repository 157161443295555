import { createSelector } from 'reselect';

import { PixelsStore } from './types';

const pixelStateSelector = <T extends { pixelStore: PixelsStore }>(store: T): PixelsStore => store.pixelStore;

export const getAllPixels = createSelector([pixelStateSelector], (state) => Object.values(state.pixels));

export const getAllPixelsMap = createSelector([pixelStateSelector], (state) => state.pixels);

export const getPixelByRefererOrUtm = createSelector([getAllPixelsMap], (pixels) => {
    if (typeof document === 'undefined') {
        return null;
    }
    const referer = document.referrer || '';
    const search = new URL(document.location.toString()).searchParams;
    const utmSource = search.get('utm_source') || '';

    return Object.values(pixels).filter((pixel) => {
        return (
            (pixel.referers || []).some((ref) => referer.includes(ref)) ||
            (pixel.utmSources || []).some((utm) => utmSource.includes(utm))
        );
    });
});
