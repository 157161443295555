import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import React, { useEffect, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Interpreter } from 'xstate';

import { getIsInStudentRegistrationLinkExperiment } from 'common.components/user/selectors';
import * as coursesTemplatesActions from 'store/courses-templates/actions';
import * as coursesTemplatesSelectors from 'store/courses-templates/selectors';

import * as selectors from '../selectors';
import { TeamBuilder, TeamBuilderProps } from '../team-builder';
import { TEAM_BUILDER_INNER_STEPS } from '../team-builder-machine';
import {
    MachineContext,
    MachineEvents,
    TEAM_BUILDER_ENTRY_STEPS,
    TEAM_BUILDER_STEPS,
    TeamBuilderModeContainerInterface,
    TeamBuilderTeamData,
} from '../typings';

interface Props extends Omit<TeamBuilderProps, 'loadTemplates' | 'shouldLoadTemplates'> {
    machineState: TEAM_BUILDER_STEPS | TEAM_BUILDER_INNER_STEPS;
    send: Interpreter<MachineContext, any, MachineEvents>['send'];
    entryPoint: TEAM_BUILDER_ENTRY_STEPS | null;
    hasLetter: boolean;
    hasSchool: boolean;
    hasTeam: boolean;
    teamData: TeamBuilderTeamData | null;
    handleTemplatesLoaded: (loadingStatus: LOADING_STATUSES) => void;
}

export const TeamBuilderModeCreateTeamContainer = React.forwardRef<TeamBuilderModeContainerInterface, Props>(
    (props: Props, ref) => {
        const { machineState, entryPoint, teamData, hasTeam, hasSchool, handleTemplatesLoaded, send, ...otherProps } =
            props;
        const dispatch = useDispatch();

        const templatesSource = { classId: null, grade: teamData?.teamLevel };
        const hasTemplates = useSelector((store) => selectors.getHasTemplates(store, templatesSource));
        const templatesLoadingStatus = useSelector((store) =>
            coursesTemplatesSelectors.getCoursesTemplatesLoadingStatus(store, templatesSource)
        );
        const shouldLoadTemplates = useSelector((store) => selectors.getShouldLoadTemplates(store, templatesSource));
        const isInStudentLinkExperiment = useSelector(getIsInStudentRegistrationLinkExperiment);

        useEffect(
            function updateChangedData() {
                send({
                    type: 'UPDATE',
                    newContext: {
                        hasTemplates,
                        hasSchool,
                        hasTeam,
                        hasLetter: false,
                        hasSubjects: false,
                        hasStudents: false,
                        inStudentLinkExperiment: isInStudentLinkExperiment,
                    },
                });
            },
            [hasTemplates, hasSchool, hasTeam, send, isInStudentLinkExperiment]
        );

        useEffect(() => {
            handleTemplatesLoaded(templatesLoadingStatus);
        }, [handleTemplatesLoaded, templatesLoadingStatus]);

        const resetMachine = () => {
            send({
                type: 'RESET',
                newContext: {
                    entryPoint,
                    hasLetter: false,
                    hasSchool,
                    hasTeam,
                    hasSubjects: false,
                    hasStudents: false,
                    hasTemplates,
                    grade: null,
                    hasError: false,
                    passedSteps: [],
                    hasCode: false,
                    inStudentLinkExperiment: isInStudentLinkExperiment,
                },
            });
        };

        useImperativeHandle(ref, () => ({
            resetMachine,
        }));

        const loadTemplates = () => {
            dispatch(coursesTemplatesActions.getCoursesTemplatesRequest(templatesSource));
        };

        return (
            <TeamBuilder
                {...otherProps}
                loadTemplates={loadTemplates}
                machineState={machineState as TEAM_BUILDER_STEPS | TEAM_BUILDER_INNER_STEPS}
                shouldLoadTemplates={shouldLoadTemplates}
            />
        );
    }
);
