import { withBemMod } from '@bem-react/core';
import { cnButton } from '@yandex-lego/components/Button';
import React from 'react';

import { BUTTON_ACCENT } from '../types';

export interface ButtonAccentNormalProps {
    accent?: BUTTON_ACCENT.NORMAL;
}

export const withAccentNormal = withBemMod<ButtonAccentNormalProps>(
    cnButton(),
    { accent: BUTTON_ACCENT.NORMAL },
    (Button) =>
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ({ accent, ...props }: ButtonAccentNormalProps) => <Button {...props} />
);
