import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClessonRecommendation, Recommendation } from '@yandex-int/k-common';
import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';

import { CourseRecommendationData, CourseRecommendationsStore } from 'store/recommendations/typings';

const initialState: CourseRecommendationsStore = {
    courseRecommendations: {},
    clessonRecommendationLinks: {},
};

const initialCourseRecommendationData: CourseRecommendationData = {
    loadingStatus: LOADING_STATUSES.UNSENT,
    recommendations: [],
};

/* eslint-disable @typescript-eslint/no-unused-vars */
const slice = createSlice({
    name: 'COURSE_RECOMMENDATIONS',
    initialState: initialState,
    reducers: {
        getCourseRecommendationsRequest: (state, action: PayloadAction<{ courseId: number }>) => {
            const { courseId } = action.payload;

            if (!state.courseRecommendations[courseId]) {
                state.courseRecommendations[courseId] = { ...initialCourseRecommendationData };
            }
            state.courseRecommendations[courseId].loadingStatus = LOADING_STATUSES.LOADING;
        },
        getCourseRecommendationsSuccess: (
            state,
            action: PayloadAction<{ courseId: number; recommendations: Recommendation[] }>
        ) => {
            const { courseId, recommendations } = action.payload;
            const data = state.courseRecommendations[courseId] || { ...initialCourseRecommendationData };
            data.loadingStatus = LOADING_STATUSES.SUCCESS;
            data.recommendations = recommendations;
            state.courseRecommendations[courseId] = data;
        },
        getCourseRecommendationsError: (state, action: PayloadAction<{ courseId: number }>) => {
            const { courseId } = action.payload;
            const data = state.courseRecommendations[courseId] || { ...initialCourseRecommendationData };
            data.loadingStatus = LOADING_STATUSES.ERROR;
            state.courseRecommendations[courseId] = data;
        },

        setRecommendationLessonLink: (
            state,
            action: PayloadAction<{ clessonId: number; recommendationId: string; recommendationGroupId: string }>
        ) => {
            // saga
        },

        getRecommendationLessonLinkRequest: (state, action: PayloadAction<{ clessonId: number }>) => {
            // saga
        },
        getRecommendationLessonLinkSuccess: (
            state,
            action: PayloadAction<{ clessonId: number; link: ClessonRecommendation }>
        ) => {
            const { clessonId, link } = action.payload;

            state.clessonRecommendationLinks[clessonId] = link;
        },
    },
});
/* eslint-enable @typescript-eslint/no-unused-vars */

export const courseRecommendationsActions = slice.actions;
export const courseRecommendationsReducer = slice.reducer;
