import {
    Team,
    Me,
    SYNC_STATUS,
    UserRights,
    TeacherProfile,
    SenderProfile,
    Child,
    ParentProfile,
} from '@yandex-int/k-common/typings';
import { SimpleCallback } from 'platform-components';

import * as constants from './constants';
import { Hint } from './typings';

export const setMe = (me: Me) => {
    return { type: constants.SET_ME, me };
};

export const setTeacherProfile = (teacherProfile: TeacherProfile) => {
    return { type: constants.SET_TEACHER_PROFILE, teacherProfile };
};

export const setSenderProfile = (senderProfile: SenderProfile) => {
    return { type: constants.SET_SENDER_PROFILE, senderProfile };
};

export const setParentChildren = (children: Array<Child>) => {
    return { type: constants.SET_PARENT_CHILDREN, children };
};

export const setParentProfile = (parentProfile: ParentProfile) => {
    return { type: constants.SET_PARENT_PROFILE, parentProfile };
};

export const setHintsViewedMap = (hints: Array<Hint>) => <const>{ type: constants.SET_HINTS_VIEWED_MAP, hints };

export const setWizardVariables = (hint: Hint, onSuccess?: SimpleCallback) =>
    <const>{ type: constants.SET_WIZARD_VARIABLES, hint, onSuccess };

export const clearWizardVariables = () => <const>{ type: constants.CLEAR_WIZARD_VARIABLES };

export const setWizardHints = (hint: Hint) => <const>{ type: constants.SET_WIZARD_HINTS, hint };

export const addViewedHint = (id: string, data: any, onAddViewedHint?: SimpleCallback) =>
    <const>{ type: constants.ADD_VIEWED_HINT_REQUEST, id, data, onAddViewedHint };

export const addViewedHintSuccess = (hints: Array<Hint>, id: string) =>
    <const>{ type: constants.ADD_VIEWED_HINT_SUCCESS, hints, id };

export const addViewedHintError = (error: Error, id: string) =>
    <const>{ type: constants.ADD_VIEWED_HINT_ERROR, error, id };

export const deleteClassRequest = (classItem: Team) => <const>{ type: constants.DELETE_CLASS_REQUEST, classItem };

export const deleteClassSuccess = (classId: number) => <const>{ type: constants.DELETE_CLASS_SUCCESS, classId };

export const setMeRequest = () => <const>{ type: constants.SET_ME_REQUEST };

export const setMeSuccess = () => <const>{ type: constants.SET_ME_SUCCESS };

export const getLocalityNameRequest = (geoId: number) => <const>{ type: constants.GET_LOCALITY_NAME_REQUEST, geoId };

export const getLocalityNameSuccess = (localityName: string) =>
    <const>{ type: constants.GET_LOCALITY_NAME_SUCCESS, localityName };

export const setClassesRequest = () => <const>{ type: constants.SET_CLASSES_REQUEST };

export const setTeamsSuccess = () => <const>{ type: constants.SET_CLASSES_SUCCESS };

export const getArchivedClassesRequest = () => <const>{ type: constants.GET_ARCHIVED_CLASSES_REQUEST };

export const getArchivedClassesSuccess = (archivedClasses: Array<Team>) =>
    <const>{ type: constants.GET_ARCHIVED_CLASSES_SUCCESS, archivedClasses };

export const archivateClassRequest = ({ classItem, rollback }: { classItem: Team; rollback: SimpleCallback }) =>
    <const>{ type: constants.ARCHIVATE_CLASS_REQUEST, classItem, rollback };

export const archivateClassSuccess = () => <const>{ type: constants.ARCHIVATE_CLASS_SUCCESS };

export const recoverClassRequest = (classItem: Team) => <const>{ type: constants.RECOVER_CLASS_REQUEST, classItem };

export const recoverClassSuccess = () => <const>{ type: constants.RECOVER_CLASS_SUCCESS };

export const saveSalesCode = (salesCode: string) => <const>{ type: constants.SAVE_SALES_CODE, salesCode };

export const setTeams = (teams: Array<Team>) => <const>{ type: constants.SET_TEAMS, teams };

export const syncTeamRequest = (teamId: number) => <const>{ type: constants.SYNC_TEAM_REQUEST, teamId };

export const syncTeamSuccess = (teamId: number) => <const>{ type: constants.SYNC_TEAM_SUCCESS, teamId };

export const syncTeamError = (teamId: number) => <const>{ type: constants.SYNC_TEAM_ERROR, teamId };

export const teamSyncStatusesSuccess = (teamIdToStatus: Record<number, SYNC_STATUS>) =>
    <const>{ type: constants.TEAM_SYNC_STATUSES_SUCCESS, teamIdToStatus };

export const referalCodeRequest = (referalCode: string, url: string) =>
    <const>{ type: constants.REFERAL_CODE_REQUEST, referalCode, url };

export const referalCodeSuccess = () => <const>{ type: constants.REFERAL_CODE_SUCCESS };

export const referalCodeError = () => <const>{ type: constants.REFERAL_CODE_ERROR };

export const grantUserRightsRequest = (userRights: UserRights) =>
    <const>{ type: constants.GRANT_USER_RIGHTS_REQUEST, userRights };

export const grantUserRightsSuccess = () => <const>{ type: constants.GRANT_USER_RIGHTS_SUCCESS };

export const grantUserRightsError = () => <const>{ type: constants.GRANT_USER_RIGHTS_ERROR };
