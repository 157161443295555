import cx from 'classnames';
import { Text, Button, SimpleText, SimpleCallback } from 'platform-components';
import React from 'react';

import './add-child-form.scss';

interface Props {
    mix?: string;
    handleAddByCode: SimpleCallback;
    handleCreateChild: SimpleCallback;
}

const AddChildForm = ({ mix, handleCreateChild, handleAddByCode }: Props) => {
    return (
        <div className={cx('add-child-form', mix)}>
            <div className="add-child-form__title">
                <Text id="addChildForm.title" size={Text.SIZE.XXL} type={Text.TYPE.MARKDOWN} />
            </div>
            <Button className="add-child-form__button" onClick={handleAddByCode} view={Button.VIEW.SECONDARY}>
                <SimpleText id="addChildForm.button.yes" />
            </Button>
            <Button className="add-child-form__button" onClick={handleCreateChild} view={Button.VIEW.SECONDARY}>
                <SimpleText id="addChildForm.button.no" />
            </Button>
        </div>
    );
};

export default AddChildForm;
