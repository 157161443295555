import { withBemMod } from '@bem-react/core';
import { cnButton } from '@yandex-lego/components/Button/desktop';

import './Button_view_secondary.css';

export type WithViewSecondaryProps = {
    view?: 'secondary';
};

export const withViewSecondary = withBemMod<WithViewSecondaryProps>(
    cnButton(),
    {
        view: 'secondary',
    },
);
