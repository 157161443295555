import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import { SimpleCallback } from 'platform-components';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SubjectsForm from 'common.components/subjects-form/subjects-form';
import * as coursesTemplatesSelectors from 'store/courses-templates/selectors';
import { YM_LOCATION } from 'utils/ym-constants';

import { teamBuilderActions } from '../actions';
import * as selectors from '../selectors';

interface Props {
    onAssign: SimpleCallback;
    onNext: SimpleCallback;
    shouldLoadTemplates: boolean;
    loadTemplates: SimpleCallback;
}

export const TeamBuilderCreateSubjects = (props: Props) => {
    const { onNext, onAssign, shouldLoadTemplates, loadTemplates } = props;
    const dispatch = useDispatch();
    const { teamLetter, teamLevel } = useSelector(selectors.getTeamData) || {};
    const templates = useSelector((store) =>
        coursesTemplatesSelectors.getCoursesTemplatesList(store, { grade: teamLevel, classId: null })
    );
    const templatesLoadingStatus = useSelector((store) =>
        coursesTemplatesSelectors.getCoursesTemplatesLoadingStatus(store, { grade: teamLevel, classId: null })
    );
    const submittingStatus = useSelector(selectors.getSubmittingStatus);
    const subjectsIds = useSelector(selectors.getSelectedTemplatesIds);

    const handleChangeSubjects = (selectedIds: Array<number>) => {
        dispatch(teamBuilderActions.setSelectedTemplatesIds({ selectedTemplatesIds: selectedIds }));
        onAssign();
    };

    const handleSubmitSubjects = () => {
        onNext();
    };

    useEffect(() => {
        if (shouldLoadTemplates && loadTemplates) {
            loadTemplates();
        }
    }, [shouldLoadTemplates, loadTemplates]);

    return (
        <SubjectsForm
            coursesTemplates={templates || []}
            isLoading={templatesLoadingStatus === LOADING_STATUSES.LOADING}
            isSubmitting={submittingStatus === LOADING_STATUSES.LOADING}
            locationForMetrika={YM_LOCATION.NEW_TEAM}
            mix="team-builder__subjects"
            onChange={handleChangeSubjects}
            onSubmit={handleSubmitSubjects}
            selectedSubjectIds={subjectsIds || []}
            team={{ number: teamLevel, letter: teamLetter || '' }}
        />
    );
};
