import { withBemMod } from '@bem-react/core';
import { cnLink } from '@yandex-lego/components/Link/desktop';

import './Link_theme_link.css';

export type WithThemeLinkProps = {
    theme?: 'link';
};

export const withThemeLink = withBemMod<WithThemeLinkProps>(cnLink(), {
    theme: 'link',
});
