import mapKeys from 'lodash/mapKeys';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ym, { YMInitializer as YM } from 'react-yandex-metrika';

import { getUserSessionId, getUserSessionParams } from '../../../utils/user-session';
import { getMe, getMyGrade } from '../../user/selectors';

function getDate(string) {
    const date = new Date(string);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return {
        day,
        month,
        year,
        weekday: date.getDay(),
        date: `${day}.${month}.${year}`,
    };
}

class PageMetrika extends Component {
    static propTypes = {
        me: PropTypes.object,
        grade: PropTypes.number,
    };

    static contextTypes = {
        metrika: PropTypes.object.isRequired,
        service: PropTypes.string.isRequired,
    };

    componentDidMount() {
        this.setParams();
    }

    setParams = () => {
        // eslint-disable-line complexity
        const me = this.props.me || {};
        const grade = this.props.grade;

        const { cookie } = document;

        let role;

        const experimentBoxes = this.context.metrika.experimentBoxes || [];
        const abTestId = experimentBoxes.map((box) => box.split(',')[0]).filter(Boolean);

        if (!me.username) {
            return ym('params', {
                abTestId,
                username: 'anonymous user',
            });
        }

        if (me.is_content_manager) {
            role = 'cm';
        } else {
            role = me.is_teacher ? 'teacher' : 'student';
        }

        ym('setUserID', me.id);

        const userSessionId = getUserSessionId();
        const userSessionParams = mapKeys(getUserSessionParams(), (_, key) => {
            return `user_session_param_${key}`;
        });

        /* eslint-disable camelcase */
        return ym('params', {
            abTestId,
            date_joined: me.date_joined ? getDate(me.date_joined) : null,
            grade,
            is_parent: me.is_parent,
            is_teacher: me.is_teacher,
            pin_auth: Boolean(cookie.match('student-token') || cookie.match('teacher-token')),
            role,
            user_id: me.id,
            user_session_id: userSessionId,
            ...userSessionParams,
        });
        /* eslint-enable camelcase */
    };

    render() {
        const { metrika, service } = this.context;
        const metrikaId = (metrika.idByService && metrika.idByService[service]) || metrika.defaultId;

        return <YM accounts={[metrikaId]} options={metrika.options} version="2" />;
    }
}

const mapStateToProps = (storeState) => ({
    grade: getMyGrade(storeState),
    me: getMe(storeState),
});

export default connect(mapStateToProps)(PageMetrika);
