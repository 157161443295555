export enum GEOBASE_REQUEST_TYPE {
    PARENTS='parents',
    REGION='region_by_id',
}

export type GeobaseParentsResponse = Array<number>;

export interface GeobaseRegionResponse {
    id: number;
    name: string;
    parent_id: number;
}

export type GeobaseResponseData = GeobaseParentsResponse | GeobaseRegionResponse;
