import b from 'b_';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './icon__question-circle.scss';

const IconQuestionCircle = (props) => {
    return (
        <svg className={cx(b('icon', 'question-circle'), props.mix)} viewBox="0 0 20 20">
            <path d="M9,16 L11,16 L11,14 L9,14 L9,16 Z M10,0 C4.48,0 0,4.48 0,10 C0,15.52 4.48,20 10,20 C15.52,20 20,15.52 20,10 C20,4.48 15.52,0 10,0 Z M10,18 C5.59,18 2,14.41 2,10 C2,5.59 5.59,2 10,2 C14.41,2 18,5.59 18,10 C18,14.41 14.41,18 10,18 Z M10,4 C8.93906628,3.9997789 7.92152216,4.42113526 7.17132871,5.17132871 C6.42113526,5.92152216 5.9997789,6.93906628 6,8 L8,8 C8,6.9 8.9,6 10,6 C11.1,6 12,6.9 12,8 C12,10 9,9.75 9,13 L11,13 C11,10.75 14,10.5 14,8 C14,5.79 12.21,4 10,4 Z" />
        </svg>
    );
};

IconQuestionCircle.propTypes = {
    mix: PropTypes.string,
};

export default IconQuestionCircle;
