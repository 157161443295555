import { useEffect } from 'react';

export interface BodyInfoItem {
    counter: number;
    initialOverflow?: CSSStyleDeclaration['overflow'];
}

const bodyInfo: BodyInfoItem = {
    counter: 0,
};

// todo: try https://github.com/lazd/iNoBounce to prevent body scroll under modal on ios?
export default function useLockBodyScroll(locked = true) {
    useEffect(() => {
        function lock() {
            if (bodyInfo.counter === 0) {
                bodyInfo.initialOverflow = document.body.style.overflow;
                document.body.style.overflow = 'hidden';
            }
            bodyInfo.counter += 1;
        }

        function unlock() {
            bodyInfo.counter -= 1;
            if (bodyInfo.counter === 0) {
                // Проверка нужна на случай открытия попапа внутри попапа с компонентом, не использующим это хук, например, Drawer из HrComponents
                if (document.body.style.overflow) {
                    document.body.style.overflow = bodyInfo.initialOverflow || '';
                }

                delete bodyInfo.initialOverflow;
            }
        }

        if (locked) {
            lock();
            return () => {
                unlock();
            };
        }

        return undefined;
    }, [locked]);
}
