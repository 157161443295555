import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';
import { useLocation } from 'react-router';

import cx from 'classnames';
import { Link, SimpleCallback, Spin, Text } from 'platform-components';
import { getIsTeacher } from 'platform-components/src/components/user/selectors';

import { ClassForm } from 'common.components/class-form/class-form';
import ConnectExternalError from 'common.components/connect-external-error/connect-external-error';
import { PRESELECTED_CLASS_LETTER, PRESELECTED_CLASS_NUMBER } from 'common.components/user/class.constants';
import { getSchoolGeoId } from 'common.components/user/selectors';
import { getIsCollegeTeamUtil } from 'common.components/user/utils';
import { externalLmsConnectActions } from 'store/external-lms-connect/actions';
import * as externalLmsSelectors from 'store/external-lms-connect/selectors';
import { getIsTeacherFromMes } from 'utils/external-lms/external-lms';

import { TeamBuilderConnectExternalButtons } from '../__external-buttons/team-builder__external-buttons';
import { teamBuilderActions } from '../actions';
import { getTeamData, getIsSubjectTeachersFeatureEnabled, getCanEditGrade, getDefaultGrade } from '../selectors';

import './team-builder__create-team.scss';

interface Props {
    hasBackButton: boolean;
    onAssign: (grade: number) => void;
    onBack: SimpleCallback;
    onNext: SimpleCallback;
    onCodeClick: SimpleCallback;
}

export const TeamBuilderCreateTeam = (props: Props) => {
    const { hasBackButton, onAssign, onBack, onNext, onCodeClick } = props;
    const dispatch = useDispatch();
    const { search } = useLocation();
    const teamData = useSelector(getTeamData);
    const isTeacher = useSelector(getIsTeacher);
    const isLmsError = useSelector(externalLmsSelectors.getIsLmsError);
    const isLmsLoading = useSelector(externalLmsSelectors.getIsLmsLoading);
    const shouldLoadLmsByRegionList = useSelector(externalLmsSelectors.getShouldLoadLmsByRegionList);
    const geoId = useSelector(getSchoolGeoId);
    const defaultGradeValue = useSelector(getDefaultGrade);
    const teamLevel = teamData ? teamData.teamLevel : defaultGradeValue || PRESELECTED_CLASS_NUMBER;
    const teamLetter = teamData ? teamData.teamLetter : PRESELECTED_CLASS_LETTER;
    const isSubjectTeachersFeatureEnabled = useSelector(getIsSubjectTeachersFeatureEnabled);
    const canEditGrade = useSelector(getCanEditGrade);
    const isMesTeacher = getIsTeacherFromMes(search);

    useMount(function loadRegions() {
        if (shouldLoadLmsByRegionList && geoId) {
            dispatch(externalLmsConnectActions.getLmsByRegionRequest({ geoId }));
        }
    });

    const buttonGoBack = (
        <Link mix="class-form__footer-link" onClick={onBack} pseudo>
            <Text id="classForm.goBack" />
        </Link>
    );

    const handleTeamFormChange = (data: { letter: string; number: number } | null) => {
        dispatch(teamBuilderActions.setTeamData(data));

        if (data) {
            onAssign(data.number);
        }
    };

    const handleCodeClick = () => {
        // Перед переходом к вводу кода очищаем изменения teamData,
        // чтобы не создавать новый класс в finishSubmitSaga
        handleTeamFormChange(null);
        onCodeClick();
    };

    const handleSubmit = () => {
        // В finishSubmitSaga создается класс только при наличии teamData,
        // так что если не меняли дефолтный null, то проставляем preselected
        handleTeamFormChange({ letter: teamLetter, number: teamLevel });
        onNext();
    };

    const getConnectButtons = () => {
        const isCollegeTeam = getIsCollegeTeamUtil(teamData?.teamLevel);

        return (
            <>
                <div className={cx('team-builder__separator', { 'team-builder__separator-small': isMesTeacher })}>
                    <span className="team-builder__separator-or">
                        <Text id="schoolForm.separator.or" size={Text.SIZE.S} />
                    </span>
                </div>
                {isLmsLoading && (
                    <div className="team-builder__spin-wrapper">
                        <Spin center />
                    </div>
                )}
                {isLmsError && <ConnectExternalError />}
                <TeamBuilderConnectExternalButtons
                    isCollegeTeam={isCollegeTeam}
                    onCodeControlClick={handleCodeClick}
                    shouldShowCodeControl={isSubjectTeachersFeatureEnabled}
                    shouldShowConnectMesControl={isMesTeacher}
                />
            </>
        );
    };

    return (
        <ClassForm
            additionalButton={getConnectButtons()}
            bottomSection={hasBackButton ? buttonGoBack : undefined}
            disabledNumber={!canEditGrade}
            letter={teamLetter}
            mix="team-builder__class-form"
            number={teamLevel}
            onChange={handleTeamFormChange}
            onSubmit={handleSubmit}
            shouldShowPersonalDataLink={!isTeacher}
        />
    );
};
