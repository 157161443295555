import { Me, EXTERNAL_LMS_SLUGS, TeacherProfile } from '@yandex-int/k-common/typings';
import cx from 'classnames';
import { Dnevnikru } from 'education-icons';
import { Button, SimpleText, SimpleCallback } from 'platform-components';
import React from 'react';
import ym from 'react-yandex-metrika';

import './connect-external-button.scss';

interface StoreProps {
    me: Me | null;
    teacherProfile: TeacherProfile | null;
}

interface OwnProps {
    buttonTextId: string;
    mix?: string;
    onClick?: SimpleCallback;
}

interface Props extends OwnProps, StoreProps {}

export const ConnectExternalButtonTypeDnevnikru = (props: Props) => {
    const { buttonTextId, me, teacherProfile, mix, onClick } = props;

    const handleClick = () => {
        const { id } = me || {};
        ym('reachGoal', teacherProfile ? 'teacher_my_class_add_from_partner' : 'teacher_onboarding_chose_partner', {
            partner_slug: EXTERNAL_LMS_SLUGS.DNEVNIKRU,
            user_id: id,
        });

        // TODO: здесь будет обработка клика
        if (onClick) {
            onClick();
        }
    };

    return (
        <Button className={cx('connect-external-button', mix)} onClick={handleClick} view={Button.VIEW.SECONDARY}>
            <Dnevnikru />
            <SimpleText id={buttonTextId} />
        </Button>
    );
};
