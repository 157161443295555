import b from 'b_';
import cx from 'classnames';
import React from 'react';

import { CHECKBOX_DIRECTIONS, CHECKBOX_SIZES, CHECKBOX_TYPES } from '../constants';

import './checkbox.scss';

interface Props {
    checked?: boolean;
    children?: React.ReactNode;
    defaultChecked?: boolean;
    direction?: CHECKBOX_DIRECTIONS;
    disabled?: boolean;
    focused?: boolean;
    mix?: string | string[];
    name?: string;
    /**
     * Для того чтобы предотвратить смену состояния checked нужно вернуть false
     */
    onBeforeToggle?: (e: React.ChangeEvent<HTMLInputElement>) => boolean | undefined;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onMouseEnter?: (e: React.MouseEvent<HTMLInputElement>) => void;
    onMouseLeave?: (e: React.MouseEvent<HTMLInputElement>) => void;
    preventClickPropagation?: boolean;
    size?: CHECKBOX_SIZES;
    type?: CHECKBOX_TYPES;
    value?: string | number;
}

interface State {
    checked: boolean;
    focused?: boolean;
    hasImage: boolean;
}

class Checkbox extends React.PureComponent<Props, State> {
    static defaultProps = {
        direction: CHECKBOX_DIRECTIONS.DEFAULT,
        size: CHECKBOX_SIZES.MEDIUM,
        type: CHECKBOX_TYPES.NORMAL,
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            checked: Boolean(this.props.defaultChecked) || Boolean(this.props.checked),
            focused: this.props.focused,
            hasImage: false,
        };
    }

    componentDidMount() {
        if (this.state.focused) {
            this.focus();
        }

        if (this.refLabel.current) {
            const hasImg = this.refLabel.current.querySelector('.paragraph:first-child img:first-child');

            if (hasImg) {
                // eslint-disable-next-line react/no-did-mount-set-state
                this.setState({ hasImage: true });
            }
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (nextProps.checked !== undefined && nextProps.checked !== this.state.checked) {
            this.setState({ checked: nextProps.checked });
        }
    }

    onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        this.setState({ focused: true });
        this.focus();

        if (this.props.onFocus) {
            this.props.onFocus(e);
        }
    };

    onBlur = () => {
        this.setState({ focused: false });
    };

    onBoxClick = (e: React.MouseEvent) => {
        if (this.props.preventClickPropagation) {
            e.stopPropagation();
        }
    };

    focus = () => {
        if (this.refs.checkboxInput instanceof HTMLInputElement) {
            this.refs.checkboxInput.focus();
        }
    };

    handleMouseEnter = (e: React.MouseEvent<HTMLInputElement>) => {
        if (this.props.onMouseEnter) {
            this.props.onMouseEnter(e);
        }
    };

    handleMouseLeave = (e: React.MouseEvent<HTMLInputElement>) => {
        if (this.props.onMouseLeave) {
            this.props.onMouseLeave(e);
        }
    };

    onBeforeToggle = (e: React.ChangeEvent<HTMLInputElement>): boolean => {
        if (this.props.onBeforeToggle) {
            const allowedToggle = this.props.onBeforeToggle(e);

            return allowedToggle === undefined || allowedToggle;
        }

        return true;
    };

    toggle = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.focus();

        const allowedToggle = this.onBeforeToggle(e);

        if (allowedToggle) {
            this.setState({ checked: !this.state.checked });

            if (this.props.onChange) {
                this.props.onChange(e);
            }
        }
    };

    renderCheckboxBlock = () => {
        const { hasImage } = this.state;

        return (
            <div
                className={b('checkbox__wrapper', { image: hasImage })}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
            >
                <span className="checkbox__box" onClick={this.onBoxClick}>
                    <input
                        autoComplete="off"
                        checked={this.state.checked}
                        className="checkbox__control"
                        disabled={this.props.disabled}
                        name={this.props.name}
                        onBlur={this.onBlur}
                        onChange={this.toggle}
                        onFocus={this.onFocus}
                        ref="checkboxInput"
                        type="checkbox"
                        value={this.props.value}
                    />
                    <i className="checkbox__tick" />
                </span>
            </div>
        );
    };

    refLabel = React.createRef<HTMLSpanElement>();

    render() {
        const className = b('checkbox', {
            direction: this.props.direction,
            type: this.props.type,
            size: this.props.size,
            checked: this.state.checked,
            disabled: this.props.disabled,
            focused: this.state.focused,
        });

        const isTypeText = this.props.type === 'text';

        return (
            <label className={cx(className, this.props.mix)}>
                {!isTypeText && this.renderCheckboxBlock()}
                <span className={b('checkbox', 'label', { enabled: !this.props.disabled })} ref={this.refLabel}>
                    {isTypeText && this.renderCheckboxBlock()}
                    {this.props.children}
                </span>
            </label>
        );
    }
}

export default Checkbox;
