import { useEffect, useState } from 'react';

import { SimpleCallback } from '../../typings';
import { reportMessage, captureException } from '../raven-message';

type SourceCode = string;
type LoadingError = Error | undefined;

export function useLoadFile(src: string, onLoad?: SimpleCallback): [SourceCode, boolean, LoadingError] {
    const [loading, setLoading] = useState(true);
    const [file, setFile] = useState('');
    const [error, setError] = useState<LoadingError>();

    useEffect(() => {
        let didCancel = false;

        async function fetchFile() {
            setLoading(true);

            try {
                const response = await fetch(src);
                const fileMarkup = await response.text();

                if (!didCancel) {
                    setFile(fileMarkup);
                    setLoading(false);
                }
            } catch (e) {
                setError(e);
                setLoading(false);

                reportMessage('[UseLoadFile] Failed to load resource', e);
                captureException(e);
            }
        }

        fetchFile();

        return () => {
            didCancel = true;
        };
    }, [src]);

    useEffect(() => {
        if (!loading && onLoad) {
            onLoad();
        }
    }, [loading, onLoad]);

    return [file, loading, error];
}
