import { withBemMod } from '@bem-react/core';
import { cnButton } from '@yandex-lego/components/Button/desktop';

import './Button_view_media.css';

export type WithViewMediaProps = {
    view?: 'media';
};

export const withViewMedia = withBemMod<WithViewMediaProps>(cnButton(), {
    view: 'media',
});
