import cx from 'classnames';
import React from 'react';

import './modal__header.scss';

interface Props {
    mix?: string;
    children: React.ReactNode | Array<React.ReactNode>;
}

export const ModalHeader = ({ children, mix }: Props) => <div className={cx('modal__header', mix)}>{children}</div>;
