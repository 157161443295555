import cx from 'classnames';
import { Link, Text, H2 } from 'platform-components';
import { FormattedHTMLMessage } from 'platform-components';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import AdultLogin from '../__adult-login';

import './start-screen__adult.scss';

export class StartScreenAdult extends React.Component {
    static propTypes = {
        mix: PropTypes.string,
        onAdultClick: PropTypes.func.isRequired,
    };

    render() {
        const { mix, onAdultClick } = this.props;

        return (
            <div className={cx('start-screen__teacher', mix)}>
                <h1 className="start-screen__teacher-title">
                    <FormattedHTMLMessage id="startScreen.title" />
                </h1>
                <div className="start-screen__teacher-controls">
                    <AdultLogin onLoginClick={onAdultClick} />
                    <div className="start-screen__project-description">
                        <div className="start-screen__subject-description">
                            <div className="start-screen__subject-description-title">
                                <Text id="startScreen.subjects.russian" size={Text.SIZE.L} weight={Text.WEIGHT.M} />
                            </div>
                            <div className="start-screen__divide" />
                            <div className="start-screen__classes">
                                <div className="start-screen__classes-levels">
                                    <H2 id="startScreen.projectDescription.classesList" />
                                </div>
                                <Text id="startScreen.projectDescription.classes" size={Text.SIZE.L} />
                            </div>
                        </div>
                        <div className="start-screen__subject-description">
                            <div className="start-screen__subject-description-title">
                                <Text id="startScreen.subjects.mathematics" size={Text.SIZE.L} weight={Text.WEIGHT.M} />
                            </div>
                            <div className="start-screen__divide" />
                            <div className="start-screen__classes">
                                <div className="start-screen__classes-levels">
                                    <H2 id="startScreen.projectDescription.classesList" />
                                </div>
                                <Text id="startScreen.projectDescription.classes" size={Text.SIZE.L} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="start-screen__about-link">
                    <Link pseudo>
                        <FormattedMessage id="startScreen.detailsLink" />
                    </Link>
                </div>
            </div>
        );
    }
}

export default StartScreenAdult;
