import { useAppContext, useGeneratePath, useStaticUrls } from 'platform-components/common';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { getCurrentTeamEnteringCode, getIsInStudentRegistrationLinkExperiment } from 'common.components/user/selectors';
import { getTeamIdFromPath } from 'utils/path';

export const useTeamRegistrationLink = () => {
    const classId = getTeamIdFromPath();
    const { kidsAddByCode, kids } = useStaticUrls();
    const { basePath, prefix } = useAppContext();
    const isInStudentLinkExperiment = useSelector(getIsInStudentRegistrationLinkExperiment);
    const generatePath = useGeneratePath();
    const teamEnteringCode = useSelector(
        useCallback((store) => getCurrentTeamEnteringCode(store, { classId }), [classId])
    );

    const path =
        isInStudentLinkExperiment && teamEnteringCode
            ? generatePath(kidsAddByCode, { code: teamEnteringCode })
            : generatePath(kids);

    return `${basePath}${prefix}${path}`;
};
