import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'close';
    sizeManagement?: 'self' | 'parent';
}

export const withCloseIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'close',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 's':
                svg = <svg width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.21869 8.71635C1.9258 9.00924 1.9258 9.48412 2.21869 9.77701C2.51159 10.0699 2.98646 10.0699 3.27935 9.77701L5.99791 7.05845L8.71647 9.77701C9.00936 10.0699 9.48424 10.0699 9.77713 9.77701C10.07 9.48412 10.07 9.00925 9.77713 8.71635L7.05857 5.99779L9.77713 3.27923C10.07 2.98634 10.07 2.51146 9.77713 2.21857C9.48424 1.92568 9.00936 1.92568 8.71647 2.21857L5.99791 4.93713L3.27935 2.21858C2.98646 1.92568 2.51159 1.92568 2.21869 2.21858C1.9258 2.51147 1.9258 2.98634 2.21869 3.27924L4.93725 5.99779L2.21869 8.71635Z" fill="currentColor" /></svg>;
                break;
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M4.29289 4.29289C4.68342 3.90237 5.31658 3.90237 5.70711 4.29289L12 10.5858L18.2929 4.29289C18.6834 3.90237 19.3166 3.90237 19.7071 4.29289C20.0976 4.68342 20.0976 5.31658 19.7071 5.70711L13.4142 12L19.7071 18.2929C20.0976 18.6834 20.0976 19.3166 19.7071 19.7071C19.3166 20.0976 18.6834 20.0976 18.2929 19.7071L12 13.4142L5.70711 19.7071C5.31658 20.0976 4.68342 20.0976 4.29289 19.7071C3.90237 19.3166 3.90237 18.6834 4.29289 18.2929L10.5858 12L4.29289 5.70711C3.90237 5.31658 3.90237 4.68342 4.29289 4.29289Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M2.60505 2.60502C2.87842 2.33165 3.32163 2.33165 3.595 2.60502L8.00002 7.01004L12.405 2.60502C12.6784 2.33165 13.1216 2.33165 13.395 2.60502C13.6684 2.87839 13.6684 3.3216 13.395 3.59497L8.98997 7.99999L13.395 12.405C13.6684 12.6784 13.6684 13.1216 13.395 13.395C13.1216 13.6683 12.6784 13.6683 12.405 13.395L8.00002 8.98994L3.595 13.395C3.32163 13.6683 2.87842 13.6683 2.60505 13.395C2.33168 13.1216 2.33168 12.6784 2.60505 12.405L7.01008 7.99999L2.60505 3.59497C2.33168 3.3216 2.33168 2.87839 2.60505 2.60502Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
