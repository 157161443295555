import { getFetch } from '../fetch';

import { apiConfig } from '../config';

export function getClass(classId: string | number, { getParentIsBinded, getPinAndPass, getExpandBinded }: any) {
    const { apiGetClass } = apiConfig.getStaticUrls();

    return getFetch(`${apiGetClass}${classId}/`, {
        expandBinded: getExpandBinded,
        withBinded: getParentIsBinded,
        withCredentials: getPinAndPass,
    });
}
