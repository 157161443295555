import { Link, Text } from 'platform-components';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import ym from 'react-yandex-metrika';

const FooterAgreement = ({ intl }, { service }) => {
    const agreementLink = intl.formatMessage({ id: `footer.agreementLink.${service}` });
    const recommendationsLink = intl.formatMessage({ id: `footer.recommendationsLink.${service}` });

    return (
        <div className="footer__legal-links">
            <Link
                mix="footer__agreement"
                onClick={() => ym('reachGoal', 'footer_legal')}
                target="_blank"
                theme={Link.THEME.STEALTHY}
                to={agreementLink}
            >
                <Text id="footer.agreement" />
            </Link>
            <Link
                mix="footer__recommendations"
                onClick={() => ym('reachGoal', 'footer_recommendations')}
                target="_blank"
                theme={Link.THEME.STEALTHY}
                to={recommendationsLink}
            >
                <Text id="footer.recommendations" />
            </Link>
        </div>
    );
};

FooterAgreement.propTypes = {
    intl: PropTypes.object.isRequired,
};

FooterAgreement.contextTypes = {
    service: PropTypes.string.isRequired,
};

export default injectIntl(FooterAgreement);
