import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import { Spin } from 'platform-components';
import { FormattedHTMLMessage } from 'platform-components';
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import Logo from 'common.components/logo/logo';

import * as subscriptionHandlerActions from './actions';
import { getIsLoading, getStatus } from './selectors';

import './subscription-handler.scss';

const SENDER_TYPES = {
    SUBSCRIBE: 'subscribe',
    UNSUBSCRIBE: 'unsubscribe',
};

class subscriptionHandler extends React.Component {
    static propTypes = {
        intl: PropTypes.object.isRequired,
        isLoading: PropTypes.string.isRequired,
        location: PropTypes.object.isRequired,
        approveSubscribeRequest: PropTypes.func.isRequired,
        approveUnsubscribeSuccess: PropTypes.func.isRequired,
        match: PropTypes.object.isRequired,
        status: PropTypes.string,
    };

    static contextTypes = {
        service: PropTypes.string.isRequired,
    };

    componentDidMount() {
        const {
            approveSubscribeRequest,
            approveUnsubscribeSuccess,
            location: { search: emailParams },
            match: {
                params: { action },
            },
        } = this.props;

        if (action === SENDER_TYPES.SUBSCRIBE) {
            approveSubscribeRequest(parse(emailParams));
        } else if (action === SENDER_TYPES.UNSUBSCRIBE) {
            approveUnsubscribeSuccess();
        }
    }

    render() {
        const { service } = this.context;
        const {
            isLoading,
            status,
            match: {
                params: { action },
            },
        } = this.props;

        return (
            <div className="subscription-handler">
                <Logo service={service} />
                {isLoading === LOADING_STATUSES.LOADING ? (
                    <div className="spin-wrapper">
                        <Spin />
                    </div>
                ) : (
                    <div className="subscription-handler__info-block">
                        <h2 className="subscription-handler__info-block-title">
                            <FormattedMessage id={`subscriptionHandler.${action}.title.${status}`} />
                        </h2>
                        <div className="subscription-handler__info-block-text">
                            <FormattedHTMLMessage id={`subscriptionHandler.${action}.${status}`} />
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (storeState) => {
    return {
        isLoading: getIsLoading(storeState),
        status: getStatus(storeState),
    };
};

const mapDispatchToProps = (dispatch) => {
    const { approveSubscribeRequest, approveUnsubscribeSuccess } = subscriptionHandlerActions;

    return bindActionCreators(
        {
            approveSubscribeRequest,
            approveUnsubscribeSuccess,
        },
        dispatch
    );
};

export default compose(injectIntl, connect(mapStateToProps, mapDispatchToProps))(subscriptionHandler);
