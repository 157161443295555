import { checkNever } from '@yandex-int/k-common';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { ERROR_TYPES } from '../constants';
import { getErrorType, getShouldShowErrorPopup } from '../selectors';
import { ErrorTypes } from '../typings';

import './child-management__error-popup.scss';

export const ChildManagementErrorPopup = () => {
    const errorType = useSelector(getErrorType);
    const shouldShowErrorPopup = useSelector(getShouldShowErrorPopup);

    const getErrorText = (error: ErrorTypes) => {
        switch (error) {
            case ERROR_TYPES.WRONG_SCHOOL_CODE:
                return 'childManagement.wrongSchoolCode';
            case ERROR_TYPES.WRONG_LOGIN:
                return 'childManagement.wrongLogin';
            default:
                checkNever(error);
                return error;
        }
    };

    const errorTextId = errorType ? getErrorText(errorType) : '';

    return shouldShowErrorPopup ? (
        <div className="child-management__error-popup">
            <FormattedMessage id={errorTextId} />
        </div>
    ) : null;
};

export const ErrorPopup = React.memo(ChildManagementErrorPopup);
