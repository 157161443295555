import { STUDENT_NAME_MAX_LENGTH } from 'platform-components/src/utils/get-student-name';
import { isValidHumanName } from 'platform-components/src/utils/input-validators';

const incorrectSymbolError = 'addClass.validation.incorrectSymbol';
const tooLongNameError = 'addClass.validation.tooLongName';
// приоритетность ошибок по возрастанию
const priorityList = [tooLongNameError, incorrectSymbolError];

export const getValidationMessage = (name) => {
    if (!name) {
        return null;
    }

    if (!isValidHumanName(name)) {
        return incorrectSymbolError;
    }

    if (name.length > STUDENT_NAME_MAX_LENGTH) {
        return tooLongNameError;
    }
    return null;
};

export const getPrioritizedError = (error1, error2) => {
    if (error1 !== error2) {
        const error1Priority = priorityList.indexOf(error1);
        const error2Priority = priorityList.indexOf(error2);

        return priorityList[Math.max(error1Priority, error2Priority)];
    }

    return error1;
};
