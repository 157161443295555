import { PayloadAction } from '@reduxjs/toolkit';
import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import { SimpleCallback } from 'platform-components';

import { LessonStore } from '../typings';

export interface GetFullClessonRequest {
    clessonId: number;
    courseId: number;
    onLoad?: SimpleCallback;
}

// Параметры нужны для саги
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getFullClessonRequest = (state: LessonStore, action: PayloadAction<GetFullClessonRequest>) => {};

export const getFullClessonLoading = (state: LessonStore) => {
    state.lessonLoadingStatus = LOADING_STATUSES.SUCCESS;
};

export const getFullClessonSuccess = (state: LessonStore) => {
    state.lessonLoadingStatus = LOADING_STATUSES.SUCCESS;
};

export const getFullClessonError = (state: LessonStore) => {
    state.lessonLoadingStatus = LOADING_STATUSES.ERROR;
};
