import * as constants from '../constants';
import { Route } from '../types';

export const addRouteToTree = (route: Route) => {
    return {
        type: constants.ADD_ROUTE_TO_TREE,
        route,
    };
};

export const removeRouteFromTree = (route: Route) => {
    return {
        type: constants.REMOVE_ROUTE_FROM_TREE,
        route,
    };
};

export const replaceRouteInTree = (route: Route) => {
    return {
        type: constants.REPLACE_ROUTE_IN_TREE,
        route,
    };
};
