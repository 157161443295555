import { useMe } from 'platform-components';
import { useMetrika } from 'platform-components/src/utils/hooks/useMetrika';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getStudents } from 'common.components/classes/selectors';
import { getCLessonId } from 'common.components/lesson/selectors';
import { useTeamRegistrationLink } from 'common.components/user/hooks/useTeamRegistrationLink';
import { getTeamIdFromPath } from 'utils/path';

export type Location = 'my_classes' | 'lesson_before_assign' | 'lesson_after_assign' | 'olympiad';
export type PrintModalAction = 'view' | 'print' | 'close';
export type SendMethod =
    | 'list_copy'
    | 'list_open_phone'
    | 'copy_link'
    | 'whatsapp'
    | 'viber'
    | 'vkontakte'
    | 'telegram';

export function useRegistrationLinkMetrika() {
    const { id: teacherId } = useMe() || {};
    const { sendGoal } = useMetrika();
    const teamId = getTeamIdFromPath();
    const teamRegistrationLink = useTeamRegistrationLink();
    const cLessonId = useSelector(getCLessonId);
    const teamStudents = useSelector(useCallback((store) => getStudents(store, { classId: teamId }), [teamId]));
    const studentsCount = teamStudents?.length;

    const ymTeacherSendLink = useCallback(
        (location: Location) => {
            sendGoal('teacher_send_link', {
                teacher_id: teacherId,
                team_id: teamId || undefined,
                cl_id: cLessonId || undefined,
                students_count: studentsCount,
                location,
            });
        },
        [cLessonId, sendGoal, studentsCount, teacherId, teamId]
    );

    const ymTeacherLinkMethod = useCallback(
        (method: SendMethod) => {
            sendGoal('teacher_link_method', {
                teacher_id: teacherId,
                team_id: teamId || undefined,
                link: teamRegistrationLink,
                method,
            });
        },
        [sendGoal, teacherId, teamId, teamRegistrationLink]
    );

    const ymTeacherLinkCopy = useCallback(
        (location: Location, method: SendMethod) => {
            ymTeacherSendLink(location);
            ymTeacherLinkMethod(method);
        },
        [ymTeacherLinkMethod, ymTeacherSendLink]
    );

    const ymTeacherBadLink = useCallback(
        (location: Location) => {
            sendGoal('teacher_bad_link', {
                teacher_id: teacherId,
                team_id: teamId || undefined,
                cl_id: cLessonId || undefined,
                students_count: studentsCount,
                location,
            });
        },
        [cLessonId, sendGoal, studentsCount, teacherId, teamId]
    );

    const ymTeacherStudentsLoginsModal = useCallback(
        (action: PrintModalAction) => {
            sendGoal('teacher_students_logins_modal', {
                teacher_id: teacherId,
                team_id: teamId || undefined,
                action,
            });
        },
        [sendGoal, teacherId, teamId]
    );

    const ymTeacherAddSchool = useCallback(
        (location: Location) => {
            sendGoal('teacher_add_school', {
                teacher_id: teacherId,
                location,
            });
        },
        [sendGoal, teacherId]
    );

    return useMemo(
        () => ({
            ymTeacherSendLink,
            ymTeacherLinkMethod,
            ymTeacherBadLink,
            ymTeacherStudentsLoginsModal,
            ymTeacherLinkCopy,
            ymTeacherAddSchool,
        }),
        [
            ymTeacherAddSchool,
            ymTeacherBadLink,
            ymTeacherLinkCopy,
            ymTeacherLinkMethod,
            ymTeacherSendLink,
            ymTeacherStudentsLoginsModal,
        ]
    );
}
