import { addThousandsSeparators, preFormat } from '../../utils/format-number/format-number';

export function formatWithCaret(value, caretPosition) {
    const formatted = formatNumber(value) || '';

    if (value === formatted) {
        return null;
    }

    const caretPositionAfterFormat =
        caretPosition !== null ? getCaretPositionAfterFormat(formatted, value, caretPosition) : null;

    return {
        value: formatted,
        caretPosition: caretPositionAfterFormat,
    };
}

/**
 * Допускаем, что в результате форматирования могут только добавиться или исчезнуть пробелы
 *
 * @param {string} formatted
 * @param {string} current
 * @param {number} caretPos
 * @returns {number}
 */
function getCaretPositionAfterFormat(formatted, current, caretPos) {
    let j = 0;

    for (let i = 0; i < caretPos; i += 1) {
        if (/\s/.test(formatted[j])) {
            j += 1;
        }
        if (formatted[j] === current[i]) {
            j += 1;
        }
    }

    return j;
}

export function formatNumber(number) {
    if (!number) {
        return null;
    }
    const preFormattedNumber = preFormat(number.replace(/\s+/g, ''));
    const matchingNumber = preFormattedNumber.match(/^{(\d*)}/);

    if (!matchingNumber) {
        return preFormattedNumber;
    }

    const numberWithSeparators = addThousandsSeparators(matchingNumber[1], ' ');
    const matchingNumberInput = matchingNumber.input;

    return matchingNumberInput.replace(/{(\d*)}/, numberWithSeparators);
}

/**
 * Copied from https://github.com/s-yadav/react-number-format/blob/8effbb44248eb6bb2a4937e4b844ce5035ad065e/src/utils.js#L166
 */
export function getCurrentCaretPosition(el) {
    /*Max of selectionStart and selectionEnd is taken for the patch of pixel and other mobile device caret bug*/
    return Math.max(el.selectionStart, el.selectionEnd);
}

/**
 * Set the caret position in an input field.
 * Copied from https://github.com/s-yadav/react-number-format/blob/8effbb44248eb6bb2a4937e4b844ce5035ad065e/src/utils.js#L110
 */
export function setCaretPosition(el, caretPos) {
    el.value = el.value;
    // ^ this is used to not only get "focus", but
    // to make sure we don't have it everything -selected-
    // (it causes an issue in chrome, and having it doesn't hurt any other browser)
    if (el !== null) {
        if (el.createTextRange) {
            const range = el.createTextRange();
            range.move('character', caretPos);
            range.select();
            return true;
        }
        // (el.selectionStart === 0 added for Firefox bug)
        if (el.selectionStart || el.selectionStart === 0) {
            el.focus();
            el.setSelectionRange(caretPos, caretPos);
            return true;
        }

        // fail city, fortunately this never happens (as far as I've tested) :)
        el.focus();
        return false;
    }
    return false;
}
