import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import get from 'lodash/get';
import { createSelector } from 'reselect';

import { isPasswordLengthValid } from './utils';

const startSelector = (storeState) => get(storeState, 'start');

/**
 * @param {Object} storeState
 * @return {Object}
 */
export const getSchoolData = createSelector(startSelector, (storeState) => get(storeState, 'schoolData'));

/**
 * @param {Object} storeState
 * @return {LOADING_STATUSES}
 */
export const getAuthCodeStatus = createSelector(startSelector, (storeState) => get(storeState, 'authCodeStatus'));

/**
 * @param {Object} storeState
 * @return {boolean}
 */
export const getIsSchoolCodeChecked = createSelector(
    getAuthCodeStatus,
    (status) => status === LOADING_STATUSES.SUCCESS
);

/**
 * @param {Object} storeState
 * @return {boolean}
 */
export const getIsSchool = createSelector(startSelector, (storeState) => get(storeState, 'isSchool'));

/**
 * @param {Object} storeState
 * @return {boolean}
 */
export const getIsParent = createSelector(startSelector, (storeState) => get(storeState, 'isParent'));

/**
 * @param {Object} storeState
 * @return {String}
 */
export const getPassword = createSelector(startSelector, (storeState) => get(storeState, 'password'));

/**
 * @param {Object} storeState
 * @return {Number}
 */
export const getPasswordLength = createSelector(startSelector, (storeState) => get(storeState, 'passwordLength'));

/**
 * @param {Object} storeState
 * @return {String}
 */
export const getLogin = createSelector(startSelector, (storeState) => get(storeState, 'login'));

/**
 * @param {Object} storeState
 * @return {boolean}
 */
export const getCanSubmitLogin = createSelector(
    [getIsSchoolCodeChecked, getLogin, getPassword, getPasswordLength],
    (isChecked, login, password, passwordLength) =>
        isChecked && login.length > 0 && isPasswordLengthValid(password, passwordLength)
);

/**
 * @param {Object} storeState
 * @return {LOADING_STATUSES}
 */
export const getLoginStatus = createSelector(startSelector, (storeState) => get(storeState, 'loginStatus'));

/**
 * @param {Object} storeState
 * @return {ERROR_TYPES}
 */
export const getErrorType = createSelector(startSelector, (storeState) => get(storeState, 'errorType'));

/**
 * @param {Object} storeState
 * @return {boolean}
 */
export const getShouldShowErrorPopup = createSelector(startSelector, (storeState) =>
    get(storeState, 'shouldShowErrorPopup')
);

/**
 * @param {Object} storeState
 * @return {boolean}
 */
export const getShouldRedirectToClassroom = createSelector(startSelector, (storeState) =>
    get(storeState, 'shouldRedirectToClassroom')
);
