import { addedLessonToCourse } from './addedLessonToCourse';
import { addLessonToCourseAsync } from './addLessonToCourse';
import { actions as deleteCLessonActions } from './deleteCLessonActions';
import { showLessonDeletedToast } from './showLessonDeletedToast';
import { showProblemDeletedToast } from './showProblemDeletedToast';
import { slice } from './slice';

export const actions = {
    ...slice.actions,
    ...deleteCLessonActions,
    showLessonDeletedToast,
    showProblemDeletedToast,
    addLessonToCourseAsync,
    addedLessonToCourse,
};
export const reducer = slice.reducer;

export { reducer as deleteCLessonReducer } from './deleteCLessonActions';

export default actions;
