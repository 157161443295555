import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import { SimpleCallback } from 'platform-components';
import React from 'react';

import { TeamBuilderChangeInfo } from 'common.components/team-builder/__change-team/team-builder__change-team';
import { TeamBuilderStudentLink } from 'common.components/team-builder/__student-link/team-builder__student-link';
import { YM_LOCATION } from 'utils/ym-constants';

import { TeamBuilderAddSubjects } from './__add-subjects/team-builder__add-subjects';
import { TeamBuilderCode } from './__code/team-builder__code';
import { TeamBuilderContent } from './__content/team-builder__content';
import { TeamBuilderCreateSubjects } from './__create-subjects/team-builder__create-subjects';
import { TeamBuilderCreateTeam } from './__create-team/team-builder__create-team';
import { TeamBuilderFallbackPoll } from './__fallback-poll/team-builder__fallback-poll';
import { TeamBuilderLogins } from './__logins/team-builder__logins';
import { TeamBuilderSchoolMes } from './__school-mes/team-builder__school-mes';
import { TeamBuilderSchool } from './__school/team-builder__school';
import { TeamBuilderStudents } from './__students/team-builder__students';
import { TeamBuilderSubmit } from './__submit/team-builder__submit';
import { TEAM_BUILDER_INNER_STEPS } from './team-builder-machine';
import { TEAM_BUILDER_STEPS } from './typings';

import './team-builder.scss';

export interface TeamBuilderProps {
    machineState: TEAM_BUILDER_STEPS | TEAM_BUILDER_INNER_STEPS;
    handleAssignTeam: (grade: number) => void;
    handleAssign: SimpleCallback;
    handleGoNext: SimpleCallback;
    handleGoToCode: SimpleCallback;
    wasSchoolAdded: boolean;
    shouldDisplayLogins: boolean;
    handleGoBack: SimpleCallback;
    shouldLoadTemplates: boolean;
    loadTemplates: SimpleCallback;
    shouldSubmit: boolean;
    shouldSubmitCode: boolean;
    submit: SimpleCallback;
    submitCode: SimpleCallback;
    submitMesRequest: SimpleCallback;
    createdTeamId: number | null;
    addedStudentsIds: Array<number> | null;
    handleClose: SimpleCallback;
    isStarted: boolean;
    location?: YM_LOCATION;
}

// TODO: однократность событий метрики
// eslint-disable-next-line complexity
export const TeamBuilder = (props: TeamBuilderProps) => {
    const {
        machineState,
        handleAssignTeam,
        handleAssign,
        handleGoNext,
        handleGoToCode,
        wasSchoolAdded,
        handleGoBack,
        shouldLoadTemplates,
        loadTemplates,
        shouldSubmit,
        shouldSubmitCode,
        submit,
        submitCode,
        submitMesRequest,
        createdTeamId,
        handleClose,
        isStarted,
        location,
        shouldDisplayLogins,
        addedStudentsIds,
    } = props;
    let component: JSX.Element | null = null;

    switch (machineState) {
        case TEAM_BUILDER_STEPS.SCHOOL:
            component = <TeamBuilderSchool location={location} onAssign={handleAssign} onNext={handleGoNext} />;
            break;
        case TEAM_BUILDER_STEPS.SCHOOL_FOR_MES:
            component = <TeamBuilderSchoolMes location={location} onAssign={handleAssign} onNext={handleGoNext} />;
            break;
        case TEAM_BUILDER_STEPS.MES_SUBMIT:
            component = (
                <TeamBuilderSubmit
                    shouldDisplayLogins={shouldDisplayLogins}
                    shouldSubmit={shouldSubmit}
                    submit={submitMesRequest}
                />
            );
            break;
        case TEAM_BUILDER_STEPS.TEAM:
            component = (
                <TeamBuilderCreateTeam
                    hasBackButton={wasSchoolAdded}
                    onAssign={handleAssignTeam}
                    onBack={handleGoBack}
                    onCodeClick={handleGoToCode}
                    onNext={handleGoNext}
                />
            );
            break;
        case TEAM_BUILDER_STEPS.LETTER:
            component = (
                <TeamBuilderChangeInfo
                    hasBackButton={wasSchoolAdded}
                    loadingStatus={LOADING_STATUSES.UNSENT}
                    onAssign={handleAssign}
                    onBack={handleGoBack}
                    onNext={handleGoNext}
                />
            );
            break;
        case TEAM_BUILDER_STEPS.STUDENTS:
            component = (
                <TeamBuilderStudents
                    loadingStatus={LOADING_STATUSES.UNSENT}
                    onAssign={handleAssign}
                    onNext={handleGoNext}
                />
            );
            break;
        case TEAM_BUILDER_STEPS.CODE:
            component = (
                <TeamBuilderCode
                    isLoading={false}
                    onAssign={handleAssign}
                    onBack={handleGoBack}
                    onNext={handleGoNext}
                />
            );
            break;
        case TEAM_BUILDER_STEPS.TEMPLATES_LOADING:
            component = (
                <TeamBuilderStudents
                    loadTemplates={loadTemplates}
                    loadingStatus={LOADING_STATUSES.LOADING}
                    onAssign={handleAssign}
                    onNext={handleGoNext}
                    shouldLoadTemplates={shouldLoadTemplates}
                />
            );
            break;
        case TEAM_BUILDER_STEPS.SUBJECTS:
            component = (
                <TeamBuilderCreateSubjects
                    loadTemplates={loadTemplates}
                    onAssign={handleAssign}
                    onNext={handleGoNext}
                    shouldLoadTemplates={shouldLoadTemplates}
                />
            );
            break;
        case TEAM_BUILDER_STEPS.ADDING_SUBJECTS:
            component = (
                <TeamBuilderAddSubjects
                    createdTeamId={createdTeamId}
                    loadTemplates={loadTemplates}
                    onAssign={handleAssign}
                    onNext={handleGoNext}
                    shouldLoadTemplates={shouldLoadTemplates}
                />
            );
            break;
        case TEAM_BUILDER_STEPS.SUBMIT:
            component = (
                <TeamBuilderSubmit
                    shouldDisplayLogins={shouldDisplayLogins}
                    shouldSubmit={shouldSubmit}
                    submit={submit}
                />
            );
            break;
        case TEAM_BUILDER_STEPS.SUBMIT_CODE:
            component = (
                <TeamBuilderCode
                    isLoading={true}
                    onAssign={handleAssign}
                    onBack={handleGoBack}
                    onNext={handleGoNext}
                    shouldSubmit={shouldSubmitCode}
                    submit={submitCode}
                />
            );
            break;
        case TEAM_BUILDER_STEPS.STUDENT_LINK:
            component = <TeamBuilderStudentLink location={location} onAddManuallyClick={handleGoNext} />;
            break;
        case TEAM_BUILDER_STEPS.FALLBACK_POLL:
            component = <TeamBuilderFallbackPoll onNext={handleGoNext} />;
            break;
        case TEAM_BUILDER_STEPS.LOGINS:
            component = (
                <TeamBuilderLogins
                    addedStudentsIds={addedStudentsIds}
                    createdTeamId={createdTeamId}
                    onNext={handleGoNext}
                />
            );
            break;
        default:
            break;
    }

    return (
        <TeamBuilderContent
            canClose={machineState !== TEAM_BUILDER_STEPS.SUBMIT && machineState !== TEAM_BUILDER_STEPS.MES_SUBMIT}
            component={component}
            handleClose={handleClose}
            isStarted={isStarted}
            state={String(machineState).toLowerCase()}
        />
    );
};
