// @ts-nocheck
import { Me, Team } from '@yandex-int/k-common/typings';
import { Spin, Text } from 'platform-components';
import { TEXT_MARKUP_TYPE } from 'platform-components/constants';
import { getMe } from 'platform-components/src/components/user/selectors';
import { withRouterPath, InjectedProps as WithRouterPathHOC } from 'platform-components/hocs';
import qs from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ym from 'react-yandex-metrika';
import { bindActionCreators, Dispatch } from 'redux';

import { INTEGRATION_SCENARIO, SEARCH_PARAMS_INTEGRATION, SEARCH_PARAMS_MES } from 'common.components/constants';
import { actions as externalLmsActions } from 'store/external-lms/actions';
import * as externalLmsSelectors from 'store/external-lms/selectors';

import { ReduxIndexStore, RootAction } from '../../typings';
import ExternalBackground from '../external-background/external-background';

import { ExternalClassesError } from './__error/external-classes__error';
import { EXTERNAL_CLASSES_ERROR } from './typings';

import './external-classes.scss';

interface StoreProps {
    me: Me | null;
    firstSyncedTeams: Array<Team>;
}

interface DispatchProps {
    createTeamsRequest: typeof externalLmsActions.createTeamsRequest;
}

interface Props extends StoreProps, DispatchProps, WithRouterPathHOC {}

export const ExternalClasses = (props: Props) => {
    const {
        createTeamsRequest,
        location: { search },
        firstSyncedTeams,
    } = props;
    const [error, setError] = useState<EXTERNAL_CLASSES_ERROR | null>(null);

    const params = qs.parse(search);
    const {
        [SEARCH_PARAMS_INTEGRATION.ASYNC_CLASS_IMPORT]: isAsyncClassImport,
        [SEARCH_PARAMS_INTEGRATION.IS_TEACHER]: isTeacher,
        [SEARCH_PARAMS_INTEGRATION.LMS]: lms,
        [SEARCH_PARAMS_INTEGRATION.RETPATH]: retpath,
        [SEARCH_PARAMS_INTEGRATION.STATUS]: status,
        [SEARCH_PARAMS_INTEGRATION.INTEGRATION_SCENARIO]: scenario,
        [SEARCH_PARAMS_INTEGRATION.BACK_URL]: backUrl,
        [SEARCH_PARAMS_INTEGRATION.REJECT_RETPATH]: rejectRetpath,
        [SEARCH_PARAMS_MES.CLASS_ID]: externalClassId,
        [SEARCH_PARAMS_MES.MES_CLASS_IDS]: externalTeamIdsParam,
        [SEARCH_PARAMS_MES.TYPE]: mesResponseType,
    } = params;

    // TODO: mesResponseType === 'error' возможно, баг на стороне МЭШ, по контракту есть только status
    const isError = status === 'mes_error' || mesResponseType === 'error';

    useEffect(() => {
        const { me } = props;

        if (isError) {
            ym(
                'reachGoal',
                isTeacher ? 'teacher_my_class_partner_access_error' : 'teacher_onboarding_partner_access_error',
                { partner_slug: lms, user_id: me?.id }
            );

            window.location.href = `${decodeURIComponent(retpath)}`;

            return;
        }

        const externalTeamIds = externalTeamIdsParam.split(',').map((id: string) => Number(id));

        if (externalTeamIds?.length) {
            let goal = 'teacher_my_class_chose_sync_teams';

            switch (scenario) {
                case INTEGRATION_SCENARIO.REGISTRATION:
                    goal = 'teacher_onboarding_chose_sync_teams';
                    break;
                default:
                    break;
            }

            ym('reachGoal', goal, { partner_slug: lms, user_id: me?.id, team_list: externalTeamIds });

            if (externalClassId && !externalTeamIds.includes(externalClassId)) {
                setError(EXTERNAL_CLASSES_ERROR.TEAM_IMPORT_IS_NOT_CONFIRMED);
            }

            createTeamsRequest({
                externalTeamIds,
                lms,
                onSuccess: () => {
                    if (isAsyncClassImport) {
                        redirectToRetpath();
                    }
                },
                onError: () => {
                    window.location.href = decodeURIComponent(rejectRetpath || backUrl || retpath);
                },
            });
        } else if (externalClassId) {
            // список классов пуст и находимся в сценарии выдачи дз, кидаем по backUrl
            window.location.href = decodeURIComponent(backUrl);
        } else {
            // список классов пуст, ничего импортировать не нужно, идем дальше
            window.location.href = `${decodeURIComponent(retpath)}?${qs.stringify(params)}`;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const redirectToRetpath = useCallback(
        (searchParams = {}) => {
            window.location.href = `${decodeURIComponent(retpath)}?${qs.stringify({
                ...searchParams,
                [SEARCH_PARAMS_INTEGRATION.CONNECTED_LMS]: lms,
            })}`;
        },
        [lms, retpath]
    );

    // если находимся в сценарии выдачи ДЗ, то ждем синхронизации конкретного класса
    useEffect(() => {
        if (externalClassId) {
            const isExternalClassReady =
                externalClassId &&
                firstSyncedTeams.some(
                    (team) => team.external_lms && Object.values(team.external_lms).includes(externalClassId)
                );

            if (isExternalClassReady) {
                redirectToRetpath(params);
            }
        } else if (firstSyncedTeams.length !== 0) {
            redirectToRetpath();
        }
    }, [externalClassId, firstSyncedTeams, params, redirectToRetpath]);

    return (
        <div className="external-classes__wrapper">
            <ExternalBackground />
            {error ? (
                <ExternalClassesError backUrl={backUrl} error={error} lms={lms} />
            ) : (
                <div className="external-classes__block">
                    <Spin mix="external-classes__spin" />
                    <Text id="externalLms.mes.importTeams" type={TEXT_MARKUP_TYPE.MARKDOWN} />
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (storeState: ReduxIndexStore): StoreProps => {
    return {
        me: getMe(storeState),
        firstSyncedTeams: externalLmsSelectors.getFirstSyncedTeams(storeState),
    };
};

const mapDispatchToProps = (dispatch: Dispatch<RootAction>): DispatchProps => {
    return bindActionCreators(
        {
            createTeamsRequest: externalLmsActions.createTeamsRequest,
        },
        dispatch
    );
};

export default withRouterPath(connect(mapStateToProps, mapDispatchToProps)(ExternalClasses));
