import produce from 'immer';

import * as constants from './constants';
import { MODES } from './school-form.constants';
import { SchoolFormActions, SchoolFormStore } from './typings';

const initialState: SchoolFormStore = {
    addresses: [],
    schools: [],
    areAddressesLoading: false,
    areSchoolsLoading: false,
    mode: MODES.SUGGEST,
    currentAddress: null,
    currentSchool: null,
};

const schoolFormReducer = (state: SchoolFormStore = initialState, action: SchoolFormActions) => {
    // eslint-disable-next-line complexity,consistent-return
    return produce(state, (draft) => {
        switch (action.type) {
            case constants.CLEAR_STATE:
                return initialState;

            case constants.SET_MODE:
                const { mode } = action;

                draft.currentSchool = null;
                draft.mode = mode;
                break;

            case constants.GET_ADDRESS_SUGGESTS_REQUEST:
                draft.areAddressesLoading = true;
                break;

            case constants.GET_SCHOOL_SUGGESTS_REQUEST:
                draft.areSchoolsLoading = true;
                break;

            case constants.GET_ADDRESS_SUGGESTS_SUCCESS:
            case constants.SET_ADDRESSES:
                const { addresses } = action;

                draft.addresses = addresses;
                draft.areAddressesLoading = false;
                break;

            case constants.GET_SCHOOL_SUGGESTS_SUCCESS:
            case constants.SET_SCHOOLS:
                const { schools } = action;

                draft.schools = schools;
                draft.areSchoolsLoading = false;
                break;

            case constants.GET_ADDRESS_SUGGESTS_ERROR:
                draft.addresses = [];
                draft.areAddressesLoading = false;
                break;

            case constants.GET_SCHOOL_SUGGESTS_ERROR:
                draft.schools = [];
                draft.areSchoolsLoading = false;
                break;

            case constants.SET_CURRENT_ADDRESS:
                const { currentAddress } = action;

                draft.currentAddress = currentAddress;
                break;

            case constants.SET_CURRENT_SCHOOL:
                const { currentSchool } = action;

                draft.currentSchool = currentSchool;
                break;

            // no default
        }
    });
};

export default schoolFormReducer;
