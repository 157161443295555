// @ts-nocheck
import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import {
    getExternalClesson,
    type GetExternalCLessonResponse,
} from '@yandex-int/k-common/client/api/defs/get-external-clesson';
import isEmpty from 'lodash/isEmpty';
import { useAppContext, useIntl, useViewDeps } from 'platform-components';
import qs from 'query-string';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { actions as lessonActions } from 'common.components/lesson/actions';
import * as lessonSelectors from 'common.components/lesson/selectors';

import { ExternalDeleteLesson } from './external-delete-lesson';
import { EXTERNAL_DELETE_LESSON_ERROR } from './typings';

export const ExternalDeleteLessonContainer = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { search } = useLocation();
    const deleteClessonStatus = useSelector(lessonSelectors.getDeleteClessonStatus);
    const deleteCLessonError = useSelector(lessonSelectors.getDeleteCLessonError);
    const clesson = useViewDeps(getExternalClesson, 'getExternalCLesson') as GetExternalCLessonResponse | undefined;
    const { data } = useAppContext();
    const errors = data.errors;

    const lessonName = clesson?.name || intl.formatMessage({ id: 'externalLms.deleteLesson.defaultName' });
    const clessonId = clesson?.cll_id;
    const errorCode = isEmpty(errors.length) ? deleteCLessonError : EXTERNAL_DELETE_LESSON_ERROR.NOT_FOUND;
    const { lms, back_url: backUrl } = qs.parse(search);

    useEffect(() => {
        if (deleteClessonStatus === LOADING_STATUSES.SUCCESS) {
            window.location.href = `${decodeURIComponent(backUrl)}`;
        }

        if (deleteClessonStatus === LOADING_STATUSES.UNSENT) {
            if (clessonId && typeof window !== 'undefined') {
                dispatch(
                    lessonActions.deleteCLesson({
                        cLessonId: clessonId,
                        isDraft: false,
                    })
                );
            }
        }
    }, [dispatch, clessonId, deleteClessonStatus, backUrl]);

    const handleRetry = useCallback(() => {
        if (clessonId) {
            dispatch(
                lessonActions.deleteCLesson({
                    cLessonId: clessonId,
                    isDraft: false,
                })
            );
        }
    }, [clessonId, dispatch]);

    const isLoading = [LOADING_STATUSES.LOADING, LOADING_STATUSES.SUCCESS].includes(deleteClessonStatus);

    return (
        <ExternalDeleteLesson
            backUrl={backUrl}
            errorCode={errorCode}
            lessonName={lessonName}
            lms={lms}
            onRetry={handleRetry}
            shouldShowLoader={isLoading}
        />
    );
};
