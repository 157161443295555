export const ASSIGNED_LESSONS_COUNT = 'assigned_lessons_count';
export const LESSONS_ASSIGNED_TO_GET_CERTIFICATE = 4;
export const LESSON_ASSIGNED = 'lesson_was_assigned';
export const COPIED_AS_RNO_COUNT = 'copiedAsRNOCount';
export const HAS_FINISHED_LESSONS = 'hasFinishedLessons';

export const STATISTICS_MODAL_SEEN = 'statistics_modal_seen';
export const GO_TO_INFORMATICS_BANNER_CLICKED = 'goToInformaticsBannerClicked';
export const TEACHER_CERT_BANNER_WAS_CLOSED = 'teacher_cert_banner_was_closed';

export const UMK_DATA = 'umk_data_v2';

export const DEFAULT_CLASS_GRADES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
export const CLASS_GRADES_WITHOUT_CONTENT: number[] = [];
export const FULL_CLASS_GRADES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

// 99 - СПО
/** Грейды, для которых можно просматривать кабинет учителя незарегистрированным(без классов) пользователем */
export const TEACHER_PUBLIC_GRADES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 99];

export const INFORMATICS_GRADES = [5, 6, 7, 8, 9, 10, 11, 99];
export const JUNIOR_GRADES = [1, 2, 3, 4];

export const DEFAULT_GRADE_INFORMATICS = 5;
export const DEFAULT_GRADE_JUNIOR = 1;

export const SECONDARY_SCHOOL_FIRST_CLASS = 5;
export const HIGH_SCHOOL_FIRST_CLASS = 10;

export const PARENT_OLY_ACCEPTED_MODAL_SHOWN = 'parent_oly_accepted_modal_shown';

export const SUBMITTED_FORMS_HINT = 'submitted_forms';

export const SKILLS_STATS_HOW_TO_CALCULATE = 'skills_stats_how_to_calculate';

export const VIEWED_HINTS_CERT_EXPANDED_PARAMS_KEY = 'cert_expanded_params';
