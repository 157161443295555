export enum MENU_THEMES {
    MARKER = 'marker',
    YELLOW = 'yellow',
    SCHOOL = 'school',
    TEACHER = 'teacher',
    ROUNDED = 'rounded',
    NORMAL = 'normal',
}

export enum MENU_SIZES {
    M = 'm',
    L = 'l',
    XL = 'xl',
}
