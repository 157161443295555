import { useEffect, useState } from 'react';
import useWindowSize from 'react-use/lib/useWindowSize';

import { SimpleCallback } from '../typings';

const MOUSE_TOUCH_MAPPER = {
    mousedown: 'touchstart',
    mousemove: 'touchmove',
    mouseup: 'touchend',
};

type EventName = keyof typeof MOUSE_TOUCH_MAPPER;

const _listeners = {} as Record<string, SimpleCallback>;

export const isTouchDevice = Boolean(
    typeof window !== 'undefined' &&
        typeof navigator !== 'undefined' &&
        ('ontouchstart' in window || (navigator as any).msMaxTouchPoints > 0) // TODO: why ms only?
);

export const isMouseDevice = Boolean(typeof window !== 'undefined' && 'onmousedown' in window);

export const isPointerSupport = typeof window !== 'undefined' && 'PointerEvent' in window;

export const isLegacyTouchEvents = isTouchDevice && !isPointerSupport;

export const pixelRatio =
    typeof window !== 'undefined' && window.devicePixelRatio ? Math.max(window.devicePixelRatio, 1) : 1;

function touchToMouseEvent(eventType: string, event: TouchEvent) {
    const touch = (event.changedTouches && event.changedTouches.length && event.changedTouches[0]) || ({} as Touch);
    const mouseEvent = document.createEvent('MouseEvents');

    if (!event.target) {
        return null;
    }

    mouseEvent.initMouseEvent(
        eventType,
        true,
        true,
        // @ts-ignore
        event.target.ownerDocument.defaultView,
        0,
        touch.screenX,
        touch.screenY,
        touch.clientX,
        touch.clientY,
        event.ctrlKey,
        event.altKey,
        event.shiftKey,
        event.metaKey,
        0,
        null
    );

    mouseEvent.preventDefault = event.preventDefault.bind(event);

    return mouseEvent;
}

function dispatchMouseEventByTouch(eventType: string, event: TouchEvent) {
    const eventToDispatch = touchToMouseEvent(eventType, event);

    if (!event.target || !eventToDispatch) {
        return;
    }

    event.target.dispatchEvent(eventToDispatch);
}

export function addCrossDeviceListener(node: HTMLElement, eventName: EventName, callback: SimpleCallback) {
    node.addEventListener(eventName, callback);

    if (isTouchDevice) {
        const func = dispatchMouseEventByTouch.bind(this, eventName);

        /**
         * На iOS сафари происходит подскрол страницы на тач событиях без {passive: false}
         */
        node.addEventListener(MOUSE_TOUCH_MAPPER[eventName], func, { passive: false });
        _listeners[callback.toString()] = func;
    }
}

export function removeCrossDeviceListener(node: HTMLElement, eventName: EventName, callback: SimpleCallback) {
    node.removeEventListener(eventName, callback);

    if (isTouchDevice) {
        node.removeEventListener(MOUSE_TOUCH_MAPPER[eventName], _listeners[callback.toString()]);

        delete _listeners[callback.toString()];
    }
}

function getIsLandscape() {
    return typeof window !== 'undefined' ? window.matchMedia('(orientation: landscape)').matches : false;
}

export function useIsLandscape() {
    const { width, height } = useWindowSize();
    const [isLandscape, setIsLandscape] = useState<boolean>(getIsLandscape());

    useEffect(() => {
        setIsLandscape(getIsLandscape());
    }, [width, height]);

    return isLandscape;
}
