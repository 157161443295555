export const SET_ACCORDION_ITEM = 'LESSON_ASSIGNMENT.SET_ACCORDION_ITEM';
export const SET_ASSIGNMENT_STATE = 'LESSON_ASSIGNMENT.SET_ASSIGNMENT_STATE';
export const SET_LESSON_MODE = 'LESSON_ASSIGNMENT.SET_LESSON_MODE';
export const SET_ASSIGN_DATE = 'LESSON_ASSIGNMENT.SET_ASSIGN_DATE';
export const SET_DEADLINE_DATE = 'LESSON_ASSIGNMENT.SET_DEADLINE_DATE';
export const SET_ASSIGN_DATE_ERROR = 'LESSON_ASSIGNMENT.SET_ASSIGN_DATE_ERROR';
export const SET_DEADLINE_DATE_ERROR = 'LESSON_ASSIGNMENT.SET_DEADLINE_DATE_ERROR';
export const SET_DURATION = 'LESSON_ASSIGNMENT.SET_DURATION';
export const SET_DURATION_ERROR = 'LESSON_ASSIGNMENT.SET_DURATION_ERROR';
export const SET_SELECTED_STUDENTS = 'LESSON_ASSIGNMENT.SET_SELECTED_STUDENTS';
export const SET_STUDENTS_ERROR = 'LESSON_ASSIGNMENT.SET_STUDENTS_ERROR';
export const SET_TO_DEFAULT = 'LESSON_ASSIGNMENT.SET_TO_DEFAULT';
export const LOAD_EXTERNAL_LMS_AVAILABLE_DATES_REQUEST = 'LESSON_ASSIGNMENT.LOAD_EXTERNAL_LMS_AVAILABLE_DATES_REQUEST';
export const LOAD_EXTERNAL_LMS_AVAILABLE_DATES_SUCCESS = 'LESSON_ASSIGNMENT.LOAD_EXTERNAL_LMS_AVAILABLE_DATES_SUCCESS';
export const LOAD_EXTERNAL_LMS_AVAILABLE_DATES_ERROR = 'LESSON_ASSIGNMENT.LOAD_EXTERNAL_LMS_AVAILABLE_DATES_ERROR';
export const HANDLE_EXTERNAL_LMS_CHECKBOX_TOGGLE = 'LESSON_ASSIGNMENT.HANDLE_EXTERNAL_LMS_CHECKBOX_TOGGLE';
export const FORCE_SHOULD_ASSIGN_IN_EXTERNAL_LMS = 'LESSON_ASSIGNMENT.FORCE_SHOULD_ASSIGN_IN_EXTERNAL_LMS';
export const SET_EXTERNAL_LMS_DATES_ERROR = 'LESSON_ASSIGNMENT.SET_EXTERNAL_LMS_DATES_ERROR';
