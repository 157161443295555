export enum SPIN_THEMES {
    WHITE = 'white',
    BLACK = 'black',
    YELLOW = 'yellow',
    BLUE = 'blue',
}

export enum SPIN_SIZES {
    XS = 'xs',
    S = 's',
    M = 'm',
    L = 'l',
    XL = 'xl',
    XXL = 'xxl',
}
