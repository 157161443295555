export enum ANSWER_STATUS {
    INCORRECT = 0,
    CORRECT = 1,
    SKIPPED = -1,
}

export enum MARKER_STATUS_MODE {
    NORMAL = 'normal',
    CORRECT_ONLY = 'correct-only',
    WITHOUT_SKIPPED = 'without-skipped',
    FULL_STATUS = 'full-status',
    EVERYTHING_IS_CORRECT = 'everything-is-correct',
}

export enum MARKER_THEMES {
    CORRECT = 'correct',
    INCORRECT = 'incorrect',
    NORMAL = 'normal',
    SKIPPED = 'skipped',
}
