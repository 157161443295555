import { LESSON_STATUS, Me, Team } from '@yandex-int/k-common/typings';
import uniq from 'lodash/uniq';
import { showIntlMessageNotification, showNetworkError } from 'platform-components';
import { GRAVITIES, NOTIFICATION_THEMES } from 'platform-components/constants';
import { getMe } from 'platform-components/src/components/user/selectors';
import ym from 'react-yandex-metrika';
import { call, put, select } from 'redux-saga/effects';

import { getStudentsCount } from 'common.components/classes/selectors';
import { COMMON_NOTIFICATION_DELAY } from 'common.components/constants';
import { getCurrentYearCourseId } from 'common.components/course/selectors';
import { actions as lessonActions } from 'common.components/lesson';
import * as actions from 'common.components/lesson-view.assignment/actions';
import * as selectors from 'common.components/lesson-view.assignment/selectors';
import { getLessonAssigns } from 'common.components/lesson/sagas/getLessonAssigns';
import * as lessonSelectors from 'common.components/lesson/selectors';
import { isLessonInProgress } from 'common.components/lesson/utils';
import * as userSelectors from 'common.components/user/selectors';
import { getTeamIdFromPath } from 'utils/path';

// eslint-disable-next-line complexity
export function* finishAddingStudentsSaga(action: ReturnType<typeof lessonActions.finishAddingStudentsRequest>) {
    try {
        const { studentsIds, ymLocation } = action.payload;

        const teamId = getTeamIdFromPath();

        const currentTeam: Team | null = yield select(userSelectors.getCurrentClass, { classId: teamId });
        const currentStudentCount: number = yield select(getStudentsCount, { classId: teamId });
        const me: Me = yield select(getMe);
        const clessonId: number | null = yield select(lessonSelectors.getCLessonId);
        const courseId: number = yield select(getCurrentYearCourseId);
        const status: LESSON_STATUS | null = yield select(lessonSelectors.getLessonStatus);
        const selectedStudents: number[] = yield select(selectors.getSelectedStudents);

        if (!currentTeam) {
            return;
        }

        const { level, name } = currentTeam;

        yield put(
            showIntlMessageNotification({
                closeDelay: COMMON_NOTIFICATION_DELAY,
                gravity: GRAVITIES.BOTTOM,
                messageId: 'toast.lessonView.studentsAdded',
                messageValues: { level, name, students: studentsIds?.length },
                withExplicitClose: true,
                theme: NOTIFICATION_THEMES.TOAST,
            })
        );

        ym('reachGoal', 'student_created', {
            studentsNumber: studentsIds?.length,
            team_id: currentTeam.id,
            user_id: me.id,
            before_students_count: currentStudentCount ? currentStudentCount - (studentsIds?.length || 0) : 0,
            location: ymLocation,
        });

        if (
            clessonId &&
            courseId &&
            status &&
            (isLessonInProgress(status) ||
                status === LESSON_STATUS.ASSIGNED_IN_FUTURE ||
                status === LESSON_STATUS.ASSIGNED)
        ) {
            yield call(getLessonAssigns, { cLessonId: clessonId, courseId });
        }
        // @ts-ignore
        yield put(actions.setSelectedStudents(uniq([...selectedStudents, ...studentsIds])));

        yield put(lessonActions.finishAddingStudentsSuccess());
    } catch (error) {
        console.error(error);
        yield put(lessonActions.finishAddingStudentsError());
        yield put(showNetworkError(error));
    }
}
