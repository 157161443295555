// @ts-nocheck
import { Team, SYNC_STATUS, EXTERNAL_LMS_SLUGS } from '@yandex-int/k-common/typings';
import { MES_QUERY_PARAMS } from 'platform-components/constants';
import { getFeatureFlags } from 'platform-components/src/components/app-factory/selectors';
import { getFromLocalStorage } from 'platform-components/utils';
import qs from 'query-string';
import { createSelector } from 'reselect';

import { getStudents } from 'common.components/classes/selectors';
import {
    LMS_HOMEWORK_ID_KEY,
    SEARCH_PARAMS_INTEGRATION,
    SEARCH_PARAMS_MES_HOMEWORK,
} from 'common.components/constants';
import { getClasses, getCurrentClass } from 'common.components/user/selectors';
import { getSearchFromProps } from 'utils/selectors/selectors';

import { ExternalLmsHomeworkData, MesHomeworkData, PropsWithMesParams } from './typings';

/**
 * Включен ли фиче-флаг мэша (кажется что не используется – если мэш неактивен, то мы на bff их редиректим)
 */
export const getIsMesFeatureEnabled = createSelector([getFeatureFlags], (featureFlags): boolean =>
    Boolean(featureFlags?.mes)
);

export const getFirstSyncedTeams = createSelector(
    [getClasses],
    (teams: Array<Team>): Array<Team> => teams.filter((team) => team.sync_status !== SYNC_STATUS.FIRST_SYNC)
);

export const getExternalLmsHomeworkScenarioId = createSelector([getSearchFromProps], (search): string => {
    const { [LMS_HOMEWORK_ID_KEY]: homeworkScenarioId } = qs.parse(search);

    return homeworkScenarioId;
});

export const getIsInSandbox = () => false as const; // TODO: не используется EDUCATION-34116

export const getExternalLmsHomeworkData = createSelector(
    [getExternalLmsHomeworkScenarioId],
    (homeworkScenarioId): ExternalLmsHomeworkData | null => {
        if (!homeworkScenarioId || typeof window === 'undefined') {
            return null;
        }

        let data: any = null;

        try {
            data = JSON.parse(getFromLocalStorage(LMS_HOMEWORK_ID_KEY, {}))?.[homeworkScenarioId] || null;
        } catch {
            // ignore json.parse error
        }

        return data;
    }
);

export const getHomeworkLmsSlug = createSelector([getSearchFromProps], (search): EXTERNAL_LMS_SLUGS | null => {
    if (!search) {
        return null;
    }

    return qs.parse(search)[SEARCH_PARAMS_INTEGRATION.LMS];
});

/**
 * Отдает урл, на который нужно вести учителя после выдачи ДЗ
 */
export const getMesHomeworkBackUrl = createSelector(
    [getSearchFromProps],
    (search) => (qs.parse(search).back_url as string | undefined) || null
);

/**
 * Проверяет, что текущий класс привязан к выбранному в МЭШ классу
 */
export const getIsTeamFromMes = createSelector([getSearchFromProps, getCurrentClass], (search, currentClass) => {
    const { class_id: classId } = qs.parse(search);

    const mes = currentClass?.external_lms?.mes;

    return Boolean(classId && mes && mes === classId);
});

export const getLmsHomeworkAssignDate = createSelector([getSearchFromProps], (search): Date | null => {
    if (!search) {
        return null;
    }
    const { [SEARCH_PARAMS_MES_HOMEWORK.BEGIN_DATE]: assignDate } = qs.parse(search);
    if (!assignDate) {
        return null;
    }
    let formattedDate = assignDate.split('.').reverse().join('-');
    return new Date(formattedDate);
});

export const getLmsHomeworkDeadlineDate = createSelector([getSearchFromProps], (search): Date | null => {
    if (!search) {
        return null;
    }
    const { [SEARCH_PARAMS_MES_HOMEWORK.END_DATE]: deadLineDate } = qs.parse(search);
    if (!deadLineDate) {
        return null;
    }
    let formattedDate = deadLineDate.split('.').reverse().join('-');
    return new Date(formattedDate);
});

export const getLmsHomeworkStudentIds = createSelector(
    [getExternalLmsHomeworkData],
    (mesHomeworkData): Array<number> => {
        if (!mesHomeworkData) {
            return [];
        }

        const lms = mesHomeworkData[SEARCH_PARAMS_INTEGRATION.LMS];

        switch (lms) {
            case EXTERNAL_LMS_SLUGS.MES:
                const mesHomeworkStudentList = mesHomeworkData[SEARCH_PARAMS_MES_HOMEWORK.STUDENT_LIST];

                if (!mesHomeworkStudentList) {
                    return [];
                }

                return mesHomeworkStudentList.split(',').map((studentId: string) => Number(studentId)) || [];
            default:
                return [];
        }
    }
);

export const getLmsHomeworkAssignStudentIds = createSelector(
    [getLmsHomeworkStudentIds, getStudents],
    (lmsStudentIds, students): Array<number> => {
        if (!students) {
            return [];
        }

        const studentIds = students.map(({ id }) => id);

        if (lmsStudentIds.length === 0) {
            return studentIds;
        }

        const notAllLmsStudentsExist = lmsStudentIds.some((id) => !studentIds.includes(id));

        if (notAllLmsStudentsExist) {
            return [];
        }

        return lmsStudentIds;
    }
);
export const getScenarioExternalLms = createSelector(
    [getExternalLmsHomeworkData],
    (externalHomeworkData): string | null => externalHomeworkData?.[SEARCH_PARAMS_INTEGRATION.LMS] || null
);

export const getMesHomeworkDataFromProps = <T extends PropsWithMesParams>(
    storeState: any,
    ownProps: T
): MesHomeworkData | null => ownProps.mesHomeworkData;

export const getMesHomeworkTeamId = createSelector(
    [getClasses, getMesHomeworkDataFromProps],
    (teams, lmsHomeworkData): number | null => {
        if (!lmsHomeworkData) {
            return null;
        }

        const { [MES_QUERY_PARAMS.CLASS_ID]: classId } = lmsHomeworkData;

        const teamId = teams.find((team) => team.external_lms?.mes === classId)?.id;

        return teamId || null;
    }
);
