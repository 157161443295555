import { NOTIFICATION_THEMES } from '../constants';

import * as constants from './constants';
import { IntlMessageNotification, NotificationInfo } from './types';

export const showNotification = (notificationInfo: NotificationInfo) =>
    ({
        type: constants.SHOW_NOTIFICATION,
        notificationInfo,
    }) as const;

export const setNotification = (notification: NotificationInfo) =>
    ({
        type: constants.SET_NOTIFICATION,
        notification,
    }) as const;

export const hideNotification = () =>
    ({
        type: constants.HIDE_NOTIFICATION,
    }) as const;

export const showIntlMessageNotification = (notificationInfo: IntlMessageNotification) =>
    showNotification({
        id: constants.INTL_MESSAGE_NOTIFICATION_ID,
        theme: notificationInfo.theme || NOTIFICATION_THEMES.NORMAL,
        ...notificationInfo,
    } as const);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const showNetworkError = (error?: any) =>
    showNotification({
        id: constants.NETWORK_ERROR_NOTIFICATION_ID,
        theme: NOTIFICATION_THEMES.FAIL,
        error,
    } as const);
