/**
 * Мы используем SimpleMarkdown - https://github.com/Khan/simple-markdown
 * Преобразует строку в js-объект (parse), а затем строит реакт-дерево (output).
 */

import b from 'b_';
import cx from 'classnames';
import React, { RefObject, PropsWithChildren } from 'react';
import SimpleMarkdown, { ParserRules } from 'simple-markdown';

import { preFormat } from '../../utils/format-number/format-number';
import withRouterPath, { InjectedProps } from '../../utils/hocs/with-router-path/with-router-path';
import { MdState } from '../marker/types';

import { getOutput, prepareContent } from './utils';

import './markdown.scss';

const defaultPreParse = preFormat;

interface Props extends InjectedProps {
    display?: 'block';
    mdState?: MdState;
    mix?: string;
    rules?: ParserRules;
    innerRef?: RefObject<HTMLDivElement>;
    preParse?: typeof defaultPreParse;
    showImagePreview?: boolean;
}

class Markdown extends React.Component<PropsWithChildren<Props>> {
    UNSAFE_componentWillMount() {
        const rules = this.props.rules || SimpleMarkdown.defaultRules;

        this.output = getOutput(rules);
    }

    output: ReturnType<typeof getOutput>;

    render() {
        const {
            children,
            display,
            mdState,
            mix,
            innerRef,
            preParse = defaultPreParse,
            getRouterPath,
            staticUrls,
            showImagePreview,
        } = this.props;
        const preparedContent = prepareContent(children as string, preParse);
        const mdStateWithRouteState = { ...mdState, getRouterPath, staticUrls, showImagePreview };
        const className = cx(b('markdown', { display }), mix);

        return (
            <span className={className} ref={innerRef}>
                {this.output(preparedContent, mdStateWithRouteState)}
            </span>
        );
    }
}

export default withRouterPath(Markdown);
