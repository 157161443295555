import { Link, SimpleCallback, Text } from 'platform-components';
import React from 'react';

interface Props {
    mix?: string;
    onClick?: SimpleCallback;
    textId: string;
}

export const TeamBuilderBackButton = (props: Props) => {
    const { mix, textId, onClick } = props;

    return (
        <Link mix={mix} onClick={onClick} pseudo>
            <Text id={textId} />
        </Link>
    );
};
