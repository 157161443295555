import { FROM_PROJECT, REG_REDIRECT_URL } from '@yandex-int/k-common/constants';

export const LOGGED_IN_SUCCESSFULLY = 'logged_in_successfully';

export const LIBRARY_CONTENT_TYPE = {
    STACK: 'stack',
    COURSE_LESSON: 'courseLesson',
    PREPARED_LESSON: 'preparedLesson',
};

export const MAX_PROMO_LENGTH = 18;

export enum WEEK_DAYS {
    MONDAY = 1,
    TUESDAY = 2,
    WEDNESADY = 3,
    THURSDAY = 4,
    FRIDAY = 5,
    SATURDAY = 6,
    SUNDAY = 0,
}

export const SEARCH_PARAM_FROM_PROJECT = FROM_PROJECT;
export const SEARCH_PARAM_REG_REDIRECT_URL = REG_REDIRECT_URL;
export const SEARCH_JOINED_BY_CODE = 'joined_by_code';
export const SEARCH_SCROLL_TO_LESSON = 'scroll_to_lesson';
export const SEARCH_OPEN_MODULE = 'module_id';
export const SEARCH_GROUP_INDEX = 'group_index';
export const SEARCH_PARAM_TEACHER_PROMO = 'teacher-code';
export const SEARCH_PARAM_RELOAD_LESSON_ID = 'reload_lesson_id';
export const SEARCH_PARAM_RELOAD_SYMBOL = '#';
export const SEARCH_PARAM_PROMO_THEME_SCROLL = 'promoThemeId';
export const SEARCH_PARAM_LESSON_SOURCE = 'lessonSource';
export const SEARCH_PARAM_REFERAL_CODE = 'referalCode';

export const COMMON_NOTIFICATION_DELAY = 6000;
