import { createSelector } from 'reselect';

import { getNewSubject } from 'common.components/team-builder/selectors';
import { getUnassignedCoursesTemplatesList } from 'store/courses-templates/selectors';
import { getCoursesByClass } from 'store/courses/selectors';

export const getCurrentTeamCoursesCount = createSelector([getCoursesByClass], (courses) => courses.length);

export const getSubjectsByNewSubject = createSelector(
    [getNewSubject, getUnassignedCoursesTemplatesList],
    (newSubject, unassignedCoursesTemplatesList) => {
        return newSubject
            ? (unassignedCoursesTemplatesList || []).filter((ct) => ct.subject === newSubject)
            : unassignedCoursesTemplatesList || [];
    }
);
