import b from 'b_';
import cx from 'classnames';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider, useIntl } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';

import { getPrefix } from '../../utils/get-prefix.js';
import { getOuterHeight } from '../../utils/style-properties/height';
import { getOffset } from '../../utils/style-properties/offset';
import { getPosition } from '../../utils/style-properties/position';
import { getOuterWidth } from '../../utils/style-properties/width';
import { POPUP_AXIS, POPUP_DIRECTIONS, POPUP_INDENTS, POPUP_THEMES } from '../constants';

import './popup.scss';

class Popup extends React.Component {
    static propTypes = {
        arrowOffsetLeft: PropTypes.string,
        arrowOffsetTop: PropTypes.string,
        autoclosable: PropTypes.bool,
        // TODO: Подобрать более удачный нейминг для пропа и его возможных значений
        axis: PropTypes.string,
        children: PropTypes.node,
        direction: PropTypes.string,
        hasArrow: PropTypes.bool,
        indent: PropTypes.oneOf(Object.values(POPUP_INDENTS)),
        inLayer: PropTypes.bool,
        intl: PropTypes.object,
        maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        minWidth: PropTypes.string,
        maxWidth: PropTypes.string,
        mix: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        offsetLeft: PropTypes.number,
        offsetTop: PropTypes.number,
        onToggle: PropTypes.func,
        selfclosable: PropTypes.bool,
        shown: PropTypes.bool,
        style: PropTypes.object,
        title: PropTypes.string,
        theme: PropTypes.string,
        trackOwnerPosition: PropTypes.bool,
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        fitToScreen: PropTypes.bool,
        availableArea: PropTypes.object,
    };

    static defaultProps = {
        autoclosable: false,
        axis: POPUP_AXIS.CENTER,
        direction: POPUP_DIRECTIONS.BOTTOM,
        indent: POPUP_INDENTS.NORMAL,
        offsetLeft: 0,
        offsetTop: 0,
        theme: POPUP_THEMES.NORMAL,
    };

    state = {
        shown: this.props.shown,
        position: { left: -10000, top: -10000 },
        correctionAttempt: 0,
        ownerOffset: { left: 0, top: 0 },
        ownerPosition: { left: 0, top: 0 },
        arrowOffsetLeft: this.props.arrowOffsetLeft,
        arrowOffsetTop: this.props.arrowOffsetTop,
    };

    componentDidMount() {
        const { autoclosable, shown, inLayer } = this.props;

        if (shown && autoclosable) {
            this.outsideClickHandlerTimer = window.setTimeout(this.bindClosePopupIfClickedOutside, 0);
        }

        this.timer = setTimeout(this.updatePositions, 0);

        if (inLayer) {
            this.createPopupPortalLayer();
        }

        window.addEventListener('resize', this.debouncedUpdatePositions);
        window.addEventListener('scroll', this.debouncedUpdatePositions, { passive: true, capture: true });
        window.addEventListener('transitionend', this.debouncedUpdatePositions, { passive: true, capture: true });
        window.addEventListener('animationend', this.debouncedUpdatePositions, { passive: true, capture: true });
    }

    UNSAFE_componentWillReceiveProps({ axis: nextAxis, shown: nextShown }) {
        const { axis, shown } = this.props;

        if (axis !== nextAxis) {
            this.updatePositions();
        }

        if (shown !== nextShown) {
            if (nextShown) {
                this.show(() => {});
            } else {
                this.hide();
            }
        }
    }

    componentDidUpdate() {
        const { trackOwnerPosition } = this.props;

        if (this.owner && trackOwnerPosition) {
            this.updateOwnerCoordinates();
        }

        this.correctVerticalPosition();
    }

    componentWillUnmount() {
        const { inLayer } = this.props;

        this.unbindClosePopupIfClickedOutside();

        window.removeEventListener('resize', this.debouncedUpdatePositions);
        window.removeEventListener('scroll', this.debouncedUpdatePositions, { passive: true, capture: true });
        window.removeEventListener('transitionend', this.debouncedUpdatePositions, { passive: true, capture: true });
        window.removeEventListener('animationend', this.debouncedUpdatePositions, { passive: true, capture: true });

        if (inLayer) {
            this.removePopupPortalLayer();
        }

        window.clearTimeout(this.outsideClickHandlerTimer);
        window.clearTimeout(this.timer);
    }

    createPopupPortalLayer = () => {
        this.portalLayer = document.createElement('div');
        document.body.appendChild(this.portalLayer);
        this.portalLayer.className = 'popup-portal-layer';
    };

    removePopupPortalLayer = () => {
        document.body.removeChild(this.portalLayer);
    };

    closePopupIfClickedOutside = (e) => {
        if (!this.state.shown) {
            return;
        }

        if (this.clickedOutsideElement(e, this.popup)) {
            this.hide();
        }
    };

    bindClosePopupIfClickedOutside = () => {
        // в модалке событие выше body не всплывает
        document.body.addEventListener('click', this.closePopupIfClickedOutside);
    };

    unbindClosePopupIfClickedOutside = () => {
        // в модалке событие выше body не всплывает
        document.body.removeEventListener('click', this.closePopupIfClickedOutside);
    };

    closeAllPopups = () => {
        document.body.click();
    };

    handleRef = (ref) => {
        if (!ref) {
            return;
        }

        this.popup = ref;
    };

    handleArrowRef = (ref) => {
        if (!ref) {
            return;
        }

        this.arrowRef = ref;
    };

    debouncedUpdatePositions = debounce(() => {
        this.updatePositions();
    }, 50);

    setOwner = (owner) => {
        clearTimeout(this.timer);

        if (owner) {
            this.owner = owner;
            this.timer = setTimeout(this.updatePositions.bind(this), 0);
        } else {
            this.owner = null;
        }

        return this;
    };

    hide = () => {
        const { autoclosable, onToggle } = this.props;

        this.setState({ shown: false });
        if (onToggle) {
            onToggle({ shown: false });
        }
        if (autoclosable) {
            this.unbindClosePopupIfClickedOutside();
        }
    };

    show = (callback) => {
        const { autoclosable, onToggle } = this.props;

        this.setState({ shown: true }, callback);
        if (onToggle) {
            onToggle({ shown: true });
        }
        if (autoclosable) {
            this.bindClosePopupIfClickedOutside();
        }
    };

    toggle = () => {
        this.state.shown ? this.hide() : this.show(); // eslint-disable-line no-unused-expressions
    };

    clickedOutsideElement = (e, element) => {
        const owner = this.owner ? this.owner : null;

        return !(element.contains(e.target) || (owner && owner.contains(e.target)));
    };

    getCssValue = (node, attr) => {
        if (!node) {
            return 0;
        }

        const style = getComputedStyle(node);
        const value = style[attr];

        return parseInt(value, 10);
    };

    updateOwnerCoordinates = () => {
        const { inLayer } = this.props;
        const { ownerOffset: prevOffset, ownerPosition: prevPosition } = this.state;

        if (inLayer) {
            const currentOffset = getOffset(this.owner);
            if (currentOffset.top !== prevOffset.top || currentOffset.left !== prevOffset.left) {
                this.setState({ ownerOffset: currentOffset });
                this.updatePositions();
            }
        } else {
            const currentPosition = getPosition(this.owner);
            if (currentPosition.top !== prevPosition.top || currentPosition.left !== prevPosition.left) {
                this.setState({ ownerPosition: currentPosition });
                this.updatePositions();
            }
        }
    };

    /* eslint-disable complexity */
    updatePositions = () => {
        const { arrowOffsetTop, arrowOffsetLeft, direction, indent, inLayer, offsetLeft, offsetTop, fitToScreen } =
            this.props;
        const ownerHeight = this.owner ? getOuterHeight(this.owner) : 0;
        const ownerWidth = this.owner ? getOuterWidth(this.owner) : 0;
        const ownerMarginLeft = inLayer ? 0 : this.getCssValue(this.owner, 'margin-left');
        const ownerMarginTop = inLayer ? 0 : this.getCssValue(this.owner, 'margin-top');
        const popupHeight = this.popup ? getOuterHeight(this.popup) : 0;
        const popupWidth = this.popup ? getOuterWidth(this.popup) : 0;
        const arrowWidth = this.arrowRef ? this.arrowRef.offsetWidth : 0;
        const popupOffsetParent = this.popup ? this.popup.offsetParent || this.popup : null;
        let axis = this.props.axis;
        let verticalPosition = POPUP_AXIS.CENTER;
        let horizontalPosition = POPUP_AXIS.CENTER;

        let position = { left: 0, top: 0 };
        if (this.owner) {
            position = inLayer ? getOffset(this.owner) : getPosition(this.owner);
        }

        switch (direction) {
            case POPUP_DIRECTIONS.TOP:
                verticalPosition = POPUP_AXIS.START;
                break;
            case POPUP_DIRECTIONS.TOP_RIGHT:
                verticalPosition = POPUP_AXIS.START;
                horizontalPosition = POPUP_AXIS.END;
                axis = null;
                break;
            case POPUP_DIRECTIONS.BOTTOM_RIGHT:
                verticalPosition = POPUP_AXIS.END;
                horizontalPosition = POPUP_AXIS.END;
                axis = null;
                break;
            case POPUP_DIRECTIONS.BOTTOM:
                verticalPosition = POPUP_AXIS.END;
                break;
            case POPUP_DIRECTIONS.LEFT:
                horizontalPosition = POPUP_AXIS.START;
                break;
            case POPUP_DIRECTIONS.LEFT_BOTTOM:
                verticalPosition = POPUP_AXIS.END;
                horizontalPosition = POPUP_AXIS.START;
                axis = null;
                break;
            case POPUP_DIRECTIONS.RIGHT:
                horizontalPosition = POPUP_AXIS.END;
                break;
            default:
                break;
        }

        switch (verticalPosition) {
            case POPUP_AXIS.START:
                // EDUCATION-6436 - теперь позиционирование зависит от ownerHeight
                position.top -= popupHeight - ownerMarginTop - ownerHeight + indent;
                break;
            case POPUP_AXIS.END:
                position.top += ownerHeight + ownerMarginTop + indent;
                break;
            case POPUP_AXIS.CENTER:
            default:
                position.top += ownerHeight / 2 + ownerMarginTop - popupHeight / 2;
                break;
        }

        switch (horizontalPosition) {
            case POPUP_AXIS.START:
                position.left += -popupWidth + ownerMarginLeft - POPUP_INDENTS.NORMAL;
                break;
            case POPUP_AXIS.END:
                position.left += ownerWidth + ownerMarginLeft + POPUP_INDENTS.NORMAL;
                break;
            case POPUP_AXIS.CENTER:
            default:
                position.left += ownerMarginLeft;
                break;
        }

        switch (axis) {
            case POPUP_AXIS.START:
                if (horizontalPosition !== POPUP_AXIS.CENTER) {
                    // в начале по вертикали
                    position.top -= ownerHeight / 2 + ownerMarginTop - popupHeight / 2;

                    if (arrowOffsetTop === undefined) {
                        this.setState({
                            arrowOffsetTop: Math.max(
                                popupHeight > ownerHeight ? ownerHeight / 2 : popupHeight / 2,
                                arrowWidth / 2
                            ),
                        });
                    }
                } else {
                    // в начале по горизонтали
                    position.left += ownerMarginLeft;

                    if (arrowOffsetLeft === undefined) {
                        this.setState({
                            arrowOffsetLeft: Math.max(
                                popupWidth > ownerWidth ? ownerWidth / 2 : popupWidth / 2,
                                arrowWidth / 2
                            ),
                        });
                    }
                }
                break;
            case POPUP_AXIS.CENTER:
            case POPUP_AXIS.BOTH:
                if (horizontalPosition === POPUP_AXIS.CENTER) {
                    // по центру по горизонтали
                    position.left += (ownerWidth - popupWidth) / 2;
                }
                break;
            case POPUP_AXIS.END:
                if (horizontalPosition !== POPUP_AXIS.CENTER) {
                    // в конце по вертикали
                    position.top += ownerHeight / 2 + ownerMarginTop - popupHeight / 2;

                    if (arrowOffsetTop === undefined) {
                        this.setState({
                            arrowOffsetTop: Math.min(
                                popupHeight > ownerHeight ? popupHeight - ownerHeight / 2 : popupHeight / 2,
                                popupHeight - arrowWidth / 2
                            ),
                        });
                    }
                } else {
                    // в конце по горизонтали
                    position.left += ownerWidth + ownerMarginLeft - popupWidth;

                    if (arrowOffsetLeft === undefined) {
                        this.setState({
                            arrowOffsetLeft: Math.min(
                                popupWidth > ownerWidth ? popupWidth - ownerWidth / 2 : popupWidth / 2,
                                popupWidth - arrowWidth / 2
                            ),
                        });
                    }
                }
                break;
            default:
                break;
        }

        position.left += offsetLeft;
        position.top += offsetTop;

        if (fitToScreen && popupOffsetParent) {
            const parentRect = popupOffsetParent.getBoundingClientRect();
            const offsetParentLeft = parentRect.left;

            const maxLeft = window.innerWidth - (popupWidth + offsetParentLeft);
            const minLeft = -offsetParentLeft;

            if (position.left < minLeft) {
                position.left = minLeft;
            } else if (position.left > maxLeft) {
                position.left = maxLeft;
            }
        }
        return new Promise((resolve) => this.setState({ position }, resolve));
    };

    correctVerticalPosition = () => {
        const { availableArea } = this.props;
        const { correctionAttempt, position } = this.state;

        if (!availableArea) {
            return;
        }

        const MAX_CORRECTION_ATTEMPTS = 1;
        if (correctionAttempt >= MAX_CORRECTION_ATTEMPTS) {
            return;
        }

        const CORRECTION_TYPE = {
            NOT_AVAILABLE: 'notAvailable',
            TOP: 'top',
            BOTTOM: 'bottom',
            NOT_NEEDED: 'not_needed',
        };
        const getCorrectionType = (popupRect, areaRect) => {
            const { shown } = this.state;
            if (!shown) {
                return CORRECTION_TYPE.NOT_NEEDED;
            }

            if (popupRect.height >= areaRect.height) {
                return CORRECTION_TYPE.NOT_AVAILABLE;
            }

            if (popupRect.top < areaRect.top) {
                return CORRECTION_TYPE.TOP;
            }

            if (popupRect.bottom > areaRect.bottom) {
                return CORRECTION_TYPE.BOTTOM;
            }

            return CORRECTION_TYPE.NOT_NEEDED;
        };

        const areaRect = availableArea.getBoundingClientRect();
        const popupRect = this.popup.getBoundingClientRect();

        const correctionType = getCorrectionType(popupRect, areaRect);

        switch (correctionType) {
            case CORRECTION_TYPE.TOP: {
                const correctionDelta = popupRect.top - areaRect.top;
                this.setState({
                    position: { top: position.top - correctionDelta, left: position.left },
                    correctionAttempt: correctionAttempt + 1,
                });
                break;
            }

            case CORRECTION_TYPE.BOTTOM: {
                const correctionDelta = popupRect.bottom - areaRect.bottom;
                this.setState({
                    position: { top: position.top - correctionDelta, left: position.left },
                    correctionAttempt: correctionAttempt + 1,
                });
                break;
            }

            case CORRECTION_TYPE.NOT_AVAILABLE:
            case CORRECTION_TYPE.NOT_NEEDED:
            default:
                break;
        }
    };

    /* eslint-enable complexity */

    renderArrow = () => {
        const { arrowOffsetLeft, arrowOffsetTop } = this.state;
        const style = {
            left: arrowOffsetLeft,
            top: arrowOffsetTop,
        };

        return (
            <div className="popup__arrow-wrapper" ref={this.handleArrowRef} style={style}>
                <i className="popup__arrow" />
            </div>
        );
    };

    renderTitle = () => {
        const { title } = this.props;

        return <h3 className="popup__title">{title}</h3>;
    };

    renderToPortalLayer = () => {
        const { intl } = this.props;

        return ReactDOM.createPortal(
            <BrowserRouter basename={getPrefix()}>
                <IntlProvider {...intl}>{this._render()}</IntlProvider>
            </BrowserRouter>,
            this.portalLayer
        );
    };

    _render() {
        const { shown, position } = this.state;
        const {
            axis,
            children,
            direction,
            hasArrow,
            maxHeight,
            maxWidth,
            minWidth,
            mix,
            selfclosable,
            theme,
            title,
            width,
        } = this.props;
        const classes = b('popup', {
            theme,
            direction,
            shown,
            'with-arrow': hasArrow,
        });
        const style = {
            left: position.left,
            top: position.top,
            width: axis === POPUP_AXIS.BOTH && this.owner ? getOuterWidth(this.owner) : width,
            minWidth: minWidth ? Number(minWidth) : 'unset',
            maxWidth: maxWidth ? Number(maxWidth) : 'unset',
            maxHeight: maxHeight || 'unset',
            ...this.props.style,
        };

        return (
            <span className={cx(classes, mix)} onClick={selfclosable && this.hide} ref={this.handleRef} style={style}>
                {hasArrow && this.renderArrow()}
                {title && this.renderTitle()}
                <span className="popup__content">{children}</span>
            </span>
        );
    }

    render() {
        const { inLayer } = this.props;

        if (inLayer && this.portalLayer) {
            return this.renderToPortalLayer();
        }

        return this._render();
    }
}

// eslint-disable-next-line react/no-multi-comp
const PopupWithIntl = React.forwardRef((props, ref) => {
    const intl = useIntl();

    return <Popup {...props} intl={intl} ref={ref} />;
});

export default PopupWithIntl;
