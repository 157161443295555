import b from 'b_';
import cx from 'classnames';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { HEADER_TEXT_SIZE, TEXT_MARKUP_TYPE, TEXT_WEIGHT, UI_KIT_HEADER_TYPOGRAPHY } from '../constants';
import TextMarkup from '../text-markup/text-markup';

import './header-text.scss';

enum TAG {
    H1 = 'h1',
    H2 = 'h2',
    H3 = 'h3',
    H4 = 'h4',
    H5 = 'h5',
    H6 = 'h6',
}

interface Props {
    /** @deprecated Use `typography` prop instead */
    antiqua?: boolean;
    children?: ReactNode;
    ellipsis?: boolean;
    /** id текста из танкера */
    id?: string;
    /** id элемента */
    htmlId?: string;
    fixed?: boolean;
    mix?: string;
    /** @deprecated Use `typography` prop instead */
    size?: HEADER_TEXT_SIZE;
    style?: React.CSSProperties;
    tag: TAG;
    type?: TEXT_MARKUP_TYPE;
    typography?: UI_KIT_HEADER_TYPOGRAPHY;
    values?: React.ComponentProps<typeof FormattedMessage>['values'];
    /** @deprecated Use `typography` prop instead */
    weight?: TEXT_WEIGHT;
    role?: React.AriaRole;
}

function mapTagToSize(tag: TAG) {
    switch (tag) {
        case TAG.H1:
            return HEADER_TEXT_SIZE.XXL;

        case TAG.H2:
            return HEADER_TEXT_SIZE.XL;

        case TAG.H3:
            return HEADER_TEXT_SIZE.M;

        case TAG.H4:
            return HEADER_TEXT_SIZE.XS;

        case TAG.H5:
        case TAG.H6:
        default:
            return HEADER_TEXT_SIZE.XXS;
    }
}

const HeaderText = ({
    antiqua,
    children,
    ellipsis,
    id,
    htmlId,
    fixed = false,
    mix,
    size,
    style,
    tag,
    type,
    typography,
    values,
    weight = TEXT_WEIGHT.S,
    role,
}: Props) => {
    const headerSize = size || mapTagToSize(tag);

    const content = id ? <TextMarkup id={id} type={type} values={values} /> : children;

    const className = cx(
        b('header-text', {
            antiqua: typography ? undefined : antiqua,
            ellipsis,
            fixed,
            size: typography ? undefined : headerSize,
            tag,
            typography,
            weight: typography ? undefined : weight,
        }),
        mix
    );

    return React.createElement(tag, { id: htmlId, className, style, role }, content);
};

interface HProps extends Omit<Props, 'tag'> {}

/* eslint-disable react/no-multi-comp */
export const H1 = (props: HProps) => <HeaderText tag={TAG.H1} {...props} />;
export const H2 = (props: HProps) => <HeaderText tag={TAG.H2} {...props} />;
export const H3 = (props: HProps) => <HeaderText tag={TAG.H3} {...props} />;
export const H4 = (props: HProps) => <HeaderText tag={TAG.H4} {...props} />;
export const H5 = (props: HProps) => <HeaderText tag={TAG.H5} {...props} />;
export const H6 = (props: HProps) => <HeaderText tag={TAG.H6} {...props} />;
