const breakpointMinUtil = (width: number) => `(min-width: ${width}px)`;
const breakpointMaxUtil = (width: number) => `(max-width: ${width - 0.02}px)`;

/**
 * @see platform-components/src/design/von-dyck-breakpoints.scss
 */
export const MEDIA_QUERY_MOBILE_M = breakpointMaxUtil(600);
export const MEDIA_QUERY_MOBILE_L = breakpointMaxUtil(800);
export const MEDIA_QUERY_MOBILE_HORIZONTAL = `${breakpointMaxUtil(800)} and (orientation: landscape)`;
export const MEDIA_QUERY_DESKTOP_XS = breakpointMaxUtil(1024);
export const MEDIA_QUERY_DESKTOP_S = breakpointMaxUtil(1280);

export const MEDIA_QUERY_DESKTOP_ROVER = breakpointMinUtil(768);
export const MEDIA_QUERY_DESKTOP_L = breakpointMinUtil(1367);
export const MEDIA_QUERY_DESKTOP_XL = breakpointMinUtil(1601);
export const MEDIA_QUERY_DESKTOP_XXL = breakpointMinUtil(1921);
