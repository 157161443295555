import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './form-view__card-title.scss';

const FormViewCardTitle = ({ children, mix }) => <div className={cx('form-view__card-title', mix)}>{children}</div>;

FormViewCardTitle.propTypes = {
    children: PropTypes.node.isRequired,
    mix: PropTypes.string,
};

export default FormViewCardTitle;
