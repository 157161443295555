import PropTypes from 'prop-types';

import { INPUT_SIZES, INPUT_THEMES } from '../constants';

export const inputPropTypes = {
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool,
    errorMessage: PropTypes.node,
    focused: PropTypes.bool,
    focusInputEnd: PropTypes.bool,
    hidden: PropTypes.bool,
    max: PropTypes.number,
    maxLength: PropTypes.number,
    min: PropTypes.number,
    mix: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onControlRef: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
    onKeyUp: PropTypes.func,
    onPaste: PropTypes.func,
    pattern: PropTypes.string,
    placeholder: PropTypes.node,
    placeholderAsInput: PropTypes.bool,
    placeholderAsLabel: PropTypes.bool,
    readOnly: PropTypes.bool,
    resetValidAfterFocus: PropTypes.bool,
    size: PropTypes.oneOf(Object.values(INPUT_SIZES)),
    successMessage: PropTypes.string,
    theme: PropTypes.oneOf(Object.values(INPUT_THEMES)),
    type: PropTypes.string,
    valid: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
