import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
// @ts-ignore
import { SUBJECTS_SLUGS } from '@yandex-int/k-common/constants';

import { SchoolData } from 'common.components/school-form/typings';
import { YM_LOCATION } from 'utils/ym-constants';

import { TEAM_BUILDER_ENTRY_STEPS, TeamBuilderStore, OnSuccess, OnMesSuccess } from './typings';

const initialState: TeamBuilderStore = {
    entryPoint: null,
    started: false,
    school: null,
    address: null,
    submittingStatus: LOADING_STATUSES.UNSENT,
    codeSubmittingStatus: LOADING_STATUSES.UNSENT,
    teamData: null,
    teamPatchData: null,
    students: null,
    selectedTemplatesIds: null,
    code: null,
    onSuccess: null,
    newSubject: null,
    canEditGrade: true,
};

const slice = createSlice({
    name: 'TEAM_BUILDER',
    initialState,
    reducers: {
        start: (
            state,
            action: PayloadAction<{
                entryPoint: TEAM_BUILDER_ENTRY_STEPS;
                location?: YM_LOCATION;
                onSuccess?: OnSuccess | OnMesSuccess;
                newSubject?: SUBJECTS_SLUGS;
                defaultGrade?: number;
                canEditGrade?: boolean;
            }>
        ) => {
            state.entryPoint = action.payload.entryPoint || null;
            state.location = action.payload.location;
            state.onSuccess = action.payload.onSuccess || null;
            state.newSubject = action.payload.newSubject || null;
            state.started = true;

            if (typeof action.payload.canEditGrade === 'boolean') {
                state.canEditGrade = action.payload.canEditGrade;
            }

            if (action.payload.defaultGrade) {
                state.defaultGrade = action.payload.defaultGrade;
            }
        },
        stop: (state) => {
            state.entryPoint = null;
            delete state.location;
            state.onSuccess = null;
            state.newSubject = null;
            state.started = false;
            state.canEditGrade = initialState.canEditGrade;
            delete state.defaultGrade;
        },
        setSchoolData: (state, action: PayloadAction<SchoolData>) => {
            state.school = action.payload.school;
            state.address = action.payload.address;
        },
        setTeamData: (state, action: PayloadAction<{ letter: string; number: number } | null>) => {
            if (action.payload) {
                const { letter, number } = action.payload;
                state.teamData = { teamLetter: letter, teamLevel: number };
            } else {
                state.teamData = action.payload;
            }
        },
        setTeamPatchData: (state, action: PayloadAction<{ letter: string; number: number }>) => {
            const { letter, number } = action.payload;
            state.teamPatchData = { teamLetter: letter, teamLevel: number };
        },
        setStudentsData: (state, action: PayloadAction<{ students: Array<string> }>) => {
            state.students = action.payload.students;
        },
        setSelectedTemplatesIds: (state, action: PayloadAction<{ selectedTemplatesIds: Array<number> }>) => {
            state.selectedTemplatesIds = action.payload.selectedTemplatesIds;
        },
        setCode: (state, action: PayloadAction<{ code: string }>) => {
            state.code = action.payload.code;
        },
        clearTeamData: (state) => {
            state.teamData = null;
        },
        clearData: (state) => {
            state.submittingStatus = LOADING_STATUSES.UNSENT;
            state.teamData = null;
            state.teamPatchData = null;
            state.students = null;
            state.selectedTemplatesIds = null;
            state.code = null;
            state.codeSubmittingStatus = LOADING_STATUSES.UNSENT;
        },
        submitMesRequest: (
            state,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            action: PayloadAction<{
                hasSchool: boolean;
                students: Array<string> | null;
                schoolData: SchoolData;
                teamData: { teamLetter: string; teamLevel: number } | null;
                teamPatchData: { teamLetter: string; teamLevel: number } | null;
                subjectsIds?: Array<number> | null;
                location?: YM_LOCATION;
                hasLetter: boolean;
                onSuccess?: (schoolData: SchoolData) => void;
                onTeamCreateSuccess?: (classId: number) => void;
            }>
        ) => {
            // saga
            state.submittingStatus = LOADING_STATUSES.LOADING;
            // state.onSuccess = action.payload.onSuccess || null;
        },
        submitRequest: (
            state,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            action: PayloadAction<{
                hasSchool: boolean;
                students: Array<string> | null;
                schoolData: SchoolData;
                teamData: { teamLetter: string; teamLevel: number } | null;
                teamPatchData: { teamLetter: string; teamLevel: number } | null;
                subjectsIds?: Array<number> | null;
                location?: YM_LOCATION;
                hasLetter: boolean;
                onSuccess?: (classId: number, addedStudentIds: Array<number> | null) => void;
                onTeamCreateSuccess?: (classId: number) => void;
            }>
        ) => {
            // saga
            state.submittingStatus = LOADING_STATUSES.LOADING;
        },
        submitCode: (
            state,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            action: PayloadAction<{
                code: string | null;
                onSuccess?: (teamId: number) => void;
            }>
        ) => {
            state.codeSubmittingStatus = LOADING_STATUSES.LOADING;
        },
        submitCodeSuccess: (state) => {
            state.codeSubmittingStatus = LOADING_STATUSES.SUCCESS;
        },
        submitCodeError: (state) => {
            state.codeSubmittingStatus = LOADING_STATUSES.ERROR;
        },
        submitSuccess: (state) => {
            state.submittingStatus = LOADING_STATUSES.SUCCESS;
        },
        submitError: (state) => {
            state.submittingStatus = LOADING_STATUSES.ERROR;
        },
    },
});

export const teamBuilderActions = slice.actions;
export const teamBuilderReducer = slice.reducer;
