import b from 'b_';
import PropTypes from 'prop-types';
import React from 'react';

import { mdStatePropType } from '../../utils/prop-types/md-state';
import { INPUT_TYPES } from '../constants';
import { MARKER_STATUS_MODE } from '../marker/marker.constants';

import InputTypeChoice from './_type/inline-input_type_choice';
import InputTypeComma from './_type/inline-input_type_comma';
import InputTypeField from './_type/inline-input_type_field';
import InputTypeRational from './_type/inline-input_type_rational';
import InputTypeSeparator from './_type/inline-input_type_separator';
import InputTypeSequence from './_type/inline-input_type_sequence';
import InputTypeUnknown from './_type/inline-input_type_unknown';

import './inline-input.scss';

const INPUT_FOR_TYPE = {
    [INPUT_TYPES.CHOICE]: InputTypeChoice,
    [INPUT_TYPES.COMMA]: InputTypeComma,
    [INPUT_TYPES.FIELD]: InputTypeField,
    [INPUT_TYPES.SEQUENCE]: InputTypeSequence,
    [INPUT_TYPES.RATIONAL]: InputTypeRational,
    [INPUT_TYPES.SEPARATOR]: InputTypeSeparator,
};

class InlineInput extends React.Component {
    static propTypes = {
        answer: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number, PropTypes.array]),
        elements: PropTypes.object,
        featureFlags: PropTypes.object,
        groupCorrect: PropTypes.bool,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        mdState: mdStatePropType,
        onInputPressEnter: PropTypes.func,
        readOnly: PropTypes.bool,
        showCurrentAnswersStatus: PropTypes.bool,
        setInputAnswer: PropTypes.func.isRequired,
        type: PropTypes.string,
        userAnswer: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.bool, PropTypes.number]),
    };

    setAnswer = (userAnswer) => {
        const { id, setInputAnswer } = this.props;

        return setInputAnswer(id, userAnswer);
    };

    getTheme = ({ groupCorrect, theme, markerTheme, userAnswer, statusMode, type }) => {
        if (theme) {
            return theme;
        }

        if (markerTheme === 'correct') {
            return 'correct';
        }

        if (statusMode === MARKER_STATUS_MODE.EVERYTHING_IS_CORRECT) {
            return 'correct';
        } else if (statusMode === MARKER_STATUS_MODE.CORRECT_ONLY) {
            return groupCorrect ? 'correct' : 'normal';
        } else if (statusMode === MARKER_STATUS_MODE.FULL_STATUS) {
            return groupCorrect ? 'correct' : 'incorrect';
        } else if (statusMode === MARKER_STATUS_MODE.WITHOUT_SKIPPED) {
            const typesToShowSkippedAsIncorrect = [INPUT_TYPES.FIELD, INPUT_TYPES.CHOICE];
            // Если нужно было дать ответ, а пользователь его не дал - не показываем ему этого
            // Кроме случая, когда это обычный инпут или выпадающий список
            if (!groupCorrect && userAnswer === null && !typesToShowSkippedAsIncorrect.includes(type)) {
                return 'normal';
            }

            return groupCorrect ? 'correct' : 'incorrect';
        } else if (statusMode === MARKER_STATUS_MODE.NORMAL) {
            return 'normal';
        }

        return markerTheme || 'normal';
    };

    getReadOnlyFromTheme = (theme) => {
        return theme !== 'normal';
    };

    onKeyDown = ({ keyCode, ...e }, selectOpened) => {
        const { onInputPressEnter, type } = this.props;
        const isCtrl = e.ctrlKey || e.metaKey;
        const handleEvent =
            (!isCtrl && keyCode === 13 && type !== 'choice') ||
            ([13, 32].includes(keyCode) && selectOpened && type === 'choice');

        if (handleEvent && onInputPressEnter instanceof Function) {
            onInputPressEnter();
        }
    };

    render() {
        const { type, elements, mdState } = this.props;
        const theme = this.getTheme(this.props);
        const readOnly = this.props.readOnly || this.getReadOnlyFromTheme(theme);

        const className = b('inline-input', {
            'read-only': readOnly,
            theme,
            type,
        });

        const component = INPUT_FOR_TYPE[type] || InputTypeUnknown;

        let children = null;
        const props = {
            ...this.props,
            className,
            readOnly,
            theme,
            setAnswer: this.setAnswer,
            onKeyDown: this.onKeyDown,
            featureFlags: mdState.featureFlags,
        };

        if (elements) {
            children = INPUT_FOR_TYPE[elements.type];
            props.childrenType = elements.type;
        }

        return React.createElement(component, props, children);
    }
}

export default InlineInput;
