import { useState } from 'react';

const SAFE_STROKE_THRESHOLD = 36;
const BEAR_SCALE_RATIO = 1.208;

// 1. По дизайну уши рисуются с коэффициентом 1.208 относительно аватарки.
// 2. В случае, когда размер аватарки меньше 36px надо убирать поясок вокруг аватарки (SafeStroke).
export const useSafeStroke = () => {
    const [withSafeStroke, setWithSafeStroke] = useState(false);

    const ref = (el: SVGSVGElement) => {
        if (el === null) {
            return;
        }

        const scaledBearWidth = el.getBoundingClientRect().width;
        const originBearWidth = scaledBearWidth / BEAR_SCALE_RATIO;
        setWithSafeStroke(originBearWidth > SAFE_STROKE_THRESHOLD);
    };

    return { ref, withSafeStroke };
};
