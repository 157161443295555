import * as api from '@yandex-int/k-common/client/api';
import { ATTRIBUTES, AttributesType } from '@yandex-int/k-common/typings';
import { showNetworkError } from 'platform-components';
import { reportMessage } from 'platform-components/utils';
import { Dispatch } from 'redux';

import { getLessonViewCourseId } from 'common.components/lesson-view.store/selectors';
import { getContainerSlugFromPath, getTeamIdFromPath } from 'utils/path';

import * as lessonSelectors from '../selectors';
import { getThemeId } from '../utils';

import { slice } from './slice';
import { addedLessonToCourse } from './addedLessonToCourse';

export function addLessonToCourseAsync() {
    return async function (dispatch: Dispatch<any>, getState: () => any): Promise<number> {
        const lessonId = lessonSelectors.getLessonId(getState());

        try {
            const themeId = getThemeId();
            const teamId = getTeamIdFromPath();
            const containerSlug = getContainerSlugFromPath();
            const courseId = getLessonViewCourseId(getState());
            const moduleId = lessonSelectors.getLessonModuleId(getState());
            const lessonSource = lessonSelectors.getLessonSource(getState());
            const attributes: AttributesType = {} as AttributesType;

            if (lessonSource) {
                attributes[ATTRIBUTES.LESSON_SOURCE] = lessonSource;
            }

            const { clesson_id: clessonId } = await api.addLessonToCourse({
                attributes,
                courseId: courseId!,
                lessonId: lessonId!,
                themeId: themeId!,
                moduleId: moduleId!,
                containerSlug,
            });
            dispatch(slice.actions.lessonUpdated());
            dispatch(slice.actions.clearLessonSource());
            dispatch(
                addedLessonToCourse({
                    courseId,
                    teamId,
                    containerSlug,
                })
            );

            return clessonId;
        } catch (error) {
            if (error.status === 400) {
                reportMessage(`Ошибка при выдаче ГЗ ${lessonId}: ${JSON.stringify(error.data)}`);
            }
            console.error(error);

            dispatch(showNetworkError(error));
            throw error;
        }
    };
}
