import React from 'react';

import { setSelectionRangeAsync } from '../../utils/set-selection-range-async/set-selection-range-async';
import { INPUT_THEMES, INPUT_SIZES } from '../constants';

import InputContent from './__content/input__content';
import { INPUT_VALID_STATE } from './input.constants';
import { inputPropTypes } from './input.propTypes';

class Input extends React.PureComponent {
    static defaultProps = {
        size: INPUT_SIZES.M,
        theme: INPUT_THEMES.NORMAL,
        type: 'text',
        valid: 'uncertain',
        resetValidAfterFocus: true,
    };

    static propTypes = inputPropTypes;

    static defaultPropTypes = {
        placeholderAsLabel: true,
    };

    constructor(props) {
        super(props);

        this.state = {
            focused: props.focused,
            filled: this.getFilled(props.value, props.defaultValue),
            valid: props.valid,
            pattern: props.pattern ? new RegExp(props.pattern) : null,
        };
    }

    componentDidMount() {
        if (this.state.focused) {
            this.focus();
        }

        if (this.control) {
            const inputValue = this.control.value;

            if (inputValue !== this.getValue(this.props.value)) {
                this.onChange({ target: { value: inputValue } });
            }
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const nextValue = this.getValue(nextProps.value);
        const prevValue = this.getValue(this.props.value);

        if (nextProps.valid !== this.state.valid) {
            this.setState({ valid: nextProps.valid });
        }
        if (nextValue !== prevValue) {
            this.setState({ filled: this.getFilled(nextValue, nextProps.defaultValue) });
        }
        if (nextProps.focused !== this.props.focused) {
            this.setState({ focused: nextProps.focused });
        }
        if (nextProps.pattern !== this.props.pattern) {
            this.setState({ pattern: nextProps.pattern ? new RegExp(nextProps.pattern) : null });
        }
    }

    componentDidUpdate() {
        if (this.state.focused && !this.isControlFocused()) {
            this.focus();
        } else if (!this.state.focused && this.isControlFocused()) {
            this.control.blur();
        }
    }

    getValue = (val) => {
        if (val === null || val === undefined) {
            return val;
        }

        return val.toString();
    };

    isControlFocused = () => {
        if (typeof document === 'undefined' || !this.control) {
            return false;
        }

        return document.activeElement.isEqualNode(this.control);
    };

    onFocus = (e) => {
        // Делаем нефокусируемым readOnly инпут, как disabled
        const { readOnly, onFocus, valid, resetValidAfterFocus } = this.props;
        if (readOnly) {
            return;
        }

        this.setState({ focused: true, valid: resetValidAfterFocus ? 'uncertain' : valid });

        this.focus();

        if (onFocus) {
            onFocus(e);
        }
    };

    onBlur = (e) => {
        if (this.control) {
            this.control.blur();
        }

        const stillFocused = document.activeElement === this.control;
        // Эта проверка нужна для IE, в котором при ререндере
        // срабатывает blur, но элемент по-прежнему остается сфокусированным
        if (stillFocused) {
            return;
        }

        let { pattern, valid } = this.state;
        let { value, defaultValue } = this.props;

        if (pattern) {
            valid = !pattern.test(value) && value ? INPUT_VALID_STATE.NO : INPUT_VALID_STATE.UNCERTAIN;
        }
        this.setState({
            filled: this.getFilled(value, defaultValue) || Boolean(this.control && this.control.value),
            focused: false,
            valid,
        });

        if (this.props.onBlur) {
            this.props.onBlur(e);
        }
    };

    getFilled = (value, defaultValue) => {
        return Boolean(this.getValue(value)) || Boolean(this.getValue(defaultValue));
    };

    onKeyDown = (e) => {
        if (this.props.onKeyDown) {
            this.props.onKeyDown(e);
        }
    };

    onKeyUp = (e) => {
        if (this.props.onKeyUp) {
            this.props.onKeyUp(e);
        }
    };

    onPaste = (e) => {
        const { onPaste } = this.props;

        if (onPaste) {
            onPaste(e);
        }
    };

    focus() {
        const { focusInputEnd, value } = this.props;
        const { control } = this;

        control.focus();

        if (focusInputEnd && value) {
            setSelectionRangeAsync(control, [value.length, value.length]);
        }
    }

    onChange = (e) => {
        let value = this.processValue(e.target.value);

        const eCopy = { ...e, target: { ...e.target, value } };

        if (this.props.type !== 'date') {
            eCopy.target.selectionStart = e.target.selectionStart;
        }

        if (this.props.onChange) {
            this.props.onChange(eCopy);
        }
    };

    processValue(value) {
        if (!value) {
            return value;
        }

        const { min, max } = this.props;

        if (min && value < min) {
            return min;
        }

        if (max && value > max) {
            return max;
        }

        return value;
    }

    setControlRef = (ref) => {
        const { onControlRef } = this.props;

        this.control = ref;

        if (onControlRef) {
            onControlRef(ref);
        }
    };

    render() {
        return (
            <InputContent
                {...this.props}
                onBlur={this.onBlur}
                onChange={this.onChange}
                onControlRef={this.setControlRef}
                onFocus={this.onFocus}
                onKeyDown={this.onKeyDown}
                onKeyUp={this.onKeyUp}
                onPaste={this.onPaste}
                state={this.state}
            />
        );
    }
}

export default Input;
