import * as api from '@yandex-int/k-common/client/api';
import { showNetworkError, showIntlMessageNotification } from 'platform-components';
import { GRAVITIES, NOTIFICATION_THEMES } from 'platform-components/constants';
import ym from 'react-yandex-metrika';
import { call, put, select } from 'redux-saga/effects';

import { COMMON_NOTIFICATION_DELAY } from 'common.components/constants';
import { addViewedHint } from 'common.components/user/actions';
import { getAssignedLessonsCount } from 'common.components/user/selectors';
import { ASSIGNED_LESSONS_COUNT } from 'common.components/user/user.constants';

import lessonActions from '../actions';

export function* restoreCLessonSaga(action: ReturnType<typeof lessonActions.restoreCLessonRequest>) {
    try {
        const { cLessonId, isDraft } = action.payload;

        const assignedLessonsCount: number = yield select(getAssignedLessonsCount);
        const messageId = isDraft ? 'lesson.clessonDraftIsRestored' : 'lesson.clessonIsRestored';

        ym('reachGoal', 'teacher_lesson_creation_delete_lesson_reverse');

        yield call(api.restoreClesson, cLessonId);
        yield put(lessonActions.restoreCLessonSuccess({ cLessonId }));

        // если урок был выдан - инкрементим кол-во выданных уроков
        if (!isDraft) {
            yield put(addViewedHint(ASSIGNED_LESSONS_COUNT, assignedLessonsCount + 1));
        }

        // обновляем список уроков на странице курса
        yield put(lessonActions.lessonUpdated());

        yield put(
            showIntlMessageNotification({
                closeDelay: COMMON_NOTIFICATION_DELAY,
                gravity: GRAVITIES.BOTTOM,
                messageId,
                theme: NOTIFICATION_THEMES.TOAST,
            })
        );
    } catch (error) {
        console.error(error);

        yield put(showNetworkError(error));
        yield put(lessonActions.restoreCLessonError());
    }
}
