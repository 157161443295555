import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import { LESSONS_TYPES, type Course, type FullCourseSubject } from '@yandex-int/k-common/typings';
import type { SUBJECTS_SLUGS_TYPE } from 'platform-components';

import * as lessonActions from 'common.components/lesson/actions/addedLessonToCourse';

import type { CoursesStoreState, LessonsCount } from './typings';

const lessonsCountsInitialState = {
    [LESSONS_TYPES.UNASSIGNED]: null,
    [LESSONS_TYPES.ASSIGNED]: null,
    [LESSONS_TYPES.ACTIVE]: null,
    [LESSONS_TYPES.FINISHED]: null,
};

const initialState: CoursesStoreState = {
    coursesList: {},
    coursesSubjectsList: {},
    lessonsCounts: {},
    coursesSubjectsSlugs: {
        loadingStatus: LOADING_STATUSES.UNSENT,
        list: [],
    },
};

// Параметры нужны для саги
/* eslint-disable @typescript-eslint/no-unused-vars */
const slice = createSlice({
    name: 'COURSES',
    initialState: initialState,
    reducers: {
        getCoursesByTeamRequest: (
            state,
            action: PayloadAction<{
                teamId: number;
            }>
        ) => {
            const { teamId } = action.payload;

            setInitialCoursesListState(state, teamId);

            state.coursesList[teamId].loadingStatus = LOADING_STATUSES.LOADING;
        },
        getCoursesByTeamSuccess: (
            state,
            action: PayloadAction<{
                teamId: number;
                courses: Array<Course>;
            }>
        ) => {
            const { teamId, courses } = action.payload;

            setInitialCoursesListState(state, teamId);

            state.coursesList[teamId].loadingStatus = LOADING_STATUSES.SUCCESS;
            state.coursesList[teamId].list = courses;
        },
        getCoursesByTeamError: (
            state,
            action: PayloadAction<{
                teamId: number;
            }>
        ) => {
            const { teamId } = action.payload;

            setInitialCoursesListState(state, teamId);

            state.coursesList[teamId].loadingStatus = LOADING_STATUSES.ERROR;
        },
        setCourse: (
            state,
            action: PayloadAction<{
                teamId: number;
                course: Course;
            }>
        ) => {
            const { teamId, course } = action.payload;
            const index = state.coursesList[teamId].list.findIndex((c) => c.id === course.id);
            state.coursesList[teamId].list[index] = course;
        },
        // используется для очистки данных по урокам при:
        // - копировании урока (обрабатывается в src/client/bundles/lab/components/course-view/reducer.js)
        clearCourseLessons: (
            state,
            action: PayloadAction<{
                courseId: number;
            }>
        ) => {
            // saga
        },
        getLessonsCountRequest: (
            state,
            action: PayloadAction<{
                courseId: number;
            }>
        ) => {
            const { courseId } = action.payload;

            if (!state.lessonsCounts[courseId]) {
                // isLoaded ставим один раз только при первоначальной загрузке,
                // чтобы не было мигания списка уроков в случае emptyState
                state.lessonsCounts[courseId] = {
                    count: { ...lessonsCountsInitialState },
                    loadingStatus: LOADING_STATUSES.LOADING,
                };
            }
        },
        getLessonsCountSuccess: (
            state,
            action: PayloadAction<{
                courseId: number;
                counts: LessonsCount;
            }>
        ) => {
            const { courseId, counts } = action.payload;

            if (!state.lessonsCounts[courseId]) {
                state.lessonsCounts[courseId] = {
                    count: { ...lessonsCountsInitialState },
                    loadingStatus: LOADING_STATUSES.UNSENT,
                };
            }

            state.lessonsCounts[courseId] = { count: { ...counts }, loadingStatus: LOADING_STATUSES.SUCCESS };
        },
        clearCourseLessonCount: (
            state,
            action: PayloadAction<{
                courseId: number;
            }>
        ) => {
            const { courseId } = action.payload;

            state.lessonsCounts[courseId] = undefined;
        },

        /** Получить слаги предметов открытых учительских курсов */
        getCoursesSubjectsRequest: (state) => {
            state.coursesSubjectsSlugs.loadingStatus = LOADING_STATUSES.LOADING;
            // saga
        },
        getCoursesSubjectsSuccess: (state, action: PayloadAction<{ subjects: SUBJECTS_SLUGS_TYPE[] }>) => {
            state.coursesSubjectsSlugs = {
                loadingStatus: LOADING_STATUSES.SUCCESS,
                list: action.payload.subjects,
            };
        },

        /** Получить предметы курсов для конкретного класса */
        getCoursesSubjectsByTeamRequest: (state, action: PayloadAction<{ teamId: number }>) => {
            const { teamId } = action.payload;

            setInitialCoursesSubjectsListState(state, teamId);

            state.coursesSubjectsList[teamId].loadingStatus = LOADING_STATUSES.LOADING;
        },
        getCoursesSubjectsByTeamSuccess: (
            state,
            action: PayloadAction<{ teamId: number; coursesSubjects: Array<FullCourseSubject> }>
        ) => {
            const { teamId, coursesSubjects } = action.payload;

            setInitialCoursesSubjectsListState(state, teamId);

            state.coursesSubjectsList[teamId].loadingStatus = LOADING_STATUSES.SUCCESS;
            state.coursesSubjectsList[teamId].list = coursesSubjects;
        },
        getCoursesSubjectsByTeamError: (state, action: PayloadAction<{ teamId: number }>) => {
            const { teamId } = action.payload;

            setInitialCoursesSubjectsListState(state, teamId);

            state.coursesSubjectsList[teamId].loadingStatus = LOADING_STATUSES.ERROR;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(lessonActions.addedLessonToCourse, (state, action) => {
            const { containerSlug, courseId, teamId } = action.payload;
            if (!containerSlug || !teamId) {
                return;
            }
            const course = state.coursesList[teamId]?.list.find((c) => c.id === courseId);
            if (course) {
                course.last_container = containerSlug;
            }
        });
    },
});

const setInitialCoursesListState = (state: CoursesStoreState, classId: number | null) => {
    if (classId && !state.coursesList[classId]) {
        state.coursesList[classId] = {
            loadingStatus: LOADING_STATUSES.UNSENT,
            list: [],
        };
    }
};

const setInitialCoursesSubjectsListState = (state: CoursesStoreState, classId: number | null) => {
    if (classId && !state.coursesSubjectsList[classId]) {
        state.coursesSubjectsList[classId] = {
            loadingStatus: LOADING_STATUSES.UNSENT,
            list: [],
        };
    }
};

export const actions = slice.actions;
export const reducer = slice.reducer;
