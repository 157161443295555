import cx from 'classnames';
import { Editor as CodeMirrorEditor } from 'codemirror';
import { RefreshKids } from 'education-icons';
import React, { useRef, useState } from 'react';

import { Button } from '../../button/button';
import { RobotCodeLoadable } from '../../robot/__code/robot__code-loadable';
import { useRefreshOnOrientationChange } from '../useRefreshOnOrientationChange';

// @ts-ignore
import styles from './code-runner__editor.module.scss';

interface Props {
    value?: string;
    onChange?: (value: string) => void;
}

export function Editor(props: Props) {
    const [dirty, setDirty] = useState(false);
    const editorRef = useRef<CodeMirrorEditor>();
    const reset = () => editorRef.current?.setValue(props.value || '');
    const { key, initialValue } = useRefreshOnOrientationChange({
        initialValue: props.value,
        getCurrentValue: () => editorRef.current?.getValue(),
    });
    return (
        <div className={styles.editor}>
            <RobotCodeLoadable
                className={cx(styles.editor__code, { [styles.editor__code_dirty]: dirty })}
                editorRef={editorRef}
                initialValue={initialValue}
                key={key}
                onChange={(_editor, _data, value) => {
                    props.onChange?.(value);
                    setDirty(value !== String(props.value));
                }}
                options={{
                    mode: 'python',
                    cursorScrollMargin: 0,
                }}
                paddingBottom={0}
                paddingRight={0}
                robotHints={false}
            />
            <Button
                className={styles.editor__reset}
                disabled={!dirty}
                icon={<RefreshKids />}
                onClick={reset}
                rounded
                size={Button.SIZE.S}
                view={Button.VIEW.ACTION}
            />
        </div>
    );
}
