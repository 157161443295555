// @ts-ignore
import { CLESSON_MODES } from '../../../../constants';
import { ISODateTime } from '../../../common';

// TODO: перенести из pl-c и грохнуть использования
export enum LESSON_STATUS {
    NOT_ASSIGNED = 'not_assigned',
    ASSIGNED = 'assigned',
    ASSIGNED_IN_FUTURE = 'assigned_in_future',
    IN_PROGRESS = 'in_progress',
    FINISHED = 'finished',
    // только для занятий с дедлайном
    // если урок завершился по дедлайну, а ученики ни разу не приступали
    NOT_SOLVED = 'not_solved',
    // завершено учителем
    FINISH_FORCED = 'finish_forced',
    // только для контрольной работы
    // ожидаем оглашения результатов сданного учеников занятия
    WAIT_FOR_RESULTS = 'wait_for_results',
    CANCELLED = 'cancelled',
}

export enum WORK_TYPE {
    WORK_IN_CLASS = 0,
    WORK_AT_HOME = 1,
}

export enum LESSON_STYLE {
    DEFAULT = 'default',
    DARK = 'dark'
}

export enum LESSON_STATS_MODE {
    DEFAULT = 'default',
    POINTS = 'points'
}

export enum LESSON_NAVIGATION_TYPE {
    SCENARIO = 'scenario'
}

export enum LESSON_ADAPTIVE_ORIENTATION {
    HORIZONTAL = 'HORIZONTAL',  //Горизонтальная ориентация - default
    VERTICAL = 'VERTICAL',      //Любая ориентация
    BLOCKED_ONLY_VERTICAL = 'BLOCKED_ONLY_VERTICAL', //Вертикальная ориентация
}

export enum FEEDBACK_ENVIRONMENT_TYPE {
    GLOBAL = 'global_feedback',
    LOCAL = 'local_feedback',
    NO = 'no_feedback',
}

export interface LessonBanner {
    background_img?: Array<string>;
    background_color?: string;
}

export interface FeedbackConfig {
    student?: { url?: string, disabled?: boolean };
    teacher?: { url?: string, disabled?: boolean };
}

export interface MetaFinisher {
    bg_color_type: string;
    bg_color_value: string;
    resource_url: string;
    button: string;
    desc: string;
    image: string;    // obsolete
    format: string;
    textColor: string;
    title: string;
    override_by_score?: Record<number, MetaFinisher>;
    navigation_block?: string;

    illustration_url?: string;
    background_image_url?: string;
}

export interface MetaFinishers {
    finish_by_student?: MetaFinisher,
    finish_by_teacher?: MetaFinisher,
    finish_by_time?: MetaFinisher,
    finish_not_all_solved?: MetaFinisher,
}

export enum CUSTOM_LESSON_VIEW_TAG_SLUG {
    DESKTOP = 'desktop',
    DOCUMENT = 'document',
    MODE = 'mode',
    PLAN = 'plan',
    TIME = 'time',
    TUBE = 'tube',
    TYPE = 'type',
    VOICE = 'voice',
}

export interface CustomLessonViewTag {
    slug: CUSTOM_LESSON_VIEW_TAG_SLUG;
    text: string;
}

export interface LessonViewSettings {
    image_url: string;
    tags: Array<CustomLessonViewTag>;
    desc: string | null;
    landing_url?: string;
    show_landing?: boolean;
    show_info_icon?: boolean;
    show_action_btn?: boolean;
    show_time_column_stats?: boolean;
    show_seen_count_column_stats?: boolean;
}

export enum STUDENT_RESULT {
    PROBLEMS = 'problems',
    PROBLEMS_WITH_POINTS = 'problems_with_points',
}

export enum LESSON_TYPE {
    USUAL = 'usual',
    OLYMPIAD = 'olympiad',
    SPECIAL_PROJECT = 'special_project',
}

export enum PROGRESS_BAR_STATUS {
    /** Обучение */
    TRAINING = 'training',
    /** Занятие */
    LESSON = 'lesson',
    /** Бонус */
    BONUS = 'bonus',
    /** Контроль */
    CONTROL = 'control'
}

export interface LessonMeta {
    header: Record<string, unknown>;
    slug: string;
    style: LESSON_STYLE;
    mode: CLESSON_MODES;
    lesson_icon: string | null;
    banners_list: LessonBanner | null;
    readonly_comment: string | null;
    rules: string | null;
    adaptive_orientation: LESSON_ADAPTIVE_ORIENTATION;

    is_editable: boolean;
    is_repeatable: boolean;

    accessible_to_teacher: ISODateTime | null; // дата доступности, которую выставил КМ для меты
    date_deadline: ISODateTime | null; // дата завершения, которую выставил КМ для меты
    evaluation_date: ISODateTime | null; // дата объявления результатов КР
    duration: number | null;
    is_duration_editable?: boolean;

    lesson_type: LESSON_TYPE | null;
    certificate_template_id: string | null;

    is_mode_readonly: boolean;
    methodological_goal: string | null;
    time_to_solve: number | null;

    stats_mode: LESSON_STATS_MODE;
    feedback?: FeedbackConfig;
    feedback_environment?: FEEDBACK_ENVIRONMENT_TYPE;
    navigation_type?: LESSON_NAVIGATION_TYPE;

    finishers?: MetaFinishers;
    background_cover?: string;
    background_color?: string;
    instruction_avatar?: string;

    lesson_view_settings?: LessonViewSettings;

    /**
     * можно ли завершать учеником
     */
    can_set_date_finished_by_student: boolean;

    hide_problems_till_evaluation: boolean;
    hide_put_on_board_till_evaluation: boolean;

    are_dates_editable: boolean;
    is_lesson_mode_editable: boolean;
    is_content_editable: boolean;

    is_copiable: boolean;

    allow_forced_completion: boolean;
    allow_individual_assignment: boolean;

    show_hints_in_control_work?: boolean;
    show_student_result: STUDENT_RESULT | null;
    is_public?: boolean;

    hide_progress_bar: boolean | null;
    progress_bar_status: PROGRESS_BAR_STATUS | null;

    stickers_slugs?: string[];
    cert_subject?: string | null;
    cover_on_students_showcase?: null | string;
}
