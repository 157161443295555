// @ts-ignore
import { SUBJECTS_SLUGS } from '@yandex-int/k-common/constants';
import { CourseTemplate } from '@yandex-int/k-common/typings';
import memoize from 'lodash/memoize';
import { WrappedComponentProps, IntlShape } from 'react-intl';

import { SPEC_PROJECT_SLUGS } from 'common.components/spec-project.common/constants';
import { InjectedProps as WithNowProps } from 'common.components/timer/withTime';

export interface PropsWithClassId {
    classId: string | number | null;
}

export interface PropsWithChildId {
    childId: number | null;
}

export interface PropsWithSubject {
    subject: SUBJECTS_SLUGS;
}

export interface PropsWithGrade {
    grade: number | null | undefined;
}

export interface PropsWithCourseId {
    courseId: number | null;
}

interface PropsWithStudentId {
    studentId: number | null;
}

interface PropsWithProblemId {
    problemId: number | null;
}

interface PropsWithTeamId {
    teamId: number;
}

interface PropsWithCoursesTemplates {
    coursesTemplates: Array<CourseTemplate>;
}

interface PropsWithSpecProjectSlug {
    specProjectSlug: SPEC_PROJECT_SLUGS;
}
export interface PropsWithSearch {
    search: string;
}

interface PropsWithThemeId {
    themeId: number | undefined;
}

interface PropsWithClessonId {
    clessonId: number | null;
}

interface PropsWithLessonId {
    lessonId: number | null;
}

export interface PropsWithLessonsId {
    lessonsIds: Array<number>;
}

export interface PropsWithSlug {
    slug: string | null;
}

interface PropsWithIsMobile {
    isMobile: boolean | undefined;
}

export const intlSelector = <T extends WrappedComponentProps>(storeState: any, ownProps: T): IntlShape => ownProps.intl;

export const nowSelector = (_storeState: any, ownProps: WithNowProps): Date | null => ownProps.now;

type ClassIdFromPropsMemoizeFnType = (_storeState: any, ownProps: PropsWithClassId) => number | null;
const classIdFromPropsMemoizeFn: ClassIdFromPropsMemoizeFnType = (_storeState, ownProps) => {
    if (ownProps && ownProps.classId) {
        const classId = Number(ownProps.classId);
        return Number.isNaN(classId) ? null : classId;
    }

    return null;
};
export const getClassIdFromProps: ClassIdFromPropsMemoizeFnType = memoize(
    classIdFromPropsMemoizeFn,
    classIdFromPropsMemoizeFn
);

type ChildIdFromPropsMemoizeFnType = (_storeState: any, ownProps: PropsWithChildId) => number | null;
const childIdFromPropsMemoizeFn: ChildIdFromPropsMemoizeFnType = (_storeState, ownProps) => {
    if (ownProps && ownProps.childId) {
        const childId = Number(ownProps.childId);
        return Number.isNaN(childId) ? null : childId;
    }

    return null;
};
export const getChildIdFromProps: ChildIdFromPropsMemoizeFnType = memoize(
    childIdFromPropsMemoizeFn,
    childIdFromPropsMemoizeFn
);

export const courseIdSelector = (_storeState: any, ownProps: PropsWithCourseId): number | null => {
    if (ownProps && ownProps.courseId) {
        const courseId = Number(ownProps.courseId);
        return Number.isNaN(courseId) ? null : courseId;
    }

    return null;
};

type SubjectFromPropsMemoizeFnType = (_storeState: any, ownProps: PropsWithSubject) => SUBJECTS_SLUGS;
const subjectFromPropsMemoizeFn: SubjectFromPropsMemoizeFnType = (_storeState, ownProps) => ownProps.subject;
export const getSubjectFromProps: SubjectFromPropsMemoizeFnType = memoize(
    subjectFromPropsMemoizeFn,
    subjectFromPropsMemoizeFn
);

type GradeFromPropsMemoizeFnType = (_storeState: any, ownProps: Partial<PropsWithGrade>) => number | null;
const gradeFromPropsMemoizeFn: GradeFromPropsMemoizeFnType = (_storeState, ownProps) => {
    if (ownProps && ownProps.grade) {
        return Number.isNaN(ownProps.grade) ? null : ownProps.grade;
    }

    return null;
};
export const getGradeFromProps: GradeFromPropsMemoizeFnType = memoize(gradeFromPropsMemoizeFn, gradeFromPropsMemoizeFn);

export const getStudentIdFromProps = (_storeState: any, ownProps: PropsWithStudentId): number | null => {
    if (ownProps && ownProps.studentId) {
        const studentId = Number(ownProps.studentId);
        return Number.isNaN(studentId) ? null : studentId;
    }

    return null;
};

export const getProblemIdFromProps = (_storeState: any, ownProps: PropsWithProblemId): number | null => {
    if (ownProps && ownProps.problemId) {
        const problemId = Number(ownProps.problemId);
        return Number.isNaN(problemId) ? null : problemId;
    }

    return null;
};

export const getThemeIdFromProps = (_storeState: any, ownProps: PropsWithThemeId): number | null => {
    if (ownProps && ownProps?.themeId) {
        const themeId = Number(ownProps.themeId);
        return Number.isNaN(themeId) ? null : themeId;
    }

    return null;
};

export const isMobileSelector = (_storeState: any, ownProps: PropsWithIsMobile): boolean => {
    if (ownProps && ownProps.isMobile) {
        return ownProps.isMobile;
    }

    return false;
};

export const getClessonIdFromProps = (_storeState: any, ownProps: PropsWithClessonId): number | null => {
    if (ownProps && ownProps.clessonId) {
        const clessonId = Number(ownProps.clessonId);
        return Number.isNaN(clessonId) ? null : clessonId;
    }

    return null;
};

export const getLessonIdFromProps = (_storeState: any, ownProps: PropsWithLessonId): number | null => {
    if (ownProps && ownProps.lessonId) {
        const lessonId = Number(ownProps.lessonId);
        return Number.isNaN(lessonId) ? null : lessonId;
    }

    return null;
};

export const getSpecCourseLessonIdFromProps = (_storeState: any, ownProps: { clessonId: string }): string =>
    ownProps.clessonId;

export const getTeamIdFromProps = (_storeState: any, ownProps: PropsWithTeamId): number => Number(ownProps.teamId);

export const getCoursesTemplatesFromProps = (_storeState: any, ownProps: PropsWithCoursesTemplates) =>
    ownProps.coursesTemplates;

export const getSpecProjectSlugFromProps = (_storeState: any, ownProps: PropsWithSpecProjectSlug) =>
    ownProps.specProjectSlug;

export const getCourseIdFromProps = (_storeState: any, ownProps: PropsWithCourseId): number | null => {
    if (ownProps && ownProps.courseId) {
        const courseId = Number(ownProps.courseId);
        return Number.isNaN(courseId) ? null : courseId;
    }

    return null;
};

export const getLessonsIdsFromProps = (_storeState: any, ownProps: PropsWithLessonsId): Array<number> => {
    if (ownProps && ownProps.lessonsIds) {
        return ownProps.lessonsIds;
    }

    return [];
};

export const getSearchFromProps = (_storeState: any, ownProps: PropsWithSearch) => ownProps?.search || '';

export const getSlugFromProps = (_storeState: any, ownProps: PropsWithSlug) => {
    if (ownProps && ownProps.slug) {
        return ownProps.slug;
    }

    return null;
};
