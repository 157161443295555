import b from 'b_';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { stringify } from 'querystring';
import React from 'react';

import './yandex-player.scss';

/**
 * Справка по пропсам тут ("API тега iframe")
 * http://video-player-iframe-api-develop-hurge.qloud.yandex-team.ru/docs/index.html
 */
const YandexPlayer = ({ mix, ...yaProps }) => {
    const wrong = !validateFrameProps(yaProps);
    const className = cx(b('yandex-player', { wrong }), mix);
    const stringArgs = stringify(yaProps);

    const src = `https://yastatic.net/yandex-video-player-iframe-api/?${stringArgs}`;

    return wrong ? (
        <div className={className}>Wrong player params</div>
    ) : (
        <iframe allowFullScreen className={className} src={src} />
    );
};

YandexPlayer.propTypes = {
    mix: PropTypes.string,
    // yaProps
    /* eslint-disable camelcase */
    lq_url: PropTypes.string,
    mq_url: PropTypes.string,
    hq_url: PropTypes.string,
    hd_url: PropTypes.string,
    debug: PropTypes.bool,
    hidden: PropTypes.string,
    autoplay: PropTypes.bool,
    preview: PropTypes.string,
    start_postition: PropTypes.number,
    pratner_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    with_credentials: PropTypes.bool,
    /* eslint-enable camelcase */
};

function validateFrameProps(props) {
    return props.lq_url || props.mq_url || props.hq_url || props.hd_url; // eslint-disable-line camelcase
}

export default YandexPlayer;
