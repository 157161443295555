import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';

import type { State } from './external-lms';
import { ExternalLmsStore } from './typings';

const initialState: ExternalLmsStore = {
    createMesClassLoadingStatus: LOADING_STATUSES.UNSENT,
    bindingTeacherToMesAccountStatus: LOADING_STATUSES.UNSENT,
};

// Параметры нужны для саги
/* eslint-disable @typescript-eslint/no-unused-vars */
const slice = createSlice({
    name: 'EXTERNAL_LMS',
    initialState: initialState,
    reducers: {
        createMesClass: (
            state,
            action: PayloadAction<{
                externalClassId: number;
                subjectId: number;
                classLevelId: number;
                onSuccess: (classId: number) => void;
                onError: (errorId?: State['errorId'], errorType?: State['errorType']) => void;
            }>
        ) => {
            state.createMesClassLoadingStatus = LOADING_STATUSES.LOADING;
        },
        setCreateMesClassLoadingStatus: (state, action: PayloadAction<LOADING_STATUSES>) => {
            state.createMesClassLoadingStatus = action.payload;
        },
    },
});

export const actions = slice.actions;
export const reducer = slice.reducer;
