import type { AssignmentTable } from '@yandex-int/k-common';
import * as api from '@yandex-int/k-common/client/api';
import { showNetworkError } from 'platform-components';
import { call, put } from 'redux-saga/effects';

import { actions as lessonActions } from '../index';

import { loadTeam } from './utils';

export function* getLessonAssignsSaga(action: ReturnType<typeof lessonActions.getLessonAssigns>) {
    const { courseId, cLessonId } = action.payload;

    yield call(getLessonAssigns, { courseId, cLessonId });
}

export function* getLessonAssigns(params: { courseId: number; cLessonId: number }) {
    const { cLessonId, courseId } = params;
    try {
        yield put(lessonActions.getLessonAssignsRequest());

        yield loadTeam();

        const table: AssignmentTable = yield call(api.getLessonAssigns, { clessonId: cLessonId, courseId });
        yield put(lessonActions.getLessonAssignsSuccess({ assignmentTable: table }));
    } catch (error) {
        console.error(error);
        yield put(showNetworkError(error));
        yield put(lessonActions.getLessonAssignsError());
    }
}
