import b from 'b_';
import React from 'react';

import Link from '../link/link';

import { withSpoilerAnalytics } from './hocs/with-analytics';

import './spoiler.scss';

export interface SpoilerProps {
    caption: string[];
    closeCaption: string[];
    children: React.ReactNode;
    initialSpoiled?: boolean;
    onToggle: (open: boolean) => void;
}

interface SpoilerState {
    spoiled: boolean;
}

class Spoiler extends React.Component<SpoilerProps, SpoilerState> {
    static displayName = 'Spoiler';

    constructor(props: SpoilerProps) {
        super(props);

        const { initialSpoiled: spoiled } = this.props;

        this.state = {
            spoiled: Boolean(spoiled),
        };
    }

    onToggle = () => {
        this.props.onToggle(this.state.spoiled);
    };

    toggleHandler = () => {
        this.setState(
            ({ spoiled }) => ({ spoiled: !spoiled }),
            () => this.onToggle()
        );
    };

    render() {
        const { spoiled } = this.state;
        const { caption, children, closeCaption } = this.props;

        return (
            <div className="spoiler">
                <Link className={b('spoiler', 'caption', { open: spoiled })} onClick={this.toggleHandler} pseudo>
                    {spoiled ? closeCaption : caption}
                </Link>
                <div className={b('spoiler', 'content', { closed: !spoiled })}>{children}</div>
            </div>
        );
    }
}

export default withSpoilerAnalytics<SpoilerProps>(Spoiler);
