import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import { Me } from '@yandex-int/k-common/typings';
import produce from 'immer';

import * as studentsConstants from 'common.components/students/constants';

import { GRANT_USER_RIGHTS_ERROR, GRANT_USER_RIGHTS_REQUEST, GRANT_USER_RIGHTS_SUCCESS } from './constants';
import * as constants from './constants';
import { UserStoreState } from './typings';

const initialState: UserStoreState = {
    addViewedHintLoadingStatus: LOADING_STATUSES.UNSENT,
    archivedClasses: [],
    me: null,
    isMeSet: false,
    teacherProfile: null,
    salesCodeLoadingStatus: LOADING_STATUSES.UNSENT,
    classes: [],
    teamSyncLoadingStatuses: {},
    grantRightsLoadingStatus: LOADING_STATUSES.UNSENT,
    senderProfile: null,
    parentChildren: null,
    parentProfile: null,
    studentProfile: null,
};
const user = (state: UserStoreState = initialState, action: any) => {
    // eslint-disable-next-line complexity
    return produce(state, (draft) => {
        switch (action.type) {
            case constants.SET_ME:
                draft.me = action.me;
                break;

            case constants.SET_TEAMS:
                draft.classes = action.teams;
                break;

            case constants.SET_TEACHER_PROFILE:
                draft.teacherProfile = action.teacherProfile;
                break;

            case constants.SET_SENDER_PROFILE:
                draft.senderProfile = action.senderProfile;
                break;

            case constants.SET_PARENT_PROFILE:
                draft.parentProfile = action.parentProfile;
                break;

            case constants.SET_PARENT_CHILDREN:
                draft.parentChildren = action.children;
                break;

            case constants.SET_HINTS_VIEWED_MAP: {
                if (!draft.me) {
                    draft.me = {} as Me;
                }

                draft.me.viewed_hints = action.hints;
                break;
            }

            case constants.ADD_VIEWED_HINT_REQUEST: {
                draft.addViewedHintLoadingStatus = LOADING_STATUSES.LOADING;
                break;
            }

            case constants.ADD_VIEWED_HINT_SUCCESS: {
                if (!draft.me) {
                    draft.me = {} as Me;
                }

                draft.me.viewed_hints = action.hints;
                draft.addViewedHintLoadingStatus = LOADING_STATUSES.SUCCESS;
                break;
            }

            case constants.ADD_VIEWED_HINT_ERROR: {
                draft.addViewedHintLoadingStatus = LOADING_STATUSES.ERROR;
                break;
            }

            case constants.DELETE_CLASS_SUCCESS: {
                const { classId } = action;

                const teams = state.classes || [];
                const archivedClasses = state.archivedClasses;

                draft.classes = teams.filter((team) => team.id !== classId);
                draft.archivedClasses = archivedClasses.filter((classItem) => classItem.id !== classId);
                break;
            }

            case studentsConstants.EDIT_STUDENT_NAME_SUCCESS: {
                const { id, firstName, lastName } = action;

                if (!draft.me) {
                    draft.me = {} as Me;
                }

                const children = state.parentChildren;

                if (children) {
                    const student = children.find((child) => child.id === id);
                    if (student) {
                        student.first_name = firstName;
                        student.last_name = lastName;
                    }
                }

                break;
            }

            case constants.GET_ARCHIVED_CLASSES_SUCCESS:
                const { archivedClasses } = action;

                draft.archivedClasses = archivedClasses;
                break;

            case constants.SYNC_TEAM_REQUEST: {
                const { teamId } = action;

                draft.teamSyncLoadingStatuses[teamId] = LOADING_STATUSES.LOADING;
                break;
            }

            case constants.SYNC_TEAM_SUCCESS: {
                const { teamId } = action;

                delete draft.teamSyncLoadingStatuses[teamId];
                break;
            }

            case constants.SYNC_TEAM_ERROR: {
                const { teamId } = action;

                draft.teamSyncLoadingStatuses[teamId] = LOADING_STATUSES.ERROR;
                break;
            }

            case constants.TEAM_SYNC_STATUSES_SUCCESS: {
                const { teamIdToStatus } = action;

                Object.keys(teamIdToStatus).forEach((teamIdStr) => {
                    const teamId = Number(teamIdStr);

                    const syncStatus = teamIdToStatus[teamId];

                    const properTeam = draft.classes?.find((team) => team.id === teamId);

                    if (properTeam) {
                        properTeam.sync_status = syncStatus;
                    }

                    delete draft.teamSyncLoadingStatuses[teamId];
                });
                break;
            }

            case GRANT_USER_RIGHTS_REQUEST:
                draft.grantRightsLoadingStatus = LOADING_STATUSES.LOADING;
                break;

            case GRANT_USER_RIGHTS_SUCCESS:
                draft.grantRightsLoadingStatus = LOADING_STATUSES.SUCCESS;
                break;

            case GRANT_USER_RIGHTS_ERROR:
                draft.grantRightsLoadingStatus = LOADING_STATUSES.ERROR;
                break;

            case constants.GET_LOCALITY_NAME_SUCCESS: {
                draft.locality = action.localityName;
                break;
            }

            default:
                break;
        }
    });
};

export default user;
