// @ts-nocheck
import { Me } from '@yandex-int/k-common/typings';
import get from 'lodash/get';
//@ts-ignore
import { getMe } from 'platform-components/src/components/user/selectors';
import { withRouterPath, InjectedProps as WithRouterPathProps } from 'platform-components/hocs';
import { getFromLocalStorage } from 'platform-components/utils';
import qs from 'query-string';
import React from 'react';
import { Helmet } from 'react-helmet';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import ym from 'react-yandex-metrika';
import { bindActionCreators, Dispatch } from 'redux';

import { SEARCH_PARAM_TEACHER_PROMO } from 'common.components/constants';
import { SALES_CODE_SCENARIO } from 'common.components/sales-code-modal/constants';
import { SalesCodeModal } from 'common.components/sales-code-modal/sales-code-modal';
import { getShouldShowSalesCodeModal } from 'common.components/sales-code-modal/selectors';
import { getSourceProject } from 'utils/get-data-from-search-parameters';

import { ReduxIndexStore, RootAction } from '../../typings';
import RegistrationHeader from '../registration-header/registration-header';

import Footer from './__footer/teacher-registration-page__footer';
import { actions } from './actions';
import { getSalesCode } from './selectors';

import './teacher-registration-page.scss';

const DEFAULT_EMPTY_NAME = 'default-empty';

interface StoreProps {
    me: Me | null;
    shouldShowSalesCodeModal: boolean;
    salesCode?: string;
}

interface DispatchProps {
    setRegistrationData: typeof actions.setRegistrationData;
}

interface Props
    extends StoreProps,
        DispatchProps,
        WithRouterPathProps,
        WrappedComponentProps,
        React.PropsWithChildren {}

interface Context {
    basePath: string;
    prefix: string;
    staticUrls: {
        teacherRegistrationSchool: string;
        teacherRegistrationStudents: string;
        teacherRegistrationSubjects: string;
        teacherRegistration: string;
    };
}

export class TeacherRegistrationPage extends React.Component<Props> {
    // eslint-disable-next-line complexity
    constructor(props: Props, context: Context) {
        super(props, context);

        const {
            me,
            setRegistrationData,
            location: { search },
            history,
        } = this.props;
        const { id: userId } = me || {};

        try {
            const registrationData = getFromLocalStorage('registrationData', null);
            let userRegistrationData = registrationData && userId ? get(JSON.parse(registrationData), userId) : null;
            const parsedSearch = qs.parse(search);
            const teacherCode = parsedSearch[SEARCH_PARAM_TEACHER_PROMO];
            const { from_passport: fromPassport, ...restParams } = parsedSearch;

            if (fromPassport === 'true') {
                setTimeout(() => {
                    ym('reachGoal', 'teacher_onboarding_return_from_passport_to_reg', {
                        id: userId,
                        from_project: getSourceProject(),
                    });
                }, 0);

                history.replace({
                    search: qs.stringify(restParams, { arrayFormat: 'bracket' }),
                });
            }

            if (teacherCode) {
                userRegistrationData = userRegistrationData || { students: [] };
                userRegistrationData.salesCode = teacherCode.toUpperCase();
            }

            if (userRegistrationData) {
                userRegistrationData.students = userRegistrationData.students
                    .map((student: { name: string }) => {
                        if (student.name === DEFAULT_EMPTY_NAME) {
                            return '';
                        }

                        if (student.name) {
                            return student.name;
                        }

                        return student;
                    })
                    .filter((student: string) => student !== '');
                setRegistrationData(userRegistrationData);
            }
        } catch (e) {
            console.error(e);
        }
    }

    handleSaveSalesCode = (salesCode: string | null) => {
        const { setRegistrationData } = this.props;
        setRegistrationData({ salesCode: salesCode ? salesCode : undefined });
    };

    render() {
        const {
            children,
            shouldShowSalesCodeModal,
            salesCode,
            location: { search },
            intl,
        } = this.props;
        const teacherCode = qs.parse(search)[SEARCH_PARAM_TEACHER_PROMO];

        return (
            <div className="teacher-registration">
                <Helmet title={intl.formatMessage({ id: 'teacherRegistrationPage.title' })} />
                <RegistrationHeader mix="teacher-registration__header" />
                <div className="teacher-registration__content">{children}</div>
                <Footer />
                <SalesCodeModal
                    disabled={Boolean(teacherCode)}
                    onSave={this.handleSaveSalesCode}
                    opened={shouldShowSalesCodeModal}
                    savedSalesCode={salesCode || null}
                    scenario={SALES_CODE_SCENARIO.FROM_REGISTRATION}
                />
            </div>
        );
    }
}

const mapStateToProps = (storeState: ReduxIndexStore): StoreProps => ({
    me: getMe(storeState),
    shouldShowSalesCodeModal: getShouldShowSalesCodeModal(storeState),
    salesCode: getSalesCode(storeState),
});

const mapDispatchToProps = (dispatch: Dispatch<RootAction>): DispatchProps => {
    return bindActionCreators(
        {
            setRegistrationData: actions.setRegistrationData,
        },
        dispatch
    );
};

export default injectIntl(withRouterPath(connect(mapStateToProps, mapDispatchToProps)(TeacherRegistrationPage)));
