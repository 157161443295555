import { createSelector } from 'reselect';

import { AddressWithId } from 'common.components/school-form/typings';
import { intlSelector } from 'utils/selectors/selectors';

export const getMoscowAddress = createSelector(intlSelector, (intl): AddressWithId => {
    const country = intl.formatMessage({ id: 'mesRegion.country' });
    const city = intl.formatMessage({ id: 'mesRegion.city' });
    const id = intl.formatMessage({ id: 'mesRegion.id' });
    const geoId = intl.formatMessage({ id: 'mesRegion.geoid' });

    return {
        subtitle: country,
        geoid: Number(geoId),
        title: city,
        id: id,
    };
});
