import pick from 'lodash/pick';
import React, { useMemo } from 'react';
import { defaultRules, ParserRules } from 'simple-markdown';

import { MdState } from '../../marker/types';
import Markdown from '../markdownBase';
// @ts-ignore
import customRules from '../custom-rules';

export const rulesForContent = [
    'author',
    'brSign',
    'dialogMessage',
    'formattedNumber',
    'formula',
    'geoMap',
    'horizontalAlign',
    'image',
    'imageFloat',
    'latex',
    'link',
    'resource',
    'small',
    'sizedText',
    'coloredText',
    'customText',
    'yaForm',
    'medium',

    'contentVisibility',
    'spoiler',
    'sub',
    'sup',
    'tab',
    'table',
    'nptable',
    'verticalAlign',
    'yandexMusic',
    'yandexPlayer',
    'yandexVh',
    'youtube',
    'codeRunner',
    'scratchEmbed',
];

const commonRules = Object.assign({}, defaultRules, pick(customRules, rulesForContent));

interface Props {
    display?: 'block';
    mdState?: MdState;
    mix?: string;
    children?: React.ReactNode; // todo здесь надо будет сделать честный children: string;
    rules?: ParserRules;
    // превью изображений должно работать только в блоках с типом text, чтобы не ломать механики, в которых тоже есть картинки.
    // но оба типа используют один и тот же markdown, поэтому передаем дополнительный флаг только из текстовых блоков
    showImagePreview?: boolean;
}

const MarkdownExtXContent = (props: Props) => {
    const rules = useMemo(() => ({ ...commonRules, ...props.rules }), [props.rules]);

    return <Markdown {...props} children={props.children as string} rules={rules} />;
};

export default MarkdownExtXContent;
