import { isEqual } from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import 'string.prototype.matchall/auto';

type PortalsRendererProps = {
    code: string;
    portalRootRegExStringBase: string;
    renderPortalContentByMatch: (matchId: string) => React.ReactNode; // расскажи мне как рендерить
    createPortalRootQuerySelector: (matchId: string) => string; // расскажи мне как найти этот элемент на странице
    getMatchIdFromRegExpMatchArray: (match: RegExpMatchArray) => string; //  расскажи мне как создать id
};

type ElementById = { [key: string]: Element };

export class PortalsRenderer extends React.Component<React.PropsWithChildren<PortalsRendererProps>> {
    private static createRegEx = (regExStringBase: string) => new RegExp(regExStringBase, 'g');

    constructor(props: PortalsRendererProps) {
        super(props);

        this.portalRootRegEx = PortalsRenderer.createRegEx(props.portalRootRegExStringBase);
    }

    componentDidMount() {
        // посчитали элементы после того как примонтировали компонент
        this.portalRootNodesById = this.parsePortalRootNodesById();
        // делаем forceUpdate чтобы добавить новые элементы на страницу
        this.forceUpdate();
    }

    componentDidUpdate(prevProps: PortalsRendererProps) {
        const { portalRootRegExStringBase } = this.props;

        if (portalRootRegExStringBase !== prevProps.portalRootRegExStringBase) {
            this.portalRootRegEx = PortalsRenderer.createRegEx(portalRootRegExStringBase);
        }

        // посчитали список новых элементов
        const rootNodesById = this.parsePortalRootNodesById();

        // если есть какой-то новый узел пересчитать и перерисовать
        if (!isEqual(rootNodesById, this.portalRootNodesById)) {
            this.portalRootNodesById = rootNodesById;
            this.forceUpdate();
        }
    }

    private portalRootNodesById: ElementById = {};

    private portalRootRegEx: RegExp;

    private parsePortalRootNodesById = () => {
        const { code, createPortalRootQuerySelector, getMatchIdFromRegExpMatchArray } = this.props;
        const matches = code.matchAll(this.portalRootRegEx);

        return Array.from(matches).reduce<ElementById>((acc, match) => {
            const matchId = getMatchIdFromRegExpMatchArray(match);
            const selector = createPortalRootQuerySelector(matchId);
            const domNode = document.querySelector(selector);

            if (domNode) {
                acc[matchId] = domNode;
            }

            return acc;
        }, {});
    };

    private renderPortal = ([matchId, node]: [string, Element]) => {
        return ReactDOM.createPortal(this.props.renderPortalContentByMatch(matchId), node);
    };

    render() {
        const portals = Object.entries(this.portalRootNodesById);

        return (
            <React.Fragment>
                {this.props.children}
                {portals.map(this.renderPortal)}
            </React.Fragment>
        );
    }
}
