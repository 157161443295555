/*
 * Большая часть этого кода - копипаста внутренностей SimpleMarkdown
 * Нужно было изменить логику рендера, а сделать это без выноса и изменения кода было невозможно
 */

const TABLE_HEADER_TRIM = /^ *| *\| *$/g;
const TABLE_CELLS_TRIM = /\n+$/;
const PLAIN_TABLE_ROW_TRIM = /^ *\| *| *\| *$/g;
const TABLE_ROW_SPLIT = / *\| */;

const NPTABLE_ROW_TRIM = /^ *| *$/g;

const TABLE_RIGHT_ALIGN = /^ *-+: *$/;
const TABLE_CENTER_ALIGN = /^ *:-+: *$/;
const TABLE_LEFT_ALIGN = /^ *:-+ *$/;

const TABLE_ATTRS_SPLIT_REGEX = /\s*[,\n]\s*/;
const ATTR_VALUE_SEPARATOR_REGEX = /\s*:\s*/;

const CELL_ATTRIBUTE_REGEX = /<[\w\s:%]+>/g;
const CELL_ATTRIBUTE_TRIM_REGEX = /[<>]/g;

const CAPTURE_STRUCT = {
    ATTRS: 0,
    HEADER: 1,
    TABLE_ALIGN: 2,
    TABLE_CELLS: 3,
};

const parseTableAlignCapture = (alignCapture) => {
    if (TABLE_RIGHT_ALIGN.test(alignCapture)) {
        return 'right';
    } else if (TABLE_CENTER_ALIGN.test(alignCapture)) {
        return 'center';
    } else if (TABLE_LEFT_ALIGN.test(alignCapture)) {
        return 'left';
    }

    return null;
};

/**
 * parseTableAttrs
 *
 * This function converts a raw attributes string to Object using special separators.
 * comma(,) uses for splitting attributes to each other.
 * colon(:) uses for splitting name and value of attribute.
 *
 * @param capture
 * @returns Object
 */
const parseTableAttrs = (capture) => {
    const attrs = capture[CAPTURE_STRUCT.ATTRS];
    const attrsArray = attrs.split(TABLE_ATTRS_SPLIT_REGEX).filter(Boolean); // отфильтровывает все falsy с приведением к типу

    return getAttributesObject(attrsArray);
};

const parseCellAttributes = (text) => {
    const found = text.match(CELL_ATTRIBUTE_REGEX);

    if (found === null) {
        return {};
    }

    const attrArray = found.map((attrRaw) => attrRaw.replace(CELL_ATTRIBUTE_TRIM_REGEX, ''));

    return getAttributesObject(attrArray);
};

const clearCellAttributes = (text) => text.replace(CELL_ATTRIBUTE_REGEX, '');

const parseTableHeader = (trimRegex, capture, parse, state) => {
    const headerText = capture[CAPTURE_STRUCT.HEADER].replace(trimRegex, '').split(TABLE_ROW_SPLIT);

    return headerText.map((text) => parseCell(text, parse, state));
};

const parseTableAlign = (trimRegex, capture) => {
    const alignText = capture[CAPTURE_STRUCT.TABLE_ALIGN].replace(trimRegex, '').split(TABLE_ROW_SPLIT);

    return alignText.map(parseTableAlignCapture);
};

const parseCell = (text, parse, state) => {
    const attrs = parseCellAttributes(text);
    const clearText = clearCellAttributes(text);

    return {
        attrs,
        content: parse(clearText, state),
    };
};

const parseTableCells = (capture, parse, state) => {
    const rowsText = capture[CAPTURE_STRUCT.TABLE_CELLS].replace(TABLE_CELLS_TRIM, '').split('\n');

    return rowsText.map(function (rowText) {
        const cellText = rowText.replace(PLAIN_TABLE_ROW_TRIM, '').split(TABLE_ROW_SPLIT);

        return cellText.map((text) => parseCell(text, parse, state));
    });
};

const parseNpTableCells = (capture, parse, state) => {
    const rowsText = capture[3].replace(TABLE_CELLS_TRIM, '').split('\n');

    return rowsText.map((rowText) => {
        const cellText = rowText.split(TABLE_ROW_SPLIT);
        return cellText.map((text) => parseCell(text, parse, state));
    });
};

export const parseTable = (capture, parse, state) => {
    state.inline = true;
    const attrs = parseTableAttrs(capture);
    const header = parseTableHeader(TABLE_HEADER_TRIM, capture, parse, state);
    const align = parseTableAlign(TABLE_HEADER_TRIM, capture, parse, state);
    const cells = parseTableCells(capture, parse, state);
    state.inline = false;

    const type = 'table';

    return { align, attrs, cells, header, type };
};

export const parseNpTable = (capture, parse, state) => {
    state.inline = true;
    const attrs = parseTableAttrs(capture);
    const header = parseTableHeader(NPTABLE_ROW_TRIM, capture, parse, state);
    const align = parseTableAlign(NPTABLE_ROW_TRIM, capture, parse, state);
    const cells = parseNpTableCells(capture, parse, state);
    state.inline = false;

    const type = 'table';

    return { align, attrs, cells, header, type };
};

export const getTableColSize = (parsedTable) => parsedTable.header.length;

export const getTableOccupiedColSpace = (parsedTable) =>
    parsedTable.header
        .map(({ width }) => width)
        .filter(Boolean)
        .reduce((acc, width) => acc + Number(width), 0);

export const getTableOccupiedCols = (parsedTable) =>
    parsedTable.header.map(({ width }) => width).filter(Boolean).length;

const getAttributesObject = (attrsArray) =>
    attrsArray.reduce((acc, attrRaw) => {
        const [attrName, attrValue = true] = attrRaw.split(ATTR_VALUE_SEPARATOR_REGEX);

        return { ...acc, [attrName]: attrValue };
    }, {});

export default parseTable;
