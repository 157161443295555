import { ROLES } from '@yandex-int/k-common/constants';
import cx from 'classnames';
import { Link, Text } from 'platform-components';
import { getCurrentRole } from 'platform-components/src/components/user/selectors';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import ym from 'react-yandex-metrika';
import { compose } from 'redux';

class FooterHelp extends React.Component {
    static propTypes = {
        intl: PropTypes.object.isRequired,
        role: PropTypes.oneOf(Object.values(ROLES)),
    };

    static contextTypes = {
        service: PropTypes.string.isRequired,
    };

    handleFooterClick = () => {
        const { role } = this.props;

        if (role === ROLES.TEACHER) {
            ym('reachGoal', 'teacher_help_help');
        }
    };

    render() {
        const { intl, role } = this.props;
        const { service } = this.context;

        const helpLink = intl.formatMessage({ id: `footer.helpLink.${service}.${role}` });
        const helpTitle =
            role === ROLES.PARENT || role === ROLES.TEACHER ? (
                <Text id={`footer.${role}.help`} />
            ) : (
                <Text id="footer.help" />
            );

        return (
            <Link
                mix={cx('footer__link', 'footer__help-link')}
                onClick={this.handleFooterClick}
                target="_blank"
                theme={Link.THEME.STEALTHY}
                to={helpLink}
            >
                <Text>{helpTitle}</Text>
            </Link>
        );
    }
}

const mapStateToProps = (storeState) => ({
    role: getCurrentRole(storeState),
});

export default compose(connect(mapStateToProps), injectIntl)(FooterHelp);
