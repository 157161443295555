import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import { ProblemAnswer } from '@yandex-int/k-common/typings';
import isEmpty from 'lodash/isEmpty';
import { createSelector } from 'reselect';

import { MarkerAnswer, ProblemAnswerItem, ProblemsViewStore } from './typings';

const getProblemsViewState = (storeState: any): ProblemsViewStore => storeState.problemsView;

export const getSelectedProblemId = createSelector([getProblemsViewState], (problemsViewState) => {
    return problemsViewState.selectedProblemId;
});

const getCurrentProblemProgressState = createSelector(
    [getProblemsViewState, getSelectedProblemId],
    (problemsViewState: ProblemsViewStore, problemId: number | null): ProblemAnswerItem | null => {
        if (!problemId) {
            return null;
        }

        return problemsViewState.problems[problemId] || null;
    }
);

export const getShouldShowCorrectAnswers = createSelector([getProblemsViewState], (problemsViewState) => {
    return problemsViewState.shouldShowCorrectAnswers;
});

export const getAnswerLoadingStatus = createSelector(
    [getCurrentProblemProgressState],
    (problemAnswer: ProblemAnswerItem | null): LOADING_STATUSES =>
        problemAnswer?.loadingStatus || LOADING_STATUSES.UNSENT
);

export const getUserAnswer = createSelector(
    [getCurrentProblemProgressState],
    (problemAnswer: ProblemAnswerItem | null): Record<string, MarkerAnswer> | null => problemAnswer?.answers || null
);

export const getProblemAttempt = createSelector(
    [getCurrentProblemProgressState],
    (problemAnswer: ProblemAnswerItem | null): ProblemAnswer | null => problemAnswer?.attempt || null
);

export const getIsAnswered = createSelector(
    [getProblemAttempt],
    (attempt: ProblemAnswer | null): boolean => !isEmpty(attempt)
);
