// import { withBaobab, getBaobabState, BaobabButton } from '@edu-frontend/baobab';
import { configureRootTheme } from '@yandex-int/edu-components/Theme';
import { theme } from '@yandex-int/edu-components/Theme/presets/platform-default';
import PropTypes from 'prop-types';
// import qs from 'query-string';
import React, { Component } from 'react';
import { IntlProvider } from 'react-intl';
import { Provider as StoreProvider } from 'react-redux';

import { reportMessage } from '../../utils/raven-message';
import createStore from '../../utils/store/create-store';
import { pixelsReducer } from '../pixels/actions';
import routerReducer from '../router/reducers';
import { UseReloadOnLogin } from '../../utils/hooks/useReloadOnUserChange';

import { AppContextProvider } from './context';
import { getPixels, processTankerMessages } from './utils';

import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/ru'; // Add locale data for de
import '@formatjs/intl-pluralrules/locale-data/sr';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/ru';
import '@formatjs/intl-relativetimeformat/locale-data/sr';

// Без указания root, по умолчанию body
configureRootTheme({ theme });

const LOCALES = {
    ru: 'ru-RU',
    sr: 'sr-RS',
};

// const BAOBAB_SERVICE = 'schoolbook';

const APPROPTIATE_ENV = ['autotest-stand', 'development', 'local', 'testing'];

const AppFactory = (reducers, apiConfig, onBeforeMount) => {
    class App extends Component {
        static propTypes = {
            appInitialReducers: PropTypes.object,
            children: PropTypes.node,
            config: PropTypes.object,
            data: PropTypes.object,
            errorCode: PropTypes.number,
            messages: PropTypes.object,
            onAppWillMount: PropTypes.func,
            route: PropTypes.object,
            rootSaga: PropTypes.func,
            reloadOnUserChange: PropTypes.string,
        };

        static childContextTypes = {
            basePath: PropTypes.string,
            pluckerBasePath: PropTypes.string,
            data: PropTypes.object,
            environment: PropTypes.string,
            errorCode: PropTypes.number,
            featureFlags: PropTypes.object,
            initialRender: PropTypes.bool,
            l7path: PropTypes.string,
            me: PropTypes.object,
            metrika: PropTypes.object,
            nonce: PropTypes.string,
            prefix: PropTypes.string,
            service: PropTypes.string,
            sk: PropTypes.string,
            socketIOMountPath: PropTypes.string,
            staticUrls: PropTypes.object,
            uatraits: PropTypes.object,
            problemPlate: PropTypes.object,
            mainPage: PropTypes.object,
            specProjectConfig: PropTypes.object,
            formsConfig: PropTypes.object,
            store: PropTypes.object,
            feynmanDomain: PropTypes.string,
            websocketDomain: PropTypes.string,
            requestId: PropTypes.string,
        };

        constructor(props) {
            super(props);

            this.state = {
                initialRender: true,
            };
        }

        getChildContext() {
            // route === window._data
            const {
                route,
                route: { config },
            } = this.props;
            const { initialRender } = this.state;

            return {
                basePath: route.basePath,
                pluckerBasePath: route.pluckerBasePath,
                data: route.data,
                environment: config.environment,
                errorCode: route.errorCode,
                featureFlags: route.featureFlags,
                initialRender,
                l7path: config.l7path,
                metrika: config.metrika,
                nonce: config.nonce,
                prefix: config.prefix,
                service: config.service,
                sk: config.sk,
                socketIOMountPath: config.socketIOMountPath,
                staticUrls: config.staticUrls,
                uatraits: config.uatraits,
                problemPlate: route.basePlate,
                mainPage: route.mainPage,
                specProjectConfig: route.specProjectConfig,
                formsConfig: route.formsConfig,
                store: this.store,
                feynmanDomain: config.feynmanDomain,
                websocketDomain: config.websocketDomain,
                requestId: config.requestId,
            };
        }

        UNSAFE_componentWillMount() {
            const { appInitialReducers, onAppWillMount, rootSaga, route } = this.props;
            const {
                config: { staticUrls, sk, experimentFlags = {}, experimentTestId = '', experimentTestIds = [], pixels },
            } = route;

            const initialReducers = {
                ...reducers,
                ...appInitialReducers,
                router: routerReducer,
                _data: (state = route) => state,
                pixelsStore: pixelsReducer,
            };

            const initialState = {
                experimentData: { experimentFlags, experimentTestId, experimentTestIds },
                pixelStore: { pixels: getPixels(pixels) },
            };

            this.store = createStore(initialReducers, rootSaga, initialState);

            apiConfig.setStaticUrls(staticUrls);
            apiConfig.setSecretKey(sk);

            if (onBeforeMount) {
                onBeforeMount({ route, store: this.store });
            }

            if (onAppWillMount) {
                onAppWillMount({ route, store: this.store });
            }
        }

        componentDidMount() {
            this.updateCookie();
            // eslint-disable-next-line react/no-did-mount-set-state
            this.setState({ initialRender: false });
        }

        updateCookie() {
            const {
                route: { config, data },
            } = this.props;

            const { search } = window.location;
            const { getMe } = data || {};
            const { cookieStatus } = getMe || {};
            const { environment } = config || {};

            const forceRefresh = APPROPTIATE_ENV.includes(environment) && search.includes('force_cookie_reset');

            if (cookieStatus === 'NEED_RESET' || forceRefresh) {
                fetch('https://passport.yandex.ru/auth/update/', {
                    credentials: 'include',
                    // eslint-disable-next-line no-unused-vars
                }).catch((error) => reportMessage(error.message));
            }
        }

        // пока непонятно как отсылать state в error booster
        onKeyPress = (e) => {
            // HotKey: Ctrl + Alt + E
            if (e.altKey && e.ctrlKey && e.which === 69) {
                // eslint-disable-next-line no-undef
                if (window.Ya && window.Ya.Rum && window.Ya.Rum.logError) {
                    window.Ya.Rum.logError({ level: 'info', message: 'Redux state' });
                }
            }
        };

        render() {
            const {
                route: {
                    config: {
                        initialNow,
                        locale: { language },
                        // uatraits,
                        // experimentTestIds,
                        // requestId,
                        // environment,
                    },
                    tankerMessages,
                    // basePath,
                },
                reloadOnUserChange,
            } = this.props;

            // const { AppWithBaobab } = withBaobab(
            //     uatraits.isMobile ? 'mobile' : 'desktop',
            //     // Нужно передать компонент. Пока что не придумал ничего лучше
            //     () => this.props.children,
            //     typeof window === 'undefined',
            //     environment === 'production' ? BAOBAB_SERVICE : `${BAOBAB_SERVICE}-test`
            // );

            // const host = new URL(basePath).hostName;
            // const baobabState = getBaobabState(requestId, host, experimentTestIds, BAOBAB_SERVICE);

            const processedTankerMessages = processTankerMessages(tankerMessages);

            // const searchParams = (typeof window !== 'undefined' && window.location.search) || '';
            // const { baobabDebug } = qs.parse(searchParams);

            return (
                <StoreProvider store={this.store}>
                    <IntlProvider
                        defaultLocale={LOCALES.ru}
                        initialNow={initialNow}
                        locale={LOCALES[language]}
                        messages={processedTankerMessages}
                    >
                        <AppContextProvider value={this.getChildContext()}>
                            {reloadOnUserChange && <UseReloadOnLogin suffix={reloadOnUserChange} />}
                            {this.props.children}
                            {/* {baobabDebug && <BaobabButton service={BAOBAB_SERVICE} />} */}
                        </AppContextProvider>
                    </IntlProvider>
                </StoreProvider>
            );
        }
    }

    return App;
};

export default AppFactory;
