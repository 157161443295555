import { Uatraits } from '@yandex-int/k-common';
import { FeatureFlags } from '@yandex-int/k-common/typings';
import { createContext, useContext } from 'react';
import { Store } from 'redux';

import { ViewDeps } from '../../typings';

const appContext = createContext<AppContext>({} as AppContext);

export const AppContextProvider = appContext.Provider;

export const AppContextConsumer = appContext.Consumer;

export function useAppContext() {
    return useContext(appContext);
}

export const useFeatureFlags = () => useAppContext().featureFlags;

export interface AppContext {
    basePath: string;
    pluckerBasePath: string;
    data: ViewDeps;
    environment: string;
    errorCode?: number;
    featureFlags: FeatureFlags;
    initialRender: boolean;
    l7path: string;
    metrika: MetrikaConfig;
    nonce: string;
    prefix: string;
    service: string;
    sk: string;
    socketIOMountPath: string;
    staticUrls: { [key: string]: string };
    uatraits: Uatraits;
    problemPlate: object;
    mainPage: object;
    specProjectConfig: object;
    formsConfig: object;
    store: Store;
    feynmanDomain: string;
    websocketDomain: string;
}

interface MetrikaConfig {
    options: {
        clickmap: boolean;
        trackLinks: boolean;
        accurateTrackBounce: boolean;
        webvisor: boolean;
        trackHash: boolean;
    };
    idByService: null;
    defaultId: number;
}
