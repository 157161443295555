import PropTypes from 'prop-types';
import React from 'react';

import withUserRouter from '../../hocs/user-router/user-router';
import StartScreen from '../start-screen';

import IndexFooter from './__footer';
import IndexHeader from './__header';

import './index.scss';

class Index extends React.Component {
    static propTypes = {
        registerAdult: PropTypes.func.isRequired,
    };

    scrollToStudentLogin = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    render() {
        const { registerAdult } = this.props;

        return (
            <div className="index index-page">
                <IndexHeader signInAdult={registerAdult} signInStudent={this.scrollToStudentLogin} />
                <StartScreen registerAdult={registerAdult} />
                <IndexFooter />
            </div>
        );
    }
}

export default withUserRouter(Index);
