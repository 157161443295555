import { postFetch } from '../fetch';

import { apiConfig } from '../config';

interface AddCoursesRequest {
    classId: number;
    templateIds: Array<number>;
}

export function postAddCourses(requestData: AddCoursesRequest):Promise<any> {
    const { classId, templateIds } = requestData;
    const { apiPostAddCourses } = apiConfig.getStaticUrls();

    return postFetch(`${apiPostAddCourses}${classId}/`, { template_ids: templateIds });
}
