// @ts-nocheck
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import { EXTERNAL_LMS_SLUGS } from '@yandex-int/k-common/typings';
import { SimpleCallback } from 'platform-components';

import { StoreState } from 'store/external-lms/typings';

const initialState: StoreState = {
    createTeamsLoadingStatus: LOADING_STATUSES.UNSENT,
};

// Параметры нужны для саги
/* eslint-disable @typescript-eslint/no-unused-vars */
const slice = createSlice({
    name: 'EXTERNAL_LMS',
    initialState: initialState,
    reducers: {
        createTeamsRequest: (
            state: StoreState,
            action: PayloadAction<{
                externalTeamIds: Array<number>;
                lms: EXTERNAL_LMS_SLUGS;
                onSuccess?: SimpleCallback;
                onError?: SimpleCallback;
            }>
        ) => {
            state.createTeamsLoadingStatus = LOADING_STATUSES.LOADING;
        },
        createTeamsSuccess: (state: StoreState) => {
            state.createTeamsLoadingStatus = LOADING_STATUSES.SUCCESS;
        },
        createTeamsError: (state: StoreState) => {
            state.createTeamsLoadingStatus = LOADING_STATUSES.ERROR;
        },
    },
});
/* eslint-enable @typescript-eslint/no-unused-vars */

export const actions = slice.actions;
export const reducer = slice.reducer;
