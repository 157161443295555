import get from 'lodash/get';
import { createSelector } from 'reselect';

import { School } from 'common.components/typings';

import { SchoolFormStore, AddressWithId } from './typings';

interface StoreWithSchoolForm {
    schoolForm: SchoolFormStore;
}

const schoolFormStateSelector = <T extends StoreWithSchoolForm>(storeState: T) => storeState.schoolForm;

/**
 * @param {Object} storeState
 * @return {Boolean}
 */
export const getAreAddressesLoading = createSelector(schoolFormStateSelector, (state) => state.areAddressesLoading);

/**
 * @param {Object} storeState
 * @return {Boolean}
 */
export const getAreSchoolsLoading = createSelector(schoolFormStateSelector, (state) => state.areSchoolsLoading);

/**
 * @param {Object} storeState
 * @return {String}
 */
export const getMode = createSelector(schoolFormStateSelector, (state) => state.mode);

/**
 * @param {Object} storeState
 * @return {Array}
 */
export const getSchools = createSelector(schoolFormStateSelector, (state) => state.schools);

/**
 * @param {Object} storeState
 * @return {Array[{subtitle: String, geoid: String, title: String}]}
 */
export const getAddresses = createSelector(schoolFormStateSelector, (state) => state.addresses);

/**
 * @param {Object} storeState
 * @return {{id: String, geoid: String, title: String, subtitle: String}|null}
 */
export const getCurrentAddress = createSelector(
    schoolFormStateSelector,
    (state: SchoolFormStore): AddressWithId | null => state.currentAddress
);

/**
 * @param {Object} storeState
 * @return {Boolean}
 */
export const getIsCurrentAddressSet = createSelector(
    getCurrentAddress,
    (address: AddressWithId | null): boolean => get(address, 'id', null) !== null
);

/**
 * @param {Object} storeState
 * @return {{id: String, subtitle: String, title: String}|null}
 */
export const getCurrentSchool = createSelector(
    schoolFormStateSelector,
    (state: SchoolFormStore): School | null => state.currentSchool
);

/**
 * @param {Object} storeState
 * @return {Boolean}
 */
export const getIsCurrentSchoolSet = createSelector(getCurrentSchool, (school: School | null): boolean =>
    Boolean(get(school, 'id', null) !== null || get(school, 'title'))
);
