import * as api from '@yandex-int/k-common/client/api';
import { getCoursesTemplates } from '@yandex-int/k-common/client/api';
// @ts-ignore
import { SUBJECTS_SLUGS } from '@yandex-int/k-common/constants';
import { CourseTemplate, Me, Team } from '@yandex-int/k-common/typings';
import { pixelsActions, showNetworkError, SimpleCallback } from 'platform-components';
import { NOTIFICATION_THEMES } from 'platform-components/constants';
import { showIntlMessageNotification } from 'platform-components/src/components/notification/actions';
import { getMe } from 'platform-components/src/components/user/selectors';
import { reportMessage } from 'platform-components/utils';
import ym from 'react-yandex-metrika';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { setWizardVariables } from 'common.components/user/actions';
import * as userActions from 'common.components/user/actions';
import { SECONDARY_SCHOOL_FIRST_CLASS, HIGH_SCHOOL_FIRST_CLASS } from 'common.components/user/user.constants';
import * as coursesTemplatesActions from 'store/courses-templates/actions';
import { getSourceProject } from 'utils/get-data-from-search-parameters';

import { actions as teacherRegistrationPageActions } from '../teacher-registration-page/actions';
import { getRegistrationData } from '../teacher-registration-page/selectors';
import { TeacherRegistrationData } from '../teacher-registration-page/typings';

import * as actions from './actions';
import {
    FINISH_REGISTRATION,
    APPLY_CODE_REQUEST,
    FINISH_REGISTRATION_BY_CODE,
    FINISH_REGISTRATION_BY_LINK,
    LOAD_SUBJECTS_FOR_GRADE,
} from './constants';
import { getCreatedClass, shouldForceSelectingAllSubjectsForGrade } from './selectors';
import { getIsPromoCodeValid } from './utils';

export function* teacherRegistrationSagasWatcher() {
    yield all([
        takeLatest(LOAD_SUBJECTS_FOR_GRADE, loadSubjectsForGradeSaga),
        takeLatest(FINISH_REGISTRATION, finishRegistrationSaga),
        takeLatest(APPLY_CODE_REQUEST, applyCodeSaga),
        takeLatest(FINISH_REGISTRATION_BY_CODE, finishRegistrationByCodeSaga),
        takeLatest(FINISH_REGISTRATION_BY_LINK, finishRegistrationByLinkSaga),
    ]);
}

function* loadSubjectsForGradeSaga(action: ReturnType<typeof actions.loadSubjectsForGrade>) {
    const { grade } = action;

    let coursesTemplates: CourseTemplate[];

    try {
        coursesTemplates = yield call(api.getCoursesTemplates, { grade });
        yield put(coursesTemplatesActions.getCoursesTemplatesSuccess({ grade, coursesTemplates }));
    } catch (error) {
        console.error(error);

        yield put(coursesTemplatesActions.getCoursesTemplatesError({ grade }));
        yield put(showNetworkError(error));
        return;
    }

    const forceSelection: boolean = yield select(shouldForceSelectingAllSubjectsForGrade, grade);

    let selectedCourseTemplateIds: number[] | null = null;

    if (forceSelection) {
        selectedCourseTemplateIds = coursesTemplates.map((t) => t.id);
    } else if (grade < SECONDARY_SCHOOL_FIRST_CLASS) {
        selectedCourseTemplateIds = coursesTemplates
            .filter((t) => t.subject === SUBJECTS_SLUGS.russian || t.subject === SUBJECTS_SLUGS.mathematics)
            .map((t) => t.id);
    }

    if (selectedCourseTemplateIds) {
        yield put(teacherRegistrationPageActions.setRegistrationData({ courseTemplateIds: selectedCourseTemplateIds }));
    }
}

class UnexpectedConditionError {
    message: string;

    constructor(message: string) {
        this.message = message;
    }
}

function* getCourseTemplateIdsSaga(classesData: { grade: number; teamId: number }[]) {
    const courseTemplatesPerClassIds: Record<number, Array<number>> = {};

    for (const classData of classesData) {
        const { grade, teamId } = classData;

        if (grade) {
            const coursesTemplates: CourseTemplate[] = yield call(getCoursesTemplates, { classId: null, grade });

            courseTemplatesPerClassIds[teamId] = coursesTemplates.map(({ id }: { id: number }) => id);
        }
    }

    const classIds = classesData
        .filter(({ grade }) => grade && grade < HIGH_SCHOOL_FIRST_CLASS)
        // @ts-ignore
        .map((r) => r.classId);

    yield call(api.postAddCoursesBulk, { classIds, templatesPerClassIds: courseTemplatesPerClassIds });
}

// eslint-disable-next-line complexity
function* finishRegistrationSaga(action: ReturnType<typeof actions.finishRegistration>) {
    try {
        const { callback } = action;
        const { is_parent: isParent } = yield call(api.getMe);

        if (isParent) {
            yield put(
                showIntlMessageNotification({
                    messageId: 'registration.alreadyRegistered.parent',
                    theme: NOTIFICATION_THEMES.FAIL,
                })
            );

            return;
        }

        const { salesCode: promo, classes: classesData }: TeacherRegistrationData = yield select(getRegistrationData);

        if (!classesData || !classesData.length) {
            throw new UnexpectedConditionError('Unexpected missing classes data on finishing registration');
        }

        const validPromo = getIsPromoCodeValid(promo || '') ? promo : null;

        const { id } = yield call(api.postMakeMeTeacher); // новая ручка postMakeMeTeacher

        const classIdsResponses: api.PostCreateClassBulkResponse = yield call(api.postCreateClassBulk, {
            classes: classesData, // TODO: неверный тип
        } as any);

        yield call(getCourseTemplateIdsSaga, classIdsResponses as any); // TODO: неверный тип

        yield call(sendPlatformTeacherRegistrationFinishedGoal, {
            goal: 'platform_teacher_registration_finished',
            meId: id,
            teamCreationType: 'manual_creation',
        });

        yield put(actions.finishRegistrationSuccess());

        if (validPromo) {
            yield call(api.postSetEventCode, validPromo);
            yield put(
                setWizardVariables(
                    {
                        teacherRegistrationPromoCodeApplied: Boolean(validPromo) || undefined,
                    },
                    callback
                )
            );
        } else if (callback) {
            callback();
        }
    } catch (error) {
        if (error instanceof UnexpectedConditionError) {
            console.error(error.message);
            reportMessage(error.message);
        } else {
            console.error(error);
        }

        yield put(actions.finishRegistrationError());
        yield put(showNetworkError(error));
    }
}

function* applyCodeSaga(action: ReturnType<typeof actions.applyCodeRequest>) {
    try {
        const { code } = action;

        const team: Team = yield call(api.putAuthByCode, code);
        const me: Me = yield call(api.getMe);
        yield put(userActions.setMe(me));
        yield put(actions.applyCodeSuccess(team));
    } catch (error) {
        console.error(error);
        yield put(actions.applyCodeError(error));
    }
}

function* finishRegistrationByCodeSaga(action: ReturnType<typeof actions.finishRegistrationByCode>) {
    try {
        const { callback } = action;

        const me: Me = yield select(getMe);

        const createdClass: Team | undefined = yield select(getCreatedClass);
        const { id: classId } = createdClass || {};

        yield call(teacherRegisterUtil, classId!, callback); // TODO: undefined?

        yield call(sendPlatformTeacherRegistrationFinishedGoal, {
            goal: 'platform_teacher_registration_finished',
            meId: me?.id,
            teamCreationType: 'code',
        });
    } catch (error) {
        console.error(error);
        yield put(showNetworkError(error));
        yield put(actions.finishRegistrationByCodeError());
    }
}

function* finishRegistrationByLinkSaga(action: ReturnType<typeof actions.finishRegistrationByLink>) {
    try {
        const { callback, classId } = action;

        const me: Me = yield select(getMe);

        yield call(teacherRegisterUtil, classId, callback);

        yield call(sendPlatformTeacherRegistrationFinishedGoal, {
            goal: 'platform_teacher_registration_finished',
            meId: me?.id,
            teamCreationType: 'link',
        });
    } catch (error) {
        console.error(error);
        yield put(showNetworkError(error));
        yield put(actions.finishRegistrationByCodeError());
    }
}

function* teacherRegisterUtil(classId: number, callback: SimpleCallback) {
    const { courseTemplateIds, salesCode: promo } = yield select(getRegistrationData);

    yield call(api.postAddCourses, { classId, templateIds: courseTemplateIds });

    if (getIsPromoCodeValid(promo)) {
        yield put(userActions.saveSalesCode(promo));
        yield put(setWizardVariables({ teacherRegistrationPromoCodeApplied: true }));
    }

    yield put(actions.finishRegistrationByCodeSuccess());
    yield put(
        setWizardVariables(
            {
                registratedByCode: true,
            },
            callback
        )
    );
}

function* sendPlatformTeacherRegistrationFinishedGoal(params: {
    goal: string;
    teamCreationType: 'link' | 'code' | 'manual_creation';
    meId: number | null;
}) {
    const { goal, meId, teamCreationType } = params;
    yield put(pixelsActions.trackAllPixelsButtonClick({ goal }));
    ym('reachGoal', goal, {
        id: meId,
        from_project: getSourceProject(),
        class_creation_type: teamCreationType,
    });
}
