import * as api from '@yandex-int/k-common/client/api';
import { LESSON_STATUS, LessonSubscriptionsData } from '@yandex-int/k-common/typings';
import { showNetworkError } from 'platform-components';
import { getIsParent } from 'platform-components/src/components/user/selectors';
import { call, put, select } from 'redux-saga/effects';

import { courseRecommendationsActions } from 'store/recommendations/actions';
import { courseSubscriptionsActions } from 'store/subscriptions/actions';
import { getCLessonSubscriptions } from 'store/subscriptions/subscriptions-loader';

import { actions as lessonActions } from '../index';
import { getLessonAssigns } from '../sagas/getLessonAssigns';
import * as lessonSelectors from '../selectors';
import { isLessonCompleted, isLessonInProgress } from '../utils';

import { loadTeam } from './utils';

// eslint-disable-next-line complexity
export function* getClessonWithResultsSaga(action: ReturnType<typeof lessonActions.getClessonWithResultsRequest>) {
    try {
        const { clessonId, courseId, onSuccess } = action.payload;
        const isParent: boolean = yield select(getIsParent);

        yield loadTeam();
        yield put(courseRecommendationsActions.getRecommendationLessonLinkRequest({ clessonId }));
        const data: api.FullClessonResult = yield call(api.getFullClessonWithResults, clessonId, courseId);

        yield put(lessonActions.setLesson({ lesson: data.clesson }));
        yield put(lessonActions.setCLessonResults({ results: data.clessonResults.data }));

        if (isParent) {
            const subscriptions: LessonSubscriptionsData = yield call(getCLessonSubscriptions, [clessonId]);

            yield put(courseSubscriptionsActions.getSubscriptionsByCourse({ courseId }));
            yield put(lessonActions.setSubscriptions({ subscriptions: subscriptions[clessonId] || [] }));
        }

        const status: LESSON_STATUS | null = yield select(lessonSelectors.getLessonStatus);
        const isRepeatableLesson: boolean = yield select(lessonSelectors.getIsRepeatableLesson);

        if (status && !isLessonCompleted(status) && !(isLessonInProgress(status) && isRepeatableLesson)) {
            yield call(getLessonAssigns, { cLessonId: data.clesson.clesson!.id, courseId });
        }

        yield put(lessonActions.getClessonWithResultsSuccess());

        if (onSuccess) {
            onSuccess(data.clesson as any);
        }
    } catch (error) {
        yield put(showNetworkError(error));
        yield put(lessonActions.getClessonWithResultsError());
    }
}
