export enum BUTTON_SIZE {
    S = 's',
    M = 'm',
    L = 'l',
    ADAPTIVE = 'adaptive',
}

export enum BUTTON_VIEW {
    ACTION = 'action',
    SECONDARY = 'secondary',
    PSEUDO = 'pseudo',
    CLEAR = 'clear',
    RAISED = 'raised',
    LITE = 'lite',
    PLUS = 'plus',
}

export enum BUTTON_ACCENT {
    DANGER = 'danger',
    NORMAL = 'normal',
}

export enum BUTTON_ICON_POSITION {
    LEFT = 'left',
    RIGHT = 'right',
}

export enum BUTTON_TRANSPARENCY_THEME {
    LIGHT = 'light',
    DARK = 'dark',
}

export enum BUTTON_TYPE {
    LINK = 'link',
}
