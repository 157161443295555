export const EDIT_STUDENT_NAME_SUCCESS = 'STUDENTS.EDIT_STUDENT_NAME_SUCCESS';
export const EDIT_STUDENT_NAME_ERROR = 'STUDENTS.EDIT_STUDENT_NAME_ERROR';
export const EDIT_STUDENT_NAME_REQUEST = 'STUDENTS.EDIT_STUDENT_NAME_REQUEST';

export const CANCEL_ADDING_NEW_STUDENT = 'STUDENTS.CANCEL_ADDING_NEW_STUDENT';

export const GET_NEW_STUDENT_LOGIN_REQUEST = 'STUDENTS.GET_NEW_STUDENT_LOGIN_REQUEST';
export const GET_NEW_STUDENT_LOGIN_SUCCESS = 'STUDENTS.GET_NEW_STUDENT_LOGIN_SUCCESS';

export const SAVE_NEW_STUDENT_SUCCESS = 'STUDENTS.SAVE_NEW_STUDENT_SUCCESS';
export const SAVE_NEW_STUDENT_REQUEST = 'STUDENTS.SAVE_NEW_STUDENT_REQUEST';

export const RESET_STUDENT_LOGIN_REQUEST = 'STUDENTS.RESET_STUDENT_LOGIN_REQUEST';
export const RESET_STUDENT_LOGIN_SUCCESS = 'STUDENTS.RESET_STUDENT_LOGIN_SUCCESS';

export const DELETE_STUDENT_REQUEST = 'STUDENTS.DELETE_STUDENT_REQUEST';
export const DELETE_STUDENT_SUCCESS = 'STUDENTS.DELETE_STUDENT_SUCCESS';

export const SET_CURRENT_EDITING_STUDENT_NAME = 'STUDENTS.SET_CURRENT_EDITING_STUDENT_NAME';

export const SET_CURRENT_EDITING_STUDENT_INDEX = 'STUDENTS.SET_CURRENT_EDITING_STUDENT_INDEX';

export const SUBMITTING = 'STUDENTS.SUBMITTING';

export const REQUESTING_LOGIN = 'STUDENTS.REQUESTING_LOGIN';

export const CONVERT_STUDENTS_PASSPORT_REQUEST = 'STUDENTS.CONVERT_STUDENTS_PASSPORT_REQUEST';
export const CONVERT_STUDENTS_PASSPORT_SUCCESS = 'STUDENTS.CONVERT_STUDENTS_PASSPORT_SUCCESS';
export const CONVERT_STUDENTS_PASSPORT_ERROR = 'STUDENTS.CONVERT_STUDENTS_PASSPORT_ERROR';

export const CONVERT_STUDENT_PASSPORT_REQUEST = 'STUDENTS.CONVERT_STUDENT_PASSPORT_REQUEST';
export const CONVERT_STUDENT_PASSPORT_SUCCESS = 'STUDENTS.CONVERT_STUDENT_PASSPORT_SUCCESS';
export const CONVERT_STUDENT_PASSPORT_ERROR = 'STUDENTS.CONVERT_STUDENT_PASSPORT_ERROR';

export const CHANGE_STUDENT_PASSWORD_REQUEST = 'STUDENTS.CHANGE_STUDENT_PASSWORD_REQUEST';
export const CHANGE_STUDENT_PASSWORD_SUCCESS = 'STUDENTS.CHANGE_STUDENT_PASSWORD_SUCCESS';
export const CHANGE_STUDENT_PASSWORD_ERROR = 'STUDENTS.CHANGE_STUDENT_PASSWORD_ERROR';

export const UPDATE_PASSPORT_STUDENTS = 'STUDENTS.UPDATE_PASSPORT_STUDENTS';

export const SET_STUDENT_IS_MIGRATING = 'STUDENTS.SET_STUDENT_IS_MIGRATING';

export const SET_STUDENT_VALIDATION = 'STUDENTS.SET_STUDENT_VALIDATION';
