import { Course } from '../typings';

const { COURSE_DISPLAY_LOCATION } = require('../constants');

export function getIsCourseInvisibleForTeacher(course: Course) {
    return course.display_location === COURSE_DISPLAY_LOCATION.INVISIBLE_FOR_TEACHER;
}

export function getInvisibleForTeacherCourses(courses: Course[]) {
    if (!courses) {
        return [];
    }

    return courses.filter(getIsCourseInvisibleForTeacher);
}
