import { Me, EXTERNAL_LMS_SLUGS } from '@yandex-int/k-common/typings';
import { Popup, Spin, Text, Tooltip, SimpleText, SimpleCallback, PersonalAgreement } from 'platform-components';
import { POPUP_DIRECTIONS } from 'platform-components/constants';
import { getMe } from 'platform-components/src/components/user/selectors';
import { withRouterPath, InjectedProps as WithRouterPathProps } from 'platform-components/hocs';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import ym from 'react-yandex-metrika';
import { bindActionCreators, Dispatch } from 'redux';

import {
    ConnectDnevnikruButton,
    ConnectMesButton,
    ConnectTeacherCodeButton,
} from 'common.components/connect-external-button';
import ConnectExternalError from 'common.components/connect-external-error/connect-external-error';
import { INTEGRATION_SCENARIO } from 'common.components/constants';
// @ts-ignore
import FormView from 'common.components/form-view/form-view';
// @ts-ignore
import HeadedFormViewCard from 'common.components/headed-form-view-card';
import { School } from 'common.components/typings';
import { externalLmsConnectActions } from 'store/external-lms-connect/actions';
import * as externalLmsSelectors from 'store/external-lms-connect/selectors';
import { ExternalLmsConnectStore } from 'store/external-lms-connect/typings';
import { getSourceProject } from 'utils/get-data-from-search-parameters';
import { YM_LOCATION } from 'utils/ym-constants';

import AddressSection from './__address-section/school-form__address-section';
import FooterLink from './__footer-link/school-form__footer-link';
import { SchoolFormSchoolSectionContainer } from './__school-section/school-form__school-section.container';
import { SchoolFormSendButton as SendButton } from './__send-button/school-form__send-button';
import * as schoolActions from './actions';
import { MODES } from './school-form.constants';
import {
    getIsCurrentAddressSet,
    getIsCurrentSchoolSet,
    getMode,
    getCurrentAddress,
    getCurrentSchool,
} from './selectors';
import { AddressWithId, SchoolData, SchoolFormStore } from './typings';

import './school-form.scss';

interface HOCProps extends WrappedComponentProps, WithRouterPathProps {}

interface OwnProps {
    canAddOwnSchool?: boolean;
    onChange: (arg: SchoolData) => void;
    onGoBack?: SimpleCallback;
    onSubmit: (arg: SchoolData) => void;
    shouldDisableAddress?: boolean;
    shouldHideAlternativeRegistration?: boolean;
    value: SchoolData;
    shouldShowPersonalData: boolean;
    ymLocation?: YM_LOCATION;
}

interface StoreProps {
    isCurrentAddressSet: boolean;
    isCurrentSchoolSet: boolean;
    me: Me | null;
    mode: MODES;
    currentAddress: AddressWithId | null;
    currentSchool: School | null;
    lmsByRegion: Array<string>;
    isLmsLoading: boolean;
    isLmsError: boolean;
    shouldLoadUserExternalLms: boolean;
}

interface DispatchProps {
    clearState: typeof schoolActions.clearState;
    setMode: typeof schoolActions.setMode;
    setAddresses: typeof schoolActions.setAddresses;
    setCurrentAddress: typeof schoolActions.setCurrentAddress;
    setCurrentSchool: typeof schoolActions.setCurrentSchool;
    setSchools: typeof schoolActions.setSchools;
    getLmsByRegion: typeof externalLmsConnectActions.getLmsByRegionRequest;
    getUserExternalLmsListRequest: typeof externalLmsConnectActions.getUserExternalLmsListRequest;
}

interface Props extends HOCProps, OwnProps, StoreProps, DispatchProps {}

export class SchoolForm extends React.Component<Props> {
    static contextTypes = {
        basePath: PropTypes.string.isRequired,
        prefix: PropTypes.string.isRequired,
        staticUrls: PropTypes.object.isRequired,
    };

    componentDidMount() {
        const { setCurrentAddress, setAddresses, setSchools, getLmsByRegion, setCurrentSchool, setMode, value } =
            this.props;

        if (value) {
            const { address, school } = value;
            const schoolId = school?.id;
            const schoolName = school?.title;
            const schoolAddress = address?.id;

            this.getUserExternalLms();

            // отдельно обрабатываем кейс с неверифицированной школой
            if (schoolId === null && schoolName && schoolAddress) {
                setMode(MODES.NOT_FOUND);
                setCurrentSchool(school!);
            }

            if (schoolId) {
                setCurrentSchool(school!);
                setSchools([school!]);
            }

            if (schoolAddress) {
                setCurrentAddress(address!);
                getLmsByRegion({ geoId: address?.geoid! });
                setAddresses([address!]);
            }
        }
    }

    componentDidUpdate(prevProps: Props) {
        const { currentAddress, currentSchool, onChange } = this.props;
        const { currentAddress: prevAddress, currentSchool: prevSchool } = prevProps;

        if (currentAddress !== prevAddress || currentSchool !== prevSchool) {
            onChange({
                address: currentAddress,
                school: currentSchool,
            });
        }
    }

    componentWillUnmount() {
        const { clearState } = this.props;

        clearState();
    }
    context!: React.ContextType<
        React.Context<{
            staticUrls: Record<string, string>;
            basePath: string;
            prefix: string;
        }>
    >;

    private popupRef: Popup;

    getUserExternalLms = () => {
        const { getUserExternalLmsListRequest, shouldLoadUserExternalLms } = this.props;

        if (shouldLoadUserExternalLms) {
            getUserExternalLmsListRequest();
        }
    };

    setSuggestMode = () => {
        const { setMode } = this.props;

        setMode(MODES.SUGGEST);
    };

    handleSubmitForm = () => {
        const { currentAddress, currentSchool, onSubmit } = this.props;

        onSubmit({
            address: currentAddress,
            school: currentSchool,
        });
    };

    goToRegistrationByCode = () => {
        const {
            history,
            me,
            location: { search },
            ymLocation,
        } = this.props;
        const { staticUrls } = this.context;

        ym('reachGoal', 'teacher_onboarding_have_class_code', {
            id: me?.id,
            from_project: getSourceProject(),
            location: ymLocation,
        });

        //TODO: EDUCATION-19624 проверить редиректы МЭШ
        history.push(`${staticUrls.labLibraryMain}${search}`);
    };

    onPopupRef = (ref: Popup) => {
        this.popupRef = ref;
    };

    onMouseEnter = () => {
        if (this.popupRef) {
            this.popupRef.show();
        }
    };

    onMouseLeave = () => {
        if (this.popupRef) {
            this.popupRef.hide();
        }
    };

    // eslint-disable-next-line complexity
    getConnectButtons = () => {
        const {
            currentSchool,
            isCurrentAddressSet,
            isCurrentSchoolSet,
            isLmsError,
            isLmsLoading,
            lmsByRegion,
            mode,
            getRouterPath,
        } = this.props;

        const buttons: JSX.Element[] = [];
        //TODO: EDUCATION-19624 проверить редиректы МЭШ
        const {
            basePath,
            prefix,
            staticUrls: { teacherRegistrationSchool },
        } = this.context;

        const isModeNotFound = mode === MODES.NOT_FOUND;
        const disabled = !isCurrentAddressSet || !isCurrentSchoolSet || isModeNotFound;

        if (lmsByRegion.includes(EXTERNAL_LMS_SLUGS.MES)) {
            //TODO: EDUCATION-19624 проверить редиректы МЭШ
            // и ниже проверить ключи танкера
            const rejectRetpath = `${basePath}${prefix}${getRouterPath(teacherRegistrationSchool)}`;

            buttons.push(
                <ConnectMesButton
                    buttonTextId="teacherRegistration.withMes"
                    disabled={disabled}
                    key="mes"
                    mix="school-form__connect-mes-button"
                    onMouseEnter={this.onMouseEnter}
                    onMouseLeave={this.onMouseLeave}
                    rejectRetpath={rejectRetpath}
                    scenario={INTEGRATION_SCENARIO.REGISTRATION}
                    schoolId={currentSchool && currentSchool.id}
                >
                    {isModeNotFound && (
                        <Tooltip
                            onPopupRef={this.onPopupRef}
                            popupProps={{ direction: POPUP_DIRECTIONS.TOP, width: 288, offsetLeft: 148 }}
                            theme={Tooltip.THEME.LIGHT_ROUNDED}
                        >
                            <span className="school-form__tooltip-text">
                                <SimpleText id="teacherRegistration.mesDisabled.tooltip" />
                            </span>
                        </Tooltip>
                    )}
                </ConnectMesButton>
            );
        }
        if (lmsByRegion.includes(EXTERNAL_LMS_SLUGS.DNEVNIKRU)) {
            buttons.push(
                <ConnectDnevnikruButton
                    buttonTextId="teacherRegistration.withDnevnikru"
                    key="dnevnikru"
                    mix="school-form__connect-dnevnikru-button"
                />
            );
        }

        buttons.push(
            <ConnectTeacherCodeButton
                buttonTextId="schoolForm.withCode"
                key="teacher-code"
                mix="school-form__connect-teacher-code-button"
                onClick={this.goToRegistrationByCode}
            />
        );

        return (
            <>
                <div className="school-form__separator">
                    <span className="school-form__separator-or">
                        <Text id="schoolForm.separator.signInWith" size={Text.SIZE.S} />
                    </span>
                </div>
                {isLmsLoading && (
                    <div className="school-form__spin-wrapper">
                        <Spin center />
                    </div>
                )}
                {isLmsError && <ConnectExternalError />}
                {buttons}
            </>
        );
    };

    render() {
        const {
            canAddOwnSchool,
            intl,
            isCurrentAddressSet,
            isCurrentSchoolSet,
            onGoBack,
            mode,
            shouldDisableAddress,
            shouldHideAlternativeRegistration,
            shouldShowPersonalData,
            match: { params },
            ymLocation,
        } = this.props;

        const isNotFoundMode = mode === MODES.NOT_FOUND;
        const submitButtonText = intl.formatMessage({ id: `schoolForm.sendButton.${mode}` });
        const disabled = !isCurrentAddressSet || !isCurrentSchoolSet;
        const { lms } = params;

        const schoolChoiceDescriptionId =
            lms === EXTERNAL_LMS_SLUGS.MES
                ? 'schoolForm.description.choiceSchool.mes'
                : 'schoolForm.description.choiceSchool';

        const descriptionId = isNotFoundMode ? 'schoolForm.schoolAdding.message' : schoolChoiceDescriptionId;

        return (
            <HeadedFormViewCard descriptionId={descriptionId} headerId="schoolForm.header.tellYourSchool">
                <div className="school-form__school-controls">
                    <AddressSection location={ymLocation} shouldDisableAddress={shouldDisableAddress} />
                    <SchoolFormSchoolSectionContainer
                        canAddOwnSchool={Boolean(canAddOwnSchool)}
                        location={ymLocation}
                    />
                    <SendButton disabled={disabled} mode={mode} onSubmit={this.handleSubmitForm} />
                    {!shouldHideAlternativeRegistration && this.getConnectButtons()}
                    {shouldShowPersonalData && (
                        <FormView.CardDescription mix="school-form__pd">
                            <PersonalAgreement
                                buttonText={submitButtonText}
                                linksConfig={[
                                    PersonalAgreement.personalAgreementLinks.teacherPdLink,
                                    PersonalAgreement.personalAgreementLinks.olympLink,
                                ]}
                            />
                        </FormView.CardDescription>
                    )}
                </div>
                {isNotFoundMode && (
                    <FooterLink
                        mix="qa_back-btn"
                        onLinkClick={this.setSuggestMode}
                        textId="schoolForm.formLink.not-found"
                    />
                )}
                {onGoBack && (
                    <FooterLink mix="school-form__go-back-btn" onLinkClick={onGoBack} textId="schoolForm.goBack" />
                )}
            </HeadedFormViewCard>
        );
    }
}

type LocalStore = { externalLmsConnect: ExternalLmsConnectStore } & { schoolForm: SchoolFormStore };

const mapStateToProps = <T extends LocalStore>(storeState: T): StoreProps => {
    return {
        isCurrentAddressSet: getIsCurrentAddressSet(storeState),
        isCurrentSchoolSet: getIsCurrentSchoolSet(storeState),
        me: getMe(storeState),
        mode: getMode(storeState),
        currentAddress: getCurrentAddress(storeState),
        currentSchool: getCurrentSchool(storeState),
        lmsByRegion: externalLmsSelectors.getLmsByRegionList(storeState),
        isLmsLoading: externalLmsSelectors.getIsLmsLoading(storeState),
        isLmsError: externalLmsSelectors.getIsLmsError(storeState),
        shouldLoadUserExternalLms: externalLmsSelectors.getShouldLoadUserExternalLms(storeState),
    };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
    return bindActionCreators(
        {
            clearState: schoolActions.clearState,
            setMode: schoolActions.setMode,
            setAddresses: schoolActions.setAddresses,
            setCurrentAddress: schoolActions.setCurrentAddress,
            setCurrentSchool: schoolActions.setCurrentSchool,
            setSchools: schoolActions.setSchools,
            getLmsByRegion: externalLmsConnectActions.getLmsByRegionRequest,
            getUserExternalLmsListRequest: externalLmsConnectActions.getUserExternalLmsListRequest,
        },
        dispatch
    );
};

export default injectIntl(withRouterPath(connect(mapStateToProps, mapDispatchToProps)(SchoolForm)));
