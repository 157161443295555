import * as api from '@yandex-int/k-common/client/api';
// @ts-ignore
import { NOTIFICATION_THEMES } from 'platform-components';
import { showIntlMessageNotification, showNetworkError } from 'platform-components/src/components/notification/actions';
import { call, put, takeLatest, all } from 'redux-saga/effects';

import { setClassesRequest } from 'common.components/user/actions';

import { actions } from './actions';

export function* externalServicesSagasWatcher() {
    yield all([takeLatest(actions.createTeamsRequest, importTeamsSaga)]);
}

function* importTeamsSaga(action: ReturnType<typeof actions.createTeamsRequest>) {
    const { externalTeamIds, lms, onSuccess } = action.payload;

    try {
        // специально по одному
        for (const externalTeamId of externalTeamIds) {
            yield call(api.postCreateTeams, [externalTeamId], lms);
        }
        yield put(setClassesRequest());

        if (onSuccess) {
            onSuccess();
        }

        yield put(actions.createTeamsSuccess());
    } catch (error) {
        console.error(error);

        yield put(actions.createTeamsError());

        if (error.status === 409) {
            const duplicateExternalTeamIds = error.data.duplicate_external_team_ids;
            const notImportedExternalTeamIds = externalTeamIds.filter(
                (teamId) => !duplicateExternalTeamIds.includes(teamId)
            );

            if (notImportedExternalTeamIds.length === 0) {
                yield put(
                    showIntlMessageNotification({
                        messageId: 'externalLms.error.duplicateClass',
                        theme: NOTIFICATION_THEMES.FAIL,
                    })
                );
            } else {
                yield put(actions.createTeamsRequest({ lms, externalTeamIds: notImportedExternalTeamIds }));
            }
        } else {
            const { onError } = action.payload;

            if (onError) {
                onError();
            }

            yield put(showNetworkError(error));
        }
    }
}
