import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import cx from 'classnames';
import {
    Input,
    Select,
    SimpleText,
    Text,
    SimpleCallback,
    PersonalAgreement,
    PersonalAgreementLink,
} from 'platform-components';
import { SELECT_THEMES } from 'platform-components/constants';
import { StoreWithData } from 'platform-components/src/components/app-factory/typings';
import { InputChangeEvent, SelectEvent } from 'platform-components/src/typings';
import { STUDENT_NAME_MAX_LENGTH } from 'platform-components/src/utils/get-student-name';
import { isValidHumanName } from 'platform-components/src/utils/input-validators';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';

import { getAvailableClassNumbers } from 'common.components/user/selectors';

// @ts-ignore
import FormView from '../form-view/form-view';

import './create-child-form.scss';

const submitButtonTextId = 'createChildForm.button';

interface OwnProps {
    onSubmit: SimpleCallback;
    onChangeName?: (payload: { name: string }) => void;
    onChangeStudentGrade: (payload: { grade: number }) => void;
    bottomSection?: React.ReactElement;
    mix?: string;
    name?: string;
    studentGrade: number | null;
    submittingStatus: LOADING_STATUSES;
    shouldCreateChild: boolean;
    showAgreement?: boolean;
    agreementLinks?: Array<PersonalAgreementLink>;
    titleId?: string;
}

type StateProps = ReturnType<typeof mapStateToProps>;

interface State {
    nameInvalidMsg: string;
    courseInvalidMsg: string;
}

interface Props extends OwnProps, WrappedComponentProps, StateProps {}

export class CreateChildForm extends React.PureComponent<Props, State> {
    static defaultProps = {
        titleId: 'createChildForm.title',
    };

    state: State = {
        nameInvalidMsg: '',
        courseInvalidMsg: '',
    };

    componentDidUpdate(): void {
        const { nameInvalidMsg, courseInvalidMsg } = this.state;

        if (nameInvalidMsg || courseInvalidMsg) {
            this.validate();
        }
    }

    handleChangeName = ({ target: { value } }: InputChangeEvent) => {
        const { onChangeName } = this.props;

        if (onChangeName) {
            onChangeName({ name: value.replace(/\s+/g, ' ') });
        }
    };

    handleChangeLevel = (e: SelectEvent<number | null>) => {
        const {
            target: { value },
        } = e;
        const grade = value;
        const { onChangeStudentGrade } = this.props;

        if (grade) {
            onChangeStudentGrade({ grade });
        }
    };

    validate = (): boolean => {
        const { intl, name, studentGrade } = this.props;

        let nameInvalidMsg = '';

        if (!name || !name.trim()) {
            nameInvalidMsg = intl.formatMessage({ id: 'createChildForm.nameInvalidMsg' });
        } else if (name.length > STUDENT_NAME_MAX_LENGTH) {
            nameInvalidMsg = intl.formatMessage({ id: 'createChildForm.nameInvalidLengthMsg' });
        } else if (!isValidHumanName(name)) {
            nameInvalidMsg = intl.formatMessage({ id: 'createChildForm.nameInvalidSymbolsMsg' });
        }

        this.setState({
            nameInvalidMsg,
            courseInvalidMsg: studentGrade ? '' : intl.formatMessage({ id: 'createChildForm.courseInvalidMsg' }),
        });

        return Boolean(nameInvalidMsg === '' && studentGrade);
    };

    handleSubmit = () => {
        const { onSubmit, submittingStatus } = this.props;
        const isSubmitting = submittingStatus === LOADING_STATUSES.LOADING;

        if (this.validate() && !isSubmitting) {
            onSubmit();
        }
    };

    render() {
        const {
            intl,
            mix,
            availableStudentGrades,
            name,
            studentGrade,
            submittingStatus,
            bottomSection,
            shouldCreateChild,
            showAgreement,
            titleId,
            agreementLinks = [
                PersonalAgreement.personalAgreementLinks.parentPdLink,
                PersonalAgreement.personalAgreementLinks.olympLink,
            ],
        } = this.props;
        const { nameInvalidMsg, courseInvalidMsg } = this.state;
        const submitButtonText = intl.formatMessage({ id: submitButtonTextId });

        return (
            <FormView.Card mix={cx('create-child-form', mix)}>
                <FormView.CardSection>
                    <div className="create-child-form__title">
                        <Text id={titleId} size={Text.SIZE.XXL} type={Text.TYPE.MARKDOWN} />
                    </div>
                    <div className="create-child-form__description">
                        <Text id="createChildForm.description" size={Text.SIZE.XL} type={Text.TYPE.MARKDOWN} />
                    </div>
                </FormView.CardSection>
                <FormView.CardSection mix="create-child-form__name">
                    <Text id="createChildForm.name.label" />
                    <Input
                        disabled={!shouldCreateChild}
                        errorMessage={nameInvalidMsg}
                        mix="create-child-form__input"
                        onChange={this.handleChangeName}
                        placeholder={intl.formatMessage({ id: 'createChildForm.name.placeholder' })}
                        valid={nameInvalidMsg ? 'no' : 'uncertain'}
                        value={name}
                    />
                </FormView.CardSection>
                <FormView.CardSection mix="create-child-form__level">
                    <Text id="createChildForm.level.label" />
                    <Select
                        errorMessage={courseInvalidMsg}
                        mix="create-child-form__select"
                        onSelect={this.handleChangeLevel}
                        placeholder={intl.formatMessage({ id: 'createChildForm.level.placeholder' })}
                        theme={SELECT_THEMES.SCHOOL}
                        valid={courseInvalidMsg ? 'no' : 'uncertain'}
                        value={studentGrade}
                        width={288}
                    >
                        {availableStudentGrades.map((grade) => (
                            <Select.Option key={grade} mix="create-child-form__select-option" value={grade}>
                                {grade}
                            </Select.Option>
                        ))}
                    </Select>
                </FormView.CardSection>
                <FormView.CardSection>
                    <FormView.Button
                        disabled={!name || !studentGrade}
                        onClick={this.handleSubmit}
                        progress={submittingStatus === LOADING_STATUSES.LOADING}
                    >
                        <SimpleText id={submitButtonTextId} />
                    </FormView.Button>
                </FormView.CardSection>
                {showAgreement && (
                    <FormView.CardDescription mix="create-child-form__agreement">
                        <PersonalAgreement buttonText={submitButtonText} linksConfig={agreementLinks} />
                    </FormView.CardDescription>
                )}
                {bottomSection && <FormView.Footer mix="create-child-form__footer">{bottomSection}</FormView.Footer>}
            </FormView.Card>
        );
    }
}

const mapStateToProps = <T extends StoreWithData>(store: T) => ({
    availableStudentGrades: getAvailableClassNumbers(store),
});

export default injectIntl(connect(mapStateToProps)(CreateChildForm));
