import { SUBJECT_LIBRARY_TAB_SLUG } from '@yandex-int/k-common';
import { ExternalLmsErrorMessages } from '@yandex-int/k-common/typings/external';
import { Team } from '@yandex-int/k-common/typings';
import { withRouterPath, InjectedProps as WithRouterPathProps } from 'platform-components/hocs';
import PropTypes from 'prop-types';
import qs from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { SEARCH_PARAMS_INTEGRATION, SEARCH_PARAMS_MES } from 'common.components/constants';

import ExternalLmsError from './__error/external-lms__error';
import { actions } from './actions';

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

interface OwnProps extends WithRouterPathProps {}

interface Props extends OwnProps, DispatchProps {}

export interface State {
    shouldShowError: boolean;
    errorId?: string | undefined;
    errorType?: 'passport' | undefined;
}

interface Context {
    basePath: string;
    data: {
        getExternalSubject?: {
            subject_slug: string;
        };
        postedProfile?:
            | {
                  errorCode: number;
                  user_external_id?: never;
              }
            | {
                  errorCode?: never;
                  user_external_id: string;
              };
        getClasses: Array<Team> | null;
    };
    staticUrls: { labClassLibrarySubjectTab: string };
}

/**
 * Разводящая страница для сценария создания урока
 */
export class ExternalLms extends React.Component<Props, State> {
    static contextTypes = {
        basePath: PropTypes.string.isRequired,
        data: PropTypes.shape({
            getExternalSubject: PropTypes.shape({
                subject_slug: PropTypes.string.isRequired,
            }),
            postedProfile: PropTypes.shape({
                user_external_id: PropTypes.string,
                errorCode: PropTypes.number,
            }),
            getClasses: PropTypes.array,
        }),
        staticUrls: PropTypes.shape({
            labClassLibrarySubjectTab: PropTypes.string.isRequired,
        }),
    };

    constructor(props: Props, context: Context) {
        super(props, context);

        if (isSSR) {
            return;
        }

        const {
            data: { getExternalSubject: externalSubject, postedProfile: postedProfile, getClasses: teams },
            staticUrls: { labClassLibrarySubjectTab },
        } = context;

        const {
            location: { search },
            getRouterPath,
            createMesClass,
        } = props;

        if (!postedProfile) {
            this.state = { shouldShowError: true, errorId: ExternalLmsErrorMessages.noContentForGrade };
            return;
        }

        if (postedProfile.errorCode) {
            // Если errorCode === 409, то аккаунт МЭШ привязан к другому паспорту
            this.state = {
                shouldShowError: true,
                errorId: postedProfile.errorCode === 409 ? ExternalLmsErrorMessages.duplicatePassport : undefined,
                errorType: 'passport',
            };
            return;
        }

        if (!externalSubject) {
            this.state = { shouldShowError: true, errorId: ExternalLmsErrorMessages.subjectUnavailable };
            return;
        }

        const {
            [SEARCH_PARAMS_MES.CLASS_ID]: externalClassId,
            [SEARCH_PARAMS_INTEGRATION.STAFF_ID]: staffIdStr,
            [SEARCH_PARAMS_INTEGRATION.SUBJECT_ID]: subjectId,
            [SEARCH_PARAMS_INTEGRATION.CLASS_LEVEL_ID]: classLevelId,
        } = qs.parse(search);

        if (Number(postedProfile.user_external_id) !== Number(staffIdStr)) {
            this.state = { shouldShowError: true, errorId: ExternalLmsErrorMessages.mismatchExternalIdWithStaffId };
            return;
        }

        const redirectToLabClassLibrarySubjectTab = (classId: string | number) => {
            window.location.href = `${getRouterPath(labClassLibrarySubjectTab, {
                classId,
                subject: externalSubject.subject_slug,
                tabSlug: SUBJECT_LIBRARY_TAB_SLUG.MAIN,
            })}${search}`;
        };

        const createdMesTeam = teams?.find((team) => team.external_lms?.mes === externalClassId);
        if (createdMesTeam) {
            redirectToLabClassLibrarySubjectTab(createdMesTeam.id);
            return;
        }

        createMesClass({
            externalClassId: Number(externalClassId),
            subjectId: Number(subjectId),
            classLevelId: Number(classLevelId),
            onSuccess: (classId) => redirectToLabClassLibrarySubjectTab(classId),
            onError: (errorId) => this.setState({ shouldShowError: true, errorId }),
        });
    }

    state = { shouldShowError: false } as State;

    /**
     * Рендерим страницу только если случилась ошибка, иначе в `constructor()` происходит редирект
     */
    render() {
        const { shouldShowError, errorId, errorType } = this.state;

        return shouldShowError ? <ExternalLmsError errorId={errorId} errorType={errorType} /> : null;
    }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({ createMesClass: actions.createMesClass }, dispatch);

export default connect(null, mapDispatchToProps)(withRouterPath(ExternalLms)) as React.ComponentType<OwnProps>;
