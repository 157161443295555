import noop from 'lodash/noop';
import React, { useContext } from 'react';

import { SimpleCallback } from '../../typings';

import { KEYBOARD_TYPES } from './types';

interface KeyboardContextTypeElement {
    handleKeyboardArrow?: SimpleCallback;
    handleTrackKeypress?: SimpleCallback;
    setRef: (instance: HTMLDivElement | null) => void;
    shouldShowKeyboard: boolean;
    shouldDisableNativeMobileKeyboard: boolean;
    keyboardAllowed: boolean;
}

export type KeyboardContextType = Record<KEYBOARD_TYPES, KeyboardContextTypeElement> & {
    show: (type: KEYBOARD_TYPES) => void;
    refs: Partial<Record<KEYBOARD_TYPES, HTMLElement | undefined>>;
};

const initialContext = {
    [KEYBOARD_TYPES.PRIMARY]: {
        handleKeyboardArrow: noop,
        handleTrackKeypress: noop,
        setRef: noop,
        shouldShowKeyboard: false,
        shouldDisableNativeMobileKeyboard: false,
        keyboardAllowed: false,
    },
    [KEYBOARD_TYPES.SECONDARY]: {
        handleKeyboardArrow: noop,
        handleTrackKeypress: noop,
        setRef: noop,
        shouldShowKeyboard: false,
        shouldDisableNativeMobileKeyboard: false,
        keyboardAllowed: false,
    },
    [KEYBOARD_TYPES.PROBLEM_BAR]: {
        handleKeyboardArrow: noop,
        handleTrackKeypress: noop,
        setRef: noop,
        shouldShowKeyboard: false,
        shouldDisableNativeMobileKeyboard: false,
        keyboardAllowed: false,
    },
    [KEYBOARD_TYPES.PROBLEM_BAR_SECONDARY]: {
        handleKeyboardArrow: noop,
        handleTrackKeypress: noop,
        setRef: noop,
        shouldShowKeyboard: false,
        shouldDisableNativeMobileKeyboard: false,
        keyboardAllowed: false,
    },
    [KEYBOARD_TYPES.PROBLEM_BAR_ROVER]: {
        handleKeyboardArrow: noop,
        handleTrackKeypress: noop,
        setRef: noop,
        shouldShowKeyboard: false,
        shouldDisableNativeMobileKeyboard: false,
        keyboardAllowed: false,
    },
    show: () => ({
        hide: noop,
        render: noop,
    }),
    refs: {},
};

const KeyboardContext = React.createContext<KeyboardContextType>(initialContext);

export const useKeyboardContext = () => useContext(KeyboardContext);

export default KeyboardContext;
