import * as api from '@yandex-int/k-common/client/api';
import { patchTeamSyncStatus } from '@yandex-int/k-common/client/api';
import {
    Me,
    GrantRightsRequest,
    Team,
    SYNC_STATUS,
    EXTERNAL_LMS_SLUGS,
    GEOBASE_REQUEST_TYPE,
    GeobaseRegionResponse,
    ParentProfile,
    MeViewedHints,
    TeacherProfile,
    Child,
} from '@yandex-int/k-common/typings';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import {
    showNetworkError,
    showIntlMessageNotification,
    addViewedHintSuccess as addViewedHintWidgetSuccess,
} from 'platform-components';
import { GRAVITIES, NOTIFICATION_THEMES } from 'platform-components/constants';
import {
    getIsTeacher,
    getWizardHints,
    getWizardVariables,
    getHints,
    getMe,
    getIsAuthenticated,
    getIsParent,
} from 'platform-components/src/components/user/selectors';
import { WIZARD } from 'platform-components/src/components/user/user.constants';
import { Action } from 'redux';
import { buffers } from 'redux-saga';
import {
    actionChannel,
    all,
    call,
    delay,
    put,
    select,
    take,
    takeLatest,
    takeEvery,
    ActionPattern,
} from 'redux-saga/effects';

import * as userActions from 'common.components/user/actions';
import { getClasses } from 'common.components/user/selectors';
import { actions as coursesActions } from 'store/courses/actions';
import { getTrimmedName } from 'utils/get-trimmed-name';

import {
    addViewedHint,
    addViewedHintSuccess,
    addViewedHintError,
    deleteClassSuccess,
    getArchivedClassesRequest,
    getArchivedClassesSuccess,
    setClassesRequest,
    setMeRequest,
    archivateClassSuccess,
    recoverClassSuccess,
    syncTeamRequest,
    syncTeamSuccess,
    syncTeamError,
    teamSyncStatusesSuccess,
    setWizardVariables,
    archivateClassRequest,
    recoverClassRequest,
    saveSalesCode,
    setTeams,
    setTeamsSuccess,
    setWizardHints,
    referalCodeRequest,
    referalCodeSuccess,
    referalCodeError,
    grantUserRightsRequest,
    grantUserRightsSuccess,
    grantUserRightsError,
    getLocalityNameSuccess,
    setTeacherProfile,
    setParentProfile,
    setParentChildren,
} from './actions';
import { DELETE_CLASS_ERROR_STATUSES, NOTIFICATION_CLOSE_DELAY } from './class.constants';
import {
    ADD_VIEWED_HINT_REQUEST,
    SET_WIZARD_VARIABLES,
    SET_WIZARD_HINTS,
    DELETE_CLASS_REQUEST,
    GET_ARCHIVED_CLASSES_REQUEST,
    RECOVER_CLASS_REQUEST,
    ARCHIVATE_CLASS_REQUEST,
    CLEAR_WIZARD_VARIABLES,
    SAVE_SALES_CODE,
    SYNC_TEAM_REQUEST,
    SET_CLASSES_REQUEST,
    REFERAL_CODE_REQUEST,
    GRANT_USER_RIGHTS_REQUEST,
    GET_LOCALITY_NAME_REQUEST,
} from './constants';
import { getWizardVariablesWhiteList, getSchoolGeoId } from './selectors';

const TEAM_SYNC_STATUS_POLLING_INTERVAL = 5000;

export function* userSagasWatcher() {
    yield all([
        addViewedHintSagaWatcher(),
        pollSyncingTeamsSaga(),
        takeLatest(SET_CLASSES_REQUEST, setTeamRequestSaga),
        takeLatest(DELETE_CLASS_REQUEST as any, deleteClassSaga), // TODO: ?
        takeLatest(GET_ARCHIVED_CLASSES_REQUEST, getArchivedClassesRequestSaga),
        takeLatest(ARCHIVATE_CLASS_REQUEST, archivateClassSaga),
        takeLatest(RECOVER_CLASS_REQUEST, recoverClassSaga),
        takeLatest(SAVE_SALES_CODE, saveSalesCodeSaga),
        takeEvery(SYNC_TEAM_REQUEST, syncTeamRequestSaga),
        takeLatest(REFERAL_CODE_REQUEST, sendReferalCode),
        takeLatest(GRANT_USER_RIGHTS_REQUEST, grantUserRightsSaga),
        takeLatest(GET_LOCALITY_NAME_REQUEST, getLocalityNameSaga),
    ]);
}

export function* ensureIAmParent() {
    const isParent: boolean = yield select(getIsParent);

    if (!isParent) {
        const me: Me = yield call(api.postMakeMeParent);
        yield put(userActions.setMe(me));

        const profile: ParentProfile = yield call(api.getParentProfile);
        yield put(userActions.setParentProfile(profile));
    }
}

export function* addViewedHintSagaWatcher() {
    const requestChan: ActionPattern | undefined = yield actionChannel(
        [SET_WIZARD_VARIABLES, CLEAR_WIZARD_VARIABLES, SET_WIZARD_HINTS, ADD_VIEWED_HINT_REQUEST],
        buffers.expanding(10)
    );

    while (true) {
        const action = (yield take(requestChan)) as Action as any;

        switch (action.type) {
            case SET_WIZARD_VARIABLES:
                yield call(setVariablesSaga, action);
                break;
            case CLEAR_WIZARD_VARIABLES:
                yield call(clearVariablesSaga);
                break;
            case SET_WIZARD_HINTS:
                yield call(setHintsSaga, action);
                break;
            case ADD_VIEWED_HINT_REQUEST:
                yield call(addViewedHintSaga, action);
                break;
            default:
                break;
        }
    }
}

function* pollTeamsUntilEverythingIsSynced() {
    let patchedTeamIds: number[] = [];

    while (true) {
        try {
            const teams: Array<Team> = yield select(getClasses) || [];

            const notReadyTeamIds = teams
                .filter((team) => {
                    return team.sync_status === SYNC_STATUS.SYNCING || team.sync_status === SYNC_STATUS.FIRST_SYNC;
                })
                .map((team) => team.id);

            if (notReadyTeamIds.length === 0) {
                return;
            }

            const { statuses } = yield call(api.getTeamSyncStatuses, notReadyTeamIds.join(','));

            const hasAnyTeamChangedStatus = notReadyTeamIds.some(
                (teamId) => statuses[teamId] !== SYNC_STATUS.SYNCING && statuses[teamId] !== SYNC_STATUS.FIRST_SYNC
            );

            if (hasAnyTeamChangedStatus) {
                yield put(setClassesRequest());
            }

            yield put(teamSyncStatusesSuccess(statuses));

            // eslint-disable-next-line no-loop-func
            const notPatchedTeamIds = notReadyTeamIds.filter((id) => !patchedTeamIds.includes(id));

            for (let i = 0; i < notPatchedTeamIds.length; i += 1) {
                yield call(patchTeamSyncStatus, {
                    lms: EXTERNAL_LMS_SLUGS.MES,
                    teamIds: [notPatchedTeamIds[i]],
                });
            }

            patchedTeamIds = patchedTeamIds.concat(notPatchedTeamIds);
        } catch (e) {
            // just ignore the error if it's network  error
            console.error(e);
        }

        yield delay(TEAM_SYNC_STATUS_POLLING_INTERVAL);
    }
}

function* pollSyncingTeamsSaga() {
    const TEAMS_STATUS_CHECKING_INTERVAL = 1000;

    if (isSSR) {
        return;
    }

    while (true) {
        // Поскольку мы можем уже открыть страницу с состоянием,
        // когда имеются какие-то классы в процессе синхронизации,
        // то мы должны запустить проверку на потребность в поллинге
        // сразу после старта приложения
        yield pollTeamsUntilEverythingIsSynced();

        yield delay(TEAMS_STATUS_CHECKING_INTERVAL);
    }
}

function* setVariablesSaga(action: ReturnType<typeof setWizardVariables>) {
    const wizardVariables = (yield select(getWizardVariables)) as unknown as any;
    const wizardHints = (yield select(getWizardHints)) as unknown as any;
    const mergedHints = { ...wizardVariables, ...action.hint };

    if (!isEqual(mergedHints, wizardVariables)) {
        const innerAction = addViewedHint(
            WIZARD,
            {
                variables: mergedHints,
                hints: wizardHints,
            },
            action.onSuccess
        );

        yield call(addViewedHintSaga, innerAction);
    } else if (action.onSuccess) {
        action.onSuccess();
    }
}

function* clearVariablesSaga() {
    const wizardVariables = (yield select(getWizardVariables)) as unknown as any;
    const whiteList = (yield select(getWizardVariablesWhiteList)) as unknown as any;
    const filteredVariables = pick(wizardVariables, whiteList);
    const innerAction = addViewedHint(WIZARD, { variables: filteredVariables, hints: {} });

    yield call(addViewedHintSaga, innerAction);
}

function* setHintsSaga(action: ReturnType<typeof setWizardHints>) {
    const wizardHints = (yield select(getWizardHints)) as unknown as any;
    const wizardsVariables = (yield select(getWizardVariables)) as unknown as any;
    const mergedHints = { ...wizardHints, ...action.hint };

    if (!isEqual(mergedHints, wizardHints)) {
        const innerAction = addViewedHint(WIZARD, { hints: mergedHints, variables: wizardsVariables });

        yield call(addViewedHintSaga, innerAction);
    }
}

function* addViewedHintSaga(action: ReturnType<typeof addViewedHint>) {
    const { id, data, onAddViewedHint } = action;

    try {
        const hints: MeViewedHints | undefined = yield select(getHints);
        const mergedHints = { ...hints, [id]: data };

        let newHints = mergedHints;

        // блокируем сохранение во viewed_hints для не аутентифицированных пользователей
        const isAuthenticatedUser: boolean = yield select(getIsAuthenticated);

        if (!isEqual(mergedHints, hints) && isAuthenticatedUser) {
            newHints = yield call(api.postSetViewedHints, mergedHints);
        }

        yield put(addViewedHintSuccess(newHints as any, id));
        yield put(addViewedHintWidgetSuccess());

        if (onAddViewedHint) {
            onAddViewedHint();
        }
    } catch (error) {
        console.error(error);
        yield put(addViewedHintError(error, id));
        yield put(showNetworkError(error));
    }
}

function* setTeamRequestSaga() {
    try {
        const teams: Team[] = yield call(api.getClasses);

        // Нам нужно знать список предметов, чтобы включать некоторые фичи
        // только тем, у кого есть определенный предмет
        // При изменении классов список предметов может меняться
        yield put(coursesActions.getCoursesSubjectsRequest());

        yield put(setTeams(teams));
        yield put(setTeamsSuccess());
    } catch (error) {
        yield put(showNetworkError(error));
    }
}

export function* deleteClassSaga(action: Awaited<ReturnType<typeof api.deleteClass>>) {
    const { classItem } = action;
    const id = get(classItem, 'id');
    const level = get(classItem, 'level');
    const name = getTrimmedName(get(classItem, 'name'));

    try {
        yield call(api.deleteClass, id);
        yield put(deleteClassSuccess(id));
        yield put(coursesActions.getCoursesSubjectsRequest());
        yield put(
            showIntlMessageNotification({
                closeDelay: NOTIFICATION_CLOSE_DELAY,
                gravity: GRAVITIES.BOTTOM,
                messageId: 'toast.class.deleted',
                messageValues: { name, level },
                withExplicitClose: true,
                theme: NOTIFICATION_THEMES.TOAST,
            })
        );
    } catch (error) {
        const { status } = error;

        switch (status) {
            case DELETE_CLASS_ERROR_STATUSES.CLASS_HAS_STUDENTS:
                yield put(
                    showIntlMessageNotification({
                        messageId: 'class.deleteErrorMessage.hasStudents',
                        theme: NOTIFICATION_THEMES.FAIL,
                        closeDelay: NOTIFICATION_CLOSE_DELAY,
                    })
                );
                break;
            case DELETE_CLASS_ERROR_STATUSES.ONLY_CLASS:
                yield put(
                    showIntlMessageNotification({
                        messageId: 'class.deleteErrorMessage.onlyClass',
                        theme: NOTIFICATION_THEMES.FAIL,
                        closeDelay: NOTIFICATION_CLOSE_DELAY,
                    })
                );
                break;
            default:
                yield put(showNetworkError(error));
        }
    }
}

export function* getArchivedClassesRequestSaga() {
    try {
        const archivedClasses: Team[] = yield call(api.getArchivedClasses);

        yield put(getArchivedClassesSuccess(archivedClasses));
    } catch (error) {
        yield put(showNetworkError(error));
    }
}

function* archivateClassSaga(action: ReturnType<typeof archivateClassRequest>) {
    try {
        const { classItem, rollback } = action;
        const id = get(classItem, 'id');
        const level = get(classItem, 'level');
        const name = getTrimmedName(get(classItem, 'name'));

        yield call(api.patchClass, id, { active: false });
        yield put(setClassesRequest());
        yield put(getArchivedClassesRequest());
        yield put(archivateClassSuccess());

        yield put(
            showIntlMessageNotification({
                closeDelay: NOTIFICATION_CLOSE_DELAY,
                gravity: GRAVITIES.BOTTOM,
                messageId: 'toast.class.archived',
                messageValues: { name, level },
                notificationAction: rollback,
                notificationMessageId: 'toast.class.recover',
                withExplicitClose: true,
                theme: NOTIFICATION_THEMES.TOAST,
            })
        );
    } catch (error) {
        yield put(showNetworkError(error));
    }
}

function* recoverClassSaga(action: ReturnType<typeof recoverClassRequest>) {
    try {
        const { classItem } = action;
        const id = get(classItem, 'id');
        const level = get(classItem, 'level');
        const name = getTrimmedName(get(classItem, 'name'));

        yield call(api.patchClass, id, { active: true });
        yield put(setClassesRequest());
        yield put(getArchivedClassesRequest());
        yield put(recoverClassSuccess());

        yield put(
            showIntlMessageNotification({
                closeDelay: NOTIFICATION_CLOSE_DELAY,
                gravity: GRAVITIES.BOTTOM,
                messageId: 'toast.class.recovered',
                messageValues: { name, level },
                withExplicitClose: true,
                theme: NOTIFICATION_THEMES.TOAST,
            })
        );
    } catch (error) {
        yield put(showNetworkError(error));
    }
}

function* saveSalesCodeSaga(action: ReturnType<typeof saveSalesCode>) {
    try {
        const { salesCode } = action;
        const isTeacher: boolean = yield select(getIsTeacher);

        if (!isTeacher) {
            return;
        }

        yield call(api.postSetEventCode, salesCode);
        yield put(setMeRequest());

        const profileWithNewCode: TeacherProfile = yield call(api.getTeacherProfile);
        yield put(setTeacherProfile(profileWithNewCode));
    } catch (error) {
        console.error(error);
        yield put(showNetworkError(error));
    }
}

function* syncTeamRequestSaga(action: ReturnType<typeof syncTeamRequest>) {
    const { teamId } = action;

    try {
        yield call(api.patchTeamSyncStatus, { lms: EXTERNAL_LMS_SLUGS.MES, teamIds: [teamId] });

        // сага, а не экшн, потому что нужно сбрасывать статус loading
        // только после получения обновленного списка классов
        yield setTeamRequestSaga();

        yield put(syncTeamSuccess(teamId));
    } catch (error) {
        console.error(error);
        yield put(showNetworkError(error));
        yield put(syncTeamError(teamId));
    }
}

function* sendReferalCode(action: ReturnType<typeof referalCodeRequest>) {
    try {
        const { referalCode, url } = action;
        yield call(api.postReferalCode, { referalCode, url });

        yield put(referalCodeSuccess());
    } catch (error) {
        console.error(error);
        yield put(referalCodeError());
    }
}

function* grantUserRightsSaga(action: ReturnType<typeof grantUserRightsRequest>) {
    const { userRights } = action;
    try {
        const me: Me | null = yield select(getMe);
        const query: GrantRightsRequest = { meId: me?.id || 0, userRights };
        const { is_teacher: isTeacher, is_parent: isParent } = me || {};
        if (isTeacher) {
            const newProfile: TeacherProfile = yield call(api.patchGrantTeacherRights, query);
            yield put(setTeacherProfile(newProfile));
        } else if (isParent) {
            const newProfile: ParentProfile = yield call(api.patchGrantParentRights, query);
            yield put(setParentProfile(newProfile));
            const children: Child[] = yield call(api.getParentChildren);
            yield put(setParentChildren(children));
        } else {
            throw Error(`Unsupported user: id ${me?.id}`);
        }

        yield put(grantUserRightsSuccess());
    } catch (e) {
        console.error(e);
        yield put(grantUserRightsError());
        yield put(showNetworkError());
    }
}

function* getLocalityNameSaga() {
    try {
        const geoId: number = yield select(getSchoolGeoId);
        const result: GeobaseRegionResponse = yield call(api.getGeobaseData, geoId, GEOBASE_REQUEST_TYPE.REGION);

        yield put(getLocalityNameSuccess(result.name));
    } catch (error) {
        console.error(error);
        yield put(showNetworkError());
    }
}
