import * as React from 'react';
const SvgAlarm = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.34 20L12 5l8.66 15H3.34zm7.794-17.5a1 1 0 011.732 0l10.392 18a1 1 0 01-.866 1.5H1.608a1 1 0 01-.866-1.5l10.392-18zm1.287 7.5h-.842a.75.75 0 00-.747.825l.373 3.724a.5.5 0 00.497.45h.595a.5.5 0 00.498-.45l.373-3.724A.75.75 0 0012.42 10zM12 15.89c-.648 0-1.14.469-1.14 1.14 0 .673.492 1.14 1.14 1.14.648 0 1.14-.467 1.14-1.14 0-.671-.492-1.14-1.14-1.14z"
            fill="#DD3030"
        />
    </svg>
);
export default SvgAlarm;
