export enum YM_LOCATION {
    CLASS = 'my_classes',
    LESSON_VIEW = 'lesson_creation',
    OLYMP = 'olymp',
    NEW_TEAM = 'new_class',
    ONE_MORE_SUBJECT = 'one_more_subject',
    FROM_EMPTY_STATE = 'from_empty_state',
    MY_LESSONS = 'my_lessons',
    LIBRARY = 'library',
}
