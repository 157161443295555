import fetch from 'isomorphic-fetch';
import { stringify, parse } from 'query-string';
import { v4 as uuidv4 } from 'uuid';

import { HTTP_METHODS, NO_CACHE } from './constants';
import { apiConfig } from './config';

function isObject(variable) {
    return typeof variable === 'object' && !Array.isArray(variable) && variable !== null;
}

function handleResponsePromise(responsePromise, httpMethod) {
    return responsePromise
        .then((response) => {
            const contentType = response.headers.get('Content-Type');

            if (contentType && contentType.includes('application/json')) {
                return Promise.all([response, response.json()]);
            }

            return Promise.all([response, response.text()]);
        })
        .catch((error) => error)
        .then(([response, parsedData]) => {
            const { ok, status, statusText = 'No response' } = response || {};

            if (ok) {
                return parsedData;
            }

            const error = new Error();

            Object.assign(error, {
                data: parsedData,
                status,
                statusText,
                httpMethod,
            });

            throw error;
        });
}

function getRequestOptions({ body, method, options = {} }) {
    const { srcrwr } = parse(window.location.search);

    const headers = {
        'X-Correlation-ID': uuidv4(),
    };

    if (srcrwr) {
        headers.srcrwr = JSON.stringify(srcrwr);
    }

    const commonOptions = {
        ...options,
        credentials: 'same-origin',
        method,
        headers,
    };

    if (!body) {
        return commonOptions;
    } else if (body instanceof FormData) {
        return {
            ...commonOptions,
            body,
        };
    }

    return {
        ...commonOptions,
        headers: {
            ...commonOptions.headers,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    };
}

export function postFetch(url, body = {}, options) {
    if (typeof window === 'undefined') {
        return Promise.reject({
            message: `HTTP request METHOD ${HTTP_METHODS.POST} URL ${url} is prohibited on server side`,
        });
    }

    const secretKey = apiConfig.getSecretKey();
    const method = HTTP_METHODS.POST;

    let requestBody = body;
    if (requestBody instanceof FormData) {
        if (!requestBody.get('sk')) {
            requestBody.append('sk', secretKey);
        }
    } else if (isObject(body)) {
        requestBody = { ...requestBody, sk: secretKey };
    }

    const autotestsParams = getAutotestsParams();
    const stringArgs = stringify({ ...autotestsParams });
    if (stringArgs) {
        url += `?${stringArgs}`;
    }

    const requestOptions = getRequestOptions({ body: requestBody, method, options });
    requestOptions.headers['x-csrf-token'] = secretKey;
    const responsePromise = fetch(url, requestOptions);

    return handleResponsePromise(responsePromise, method);
}

function getAutotestsParams() {
    if (typeof window !== 'undefined') {
        const searchDir = new URLSearchParams(window.location.search);
        const testDir = searchDir.get('__testDir');
        const testName = searchDir.get('__testName');
        const isSequential = searchDir.get('__testSequential');

        return Object.assign(
            {},
            testDir && { __testDir: testDir },
            testName && { __testName: testName },
            isSequential && { __testSequential: isSequential }
        );
    }

    return {};
}

export function getFetch(url, args = {}, options = { cache: NO_CACHE }) {
    if (typeof window === 'undefined') {
        return Promise.reject({
            message: `HTTP request METHOD ${HTTP_METHODS.GET} URL ${url} is prohibited on server side`,
        });
    }

    const autotestsParams = getAutotestsParams();

    const stringArgs = stringify({ ...args, ...autotestsParams });
    if (stringArgs) {
        url += `?${stringArgs}`;
    }

    const method = HTTP_METHODS.GET;
    const responsePromise = fetch(url, getRequestOptions({ method, options }));

    return handleResponsePromise(responsePromise, method);
}
