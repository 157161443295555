import { withBemMod } from '@bem-react/core';
import { cnButton } from '@yandex-lego/components/Button';

import { BUTTON_VIEW } from '../types';

export interface ButtonViewPlusProps {
    view?: BUTTON_VIEW.PLUS;
}

export const withViewPlus = withBemMod<ButtonViewPlusProps>(cnButton(), { view: BUTTON_VIEW.PLUS });
