import b from 'b_';
import cx from 'classnames';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import { injectIntl } from 'react-intl';

import { KEY_CODES, POPUP_AXIS, POPUP_DIRECTIONS, POPUP_INDENTS, POPUP_POSITIONS, POPUP_THEMES } from '../constants';
import Input from '../input/input';
import Menu, { Item as MenuItem } from '../menu/menu';
import { MENU_THEMES } from '../menu/typings';

import { A11yHidden } from '../A11yHidden';
import SelectPopup from './__popup/select__popup';
import { SELECT_THEMES } from './constants';
import { heightValidate } from './utils';

import './select.scss';
import './select_theme/index.scss';

const THEME_MARKER_WIDTH_OFFSET = 24; // ширина стрелочки
const THEME_FILTER_WIDTH_OFFSET = 32;
const DEFAULT_WIDTH_OFFSET = 20;
export const LETTER_WIDTH = 10;

class SelectComponent extends React.Component {
    static propTypes = {
        additionalArrowOffset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
        closeMenuOnOuterScrollAndResize: PropTypes.bool,
        popupDirection: PropTypes.oneOf(Object.values(POPUP_DIRECTIONS)),
        disabled: PropTypes.bool,
        disallowEmpty: PropTypes.bool,
        errorMessage: PropTypes.string,
        focused: PropTypes.bool,
        height: heightValidate,
        inLayer: PropTypes.bool,
        letterWidth: PropTypes.number,
        matchPos: PropTypes.string,
        matchProp: PropTypes.string,
        minWidth: PropTypes.string,
        maxWidth: PropTypes.string,
        mix: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        name: PropTypes.string,
        noClear: PropTypes.bool,
        noResultsText: PropTypes.string,
        notFocusAfterUpdate: PropTypes.bool,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
        onChange: PropTypes.func,
        onInput: PropTypes.func,
        onResize: PropTypes.func,
        onSelect: PropTypes.func,
        onKeyDown: PropTypes.func,
        placeholder: PropTypes.string,
        popupMix: PropTypes.string,
        popupMinWidth: PropTypes.string,
        popupMaxWidth: PropTypes.string,
        popupAxis: PropTypes.oneOf(Object.values(POPUP_AXIS)),
        popupPosition: PropTypes.oneOf(Object.values(POPUP_POSITIONS)),
        searchable: PropTypes.bool,
        size: PropTypes.string,
        successMessage: PropTypes.string,
        theme: PropTypes.oneOf(Object.values(SELECT_THEMES)),
        valid: PropTypes.bool,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        intl: PropTypes.object,
    };

    static defaultProps = {
        disabled: false,
        children: [],
        inLayer: false,
        letterWidth: LETTER_WIDTH,
        maxWidth: null,
        matchPos: 'any',
        matchProp: 'text',
        noResultsText: 'Ничего не найдено',
        placeholder: 'Выберите…',
        searchable: false,
        size: 'm',
        theme: 'normal',
    };

    constructor(props) {
        super(props);

        this.state = {
            // из старого компонента
            focusedOption: null,
            opened: false,
            options: this.getOptionsFromChildren(this.props),
            maxHeight: null,
            // новые ключи
            popupWidth: null,
        };

        this.select = React.createRef();
        this.selectMenu = React.createRef();
        this.selectPopup = React.createRef();
    }

    UNSAFE_componentWillMount() {
        this._optionsFilterString = '';
        const value = isNil(this.props.value) ? '' : String(this.props.value);
        // получаем начальное состояние, преобразуем `value` в строку, на случай если передали число
        this.setState(this.getStateFromValue(value));
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const options = this.getOptionsFromChildren(nextProps);

        this.setState({
            options,
            filteredOptions: this.filterOptions(options),
        });

        if (nextProps.value !== undefined) {
            const value = isNil(nextProps.value) ? '' : String(nextProps.value);
            this.setState(this.getStateFromValue(value, options));
        }
    }

    UNSAFE_componentWillUpdate() {
        if (this._deselectInputBeforeUpdate) {
            // Убираем селект и ставим курсор в начало строки
            const input = this.input.control;

            input.selectionStart = input.selectionEnd = 0;
            this._deselectInputBeforeUpdate = false;
        }
    }

    /* eslint-disable complexity */
    componentDidUpdate() {
        const { disabled } = this.props;

        if (!disabled && this._focusAfterUpdate) {
            clearTimeout(this._blurTimeout);

            this._focusTimeout = setTimeout(() => {
                this.input.focus();
                this._focusAfterUpdate = false;
            }, 50);
        }

        if (!disabled && this._selectInputAfterUpdate) {
            this._selectTimeout = setTimeout(() => {
                this.input.control.select();
                this._selectInputAfterUpdate = false;
            }, 50);
        }
    }
    /* eslint-enable complexity */

    componentWillUnmount() {
        clearTimeout(this._blurTimeout);
        clearTimeout(this._focusTimeout);
        clearTimeout(this._selectTimeout);

        this._removeEventsListeners();
    }

    _closeMenuIfClickedOutside = ({ target }) => {
        if (!this.state.opened) {
            return;
        }

        const inputNode = ReactDOM.findDOMNode(this.input);
        const clickedInsideSearchInput = inputNode && inputNode.contains(target);

        if (!clickedInsideSearchInput) {
            this.hideMenu();
            this.setState({ opened: false }, this._removeEventsListeners);
        }
    };

    _closeMenuOnOuterScrollAndResize = (e) => {
        const { closeMenuOnOuterScrollAndResize } = this.props;
        const popupElement = this.selectPopup.current?.selectPopup?.popup;

        if (closeMenuOnOuterScrollAndResize && popupElement && e.target !== popupElement) {
            this.setState({ opened: false }, this._removeEventsListeners);
            this.hideMenu();
        }
    };

    _addEventsListeners = () => {
        document.body.addEventListener('click', this._closeMenuIfClickedOutside);
        document.body.addEventListener('scroll', this._closeMenuOnOuterScrollAndResize, {
            passive: true,
            capture: true,
        });
        window.addEventListener('resize', this._closeMenuOnOuterScrollAndResize, { passive: true, capture: true });
    };
    _removeEventsListeners = () => {
        document.body.removeEventListener('click', this._closeMenuIfClickedOutside);
        document.body.removeEventListener('scroll', this._closeMenuOnOuterScrollAndResize, {
            passive: true,
            capture: true,
        });
        window.removeEventListener('resize', this._closeMenuOnOuterScrollAndResize, { passive: true, capture: true });
    };

    onInputRef = (ref) => {
        this.input = ref;
    };

    onControlRef = (ref) => {
        this.control = ref;
    };

    // select__popup делает пререндер в скрытую ноду
    // после дергается этот колбэк
    handlePopupSize = (domNodeProps) => {
        const { onResize } = this.props;

        if (onResize) {
            onResize(domNodeProps.clientWidth);
        }
        this.setState({ popupWidth: domNodeProps.clientWidth });
    };

    getOptionsFromChildren = (props) => {
        let options;

        // сразу преобразуем в string параметр value у опций
        if (Array.isArray(props.children)) {
            options = props.children.map((option) => {
                return React.cloneElement(option, { value: String(option.props.value) });
            });
        } else {
            options = [React.cloneElement(this.props.children, { value: String(props.children.props.value) })];
        }

        return options;
    };

    getStateFromValue = (value, options) => {
        if (!options) {
            options = this.state.options;
        }

        this._optionsFilterString = '';
        const filteredOptions = this.filterOptions(options);
        value = this.initValue(value, options);

        return {
            value: value ? value.props.value : '',
            inputValue: value ? value.props.children : '',
            filteredOptions: filteredOptions,
            placeholder: value ? value.props.children : this.props.placeholder,
            focusedOption: value ? value : this.getNewFocusedOption(filteredOptions),
        };
    };

    initValue = (value, options) => {
        // если value - строка, то ищем опцию с таким значением атрибута value
        if (typeof value === 'string' && value) {
            for (let key in options) {
                if (options.hasOwnProperty(key) && options[key] && options[key].props.value === value) {
                    return options[key];
                }
            }

            return <MenuItem value={value}>{value}</MenuItem>;
        }

        // передали react-элемент просто возвращаем его
        return value;
    };

    filterOptions = (options) => {
        if (!this.props.searchable) {
            return options;
        }

        const filterValue = this._optionsFilterString;
        return options.filter((option) => {
            const value = option.props.value;
            const text = String(option.props.children);

            // TODO refactor plz
            return !filterValue || this.props.matchPos === 'start'
                ? (this.props.matchProp !== 'text' &&
                      value.toLowerCase().substr(0, filterValue.length) === filterValue) ||
                      (this.props.matchProp !== 'value' &&
                          text.toLowerCase().substr(0, filterValue.length) === filterValue)
                : (this.props.matchProp !== 'text' && value.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0) ||
                      (this.props.matchProp !== 'value' && text.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0);
        }, this);
    };

    emitChange = (value) => {
        if (value !== this.state.value && this.props.onChange) {
            this.props.onChange({ target: { value: value } });
        }
    };

    emitSelect = (value) => {
        const { onSelect } = this.props;

        if (onSelect) {
            onSelect({ target: { value } });
        }
    };

    showMenu = () => {
        this.selectPopup.current.openPopup();
    };

    hideMenu = () => {
        this.selectPopup.current.closePopup();
        this.openAfterFocus = false;
    };

    // используется только select control
    handleClick = (e) => {
        if (this.props.disabled) {
            return;
        }

        e.stopPropagation();

        if (this.props.searchable) {
            this._selectInputAfterUpdate = true;

            if (this._reFilterOptionsBeforeShow) {
                const filteredOptions = this.filterOptions(this.state.options);

                this.setState({
                    filteredOptions: filteredOptions,
                    focusedOption: this.getNewFocusedOption(filteredOptions),
                });
                this._reFilterOptionsBeforeShow = false;
            }
        } else {
            e.preventDefault();
        }

        // Фокусируемся на инпуте, фокус нужен для использования клавиш.
        if (this.state.focused || !this.props.searchable) {
            if (this.state.opened) {
                this.setState({ opened: false }, this._removeEventsListeners);
                this.hideMenu();
            } else {
                this.showMenu();
                this.setState({ opened: true }, this._addEventsListeners);
            }
        } else {
            this.openAfterFocus = true;

            if (this.input.focus) {
                this.input.focus();
            }
            if (this.input.click) {
                this.input.click();
            }
        }
    };

    // handleInputFocus, handleInputBlur, handleInputChange используются только инпутом
    handleInputFocus = () => {
        const opened = this.state.opened || this.openAfterFocus;

        if (opened) {
            this.showMenu();
        }

        this.setState({ focused: true, opened }, () => {
            if (opened) {
                this._addEventsListeners();
            } else {
                this._removeEventsListeners();
            }
        });
        this.openAfterFocus = false;

        if (this.props.onFocus) {
            this.props.onFocus({ target: { value: this.state.value } });
        }
    };

    handleInputBlur = () => {
        // Фокус может исчезнуть из-за выбора опции, тогда нужно возратить фокус на "инпут".
        // Или пользователь сам переместил фокус,
        // тогда нужно перевести селект  в несфокусированное состояние.
        // Ставим задержку, чтобы успели выполниться предшествующие действия.
        this._blurTimeout = setTimeout(() => {
            if (this._focusAfterUpdate) {
                return;
            }
            this.setState({ focused: false });
        }, 50);

        if (this.props.searchable) {
            this._deselectInputBeforeUpdate = true;

            if (this.state.inputValue) {
                if (this.state.value && this.state.inputValue !== this.state.placeholder) {
                    // введен текст, который не соответсвует текущему выбору, востанавливаем ввод в ипунте
                    this.setState({ inputValue: this.state.placeholder });
                } else if (!this.state.value && this.state.inputValue !== this.props.placeholder) {
                    // введен текст, но текущего выбора нет, востанавливаем изначальный плейсхолдер
                    this.setState({ inputValue: '', placeholder: this.props.placeholder });
                }

                // перед следующим открытием нужно заново отфильтровать опции
                this._optionsFilterString = '';
                this._reFilterOptionsBeforeShow = true;
            } else if (this.props.disallowEmpty) {
                // текст удален, востанавливаем ввод в ипунте
                this.setState({ inputValue: this.state.placeholder });
            }
        }

        if (this.props.onBlur) {
            this.props.onBlur({ target: { value: this.state.value } });
        }
    };

    handleInputChange = (e) => {
        const {
            target: { value },
        } = e;
        let nextState = {};

        if (!this.props.disallowEmpty && value === '') {
            // смена опции и вызов обработчика, подписанного на это событие,
            // происходит при непосредственном клике на опцию в меню селекта,
            // если же поле ввода было полностью очищено, то воспринимаем это как удаление выбора
            // и вызываем подписавшийся обработчик
            nextState.value = value;
            this.emitChange(value);
        }

        this._optionsFilterString = value.toLowerCase();
        const filteredOptions = this.filterOptions(this.state.options);
        this.showMenu();
        nextState = Object.assign(nextState, {
            opened: true,
            inputValue: value,
            filteredOptions: filteredOptions,
            focusedOption: this.getNewFocusedOption(filteredOptions),
        });
        this.setState(nextState, this._addEventsListeners);

        if (this.props.onInput) {
            this.props.onInput(e);
        }
    };

    getNewFocusedOption = (filteredOptions) => {
        for (let key in filteredOptions) {
            if (filteredOptions.hasOwnProperty(key) && filteredOptions[key] === this.state.focusedOption) {
                return filteredOptions[key];
            }
        }

        // фильтруем задизейбленные опции, чтобы не зафокусить их
        const options = filteredOptions.filter((option) => !option.props.disabled);

        return options.length ? options[0] : null;
    };

    handleArrowClick = (e) => {
        if (!this.state.opened) {
            return;
        }

        e.stopPropagation();

        this.setState({ opened: false }, this._removeEventsListeners);
        this.hideMenu();
    };

    // используется только select control
    /*eslint-disable complexity*/
    handleKeyDown = (e) => {
        const { onKeyDown, disabled, searchable, notFocusAfterUpdate } = this.props;

        if (disabled) {
            return;
        }

        const { opened } = this.state;

        switch (e.keyCode) {
            case KEY_CODES.TAB:
                // закрываем меню, затем выполняется нативное поведение при нажатии на tab
                if (opened) {
                    this.setState({ opened: false }, this._removeEventsListeners);
                    this.hideMenu();
                }
                return;

            case KEY_CODES.ENTER:
            case KEY_CODES.SPACE:
                if (opened) {
                    // в открытом селекте с саджестом пробел работает на ввод текста
                    if (e.keyCode === KEY_CODES.SPACE && searchable) {
                        return;
                    }
                    this.selectFocusedOption(notFocusAfterUpdate);
                } else if (!searchable) {
                    this.showMenu();
                    this.setState({ opened: true }, this._addEventsListeners);
                }
                break;

            case KEY_CODES.ESCAPE:
                this.hideMenu();
                break;

            case KEY_CODES.ARROW_UP:
                this.focusPreviousOption();
                break;

            case KEY_CODES.ARROW_DOWN:
                this.focusNextOption();
                break;

            default:
                return;
        }

        if (onKeyDown) {
            onKeyDown(e, opened);
        }

        e.preventDefault();
    };
    /*eslint-enable complexity*/

    selectFocusedOption = (notFocusAfterUpdate) => {
        if (this.state.focusedOption) {
            this.selectValue(this.state.focusedOption.props.value, notFocusAfterUpdate);
        }
    };

    focusNextOption = () => {
        this.focusAdjacentOption('next');
    };

    focusPreviousOption = () => {
        this.focusAdjacentOption('previous');
    };

    focusAdjacentOption = (direction) => {
        const options = this.state.filteredOptions.filter((option) => !option.props.disabled);

        // Была нажата одна из стрелок при закрытом меню, но фокус был на "инпуте",
        // нужно поставить "фокус" на сфокусированную опцию до закрытия или на одну из крайних.
        if (!this.state.opened) {
            this.showMenu();
            this.setState(
                {
                    opened: true,
                    focusedOption: this.state.focusedOption || options[direction === 'next' ? 0 : options.length - 1],
                },
                this._addEventsListeners
            );
            return;
        }

        if (!options.length) {
            return;
        }

        const focusedIndex = options.indexOf(this.state.focusedOption);
        let focusedOption = options[0];

        if (direction === 'next' && focusedIndex !== -1 && focusedIndex < options.length - 1) {
            focusedOption = options[focusedIndex + 1];
        } else if (direction === 'previous') {
            if (focusedIndex > 0) {
                focusedOption = options[focusedIndex - 1];
            } else {
                focusedOption = options[options.length - 1];
            }
        }
        this.setState({ focusedOption: focusedOption });
    };

    setValue = (value, notFocusAfterUpdate) => {
        const nextState = this.getStateFromValue(value);

        if (!notFocusAfterUpdate) {
            this._focusAfterUpdate = true;
        }
        nextState.opened = false;
        this.emitChange(value);
        this.hideMenu();
        this.setState(nextState);
    };

    focusOption = (option) => {
        this.setState({ focusedOption: option });
    };

    unfocusOption = (option) => {
        if (this.state.focusedOption === option && this.state.opened) {
            this.setState({ focusedOption: null });
        }
    };

    selectValue = (value, notFocusAfterUpdate) => {
        this.setValue(value, notFocusAfterUpdate);
        this.emitSelect(value);
        this._removeEventsListeners();
    };

    renderOptions = ({ isA11y } = {}) => {
        const filteredOptions = this.state.filteredOptions;
        let focusedValue = this.state.focusedOption ? this.state.focusedOption.props.value : null;

        if (Array.isArray(filteredOptions) && filteredOptions.length) {
            focusedValue = focusedValue === null ? this.getNewFocusedOption(filteredOptions) : focusedValue;

            const selectedOptionIndex = isNaN(parseInt(this.state.value, 10)) ? -1 : Number(this.state.value);
            const selectedOptionValue = this.state.value;

            let options;

            if (this.props.popupPosition === POPUP_POSITIONS.CONTROL) {
                const selectedOption = filteredOptions[selectedOptionIndex];

                options = [selectedOption, ...filteredOptions];
                options.splice(selectedOptionIndex + 1, 1);
            } else {
                options = filteredOptions;
            }

            return React.Children.map(
                options,
                (option) => {
                    const disabled = option.props.disabled;
                    const value = option.props.value;
                    const focused = focusedValue === value;
                    const selected = selectedOptionValue === value;

                    return React.cloneElement(option, {
                        focused: focused,
                        selected: selected,
                        key: 'item-' + option.props.value,
                        onClick: !disabled ? this.selectValue.bind(this, value) : null,
                        onMouseEnter: !disabled ? this.focusOption.bind(this, option) : null,
                        onMouseLeave: !disabled ? this.unfocusOption.bind(this, option) : null,
                        role: 'option',
                        isA11y,
                        ref: (component) => {
                            if (component) {
                                const node = ReactDOM.findDOMNode(component);
                                const boundingRect = node.getBoundingClientRect();
                                this.optionHeight = boundingRect.height;
                                if (focused) {
                                    this.focusedOption = node;
                                }

                                if (selected) {
                                    this.selectedOption = node;
                                }
                            }
                        },
                        value: value,
                    });
                },
                this
            );
        }

        return (
            <MenuItem disabled role="option">
                {this.props.noResultsText}
            </MenuItem>
        );
    };

    // Определяет необходимую ширину селекта.
    // логика расчета ширины от длины строк * ширину символа была выпилена
    // в рамках EDUCATION-6436
    getPopupWidthWithOffset = () => {
        const { width, theme, popupPosition } = this.props;

        if (!isNil(width)) {
            return width;
        }
        if (theme === SELECT_THEMES.LINK) {
            return 'unset';
        }

        const offset = this.getRightOffset();

        if (popupPosition === POPUP_POSITIONS.PORTAL_LAYER) {
            return this.state.popupWidth ? this.state.popupWidth : offset;
        }

        return this.state.popupWidth ? this.state.popupWidth + offset : offset;
    };

    // смещение из-за символа стрелочки
    getRightOffset = () => {
        const { theme, additionalArrowOffset } = this.props;
        let res;

        switch (theme) {
            case SELECT_THEMES.LINK:
                res = 0;
                break;

            case SELECT_THEMES.MARKER:
                res = THEME_MARKER_WIDTH_OFFSET;
                break;

            case SELECT_THEMES.NORMAL:
            case SELECT_THEMES.TEACHER:
            case SELECT_THEMES.ROUNDED:
                res = 0;
                break;

            case SELECT_THEMES.FILTER:
                res = THEME_FILTER_WIDTH_OFFSET;
                break;

            default:
                res = DEFAULT_WIDTH_OFFSET;
        }

        if (additionalArrowOffset) {
            return typeof additionalArrowOffset === 'number'
                ? res + additionalArrowOffset
                : `calc(${res}px + ${additionalArrowOffset})`;
        }

        return res;
    };

    // вычисляем стиль селекта в зависимости от пропсов
    getSelectStyle = () => {
        const { maxWidth, minWidth, width } = this.props;

        const selectWidth = width || this.getPopupWidthWithOffset();

        return {
            width: selectWidth,
            minWidth,
            maxWidth,
        };
    };

    handleCleanIconClick = (event) => {
        event.stopPropagation();

        this.selectValue(null, true);
    };

    getPopupTheme = () => {
        const { theme } = this.props;

        switch (theme) {
            case SELECT_THEMES.SCHOOL:
                return POPUP_THEMES.SCHOOL;
            default:
                return POPUP_THEMES.CLEAN;
        }
    };

    getPopupIndent() {
        const { theme } = this.props;

        switch (theme) {
            case SELECT_THEMES.FILTER:
                return POPUP_INDENTS.NOINDENT;
            case SELECT_THEMES.MARKER:
                return POPUP_INDENTS.SMALL;
            default:
                return POPUP_INDENTS.NORMAL;
        }
    }

    getMenuTheme = () => {
        const { theme } = this.props;

        if (theme === SELECT_THEMES.FILTER) {
            return MENU_THEMES.SCHOOL;
        }

        // ищем соответствующую тему меню, но переопределяем дефолт на YELLOW
        const applySelectThemeToMenu = theme !== MENU_THEMES.NORMAL && Object.values(MENU_THEMES).includes(theme);
        return applySelectThemeToMenu ? theme : MENU_THEMES.YELLOW;
    };

    render() {
        const {
            disabled,
            errorMessage,
            maxWidth,
            minWidth,
            mix,
            name,
            height,
            inLayer,
            popupDirection,
            popupMix,
            popupMinWidth,
            popupMaxWidth,
            popupAxis,
            popupPosition,
            searchable,
            size,
            successMessage,
            theme,
            valid,
            width,
            intl,
        } = this.props;
        const { focused, inputValue, maxHeight, opened, placeholder, value } = this.state;
        const className = b('select', {
            disabled,
            empty: isNil(value) || value === '',
            focused,
            opened,
            searchable,
            selected: Boolean(value),
            size,
            theme,
            valid,
        });
        const inputProps = {
            onFocus: this.handleInputFocus,
            onBlur: this.handleInputBlur,
            ref: this.onInputRef,
            tabIndex: 0,
            'data-switchable': true,
        };

        const style = this.getSelectStyle();

        const direction = popupPosition === POPUP_POSITIONS.TOP ? POPUP_DIRECTIONS.TOP : popupDirection;

        const menuMinWidth = popupPosition === POPUP_POSITIONS.PORTAL_LAYER ? undefined : minWidth;

        const shouldShowCleanIcon = Boolean(theme === SELECT_THEMES.FILTER && inputValue);

        return (
            <div className={cx(className, mix)} ref={this.select} style={style}>
                <input disabled={disabled} name={name} type="hidden" value={value} />
                <div
                    className="select__control"
                    onClick={this.handleClick}
                    onKeyDown={this.handleKeyDown}
                    onTouchEnd={this.handleClick}
                    ref={this.onControlRef}
                    style={{ paddingRight: this.getRightOffset() }}
                >
                    {searchable && !disabled ? (
                        <Input
                            /* если инпут будет заново сфокусирован,
                               то нужно сразу добавить класс .input_focused, чтобы не было моргания */
                            mix={this._focusAfterUpdate ? 'input_focused' : ''}
                            onChange={this.handleInputChange}
                            placeholder={inputValue ? null : placeholder}
                            size={size}
                            value={inputValue}
                            {...inputProps}
                        />
                    ) : (
                        [
                            <div
                                aria-label={intl.formatMessage({ id: 'a11y.chooseVariant' })}
                                className="select__placeholder"
                                key="select__placeholder"
                                role="listbox"
                            >
                                <A11yHidden
                                    as="ol"
                                    onClick={(e) => e.stopPropagation()}
                                    onKeyDown={(e) => e.stopPropagation()}
                                    onTouchEnd={(e) => e.stopPropagation()}
                                >
                                    {this.renderOptions({ isA11y: true })}
                                </A11yHidden>

                                <span
                                    aria-details={intl.formatMessage({ id: 'a11y.selected' })}
                                    aria-hidden={this.props.placeholder === placeholder}
                                    aria-selected={true}
                                    className="selected"
                                    role="option"
                                >
                                    {placeholder}
                                </span>
                            </div>,
                            <div className="select__input" key="select__input" {...inputProps} />,
                        ]
                    )}
                    {shouldShowCleanIcon && <i className="select__clean-icon" onClick={this.handleCleanIconClick} />}
                    <i aria-hidden={true} className="select__arrow" onClick={this.handleArrowClick} />
                </div>
                <SelectPopup
                    control={this.control}
                    direction={direction}
                    disabled={disabled}
                    height={height}
                    inLayer={inLayer}
                    indent={this.getPopupIndent()}
                    maxHeight={maxHeight}
                    minWidth={minWidth}
                    optionHeight={this.optionHeight}
                    popupAxis={popupAxis}
                    popupMaxWidth={popupMaxWidth}
                    popupMinWidth={popupMinWidth}
                    popupMix={popupMix}
                    popupPosition={popupPosition}
                    popupSizeCallback={this.handlePopupSize}
                    popupTheme={this.getPopupTheme()}
                    ref={this.selectPopup}
                    selectedOption={this.selectedOption}
                    style={style}
                    theme={theme}
                    width={width}
                >
                    <Menu
                        ref={this.selectMenu}
                        size={size}
                        style={{
                            minWidth: menuMinWidth,
                            maxWidth: maxWidth,
                        }}
                        theme={this.getMenuTheme()}
                    >
                        {this.renderOptions()}
                    </Menu>
                </SelectPopup>
                {valid && valid !== 'uncertain' && (
                    <span className="select__notification">{valid === 'yes' ? successMessage : errorMessage}</span>
                )}
            </div>
        );
    }
}

export const Select = injectIntl(SelectComponent);
export default Select;

Select.Option = MenuItem;
Select.displayName = 'Select';
Select.propTypes = SelectComponent.propTypes; // нужно для сторибука для react-storybook-addon-props-combinations

export { MenuItem as Option };
