// @ts-nocheck
import { Course, EXTERNAL_LMS_SLUGS, Team } from '@yandex-int/k-common/typings';
import { Button2 as Button, Link, Spin, Text } from 'platform-components';
import { withRouterPath, InjectedProps as WithRouterPathProps } from 'platform-components/hocs';
import { getIsStorageAvailable, setToLocalStorage } from 'platform-components/utils';
import PropTypes from 'prop-types';
import qs from 'query-string';
import React from 'react';
import { connect } from 'react-redux';

import {
    LMS_HOMEWORK_ID_KEY,
    SEARCH_PARAMS_INTEGRATION,
    SEARCH_PARAMS_MES,
    SEARCH_PARAMS_MES_HOMEWORK,
} from 'common.components/constants';
import { getClasses } from 'common.components/user/selectors';
import * as externalLmsSelectors from 'store/external-lms/selectors';
import { getMesHost } from 'utils/external-lms/external-lms';

import { ReduxIndexStore } from '../../typings';
import ExternalBackground from '../external-background/external-background';
import ExternalError from '../external-error/external-error';

import { MES_HOMEWORK_ERROR } from './constants';

import './external-library-params.scss';

const REQUIRED_SEARCH_PARAMS = [
    SEARCH_PARAMS_INTEGRATION.BACK_URL,
    SEARCH_PARAMS_INTEGRATION.STAFF_ID,
    SEARCH_PARAMS_INTEGRATION.LMS,
];

const REQUIRED_MES_SEARCH_PARAMS = [
    ...REQUIRED_SEARCH_PARAMS,
    SEARCH_PARAMS_MES.CLASS_ID,
    SEARCH_PARAMS_MES_HOMEWORK.BEGIN_DATE,
    SEARCH_PARAMS_MES_HOMEWORK.END_DATE,
    SEARCH_PARAMS_MES_HOMEWORK.HOMEWORK_ENTRY_ID,
    SEARCH_PARAMS_MES_HOMEWORK.HOMEWORK_ID,
    SEARCH_PARAMS_MES_HOMEWORK.SUBJECT_ID,
];

interface HOCProps extends WithRouterPathProps {}

interface StoreProps {
    teams: Array<Team>;
    mesHomeworkTeamId: number | null;
}

interface Props extends HOCProps, StoreProps {}

interface State {
    error: MES_HOMEWORK_ERROR | null;
}

interface Context {
    basePath: string;
    data: {
        getCourses: Array<Course>;
        getExternalSubject?: {
            subject_slug: string;
        };
    };
    prefix: string;
    staticUrls: {
        labLibrarySubject: string;
        notFound: string;
    };
}

export class ExternalLibraryParams extends React.Component<Props, State> {
    static contextTypes = {
        basePath: PropTypes.string.isRequired,
        data: PropTypes.shape({
            getCourses: PropTypes.array,
        }),
        prefix: PropTypes.string.isRequired,
        staticUrls: PropTypes.object.isRequired,
    };

    // eslint-disable-next-line complexity
    constructor(props: Props, context: Context) {
        super(props, context);

        this.state = { error: null };

        if (typeof window === 'undefined') {
            return;
        }

        const {
            getRouterPath,
            location: { search },
            mesHomeworkTeamId,
        } = props;
        const {
            basePath,
            data: { getCourses: courses, getExternalSubject: externalSubject },
            prefix,
            staticUrls: { labLibrarySubject, notFound },
        } = context;

        const searchParams = qs.parse(search);
        const { [SEARCH_PARAMS_INTEGRATION.BACK_URL]: backUrl, [SEARCH_PARAMS_INTEGRATION.LMS]: lms } = searchParams;

        switch (lms) {
            case EXTERNAL_LMS_SLUGS.MES: {
                const hasRequiredParams = REQUIRED_MES_SEARCH_PARAMS.every((param) => searchParams[param]);

                if (!hasRequiredParams) {
                    const mesHost = getMesHost();

                    window.location.href = decodeURIComponent(backUrl || mesHost);

                    return;
                }
                const subject = externalSubject?.subject_slug;

                if (!mesHomeworkTeamId) {
                    window.location.href = `${basePath}${prefix}${notFound}`;

                    return;
                }

                let currentTeamHasSubject =
                    courses &&
                    courses
                        .filter((course) => course.subject === subject)
                        .find((course) => course.teams.includes(mesHomeworkTeamId) && !course.closed);

                if (!subject || !currentTeamHasSubject) {
                    this.state = { error: MES_HOMEWORK_ERROR.NO_SUBJECT };

                    return;
                }

                const key = Date.now();

                setToLocalStorage(
                    LMS_HOMEWORK_ID_KEY,
                    JSON.stringify({
                        [key]: searchParams,
                    })
                );

                window.location.href = `${basePath}${prefix}${getRouterPath(labLibrarySubject, {
                    classId: mesHomeworkTeamId,
                    subject,
                })}${getIsStorageAvailable() ? `?${qs.stringify({ [LMS_HOMEWORK_ID_KEY]: key })}` : ''}`;

                break;
            }
            default:
                break;
        }
    }

    render() {
        const {
            location: { search },
        } = this.props;
        const { error } = this.state;

        const searchParams = qs.parse(search);
        const { [SEARCH_PARAMS_INTEGRATION.BACK_URL]: backUrl } = searchParams;

        return (
            <div className="external-library-params">
                <ExternalBackground />
                {error ? (
                    <ExternalError
                        descriptionTextId={`mes.error.description.${error}`}
                        headerTextId={`mes.error.header.${error}`}
                    >
                        <Link to={decodeURIComponent(backUrl)}>
                            <Button mix="mes-library-params__error-button" theme={Button.THEMES.ACTION}>
                                <Text id={`mes.error.button.${error}`} weight={Text.WEIGHT.M} />
                            </Button>
                        </Link>
                    </ExternalError>
                ) : (
                    <Spin center />
                )}
            </div>
        );
    }
}

const mapStateToProps = (storeState: ReduxIndexStore, ownProps: HOCProps): StoreProps => {
    const {
        location: { search },
    } = ownProps;

    return {
        teams: getClasses(storeState),
        mesHomeworkTeamId: externalLmsSelectors.getMesHomeworkTeamId(storeState, {
            mesHomeworkData: qs.parse(search),
        }),
    };
};

export default withRouterPath(connect(mapStateToProps)(ExternalLibraryParams));
