import React from 'react';
import { useIntl } from 'react-intl';

import b from 'b_';
import cx from 'classnames';

import { SPIN_SIZES, SPIN_THEMES } from './constants';

import './spin.scss';

interface Props {
    center?: boolean;
    hidden?: boolean;
    mix?: string | string[];
    size?: SPIN_SIZES;
    theme?: SPIN_THEMES;
    delay?: boolean;
}

const Spin = ({ center, hidden, size, theme, delay, mix }: Props) => {
    const intl = useIntl();

    return (
        <span
            aria-label={intl.formatMessage({ id: 'a11y.loading' })}
            className={cx(b('spin', { center, hidden, size, theme, delay }), mix)}
            hidden={hidden}
        />
    );
};

Spin.defaultProps = {
    size: SPIN_SIZES.M,
    hidden: false,
};

Spin.SIZE = SPIN_SIZES;
Spin.THEME = SPIN_THEMES;

export default Spin;
