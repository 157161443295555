import b from 'b_';
import cx from 'classnames';
import hoistNonReactStatics from 'hoist-non-react-statics';
//@ts-ignore
import { IconClose, Copyright, Link, Text } from 'platform-components';
import { COPYRIGHT_TYPES } from 'platform-components/constants';
import { withRouterPath, InjectedProps as WithRouterPathProps } from 'platform-components/hocs';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { accessibleClick } from '@edu-frontend/modules/src/shared/lib/a11y';

import HamburgerButton from 'common.components/hamburger-button/hamburger-button';
import Logo from 'common.components/logo/logo';
//@ts-ignore
import User from 'common.components/user/user';

import { THEMES } from './themes';

import 'education-components/dist/header/index.css';
import 'education-components/dist/user/index.css';

import './registration-header.scss';

interface OwnProps extends WithRouterPathProps, WrappedComponentProps {
    mix?: string;
    theme?: THEMES;
}

interface Props extends OwnProps {}

interface State {
    isOpen: boolean;
}

export class RegistrationHeader extends React.Component<Props, State> {
    static contextTypes = {
        basePath: PropTypes.string.isRequired,
        prefix: PropTypes.string.isRequired,
        staticUrls: PropTypes.object.isRequired,
    };

    static defaultProps = {
        theme: THEMES.LIGHT,
    };

    state = {
        isOpen: false,
    };

    componentDidUpdate(prevProps: Props) {
        const {
            location: { pathname },
        } = this.props;
        const {
            location: { pathname: prevPathname },
        } = prevProps;
        const { isOpen } = this.state;

        if (isOpen && pathname !== prevPathname) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                isOpen: false,
            });
        }
    }
    context!: React.ContextType<
        React.Context<{
            staticUrls: Record<string, string>;
            basePath: string;
            prefix: string;
        }>
    >;

    static THEMES = THEMES;

    handleHamburgerClick = () => {
        this.setState(({ isOpen }: { isOpen: boolean }) => ({ isOpen: !isOpen }));
    };

    handleClose = () => this.setState({ isOpen: false });

    render() {
        const {
            intl,
            location: { search },
            mix,
            theme,
        } = this.props;
        const { isOpen } = this.state;
        const { prefix, basePath, staticUrls } = this.context;
        const retpath = `${basePath}${prefix}${staticUrls.home}${search}`;

        return (
            <div
                className={cx(
                    mix,
                    b('registration-header', {
                        open: isOpen,
                    })
                )}
            >
                <HamburgerButton
                    mix={b('registration-header', 'hamburger-button', { theme })}
                    onClick={this.handleHamburgerClick}
                />
                <Logo
                    mix="registration-header__logo"
                    theme={theme === THEMES.LIGHT ? Logo.THEME.BLACK : Logo.THEME.WHITE}
                />
                <User mix="registration-header__user" retpath={retpath} />
                <div className="registration-header__collapse">
                    <User mix="registration-header__user" retpath={retpath} />
                    <div className="registration-header__collapse-footer">
                        <div className="registration-header__copyright">
                            <Copyright type={COPYRIGHT_TYPES.SERVICE} />
                            <Link
                                mix="registration-header__help-link"
                                target="_blank"
                                to={intl.formatMessage({ id: 'header.link.help.url' })}
                            >
                                <Text id="teacherRegistration.help" />
                            </Link>
                        </div>
                    </div>
                    <button className="registration-header__close" onClick={this.handleClose} type="button">
                        <IconClose />
                    </button>
                </div>
                <div className="registration-header__collapse-overlay" {...accessibleClick(this.handleClose)} />
            </div>
        );
    }
}

const wrapped = injectIntl(withRouterPath(RegistrationHeader));
wrapped.displayName = 'RegistrationHeader';

export default hoistNonReactStatics(wrapped, RegistrationHeader);
