import PropTypes from 'prop-types';
import React from 'react';

import { TEXT_MARKUP_TYPE } from '../../constants';
import Text from '../../text/text';

import './intl-message-notification-body.scss';

const IntlMessageNotificationBody = ({
    notification: { headerId, headerValues, messageId, messageValues, textType },
}) => (
    <div className="intl-message-notification-body">
        <div className="intl-message-notification-body__content">
            {headerId && <Text ellipsis id={headerId} values={headerValues} weight={Text.WEIGHT.M} />}
            <div className="intl-message-notification-body__message">
                {messageId && <Text id={messageId} type={textType || TEXT_MARKUP_TYPE.PLAIN} values={messageValues} />}
            </div>
        </div>
    </div>
);

IntlMessageNotificationBody.propTypes = {
    notification: PropTypes.shape({
        headerId: PropTypes.string,
        headerValues: PropTypes.object,
        messageId: PropTypes.string.isRequired,
        messageValues: PropTypes.object,
        textType: PropTypes.oneOf(Object.values(TEXT_MARKUP_TYPE)),
    }),
};

export default IntlMessageNotificationBody;
