import * as api from '@yandex-int/k-common/client/api';
import { postDiagnosticUnassigned } from '@yandex-int/k-common/client/api/defs/post-diagnostic-unassigned';
import { call, put, select } from 'redux-saga/effects';

import { addViewedHint } from 'common.components/user/actions';
import { getAssignedLessonsCount } from 'common.components/user/selectors';
import { ASSIGNED_LESSONS_COUNT } from 'common.components/user/user.constants';
import { diagnosticsActions } from 'store/diagnostics/actions';
import { getDiagnostic } from 'store/diagnostics/selectors';
import { Diagnostic } from 'store/diagnostics/typings';
import { getSubjectFromPath, getTeamIdFromPath } from 'utils/path';

import lessonActions from '../actions';

import { handleDeleteOrEditError } from './utils';

// eslint-disable-next-line complexity
export function* deleteCLessonSaga(action: ReturnType<typeof lessonActions.deleteCLesson>) {
    const { cLessonId, isDraft, onSuccess, onError } = action.payload;
    const subject = getSubjectFromPath();
    const teamId = getTeamIdFromPath();

    try {
        const assignedLessonsCount: number = yield select(getAssignedLessonsCount);
        const diagnostic = (yield select(getDiagnostic, { subject })) as Diagnostic | undefined;

        if (diagnostic?.courseLessonLinkId && diagnostic?.courseLessonLinkId === cLessonId) {
            yield call(postDiagnosticUnassigned, { courseLessonLinkId: cLessonId });
            if (subject && teamId) {
                yield put(diagnosticsActions.clearDiagnosticStoreStateBySubject({ subject, teamId }));
            }
        }

        const newAssignedLessonsCount = Math.max(0, assignedLessonsCount - 1);

        yield call(api.softDeleteCLesson, cLessonId);
        yield put(lessonActions.setLesson({ lesson: null }));
        yield put(lessonActions.deleteCLessonSuccess());

        if (!isDraft) {
            yield put(addViewedHint(ASSIGNED_LESSONS_COUNT, newAssignedLessonsCount));
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put(lessonActions.lessonUpdated());
    } catch (error) {
        console.error(error);

        yield handleDeleteOrEditError(error, cLessonId);
        yield put(lessonActions.deleteCLessonError({ error }));

        if (onError) {
            onError();
        }
    }
}
