import type { LessonSubscriptionsData } from '@yandex-int/k-common';
import * as api from '@yandex-int/k-common/client/api';
import { showNetworkError } from 'platform-components';
import { getIsParent } from 'platform-components/src/components/user/selectors';
import { call, put, select } from 'redux-saga/effects';

import { GetFullClessonRequest } from 'common.components/lesson/actions/getFullClessonRequest';
import { courseRecommendationsActions } from 'store/recommendations/actions';
import { courseSubscriptionsActions } from 'store/subscriptions/actions';
import { getCLessonSubscriptions } from 'store/subscriptions/subscriptions-loader';

import { actions as lessonActions } from '../index';

import { loadTeam } from './utils';

export function* getFullClessonSaga(action: ReturnType<typeof lessonActions.getFullClessonRequest>) {
    yield getFullClesson(action.payload);
}

export function* getFullClesson(args: GetFullClessonRequest) {
    try {
        const { clessonId, courseId, onLoad } = args;
        const isParent: boolean = yield select(getIsParent);

        yield put(lessonActions.getFullClessonLoading());
        yield put(courseRecommendationsActions.getRecommendationLessonLinkRequest({ clessonId }));
        const data: Awaited<ReturnType<typeof api.getFullCLesson>> = yield call(
            api.getFullCLesson,
            clessonId,
            courseId
        );
        yield put(lessonActions.setLesson({ lesson: data.clesson }));

        if (isParent) {
            const subscriptions: LessonSubscriptionsData = yield call(getCLessonSubscriptions, [clessonId]);

            yield put(courseSubscriptionsActions.getSubscriptionsByCourse({ courseId }));
            yield put(lessonActions.setSubscriptions({ subscriptions: subscriptions[clessonId] || [] }));
        }

        yield loadTeam();

        yield put(lessonActions.getLessonAssignsSuccess({ assignmentTable: data.assignment as any }));
        yield put(lessonActions.getFullClessonSuccess());

        if (onLoad) {
            onLoad();
        }
    } catch (error) {
        console.error(error);

        yield put(showNetworkError(error));
        yield put(lessonActions.getFullClessonError());
    }
}
