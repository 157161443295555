import { useAppContext } from '../../components/app-factory/context';

export function useViewDeps<TResult>(dep: (...args: unknown[]) => Promise<TResult>, depName?: string): TResult {
    const { data } = useAppContext();

    if (depName) {
        return data?.[depName] as TResult;
    }

    return data?.[dep.name] as TResult;
}
