import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import React, { useEffect, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Interpreter } from 'xstate';

import { getCurrentTeamLevel, getIsInStudentRegistrationLinkExperiment } from 'common.components/user/selectors';
import * as coursesTemplatesActions from 'store/courses-templates/actions';
import * as coursesTemplatesSelectors from 'store/courses-templates/selectors';
import { getTeamIdFromPath } from 'utils/path';

import * as selectors from '../selectors';
import { TeamBuilder, TeamBuilderProps } from '../team-builder';
import { TEAM_BUILDER_INNER_STEPS } from '../team-builder-machine';
import {
    MachineContext,
    MachineEvents,
    TEAM_BUILDER_ENTRY_STEPS,
    TEAM_BUILDER_STEPS,
    TeamBuilderModeContainerInterface,
    TeamBuilderTeamData,
} from '../typings';

interface Props extends Omit<TeamBuilderProps, 'loadTemplates' | 'shouldLoadTemplates' | 'currentTeamInfo'> {
    machineState: TEAM_BUILDER_STEPS | TEAM_BUILDER_INNER_STEPS;
    send: Interpreter<MachineContext, any, MachineEvents>['send'];
    entryPoint: TEAM_BUILDER_ENTRY_STEPS | null;
    hasSchool: boolean;
    teamData: TeamBuilderTeamData | null;
    handleTemplatesLoaded: (loadingStatus: LOADING_STATUSES) => void;
}

export const TeamBuilderModeUpdateTeamContainer = React.forwardRef<TeamBuilderModeContainerInterface, Props>(
    (props: Props, ref) => {
        const { createdTeamId, machineState, send, entryPoint, hasSchool, handleTemplatesLoaded, ...otherProps } =
            props;
        const dispatch = useDispatch();

        const teamId = createdTeamId || getTeamIdFromPath();
        const existingTeamId = getTeamIdFromPath();

        const grade = useSelector((store) => getCurrentTeamLevel(store, { classId: teamId }));
        const hasStudents = useSelector((store) => selectors.getHasStudents(store, { classId: teamId }));
        const hasLetter = useSelector((store) => selectors.getHasLetterAndNumber(store, { classId: teamId }));
        const hasSubjects = useSelector((store) => selectors.getHasSubjects(store, { classId: teamId }));
        const templatesSource = { classId: teamId };
        const hasTemplates = useSelector((store) => selectors.getHasTemplates(store, templatesSource));
        const templatesLoadingStatus = useSelector((store) =>
            coursesTemplatesSelectors.getCoursesTemplatesLoadingStatus(store, templatesSource)
        );
        const shouldLoadTemplates = useSelector((store) => selectors.getShouldLoadTemplates(store, templatesSource));
        const isInStudentLinkExperiment = useSelector(getIsInStudentRegistrationLinkExperiment);

        useEffect(
            function updateChangedData() {
                send({
                    type: 'UPDATE',
                    newContext: {
                        hasTemplates,
                        hasSchool,
                        hasLetter,
                        hasSubjects,
                        hasStudents,
                        grade,
                        inStudentLinkExperiment: isInStudentLinkExperiment,
                    },
                });
            },
            [
                send,
                hasTemplates,
                hasSchool,
                hasSubjects,
                hasStudents,
                teamId,
                hasLetter,
                grade,
                isInStudentLinkExperiment,
            ]
        );

        useEffect(() => {
            handleTemplatesLoaded(templatesLoadingStatus);
        }, [handleTemplatesLoaded, templatesLoadingStatus]);

        // разное
        const resetMachine = () => {
            send({
                type: 'RESET',
                newContext: {
                    entryPoint,
                    hasSchool,
                    hasTeam: true,
                    hasLetter,
                    hasSubjects,
                    hasStudents,
                    hasTemplates,
                    grade,
                    hasError: false,
                    passedSteps: [],
                    hasCode: false,
                    inStudentLinkExperiment: isInStudentLinkExperiment,
                },
            });
        };

        // TODO: Разобраться почему при reset мигает модалка https://st.yandex-team.ru/EDUCATION-25348
        // Делаем Reset при смене Класса, чтобы обновить информацию
        useEffect(() => {
            if (!existingTeamId || createdTeamId === existingTeamId) {
                return;
            }

            // При добавлении класс через код стейт машины сбрасывается уникальным образом через шаг clearTeamData
            if (machineState !== TEAM_BUILDER_STEPS.CODE && machineState !== TEAM_BUILDER_STEPS.SUBMIT_CODE) {
                resetMachine();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [existingTeamId]);

        useImperativeHandle(ref, () => ({
            resetMachine,
        }));

        const loadTemplates = () => {
            dispatch(coursesTemplatesActions.getCoursesTemplatesRequest(templatesSource));
        };

        return (
            <TeamBuilder
                {...otherProps}
                createdTeamId={createdTeamId}
                loadTemplates={loadTemplates}
                machineState={machineState as TEAM_BUILDER_STEPS | TEAM_BUILDER_INNER_STEPS}
                shouldLoadTemplates={shouldLoadTemplates}
            />
        );
    }
);
