import b from 'b_';
import { Close } from 'education-icons';
import elementResizeDetectorMaker from 'element-resize-detector';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';

import { transform } from '../../../utils/css';
import withUatraits from '../../../utils/hocs/with-uatraits';
import { Button2, BUTTON_VIEWS } from '../../button2/button2';
import LockBodyScroll from '../../lock-body-scroll/lock-body-scroll';
import { ModalContext } from '../context';
import { MODAL_THEMES } from '../types';

import './modal__portal.scss';

// eslint-disable-next-line react/prop-types
export const DefaultClose = ({ onClick, 'aria-label': ariaLabel, ...props }) => (
    <Button2 aria-label={ariaLabel} className="modal__close-wrapper" onClick={onClick} view={BUTTON_VIEWS.A11Y}>
        <Close {...props} />
    </Button2>
);

DefaultClose.propTypes = {
    onClick: PropTypes.func,
};

// eslint-disable-next-line react/no-multi-comp
class Portal extends React.Component {
    static propTypes = {
        autoclosable: PropTypes.bool,
        autoFocus: PropTypes.bool,
        backgroundColor: PropTypes.string,
        children: PropTypes.node,
        hasClose: PropTypes.bool,
        onRequestClose: PropTypes.func,
        uatraits: PropTypes.object,
        useScale: PropTypes.bool,
        theme: PropTypes.oneOf(Object.values(MODAL_THEMES)),
        lockBodyScroll: PropTypes.bool,
        scrollToTop: PropTypes.bool,
        Close: PropTypes.func,
        intl: PropTypes.object,
    };

    static defaultProps = {
        autoclosable: true,
        useScale: true,
        lockBodyScroll: true,
        scrollToTop: false,
        Close: DefaultClose,
    };

    constructor(props) {
        super(props);

        this.state = { scale: 1 };
    }

    componentDidMount() {
        const { portal, root } = this.refs;
        const { uatraits, useScale, autoFocus = true, scrollToTop } = this.props;

        if (autoFocus) {
            portal.focus();
        }

        if (scrollToTop) {
            root.scrollTop = 0;
        }

        /**
         * для мобилок, чтобы модалка подстраивалась под размер экрана
         * на него устанавливается transform: scale с вычисляемым ниже значением
         */

        if (!useScale || !uatraits.isMobile) {
            return;
        }

        this.erd = elementResizeDetectorMaker({
            strategy: 'scroll',
        });

        this.erd.listenTo(portal, (element) => {
            const { offsetWidth: width, offsetHeight: height } = root;
            const { offsetWidth, offsetHeight } = element;
            const scale = Math.min(width / offsetWidth, height / offsetHeight, 1);

            this.setState({ scale });
        });
    }

    onKeyDown = (e) => {
        if (e.keyCode === 27) {
            // esc
            this.requestClose();
            e.preventDefault();
        }
    };

    requestClose = () => {
        if (this.props.onRequestClose) {
            this.props.onRequestClose();
        }
    };

    getClose = () => {
        const { hasClose, Close: CloseComponent, intl } = this.props;

        if (!hasClose) {
            return null;
        }

        return (
            <CloseComponent
                aria-label={intl.formatMessage({ id: 'a11y.modal.close' })}
                className="modal__close"
                onClick={this.requestClose}
            />
        );
    };

    render() {
        const { scale } = this.state;
        const { children, autoclosable, theme, backgroundColor, useScale } = this.props;
        const onOutsideClick = autoclosable ? this.requestClose : null;
        const close = this.getClose();

        return (
            <ModalContext.Provider value={{ requestClose: this.requestClose }}>
                <div className="modal__root" onClick={onOutsideClick} onKeyDown={this.onKeyDown} ref="root">
                    <LockBodyScroll locked={this.props.lockBodyScroll} />
                    <div className="modal__background" />
                    <div className="modal__wrapper">
                        <div
                            className="modal__portal-wrapper"
                            ref="wrapper"
                            style={useScale ? transform(`scale(${scale})`) : undefined}
                        >
                            <div
                                className={b('modal', 'portal', { theme })}
                                onClick={(e) => e.stopPropagation()}
                                ref="portal"
                                style={{ backgroundColor }}
                                tabIndex={-1} // без этого не работает onKeyDown
                            >
                                {close}
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </ModalContext.Provider>
        );
    }
}

export default compose(injectIntl, withUatraits)(Portal);
