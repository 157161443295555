import PropTypes from 'prop-types';
import React from 'react';
import getDisplayName from 'react-display-name';
import { RouteComponentProps, generatePath, withRouter } from 'react-router';

interface MatchParams {
    [key: string]: string;
}

export interface InjectedProps extends RouteComponentProps<MatchParams> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getRouterPath: (path: string, extraParams?: Record<string, any>) => string;
    staticUrls: Record<string, string>;
}

/**
 * HOC WithRouterPath — пробрасывает метод getRouterPath, который делает reverse для урла
 *
 * Warning: помимо основного метода, в оборачиваемый компонент также приедут пропы от withRouter.
 * Отфильтровать их не получается, т.к. в коде есть множество компонентов,
 * использующих эти пропы, не объявляя у себя withRouter.
 */

export function withRouterPath<T extends InjectedProps>(WrappedComponent: React.ComponentType<T>) {
    class WithRouterPath extends React.Component<T> {
        static displayName = `withRouterPath(${getDisplayName(WrappedComponent)})`;

        static contextTypes = {
            staticUrls: PropTypes.object.isRequired,
        };
        context!: React.ContextType<React.Context<{ staticUrls: object }>>;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getRouterPath = (path: string, extraParams: Record<string, any> = {}) => {
            const params = this.props.match ? this.props.match.params : {};

            return generatePath(path, { ...params, ...extraParams });
        };

        render() {
            const { staticUrls } = this.context;

            return <WrappedComponent {...this.props} getRouterPath={this.getRouterPath} staticUrls={staticUrls} />;
        }
    }

    const WithRouter = withRouter(WithRouterPath);

    WithRouter.displayName = `withRouter(${getDisplayName(WithRouterPath)})`;

    return WithRouter as React.ComponentType<Omit<T, keyof InjectedProps>>;
}

export default withRouterPath;
