import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
// @ts-ignore
import { CLESSON_MODES } from '@yandex-int/k-common/constants';
import { InferValuesTypes } from '@yandex-int/k-common/typings/index';

import { STUDENTS_ERRORS } from 'common.components/students-list/typings';
import { LessonDate } from 'utils/lesson/lesson-time/types';

import * as actions from './actions';

export enum PROCESSABLE_ERRORS {
    DEADLINE_BEFORE_ASSIGNMENT = 'date_deadline is less than date_assignment',
    NOT_ENOUGH_TIME_TO_PREPARE = 'start_deadline is less than 15 minutes',
}

export enum ACCORDION_ITEMS {
    METHODOLOGICAL_GOAL = 'accordion_methodological_goal',
    MODE = 'accordion_mode',
    DATES = 'accordion_dates',
    STUDENTS = 'accordion_students',
}

export enum ASSIGNMENT_STATES {
    IDLE = 'idle',
    LOADING = 'loading',
    VALIDATING = 'validating',
    SUBMITTING = 'submitting',
    ERROR = 'error',
}

export enum ASSIGNMENT_DATE_TAB_LMS_DISPLAY_MODES {
    NONE = 'none',
    LOADING = 'loading',
    DATES = 'dates',
    DISABLED = 'disabled',
}

export interface ExternalLmsAvailableDates {
    lessonFinishDatetimes: Array<Date>;
    lessonStartDatetimes: Array<Date>;
}

export interface LessonAssignmentStore {
    lessonMode: CLESSON_MODES | null;
    assignDate: LessonDate | null;
    deadlineDate: LessonDate | null;
    assignDateError: string | null;
    deadlineDateError: string | null;
    duration: number | null;
    durationError: string | null;
    selectedStudents: Array<number>;
    studentsError: STUDENTS_ERRORS | null;
    currentAccordionItem: ACCORDION_ITEMS | null;
    assignmentState: ASSIGNMENT_STATES;
    externalLmsAvailableDatesLoadingStatus: LOADING_STATUSES;
    externalLmsAvailableDates: ExternalLmsAvailableDates | null;
    externalLmsDatesError: string | null;
}

export type LessonAssignmentActions = ReturnType<InferValuesTypes<typeof actions>>;
