import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
// @ts-ignore
import { CLESSON_MODES, PROBLEM_TYPES } from '@yandex-int/k-common/constants';
import { ClessonResults, LessonSubscription } from '@yandex-int/k-common/typings';
import { FullCLesson, AssignmentTable } from '@yandex-int/k-common/typings';

import { LessonDate } from 'utils/lesson/lesson-time/types';

const DEFAULT_CLESSON_MARKUP = {
    mode: 1,
    duration: null,
    max_attempts_in_group: 2,
    max_attempts: 3,
    max_points: 30,
    show_answers_in_last_attempt: true,
    show_all_problems: true,
    show_tips: true,
    work_type: 0,
    course: {},
};

export const DEFAULT_DESCRIPTION_ID = 'lessonModal.description.default';

export const DEFAULT_LESSON_MARKUP = {
    problems: [],
    owner: null,
    name: '',
    clesson: DEFAULT_CLESSON_MARKUP,
};

export interface LessonProblemToInsert {
    type: PROBLEM_TYPES;
    problem: { id: number };
}

export interface LessonStore {
    clessonResults: ClessonResults | null;
    lesson: FullCLesson | null;
    subscriptions: LessonSubscription[] | null;
    lessonLoadingStatus: LOADING_STATUSES;
    lessonAssignsLoadingStatus: LOADING_STATUSES;
    assignmentTable: AssignmentTable;
    assignLessonStatus: LOADING_STATUSES;
    restoreClessonLoadingStatus: LOADING_STATUSES;
    addLessonToCourseStatus: LOADING_STATUSES;
    // каком модулю принадлежит занятие
    // используется для получения данных о последнем выданном занятии из модуля
    fromModuleId: number | null;
    createLessonStatus: LOADING_STATUSES;
    lessonSource: string | null;
    /**
     * при обновлении любого урока, сюда записывается новая уникальная строка (uuid),
     * для возможности сравнения и вызова обновляющих функций
     * */
    lessonUpdated: string;
}

export interface SourceAssignmentInfo {
    assignmentTable: AssignmentTable;
    assignmentDate: LessonDate;
    deadlineDate: LessonDate;
    lessonMode: CLESSON_MODES;
    duration: number | null;
    evaluationDate: Date | null;
}

export enum LESSON_EDIT_ERRORS {
    LESSON_UNPUBLISHED = 'lesson_unpublished',
    LESSON_HAS_ANSWERS = 'lesson_has_answers',
}

export interface DeleteCLessonStore {
    deleteCLessonError: LESSON_EDIT_ERRORS | null;
    deleteClessonLoadingStatus: LOADING_STATUSES;
    deletingCLessonId: number | null;
}
