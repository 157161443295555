import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import { createSelector } from 'reselect';

import { DiagnosticsStoreState, ReduxDiagnosticsStore } from 'store/diagnostics/typings';
import { getTeamIdFromPath } from 'utils/path';
import { getSubjectFromProps } from 'utils/selectors/selectors';

const storeSelector = (storeState: ReduxDiagnosticsStore): DiagnosticsStoreState => storeState.diagnostics;

const storeTeamSelector = createSelector([storeSelector, getTeamIdFromPath], (store, teamId) =>
    teamId ? store[teamId] : undefined
);

export const getDiagnostic = createSelector(
    [storeTeamSelector, getSubjectFromProps, getTeamIdFromPath],
    (store, subject) => store?.[subject]?.diagnostic
);

export const getDiagnosticLesson = createSelector(
    [storeTeamSelector, getSubjectFromProps],
    (store, subject) => store?.[subject]?.lesson
);

export const getIsDiagnosticLoading = createSelector(
    [storeTeamSelector, getSubjectFromProps],
    (store, subject) => store?.[subject]?.loadingStatus === LOADING_STATUSES.LOADING
);

export const getIsDiagnosticLoaded = createSelector([storeTeamSelector, getSubjectFromProps], (store, subject) =>
    // @ts-ignore ругается, что может быть undefined, но здесь это не важно
    [LOADING_STATUSES.SUCCESS, LOADING_STATUSES.ERROR].includes(store?.[subject]?.loadingStatus)
);

export const getIsDiagnosticAssigned = createSelector(
    [storeTeamSelector, getSubjectFromProps],
    (store, subject) => !!store?.[subject]?.diagnostic?.courseLessonLinkId
);

export const shouldLoadDiagnostic = createSelector(
    [getIsDiagnosticLoading, getIsDiagnosticLoaded],
    (isDiagnosticLoading, isDiagnosticLoaded) => !isDiagnosticLoading && !isDiagnosticLoaded
);

export const getDiagnosticProblemIds = createSelector(
    [getDiagnostic],
    (diagnostic) => diagnostic?.problemsInfo.problems.map(({ id }) => id) ?? []
);
