import { BUNKER_NODE_KEYS } from '@yandex-int/k-common/constants';
import type { OlympsConfig, PublicLessonsConfig, QuizConfig } from '@yandex-int/k-common/typings/bunker-nodes';
import { getBunkerNode } from '@yandex-int/k-common/utils/bunker-nodes/get-bunker-node';
import { createSelector } from 'reselect';

import { reportMessage } from '../../utils/raven-message';

import type { TeacherReserveInfo, StoriesNode, Onboarding, OnboardingNode } from './bunker-types';
import type { Data, StoreWithData } from './typings';

export const dataSelector = <T extends StoreWithData>(state: T): Data => state._data;

export const getSpecProjectConfig = createSelector([dataSelector], (data) =>
    getBunkerNode(data, BUNKER_NODE_KEYS.SPEC_PROJECT_CONFIG, reportMessage)
);

export const getSubjectConfig = createSelector([dataSelector], (data) =>
    getBunkerNode(data, BUNKER_NODE_KEYS.SUBJECT_CONFIG, reportMessage)
);

export const getSubjectPages = createSelector([dataSelector], (data) =>
    getBunkerNode(data, BUNKER_NODE_KEYS.SUBJECT_PAGES, reportMessage)
);

export const getExperimentalSubjectPages = createSelector([dataSelector], (data) =>
    getBunkerNode(data, BUNKER_NODE_KEYS.EXPERIMENTAL_SUBJECT_PAGES, reportMessage)
);

export const getFormsConfig = createSelector([dataSelector], (data) =>
    getBunkerNode(data, BUNKER_NODE_KEYS.FORMS_CONFIG, reportMessage)
);

export const getFeatureFlags = createSelector([dataSelector], (data) =>
    getBunkerNode(data, BUNKER_NODE_KEYS.FEATURE_FLAGS, reportMessage)
);

export const getPluckerConfig = createSelector([dataSelector], (data) =>
    getBunkerNode(data, BUNKER_NODE_KEYS.PLUCKER_CONFIG, reportMessage)
);

export const getPortfolioConfig = createSelector([dataSelector], (data) =>
    getBunkerNode(data, BUNKER_NODE_KEYS.PORTFOLIO_CONFIG, reportMessage)
);

export const getTeacherTrainingCoursesConfig = createSelector([dataSelector], (data) =>
    getBunkerNode(data, BUNKER_NODE_KEYS.TEACHER_TRAINING_COURSES_CONFIG, reportMessage)
);

export const getTeacherExams = createSelector([dataSelector], (data) =>
    getBunkerNode(data, BUNKER_NODE_KEYS.TEACHER_EXAMS, reportMessage)
);

export const getStickersConfig = createSelector([dataSelector], (data) =>
    getBunkerNode(data, BUNKER_NODE_KEYS.STICKERS, reportMessage)
);

export const getTeacherBanners = createSelector([dataSelector], (data) =>
    getBunkerNode(data, BUNKER_NODE_KEYS.TEACHER_BANNERS, reportMessage)
);

export const getOlympsConfig = createSelector(
    [dataSelector],
    (data) => getBunkerNode(data, BUNKER_NODE_KEYS.OLYMPS_CONFIG, reportMessage) as OlympsConfig
);

export const getLikesConfig = createSelector([dataSelector], (data) =>
    getBunkerNode(data, BUNKER_NODE_KEYS.LIKES_CONFIG, reportMessage)
);

export const getQuizConfig = createSelector(
    [dataSelector],
    (data) => getBunkerNode(data, BUNKER_NODE_KEYS.QUIZ_CONFIG, reportMessage) as QuizConfig
);

export const getParentBanners = createSelector([dataSelector], (data) =>
    getBunkerNode(data, BUNKER_NODE_KEYS.PARENT_BANNERS, reportMessage)
);

export const getStudentBanners = createSelector([dataSelector], (data) =>
    getBunkerNode(data, BUNKER_NODE_KEYS.STUDENT_BANNERS, reportMessage)
);

export const getWebinars = createSelector([dataSelector], (data) =>
    getBunkerNode(data, BUNKER_NODE_KEYS.WEBINARS, reportMessage)
);

export const getParentAddCourses = createSelector([dataSelector], (data) =>
    getBunkerNode(data, BUNKER_NODE_KEYS.PARENT_ADD_COURSES, reportMessage)
);

export const getVerdictThemes = createSelector([dataSelector], (data) =>
    getBunkerNode(data, BUNKER_NODE_KEYS.VERDICT_THEMES, reportMessage)
);

export const getVerdictConfig = createSelector([dataSelector], (data) =>
    getBunkerNode(data, BUNKER_NODE_KEYS.VERDICT_CONFIG, reportMessage)
);

export const getPublicLessonsConfig = createSelector(
    [dataSelector],
    (data) => getBunkerNode(data, BUNKER_NODE_KEYS.PUBLIC_LESSONS_CONFIG, reportMessage) as PublicLessonsConfig
);

export const getMailPermissionConfig = createSelector([dataSelector], (data) =>
    getBunkerNode(data, BUNKER_NODE_KEYS.MAIL_PERMISSION_CONFIG, reportMessage)
);

export const getCoursesLandingData = createSelector([dataSelector], (data) =>
    getBunkerNode(data, BUNKER_NODE_KEYS.COURSES_LANDING_DATA, reportMessage)
);

export const getAboutPlusKidsConfig = createSelector([dataSelector], (data) =>
    getBunkerNode(data, BUNKER_NODE_KEYS.ABOUT_PLUS_KIDS_CONFIG, reportMessage)
);

export const getStories = createSelector(
    [dataSelector],
    (data) => getBunkerNode(data, BUNKER_NODE_KEYS.STORIES, reportMessage) as StoriesNode
);

const onboardingsAdapter = (data: OnboardingNode): Onboarding[] =>
    data.filter(({ enabled }) => enabled).map(({ enabled: _, ...other }) => other);

export const getOnboardings = createSelector([dataSelector], (data) =>
    onboardingsAdapter(getBunkerNode(data, BUNKER_NODE_KEYS.ONBOARDING, reportMessage) as OnboardingNode)
);

export const getOnboarding = createSelector(
    [getOnboardings, (_: Data, onboardingId: string) => onboardingId],
    (onboardings, onboardingId): Onboarding | undefined => {
        return onboardings?.find((onboarding: Onboarding) => onboarding.id === onboardingId);
    }
);

export const getTeacherReserveInfo = createSelector(
    [dataSelector],
    (data) => getBunkerNode(data, BUNKER_NODE_KEYS.TEACHER_RESERVE_INFO, reportMessage) as TeacherReserveInfo | null
);

export const getDiagnosticsLessonsInfo = createSelector([dataSelector], (data) =>
    getBunkerNode(data, BUNKER_NODE_KEYS.DIAGNOSTICS_LESSONS, reportMessage)
);

export const getTeacherChatGptSettings = createSelector([dataSelector], (data) =>
    getBunkerNode(data, BUNKER_NODE_KEYS.TEACHER_CHAT_GPT, reportMessage)
);

export const getDoodleSettings = createSelector([dataSelector], (data) =>
    getBunkerNode(data, BUNKER_NODE_KEYS.DOODLE, reportMessage)
);
