import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import { getFeatureFlags } from 'platform-components/src/components/app-factory/selectors';
import { createSelector } from 'reselect';

import { getLetter, getNumber, getStudents } from 'common.components/classes/selectors';
import { AddressWithId } from 'common.components/school-form/typings';
import { getSchoolId } from 'common.components/user/selectors';
import * as coursesTemplatesSelectors from 'store/courses-templates/selectors';
import { getSubjects } from 'store/courses/selectors';
import { getTeamIdFromPath } from 'utils/path';
import { intlSelector } from 'utils/selectors/selectors';

import { TeamBuilderStore } from './typings';

const teamBuilderSelector = <T extends { teamBuilder: TeamBuilderStore }>(storeState: T): TeamBuilderStore =>
    storeState.teamBuilder;

export const getHasSchool = createSelector([getSchoolId], (schoolId) => schoolId !== null);

export const getHasTeam = createSelector([getTeamIdFromPath], (id) => id !== null);

export const getHasStudents = createSelector(
    [getStudents],
    (students) => students !== null && students.length > 0 // TODO: нужна проверка на длину?
);

export const getHasSubjects = createSelector([getSubjects], (subjects) => subjects.length > 0);

export const getHasTemplates = createSelector([coursesTemplatesSelectors.getCoursesTemplatesList], (templates) => {
    return templates && templates.length > 0;
});

export const getTeamData = createSelector([teamBuilderSelector], (state) => state.teamData);

export const getCurrentTeamData = createSelector([getLetter, getNumber], (teamLetter, teamLevel) => ({
    teamLetter,
    teamLevel,
}));

export const getHasLetterAndNumber = createSelector(
    [getCurrentTeamData],
    ({ teamLevel, teamLetter }) => teamLevel !== null && teamLetter !== null && teamLetter !== ''
);

export const getTeamPatchData = createSelector([teamBuilderSelector], (state) => state.teamPatchData);

export const getShouldLoadTemplates = createSelector(
    [coursesTemplatesSelectors.getCoursesTemplatesLoadingStatus],
    (templatesLoadingStatus) => templatesLoadingStatus === LOADING_STATUSES.UNSENT
);

export const getIsStarted = createSelector([teamBuilderSelector], (state) => state.started);

export const getSchoolData = createSelector([teamBuilderSelector], (state) => ({
    school: state.school,
    address: state.address,
}));

export const getSubmittingStatus = createSelector([teamBuilderSelector], (state) => state.submittingStatus);

export const getCodeSubmittingStatus = createSelector([teamBuilderSelector], (state) => state.codeSubmittingStatus);

export const getSelectedTemplatesIds = createSelector([teamBuilderSelector], (state) => state.selectedTemplatesIds);

export const getAddedStudents = createSelector([teamBuilderSelector], (state) => state.students);

export const getCode = createSelector([teamBuilderSelector], (state) => state.code);

export const getEntryPoint = createSelector([teamBuilderSelector], (state) => state.entryPoint);

export const getLocationForMetrika = createSelector([teamBuilderSelector], (state) => state.location);

export const getOnSuccess = createSelector([teamBuilderSelector], (state) => state.onSuccess);

export const getNewSubject = createSelector([teamBuilderSelector], (state) => state.newSubject);

// селекторы ниже просто объединяют данные по месту использования,
// чтобы уменьшить бойлерплейт в team-builder.container
export const getDataForSubmit = createSelector(
    [
        getAddedStudents,
        getSchoolData,
        getSelectedTemplatesIds,
        getHasSchool,
        getHasLetterAndNumber,
        getTeamData,
        getTeamPatchData,
        getLocationForMetrika,
    ],
    (
        students,
        schoolData,
        subjectsIds,
        hasSchool,
        hasLetter,
        teamData,
        teamPatchData,
        location
        // eslint-disable-next-line max-params
    ) => ({
        students,
        schoolData,
        subjectsIds,
        hasSchool,
        hasLetter,
        teamData,
        teamPatchData,
        location,
    })
);

export const getDataForProps = createSelector(
    [
        getSubmittingStatus,
        getCodeSubmittingStatus,
        getHasTeam,
        getHasLetterAndNumber,
        getHasSchool,
        getTeamData,
        getEntryPoint,
        getLocationForMetrika,
    ],
    (
        submittingStatus,
        codeSubmittingStatus,
        hasTeam,
        hasLetter,
        hasSchool,
        teamData,
        entryPoint,
        location
        // eslint-disable-next-line max-params
    ) => ({
        shouldSubmit: submittingStatus !== LOADING_STATUSES.LOADING,
        shouldSubmitCode: codeSubmittingStatus !== LOADING_STATUSES.LOADING,
        hasTeam,
        hasLetter,
        hasSchool,
        teamData,
        entryPoint,
        location,
    })
);

export const getIsTeamBuilderActive = createSelector(teamBuilderSelector, (teamBuilder) => {
    return Boolean(teamBuilder.entryPoint);
});

export const getIsTeamBuilderTeamSubmitting = createSelector(
    [getSubmittingStatus, getCodeSubmittingStatus],
    (submittingStatus, codeSubmittingStatus) => {
        return submittingStatus === LOADING_STATUSES.LOADING || codeSubmittingStatus === LOADING_STATUSES.LOADING;
    }
);

export const getIsSubjectTeachersFeatureEnabled = createSelector([getFeatureFlags], (featureFlags): boolean =>
    Boolean(featureFlags?.subjectTeachersEnabled)
);

export const getCanEditGrade = createSelector(teamBuilderSelector, (teamBuilder) => {
    return teamBuilder.canEditGrade;
});

export const getDefaultGrade = createSelector(teamBuilderSelector, (teamBuilder) => {
    return teamBuilder.defaultGrade;
});

export const getMoscowAddress = createSelector(intlSelector, (intl): AddressWithId => {
    const country = intl.formatMessage({ id: 'mesRegion.country' });
    const city = intl.formatMessage({ id: 'mesRegion.city' });
    const id = intl.formatMessage({ id: 'mesRegion.id' });
    const geoId = intl.formatMessage({ id: 'mesRegion.geoid' });

    return {
        subtitle: country,
        geoid: Number(geoId),
        title: city,
        id: id,
    };
});
