import cx from 'classnames';
import { Link, SimpleCallback, Text } from 'platform-components';
import React from 'react';

// @ts-ignore
import FormView from 'common.components/form-view/form-view';

import './school-form__footer-link.scss';

interface FooterLinkProps {
    mix?: string;
    onLinkClick: SimpleCallback;
    textId: string;
}

const FooterLink = (props: FooterLinkProps) => (
    <FormView.Footer mix={cx(props.mix, 'school-form__footer-section')}>
        <Link onClick={props.onLinkClick} pseudo theme={Link.THEME.PRIMARY}>
            <Text id={props.textId} />
        </Link>
    </FormView.Footer>
);

export default FooterLink;
