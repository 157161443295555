import { postFetch } from '../fetch';

import { apiConfig } from '../config';

interface AddCoursesRequest {
    classIds: number[];
    templatesPerClassIds: Record<number, Array<number>>;
}

export function postAddCoursesBulk(requestData: AddCoursesRequest):Promise<void> {
    const { classIds, templatesPerClassIds } = requestData;
    const { apiPostAddCoursesBulk } = apiConfig.getStaticUrls();

    return postFetch(apiPostAddCoursesBulk, { classIds, templatesPerClassIds });
}
