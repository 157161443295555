import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { getMe } from '../../components/user/selectors';
import { createErrorFromUnknown } from '../create-error-from-unknown';
import { getFromLocalStorage, removeFromLocalStorage, setToLocalStorage } from '../localstorage';
import { reportMessage } from '../raven-message';

type State = Record<MyId, UserState>;

type ClessonId = string | number;
type MyId = number;

interface UserState {
    lessonModalAdState: Record<string, Record<ClessonId, { wasShowed: boolean }>>;
    openActiveLessonTrigger: Date | null;
    quizStudentId: string | null;
    closedParentKidsPageBanners: Record<string, boolean>;
    lessonSupportControlTimings: Record<string, Date>;
    shouldHideLessonSosButton: Record<ClessonId, boolean>;
    shouldShowWelcomeParentModal: boolean;
    shouldShowListLessons: boolean;
    quizDrafts: Record<string, string>;
    serbiaOlyModalFormShown: Record<string, boolean>;
    grade: number | null;
    promocode: string | null;
}

const defaultLocalStorageUserState: UserState = {
    lessonModalAdState: {},
    openActiveLessonTrigger: null,
    quizStudentId: null,
    closedParentKidsPageBanners: {},
    lessonSupportControlTimings: {},
    shouldHideLessonSosButton: {},
    shouldShowWelcomeParentModal: false,
    shouldShowListLessons: false,
    serbiaOlyModalFormShown: {},
    quizDrafts: {},
    grade: null,
    promocode: null,
};

const namespace = 'schoolbook';

export function useLocalStorage() {
    const me = useSelector(getMe);

    const unauthorizedId = 0;
    const myId = me?.id || unauthorizedId;

    const getState = useCallback((): State => {
        try {
            const serializedState: string = getFromLocalStorage(namespace, null);

            if (serializedState === null) {
                return {};
            }

            const parsedState: State = JSON.parse(serializedState);

            if (parsedState === null || typeof parsedState !== 'object') {
                throw new Error(`Local storage ${namespace} state was corrupted`);
            }

            return parsedState;
        } catch (error) {
            reportMessage(`Could not read local storage ${namespace} state`, createErrorFromUnknown(error));

            return {};
        }
    }, []);

    const setState = useCallback((parsedState: State) => {
        const serializedState = JSON.stringify(parsedState);

        setToLocalStorage(namespace, serializedState);
    }, []);

    const removeState = useCallback(() => {
        removeFromLocalStorage(namespace);
    }, []);

    const get = useCallback(
        <K extends keyof UserState>(key: K) => {
            const state = getState();

            return state[myId] && state[myId][key] !== undefined ? state[myId][key] : defaultLocalStorageUserState[key];
        },
        [getState, myId]
    );

    const set = useCallback(
        <K extends keyof UserState>(key: K, value: UserState[K]) => {
            const state = getState();

            if (!state[myId]) {
                state[myId] = { ...defaultLocalStorageUserState };
            }

            state[myId][key] = value;

            setState(state);
        },
        [getState, myId, setState]
    );

    const remove = useCallback(
        <K extends keyof UserState>(key: K) => {
            const state = getState();

            if (!state[myId]) {
                return;
            }

            delete state[myId][key];

            if (Object.keys(state[myId]).length === 0) {
                delete state[myId];
            }

            if (Object.keys(state).length === 0) {
                removeState();
            } else {
                setState(state);
            }
        },
        [getState, myId, removeState, setState]
    );

    return {
        get,
        remove,
        set,
    };
}
