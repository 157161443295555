import { FullCLesson } from '../../../typings';
import { getFetch } from '../fetch';

import { apiConfig } from '../config';

export function getFullCLesson(
    clessonId: number,
    courseId: number
): Promise<{ assignment: object, clesson: FullCLesson, course: object }> {
    const { apiGetFullCLesson } = apiConfig.getStaticUrls();

    return getFetch(apiGetFullCLesson, { clessonId, courseId, with_students: true, expand_problems: true });
}
