import * as api from '@yandex-int/k-common/client/api';
import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import { FullCLesson } from '@yandex-int/k-common/typings';
import type { Task } from 'redux-saga';
import { call, put, putResolve, race, select, take, spawn, join } from 'redux-saga/effects';

import { getLessonViewCourseId } from 'common.components/lesson-view.store/selectors';
import * as lessonSelectors from 'common.components/lesson/selectors';

import { actions as lessonActions } from '../index';

import { handleDeleteOrEditError, mergeClesson } from './utils';

export function* saveLessonSaga(action: ReturnType<typeof lessonActions.saveLesson>) {
    const onSuccess = action.payload?.onSuccess;

    yield saveCLesson(onSuccess);
}

type onSuccessCallback = (clessonId: number, clessonData: FullCLesson) => void;

export function* saveCLesson(onSuccess?: onSuccessCallback) {
    let clesson: FullCLesson = yield select(lessonSelectors.getLesson);

    try {
        const isPreparedLesson: boolean = yield select(lessonSelectors.getIsPreparedLesson);
        const status: LOADING_STATUSES = yield select(lessonSelectors.getAddLessonToCourseStatus);

        if (isPreparedLesson) {
            if (status !== LOADING_STATUSES.LOADING) {
                const task: Task<any> = yield spawn(copyPreparedLessonToCourse);
                yield join(task);
            } else {
                yield take(lessonActions.setAddLessonToCourseSuccess);
            }

            clesson = yield select(lessonSelectors.getLesson);
        }

        const { problems, ...lesson } = clesson;
        const lessonData = {
            ...lesson,
            problems: problems.map(({ problem, ...link }) => ({ ...link, problem: { id: problem?.id } })),
        };

        // иногда пользователь успевает закрыть модалку до завершения сохранения
        // в этом случае обновлять lesson в store не нужно
        const { newLesson } = yield race({
            newLesson: call(saveCLessonRequest, lessonData as FullCLesson, onSuccess),
            clearEditor: take(lessonActions.clearEditor),
        });

        if (newLesson) {
            yield put(lessonActions.setLesson({ lesson: newLesson }));
        }
    } catch (error) {
        console.error(error);

        yield handleDeleteOrEditError(error, clesson.clesson?.id!);
    }
}

function* saveCLessonRequest(lessonData: FullCLesson, onSuccess?: onSuccessCallback) {
    const newLesson: FullCLesson = yield call(api.putSaveCLesson, lessonData, { expandProblems: true });
    yield put(lessonActions.lessonUpdated());

    if (onSuccess) {
        onSuccess(newLesson.id, newLesson);
    }

    return newLesson;
}

function* copyPreparedLessonToCourse() {
    yield put(lessonActions.setAddLessonToCourseLoading());
    const courseId: number = yield select(getLessonViewCourseId);

    const clessonId: number = yield putResolve(lessonActions.addLessonToCourseAsync() as any);
    const fullClessonData: Awaited<ReturnType<typeof api.getFullCLesson>> = yield call(
        api.getFullCLesson,
        clessonId,
        courseId
    );

    yield call(mergeClesson, fullClessonData.clesson);
    yield put(lessonActions.setAddLessonToCourseSuccess());
}
