import { CLASS_NUMBER_SELECTOR_MODE, CLASS_NUMBER_SELECTOR_VIEW } from '../class-number-selector/types';

import { CLASS_LETTER_EDITOR_VIEW } from './__letter-editor/types';
import { CLASSNAME_EDITOR_MODE, CLASSNAME_EDITOR_VIEW } from './types';

export const MAX_LETTER_LENGTH = 50;
export const LAST_CLASS_NUMBER = 4; // переводить в 5ый класс пока нельзя

export const numberEditorViewMap = {
    [CLASSNAME_EDITOR_VIEW.PLAIN]: CLASS_NUMBER_SELECTOR_VIEW.PLAIN,
    [CLASSNAME_EDITOR_VIEW.DEFAULT]: CLASS_NUMBER_SELECTOR_VIEW.DEFAULT,
};

export const letterEditorViewMap = {
    [CLASSNAME_EDITOR_VIEW.PLAIN]: CLASS_LETTER_EDITOR_VIEW.PLAIN,
    [CLASSNAME_EDITOR_VIEW.DEFAULT]: CLASS_LETTER_EDITOR_VIEW.WITH_QUOTES,
};

export const numberEditorModeMap = {
    [CLASSNAME_EDITOR_MODE.EDIT]: CLASS_NUMBER_SELECTOR_MODE.EDIT,
    [CLASSNAME_EDITOR_MODE.CREATE]: CLASS_NUMBER_SELECTOR_MODE.CREATE,
};
