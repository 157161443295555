import b from 'b_';
import React from 'react';

import './inline-input_type_unknown.scss';

const InlineInputTypeUnknown = () => {
    return <span className={b('inline-input', { type: 'unknown' })} />;
};

export default InlineInputTypeUnknown;
