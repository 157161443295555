// @ts-ignore
import { CLESSON_MODES } from '@yandex-int/k-common/constants';
import { ExternalLmsServerDates } from '@yandex-int/k-common/typings';
import { IntlShape } from 'react-intl';

import { STUDENTS_ERRORS } from 'common.components/students-list/typings';
import { LessonDate } from 'utils/lesson/lesson-time/types';

import * as constants from './constants';
import { ACCORDION_ITEMS, ASSIGNMENT_STATES } from './typings';

export const setAccordionItem = (accordionItem: ACCORDION_ITEMS | null) =>
    <const>{ type: constants.SET_ACCORDION_ITEM, accordionItem };

export const setAssignmentState = (assignmentState: ASSIGNMENT_STATES) =>
    <const>{ type: constants.SET_ASSIGNMENT_STATE, assignmentState };

export const setToDefault = () => <const>{ type: constants.SET_TO_DEFAULT };

export const setLessonMode = (lessonMode: CLESSON_MODES | null) =>
    <const>{ type: constants.SET_LESSON_MODE, lessonMode };

export const setAssignDate = (lessonDate: LessonDate | null) =>
    <const>{ type: constants.SET_ASSIGN_DATE, value: lessonDate };

export const setDeadlineDate = (lessonDate: LessonDate | null) =>
    <const>{ type: constants.SET_DEADLINE_DATE, value: lessonDate };

export const setAssignDateError = (errorMessage: string | null) =>
    <const>{ type: constants.SET_ASSIGN_DATE_ERROR, errorMessage };

export const setDeadlineDateError = (errorMessage: string | null) =>
    <const>{ type: constants.SET_DEADLINE_DATE_ERROR, errorMessage };

export const setDuration = (duration: number | null) => <const>{ type: constants.SET_DURATION, duration };

export const setDurationError = (errorMessage: string | null) =>
    <const>{ type: constants.SET_DURATION_ERROR, errorMessage };

export const setSelectedStudents = (studentsIds: Array<number>) =>
    <const>{ type: constants.SET_SELECTED_STUDENTS, studentsIds };

export const setStudentsError = (error: STUDENTS_ERRORS | null) => <const>{ type: constants.SET_STUDENTS_ERROR, error };

export const loadExternalLmsAvailableDatesRequest = (teamId: number | null, intl: IntlShape) =>
    <const>{ type: constants.LOAD_EXTERNAL_LMS_AVAILABLE_DATES_REQUEST, teamId, intl };

export const loadExternalLmsAvailableDatesSuccess = (dates: ExternalLmsServerDates) =>
    <const>{ type: constants.LOAD_EXTERNAL_LMS_AVAILABLE_DATES_SUCCESS, dates };

export const loadExternalLmsAvailableDatesError = () =>
    <const>{ type: constants.LOAD_EXTERNAL_LMS_AVAILABLE_DATES_ERROR };

export const handleExternalLmsCheckboxToggle = (checked: boolean, teamId: number | null, intl: IntlShape) =>
    <const>{ type: constants.HANDLE_EXTERNAL_LMS_CHECKBOX_TOGGLE, checked, teamId, intl };

export const forceShouldAssignInExternalLms = () => <const>{ type: constants.FORCE_SHOULD_ASSIGN_IN_EXTERNAL_LMS };

export const setExternalLmsDatesError = (errorMessage: string | null) =>
    <const>{ type: constants.SET_EXTERNAL_LMS_DATES_ERROR, errorMessage };
