const RE_DECIMAL_FRACTION = /^\s*(0|-?0[.,]\d+|-?[1-9]\d*(?:[.,]\d+)?)\s*$/;
const RE_SIMPLE_FRACTION = /^\s*(-?)((?:[1-9]\d*\s+)?)([1-9]\d*)\s*\/\s*([1-9]\d*)\s*$/;

export function distance(a, b) {
    return Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));
}

export function validateRational(value) {
    value = String(value).trim();

    return value === '' || RE_DECIMAL_FRACTION.test(value) || RE_SIMPLE_FRACTION.test(value);
}

export function convertRationalToKatex(value) {
    if (RE_DECIMAL_FRACTION.test(value)) {
        return value.replace(',', '.');
    } else if (RE_SIMPLE_FRACTION.test(value)) {
        return value.replace(RE_SIMPLE_FRACTION, '\\displaystyle{$1}{$2}{\\frac{$3}{$4}}');
    }

    return '';
}
