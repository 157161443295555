import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import { WrappedComponentProps, injectIntl } from 'react-intl';

import { DEFAULT_VIEWPORT_META } from '../constants';

const PageTitle = ({ intl }: WrappedComponentProps, { service }: { service: string }) => {
    const serviceName = intl.formatMessage({ id: `page.serviceName.${service}` });
    const description = intl.formatMessage({ id: `page.description.${service}` });

    return (
        <Helmet defaultTitle={serviceName} titleTemplate={`%s — ${serviceName}`}>
            <meta charSet="utf-8" />
            <meta content="IE=edge" httpEquiv="X-UA-Compatible" />
            <meta content="" name="keywords" />
            <meta content={description} name="description" />
            <meta content={description} property="og:description" />
            <meta content={DEFAULT_VIEWPORT_META} name="viewport" />
            <meta content="Im1JV4_iFHnf0eqISHrOOjFlR7krSYZKG5487Oq7CpE" name="google-site-verification" />
        </Helmet>
    );
};

PageTitle.contextTypes = {
    service: PropTypes.string.isRequired,
};

export default injectIntl(PageTitle);
