import React from 'react';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';

// TODO: EDUCATION-11293 ts fix
// @ts-ignore
import Markdown from '../markdown/_extension/markdown_extension_xcontent';

interface Props extends WrappedComponentProps {
    id: string;
    values?: React.ComponentProps<typeof FormattedMessage>['values'];
}

const FormattedMarkdownMessage = ({ intl, ...props }: Props) => {
    const { id, values } = props;

    return <Markdown>{intl.formatMessage({ id }, values)}</Markdown>;
};

export default injectIntl(FormattedMarkdownMessage);
