import * as constants from './constants';

const initialState = {};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.SET_NOTIFICATION:
            return { ...action.notification, visible: true };
        case constants.HIDE_NOTIFICATION:
            return { gravity: state.gravity, visible: false };
        default:
            return state;
    }
};

export default reducer;
