import cx from 'classnames';
import { Close } from 'education-icons';
import React from 'react';

import { ModalContext } from '../context';

import './modal__close-button.scss';

interface Props {
    className?: string;
}

const ModalCloseButton = ({ className }: Props) => (
    <ModalContext.Consumer>
        {({ requestClose }) => (
            <button className={cx('modal__close-button', className)}>
                <span className="modal__close-button-tapzone" onClick={requestClose}>
                    <Close className="modal__close-button-icon" />
                </span>
            </button>
        )}
    </ModalContext.Consumer>
);

export { ModalCloseButton };
