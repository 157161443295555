import { postFetch } from '../fetch';
import { apiConfig } from '../config';

export interface PostCreateClassRequestPayload {
    grade?: string | number;
    name?: string;
}

export interface PostCreateClassResponse {
    classId: string;
}

export function postCreateClass(payload: PostCreateClassRequestPayload): Promise<PostCreateClassResponse> {
    const { apiPostCreateClass } = apiConfig.getStaticUrls();

    return postFetch(apiPostCreateClass, payload);
}
