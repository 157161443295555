import { Me } from '@yandex-int/k-common/typings/me';
import b from 'b_';
import { pixelsActions } from 'platform-components';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import ym from 'react-yandex-metrika';
import { bindActionCreators, Dispatch } from 'redux';

import UserPopup from 'common.components/user-popup/user-popup';
import { getSourceProject } from 'utils/get-data-from-search-parameters';

import withUserRouter, { InjectedProps as WithUserRouterProps } from '../../hocs/user-router/user-router';
import RegistrationHeader from '../registration-header/registration-header';

import DefaultRegistration from './_theme/registration_theme_default';

import './registration.scss';

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

interface Props extends DispatchProps, WithUserRouterProps, WrappedComponentProps {
    me: Me | null;
}

export class Registration extends React.Component<Props> {
    static contextTypes = {
        staticUrls: PropTypes.object.isRequired,
    };

    onTeacherButtonClick = () => {
        const { me, trackButtonClick, staticUrls } = this.props;
        const goal = 'platform_teacher_registration_start';

        trackButtonClick({ goal });

        ym('reachGoal', goal, {
            id: me?.id,
            from_project: getSourceProject(),
        });

        window.location.href = staticUrls.labLibraryMain;
    };

    onParentButtonClick = () => {
        const { me, staticUrls, history } = this.props;

        ym('reachGoal', 'platform_parent_registration_start', { id: me?.id, from_project: getSourceProject() });
        ym('reachGoal', 'platform_parent_registration_finished', { from_project: getSourceProject() });

        window.location.href = staticUrls.parent + history.location.search;
    };

    render() {
        const { intl } = this.props;
        const title = intl.formatMessage({ id: 'registrationPage.title' });
        const description = intl.formatMessage({ id: 'registration.pageDescription' });

        return (
            <div className={b('registration')}>
                <Helmet title={title}>
                    <meta content={description} name="description" />
                    <meta content={description} property="og:description" />
                </Helmet>
                <RegistrationHeader mix="registration__header" />
                <DefaultRegistration
                    onParentButtonClick={this.onParentButtonClick}
                    toTeacherRegistration={this.onTeacherButtonClick}
                />
                <UserPopup />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            trackButtonClick: pixelsActions.trackAllPixelsButtonClick,
        },
        dispatch
    );

export default injectIntl(withUserRouter(connect(null, mapDispatchToProps)(Registration)));
