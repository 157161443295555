import { CourseTemplate, Me, TeacherProfile, Team } from '@yandex-int/k-common';
import {
    getCoursesTemplates,
    postAddCourses,
    postClassBulkCreateStudents,
    postCreateClass,
    patchClass,
    postSetSchool,
    putAuthByCode,
    getClasses,
    getMe,
    getTeacherProfile,
    postMakeMeTeacher,
} from '@yandex-int/k-common/client/api';
import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';
import { showNetworkError } from 'platform-components';
import { getIsTeacher, getMe as getMeSelector } from 'platform-components/src/components/user/selectors';
import { setToLocalStorage } from 'platform-components/utils';
import ym from 'react-yandex-metrika';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { getTeam } from 'common.components/classes/saga';
import { getStudents } from 'common.components/classes/selectors';
import { AddressWithId } from 'common.components/school-form/typings';
import { School, iClass } from 'common.components/typings';
import * as userActions from 'common.components/user/actions';
import { getSchoolId } from 'common.components/user/selectors';
import { SECONDARY_SCHOOL_FIRST_CLASS } from 'common.components/user/user.constants';
import { actions as coursesActions } from 'store/courses/actions';
import { clearTeacherCoursesByClass } from 'store/teacher-courses/actions';
import { mapStudentsForSave } from 'utils/map-students-for-save';
import { getTeamIdFromPath } from 'utils/path';
import { YM_LOCATION } from 'utils/ym-constants';

import { teamBuilderActions } from './actions';
import { TEAM_BUILDER_LS } from './constants';
import { mapStudentsIds } from './utils';

export function* teamBuilderSagasWatcher(): Generator<unknown, void> {
    yield all([
        yield takeLatest(teamBuilderActions.setSchoolData, setSchoolDataSaga),
        yield takeLatest(teamBuilderActions.submitRequest, finishSubmitSaga),
        yield takeLatest(teamBuilderActions.submitCode, submitCodeSaga),
        yield takeLatest(teamBuilderActions.submitMesRequest, submitMesSchoolSaga),
    ]);
}

function* setSchoolDataSaga(action: ReturnType<typeof teamBuilderActions.setSchoolData>) {
    setToLocalStorage(TEAM_BUILDER_LS, JSON.stringify(action.payload));
}

function* createTeam(teamData: { teamLetter: string; teamLevel: number }) {
    const { classId } = yield call(postCreateClass, {
        grade: teamData.teamLevel,
        name: teamData.teamLetter,
    });

    return classId;
}

function* patchTeam(teamId: number, teamData: { teamLetter: string; teamLevel: number }) {
    yield call(patchClass, teamId, {
        number: teamData.teamLevel,
        letter: teamData.teamLetter,
    });
}

function* setSchool(address: AddressWithId | null, school: School | null) {
    if (!address || !school) {
        throw 'Trying to bind empty school or address';
    }

    const schoolId: number | null = yield select(getSchoolId);

    if (schoolId) {
        return;
    }

    yield call(postSetSchool, {
        // eslint-disable-next-line prettier/prettier
        geoId: address?.geoid!, // TODO: undefined?
        schoolId: school?.id,
        school: school?.title,
    });

    const me: Me = yield call(getMe);
    const teacherProfile: TeacherProfile = yield call(getTeacherProfile);

    yield put(userActions.setMe(me));
    yield put(userActions.setTeacherProfile(teacherProfile));
}

function* addSubjects(teamId: number, grade: number | null, subjectsIds: Array<number> | null) {
    let templateIds = subjectsIds;
    // если не были переданы данные предметов - грузим их
    if (!templateIds) {
        const courseTemplate: Array<CourseTemplate> = yield call(getCoursesTemplates, {
            classId: teamId,
            grade: grade,
        });
        templateIds = courseTemplate.map((template: CourseTemplate) => template.id);
    }
    yield call(postAddCourses, { classId: teamId, templateIds });
    yield put(coursesActions.getCoursesByTeamRequest({ teamId }));
    yield put(clearTeacherCoursesByClass(teamId));

    // Нам нужно знать список предметов, чтобы включать некоторые фичи
    // только тем, у кого есть определенный предмет
    // При изменении классов список предметов может меняться
    yield put(coursesActions.getCoursesSubjectsRequest());
}

function* saveStudents(students: Array<string>, teamId: number) {
    const studentsWithValidatedNames = mapStudentsForSave(students);
    // дожидаемся завершения запроса на создание новых учеников и запрашиваем класс с новым списком учеников
    yield call(postClassBulkCreateStudents, teamId, studentsWithValidatedNames);
}

// TODO: переписать тут
// eslint-disable-next-line max-params
function* callStudentMetrika(
    addedStudentsCount: number,
    oldStudentsCount: number,
    teamId: number,
    meId: number,
    location: YM_LOCATION | undefined
) {
    // TODO: тут на реге вообще другая метрика
    ym('reachGoal', 'student_created', {
        studentsNumber: addedStudentsCount,
        team_id: teamId,
        user_id: meId,
        before_students_count: oldStudentsCount,
        location,
    });
}

function* submitMesSchoolSaga(action: ReturnType<typeof teamBuilderActions.submitMesRequest>) {
    const { hasSchool, schoolData, onSuccess } = action.payload;

    try {
        const isTeacher: boolean = yield select(getIsTeacher);

        if (!isTeacher) {
            yield call(postMakeMeTeacher);
        }

        if (!hasSchool) {
            yield setSchool(schoolData.address, schoolData.school);
        }

        if (onSuccess) {
            onSuccess(schoolData);
        }
    } catch (error) {
        console.error(error);

        yield put(teamBuilderActions.submitError());
        yield put(showNetworkError(error));
    }
}

// eslint-disable-next-line complexity
function* finishSubmitSaga(action: ReturnType<typeof teamBuilderActions.submitRequest>) {
    const {
        hasSchool,
        schoolData: { address, school },
        students: studentsToAdd,
        teamData,
        teamPatchData,
        subjectsIds,
        location,
        hasLetter,
        onSuccess,
        onTeamCreateSuccess,
    } = action.payload;
    try {
        const isTeacher: boolean = yield select(getIsTeacher);

        if (!isTeacher) {
            yield call(postMakeMeTeacher);
        }

        if (!hasSchool) {
            yield setSchool(address, school);
        }

        let teamId: number | null;
        // если есть данные класса - создаем класс и курсы
        if (teamData) {
            teamId = yield createTeam(teamData);

            if (teamId) {
                yield put(teamBuilderActions.clearTeamData());
                const teams: Team[] = yield call(getClasses);
                yield put(userActions.setTeams(teams));

                if (onTeamCreateSuccess) {
                    onTeamCreateSuccess(teamId);
                }
            }
        } else {
            teamId = getTeamIdFromPath();

            if (!teamId) {
                // На 17.02.2023 единственный сценарий при котором иногда не получить classId
                // из url - это создание первого класса (в рамках публичной витрины).
                // Если класс уже есть, то создать новый можно только со страницы классов,
                // а там в урле всегда актуальный id.
                const teams: Team[] = yield call(getClasses);
                teamId = teams && teams.length && teams[0].id;
            }
        }

        if (!teamId) {
            throw Error('Cannot continue team building without team id');
        }

        if (!hasLetter && teamPatchData) {
            yield patchTeam(teamId, teamPatchData);

            const teams: Team[] = yield call(getClasses);
            yield put(userActions.setTeams(teams));
        }

        // если передан список предметов либо создали класс в началке
        if (subjectsIds || (teamData && teamData.teamLevel < SECONDARY_SCHOOL_FIRST_CLASS)) {
            yield addSubjects(teamId, teamData?.teamLevel || null, subjectsIds || null);
        }

        let addedStudentsIds: number[] | null = null;
        const oldStudents: ReturnType<typeof getStudents> = yield select(getStudents, { classId: teamId });
        if (studentsToAdd) {
            const me: Me = yield select(getMeSelector);
            yield saveStudents(studentsToAdd, teamId);
            const team: iClass = yield call(getTeam, teamId);
            const addedStudents = oldStudents ? differenceWith(team.students, oldStudents, isEqual) : team.students;
            addedStudentsIds = mapStudentsIds(addedStudents || []);
            yield callStudentMetrika(
                addedStudentsIds.length,
                team && Array.isArray(team.students) ? team.students.length - addedStudentsIds.length : 0,
                teamId,
                me.id,
                location
            );
        }

        if (onSuccess) {
            onSuccess(teamId, addedStudentsIds);
        }

        yield put(teamBuilderActions.submitSuccess());
    } catch (error) {
        console.error(error);

        yield put(teamBuilderActions.submitError());
        yield put(showNetworkError(error));
    }
}

function* submitCodeSaga(action: ReturnType<typeof teamBuilderActions.submitCode>) {
    const { code, onSuccess } = action.payload;

    const isTeacher: boolean = yield select(getIsTeacher);

    if (!isTeacher) {
        yield call(postMakeMeTeacher);
    }

    try {
        const team: Team = yield call(putAuthByCode, code!);

        const teams: Team[] = yield call(getClasses);
        yield put(userActions.setTeams(teams));

        yield call(getTeam, team.id);

        if (onSuccess) {
            onSuccess(team.id);
        }

        yield put(teamBuilderActions.submitCodeSuccess());

        ym('reachGoal', 'teacher_my_students_enter_class_code', {
            team_id: team.id,
            code,
            validation: 'valid_code',
        });
    } catch (error) {
        console.error(error);

        ym('reachGoal', 'teacher_my_students_enter_class_code', {
            code,
            validation: 'invalid_code',
        });

        yield put(teamBuilderActions.submitCodeError());
    }
}
