export function reportNetworkError(error) {
    if (window?.Ya?.Rum?.logError) {
        let message;

        if (error) {
            if (error.message) {
                message = error.message;
            } else if (typeof error.responseJSON === 'object') {
                try {
                    // Берем только первую ошибку
                    const { source, code } = error.responseJSON.errors[0];

                    message = `${error.status}:${source}:${code}`;
                } catch (e) {
                    message = 'Unknown network error (Bad structure)';
                }
            }
        }

        window.Ya.Rum.logError({ level: 'error', message }, error);
    }
}
