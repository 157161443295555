import Footer from 'education-components/dist/footer';
import { getMe } from 'platform-components/src/components/user/selectors';
import { mePropType } from 'platform-components/utils';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import ym from 'react-yandex-metrika';
import { compose } from 'redux';

import 'education-components/dist/footer/index.css';

class IndexFooter extends React.Component {
    static propTypes = {
        intl: PropTypes.object.isRequired,
        me: mePropType,
    };

    static contextTypes = {
        environment: PropTypes.string.isRequired,
    };

    sendGoalFooterHelp = () => {
        ym('reachGoal', 'footer_help');
    };

    sendGoalFooterLegal = () => {
        ym('reachGoal', 'footer_legal');
    };

    getRole() {
        const { me } = this.props;
        let role = 'all';

        if (me) {
            if (me.is_teacher || me.is_content_manager) {
                role = 'teacher';
            } else if (me.is_student) {
                role = 'student';
            }
        }

        return role;
    }

    render() {
        const { environment } = this.context;
        const { intl } = this.props;
        const helpInstanceType = environment === 'production' ? environment : 'testing';
        const role = this.getRole();

        const helpLink = intl.formatMessage({ id: `footer.helpLink.education.${helpInstanceType}.${role}` });
        const legalLink = intl.formatMessage({ id: 'footer.agreementLink.school' });
        const webinarLink = intl.formatMessage({ id: 'webinarPromo.subscribeLink' });

        const shareProps = {
            theme: {
                size: 's',
                counter: false,
                services: 'vkontakte,twitter,odnoklassniki,moimir',
            },
        };

        return (
            <Footer
                borderless
                links={{ legal: legalLink, help: helpLink, webinar: webinarLink }}
                logos={false}
                mix="index__footer"
                onClickHelp={this.sendGoalFooterHelp}
                onClickLegal={this.sendGoalFooterLegal}
                shareProps={shareProps}
            />
        );
    }
}

const mapStateToProps = (storeState) => {
    return {
        me: getMe(storeState),
    };
};

export default compose(connect(mapStateToProps), injectIntl)(IndexFooter);
