import b from 'b_';
import { SimpleText } from 'platform-components/common';
import React from 'react';

//@ts-ignore
import FormView from 'common.components/form-view/form-view';

import { MODES } from '../school-form.constants';

import './school-form__send-button.scss';

interface Props {
    disabled: boolean;
    mode: MODES;
    onSubmit: () => void;
}

export const SchoolFormSendButton = ({ disabled, mode, onSubmit }: Props) => (
    <FormView.CardSection>
        <FormView.Button
            disabled={disabled}
            mix={b('school-form', 'send-button', { 'with-offset': mode === MODES.SUGGEST })}
            onClick={onSubmit}
        >
            <SimpleText id={`schoolForm.sendButton.${mode}`} />
        </FormView.Button>
    </FormView.CardSection>
);
