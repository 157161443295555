import React from 'react';

const SvgHamburger = (props) => (
    <svg fill="none" height="1em" viewBox="0 0 24 24" width="1em" {...props}>
        <path
            clipRule="evenodd"
            d="M2 7a1 1 0 011-1h18a1 1 0 110 2H3a1 1 0 01-1-1zm0 5a1 1 0 011-1h18a1 1 0 010 2H3a1 1 0 01-1-1zm1 4a1 1 0 000 2h18a1 1 0 000-2H3z"
            fill="currentColor"
            fillRule="evenodd"
        />
        <path
            clipRule="evenodd"
            d="M2 7a1 1 0 011-1h18a1 1 0 110 2H3a1 1 0 01-1-1zm0 5a1 1 0 011-1h18a1 1 0 010 2H3a1 1 0 01-1-1zm1 4a1 1 0 000 2h18a1 1 0 000-2H3z"
            fill="currentColor"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgHamburger;
