/* eslint-disable react/no-multi-comp */
import { TMarkdownRule, TMarkdownRuleHOC, TMarkdownRules } from '@yandex-int/edu-components/Markdown';
import { startsWith, forEach } from 'lodash';
import qs from 'query-string';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import ym from 'react-yandex-metrika';
import { inlineRegex, sanitizeUrl } from 'simple-markdown';

import { Button } from '../../../button/button';
import { BUTTON_ACCENT, BUTTON_SIZE, BUTTON_TRANSPARENCY_THEME, BUTTON_VIEW } from '../../../button/types';
import { DRILLED_QUERY_PARAMS, MES_QUERY_PARAMS } from '../../../constants';
import Link from '../../../link/link';
import { ATTRIBUTES_REGEX, LINK_HREF_AND_TITLE, LINK_INSIDE } from '../../../markdown/constants';
import { stringToObject } from '../../../markdown/utils';

export const YM_GOALDATA_KEY_PREFIX = 'goaldata_';
export const ROUTE_PARAM_KEY_PREFIX = 'routeParam_';

// параметры которые перекочуют из query в markdown ссылку
export function getStickyQueryParams(search?: string) {
    const params = qs.parse(search || (typeof window !== 'undefined' && window.location?.search) || '');

    const stickyQueryParams: { [index: string]: string | string[] | null } = {};
    for (const p of Object.keys(params)) {
        // @ts-ignore не работает ts-reset/array-includes EDUCATION-34266
        if (p.startsWith('sticky_') || Object.values(MES_QUERY_PARAMS).includes(p) || DRILLED_QUERY_PARAMS[p]) {
            stickyQueryParams[p] = params[p];
        }
    }

    return qs.stringify(stickyQueryParams);
}

export function getLinkTarget(staticUrls?: Record<string, string>, rawTarget?: string) {
    if (!rawTarget || !staticUrls) {
        return rawTarget;
    }

    /**
     * @deprecated Старый синтаксис для вставки урлов из staticUrls
     */
    if (rawTarget.startsWith(':')) {
        return staticUrls[rawTarget.slice(1)];
    }

    function replacer(match: string, staticUrlKey: string) {
        return staticUrls![staticUrlKey] || match;
    }

    const targetWithInjectedStaticUrls = rawTarget.replace(/`\$([\w]+?)`/, replacer);

    return targetWithInjectedStaticUrls;
}

/**
 * HOC с правилом `link` для компонента `Markdown`.
 * [word](URL){: attributes}
 *
 * Пример разметки:
 * ```
 * [my link](./my_awesome_page){: isRouter}
 * ```
 */

type Attributes = {
    isButton?: string;
    isRouter?: string;
    sendGoal?: string;
    goalId?: string;
    view?: BUTTON_VIEW;
    size?: BUTTON_SIZE;
    theme?: BUTTON_TRANSPARENCY_THEME;
    accent?: BUTTON_ACCENT;
    rounded?: string;
} & Record<string, string>;

export const getLinkRule = (locationSearch?: string): TMarkdownRule => ({
    order: 0,
    match: inlineRegex(new RegExp(`^\\[(${LINK_INSIDE})\\]\\(${LINK_HREF_AND_TITLE}\\)(${ATTRIBUTES_REGEX})?`)),
    parse(capture, parse, state) {
        return {
            content: parse(capture[1], state),
            target: capture[2],
            title: capture[3],
            attributes: capture[4] ? stringToObject(capture[4]) : {},
        };
    },
    react(node, output, state) {
        const attributes: Attributes = node.attributes || {};
        const { isButton, isRouter, sendGoal, goalId, view, size, theme, accent, rounded, ...restAttributes } =
            attributes;

        const goalData: Record<string, string> = {};
        const routeParams: Record<string, string> = {};
        const linkAttributes: Record<string, string> = {};

        forEach(restAttributes, (value, key) => {
            if (startsWith(key, YM_GOALDATA_KEY_PREFIX)) {
                const trimmedKey = key.substr(YM_GOALDATA_KEY_PREFIX.length);

                goalData[trimmedKey] = value;
            } else if (startsWith(key, ROUTE_PARAM_KEY_PREFIX)) {
                const trimmedKey = key.substr(ROUTE_PARAM_KEY_PREFIX.length);

                routeParams[trimmedKey] = value;
            } else {
                linkAttributes[key] = value;
            }
        });

        const target = getLinkTarget(state.staticUrls, node.target);
        const sanitizedTarget = sanitizeUrl(target);
        let url: string;

        try {
            // может быть исключение при невалидных параметрах в маркдауне
            url = isRouter ? state.getRouterPath(sanitizedTarget, routeParams) : sanitizedTarget;
        } catch (e) {
            console.error(e);
            return null;
        }

        const stickyQueryParams = getStickyQueryParams(locationSearch);
        if (stickyQueryParams) {
            const separator = url.includes('?') ? '&' : '?';
            url += separator + stickyQueryParams;
        }

        const linkChildren = output(node.content, state);

        const handleClick = () => {
            if (sendGoal) {
                ym('reachGoal', goalId || 'click_link', { from: window.location.href, url, ...goalData });
            }
        };

        const linkProps = {
            key: state.key,
            onClick: handleClick,
            title: node.title,
            ...linkAttributes,
        };

        return (
            <Link isRouter={Boolean(isRouter)} theme={Link.THEME.PRIMARY} to={url} {...linkProps}>
                {isButton ? (
                    <Button
                        accent={accent}
                        rounded={Boolean(rounded)}
                        size={size}
                        transparencyTheme={theme}
                        view={view}
                    >
                        {linkChildren}
                    </Button>
                ) : (
                    linkChildren
                )}
            </Link>
        );
    },
});

export const linkRule = getLinkRule();

export const withRuleLink: TMarkdownRuleHOC = (Markdown) => {
    return ({ rules, ...restProps }) => {
        const { search } = useLocation();

        const extendedRules: TMarkdownRules = useMemo(
            () => ({
                ...rules,
                link: getLinkRule(search),
            }),
            [search, rules]
        );

        return <Markdown {...restProps} rules={extendedRules} />;
    };
};
