import b from 'b_';
import cx from 'classnames';
import { Copyright, Link, Text, useStaticUrls } from 'platform-components';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { useLocation, matchPath } from 'react-router';

import SvgOk from 'common.components/icon/__social/ok';
import SvgVk from 'common.components/icon/__social/vk';

import Agreement from '../__agreement/footer__agreement';
import { THEMES } from '../constants';

import './footer_theme_default.scss';

const FooterThemeDefault = ({ children, intl, mix }) => {
    const staticUrls = useStaticUrls();
    const { pathname } = useLocation();

    const shouldShowPlusDisclaimer = matchPath(pathname, staticUrls.parent);

    // intl не умеет возвращать пустое значение в defaultMessage при отсутствии перевода, поэтому хак через trim()
    const okLink = intl.formatMessage({ id: 'footer.okLink', defaultMessage: ' ' }).trim();
    const vkLink = intl.formatMessage({ id: 'footer.vkLink', defaultMessage: ' ' }).trim();

    return (
        <footer className={cx(b('footer', { theme: THEMES.DEFAULT }), mix)}>
            {children ? (
                children
            ) : (
                <div className="footer__wrapper">
                    <Copyright />
                    <div className="footer__social">
                        {okLink && (
                            <Link target="_blank" to={okLink}>
                                <SvgOk />
                            </Link>
                        )}
                        {vkLink && (
                            <Link target="_blank" to={vkLink}>
                                <SvgVk />
                            </Link>
                        )}
                    </div>
                    <div className="footer__info">
                        <Text id="footer.fgos" />
                    </div>
                    <Agreement />
                    {shouldShowPlusDisclaimer && (
                        <Text
                            className="footer__plus-disclaimer"
                            id="footer.plusDisclaimer"
                            type={Text.TYPE.MARKDOWN}
                        />
                    )}
                </div>
            )}
        </footer>
    );
};

FooterThemeDefault.propTypes = {
    children: PropTypes.node,
    intl: PropTypes.object,
    mix: PropTypes.string,
};

export default injectIntl(FooterThemeDefault);
