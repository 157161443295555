import React, { MouseEvent } from 'react';
import { type WrappedComponentProps, injectIntl } from 'react-intl';

import KeyboardContext from './keyboard-context';
import { KeyboardControls, KEYBOARD_TYPES } from './types';
import { addNavigationEventListener, removeNavigationEventListener } from './utils';

interface Props extends WrappedComponentProps {
    show: boolean;
    keyboardType: KEYBOARD_TYPES;
}

interface State {
    controls: KeyboardControls | null;
}

class UseKeyboard extends React.Component<React.PropsWithChildren<Props>, State> {
    static contextType = KeyboardContext;

    state = {
        controls: null,
    } as State;

    componentDidMount() {
        const { show } = this.props;

        if (show) {
            this.show();
        }

        addNavigationEventListener(this.hide);
    }

    componentDidUpdate(prevProps: Props) {
        const { show } = this.props;

        if (show === prevProps.show) {
            return;
        }

        if (show) {
            this.show();
        } else {
            this.hide();
        }
    }

    componentWillUnmount() {
        this.hide();
        removeNavigationEventListener(this.hide);
    }
    context!: React.ContextType<typeof KeyboardContext>;

    show = () => {
        const { keyboardType } = this.props;

        this.setState({
            //@ts-ignore - EDUCATION-37077: [react-update]: fixme typings: тут this.context.show неверный тайпинг
            controls: this.context.show(keyboardType),
        });
    };

    hide = () => {
        const { controls } = this.state;

        if (controls) {
            controls.hide();
        }
    };

    /**
     * Prevent currently focused input from blur.
     */
    handleMouseDown = (e: MouseEvent) => {
        e.preventDefault();
    };

    render() {
        const { children, intl } = this.props;
        const { controls } = this.state;

        if (controls) {
            const controlsElement = controls.render(
                <aside
                    aria-label={intl.formatMessage({ id: 'a11y.keyboard.label' })}
                    className="keyboard__content"
                    onMouseDown={this.handleMouseDown}
                >
                    {children}
                </aside>
            );

            return controlsElement || null;
        }

        return null;
    }
}

export default injectIntl(UseKeyboard);
