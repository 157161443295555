import noop from 'lodash/noop';
import { createContext, useContext } from 'react';

interface ProblemAnalyticsContext {
    ymOnAudioPlay: () => void;
    ymOnSpoilerToggle: (open: boolean) => void;
}

const ProblemAnalyticsContext = createContext<ProblemAnalyticsContext>({
    ymOnAudioPlay: noop,
    ymOnSpoilerToggle: noop,
});

export const ProblemAnalyticsProvider = ProblemAnalyticsContext.Provider;

export const useProblemAnalyticsContext = () => useContext(ProblemAnalyticsContext);
