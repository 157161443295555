const { COURSE_DISPLAY_LOCATION } = require('../constants');

function getSpecCourse(course) {
    return course.display_location === COURSE_DISPLAY_LOCATION.HEADER;
}

function getSpecCourses(courses) {
    if (!courses) {
        return [];
    }

    return courses.filter(getSpecCourse);
}

module.exports = {
    getSpecCourse,
    getSpecCourses
};
