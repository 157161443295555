import b from 'b_';
import cx from 'classnames';
import { Checkmark as IconCheckmark } from 'education-icons';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { copyTextToClipboard } from '../../utils/copy-text-to-clipboard/copy-text-to-clipboard';
import { isTouchDevice } from '../../utils/device';
import { useLatestTimeout } from '../../utils/hooks';
import { Button } from '../button/button';
import { BUTTON_SIZE, BUTTON_VIEW } from '../button/types';
import { Tooltip } from '../LegoTooltip/LegoTooltip';
import Text from '../text/text';

import './copy-button.scss';
interface CopyButtonProps {
    buttonText?: string;
    className?: string;
    onButtonClick?: (e: React.MouseEvent) => void;
    text: string;
}

export const CopyButton = ({ buttonText, className, onButtonClick, text }: CopyButtonProps) => {
    const buttonRef = useRef<HTMLDivElement | null>(null);
    const [visible, setTooltipVisible] = useState(false);
    const takeLatestTimeout = useLatestTimeout();

    const intl = useIntl();

    const onClick = useCallback(
        (e: React.MouseEvent) => {
            copyTextToClipboard(text);
            setTooltipVisible(true);

            if (onButtonClick) {
                onButtonClick(e);
            }

            if (isTouchDevice) {
                takeLatestTimeout(() => {
                    setTooltipVisible(false);
                }, 1000);
            }
        },
        [text, onButtonClick, takeLatestTimeout]
    );

    const hoverHandlers = useMemo(() => {
        if (isTouchDevice) {
            return {};
        }

        return {
            onMouseLeave: () => setTooltipVisible(false),
        };
    }, []);

    return (
        <div className={cx('copy-button', className)}>
            <Button
                className="copy-button__button"
                innerRef={buttonRef}
                onClick={onClick}
                size={BUTTON_SIZE.M}
                view={BUTTON_VIEW.ACTION}
                {...hoverHandlers}
            >
                {buttonText || intl.formatMessage({ id: 'copyComponent.copyLink' })}
            </Button>
            <Tooltip
                anchor={buttonRef}
                className="copy-button__tooltip"
                direction="top"
                hasTail
                mainOffset={15}
                size="m"
                visible={visible}
            >
                <div className="copy-button__tooltip-content">
                    <IconCheckmark className={b('copy-button__tooltip-icon', { success: true })} />
                    <Text id={'copyComponent.linkCopied'} />
                </div>
            </Tooltip>
        </div>
    );
};
