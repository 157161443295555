import { TEACHER_QUERY_PARAMS } from 'platform-components';
import { getDrilledQueryParams, ParamConfig } from 'platform-components/src/utils/get-drilled-query-params';

const DRILLED_QUERY_PARAMS_CONFIG: ParamConfig = {
    [TEACHER_QUERY_PARAMS.REFERRER_LINK_ID]: {
        urls: true,
    },
};
/**
 * фильтрует квери параметры, оставляя только разрешенные для проброса на выбранный путь или на все пути
 * @param {String} search query строка
 * @param {String} [path] путь по которому нужно пробросить query параметры
 * */
export const getTeacherDrilledQueryParams = (search: string, path?: string) => {
    return getDrilledQueryParams({ search, path, config: DRILLED_QUERY_PARAMS_CONFIG });
};
