import * as constants from './constants';

export const setSalesCode = (salesCode: string | null) =>
    <const>{
        type: constants.SET_SALES_CODE,
        salesCode,
    };

export const toggleSalesCodeModal = (showShowModal: boolean) =>
    <const>{
        type: constants.SHOULD_SHOW_MODAL,
        showShowModal,
    };
