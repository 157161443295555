import { isDigital } from 'platform-components/src/utils/input-validators';

export function canBeParentCode(code) {
    const regexp = /^[zZЯя]\d*$/i;

    return regexp.test(code);
}

export function fomatParentCode(code) {
    return code.replace(/^.{1}/, 'Я');
}

export function canBeSchoolCode(code) {
    return isDigital(code);
}

export function isPasswordLengthValid(password, length) {
    return Boolean(password) && password.length === length;
}
