import { pixelsSagasWatcher } from 'platform-components';
import { awardsSagasWatcher } from 'platform-components/src/components/awards/saga';
import { all } from 'redux-saga/effects';

import { childManagementWatcher } from 'common.components/child-management/saga';
import { deleteLessonSagasWatcher } from 'common.components/lesson/sagas';
import { schoolFormSagasWatcher } from 'common.components/school-form/saga';
import { teamBuilderSagasWatcher } from 'common.components/team-builder/saga';
import { userSagasWatcher } from 'common.components/user/saga';
import { coursesTemplatesSagasWatcher } from 'store/courses-templates/saga';
import { externalLmsSagasWatcher } from 'store/external-lms-connect/saga';
import { externalServicesSagasWatcher } from 'store/external-lms/saga';

import { applyCodeSagaWatcher } from '../bundles/index/components/apply-code-page/saga';
import { externalLmsSaga } from '../bundles/index/components/external-lms/saga';
import { parentRegistrationWatcher } from '../bundles/index/components/parent-registration/saga';
import { startSagasWatcher } from '../bundles/index/components/start-screen/saga';
import { approveSubscribeWatcher } from '../bundles/index/components/subscription-handler/saga';
import { teacherRegistrationPageSagasWatcher } from '../bundles/index/components/teacher-registration-page/saga';
import { teacherRegistrationSagasWatcher } from '../bundles/index/components/teacher-registration/saga';

import commonWatchers from './common-watchers';

export default function* () {
    yield all([
        ...commonWatchers,
        userSagasWatcher(),
        teacherRegistrationPageSagasWatcher(),
        teacherRegistrationSagasWatcher(),
        approveSubscribeWatcher(),
        startSagasWatcher(),
        childManagementWatcher(),
        schoolFormSagasWatcher(),
        coursesTemplatesSagasWatcher(),
        applyCodeSagaWatcher(),
        externalLmsSaga(),
        externalServicesSagasWatcher(),
        externalLmsSagasWatcher(),
        parentRegistrationWatcher(),
        pixelsSagasWatcher(),
        awardsSagasWatcher(),
        deleteLessonSagasWatcher(),
        teamBuilderSagasWatcher(),
    ]);
}
