import { SimpleCallback } from 'platform-components';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ym from 'react-yandex-metrika';

import SchoolForm from 'common.components/school-form/school-form';
import { SchoolData } from 'common.components/school-form/typings';
import { YM_LOCATION } from 'utils/ym-constants';

import { teamBuilderActions } from '../actions';
import * as selectors from '../selectors';

interface Props {
    location?: YM_LOCATION;
    onAssign: () => void;
    onNext: SimpleCallback;
}

export const TeamBuilderSchool = (props: Props) => {
    const { onAssign, onNext, location } = props;
    const dispatch = useDispatch();

    const schoolData = useSelector(selectors.getSchoolData);

    const handleChange = ({ school, address }: SchoolData) => {
        dispatch(
            teamBuilderActions.setSchoolData({
                school,
                address,
            })
        );
        onAssign();
    };

    const handleNext = () => {
        if (location) {
            ym('reachGoal', 'teacher_onboarding_chose_school', { location: location });
        }
        onNext();
    };

    return (
        <SchoolForm
            canAddOwnSchool={true}
            onChange={handleChange}
            onSubmit={handleNext}
            shouldDisableAddress={false}
            shouldHideAlternativeRegistration={true}
            shouldShowPersonalData={false}
            value={schoolData}
            ymLocation={location}
        />
    );
};
