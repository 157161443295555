import { ISODateTime } from './common';
// @ts-ignore
import { SUBJECTS_SLUGS } from '../constants';
import { FEATURES } from './featureFlags';
import { TEACHER_HEADER_MENU_ITEMS } from './teacher-header-menu';
import { VerdictTheme } from './api/models/verdict';
import { PROBLEM_ANSWER_STATUS, ROVER_ANSWER_STATUS } from './api/models';
import { LESSON_STATUS } from './api/defs';

export interface BunkerNodes {
    // тип определен в platform-components/src/components/app-factory/bunker-types
    stories: unknown;
    teacherChatGpt: TeacherChatGpt;
    // тип находится в modules
    teacherReserveOnboarding: unknown;
    // тип находится в modules
    teacherReserveInfo: unknown;
    // тип находится в modules;
    onboarding: unknown;
    specProjectConfig: SpecProjectConfig;
    subjectConfig: SubjectConfig;
    subjectPages: SubjectPages;
    subjectPagesExperimental: SubjectPages;
    formsConfig?: FormsConfig;
    olympStubs?: OlympStubs;
    calendarPlanShowcaseConfig?: CalendarPlanShowcaseConfig;
    featureFlags: FeatureFlags;
    pluckerConfig?: PluckerConfig;
    portfolioConfig?: PortfolioConfig;
    teacherTrainingCoursesConfig?: TeacherTrainingCoursesConfig;
    teacherExams?: TeacherExams;
    stickers: StickersConfig;
    teacherBanners: TeacherBanners;
    olympsConfig: OlympsConfig;
    likesConfig: LikesConfig;
    diagnosticConfig: DiagnosticConfig;
    quizConfig: QuizConfig;
    parentBanners: ParentBanners;
    studentBanners: StudentBanners;
    parentAddCourses: ParentAddCourses;
    verdictThemes: VerdictThemes;
    verdictConfig: VerdictConfig;
    publicLessonsConfig: PublicLessonsConfig;
    mailPermissionConfig: MailPermissionConfig;
    webinars: Webinars;
    coursesLandingData: CoursesLandingData;
    aboutPlusKidsConfig: AboutPlusKidsConfig;
    studentOnboardingBanners: StudentOnboardingBanners;
    mobileVersion: MobileVersion;
    diagnosticsLessons: { [clessonId in number]?: string /* slug */ | { slug: string, consultation?: string } };
    doodle: Doodle;
    webViewBaseUrlDict: WebViewBaseUrlDict;
}

interface TeacherChatGpt {
    completionOptions: {
        temperature: number;
    };
    systemPromt: string;
    maxContextLength: number;
    suggestGroups: Array<{
        groupName: string;
        suggests: Array<{
            text: string;
        }>;
    }>;
}

export interface SpecProjectTourData {
    accessibleToTeacher?: string;
    slugs: string[];
}

export interface SpecProjectConfigItem {
    slug: string;
    grades: number[];
    subjects: SUBJECTS_SLUGS[];
    redirectAfterRegistration?: string;
    parentRegistrationUrl?: string;
    trialTour: SpecProjectTourData;
    mainTour: SpecProjectTourData;
}

export type SpecProjectConfigList = Record<string, SpecProjectConfigItem>;

export interface SpecProjectConfig {
    list: SpecProjectConfigList;
    isParentRegistrationAvailable: boolean;
    relevantSpecProjectSlugForKids?: string;
}

/* eslint-disable camelcase */

export enum SUBJECT_LIBRARY_TAB_SLUG {
    MAIN = 'main',
    TIMELINE = 'timeline',
    CONTAINER = 'container',
    calendarPlan ='calendar-plan'
}

export interface SubjectLibraryTab {
    name: string;
    slug: SUBJECT_LIBRARY_TAB_SLUG;
}

export interface SubjectLibraryTabsConfig {
    grades: number[];
    list: SubjectLibraryTab[];
}

export interface SearchConfig {
    grade: number;
    namespace_ids: number[];
    query?: string;
}

export interface SubjectConfigItem {
    has_grade_difference: boolean;
    has_stacks: boolean;
    grades_with_skill_stats?: number[];
    grades_with_like_reaction?: number[];
    order: number;
    subject: SUBJECTS_SLUGS;
    grades_to_preselect?: number[];
    search_namespace_by_grade?: SearchConfig[];
    tabs_config: SubjectLibraryTabsConfig[];
    show_library_updates_modal_to_grades?: number[];
    enable_calendar_plan_showcase: boolean;
    show_tabs?: boolean;
    student_order?: number;
    grades_with_student_individual_recommendations?: number[];
}

export interface SubjectConfig {
    initial_subject: SUBJECTS_SLUGS;
    list: SubjectConfigItem[];
    specCourseSubjectsList: string[];
}

export interface SubjectPageItem {
    grade: number;
    description: string;
    subject: SUBJECTS_SLUGS;
    slug: string;
}

export interface SubjectPages {
    slug: string;
    pages: SubjectPageItem[];
}

export interface FormByIdGrade {
    grade: number;
    formId: string;
}

export interface FormsConfigItem {
    enabled: boolean;
    formIdByGrade: FormByIdGrade[];
}

export type FormsConfigList = Record<string, FormsConfigItem>;

export interface FormsConfig {
    student: FormsConfigList;
}

export interface OlympSerbiaCommon {
    landing: string;
    lessonMetaSlugs: string[];
    completedAnswersForModal: number;
    formId: {
        local: string;
        testing: string;
        production: string;
    };
    lessonIds: {
        local: string;
        testing: string;
        production: string;
    };
}

export interface OlympsConfig {
    olymps: OlympsConfigOlymp[];
    studentsAwardsAvailableToTeacher: OlympsConfigStudentsAwardsAvailableToTeacher[];
    achievements: OlympsConfigAchievements;
}

export interface OlympsConfigOlymp {
    slug: string;

    serbiaCommon?: OlympSerbiaCommon;
    student?: OlympsConfigOlympStudent;
    teacher?: OlympsConfigOlympTeacher;
    parent?: OlympsConfigOlympParent;
}

export interface OlympsConfigOlympStudent {
    results?: OlympsConfigOlympStudentResults;
}

export interface OlympsConfigOlympParent {
    grades?: {
        min?: number;
        max?: number;
    };
    results?: OlympsConfigOlympParentResults;
}

export interface OlympsConfigOlympTeacher {
    results?: OlympsConfigOlympTeacherResults;
}

export interface OlympsConfigOlympStudentResultsButton {
    text: string;
    view?: string;

    // ссылка
    url?: string;
    target?: string;

    // если указан - по клику открывается модалка с iframe
    iframe?: string;
    // если указаг вместе с iframe, то добавляет в скрытый вопрос яндекс формы id ученика
    childIdQuestion?: string;

    goalId?: string;
}

export interface OlympsConfigOlympStudentResults {
    awardDescription?: string;
    awardsAssignmentDate?: ISODateTime;
    shouldShowAwardImage?: boolean;
    resultNotificationCustomText?: string;
    certificatePreviewImages?: Partial<Record<string, string>>;
    buttons?: Record<string, OlympsConfigOlympStudentResultsButton[]>;
}

export interface OlympsConfigOlympTeacherResults {
    banners?: OlympsConfigOlympTeacherResultsBanners;
}

export interface OlympsConfigOlympTeacherResultsBanners {
    activeParticipationCertSlug?: string;
    roverBoardgame?: boolean;
    activeParticipantCertificateBannerImgSrc?: string;
    teacherCertificateBannerImgSrc?: string;
    studentsCertificatesBanner?: StudentsCertificatesBannerParams;
}

interface StudentsCertificatesBannerParams {
    descriptionWithDownloadAllowed?: string;
    descriptionWithScoringInProgress?: string;
    buttonText?: string;
    headerText?: string;
    imgSrc?: string;
}

export interface OlympsConfigOlympParentResults {
    getGiftFromOlympResultsPage?: boolean;
    banners?: OlympsConfigOlympParentResultsBanners;
}

export enum ResultsParticipantCertType {
    EMPTY = '',
    CERT_TO_PRINT = 'cert-to-print',
    PLUS_PROMOCODE = 'plus-promocode',
}

export interface OlympsConfigOlympParentResultsBanners {
    shouldShowParticipantCertToPrint?: boolean;
    resultsParticipantCertType?: ResultsParticipantCertType;
    parentCertificateBanner?: ParentCertificateBanner;
}

interface ParentCertificateBanner {
    buttonTextId?: string;
    descriptionTextId?: string;
    headerTextId?: string;
    images?: Partial<Record<'participant' | 'awardee' | 'winner', string>>;
}

export interface OlympsConfigStudentsAwardsAvailableToTeacher {
    olympSlugs: string[];
    campaigns: OlympsConfigCampaignByGrade[];
}

export interface OlympsConfigCampaignByGrade {
    grade: number;
    campaignSlug: string;
}

export interface OlympsConfigAchievements {
    campaigns: OlympsConfigCampaignByGrade[];
    winnerAwardSlugs: string[];
    participantAwardSlugs: string[];
}

export interface OlympStubs {
    main: string;
    lessons: {
        id: string;
        content: string;
    }[];
}

export interface CalendarPlanShowcaseConfig {
    defaults: CalendarPlanShowcaseConfigDefaults;
    grades: CalendarPlanShowcaseConfigGrade[];
    meta: CalendarPlanShowcaseConfigMeta;
    teaser: CalendarPlanShowcaseConfigTeaser;
}

export interface CalendarPlanShowcaseConfigDefaults {
    calendarPlanDescription: string;
    unavailableStatusMessage: string;
}

export interface CalendarPlanShowcaseConfigMeta {
    template_id: string;
}

export interface CalendarPlanShowcaseConfigGrade {
    grade: string;
    subjects: CalendarPlanShowcaseConfigSubject[];
}

export interface CalendarPlanShowcaseConfigSubject {
    calendarPlans: CalendarPlanShowcaseConfigCalendarPlan[];
    slug: SUBJECTS_SLUGS;
}

export interface CalendarPlanShowcaseConfigCalendarPlan {
    description?: string;
    themeGroups: CalendarPlanShowcaseConfigThemeGroup[];
    groupSelections2?: CalendarPlanShowcaseConfigGroupSelection[];
    groupSelections4?: CalendarPlanShowcaseConfigGroupSelection[];
}

export interface CalendarPlanShowcaseConfigGroupSelection {
    name: string;
    themes: number[];
}

export interface CalendarPlanShowcaseConfigThemeGroup {
    name: string;
    themes: CalendarPlanShowcaseConfigTheme[];
}

export interface CalendarPlanShowcaseConfigTheme {
    name: string;
    from: string;
    to: string;
    lessons: CalendarPlanShowcaseConfigLesson[];
}

export interface CalendarPlanShowcaseConfigLesson {
    additionalContent: string[];
    cardContent: CalendarPlanShowcaseConfigLessonCardContent;
    cardUI: CalendarPlanShowcaseConfigLessonCardUI;
    from: string;
    id: number;
    name: string;
    status: CalendarPlanShowcaseConfigLessonStatus;
    to: string;
}

export interface CalendarPlanShowcaseConfigLessonStatus {
    enabled: boolean;
    published: boolean;
    unavailableStatusMessage?: string;
    givenOutStatus?: CalendarPlanShowcaseConfigGivenOutStatus;
}

export enum CalendarPlanShowcaseConfigGivenOutStatus {
    AVAILABLE = 'available',
    GIVENOUT = 'givenout',
    UNAVAILABLE = 'unavailable',
}

export interface CalendarPlanShowcaseConfigLessonCardUI {
    backgroundColor?: string;
    image: string;
}

export interface CalendarPlanShowcaseConfigLessonCardContent {
    description?: string;
    skills: string;
    teacherMark: string;
}

export interface CalendarPlanShowcaseConfigTeaser {
    backgroundColor?: string;
    description?: string;
    enabled?: boolean;
    image?: string;
    openCalendarButtonText?: string;
    title?: string;
    uploadCalendarButtonText?: string;
    uploadCalendarUrl?: string;
}

export type FeatureFlags = Partial<Record<FEATURES, boolean>>;

export interface PluckerConfig {
    teacherHeader: PluckerConfigTeacherHeader;
}

export interface PortfolioConfig {
    active_projects: string[];
    grades: Record<string, number[]>;
    cert_slugs: Record<string, string[]>;
    expanded_slugs: string[];
}

export interface TeacherTrainingCourseBanner {
    link: string;
    title: string;
    description?: string;
    coverUrl?: string;
}

/** Настройки отображения курса кпк из бункера */
export interface TeacherKPKBunkerSettings {
    courseTemplateId: number;

    title: string;
    description?: string | undefined;

    /** Маркдаун с описанием курса по кнопке "Подробнее" */
    details?: string | undefined;

    /** Картинка на карточке */
    coverUrl?: string | undefined;

    /** Нужно ли показывать модалку с пользовательским соглашением */
    hasOffer?: boolean | undefined;

    /** По нему учитель может присоединиться */
    courseCode?: string | undefined;

    /** Нужно ли отображать плашку "Баллы КР" */
    hasHrReservePoints?: boolean | undefined;

    /** Если `hidden`, то курс добавляется только по коду */
    hidden?: boolean | undefined;

    /** Курс нужно показывать только если у учителя есть класс из `grades` */
    grades?: number[] | undefined;

    /** Порядок курса в КПК */
    order?: number;
}

export interface TeacherTrainingCoursesConfig {
    courses: TeacherKPKBunkerSettings[];
    banners: TeacherTrainingCourseBanner[];
}

type TeacherExamBaseCard = {
    buttonText: string;
    imgSrc: string;
    description: string;
    title: string;
};

type TeacherExamCardWithButton = TeacherExamBaseCard & {
    slug: string;
};

type TeacherExamCardWithLink = TeacherExamBaseCard & {
    url: string;
    target?: '_blank' | '_self' | undefined;
};

export type TeacherExams = {
    title: string;
    grade: {
        min: number;
        max: number;
    };
    OGE: {
        title: string;
        mainCard: TeacherExamCardWithButton;
        extraCard: TeacherExamCardWithLink;
    };
    EGE: {
        title: string;
        show: boolean;
        card: TeacherExamCardWithLink;
    };
};

export interface PluckerConfigTeacherHeader {
    teacherMenu: PluckerConfigTeacherMenu;
}

export interface PluckerConfigTeacherMenu {
    teacherEducationMenu: PluckerConfigTeacherEducationMenu;
}

export interface PluckerConfigTeacherEducationMenu {
    enabled: boolean;
    items: PluckerConfigTeacherEducationMenuItem[];
    name: string;
}

export interface PluckerConfigTeacherEducationMenuItem extends Link {
    elementId: TEACHER_HEADER_MENU_ITEMS;
}

export interface Link {
    content: string;
    published?: boolean;
    url: string;
}

export interface ImageMenuItemMeta {
    slug: string;
    resource_id: number;
}

export interface TeacherBanners {
    banners: {
        grades: number[];
        subjects: string[];
        showKey?: boolean;
        banner: {
            eventName: string;
            eventDescription: string;
            detailsButton: string;
            eventUrl?: string;
            background?: string;
            warningMode?: boolean;
        };
    }[];
}

export type StickersConfig = ImageMenuItemMeta[];

export interface LikesConfig {
    order: string[];
    items: ImageMenuItemMeta[];
}

export interface DiagnosticConfig {
    [subject: string]: {
        [grade: number]: {
            lessonId: number;
        };
    };
}

export interface QuizScheme {
    answer: string;
    index: number;
    q: string;
    options: string[];
}

export interface QuizItem {
    title: string;
    subject: string;
    scheme: QuizScheme[];
}

export interface QuizConfig {
    subjects: QuizSubject[];
    quizzesViewConfig: QuizzesViewConfig;
    quizzes: Quiz[];
}

interface QuizzesViewConfig {
    showcaseQuizzesLimit: number;
    titleMaxLength: number;
    questionMaxLength: number;
    answerMaxLength: number;
    defaultQuizCardImage: Record<string, string>;
    userQuizCardImage: Record<string, string>;
}

interface QuizSubject {
    slug: string;
    title: string;
    imgSrcSet?: Record<string, string>;
}

interface Quiz {
    id?: string;
    title: string;
    subject: string;
    questions: QuizQuestion[];
}

interface QuizQuestion {
    title: string;
    answers: QuizAnswer[];
}

interface QuizAnswer {
    value: string;
    correct: boolean;
}

export interface ParentBanner {
    slug: string;
    visible: boolean;
    course_template_id?: number;
    buttonText: string;
    imgSrc?: string;
    text: string;
    modal?: {
        imgSrc: string;
        title: string;
        description: string;
        buttonText: string;
        buttonTextDone?: string;
    };
}

export type StudentBanners = StudentBanner[];

export interface StudentBanner {
    id: string;
    title: string;
    text: string;
    tag: string;
    button_text?: string;
    button_link: string;
    desktop_image: string;
    mobile_image: string;
    visual_settings?: {
        background_color?: string;
        title_color?: string;
        description_color?: string;
    };
    order?: number;
    grades: number[];
}

export type ParentBanners = ParentBanner[];

export interface ParentAddCourse {
    slug?: string;
    visible: boolean;
    courseTemplateId: number;
    buttonText: string;
    title: string;
    description: string;
}

export type ParentAddCourses = ParentAddCourse[];

export type VerdictThemes = VerdictTheme[];
export type VerdictOptions = {
    theme: string;
    text?: string;
    textId?: string;
};

export interface VerdictOverrideConfigFiltersToFind {
    subject?: string;
    grades?: number;
    type?: string;
}

export interface VerdictOverrideConfig {
    filters: {
        subject?: string;
        grades?: number[];
        type?: string;
    };
    config: Record<string, VerdictOptions>;
    should_use_old_verdict?: boolean;
}

export interface VerdictConfig {
    default: Record<PROBLEM_ANSWER_STATUS | ROVER_ANSWER_STATUS, VerdictOptions>;
    rover_light: Record<string, VerdictOptions>;
    override: VerdictOverrideConfig[];
}

export interface PublicLessonsConfig {
    withoutRegistrationModal: number[];
}

export interface MailPermissionConfig {
    startDate: string;
    descriptionTankerKey: string;
}

export interface Webinar {
    title: string;
    link: string;
    image?: string;
}

export interface Webinars {
    config: {
        hideFromGrage: number;
    };
    items: Webinar[];
}

export interface CoursesLandingData {
    contentOrigin: string;
    pages: { [key: string]: string };
}

export interface AboutPlusKidsConfig {
    config?: {
        statusesToShowAlternativeText: LESSON_STATUS[];
        withoutPlusOnly: boolean;
    };
    olyLessonIdsByGrade:
        | {
              [key in number]?: number | null;
          }
        | null;
}

export type StudentOnboardingBanners = StudentOnboardingBanner[];

export interface StudentOnboardingBanner {
    url: string;
    type: 'IMAGE' | 'VIDEO';
    next_button_text: string;
    fallback_url: string;
}

export type MobileVersion = {
    minimalVersion: string;
};

export interface StoryAction {
    type: 'link';
    data: {
        title: string;
        url: string;
    };
}

export type StoryThemes = 'primary' | 'inverse';

export interface StoryCard {
    id: string;
    header: string;
    description: string;
    imgSrc: string;
    action?: StoryAction;
    theme?: StoryThemes;
}

export interface Doodle {
    id: string;
    enabled: boolean;
    startDate?: Date;
    endDate?: Date;
    img: string;
    header: string;
    description: string;
    serviceLogo?: string;
    serviceLogoShort?: string;
    serviceLogoText?: string;
}

export interface WebViewBaseUrlDict {
    [key: string]: string;
}
