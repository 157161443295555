import { SimpleCallback } from 'platform-components';
import { useIntl } from 'platform-components/common';
import { getMe } from 'platform-components/src/components/user/selectors';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import './team-builder__fallback-poll.scss';

const POLL_ORIGIN = 'https://yandex.ru';
const POLL_ANSWERED_EVENTS = ['survey.embed.finished', 'survey.embed.rejected'];

interface Props {
    onNext: SimpleCallback;
}

export const TeamBuilderFallbackPoll = (props: Props) => {
    const { onNext } = props;
    const me = useSelector(getMe);
    const intl = useIntl();

    useEffect(() => {
        const receiveMessage = (e: any) => {
            if (e.origin === POLL_ORIGIN) {
                try {
                    const data = JSON.parse(e.data);

                    if (POLL_ANSWERED_EVENTS.includes(data.event)) {
                        onNext();
                    }
                } catch (error) {
                    onNext();
                }
            }
        };

        window.addEventListener('message', receiveMessage);

        return () => window.removeEventListener('message', receiveMessage, false);
    });

    return (
        <div className="team-builder__fallback-poll">
            <iframe
                className="team-builder__fallback-poll-iframe"
                src={`${intl.formatMessage({ id: 'addStudentsFallbackPollIframe.url' })}?teacher_id=${me?.id}`}
                title="Опрос"
            />
        </div>
    );
};
