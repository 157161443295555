import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import loadjs from 'loadjs';
import { useEffect, useState } from 'react';

type SetReady = (ready: boolean) => void;

export type LoaderState = {
    loading: LOADING_STATUSES;
    load: () => Promise<void>;
    onLoadHandlers: Array<SetReady>;
};

type ScriptId = string;

type LoadSource = string | string[];

const loaders: Record<ScriptId, LoaderState> = {};

export const successLoad = (loader: LoaderState) => {
    loader.loading = LOADING_STATUSES.SUCCESS;
    loader.onLoadHandlers.forEach((callback) => callback(true));
    loader.onLoadHandlers = [];
};

export const useScriptLoader = ({ src, isShouldLoad }: { src: LoadSource; isShouldLoad?: () => boolean }) => {
    const files: string[] = Array.isArray(src) ? src : [src];
    const id = files.join();
    loaders[id] = loaders[id] || {
        load: () => loadjs(files, { returnPromise: true }),
        loading: LOADING_STATUSES.UNSENT,
        onLoadHandlers: [],
    };

    const shouldLoad = isShouldLoad ? isShouldLoad() : true;
    const initialLoaded = !shouldLoad || loaders[id].loading === LOADING_STATUSES.SUCCESS;
    const [loaded, setLoaded] = useState(initialLoaded);

    useEffect(() => {
        if (loaded) {
            return;
        }
        const loader = loaders[id];

        loader.onLoadHandlers.push(setLoaded);

        if (loader.loading !== LOADING_STATUSES.UNSENT) {
            return;
        }

        loader.loading = LOADING_STATUSES.LOADING;
        loader
            .load()
            .then(() => successLoad(loader))
            .catch((error) => {
                loader.loading = LOADING_STATUSES.ERROR;
                loader.onLoadHandlers = [];
                console.error(error);
            });
    }, [id, loaded]);

    return {
        loaded,
        loader: loaders[id],
    };
};
