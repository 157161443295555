import b from 'b_';
import cx from 'classnames';
import React, { CSSProperties } from 'react';

import Item from './__item/menu__item';
import { ContextProps, MenuContext } from './context';
import { MENU_SIZES, MENU_THEMES } from './typings';

import './menu.scss';

interface Props extends ContextProps {
    mix?: string;
    style?: CSSProperties;
}

class Menu extends React.Component<React.PropsWithChildren<Props>> {
    static THEME = MENU_THEMES;
    static SIZE = MENU_SIZES;

    static Item = Item;
    static Divider = () => <div className={b('menu', 'divider')} />;

    static defaultProps = {
        size: MENU_SIZES.M,
        theme: MENU_THEMES.NORMAL,
    };

    render() {
        const { theme, size, disabled, children, mix, style } = this.props;
        const className = b('menu', { disabled, size, theme });

        return (
            <MenuContext.Provider value={{ disabled, size, theme }}>
                <ul className={cx(className, mix)} style={style} tabIndex={0}>
                    {children}
                </ul>
            </MenuContext.Provider>
        );
    }
}

export default Menu;
export { default as Item } from './__item/menu__item';
