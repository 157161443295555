import { Me } from '@yandex-int/k-common/typings/me';
import { Text } from 'platform-components';
import { getMe } from 'platform-components/src/components/user/selectors';
import { withUatraits, WithUatraitsProps } from 'platform-components/hocs';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import ym from 'react-yandex-metrika';
import { Dispatch, bindActionCreators } from 'redux';

//@ts-ignore
import FormView, { SUGGEST_TYPES } from 'common.components/form-view/form-view';
import { externalLmsConnectActions } from 'store/external-lms-connect/actions';
import { YM_LOCATION } from 'utils/ym-constants';

import * as actions from '../actions';
import {
    getAddresses,
    getCurrentAddress,
    getIsCurrentSchoolSet,
    getIsCurrentAddressSet,
    getAreAddressesLoading,
} from '../selectors';
import { AddressWithId, SchoolFormStore } from '../typings';

interface OwnProps {
    shouldDisableAddress?: boolean;
    location?: YM_LOCATION;
}

interface StoreProps {
    isCurrentAddressSet: boolean;
    isCurrentSchoolSet: boolean;
    isLoading: boolean;
    addresses: Array<AddressWithId>;
    currentAddress: AddressWithId | null;
    me: Me | null;
}

interface DispatchProps {
    getAddressSuggests: typeof actions.getAddressSuggests;
    setAddresses: typeof actions.setAddresses;
    setCurrentAddress: typeof actions.setCurrentAddress;
    setCurrentSchool: typeof actions.setCurrentSchool;
    setSchools: typeof actions.setSchools;
    setLmsByRegion: typeof externalLmsConnectActions.setLmsByRegion;
    getLmsByRegion: typeof externalLmsConnectActions.getLmsByRegionRequest;
}

interface Props extends OwnProps, StoreProps, DispatchProps, WrappedComponentProps, WithUatraitsProps {}

interface State {
    errorMsg: string | null;
    focused: boolean;
}

export class SchoolFormAddressSection extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            errorMsg: null,
            focused: true,
        };
    }

    // eslint-disable-next-line complexity
    componentDidUpdate() {
        const { addresses, intl, isLoading } = this.props;
        const { errorMsg, focused } = this.state;

        /* eslint-disable react/no-did-update-set-state */
        if (this.value && !isLoading) {
            if (!addresses.length && !errorMsg) {
                this.setState({ errorMsg: intl.formatMessage({ id: 'input.incorrectMessage' }) });
            } else if (addresses.length && errorMsg && focused) {
                this.setState({ errorMsg: null });
            }
        } else if (!this.value && focused && errorMsg) {
            this.setState({ errorMsg: null });
        }
        /* eslint-enable react/no-did-update-set-state */
    }

    value: string | null;

    handleAddressSuggestChange = (address: AddressWithId) => {
        const { setCurrentAddress, setLmsByRegion, getLmsByRegion, me, currentAddress, location } = this.props;

        if (address.id !== currentAddress?.id) {
            this.clearSchoolValue();
        }

        this.setState({ errorMsg: null });
        this.value = address.title;
        setCurrentAddress(address);

        if (address.geoid) {
            ym('reachGoal', 'teacher_onboarding_chose_locality', {
                locality_geoid: address.geoid,
                user_id: me?.id,
                location,
            });
        }

        if (address.geoid) {
            getLmsByRegion({ geoId: address.geoid });
        } else {
            setLmsByRegion([]);
        }
    };

    clearSchoolValue = () => {
        const { isCurrentSchoolSet, setCurrentSchool, setSchools } = this.props;

        if (isCurrentSchoolSet) {
            setCurrentSchool(null);
        }

        setSchools([]);
    };

    handleAddressInputChange = (value: string) => {
        const { getAddressSuggests, setAddresses } = this.props;

        this.clearSchoolValue();

        if (!value.trim()) {
            this.value = null;
            setAddresses([]);

            return;
        }

        this.value = value;

        const filteredValueMatch = value.match(/^(г\.\s?|город\s)(.*)/i);
        const filteredValue = filteredValueMatch && filteredValueMatch[2] ? filteredValueMatch[2] : value;

        getAddressSuggests(filteredValue);
    };

    validateAddress = () => {
        const { intl, isCurrentAddressSet } = this.props;

        if (!isCurrentAddressSet) {
            if (!this.value || this.value.length < 1) {
                return intl.formatMessage({ id: 'schoolForm.addressIsEmpty' });
            }

            return intl.formatMessage({ id: 'input.incorrectMessage' });
        }

        return null;
    };

    handleOnSuggestBlur = () => {
        this.setState({ errorMsg: this.validateAddress(), focused: false });
    };

    handleAddressInputFocus = () => {
        this.setState({ focused: true });

        if (!this.value) {
            this.setState({ errorMsg: null });
        }
    };

    render() {
        const {
            addresses,
            currentAddress,
            intl,
            isLoading,
            uatraits: { isMobile },
            shouldDisableAddress,
        } = this.props;
        const { errorMsg } = this.state;

        const hint = <Text id="schoolForm.address.cityExample" size={Text.SIZE.S} />;

        return (
            <FormView.CardSection mix="teacher-registration__address-section" secondary>
                <FormView.Suggest
                    autoFocus
                    disabled={shouldDisableAddress}
                    errorMessage={errorMsg}
                    hint={hint}
                    labelKey="title"
                    onBlur={this.handleOnSuggestBlur}
                    onChange={this.handleAddressSuggestChange}
                    onFocus={this.handleAddressInputFocus}
                    onInputChange={this.handleAddressInputChange}
                    options={
                        isLoading
                            ? []
                            : (addresses || []).map((address) => {
                                  const addressCopy = { ...address };
                                  addressCopy.subtitle = '';
                                  return addressCopy;
                              })
                    }
                    placeholder={intl.formatMessage({ id: 'schoolForm.inputAddressPlaceholder' })}
                    scrollOnOpen={isMobile}
                    type={SUGGEST_TYPES.SELECT}
                    value={currentAddress && currentAddress.id}
                    valueKey="id"
                />
            </FormView.CardSection>
        );
    }
}

const mapStateToProps = <T extends { schoolForm: SchoolFormStore; me: Me }>(storeState: T): StoreProps => {
    return {
        addresses: getAddresses(storeState),
        currentAddress: getCurrentAddress(storeState),
        isCurrentAddressSet: getIsCurrentAddressSet(storeState),
        isCurrentSchoolSet: getIsCurrentSchoolSet(storeState),
        isLoading: getAreAddressesLoading(storeState),
        me: getMe(storeState),
    };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
    const { getAddressSuggests, setAddresses, setSchools, setCurrentAddress, setCurrentSchool } = actions;

    return bindActionCreators(
        {
            getAddressSuggests,
            setAddresses,
            setCurrentAddress,
            setCurrentSchool,
            setSchools,
            setLmsByRegion: externalLmsConnectActions.setLmsByRegion,
            getLmsByRegion: externalLmsConnectActions.getLmsByRegionRequest,
        },
        dispatch
    );
};

export default injectIntl(withUatraits(connect(mapStateToProps, mapDispatchToProps)(SchoolFormAddressSection)));
