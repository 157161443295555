import { getMe } from 'platform-components/src/components/user/selectors';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { ReduxUserStore } from 'common.components/user/typings';
import { YM_LOCATION } from 'utils/ym-constants';

import * as actions from '../actions';
import {
    getAreSchoolsLoading,
    getCurrentSchool,
    getIsCurrentAddressSet,
    getIsCurrentSchoolSet,
    getMode,
    getSchools,
} from '../selectors';
import { SchoolFormStore } from '../typings';

import SchoolFormSchoolSection from './school-form__school-section';

const mapStateToProps = <T extends { schoolForm: SchoolFormStore } & ReduxUserStore>(storeState: T) => {
    return {
        currentSchool: getCurrentSchool(storeState),
        isCurrentAddressSet: getIsCurrentAddressSet(storeState),
        isCurrentSchoolSet: getIsCurrentSchoolSet(storeState),
        isLoading: getAreSchoolsLoading(storeState),
        me: getMe(storeState),
        mode: getMode(storeState),
        schools: getSchools(storeState),
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    const { getSchoolSuggests, setCurrentSchool, setMode, setSchools } = actions;

    return bindActionCreators(
        {
            getSchoolSuggests,
            setCurrentSchool,
            setMode,
            setSchools,
        },
        dispatch
    );
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

interface OwnProps {
    canAddOwnSchool: boolean;
    onPopupTargetRef?: (ref: HTMLDivElement | null) => void;
    location?: YM_LOCATION;
}

interface Props extends OwnProps, StoreProps, DispatchProps {}

export const container = (props: Props) => {
    return <SchoolFormSchoolSection {...props} />;
};

export const SchoolFormSchoolSectionContainer = connect(mapStateToProps, mapDispatchToProps)(container);
