import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import './resource__document.scss';

const Document = ({ file }) => (
    <object className="document" data={file} type="application/pdf">
        <iframe className="document__iframe" src={file} type="application/pdf">
            <span className="document__not-supported">
                <FormattedMessage id="document.notSupported" />
                <a href={file} target="_blank">
                    <FormattedMessage id="document.download" />
                </a>
            </span>
        </iframe>
    </object>
);

Document.propTypes = {
    file: PropTypes.string,
};

export default Document;
