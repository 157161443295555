import { v4 as uuid } from 'uuid';

import { createErrorFromUnknown } from './create-error-from-unknown';
import { reportMessage } from './raven-message';
import { retrieveFromSessionStorage, saveToSessionStorage } from './web-storage';

const idKey = 'ya_schoolbook_user_session_id';
const paramsKey = 'ya_schoolbook_user_session_params';

export type UserSessionParams = Record<string, string | undefined>;

export function getUserSessionId() {
    return retrieveFromSessionStorage(idKey);
}

export function setUserSessionId() {
    const id = getUserSessionId();

    if (id === null) {
        saveToSessionStorage(idKey, uuid());
    }
}

export function getUserSessionParams() {
    const params = retrieveFromSessionStorage(paramsKey);
    const emptyParams = {} as UserSessionParams;

    if (params === null) {
        return emptyParams;
    }

    try {
        return JSON.parse(params) as UserSessionParams;
    } catch (error) {
        reportMessage(`Failed to parse ${paramsKey}`, createErrorFromUnknown(error));

        return emptyParams;
    }
}

export function extendUserSessionParamsFromUrl(params?: string[]) {
    if (typeof window !== 'undefined' && params !== undefined) {
        const sessionParams = getUserSessionParams();
        const searchParams = new window.URLSearchParams(window.location.search);

        for (const param of params) {
            const valueFromSearch = searchParams.get(param);

            if (valueFromSearch !== null) {
                sessionParams[param] = valueFromSearch;
            }
        }

        saveToSessionStorage(paramsKey, JSON.stringify(sessionParams));
    }
}
