import cx from 'classnames';
import { Copyright, Link, Text } from 'platform-components';
import { COPYRIGHT_TYPES } from 'platform-components/constants';
import React from 'react';
import { injectIntl, IntlShape } from 'react-intl';

// @ts-ignore
import Footer from 'common.components/footer/footer';

import TeacherRegistrationSalesCode from '../__sales-code/teacher-registration-page__sales-code';

import './teacher-registration-page__footer.scss';

interface iTeacherRegistrationFooterProps {
    intl: IntlShape;
}

export const TeacherRegistrationFooter = ({ intl }: iTeacherRegistrationFooterProps) => {
    return (
        <Footer mix="teacher-registration__footer">
            <div className={cx('teacher-registration__footer-wrapper', 'footer__wrapper')}>
                <div className={cx('teacher-registration__footer-main', 'footer__main')}>
                    <Copyright type={COPYRIGHT_TYPES.SERVICE} />
                    <Link
                        mix="teacher-registration__footer-link"
                        target="_blank"
                        to={intl.formatMessage({ id: 'header.link.help.url' })}
                    >
                        <Text id="teacherRegistration.help" />
                    </Link>
                </div>
                <div className={cx('teacher-registration__footer-right', 'footer__right')}>
                    <TeacherRegistrationSalesCode />
                </div>
            </div>
        </Footer>
    );
};

export default injectIntl(TeacherRegistrationFooter);
