import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ExternalLms } from '@yandex-int/k-common';
import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';

import { ExternalLmsConnectStore } from './typings';

// action for saga
export const setLmsByRegion = (state: ExternalLmsConnectStore, action: PayloadAction<Array<string>>) => {
    state.externalLms = action.payload;
};

const initialState: ExternalLmsConnectStore = {
    externalLms: [],
    loadingStatus: LOADING_STATUSES.UNSENT,
    userExternalLmsList: null,
    userExternalLmsListLoadingStatus: LOADING_STATUSES.UNSENT,
};

const slice = createSlice({
    name: 'EXTERNAL_LMS_CONNECT',
    initialState: initialState,
    reducers: {
        // action for saga
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        getLmsByRegionRequest: (state, action: PayloadAction<{ geoId: number }>) => {
            state.loadingStatus = LOADING_STATUSES.LOADING;
        },
        getLmsByRegionSuccess: (state) => {
            state.loadingStatus = LOADING_STATUSES.SUCCESS;
        },
        getLmsByRegionError: (state) => {
            state.loadingStatus = LOADING_STATUSES.ERROR;
        },
        getUserExternalLmsListRequest: (state) => {
            state.userExternalLmsListLoadingStatus = LOADING_STATUSES.LOADING;
        },
        getUserExternalLmsListSuccess: (state, action: PayloadAction<{ lms: Record<string, ExternalLms> }>) => {
            state.userExternalLmsList = action.payload.lms;
            state.userExternalLmsListLoadingStatus = LOADING_STATUSES.SUCCESS;
        },
        getUserExternalLmsListError: (state) => {
            state.userExternalLmsListLoadingStatus = LOADING_STATUSES.ERROR;
        },
        setLmsByRegion,
    },
});

export const externalLmsConnectActions = slice.actions;
export const externalLmsConnectReducer = slice.reducer;
