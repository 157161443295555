import { useEffect } from 'react';

import { useIsMeSet, useMe } from './useMe';

const USER_ID_LS_PREFIX = 'reload-user-id-';

/**
 * Перезагружает страницу если пользователь изменился в другой вкладке
 * @param suffix суффикс ключа чтобы разные приложения могли одновременно хранить разные id
 */
export function useReloadOnLogin(suffix = 'all') {
    const lsKey = USER_ID_LS_PREFIX + suffix;

    const isMeSet = useIsMeSet();
    const id = useMe('id') + '';

    useEffect(() => {
        function onStorage({ key }: StorageEvent) {
            if (key === lsKey && localStorage.getItem(lsKey) !== id) {
                location.reload();
            }
        }

        if (isMeSet) {
            if (localStorage.getItem(lsKey) !== id) {
                localStorage.setItem(lsKey, id);
            }

            window.addEventListener('storage', onStorage);

            return () => window.removeEventListener('storage', onStorage);
        }

        return; // eslint-disable-line consistent-return
    }, [lsKey, isMeSet, id]);
}

/**
 * Компонент, который вызывает хук useReloadOnLogin и возвращает null.
 * Нужен для удобства использования хука в классовых компонентах.
 */
export function UseReloadOnLogin({ suffix }: { suffix?: string }) {
    useReloadOnLogin(suffix);

    return null;
}
