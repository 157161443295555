export const VERIFY_PASSWORD_REQUEST = 'INDEX.START_SCREEN.VERIFY_PASSWORD_REQUEST';
export const VERIFY_SCHOOL_CODE_SUCCESS = 'INDEX.START_SCREEN.VERIFY_SCHOOL_CODE_SUCCESS';
export const VERIFY_SCHOOL_CODE_ERROR = 'INDEX.START_SCREEN.VERIFY_SCHOOL_CODE_ERROR';

export const SUBMIT_LOGIN_REQUEST = 'INDEX.START_SCREEN.SUBMIT_LOGIN_REQUEST';
export const SUBMIT_LOGIN_SUCCESS = 'INDEX.START_SCREEN.SUBMIT_LOGIN_SUCCESS';
export const SUBMIT_LOGIN_ERROR = 'INDEX.START_SCREEN.SUBMIT_LOGIN_ERROR';

export const SET_PASSWORD = 'INDEX.START_SCREEN.SET_PASSWORD';
export const SET_LOGIN = 'INDEX.START_SCREEN.SET_LOGIN';
export const SET_PASSWORD_LENGTH = 'INDEX.START_SCREEN.SET_PASSWORD_LENGTH';
