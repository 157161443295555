import { withBemMod } from '@bem-react/core';
import { cnButton } from '@yandex-lego/components/Button';

import { BUTTON_VIEW } from '../types';

export interface ButtonViewLiteProps {
    view?: BUTTON_VIEW.LITE;
}

export const withViewLite = withBemMod<ButtonViewLiteProps>(cnButton(), { view: BUTTON_VIEW.LITE });
