import b from 'b_';
import cx from 'classnames';
import { Button } from 'platform-components/common';
import PropTypes from 'prop-types';
import React from 'react';

import './form-view__button.scss';

const FormViewButton = ({ children, mix, ...props }) => (
    <Button {...props} className={cx(b('form-view', 'button'), mix)}>
        {children}
    </Button>
);

FormViewButton.propTypes = {
    children: PropTypes.node.isRequired,
    mix: PropTypes.string,
    onClick: PropTypes.func,
    view: PropTypes.oneOf(Object.values(Button.VIEW)),
};

export default FormViewButton;
