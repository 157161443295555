//@ts-ignore
import { SEMI_REGISTERED_PASSPORT_FLAG } from '@yandex-int/k-common/constants';
import get from 'lodash/get';
//@ts-ignore
import { SimpleCallback } from 'platform-components';
import { AppFactoryStore } from 'platform-components/src/components/app-factory/typings';
import { getMe, getIsTeacher, getIsAuthenticated } from 'platform-components/src/components/user/selectors';
import { withRouterPath, InjectedProps as WithRouterPathProps } from 'platform-components/hocs';
import PropTypes from 'prop-types';
import qs from 'query-string';
import React from 'react';
import { connect } from 'react-redux';

import {
    getIsParentRegistrationAvailable,
    getSpecProjectConfigBySourceProject,
} from 'common.components/spec-project.common/selectors';
import { getSavedOrFirstTeamId } from 'common.components/user/selectors';
import { ReduxUserStore } from 'common.components/user/typings';
import { getAuthPath, PASSPORT_MODES } from 'utils/get-auth-path';

export interface InjectedProps extends ReturnType<typeof mapStateToProps>, WithRouterPathProps {
    registerAdult: SimpleCallback;
    registerParent: SimpleCallback;
    registerTeacher: () => void;
}

const withUserRouter = <T extends InjectedProps>(WrappedComponent: React.ComponentType<T>) => {
    class UserRouter extends React.Component<T> {
        static displayName = `withUserRouter(${WrappedComponent.displayName})`;

        static contextTypes = {
            basePath: PropTypes.string.isRequired,
            prefix: PropTypes.string.isRequired,
            staticUrls: PropTypes.object.isRequired,
        };

        componentDidMount() {
            const {
                isAuthenticated,
                isTeacher,
                location: { search },
            } = this.props;

            if (isTeacher) {
                this.brutalRedirect(this.getTeacherDefaultFullUrl());
            } else if (isAuthenticated) {
                if (qs.parse(search).adult_registration === 'true') {
                    this.registerAdult();
                }
            }
        }
        context!: React.ContextType<
            React.Context<{
                staticUrls: Record<string, string>;
                basePath: string;
                prefix: string;
            }>
        >;

        getFullUrl = (path: string) => {
            const { basePath, prefix } = this.context;

            return `${basePath}${prefix}${path}`;
        };

        getTeacherDefaultFullUrl = () => {
            const {
                staticUrls: { labCourses, labLessons },
            } = this.context;
            const { savedOrFirstTeamId, getRouterPath } = this.props;

            return savedOrFirstTeamId
                ? this.getFullUrl(getRouterPath(labCourses, { classId: savedOrFirstTeamId }))
                : this.getFullUrl(getRouterPath(labLessons));
        };

        getPassportUrl = (
            urlPath: string,
            queryParams: Record<string, string | number | boolean> = {},
            passportMode: PASSPORT_MODES.AUTH | PASSPORT_MODES.POST_REGISTRATION = PASSPORT_MODES.AUTH
        ) => {
            queryParams.from_passport = true;
            const queryParamsStr = qs.stringify(queryParams);
            const {
                staticUrls: { registration },
            } = this.context;

            const retpath = `${this.getFullUrl(urlPath)}?${queryParamsStr}`;
            const backpath = `${this.getFullUrl(registration)}?${queryParamsStr}`;

            return getAuthPath({ retpath, backpath }, passportMode);
        };

        registerTeacher = () => {
            const {
                isAuthenticated,
                location: { search },
                me,
            } = this.props;
            const {
                staticUrls: { teacherRegistration },
            } = this.context;
            const semiRegistered = Boolean(get(me, SEMI_REGISTERED_PASSPORT_FLAG));

            if (isAuthenticated && !semiRegistered) {
                this.brutalRedirect(`${teacherRegistration}${search}`);
            } else {
                this.brutalRedirect(
                    this.getPassportUrl(
                        teacherRegistration,
                        {
                            ...qs.parse(search),
                            adult_registration: true,
                        },
                        semiRegistered ? PASSPORT_MODES.POST_REGISTRATION : PASSPORT_MODES.AUTH
                    )
                );
            }
        };

        registerParent = () => {
            const {
                isAuthenticated,
                isParentRegistrationAvailable,
                location: { search },
                me,
                specProjectRegistrationUrl,
            } = this.props;
            const {
                staticUrls: { parentRegistration },
            } = this.context;
            const semiRegistered = Boolean(get(me, SEMI_REGISTERED_PASSPORT_FLAG));

            const pathName =
                isParentRegistrationAvailable && specProjectRegistrationUrl
                    ? specProjectRegistrationUrl
                    : parentRegistration;

            if (isAuthenticated && !semiRegistered) {
                this.brutalRedirect(`${pathName}${search}`);
            } else {
                this.brutalRedirect(
                    this.getPassportUrl(
                        pathName,
                        { ...qs.parse(search), adult_registration: true },
                        semiRegistered ? PASSPORT_MODES.POST_REGISTRATION : PASSPORT_MODES.AUTH
                    )
                );
            }
        };

        registerAdult = () => {
            // FIXME: move postregistration check to selectors
            const {
                history,
                location: { search },
            } = this.props;
            const {
                staticUrls: { registration },
            } = this.context;

            history.push(`${registration}${search}`);
        };

        brutalRedirect = (url: string) => {
            if (!isSSR) {
                window.location.href = url;
            }
        };

        render() {
            const { isTeacher } = this.props;

            if (isTeacher) {
                return null;
            }

            return (
                <WrappedComponent
                    {...this.props}
                    registerAdult={this.registerAdult}
                    registerParent={this.registerParent}
                    registerTeacher={this.registerTeacher}
                />
            );
        }
    }

    //@ts-ignore
    return withRouterPath(connect(mapStateToProps)(UserRouter)) as React.ComponentType<Omit<T, keyof InjectedProps>>;
};

const mapStateToProps = (storeState: ReduxUserStore & AppFactoryStore) => ({
    savedOrFirstTeamId: getSavedOrFirstTeamId(storeState),
    isAuthenticated: getIsAuthenticated(storeState),
    isParentRegistrationAvailable: getIsParentRegistrationAvailable(storeState),
    isTeacher: getIsTeacher(storeState),
    me: getMe(storeState),
    specProjectRegistrationUrl: getSpecProjectConfigBySourceProject(storeState)?.parentRegistrationUrl,
});

export default withUserRouter;
