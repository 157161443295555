import b from 'b_';
import React from 'react';

import './keyboard__groups-wrapper.scss';

interface Props {
    children: React.ReactNode;
    type?: 'main' | 'additional';
}

export const KeyboardGroupsWrapper = ({ children, type = 'main' }: Props) => {
    return <div className={b('keyboard', 'groups-wrapper', { type })}>{children}</div>;
};
