import b from 'b_';
import React from 'react';

import './dialog-message.scss';

interface Props {
    name: string;
    color: string;
    children: React.ReactNode;
}

export const DialogMessage = ({ name, color, children }: Props) => {
    return (
        <div className="dialog-message">
            <div className={b('dialog-message__name', { color })}>{name}</div>
            <div className="dialog-message__content">{children}</div>
        </div>
    );
};
