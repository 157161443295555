import { postFetch } from '../fetch';
import { apiConfig } from '../config';

export function postSetEventCode(eventCode: string): Promise<void> {
    const { apiPostSetEventCode } = apiConfig.getStaticUrls();

    return postFetch(apiPostSetEventCode, {
        event_code: eventCode,
    });
}
