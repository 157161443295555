import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import { Me } from '@yandex-int/k-common/typings';
import { Link, Spin, Text } from 'platform-components';
import { getMe } from 'platform-components/src/components/user/selectors';
import { withLocalStorage, WithLocalStorageProps } from 'platform-components/src/utils/hocs/withLocalStorage';
import { withRouterPath, InjectedProps as WithRouterPathProps } from 'platform-components/hocs';
import PropTypes from 'prop-types';
import qs from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import ym from 'react-yandex-metrika';
import { compose } from 'redux';
import { bindActionCreators, Dispatch } from 'redux';

import CreateChildForm from 'common.components/create-child-form/create-child-form';
import { getSourceProject } from 'utils/get-data-from-search-parameters';

import RegistrationWrapper from '../__wrapper/parent-registration__wrapper';
import { RootAction, ReduxIndexStore } from '../../../typings';
import { actions } from '../actions';
import * as selectors from '../selectors';

interface StoreProps {
    childName?: string;
    studentGrade: number | null;
    createChildLoadingStatus: LOADING_STATUSES;
    me: Me | null;
    isChildCreationSuccess: boolean;
}

interface DispatchProps {
    setChildName: typeof actions.setChildName;
    setStudentGrade: typeof actions.setStudentGrade;
    createChildRequest: typeof actions.createChildRequest;
}

interface SearchParams {
    returnPath?: string;
}

interface Props extends WithRouterPathProps, WithLocalStorageProps, StoreProps, DispatchProps {}

class RegistrationCreateChild extends React.PureComponent<Props> {
    static contextTypes = {
        staticUrls: PropTypes.object.isRequired,
    };

    componentDidUpdate(prevProps: Readonly<Props>): void {
        const {
            createChildLoadingStatus: status,
            localStorage,
            location: { search },
        } = this.props;
        const { createChildLoadingStatus: prevStatus } = prevProps;
        const { staticUrls } = this.context;

        if (status !== prevStatus && status === LOADING_STATUSES.SUCCESS) {
            ym('reachGoal', 'platform_parent_registration_finished', { from_project: getSourceProject() });

            ym('reachGoal', 'parent_add_child', {
                place: 'onboarding',
                type: 'manual',
            });

            localStorage.set('shouldShowWelcomeParentModal', true);

            const searchParams: SearchParams | undefined = qs.parse(search);

            if (searchParams?.returnPath) {
                window.location.href = searchParams?.returnPath;

                return;
            }

            window.location.href = `${staticUrls.parentMain}${search}`;
        }
    }

    handleSubmitForm = () => {
        const { createChildRequest } = this.props;

        createChildRequest();
    };

    toRegistrationByCode = () => {
        const { staticUrls } = this.context;
        const {
            history,
            location: { search },
        } = this.props;

        ym('reachGoal', 'parent_olymp_onboarding_have_code', {
            register_child_screen: true,
            from_project: getSourceProject(),
        });

        history.push(`${staticUrls.parentRegistrationByCode}${search}`);
    };
    context!: React.ContextType<React.Context<{ staticUrls: Record<string, string> }>>;

    render() {
        const {
            setChildName,
            setStudentGrade,
            childName,
            studentGrade,
            createChildLoadingStatus,
            isChildCreationSuccess,
        } = this.props;

        // хотим показывать лоадер до окончания редиректа
        const submittingStatus =
            createChildLoadingStatus === LOADING_STATUSES.SUCCESS ? LOADING_STATUSES.LOADING : createChildLoadingStatus;

        if (isChildCreationSuccess) {
            return <Spin center size={Spin.SIZE.L} />;
        }

        return (
            <RegistrationWrapper textId="parentRegistration.welcomeText">
                <CreateChildForm
                    bottomSection={
                        <Link onClick={this.toRegistrationByCode} pseudo theme={Link.THEME.INHERIT}>
                            <Text id="createChildForm.hasCode" weight={Text.WEIGHT.M} />
                        </Link>
                    }
                    mix="parent-registration__form"
                    name={childName}
                    onChangeName={setChildName}
                    onChangeStudentGrade={setStudentGrade}
                    onSubmit={this.handleSubmitForm}
                    shouldCreateChild={true}
                    showAgreement={true}
                    studentGrade={studentGrade}
                    submittingStatus={submittingStatus}
                />
            </RegistrationWrapper>
        );
    }
}

const mapStateToProps = (storeState: ReduxIndexStore): StoreProps => {
    return {
        childName: selectors.getChildName(storeState),
        studentGrade: selectors.getStudentGrade(storeState),
        createChildLoadingStatus: selectors.getCreateChildLoadingStatus(storeState),
        me: getMe(storeState),
        isChildCreationSuccess: selectors.getIsChildCreationSuccess(storeState),
    };
};

const mapDispatchToProps = (dispatch: Dispatch<RootAction>): DispatchProps => {
    return bindActionCreators(
        {
            setChildName: actions.setChildName,
            setStudentGrade: actions.setStudentGrade,
            createChildRequest: actions.createChildRequest,
        },
        dispatch
    );
};

export default compose(
    withRouterPath,
    withLocalStorage,
    connect(mapStateToProps, mapDispatchToProps)
)(RegistrationCreateChild);
