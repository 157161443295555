import b from 'b_';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useMount, useUpdateEffect } from 'react-use';

import { A11yHidden } from '../A11yHidden';
import { useAppContext } from '../app-factory/context';

import { ModalCloseButton } from './__close-button/modal__close-button';
import { ModalContent } from './__content/modal__content';
import { ModalFooter } from './__footer/modal__footer';
import { ModalHeader } from './__header/modal__header';
import { ModalImg } from './__img/modal__img';
// @ts-ignore js-file
import Portal from './__portal/modal__portal';
import { ModalProps, MODAL_FADE_THEMES, MODAL_THEMES } from './types';

import './modal.scss';

export const Modal = ({
    closeDelay,
    opened,
    fadeTheme: modalThemeFromProps,
    mix,
    portalAutoFocus = true,
    onOpened,
    'aria-labelledby': ariaLabelledby,
    'aria-describedby': ariaDescribedby,
    ...otherProps
}: ModalProps) => {
    const getRootElement = () => {
        return typeof window === 'undefined' ? null : document.body;
    };
    const [modalState, setModalState] = useState<'opened' | 'closing' | 'closed'>(opened ? 'opened' : 'closed');
    const [root, setRoot] = useState(getRootElement());
    const context = useAppContext();
    // @ts-ignore
    const { modalTheme: modalThemeFromContext } = context;

    useMount(() => {
        if (!root) {
            setRoot(getRootElement());
        }
    });

    useUpdateEffect(() => {
        if (opened) {
            setModalState('opened');
        } else if (closeDelay) {
            setModalState('closing');
            setTimeout(() => setModalState('closed'), closeDelay);
        } else {
            setModalState('closed');
        }
    }, [opened]);

    useEffect(() => {
        if (modalState === 'opened' && onOpened) {
            onOpened();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalState]);

    const getFadeTheme = () => {
        if (modalThemeFromProps) {
            return modalThemeFromProps;
        }

        if (modalThemeFromContext) {
            return modalThemeFromContext;
        }

        return MODAL_FADE_THEMES.DARK;
    };

    if (!root || modalState === 'closed') {
        return null;
    }

    return ReactDOM.createPortal(
        <div
            aria-describedby={ariaDescribedby}
            aria-labelledby={ariaLabelledby || 'fallback-modal-label'}
            aria-modal={true} // у VoiceOver проблемы с этим – он иногда может выйти за пределы модалки
            className={cx(b('modal', { opened: modalState === 'opened', 'fade-theme': getFadeTheme() }), mix)}
            role="dialog"
            tabIndex={-1}
        >
            {!ariaLabelledby && <A11yHidden id="fallback-modal-label">Модальное окно</A11yHidden>}
            <Portal {...otherProps} autoFocus={portalAutoFocus} />
        </div>,
        root
    );
};

Modal.CloseButton = ModalCloseButton;
Modal.Footer = ModalFooter;
Modal.Header = ModalHeader;
Modal.Content = ModalContent;
Modal.Img = ModalImg;
Modal.THEMES = MODAL_THEMES;
Modal.FADE_THEMES = MODAL_FADE_THEMES;

export default Modal;
