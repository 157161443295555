import { postFetch } from '../fetch';

import { apiConfig } from '../config';

interface AddChildCoursesRequest {
    childIds: number[];
    templateIds: number[];
}

export function postAddChildCoursesBulk(requestData: AddChildCoursesRequest):Promise<any> {
    const { childIds, templateIds } = requestData;
    const { apiPostAddChildCoursesBulk } = apiConfig.getStaticUrls();

    return postFetch(apiPostAddChildCoursesBulk, {
        child_ids: childIds,
        template_ids: templateIds,
    });
}
