import { showIntlMessageNotification } from 'platform-components';
import { NOTIFICATION_THEMES, GRAVITIES } from 'platform-components/constants';
import { Dispatch } from 'redux';

import { slice } from './slice';

export function showLessonDeletedToast(options: { cLessonId: number; isDraft: boolean }) {
    const { cLessonId, isDraft } = options;

    return function (dispatch: Dispatch<any>) {
        const messageId = isDraft ? 'lesson.clessonDraftIsDeleted' : 'lesson.clessonIsDeleted';

        dispatch(
            showIntlMessageNotification({
                gravity: GRAVITIES.BOTTOM,
                messageId,
                notificationAction: () => {
                    dispatch(slice.actions.restoreCLessonRequest({ cLessonId, isDraft }));
                },
                notificationMessageId: 'lesson.undoClessonDelete',
                theme: NOTIFICATION_THEMES.TOAST,
                withExplicitClose: true,
            })
        );
    };
}
