import React, { ReactElement } from 'react';

import { MarkdownJsonResource } from '../../typings';
import { useLoadFile } from '../../utils/hooks/useLoadFile';

interface Props {
    children: ReactElement;
    resource: MarkdownJsonResource;
    resourceName: string;
}

export const JsonResourseParser = ({ children, resource, resourceName, ...props }: Props) => {
    const [jsonCode] = useLoadFile(resource.file);

    if (jsonCode === '') {
        return null;
    }

    const jsonData = JSON.parse(jsonCode);

    return React.cloneElement(children, { [resourceName]: jsonData, ...props });
};
