import { School } from 'common.components/typings';

import * as constants from './constants';
import { MODES } from './school-form.constants';
import { AddressWithId } from './typings';

export const clearState = () => <const>{ type: constants.CLEAR_STATE };

export const setMode = (mode: MODES) => <const>{ type: constants.SET_MODE, mode };

export const getAddressSuggests = (text: string) => <const>{ type: constants.GET_ADDRESS_SUGGESTS_REQUEST, text };

export const getSchoolSuggests = (text: string) => <const>{ type: constants.GET_SCHOOL_SUGGESTS_REQUEST, text };

export const getAddressSuggestsSuccess = (addresses: Array<AddressWithId>) =>
    <const>{ type: constants.GET_ADDRESS_SUGGESTS_SUCCESS, addresses };

export const getSchoolSuggestsSuccess = (schools: Array<School>) =>
    <const>{ type: constants.GET_SCHOOL_SUGGESTS_SUCCESS, schools };

export const setAddresses = (addresses: Array<AddressWithId>) => <const>{ type: constants.SET_ADDRESSES, addresses };

export const setSchools = (schools: Array<School>) => <const>{ type: constants.SET_SCHOOLS, schools };

export const getAddressSuggestsError = () => <const>{ type: constants.GET_ADDRESS_SUGGESTS_ERROR };

export const getSchoolSuggestsError = () => <const>{ type: constants.GET_SCHOOL_SUGGESTS_ERROR };

export const setCurrentAddress = (currentAddress: AddressWithId) =>
    <const>{ type: constants.SET_CURRENT_ADDRESS, currentAddress };

export const setCurrentSchool = (currentSchool: School | null) =>
    <const>{ type: constants.SET_CURRENT_SCHOOL, currentSchool };
