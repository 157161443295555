import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import { createSelector } from 'reselect';

import { DeleteCLessonStore, LESSON_EDIT_ERRORS } from '../typings';

const storeStateSelector = (storeState: any): DeleteCLessonStore => storeState.deleteCLesson;

export const getDeletingCLessonId = createSelector(storeStateSelector, (state) => state.deletingCLessonId);

export const getDeleteClessonStatus = createSelector(
    storeStateSelector,
    (state): LOADING_STATUSES => state.deleteClessonLoadingStatus
);

export const getDeleteCLessonError = createSelector(
    storeStateSelector,
    (state): LESSON_EDIT_ERRORS | null => state.deleteCLessonError
);
