export function findLmsByRegion(geoParents: Array<number>, lmsByGeoIds: Record<string, Array<string>>): Array<string> {
    let parentIdIndex = 0;
    while (parentIdIndex < geoParents.length) {
        const parentId = geoParents[parentIdIndex];
        const lms = lmsByGeoIds[parentId.toString()];
        if (lms) {
            return lms;
        }
        parentIdIndex += 1;
    }
    return [];
}
