import type { Me, Team } from '@yandex-int/k-common';
import { putAuthByCode } from '@yandex-int/k-common/client/api';
import { getIsTeacher, getMe } from 'platform-components/src/components/user/selectors';
import ym from 'react-yandex-metrika';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { authByCodeError, authByCodeRequest } from './actions';
import { AUTH_BY_CODE_REQUEST } from './constants';

export function* applyCodeSagaWatcher(): Generator<unknown, void> {
    yield all([yield takeLatest(AUTH_BY_CODE_REQUEST, authByCodeSaga)]);
}

function* authByCodeSaga(action: ReturnType<typeof authByCodeRequest>) {
    const { code, callback } = action;
    const isTeacher: boolean = yield select(getIsTeacher);
    const me: Me = yield select(getMe);

    const metrikaTarget = isTeacher ? 'teacher_platform_followed_class_link' : 'teacher_onboarding_followed_class_link';

    try {
        const connectedClass: Team = yield call(putAuthByCode, code);

        ym('reachGoal', metrikaTarget, {
            id: me.id,
            validation: 'valid_link',
            code,
            team_id: connectedClass?.id,
        });

        callback(connectedClass.id);
    } catch (error) {
        ym('reachGoal', metrikaTarget, {
            id: me.id,
            validation: 'invalid_link',
            code,
        });

        console.error(error);

        yield put(authByCodeError());
    }
}
