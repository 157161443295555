import b from 'b_';
import cx from 'classnames';
import React from 'react';

import './modal__footer.scss';

interface Props {
    mix?: string;
    children: React.ReactNode | Array<React.ReactNode>;
    stackable?: boolean;
}

export const ModalFooter = ({ children, mix, stackable }: Props) => (
    <div className={cx(b('modal', 'footer', { stackable }), mix)}>
        {React.Children.map(children, (child) => (
            <div className="modal__footer-element">{child}</div>
        ))}
    </div>
);
