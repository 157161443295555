// @ts-ignore
import { SUBJECTS_SLUGS } from '@yandex-int/k-common/constants';
import { CourseTemplate } from '@yandex-int/k-common/typings';
import b from 'b_';
import cx from 'classnames';
import { Spin, Text, SimpleText, SimpleCallback, Link, PersonalAgreement } from 'platform-components';
import { SPIN_SIZES } from 'platform-components/src/components/constants';
// @ts-ignore
import { withRouter } from 'platform-components/src/components/router/router';
import { InjectedProps } from 'platform-components/src/utils/hocs/with-router-path/with-router-path';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import ym from 'react-yandex-metrika';

import Subject from 'common.components/subjects-form/__subject/subject-form__subject';
import { iClass } from 'common.components/typings';
import { YM_LOCATION } from 'utils/ym-constants';

// @ts-ignore
import FormView from '../form-view/form-view';
// @ts-ignore
import HeadedFormViewCard from '../headed-form-view-card';

import SubjectsFormEmptyState from './__empty-state/subjects-form__empty-state';

import './subjects-form.scss';

const TITLE_DEFAULT_VALUE = 'subjectsForm.title';
const DESCRIPTION_DEFAULT_VALUE = 'subjectsForm.description';
const BUTTON_TEXT_ID_DEFAULT_VALUE = 'subjectsForm.sendButton';

interface HOCProps extends WrappedComponentProps, InjectedProps {}

interface OwnProps {
    additionalButton?: React.ReactNode | null;
    buttonTextId?: string;
    coursesTemplates: Array<CourseTemplate>;
    customMetrikaHandler?: SimpleCallback;
    descriptionId?: string;
    disabledSubjectsIds?: Array<number>;
    isLoading?: boolean;
    isSubmitting?: boolean;
    locationForMetrika?: YM_LOCATION;
    mix?: string;
    onChange: (selectedSubjectIds: Array<number>) => void;
    onGoBack?: SimpleCallback;
    onSubmit: (selectedSubjectIds: Array<number>) => void;
    selectedSubjectIds: Array<number>;
    shouldShowPersonalDataLink?: boolean;
    shouldShowFooter?: boolean;
    subjectToShow?: SUBJECTS_SLUGS;
    team: iClass;
    titleId?: string;
    warning?: React.ReactNode;
}

interface Props extends OwnProps, HOCProps {}

interface State {
    shouldShowError: boolean;
}

export class SubjectsForm extends React.Component<Props, State> {
    static defaultProps = {
        titleId: TITLE_DEFAULT_VALUE,
        descriptionId: DESCRIPTION_DEFAULT_VALUE,
        buttonTextId: BUTTON_TEXT_ID_DEFAULT_VALUE,
    };

    state: State = {
        shouldShowError: false,
    };

    errorMsgRef = React.createRef<HTMLDivElement>();

    handleSelectSubject = (subjectId: number) => {
        const { onChange, selectedSubjectIds } = this.props;

        const wasSelected = selectedSubjectIds.includes(subjectId);

        if (wasSelected) {
            onChange(selectedSubjectIds.filter((id) => id !== subjectId));
        } else {
            onChange([...selectedSubjectIds, subjectId]);
        }
    };

    handleSubmit = () => {
        const { customMetrikaHandler, isSubmitting, locationForMetrika, onSubmit, selectedSubjectIds } = this.props;

        if (isSubmitting) {
            return;
        }

        if (customMetrikaHandler) {
            customMetrikaHandler();
        } else {
            ym('reachGoal', 'teacher_my_students_chose_subject', { location: locationForMetrika });
        }

        const error = this.getErrorMsg();
        if (error) {
            this.setState({ shouldShowError: true });

            // timeout чтобы успел появиться errorMsg
            setTimeout(() => {
                if (this.errorMsgRef?.current) {
                    this.errorMsgRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            }, 0);

            return;
        }

        onSubmit(selectedSubjectIds);
    };

    getErrorMsg = (): string | null => {
        const { intl, selectedSubjectIds } = this.props;

        if (selectedSubjectIds.length === 0) {
            return intl.formatMessage({ id: 'subjectsForm.error.required' });
        }

        return null;
    };

    getHeader = (): string => {
        const { intl, team, titleId } = this.props;

        if (titleId) {
            return intl.formatMessage(
                { id: titleId },
                {
                    level: team.number,
                    name: team.letter,
                }
            );
        }

        return TITLE_DEFAULT_VALUE;
    };

    getDescription = (): string | React.ReactNode => {
        const { coursesTemplates, descriptionId, intl, subjectToShow, team, warning } = this.props;

        if (warning) {
            return warning;
        }

        if (descriptionId) {
            return intl.formatMessage(
                { id: descriptionId },
                {
                    level: team.number,
                    name: team.letter,
                    selectedSubjectName: coursesTemplates.find((ct) => subjectToShow === ct.subject)?.name,
                }
            );
        }

        return DESCRIPTION_DEFAULT_VALUE;
    };

    // eslint-disable-next-line complexity
    render() {
        const {
            additionalButton,
            mix,
            selectedSubjectIds,
            disabledSubjectsIds,
            isLoading,
            isSubmitting,
            coursesTemplates,
            buttonTextId,
            onGoBack,
            shouldShowPersonalDataLink,
            shouldShowFooter,
            intl,
        } = this.props;
        const { shouldShowError } = this.state;

        const hasNoTemplates = coursesTemplates && coursesTemplates.length === 0;
        const shouldShowEmptyState = !isLoading && !isSubmitting && hasNoTemplates;
        const shouldShowLoader = (isSubmitting || isLoading) && hasNoTemplates && !shouldShowError;

        if (shouldShowEmptyState) {
            return <SubjectsFormEmptyState mix={mix} />;
        }

        const submitButtonText = buttonTextId ? intl.formatMessage({ id: buttonTextId }) : '';

        return (
            <HeadedFormViewCard
                description={this.getDescription()}
                header={this.getHeader()}
                mix={cx('subjects-form', mix)}
            >
                {isLoading ? (
                    <Spin center />
                ) : (
                    <div className={b('subjects-form', 'content', { expanded: Boolean(additionalButton) })}>
                        <FormView.CardSection mix="subjects-form__subjects-list">
                            {coursesTemplates.map(({ id, cover, name, description: descr }) => (
                                <Subject
                                    coverUrl={cover ? cover.file : null}
                                    description={descr}
                                    disabled={(disabledSubjectsIds || []).includes(id)}
                                    id={id}
                                    key={id}
                                    name={name}
                                    onSelect={this.handleSelectSubject}
                                    selected={selectedSubjectIds.includes(id)}
                                />
                            ))}
                            {shouldShowError && (
                                <div className="subjects-form__error" ref={this.errorMsgRef}>
                                    <Text>{this.getErrorMsg()}</Text>
                                </div>
                            )}
                            {shouldShowLoader && <Spin center size={SPIN_SIZES.L} />}
                        </FormView.CardSection>
                        <FormView.CardSection mix="subjects-form__control-wrapper">
                            <FormView.Button
                                mix="qa_subjects-form-submit-button"
                                onClick={this.handleSubmit}
                                progress={isSubmitting}
                            >
                                <SimpleText id={buttonTextId} />
                            </FormView.Button>
                            {additionalButton}
                            {shouldShowPersonalDataLink && (
                                <FormView.CardDescription mix="subjects-form__agreement">
                                    <PersonalAgreement
                                        buttonText={submitButtonText}
                                        linksConfig={[
                                            PersonalAgreement.personalAgreementLinks.teacherPdLink,
                                            PersonalAgreement.personalAgreementLinks.olympLink,
                                        ]}
                                    />
                                </FormView.CardDescription>
                            )}
                        </FormView.CardSection>
                    </div>
                )}
                {shouldShowFooter && (
                    <FormView.Footer mix="subjects-form__footer">
                        <Link mix="subjects-form__footer-link" onClick={onGoBack} pseudo>
                            <Text id="subjectsForm.goBack" />
                        </Link>
                    </FormView.Footer>
                )}
            </HeadedFormViewCard>
        );
    }
}

export default withRouter(injectIntl(SubjectsForm)) as React.ComponentType<OwnProps>;
