//@ts-ignore
import { reportMessage } from './raven-message';

// eslint-disable-next-line max-len
//см. https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#Testing_for_availability
export function getIsStorageAvailable(type: 'localStorage' | 'sessionStorage' = 'localStorage') {
    let storage;
    try {
        storage = window[type];
        const x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    } catch (e) {
        return (
            typeof window === 'undefined' ||
            (e instanceof DOMException &&
                // everything except Firefox
                (e.code === 22 ||
                    // Firefox
                    e.code === 1014 ||
                    // test name field too, because code might not be present
                    // everything except Firefox
                    e.name === 'QuotaExceededError' ||
                    // Firefox
                    e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
                // acknowledge QuotaExceededError only if there's something already stored
                storage &&
                storage.length !== 0)
        );
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getFromLocalStorage(key: string, defaultValue: any) {
    const isLocalStorageAvailable = getIsStorageAvailable();

    if (typeof window !== 'undefined' && isLocalStorageAvailable) {
        return window.localStorage.getItem(key);
    }

    return defaultValue;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setToLocalStorage(key: string, value: any) {
    const isLocalStorageAvailable = getIsStorageAvailable();

    if (typeof window !== 'undefined' && isLocalStorageAvailable) {
        window.localStorage.setItem(key, value);
    } else if (!isLocalStorageAvailable) {
        reportMessage('Local storage is unavailable');
    }
}

export function removeFromLocalStorage(key: string) {
    const isLocalStorageAvailable = getIsStorageAvailable();

    if (typeof window !== 'undefined' && isLocalStorageAvailable) {
        window.localStorage.removeItem(key);
    } else if (!isLocalStorageAvailable) {
        reportMessage('Local storage is unavailable');
    }
}
