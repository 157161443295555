import * as api from '@yandex-int/k-common/client/api';
import { LESSON_STATUS, LessonProblem } from '@yandex-int/k-common/typings';
import { showNetworkError } from 'platform-components';
import { call, put, select } from 'redux-saga/effects';

import { getCurrentYearCourseId } from 'common.components/course/selectors';
import { saveCLesson } from 'common.components/lesson/sagas/saveLesson';
import * as lessonSelectors from 'common.components/lesson/selectors';
import { addViewedHint } from 'common.components/user/actions';
import { getAssignedLessonsCount } from 'common.components/user/selectors';
import { ASSIGNED_LESSONS_COUNT } from 'common.components/user/user.constants';
import { removeProblemUsage, getProblemsRequest } from 'store/problems/actions';

import { actions as lessonActions } from '../index';

import { handleDeleteOrEditError } from './utils';

export function* removeProblemFromLessonSaga(action: ReturnType<typeof lessonActions.removeProblemFromLesson>) {
    try {
        const { problemId, stackId, onSuccess } = action.payload;

        const lessonId: number = yield select(lessonSelectors.getLessonId);
        const cLessonId: number = yield select(lessonSelectors.getCLessonId);
        const problems: LessonProblem[] = yield select(lessonSelectors.getLessonProblems);
        const lessonStatus: LESSON_STATUS = yield select(lessonSelectors.getLessonStatus);
        const courseId: number = yield select(getCurrentYearCourseId);

        const isLastProblem = problems.length === 0;
        const isAssigned = lessonStatus && lessonStatus !== LESSON_STATUS.NOT_ASSIGNED;

        if (isLastProblem && isAssigned) {
            yield unpublishCLesson(cLessonId);
        }

        /*
         * нужно, чтобы бейдж не мигал тк после удаления из урока и до получения актуального списка
         * карточек в уроках интерфейс считает, что данная карточка уже выдавалась и горит красный бэйдж
         */
        yield put(removeProblemUsage(courseId, lessonId, problemId));

        yield saveCLesson();

        // загружаем честную инфу по использованию карточек
        if (stackId) {
            yield put(getProblemsRequest(stackId));
        }

        if (onSuccess) {
            onSuccess(cLessonId, isLastProblem);
        }
    } catch (error) {
        console.error(error);
        yield put(showNetworkError(error));
    }
}

function* unpublishCLesson(cLessonId: number) {
    try {
        const assignedLessonsCount: number = yield select(getAssignedLessonsCount);
        const newAssignedLessonsCount = Math.max(0, assignedLessonsCount - 1);

        yield call(api.unpublishCLesson, cLessonId);

        yield put(addViewedHint(ASSIGNED_LESSONS_COUNT, newAssignedLessonsCount));

        yield put(lessonActions.setDateAssignment(null));
        yield put(lessonActions.setDateDeadline(null));

        yield put(lessonActions.lessonUpdated());
    } catch (error) {
        console.error(error);

        yield handleDeleteOrEditError(error, cLessonId);
    }
}
