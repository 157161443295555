import get from 'lodash/get';

import { getCoursesByClassAndSubject } from 'store/courses/selectors';
import { getCurrentYearCourseUtil } from 'utils/get-course';
import { getTeamIdFromPath, getSubjectFromPath } from 'utils/path';

export const getCurrentYearCourseId = (
    storeState: any,
    params: { classId?: string | null; subject?: string | null } = {}
): number | null => {
    const classId = params.classId ?? getTeamIdFromPath();
    const subject = params.subject ?? getSubjectFromPath();

    const courses = getCoursesByClassAndSubject(storeState, { classId, subject });
    return get(getCurrentYearCourseUtil(courses), 'id', null);
};
