import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import { SimpleCallback } from 'platform-components';

import { DeleteCLessonStore, LESSON_EDIT_ERRORS } from '../typings';

const initialState: DeleteCLessonStore = {
    deleteCLessonError: null,
    deletingCLessonId: null,
    deleteClessonLoadingStatus: LOADING_STATUSES.UNSENT,
};

const slice = createSlice({
    name: 'DELETE_LESSON',
    initialState: initialState,
    reducers: {
        deleteCLesson: (
            state,
            action: PayloadAction<{
                cLessonId: number;
                onSuccess?: SimpleCallback;
                onError?: SimpleCallback;
                isDraft: boolean;
            }>
        ) => {
            const { cLessonId } = action.payload;

            state.deleteClessonLoadingStatus = LOADING_STATUSES.LOADING;
            state.deletingCLessonId = cLessonId;
        },

        deleteCLessonSuccess: (state) => {
            state.deleteClessonLoadingStatus = LOADING_STATUSES.SUCCESS;
            state.deletingCLessonId = null;
        },

        deleteCLessonError: (
            state,
            action: PayloadAction<{
                error: LESSON_EDIT_ERRORS;
            }>
        ) => {
            const { error } = action.payload;

            state.deleteClessonLoadingStatus = LOADING_STATUSES.ERROR;
            state.deletingCLessonId = null;
            state.deleteCLessonError = error;
        },
    },
});

export const actions = slice.actions;
export const reducer = slice.reducer;

export default actions;
