import type { Team } from '@yandex-int/k-common';
import { getClass, patchClass, getClasses, getClassTeachers, putTeacherAccess } from '@yandex-int/k-common/client/api';
import type { ClassTeacher } from '@yandex-int/k-common/typings/api/defs/get-class-teachers';
import { showNetworkError, showIntlMessageNotification } from 'platform-components';
import { GRAVITIES, NOTIFICATION_THEMES } from 'platform-components/constants';
import { all, call, put, takeEvery, takeLatest, select } from 'redux-saga/effects';

import { iClass } from 'common.components/typings';
import * as userActions from 'common.components/user/actions';
import * as coursesTemplatesActions from 'store/courses-templates/actions';
import { teamBuilderActions } from 'common.components/team-builder/actions';
import { TEAM_BUILDER_ENTRY_STEPS } from 'common.components/team-builder/typings';
import { getTrimmedName } from 'utils/get-trimmed-name';

import {
    getClassSuccess,
    getClassError,
    getClassRequest,
    setNameRequest,
    setNameSuccess,
    setNameError,
    getTeachersRequest,
    getTeachersSuccess,
    removeTeacher,
    restoreTeacher,
    getTeachersError,
    getTeam as getTeamAction,
} from './actions';
import { SET_NAME_REQUEST, GET_TEACHERS_REQUEST, REMOVE_TEACHER, RESTORE_TEACHER, GET_TEAM } from './constants';
import { getLetter, getNumber } from './selectors';

const NOTIFICATION_CLOSE_DELAY = 3000;

export function* classesSagasWatcher(): Generator<unknown, void> {
    yield all([
        yield takeLatest(GET_TEAM, getClassSaga),
        yield takeLatest(SET_NAME_REQUEST, setNameSaga),
        yield takeEvery(GET_TEACHERS_REQUEST, loadTeachersSaga),
        yield takeEvery(REMOVE_TEACHER, removeTeacherSaga),
        yield takeEvery(RESTORE_TEACHER, restoreTeacherSaga),
    ]);
}

export function* getClassSaga(action: ReturnType<typeof getTeamAction>) {
    const { classId } = action;

    yield call(getTeam, classId);
}

export function* getTeam(teamId: number) {
    try {
        yield put(getClassRequest(teamId));

        const data: iClass = yield call(getClass, teamId, {
            getPinAndPass: true,
            getParentIsBinded: true,
            getExpandBinded: true,
        });
        yield put(getClassSuccess(teamId, data));

        return data;
    } catch (error) {
        console.error(error);

        yield put(getClassError(teamId, error));
        yield put(showNetworkError(error));

        return null;
    }
}

/**
 * Выставляет имя класса
 */
export function* setNameSaga(action: ReturnType<typeof setNameRequest>) {
    const { classId, letter, number } = action;
    interface SavedClass {
        letter: string;
        number: number;
    }

    try {
        const { letter: savedLetter, number: savedNumber }: SavedClass = yield call(patchClass, classId, {
            letter,
            number,
        });
        const teams: Team[] = yield call(getClasses);
        const oldLetter: string | null = yield select(getLetter, { classId });
        const oldNumber: number | null = yield select(getNumber, { classId });

        yield put(userActions.setTeams(teams));
        yield put(setNameSuccess(classId, savedLetter, savedNumber));

        yield put(
            showIntlMessageNotification({
                closeDelay: NOTIFICATION_CLOSE_DELAY,
                gravity: GRAVITIES.BOTTOM,
                messageId: 'toast.class.renamed',
                messageValues: {
                    savedLetter: getTrimmedName(savedLetter),
                    savedNumber,
                    oldLetter: getTrimmedName(oldLetter),
                    oldNumber,
                },
                withExplicitClose: true,
                theme: NOTIFICATION_THEMES.TOAST,
            })
        );
        if (savedNumber !== oldNumber) {
            yield put(coursesTemplatesActions.getCoursesTemplatesRequest({ classId }));
            yield put(teamBuilderActions.start({ entryPoint: TEAM_BUILDER_ENTRY_STEPS.SUBJECTS }));
        }
    } catch (error) {
        console.error(error);
        yield put(setNameError(classId, error));
        yield put(showNetworkError(error));
    }
}

export function* loadTeachersSaga(action: ReturnType<typeof getTeachersRequest>) {
    const PAGE_SIZE = 1000;

    const { classId } = action;

    try {
        const teachers: ClassTeacher[] = yield call(getClassTeachers, classId, PAGE_SIZE);

        yield put(getTeachersSuccess(classId, teachers));
    } catch (error) {
        console.error(error);
        yield put(showNetworkError(error));
        yield put(getTeachersError(classId));
    }
}

export function* removeTeacherSaga(action: ReturnType<typeof removeTeacher>) {
    const REMOVE_NOTIFICATION_CLOSE_DELAY = 7000;
    const { teacherId, teacherName, restoreCallback } = action;

    const { id: classId, level, name } = action.classData;

    try {
        yield call(putTeacherAccess, classId, teacherId, false);

        yield put(getTeachersRequest(classId));

        yield put(
            showIntlMessageNotification({
                closeDelay: REMOVE_NOTIFICATION_CLOSE_DELAY,
                gravity: GRAVITIES.BOTTOM,
                messageId: 'toast.class.teacherRemoved',
                messageValues: { teacherName, level, name },
                notificationAction: restoreCallback,
                notificationMessageId: 'toast.class.restoreTeacher',
                withExplicitClose: true,
                theme: NOTIFICATION_THEMES.TOAST,
            })
        );
    } catch (error) {
        console.error(error);
        yield put(showNetworkError(error));
    }
}

export function* restoreTeacherSaga(action: ReturnType<typeof restoreTeacher>) {
    const REMOVE_NOTIFICATION_CLOSE_DELAY = 3000;
    const { teacherId, teacherName } = action;

    const { id: classId, level, name } = action.classData;

    try {
        yield call(putTeacherAccess, classId, teacherId, true);

        yield put(getTeachersRequest(classId));

        yield put(
            showIntlMessageNotification({
                closeDelay: REMOVE_NOTIFICATION_CLOSE_DELAY,
                gravity: GRAVITIES.BOTTOM,
                messageId: 'toast.class.teacherRestored',
                messageValues: { teacherName, level, name },
                withExplicitClose: true,
                theme: NOTIFICATION_THEMES.TOAST,
            })
        );
    } catch (error) {
        console.error(error);
        yield put(showNetworkError(error));
    }
}
