import { addMinutes, format } from 'date-fns';
import isNil from 'lodash/isNil';

import { LESSON_DATES } from 'common.components/constants';
import { getLessonDateTime, LESSON_TIME_MARKS } from 'utils/lesson';
import { LessonDate } from 'utils/lesson/lesson-time/types';

export const getDefaultAssignDate = () => {
    const nowDate = new Date();
    return addMinutes(nowDate, 5);
};

export const getDefaultDeadlineDate = () => {
    const nowDate = new Date();
    const dateFn = LESSON_TIME_MARKS[LESSON_DATES.NEXT_WEEK_MONDAY];
    return dateFn ? dateFn(nowDate) : null;
};

export const formatDate = (date: Date, divider: string): string => {
    const formattedDate = format(date, 'dd.MM');
    const formattedTime = format(date, 'HH:mm');

    return formattedDate + divider + formattedTime;
};

export const getDateValue = (now: Date, date: LessonDate | null): Date | null => {
    if (!date) {
        return null;
    }

    switch (date.type) {
        case LESSON_DATES.CUSTOM:
            return date.customDate;
        case LESSON_DATES.NONE:
            return null;
        default:
            return getLessonDateTime(now, date.type);
    }
};

export const getDate = (date: Date): string => date.toISOString().slice(0, 10);

export const getLmsTimes = (availableDates: Array<Date>, currentDate: Date | null): Array<Date> => {
    if (availableDates.length === 0 || isNil(currentDate)) {
        return [];
    }
    const cDate = getDate(currentDate);

    return availableDates.filter((date) => getDate(date) === cDate);
};
