import { apiConfig } from '../config';
import { getFetch } from '../fetch';

import { Team } from '../../../typings';

export function getClasses(): Promise<Array<Team>> {
    const { apiGetClasses } = apiConfig.getStaticUrls();

    return getFetch(apiGetClasses);
}
