import * as api from '@yandex-int/k-common/client/api';
import { showNetworkError } from 'platform-components/index';
import { call, put } from 'redux-saga/effects';

import { actions as lessonActions } from 'common.components/lesson';
import { courseSubscriptionsActions } from 'store/subscriptions/actions';

import { loadTeam } from './utils';

export function* getCLessonResultsSaga(action: ReturnType<typeof lessonActions.getClessonResultsRequest>) {
    try {
        const { cLessonId, courseId } = action.payload;

        yield loadTeam();

        const { data } = yield call(api.getFullClessonResults, cLessonId);
        yield put(courseSubscriptionsActions.getSubscriptionsByCourse({ courseId }));

        yield put(lessonActions.setCLessonResults({ results: data }));
    } catch (error) {
        console.error(error);

        yield put(showNetworkError(error));
    }
}
