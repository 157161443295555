import b from 'b_';
import cx from 'classnames';
import { H3, Text } from 'platform-components';
import { TEXT_MARKUP_TYPE, TEXT_WEIGHT } from 'platform-components/constants';
import PropTypes from 'prop-types';
import React from 'react';

import FormView from 'common.components/form-view/form-view';

import './headed-form-view-card.scss';

const THEMES = {
    DEFAULT: 'default',
    CLEAR: 'clear',
};

/** Обертка для формы внутри модалки. Выглядит как карточка с заголовком и описанием */
export const HeadedFormViewCard = React.forwardRef(
    ({ children, descriptionId, descriptionValues, header, headerId, mix, theme, description }, ref) => (
        <FormView.Card mix={cx(mix, b('headed-form-view-card', { theme }))} ref={ref}>
            <div className="headed-form-view-card__header">
                {headerId ? (
                    <H3 id={headerId} type={TEXT_MARKUP_TYPE.HTML} weight={TEXT_WEIGHT.M} />
                ) : (
                    <H3 type={TEXT_MARKUP_TYPE.HTML}>{header}</H3>
                )}
            </div>
            <FormView.CardSection mix="headed-form-view-card__description">
                {descriptionId ? (
                    <Text
                        id={descriptionId}
                        size={Text.SIZE.XL}
                        type={TEXT_MARKUP_TYPE.HTML}
                        values={descriptionValues}
                    />
                ) : (
                    description
                )}
            </FormView.CardSection>
            {children}
        </FormView.Card>
    )
);

HeadedFormViewCard.THEMES = THEMES;

HeadedFormViewCard.propTypes = {
    children: PropTypes.node.isRequired,
    description: PropTypes.string,
    descriptionId: PropTypes.string,
    descriptionValues: PropTypes.object,
    header: PropTypes.string,
    headerId: PropTypes.string,
    mix: PropTypes.string,
    theme: PropTypes.oneOf(Object.values(THEMES)),
};

HeadedFormViewCard.displayName = 'HeadedFormViewCard';

export default HeadedFormViewCard;
