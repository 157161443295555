import type { CourseTemplate } from '@yandex-int/k-common';
import { getCoursesTemplates } from '@yandex-int/k-common/client/api';
import { showNetworkError } from 'platform-components/src/components/notification/actions';
import { call, put, takeEvery, all } from 'redux-saga/effects';

import * as actions from './actions';
import * as constants from './constants';

export function* coursesTemplatesSagasWatcher() {
    yield all([takeEvery(constants.GET_COURSES_TEMPLATES_REQUEST, getCoursesTemplatesSaga)]);
}

function* getCoursesTemplatesSaga(action: ReturnType<typeof actions.getCoursesTemplatesRequest>) {
    const { classId, grade, onSuccess } = action;

    try {
        const coursesTemplates: CourseTemplate[] = yield call(getCoursesTemplates, { classId, grade });
        yield put(actions.getCoursesTemplatesSuccess({ classId, grade, coursesTemplates }));

        if (onSuccess) {
            onSuccess({ coursesTemplates });
        }
    } catch (error) {
        console.error(error);

        yield put(actions.getCoursesTemplatesError({ classId, grade }));
        yield put(showNetworkError(error));
    }
}
