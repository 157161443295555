import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import produce from 'immer';

import * as constants from './constants';
import { CoursesTemplatesStoreState, CoursesTemplatesAction } from './typings';

const initialState: CoursesTemplatesStoreState = {
    classes: {},
    grades: {},
};

const reducer = (state: CoursesTemplatesStoreState = initialState, action: CoursesTemplatesAction) => {
    // eslint-disable-next-line complexity
    return produce(state, (draft) => {
        // если мы запрашиваем шаблоны для несуществующего класса(по grade)
        const classId = action.classId;
        const grade = action.grade;

        if (!classId && !grade) {
            return;
        }

        if (classId && !state.classes[classId]) {
            draft.classes[classId] = {
                loadingStatus: LOADING_STATUSES.UNSENT,
                list: [],
            };
        }

        if (grade && !state.grades[grade]) {
            draft.grades[grade] = {
                loadingStatus: LOADING_STATUSES.UNSENT,
                list: [],
            };
        }

        const draftPart = classId ? draft.classes[classId] : draft.grades[grade!];

        switch (action.type) {
            case constants.GET_COURSES_TEMPLATES_REQUEST: {
                draftPart.loadingStatus = LOADING_STATUSES.LOADING;
                break;
            }

            case constants.GET_COURSES_TEMPLATES_SUCCESS: {
                draftPart.loadingStatus = LOADING_STATUSES.SUCCESS;
                draftPart.list = action.coursesTemplates;
                break;
            }

            case constants.GET_COURSES_TEMPLATES_ERROR: {
                draftPart.loadingStatus = LOADING_STATUSES.ERROR;
                break;
            }

            default:
                break;
        }
    });
};

export default reducer;
