import { postFetch } from '../fetch';

import { apiConfig } from '../config';
import { PatchTeamSyncStatus, PatchTeamSyncStatusParams } from '../../../typings';

export function patchTeamSyncStatus(params: PatchTeamSyncStatusParams): Promise<PatchTeamSyncStatus> {
    const { apiPatchTeamSyncStatus } = apiConfig.getStaticUrls();

    return postFetch(`${apiPatchTeamSyncStatus}`, {
        lms: params.lms,
        team_ids: [params.teamIds[0]],
        // отключаем всплытие ошибок для блокировки отображения в интерфейсе
    }).catch(console.error);
}
