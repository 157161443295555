import { Input } from '../../typings';

const INPUT_REGEXP = /^{input:(\d+)}$/;

export function isInput(text: string): boolean {
    return INPUT_REGEXP.test(text);
}

export function extractInputIndex(text: string): number {
    const match = INPUT_REGEXP.exec(text);

    if (match) {
        return Number(match[1]);
    }

    return -1;
}

export function isNumberInput(text: string, inputs: Input[]): boolean {
    return isInput(text) && inputs[extractInputIndex(text)].options.type_content === 'number';
}

export function isTextInput(text: string, inputs: Input[]): boolean {
    return (
        isInput(text) && ['text', 'spaceless', 'strict'].includes(inputs[extractInputIndex(text)].options.type_content)
    );
}

export function trimRegExp(regexp: RegExp): string {
    return regexp.toString().slice(1, regexp.toString().lastIndexOf('/'));
}

/**
 * Функция concatRegExps объединяет две регулярки:
 * - если вторая регулярка начинается со знака ^, то этот знак просто убирается и если он нужен, то его нужно учитывать
 * в первой регулярке.
 * - флаги регулярок также обнуляются и их нужно задавать отдельно через третий аргумент flags
 */
export function concatRegExps(leftRegExp: RegExp, rightRegExp = new RegExp(''), flags?: string | undefined) {
    const stringRightRegExp = trimRegExp(rightRegExp);

    return new RegExp(trimRegExp(leftRegExp) + stringRightRegExp.slice(stringRightRegExp.indexOf('^') + 1), flags);
}
