import { ContainerElement } from '@yandex-lego/components/Button';
import b from 'b_';
import cx from 'classnames';
import React, { forwardRef } from 'react';

import { MarkdownImageResource } from '../../../typings';
import { getResourceImageWidth, getResourceImageDisableSrcSet } from '../../../utils/image';
import Image from '../../image/image';

import './resource__image.scss';

export interface ImageProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    attrs?: Record<string, any>;
    className?: string;
    mix?: string;
    onClick?: (e: React.MouseEvent<ContainerElement | HTMLDivElement, MouseEvent>) => void;
}

type Props = ImageProps & MarkdownImageResource;

const resourceImage = forwardRef((props: Props, ref: React.Ref<HTMLImageElement>) => {
    const { attrs, className, mix, onClick, version } = props;
    const { src, ...restAttrs } = attrs || {};

    return (
        <Image
            alt=""
            className={className || cx(b('resource', 'image', restAttrs), mix)}
            disableSrcSet={getResourceImageDisableSrcSet({ version })}
            onClick={onClick}
            ref={ref}
            src={src}
            width={getResourceImageWidth(props)}
            {...restAttrs}
        />
    );
});

export default resourceImage;
