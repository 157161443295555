import b from 'b_';
import cx from 'classnames';
import * as React from 'react';

//@ts-ignore
import COLORS from '../../design/colors.scss';
import { TEXT_SIZE, TEXT_WEIGHT, UI_KIT_TEXT_TYPOGRAPHY } from '../constants';
import { SimpleText, SimpleTextProps } from '../simple-text/simple-text';

import './text.scss';

type OmittedProps = 'className' | 'style';

export interface TextProps extends Omit<SimpleTextProps, OmittedProps> {
    className?: string;
    color?: COLORS[keyof COLORS];
    ellipsis?: boolean;
    /** @deprecated Use `typography` prop instead */
    size?: TEXT_SIZE;
    uppercase?: boolean;
    lowercase?: boolean;
    /** @deprecated Use `typography` prop instead */
    weight?: TEXT_WEIGHT;
    typography?: UI_KIT_TEXT_TYPOGRAPHY;
}

export const Text = ({
    className,
    ellipsis,
    size = TEXT_SIZE.L,
    uppercase,
    lowercase,
    weight = TEXT_WEIGHT.S,
    color,
    typography,
    ...props
}: TextProps) => {
    const rootClassName = cx(
        b('text', {
            ellipsis,
            lowercase,
            size: typography ? undefined : size,
            typography,
            uppercase,
            weight: typography ? undefined : weight,
        }),
        className
    );

    const style = { color };

    return <SimpleText className={rootClassName} style={style} {...props} />;
};

Text.TAG = SimpleText.TAG;
Text.TYPE = SimpleText.TYPE;

Text.SIZE = TEXT_SIZE;
Text.WEIGHT = TEXT_WEIGHT;

export default Text;
