import b from 'b_';
import cx from 'classnames';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';

import { scrollIntoView } from '../../utils/scroll';
import { KEY_CODES, POPUP_AXIS, POPUP_INDENTS } from '../constants';
import Popup from '../popup/popup';

import DefaultOptionRenderer from './__default-option-renderer/select-suggest__default-option-renderer';
import ErrorMessageDefaultRenderer from './__error-message-default-renderer/select-suggest__error-message-default-renderer';
import Input from './__input/select-suggest__input';
import Option from './__option/select-suggest__option';
import { SelectSuggestTypes } from './constants';

import './select-suggest.scss';

class SelectSuggest extends React.Component {
    static propTypes = {
        autoFocus: PropTypes.bool,
        disabled: PropTypes.bool,
        errorMessage: PropTypes.string,
        errorRenderer: PropTypes.func,
        indent: PropTypes.oneOf(Object.values(POPUP_INDENTS)),
        labelKey: PropTypes.string,
        mix: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        name: PropTypes.string,
        onBlur: PropTypes.func,
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        onInputChange: PropTypes.func,
        onRequestHide: PropTypes.func,
        onRequestShow: PropTypes.func,
        optionRenderer: PropTypes.func,
        options: PropTypes.array,
        placeholder: PropTypes.string,
        shown: PropTypes.bool,
        type: PropTypes.oneOf([SelectSuggestTypes.INPUT, SelectSuggestTypes.SELECT]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        valueKey: PropTypes.string,
        onRequestClose: PropTypes.func,
        onRequestOpen: PropTypes.func,
    };

    static defaultProps = {
        labelKey: 'label',
        valueKey: 'value',
        optionRenderer: DefaultOptionRenderer,
    };

    state = {
        highlightedValue: null,
        inputText: '',
    };

    componentDidMount() {
        this.popup.setOwner(ReactDOM.findDOMNode(this.input));
    }

    // Если переделать открытие popup'a через проп opened, этот метод станет не нужен
    UNSAFE_componentWillUpdate(nextProps) {
        const { shown: nextShown } = nextProps;
        const { shown } = this.props;
        const shouldHide = shown && !nextShown;
        const shouldShow = !shown && nextShown;

        if (shouldHide) {
            this.hidePopup();
        } else if (shouldShow) {
            this.showPopup();
        }
    }

    getNullOption = () => {
        const { valueKey } = this.props;

        return { [valueKey]: null };
    };

    showPopup() {
        if (this.popup) {
            this.popup.updatePositions();
            this.popup.show();
        }
    }

    hidePopup() {
        if (this.popup) {
            this.popup.hide();
        }
    }

    popupRef = (ref) => {
        const { shown } = this.props;
        this.popup = ref;

        //TODO: выпилить после переделки попапа
        if (shown) {
            this.showPopup();
        }
    };

    inputRef = (ref) => {
        this.input = ref;
    };

    showOptions = () => {
        const { disabled, onRequestShow } = this.props;

        if (!disabled) {
            onRequestShow();
        }
    };

    hideOptions = () => {
        const { onRequestHide } = this.props;

        onRequestHide();
    };

    toggleOptions = () => {
        const { disabled, onRequestShow, onRequestHide, shown } = this.props;

        if (!disabled) {
            if (shown) {
                onRequestHide();
            } else {
                onRequestShow();
            }
        }
    };

    handleChange = (value) => {
        const { onChange } = this.props;

        if (onChange) {
            onChange(value);
        }

        this.setState({ inputText: '' });
    };

    handleOptionClick = (value) => {
        this.handleChange(value);
        this.hideOptions();
    };

    handleInputChange = (inputText) => {
        const { onInputChange, value } = this.props;

        if (value) {
            this.handleChange(this.getNullOption());
        }

        if (onInputChange) {
            onInputChange(inputText);
        }

        this.setState({ inputText, highlightedValue: null });
        this.showOptions();
    };

    handleKeyDown = (event) => {
        switch (event.keyCode) {
            case KEY_CODES.ARROW_DOWN:
                event.preventDefault();
                this.highlightNextOption();
                break;
            case KEY_CODES.ARROW_UP:
                event.preventDefault();
                this.highlightPrevOption();
                break;
            case KEY_CODES.ENTER: {
                const { highlightedValue } = this.state;
                const { valueKey, options, shown } = this.props;

                if (shown && !isNil(highlightedValue)) {
                    this.handleChange(options.find((option) => option[valueKey] === highlightedValue));
                }

                this.toggleOptions();
                break;
            }
            case KEY_CODES.ESCAPE:
                this.hideOptions();
                break;
            default:
                break;
        }
    };

    // Если переделать открытие popup'a через проп opened, этот метод станет не нужен
    handlePopupToggle = ({ shown }) => {
        const { onRequestHide } = this.props;

        if (!shown) {
            onRequestHide();
        }
    };

    setHighlightedOption = (step) => {
        const { highlightedValue } = this.state;
        const { options, valueKey } = this.props;

        if (!options.length) {
            return;
        }

        this.showOptions();

        if (highlightedValue === null) {
            const firstOption = options[0];

            this.setState({ highlightedValue: firstOption[valueKey] });
        } else {
            let currentHighlightedValueIndex = 0;

            options.find((option, index) => {
                currentHighlightedValueIndex = index;

                return option[valueKey] === highlightedValue;
            });

            const nextHighlightedOption = options[currentHighlightedValueIndex + step];

            if (nextHighlightedOption) {
                this.setState({ highlightedValue: nextHighlightedOption[valueKey] });
            }
        }
    };

    highlightNextOption = () => {
        this.setHighlightedOption(1);
    };

    highlightPrevOption = () => {
        this.setHighlightedOption(-1);
    };

    setHighlighted = (value) => {
        const { valueKey } = this.props;

        this.setState({ highlightedValue: value[valueKey] });
    };

    removeHighlighted = () => {
        this.setState({ highlightedValue: null });
    };

    scrollOptionIntoView = (optionRef) => {
        const domPopup = ReactDOM.findDOMNode(this.popup);

        scrollIntoView(domPopup, optionRef);
    };

    getInputValue = () => {
        const { options, labelKey, valueKey, value } = this.props;
        const { inputText } = this.state;

        if (inputText !== '') {
            return inputText;
        }

        const currentOption = options.find((option) => option[valueKey] === value);

        return currentOption ? currentOption[labelKey] : '';
    };

    render() {
        const {
            autoFocus,
            disabled,
            errorMessage,
            errorRenderer: ErrorMessageRenderer = ErrorMessageDefaultRenderer,
            indent,
            labelKey,
            mix,
            name,
            onBlur,
            onFocus,
            optionRenderer: OptionRenderer,
            options = [],
            placeholder,
            shown,
            type,
            valueKey,
        } = this.props;

        const { highlightedValue } = this.state;
        const className = b('select-suggest', { disabled, error: Boolean(errorMessage) });
        const handleInputClick = type !== SelectSuggestTypes.INPUT ? this.toggleOptions : null;
        const inputValue = this.getInputValue();

        return (
            <div className={cx(className, mix)} onKeyDown={this.handleKeyDown}>
                <Input
                    autoFocus={autoFocus}
                    disabled={disabled}
                    name={name}
                    onBlur={onBlur}
                    onChange={this.handleInputChange}
                    onClick={handleInputClick}
                    onFocus={onFocus}
                    opened={shown}
                    placeholder={placeholder}
                    ref={this.inputRef}
                    type={type}
                    value={inputValue}
                />
                {errorMessage && <ErrorMessageRenderer message={errorMessage} />}
                <Popup
                    autoclosable
                    axis={POPUP_AXIS.BOTH}
                    indent={indent}
                    maxHeight="250px"
                    offsetTop={2}
                    onToggle={this.handlePopupToggle}
                    ref={this.popupRef}
                >
                    {options.map((value) => {
                        const highlighted = value[valueKey] === highlightedValue;

                        return (
                            <Option
                                className={b('select-suggest__option', { highlighted })}
                                highlighted={highlighted}
                                key={value[valueKey]}
                                onClick={this.handleOptionClick}
                                onMouseEnter={this.setHighlighted}
                                onMouseLeave={this.removeHighlighted}
                                scrollOptionIntoView={this.scrollOptionIntoView}
                                value={value}
                            >
                                <OptionRenderer text={value[labelKey]} value={value} />
                            </Option>
                        );
                    })}
                </Popup>
            </div>
        );
    }
}

export default SelectSuggest;
