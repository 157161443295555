import { createSelector } from 'reselect';

import { SalesCodeModalStoreState } from './typings';

const modalSelector = <T extends { salesCodeModal: SalesCodeModalStoreState }>(storeState: T) =>
    storeState.salesCodeModal;

export const getSalesCode = createSelector(modalSelector, (state) => state.salesCode);

export const getShouldShowSalesCodeModal = createSelector(modalSelector, (state) => state.shouldShowModal || false);
