import * as React from 'react';
const SvgBackspaceIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 24 18" fill="none" {...props}>
        <path
            d="M22 0H7c-.69 0-1.23.35-1.59.88L.37 8.445a1 1 0 000 1.11l5.04 7.555c.36.53.9.89 1.59.89h15c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2zm0 16H7.07l-4.3-6.446a1 1 0 010-1.109L7.06 2H22v14zM9.705 13.295c.39.39 1.02.39 1.41 0L14 10.41l2.885 2.885a.997.997 0 101.41-1.41L15.41 9l2.885-2.885a.997.997 0 00-1.41-1.41L14 7.59l-2.885-2.885a.997.997 0 00-1.41 1.41L12.59 9l-2.885 2.885a.997.997 0 000 1.41z"
            fill="#EB9696"
        />
    </svg>
);
export default SvgBackspaceIcon;
