import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';

import { ParentRegistrationStoreState } from './typings';

const initialState: ParentRegistrationStoreState = {
    childName: '',
    studentGrade: null,
    createChildLoadingStatus: LOADING_STATUSES.UNSENT,
    createdChildId: null,
};

export const setChildName = (state: ParentRegistrationStoreState, action: PayloadAction<{ name: string }>) => {
    state.childName = action.payload.name;
};

export const setStudentGrade = (state: ParentRegistrationStoreState, action: PayloadAction<{ grade: number }>) => {
    state.studentGrade = action.payload.grade;
};

// этот акшн отличается от акшена в add-child-modal/actions.ts
export const createChildRequest = (state: ParentRegistrationStoreState) => {
    state.createChildLoadingStatus = LOADING_STATUSES.LOADING;
};

export const createChildSuccess = (state: ParentRegistrationStoreState, action: PayloadAction<{ childId: number }>) => {
    state.createdChildId = action.payload.childId;
    state.createChildLoadingStatus = LOADING_STATUSES.SUCCESS;
};

export const createChildError = (state: ParentRegistrationStoreState) => {
    state.createChildLoadingStatus = LOADING_STATUSES.ERROR;
};

const slice = createSlice({
    name: 'SPEC_PROJECT_COURSES',
    initialState: initialState,
    reducers: {
        setChildName,
        setStudentGrade,
        createChildRequest,
        createChildSuccess,
        createChildError,
    },
});

export const actions = slice.actions;
export const reducer = slice.reducer;
