import cx from 'classnames';
import { Popup } from 'platform-components';
import { POPUP_AXIS } from 'platform-components/constants';
import { getMe } from 'platform-components/src/components/user/selectors';
import React, { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { accessibleClick } from '@edu-frontend/modules/src/shared/lib/a11y';

import UserInfo from './__info/user__info';
import LoginButton from './__login/user__login';
import UserMenu from './__menu/user__menu';

import './user.scss';

interface UserProps {
    mix?: string;
    retpath?: string;
}

const User = (props: UserProps) => {
    const { mix, retpath } = props;

    const me = useSelector(getMe);

    const popupRef = useRef<Popup>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    const togglePopup = useCallback(() => {
        if (popupRef.current) {
            popupRef.current.toggle();
        }
    }, []);

    useEffect(() => {
        if (popupRef.current) {
            popupRef.current.setOwner(containerRef.current);
        }
    }, []);

    const className = cx('user', mix);

    if (!me) {
        return (
            <div className={cx('user__login', className)}>
                <LoginButton retpath={retpath} />
            </div>
        );
    }

    return (
        <div className={className} ref={containerRef} {...accessibleClick(togglePopup)}>
            <UserInfo withAvatar />
            <Popup autoclosable axis={POPUP_AXIS.END} mix="user__popup" ref={popupRef}>
                <UserMenu retpath={retpath} />
            </Popup>
        </div>
    );
};

export default User;
