import React from 'react';

import User from 'common.components/user/user';

import './withUserLogin.scss';

export const withUserLogin = (WrappedComponent: React.ComponentType) => {
    return () => (
        <>
            <header className="with-user-login__header">
                <User />
            </header>

            <WrappedComponent />
        </>
    );
};
