import get from 'lodash/get';
import { createSelector } from 'reselect';

import { ReduxIndexStore } from '../../typings';

import { TeacherRegistrationData, TeacherRegistrationPageStore } from './typings';

const teacherRegistrationStateSelector = (storeState: ReduxIndexStore): TeacherRegistrationPageStore =>
    storeState.teacherRegistrationPage;

export const getRegistrationData = createSelector(
    teacherRegistrationStateSelector,
    (state: TeacherRegistrationPageStore): TeacherRegistrationData => state.registrationData
);

export const getRegistrationDataClassGrades = createSelector(
    getRegistrationData,
    (registrationData: TeacherRegistrationData): number[] => {
        const registrationClasses = registrationData?.classes || [];

        // @ts-ignore
        return registrationClasses.map((icl) => icl.number).filter((grade) => grade !== undefined && grade !== null);
    }
);

// export const getRegistrationDataClassLetter = createSelector(
//     getRegistrationData,
//     (registrationData: TeacherRegistrationData): string | undefined => registrationData?.class?.letter || undefined
// );

export const getSalesCode = createSelector(
    getRegistrationData,
    (registrationData: TeacherRegistrationData): string | undefined => registrationData.salesCode
);

export const getIsSchoolStepDone = createSelector(
    getRegistrationData,
    (registrationData: TeacherRegistrationData): boolean => {
        const addressIds = get(registrationData, 'address.id');
        const schoolTitle = get(registrationData, 'school.title');

        return Boolean(addressIds && schoolTitle);
    }
);

export const getIsClassStepDone = createSelector(
    getRegistrationData,
    (registrationData: TeacherRegistrationData): boolean => {
        if (!registrationData.classes) {
            return false;
        }

        return Boolean(registrationData.classes.length);
    }
);

export const getIsStudentsStepDone = createSelector(getRegistrationData, (registrationData) => {
    const students = registrationData.students || [];

    return students.some((student) => Boolean(student.trim()));
});
