export function validateEmail(email: string): boolean {
    return /^.+@.+\..+$/.test(email);
}

export const MAX_URL_LENGTH = 2048;

export function validateYaDiskUrl(value: string | undefined, availableDomains: string[]): boolean {
    if (!value) {
        return true;
    }
    let url: URL;
    try {
        url = new URL(value);
    } catch (e) {
        // TypeError: Failed to construct 'URL': Invalid URL
        return false;
    }
    if (url.href.length > MAX_URL_LENGTH) {
        return false;
    }
    const isValidHost = availableDomains.some((domain) => url.host === domain);
    const isValidProtocol = url.protocol === 'https:';
    return isValidHost && isValidProtocol;
}
