import { withBemMod } from '@bem-react/core';
import { cnButton } from '@yandex-lego/components/Button';
import React from 'react';

import { BUTTON_TRANSPARENCY_THEME } from '../types';

export interface ButtonTransparencyThemeLightProps {
    transparencyTheme?: BUTTON_TRANSPARENCY_THEME.LIGHT;
}

export const withTransparencyThemeLight = withBemMod<ButtonTransparencyThemeLightProps>(
    cnButton(),
    { transparencyTheme: BUTTON_TRANSPARENCY_THEME.LIGHT },
    (Button) =>
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ({ transparencyTheme, ...props }: ButtonTransparencyThemeLightProps) => <Button {...props} />
);
