export enum LOADING_STATUSES {
    UNSENT = 'UNSENT',
    LOADING = 'LOADING',
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
}

export enum ANSWER_STATUSES {
    INCORRECT = 0,
    CORRECT = 1,
    NOT_EVALUATED = 2,
    SKIPPED_CORRECT = 3 // статус присутствует только на фронтенде
}

export enum HTTP_METHODS {
    GET = 'GET',
    POST = 'POST',
}

export const NO_CACHE = 'no-cache';
