import { H2, Text } from 'platform-components';
import React from 'react';

import './external-error.scss';

interface OwnProps {
    children: React.ReactNode | Array<React.ReactNode>;
    descriptionTextId: string;
    headerTextId: string;
}

interface Props extends OwnProps {}

const ExternalError = (props: Props) => {
    const { children, descriptionTextId, headerTextId } = props;

    return (
        <div className="external-error">
            <H2 id={headerTextId} />
            <div className="external-error__description">
                <Text id={descriptionTextId} />
            </div>
            <div className="external-error__controls">{children}</div>
        </div>
    );
};

export default ExternalError;
