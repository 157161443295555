import { LessonProblem } from '@yandex-int/k-common/typings';

import { LessonInfo } from 'store/problems/typings';

import * as constants from './constants';

export const getProblemsRequest = (stackId: number) => <const>{ type: constants.GET_PROBLEMS_REQUEST, stackId };

export const getProblemsSuccess = (problems: Array<LessonProblem>, stackId: number) =>
    <const>{ type: constants.GET_PROBLEMS_SUCCESS, problems, stackId };

export const getProblemsError = (error: Error) => <const>{ type: constants.GET_PROBLEMS_ERROR, error };

export const getProblemsInCourseRequest = (problemIds: Array<number>, courseId: number) =>
    <const>{ type: constants.GET_PROBLEMS_IN_COURSE_REQUEST, problemIds, courseId };

export const getProblemsInCourseSuccess = (problemsInCourse: Record<number, Array<LessonInfo>>, courseId: number) =>
    <const>{ type: constants.GET_PROBLEMS_IN_COURSE_SUCCESS, problemsInCourse, courseId };

export const getProblemsInCourseError = () => <const>{ type: constants.GET_PROBLEMS_IN_COURSE_ERROR };

export const removeProblemUsage = (courseId: number, lessonId: number, problemId: number) =>
    <const>{ type: constants.REMOVE_PROBLEM_USAGE, courseId, lessonId, problemId };
