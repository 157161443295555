import { withBemMod } from '@bem-react/core';
import { cnButton } from '@yandex-lego/components/Button';
import React from 'react';

import { BUTTON_TRANSPARENCY_THEME } from '../types';

export interface ButtonTransparencyThemeDarkProps {
    transparencyTheme?: BUTTON_TRANSPARENCY_THEME.DARK;
}

export const withTransparencyThemeDark = withBemMod<ButtonTransparencyThemeDarkProps>(
    cnButton(),
    { transparencyTheme: BUTTON_TRANSPARENCY_THEME.DARK },
    (Button) =>
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ({ transparencyTheme, ...props }: ButtonTransparencyThemeDarkProps) => <Button {...props} />
);
