import { getFetch } from '../fetch';

import { apiConfig } from '../config';
import { Lesson } from '../../../typings';

export function getLesson(lessonId: number): Promise<Lesson> {
    const { apiGetLesson } = apiConfig.getStaticUrls();

    return getFetch(`${apiGetLesson}${lessonId}/`);
}
