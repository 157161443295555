import { Text, useIntl } from 'platform-components';
import { TEXT_MARKUP_TYPE } from 'platform-components/constants';
import React from 'react';

import { StudentsPrintInvitationKey } from 'common.components/students-print/__invitation-key/students-print__invitation-key';

import './students-print__invitation-credentials.scss';

interface Props {
    instructionId: string;
    firstLabelId: string;
    firstLabelLength: number;
    firstValue: string;
    secondLabelId: string;
    secondLabelLength: number;
    secondValue: string;
}

export const StudentsPrintInvitationCredentials = (props: Props) => {
    const { instructionId, firstLabelId, firstLabelLength, firstValue, secondLabelId, secondLabelLength, secondValue } =
        props;

    const intl = useIntl();

    return (
        <>
            <div className="students-print__invitation-instruction">
                <Text id={instructionId} size={Text.SIZE.S} type={TEXT_MARKUP_TYPE.HTML} />
            </div>
            <div className="students-print__invitation-credentials">
                <StudentsPrintInvitationKey
                    label={intl.formatMessage({ id: firstLabelId })}
                    length={firstLabelLength}
                    mix="qa_student-login"
                    value={firstValue}
                />
                <StudentsPrintInvitationKey
                    label={intl.formatMessage({ id: secondLabelId })}
                    length={secondLabelLength}
                    mix="qa_student-code"
                    value={secondValue}
                />
            </div>
        </>
    );
};
