import React, { forwardRef, useMemo } from 'react';

import { ScaleStrategy } from './constants';
import { useImageResolution } from './context';
import { getSrcForResolution, getSrcSet } from './utils';

export interface ImageMeta {
    baseWidth?: number;
    strategy?: ScaleStrategy;
}

export type ImageProps = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & {
    disableSrcSet?: boolean;
    meta?: ImageMeta;
};

export const Image = forwardRef((props: ImageProps, ref: React.Ref<HTMLImageElement>) => {
    const { disableSrcSet, src: srcFromProps, srcSet: srcSetFromProps, meta = {}, ...restProps } = props;
    const { resolution } = useImageResolution();
    const { strategy, baseWidth } = meta;

    const srcSet = useMemo(() => {
        if (srcSetFromProps) {
            return srcSetFromProps;
        }

        if (resolution || disableSrcSet || !srcFromProps) {
            return undefined;
        }

        return getSrcSet(srcFromProps, strategy, baseWidth) || undefined;
    }, [disableSrcSet, baseWidth, resolution, srcFromProps, srcSetFromProps, strategy]);

    const src = useMemo(() => {
        if (!resolution) {
            return srcFromProps;
        }

        return srcFromProps && getSrcForResolution(srcFromProps, resolution);
    }, [resolution, srcFromProps]);

    return <img crossOrigin="anonymous" {...restProps} ref={ref} src={src} srcSet={srcSet} />;
});

Image.displayName = 'Image';

export default Image;
