export enum LESSONS_TYPES {
    UNASSIGNED = 'unassigned',
    ASSIGNED = 'assigned',
    ACTIVE = 'active',
    FINISHED = 'finished',
    FUTURE = 'finished',
}

export enum SYNC_STATUS {
    FIRST_SYNC = 'first_sync',
    SYNCING = 'syncing',
    READY = 'ready',
}

export enum PHONE_USAGE_PERMISSION_STATUS {
    REJECTED = -1,
    UNACCEPTED = 0,
    ACCEPTED = 1,
}

export enum MAIL_PERMISSION_STATUS {
    REJECTED = -1,
    ACCEPTED = 1,
}

export const RUN_CODE_LENGTH_LIMIT = 25000;

export type MailPermissionStatus = MAIL_PERMISSION_STATUS | null;
