import b from 'b_';
import React from 'react';

import './external-background.scss';

const ExternalBackground = () => (
    <div className="external-background">
        <div className={b('external-background', 'segment', { corner: true })} />
        <div className={b('external-background', 'segment', { horizontal: true })} />
        <div className={b('external-background', 'segment', { vertical: true })} />
    </div>
);

export default ExternalBackground;
