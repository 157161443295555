import React from 'react';

import { LogoFull } from '@edu-frontend/modules/src/shared/ui/Logo';
import b from 'b_';
import cx from 'classnames';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { LOGO_THEME } from 'platform-components/constants';
import PropTypes from 'prop-types';

import './logo.scss';

interface OwnProps {
    mix?: string;
    onClickService?: (e: React.MouseEvent<HTMLElement>) => boolean | undefined;
    theme: LOGO_THEME;
}

interface HOCProps {}

interface Props extends OwnProps, HOCProps {}

export class Logo extends React.PureComponent<Props> {
    static contextTypes = {
        staticUrls: PropTypes.object.isRequired,
    };

    static defaultProps = {
        theme: LOGO_THEME.BLACK,
    };
    context!: React.ContextType<React.Context<{ staticUrls: Record<string, string> }>>;

    static THEME = LOGO_THEME;

    render() {
        const { mix, theme, onClickService } = this.props;

        return (
            <div className={cx(b('logo', { theme }), mix)}>
                <LogoFull
                    className="logo__main"
                    color={theme === LOGO_THEME.BLACK ? 'dark' : 'light'}
                    onServiceLogoClick={onClickService}
                />
            </div>
        );
    }
}

const component = Logo;

export default hoistNonReactStatics(component, Logo);
