import cx from 'classnames';
import { Editor } from 'codemirror';
import { RefreshKids } from 'education-icons';
import React, { useRef, useState } from 'react';

import { Button } from '../../button/button';
import { RobotCodeLoadable } from '../../robot/__code/robot__code-loadable';
import { useRefreshOnOrientationChange } from '../useRefreshOnOrientationChange';

// @ts-ignore
import styles from './code-runner__stdio.module.scss';

interface Props {
    value?: string;
    title?: React.ReactNode;
    className?: string;
    onChange?: (value: string) => void;
    readOnly?: boolean;
    attention?: boolean;
    isException?: boolean;
}

export function Stdio(props: Props) {
    const [dirty, setDirty] = useState(false);
    const editorRef = useRef<Editor>();
    const reset = () => editorRef.current?.setValue(props.value || '');
    const { key, initialValue } = useRefreshOnOrientationChange({
        initialValue: props.value,
        getCurrentValue: () => editorRef.current?.getValue(),
    });
    return (
        <div className={cx(styles.stdio, props.className)}>
            {props.title && <span className={styles.stdio__title}>{props.title}</span>}
            <div className={styles.stdio__editor}>
                <RobotCodeLoadable
                    className={cx(styles.stdio__code, {
                        [styles.stdio__code_dirty]: dirty,
                        [styles.stdio__code_attention]: props.attention,
                        [styles.stdio__code_attention_exception]: props.isException,
                        [styles.stdio__code_readonly]: props.readOnly,
                    })}
                    editorRef={editorRef}
                    initialValue={initialValue}
                    key={key}
                    onChange={(_editor, _data, value) => {
                        props.onChange?.(value);
                        setDirty(value !== String(props.value));
                    }}
                    options={{
                        mode: 'text',
                        indentUnit: 4,
                        readOnly: Boolean(props.readOnly),
                        cursorScrollMargin: 0,
                        styleActiveLine: false,
                    }}
                    paddingBottom={0}
                    paddingRight={0}
                />
                {!props.readOnly && (
                    <Button
                        className={styles.stdio__reset}
                        disabled={!dirty}
                        icon={<RefreshKids />}
                        onClick={reset}
                        rounded
                        size={Button.SIZE.S}
                        view={Button.VIEW.ACTION}
                    />
                )}
            </div>
        </div>
    );
}
