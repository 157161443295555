import { apiConfig } from '@yandex-int/k-common/client/api/config';
import { Me } from '@yandex-int/k-common/typings/me';
import { getExactPathMatch, getFromLocalStorage } from 'platform-components/utils';

import { getPathName } from 'common.components/pathname/selectors';

import { LOCAL_STORAGE_KEY } from './constants';

export function shouldShowUserPopup(me: Me | null | undefined) {
    // на ssr не показываем popup - нет localstorage, данные не актуальные
    if (isSSR || !me?.id) {
        return false;
    }

    const pathname = getPathName() || '';
    const urls = apiConfig.getStaticUrls();
    // @ts-ignore
    const { path = '' } = getExactPathMatch(urls, pathname) || {};
    const isRegistration = path === urls.registration;

    // Для незарегистрированных пользователей на странице регистрации показываем popup всегда
    if (isRegistration && !me.is_teacher && !me.is_parent) {
        return true;
    }

    const previousUserId = getFromLocalStorage(LOCAL_STORAGE_KEY, null);

    return me.id !== Number(previousUserId);
}
