import { useCallback } from 'react';
import { generatePath, useParams } from 'react-router';

/**
 * @example
 * const myPath = useRouterPath(url, { courseId: 1 });
 */
export const useRouterPath: typeof generatePath = (pattern, extraParams) => {
    const params = useParams();
    // @ts-ignore
    return generatePath(pattern, { ...params, ...extraParams });
};

/**
 * @example
 * const genPath = useGeneratePath();
 * const myPath = genPath(url, { courseId: 1 });
 */
export const useGeneratePath = (): typeof generatePath => {
    const params = useParams();

    return useCallback(
        (pattern, extraParams) => {
            // @ts-ignore
            return generatePath(pattern, { ...params, ...extraParams });
        },
        [params]
    );
};
