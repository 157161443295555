const difference = require('lodash/difference');

/**
 * Сортировка массива по вхождениям
 *
 * @example
 * // вернет [3,2,4,1]
 * sortByIntersection[1,2,3,4], [5,2,4,6,1]);
 * 1. убираем невошедшие значения: _ 2 4 _ 1
 * 2. вставляем не введенные правильные ответы: 3 2 4 _ 1
 * 3. убираем пустоты: 3 2 4 1
 *
 * @example
 * // вернет [2,1,3]
 * sortByIntersection[1,2,3], [0,2,0,1,0,3,0]);
 *
 * функция не меняет исходный массив
 *
 * @param target {array} - целевой массив, по которому происходит сортировка
 * @param source {array} - исходный массив
 *
 * @returns {array} - новый отсортированный массив
 */
const sortByIntersection = (target, source = []) => {
    let result = [];
    const emptyIndexes = [];

    let targetCopy = [...target];
    source.forEach((item, currentIndex) => {
        const comparedIndex = targetCopy.indexOf(item);

        if (comparedIndex !== -1) {
            targetCopy.splice(comparedIndex, 1);
            result[currentIndex] = item;
        } else {
            result[currentIndex] = null;
            emptyIndexes.push(currentIndex);
        }
    });

    const targetDiff = difference(target, result);

    for (let index of emptyIndexes) {
        if (!targetDiff.length) {
            break;
        }
        const [el] = targetDiff.splice(0, 1);

        result[index] = el;
    }

    result = result.filter((i) => i !== null);

    if (result.length < target.length) {
        result.push(...difference(target, result));
    }

    return result.filter((i) => i !== null);
};

export default sortByIntersection;
