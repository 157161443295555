import { isDigital } from 'platform-components/src/utils/input-validators';

export function canBeSchoolCode(code: string) {
    return isDigital(code);
}

export function getIsPasswordLengthValid(password: string, length: number) {
    return Boolean(password) && password.length === length;
}

export function canBeParentCode(code: string) {
    const regexp = /^[zZЯяeEУу]\d*$/i;

    return regexp.test(code);
}
