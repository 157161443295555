import React from 'react';
import type { PropsWithChildren, AriaAttributes } from 'react';

import './A11yHidden.scss';

interface IA11yHiddenProps<T extends React.ElementType> extends PropsWithChildren, AriaAttributes {
    as?: T;
    id?: string;
}

/**
 * Компонент для вывода текста, невидимого глазу, но видимого скринридеру.
 * Полезен в случаях, когда не получается использовать aria-label, aria-labelledby, etc.
 */
export const A11yHidden = <T extends React.ElementType = 'span'>({
    as,
    id,
    children,
    ...props
}: IA11yHiddenProps<T>) => {
    const Tag = as || 'span';

    return (
        <Tag className="A11yHidden" id={id} {...props}>
            {children}
        </Tag>
    );
};
