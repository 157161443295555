import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import { produce } from 'immer';

import * as constants from './constants';
import { ERROR_TYPES, WRONG_AUTH_CODE, SCHOOL_CODE_LENGTH } from './start-screen.constants';

export const initialState = {
    password: '',
    schoolData: null,
    authCodeStatus: LOADING_STATUSES.UNSENT,
    login: '',
    loginStatus: LOADING_STATUSES.UNSENT,
    shouldShowErrorPopup: false,
    errorType: null,
    shouldRedirectToClassroom: false,
    isSchool: null,
    isParent: null,
    passwordLength: SCHOOL_CODE_LENGTH,
};

const startScreenReducer = (state = initialState, action) => {
    // eslint-disable-line complexity
    // eslint-disable-next-line complexity
    return produce(state, (draft) => {
        switch (action.type) {
            case constants.VERIFY_PASSWORD_REQUEST:
                Object.assign(draft, {
                    authCodeStatus: LOADING_STATUSES.LOADING,
                    isParent: null,
                    isSchool: null,
                    shouldShowErrorPopup: false,
                });
                break;

            case constants.VERIFY_SCHOOL_CODE_SUCCESS:
                const { isParent, isSchool, schoolData } = action;

                Object.assign(draft, {
                    schoolData,
                    isParent,
                    isSchool,
                    authCodeStatus: LOADING_STATUSES.SUCCESS,
                });
                break;

            case constants.VERIFY_SCHOOL_CODE_ERROR:
                Object.assign(draft, {
                    authCodeStatus: LOADING_STATUSES.ERROR,
                    errorType: ERROR_TYPES.WRONG_SCHOOL_CODE,
                    schoolData: null,
                    shouldShowErrorPopup: action.error.status === WRONG_AUTH_CODE,
                });
                break;

            case constants.SUBMIT_LOGIN_REQUEST:
                draft.loginStatus = LOADING_STATUSES.LOADING;
                draft.shouldShowErrorPopup = false;
                break;

            case constants.SUBMIT_LOGIN_SUCCESS:
                draft.shouldRedirectToClassroom = true;
                draft.loginStatus = LOADING_STATUSES.SUCCESS;
                break;

            case constants.SUBMIT_LOGIN_ERROR:
                Object.assign(draft, {
                    errorType: ERROR_TYPES.WRONG_LOGIN,
                    loginStatus: LOADING_STATUSES.ERROR,
                    shouldShowErrorPopup: action.error.status === WRONG_AUTH_CODE,
                });
                break;

            case constants.SET_LOGIN:
                draft.login = action.login;
                draft.shouldShowErrorPopup =
                    state.authCodeStatus === LOADING_STATUSES.SUCCESS ? false : state.shouldShowErrorPopup;
                break;

            case constants.SET_PASSWORD:
                draft.password = action.password;
                draft.shouldShowErrorPopup = false;
                break;

            case constants.SET_PASSWORD_LENGTH:
                draft.passwordLength = action.length;
                break;

            // no default
        }
    });
};

export default startScreenReducer;
