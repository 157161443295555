import React from 'react';
import { FormattedMessage } from 'react-intl';

import { TEXT_MARKUP_TYPE } from '../constants';
import { FormattedHTMLMessage } from '../formatted-html-message/formatted-html-message';
import FormattedMarkdownMessage from '../formatted-markdown-message/formatted-markdown-message';

interface Props {
    id: string;
    type?: TEXT_MARKUP_TYPE;
    values?: React.ComponentProps<typeof FormattedMessage>['values'];
}

export const TextMarkup = ({ id, type, values, ...props }: Props) => {
    switch (type) {
        case TEXT_MARKUP_TYPE.HTML:
            return <FormattedHTMLMessage id={id} values={values} {...props} />;

        case TEXT_MARKUP_TYPE.MARKDOWN:
            return <FormattedMarkdownMessage id={id} values={values} />;

        case TEXT_MARKUP_TYPE.PLAIN:
            return <FormattedMessage id={id} values={values} {...props} />;

        default:
            return null;
    }
};

TextMarkup.defaultProps = {
    type: TEXT_MARKUP_TYPE.PLAIN,
};

export default TextMarkup;
