import * as api from '@yandex-int/k-common/client/api';
import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import { FullCLesson, Me } from '@yandex-int/k-common/typings';
import { getMe } from 'platform-components/src/components/user/selectors';
import { IntlShape } from 'react-intl';
import { call, put, select, spawn, take } from 'redux-saga/effects';

import { handleDeleteOrEditError, mergeClesson } from 'common.components/lesson/sagas/utils';
import * as lessonSelectors from 'common.components/lesson/selectors';
import { DEFAULT_LESSON_MARKUP } from 'common.components/lesson/typings';
import { getLessonDefaultName } from 'common.components/lesson/utils';
import * as courseSelectors from 'store/courses/selectors';
import { getTeamIdFromPath, getSubjectFromPath } from 'utils/path';

import { actions as lessonActions } from '../index';

export function* saveOrCreateLessonSaga(action: ReturnType<typeof lessonActions.saveOrCreateLesson>) {
    try {
        const { onSuccess } = action.payload;
        const isNewLesson: boolean = yield select(lessonSelectors.getIsNew);
        const createLessonStatus: LOADING_STATUSES = yield select(lessonSelectors.getCreateLessonStatus);

        if (isNewLesson) {
            if (createLessonStatus === LOADING_STATUSES.LOADING) {
                yield take(lessonActions.createLessonSuccess);
                yield put(lessonActions.saveLesson({ onSuccess }));
            } else {
                yield spawn(createLesson, action.payload);
                return;
            }
        } else {
            yield put(lessonActions.saveLesson({ onSuccess }));
        }
    } catch (error) {
        console.error(error);

        const clesson: FullCLesson = yield select(lessonSelectors.getLesson);
        yield handleDeleteOrEditError(error, clesson.clesson?.id!);
    }
}

interface CreateLessonParams {
    intl: IntlShape;
    onSuccess: (clessonId: number, clessonData: FullCLesson) => void;
    lessonName?: string;
    lessonMeta?: string;
}

function* createLesson(params: CreateLessonParams) {
    try {
        yield put(lessonActions.createLessonRequest());

        const { intl, onSuccess, lessonName, lessonMeta } = params;
        const defaultName = lessonName || getLessonDefaultName({ intl, isEvaluable: false });
        const teamId = getTeamIdFromPath();
        const subject = getSubjectFromPath();
        const courseId: number = yield select(courseSelectors.getDefaultCourseId, { classId: teamId, subject });
        const me: Me = yield select(getMe);
        const lesson: FullCLesson | null = yield select(lessonSelectors.getLesson);

        const createdLesson: api.PostCreateLessonRequestData & { clesson: unknown } = {
            ...DEFAULT_LESSON_MARKUP,
            ...lesson,
            owner: me ? me.id : null!,
            name: defaultName,
            clesson: {
                ...lesson!.clesson,
                course: { id: courseId }, // объект тут иммутабелен
            },
            meta: (lessonMeta || undefined) as any, // TODO: undefined?
        };

        const lessonData: FullCLesson = yield call(api.postCreateClesson, createdLesson);
        const cLessonId: number = lessonData.clesson?.id!;

        yield call(mergeClesson, lessonData);
        yield put(lessonActions.createLessonSuccess());
        yield put(lessonActions.getLessonAssigns({ cLessonId, courseId }));
        yield put(lessonActions.lessonUpdated());
        onSuccess(cLessonId, lessonData);
    } catch (e) {
        yield put(lessonActions.createLessonError());
        console.error(e);
    }
}
