import { postFetch } from '../fetch';

interface Response {
    promocodes: Record<string, { is_used: boolean }>;
    type: 'promocodes';
}

export function postVerifyPromocodes(
    promocodes: string[],
    requestType: 'check' | 'activate'
): Promise<[Response] | []> {
    return postFetch('/api/v5/yagopromo', [
        {
            type: requestType === 'check' ? 'check_promocode_request' : 'activate_promocode_request',
            code_list: promocodes,
        },
    ]);
}
