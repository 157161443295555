import { postVerifyPromocodes } from '@yandex-int/k-common/client/api/defs/post-verify-promocodes';
import b_ from 'b_';
import {
    Button,
    Input,
    H1,
    Text,
    INPUT_SIZES,
    INPUT_THEMES,
    UI_KIT_TEXT_TYPOGRAPHY,
    useIntl,
    Checkbox,
    CHECKBOX_TYPES,
    H2,
    UI_KIT_HEADER_TYPOGRAPHY,
} from 'platform-components';
import { ExternalLink } from 'platform-components/src/components/external-link/external-link';
import { useLocalStorage } from 'platform-components/src/utils/hooks/useLocalStorage';
import React, { useState } from 'react';

import { withUserLogin } from '../../hocs/withUserLogin';

import './promocode-page.scss';

const b = b_.with('promocode-page');

export const PromocodePageComponent = () => {
    const { set: setInLocalStorage } = useLocalStorage();
    const [promocodeValue, setPromocodeValue] = useState('');
    const [requestInProgress, setRequestInProgress] = useState(false);
    const [errorTextId, setErrorTextId] = useState<string | null>(null);
    const [personalAgreementIsChecked, setPersonalAgreementIsChecked] = useState(false);

    const intl = useIntl();

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPromocodeValue(event?.target?.value.toUpperCase());
        setErrorTextId(null);
    };

    const handleButtonClick = async () => {
        if (errorTextId) {
            return;
        }

        setRequestInProgress(true);

        try {
            const promocodeToVerify = promocodeValue.trim();

            const response = await postVerifyPromocodes([promocodeToVerify], 'check');

            if (!response.length) {
                setErrorTextId('promocodePage.error.type.common');
                setRequestInProgress(false);
                return;
            }

            const { promocodes } = response[0];

            if (!promocodes.hasOwnProperty(promocodeToVerify)) {
                setErrorTextId('promocodePage.error.type.invalid');
                setRequestInProgress(false);
                return;
            }

            if (promocodes[promocodeToVerify].is_used) {
                setErrorTextId('promocodePage.error.type.used');
                setRequestInProgress(false);
                return;
            }

            setInLocalStorage('promocode', promocodeToVerify);

            window.open(
                intl.formatMessage({
                    id: 'promocodePage.redirectLink',
                }),
                '_self'
            );

            setPromocodeValue('');
            setRequestInProgress(false);
        } catch (error) {
            setErrorTextId('promocodePage.error.type.common');
            setRequestInProgress(false);
        }
    };

    const handleInputFocus = () => {
        setErrorTextId(null);
    };

    return (
        <>
            <div className={b('container')}>
                <div className={b('price')}>
                    <H2 id="promocodePage.price" typography={UI_KIT_HEADER_TYPOGRAPHY.headlineXSRegular} />
                    <H2
                        id="promocodePage.newPrice"
                        mix={b('new-price')}
                        typography={UI_KIT_HEADER_TYPOGRAPHY.headlineXSMedium}
                    />
                    <H2
                        id="promocodePage.oldPrice"
                        mix={b('old-price')}
                        typography={UI_KIT_HEADER_TYPOGRAPHY.headlineXSRegular}
                    />
                </div>

                <H1 id="promocodePage.title" mix={b('title')} />

                <div className={b('input-wrapper')}>
                    <Input
                        mix={b('input')}
                        onChange={handleInputChange}
                        onFocus={handleInputFocus}
                        size={INPUT_SIZES.XL}
                        theme={INPUT_THEMES.YANDEX}
                        value={promocodeValue}
                    />
                    {errorTextId && (
                        <Text
                            className={b('error-text')}
                            id={errorTextId}
                            typography={UI_KIT_TEXT_TYPOGRAPHY.paragraphSRegular}
                        />
                    )}
                </div>

                <Button
                    className={b('button')}
                    disabled={!promocodeValue || !personalAgreementIsChecked}
                    onClick={handleButtonClick}
                    progress={requestInProgress}
                    view={Button.VIEW.ACTION}
                >
                    <Text id="promocodePage.buttonText" />
                </Button>

                <Text
                    className={b('button-description')}
                    id="promocodePage.buttonDescription"
                    typography={UI_KIT_TEXT_TYPOGRAPHY.paragraphSRegular}
                />

                <Checkbox
                    checked={personalAgreementIsChecked}
                    mix={b('checkbox-wrapper')}
                    onChange={() => {
                        setPersonalAgreementIsChecked(!personalAgreementIsChecked);
                    }}
                    type={CHECKBOX_TYPES.BLUE}
                >
                    <Text id="promocodePage.agreement" />
                    <ExternalLink linkId="promocodePage.offer.link" textId="promocodePage.offer.linkText" />
                    <Text id="promocodePage.agreement.amp" />
                    <ExternalLink linkId="promocodePage.termsOfUse.link" textId="promocodePage.termsOfUse.linkText" />
                </Checkbox>
            </div>
        </>
    );
};

export const PromocodePage = withUserLogin(PromocodePageComponent);
