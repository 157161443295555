import b from 'b_';
import cx from 'classnames';
import { Input, Text } from 'platform-components';
import PropTypes from 'prop-types';
import React from 'react';

import { FORM_VIEW_INPUT_THEMES } from '../form-view.constants';

import './form-view__input.scss';

const FormViewInput = (props) => {
    const { descriptionId, helpMessage, labelId, mix, onMouseEnter, onMouseLeave, theme, valid, ...inputProps } = props;

    const textSize = theme === FORM_VIEW_INPUT_THEMES.OUTLINED ? Text.SIZE.L : Text.SIZE.S;

    return (
        <div
            className={cx(b('form-view', 'input', { theme, valid }), mix)}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            {labelId && (
                <div className="form-view__input-label">
                    <Text id={labelId} />
                </div>
            )}
            {valid === 'no' && (
                <div className="form-view__input-help">
                    <Text size={textSize}>{helpMessage}</Text>
                </div>
            )}
            <Input valid={valid} {...inputProps} />
            {valid !== 'no' && descriptionId && (
                <div className="form-view__hint">
                    <Text id={descriptionId} size={textSize} />
                </div>
            )}
        </div>
    );
};

FormViewInput.propTypes = {
    descriptionId: PropTypes.string,
    helpMessage: PropTypes.string,
    labelId: PropTypes.string,
    mix: PropTypes.string,
    onChange: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    theme: PropTypes.oneOf(Object.values(FORM_VIEW_INPUT_THEMES)),
    valid: PropTypes.string,
};

FormViewInput.defaultProps = {
    theme: FORM_VIEW_INPUT_THEMES.DEFAULT,
};

FormViewInput.THEME = FORM_VIEW_INPUT_THEMES;

export default FormViewInput;
