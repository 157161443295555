import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
// @ts-ignore
import { CLESSON_MODES, EVALUABLE_CLESSON_MODES, SUBJECTS_SLUGS } from '@yandex-int/k-common/constants';
import {
    CertificateReward,
    ClessonResults,
    ClessonRewards,
    CourseSubscription,
    FullCLesson,
    LESSON_STATS_MODE,
    LESSON_STATUS,
    LESSON_STYLE,
    LESSON_TYPE,
    LessonBanner,
    LessonMeta,
    LessonSubscription,
    REWARD,
    ExternalHomework,
    EXTERNAL_LMS_SLUGS,
    LessonProblem,
    LessonViewSettings,
    CustomLessonViewTag,
    SUBSCRIPTION_SETTING,
    StudentResults,
    SkillsLessonStructureCardStacks,
} from '@yandex-int/k-common/typings';
import { isSkillsLessonStructure } from '@yandex-int/k-common/utils/client/lesson/skills-lesson/skills-lesson';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { createCachedSelector } from 're-reselect';
import { IntlShape } from 'react-intl';
import { createSelector } from 'reselect';

import { getCurrentYearCourseId } from 'common.components/course/selectors';
import { getLessonDefaultName, isLessonCompleted, isLessonInProgress } from 'common.components/lesson/utils';
import { CARD_BADGES } from 'common.components/stack/constants';
import {
    getIsInRNOExperiment,
    getIsInCustomLessonExperiment,
    getHasNoActiveTeams,
} from 'common.components/user/selectors';
import { getCoursesByClass, getPrevYearCourses } from 'store/courses/selectors';
import * as problemSelectors from 'store/problems/selectors';
import { LessonInfo } from 'store/problems/typings';
import { isOpenAnswer, isTheory } from 'store/problems/utils';
import { getInitialSubject } from 'store/subject-config/selectors';
import * as subscriptionSelectors from 'store/subscriptions/selectors';
import { getIsTeacherFromMes } from 'utils/external-lms/external-lms';
import { getLessonViewSettings } from 'utils/lesson/permissions/get-permissions';
import * as permissonUtils from 'utils/lesson/permissions/get-permissions';
import {
    getSearchFromProps,
    getSlugFromProps,
    getSubjectFromProps,
    intlSelector,
    nowSelector,
} from 'utils/selectors/selectors';
import { getMe } from 'platform-components/src/components/user/selectors';
import { getDiagnosticsLessonsInfo } from 'platform-components/src/components/app-factory/selectors';

import { LessonStore } from '../typings';
import { getLatestLessonCertificateBySlug } from '../utils';

import { filterLoadedLessonProblems } from './utils';

const storeStateSelector = (storeState: any): LessonStore => storeState.lesson;

interface PropsWithCertificateSlug {
    certificateSlug?: string;
}

export interface PropsWithLessonsId {
    lessonsIds: Array<number>;
}

export const getCertificateSlugFromProps = <T extends PropsWithCertificateSlug>(
    storeState: any,
    ownProps: T
): string | null => ownProps.certificateSlug || null;

export const getClessonResults = createSelector(
    storeStateSelector,
    (state): ClessonResults | null => state.clessonResults
);

export const getLessonUpdateInfo = createSelector(storeStateSelector, (state) => state.lessonUpdated);

export const getLesson = createSelector(storeStateSelector, (state): FullCLesson | null => state.lesson);

export const shouldShowDiagnosticsResults = createSelector(
    [getLesson, getMe, getDiagnosticsLessonsInfo],
    (lesson, me, diagnosticsLessons) => Boolean(me?.is_teacher && diagnosticsLessons?.[lesson?.id!!])
);

export const getLessonDiagnosticsInfo = createSelector(
    [getLesson, getDiagnosticsLessonsInfo],
    (lesson, diagnosticsLessons) => diagnosticsLessons?.[lesson?.id!!]
);

export const getLessonSubscriptions = createSelector(
    storeStateSelector,
    (state): Array<LessonSubscription> | null => state.subscriptions
);

export const getLessonStudentsResults = createSelector(
    [getClessonResults],
    (results): Array<StudentResults> => Object.values(results?.students || [])
);

export const getLessonParticipants = createSelector(getClessonResults, (results) => {
    if (!results) {
        return [];
    }

    const participantIds = Object.entries(results?.students).reduce<string[]>((participants, [id, studentResult]) => {
        if (studentResult.time > 0) {
            participants.push(id);
        }
        return participants;
    }, []);

    return participantIds;
});

export const getLessonParticipantsResults = createSelector(
    [getLessonStudentsResults],
    (students): Array<StudentResults> => students.filter((studentResult) => studentResult.time > 0)
);

export const getLessonLongSubscriptions = createSelector(
    [getLessonSubscriptions],
    (subscriptions): Array<LessonSubscription> | null =>
        (subscriptions || []).filter((sub) => sub.subscription.setting === SUBSCRIPTION_SETTING.LONG)
);

export const getLessonShortSubscriptions = createSelector(
    [getLessonSubscriptions],
    (subscriptions): Array<LessonSubscription> | null =>
        (subscriptions || []).filter((sub) => sub.subscription.setting === SUBSCRIPTION_SETTING.SHORT)
);

export const getLessonCourseId = createSelector([getLesson], (lesson): number | null => {
    return lesson?.clesson?.course.id || null;
});

export const getLessonSubject = createSelector([getLesson, getInitialSubject], (lesson, initialSubject): string => {
    return lesson?.subject || initialSubject;
});

export const getIsLessonCourseClosed = createSelector(
    [getLessonCourseId, getPrevYearCourses],
    (lessonCourseId, prevYearCourses): boolean =>
        Boolean(lessonCourseId) && prevYearCourses.some((course) => course.id === lessonCourseId)
);

export const getLessonOrCurrentYearCourseId = createSelector(
    [getLessonCourseId, getCurrentYearCourseId],
    (lessonCourseId: number | null, currentYearCourseId: number | null): number | null =>
        lessonCourseId || currentYearCourseId
);

export const getLessonCourseExternalLmsSlug = createSelector(
    [getLessonOrCurrentYearCourseId, getCoursesByClass],
    (lessonCourseId, courses): EXTERNAL_LMS_SLUGS | null => {
        if (!lessonCourseId) {
            return null;
        }

        const lessonCourse = courses.find((course) => course.id === lessonCourseId);

        return (lessonCourse?.external_course?.lms as EXTERNAL_LMS_SLUGS) || null;
    }
);

export const getCourseExternalLmsSubjectId = createSelector(
    [getLessonOrCurrentYearCourseId, getCoursesByClass],
    (lessonCourseId, courses): string | null => {
        if (!lessonCourseId) {
            return null;
        }

        const lessonCourse = courses.find((course) => course.id === lessonCourseId);

        return (lessonCourse?.external_course?.external_subject as Record<string, string>)?.external_subject_id || null;
    }
);

// сейчас отличаем свое занятие от готового по наличию курса - свои занятия получаем как cLesson
// и поэтому курс там есть, готовые - не привязаны ни к какому курсу
export const getIsPreparedLesson = createSelector([getLessonCourseId], (courseId): boolean => !courseId);

export const getLessonMeta = createSelector([getLesson], (lesson): LessonMeta | null => lesson?.meta || null);

export const getIsDurationEditable = createSelector([getLessonMeta], (lessonMeta): boolean =>
    Boolean(lessonMeta?.is_duration_editable)
);

export const getCanEditAssignmentDates = createSelector([getLessonMeta], (lessonMeta): boolean => {
    if (!lessonMeta) {
        return true;
    }

    return permissonUtils.getAreDatesEditable(lessonMeta);
});

export const getCanEditLessonMode = createSelector([getLessonMeta], (lessonMeta): boolean => {
    if (!lessonMeta) {
        return true;
    }

    return permissonUtils.getIsLessonModeEditable(lessonMeta);
});

export const getIsLessonContentEditable = createSelector([getLessonMeta], (lessonMeta): boolean => {
    if (!lessonMeta) {
        return true;
    }

    return permissonUtils.getIsLessonContentEditable(lessonMeta);
});

export const getIsLessonModeReadonly = createSelector([getLessonMeta], (lessonMeta): boolean => {
    if (!lessonMeta) {
        return false;
    }

    return permissonUtils.getIsModeReadonly(lessonMeta);
});

export const getCanAssignIndividually = createSelector([getLessonMeta], (lessonMeta): boolean => {
    if (!lessonMeta) {
        return true;
    }

    return permissonUtils.getIsIndividualAssignmentAllowed(lessonMeta);
});

export const getHasAssignmentDataInMeta = createSelector([getLesson], (lesson: FullCLesson | null): boolean => {
    const meta = lesson?.meta || ({} as LessonMeta);

    return Boolean(
        meta.mode || meta.accessible_to_teacher || meta.date_deadline || meta.evaluation_date || meta.duration
    );
});

export const getHasLessonModeInMeta = createSelector([getLesson], (lesson): boolean => {
    return !isNil(lesson?.meta?.mode);
});

export const getLessonMode = createSelector(
    [getIsPreparedLesson, getLesson],
    (isPreparedLesson, lesson): CLESSON_MODES | null => {
        if (isPreparedLesson) {
            return lesson?.meta?.mode || null;
        }

        return lesson?.clesson?.mode || null;
    }
);

export const getIsLessonEvaluable = createSelector(getLessonMode, (mode): boolean =>
    EVALUABLE_CLESSON_MODES.includes(mode)
);

export const getLessonType = createSelector([getLesson], (lesson): LESSON_TYPE | null => {
    return lesson?.meta?.lesson_type || null;
});

export const getLessonStatsMode = createSelector(
    [getLesson],
    (lesson: FullCLesson): LESSON_STATS_MODE | null => lesson?.meta?.stats_mode || null
);

export const getLessonEvaluationDate = createSelector([getLesson], (lesson): Date | null => {
    const evaluationDate = lesson?.clesson?.evaluation_date;

    return evaluationDate ? new Date(evaluationDate) : null;
});

export const getLessonRewards = createSelector(
    [getLesson],
    (lesson): ClessonRewards | null => lesson?.clesson?.rewards || null
);

export const getLessonCertificates = createSelector(
    [getLessonRewards],
    (rewards): Array<CertificateReward> => rewards?.[REWARD.CERTIFICATE] || []
);

export const getLessonCertificateBySlug = createCachedSelector(
    [getLessonCertificates, getCertificateSlugFromProps],
    (certificates, slug): CertificateReward | null => {
        return getLatestLessonCertificateBySlug(certificates, slug);
    }
)((state, props: PropsWithCertificateSlug) => {
    return props.certificateSlug;
});

export const getIsStatisticsAvailable = createSelector(
    [getIsLessonEvaluable, getLessonEvaluationDate, nowSelector],
    (isLessonEvaluable, evaluationDate, now): boolean => {
        if (!isLessonEvaluable) {
            return true;
        }

        return Boolean(evaluationDate && now && evaluationDate <= now);
    }
);

export const getCertificateTemplateId = createSelector([getLesson], (lesson): string | null => {
    return lesson?.meta?.certificate_template_id || null;
});

export const getGameCertificateSubject = createSelector([getLesson], (lesson): string | null => {
    return lesson?.meta?.cert_subject || null;
});

export const getCanCertificateBeIssuedForLesson = createSelector(
    [getCertificateTemplateId],
    (certificateId): boolean => !isNil(certificateId)
);

export const getIsRepeatableLesson = createSelector([getLessonMeta], (lessonMeta): boolean => {
    if (!lessonMeta) {
        return true;
    }

    return permissonUtils.getIsRepeatable(lessonMeta) !== false;
});

export const getShouldGetAssignmentDatesFromMeta = createSelector(
    [getCanEditAssignmentDates, getIsPreparedLesson, getHasAssignmentDataInMeta],
    (canEditAssignmentDates, isPreparedLesson, hasAssignmentDataInMeta) => {
        return !canEditAssignmentDates || (isPreparedLesson && hasAssignmentDataInMeta);
    }
);

export const getShouldGetLessonModeFromMeta = createSelector(
    [getCanEditLessonMode, getIsPreparedLesson, getHasLessonModeInMeta],
    (canEditAssignmentDates, isPreparedLesson, hasLessonModeInMeta) => {
        return !getCanEditLessonMode || (isPreparedLesson && hasLessonModeInMeta);
    }
);

export const getLessonLoadingStatus = createSelector(storeStateSelector, (state) => state.lessonLoadingStatus);

export const getLessonId = createSelector(getLesson, (lesson) => lesson?.id || null);

export const getCLessonId = createSelector(
    getLesson,
    (lesson: FullCLesson | null): number | null => lesson?.clesson?.id || null
);

export const getIsNew = createSelector(getLesson, (lesson): boolean => !lesson?.id);

export const getLessonAssignmentDate = createSelector(getLesson, (lesson) => {
    return lesson?.clesson?.date_assignment || null;
});

export const getIsDraft = createSelector(
    [getLessonAssignmentDate, getIsPreparedLesson],
    (assignmentDate, isPreparedLesson) => !assignmentDate && !isPreparedLesson
);

export const getHasActiveDraft = createSelector([getIsNew, getIsDraft], (isNew, isDraft) => isDraft && !isNew);

export const getName = createSelector(getLesson, (lesson) => lesson?.name || null);

export const getDescription = createSelector(
    getLesson,
    (lesson: FullCLesson | null): string => lesson?.description || ''
);

export const getLessonAssignmentLoadingStatus = createSelector(
    storeStateSelector,
    (state) => state.lessonAssignsLoadingStatus
);

export const getLessonProblems = createSelector(getLesson, (lesson) => lesson?.problems || []);

export const getFirstLessonProblemId = createSelector([getLessonProblems], (problems): number | null => {
    if (problems.length === 0) {
        return null;
    }
    return problems[0].problem?.id || null;
});

export const getLessonStatus = createSelector(
    getLesson,
    (lesson: FullCLesson | null): LESSON_STATUS | null => lesson?.clesson?.status || null
);

export const getAssignmentTable = createSelector(storeStateSelector, (state) => state.assignmentTable);

export const getCardBadges = createSelector(
    [problemSelectors.getProblemsInCourse, getLessonProblems],
    (problemsInCourse: Record<number, LessonInfo[]>, problemBlocksInLesson) => {
        const problemIdsInLesson: Array<number> = problemBlocksInLesson
            .filter((block) => block?.problem?.id)
            .map((block) => block.problem!.id);
        const problemIdsInCourse: Array<string> = Object.keys(problemsInCourse);

        const problemsWithBadges = {};

        problemIdsInCourse.forEach((id) => {
            // @ts-ignore
            problemsWithBadges[id] = CARD_BADGES.USED_IN_COURSE;
        });

        // использование в уроке "перекрывает" использование в курсе
        problemIdsInLesson.forEach((id) => {
            // @ts-ignore
            problemsWithBadges[id] = CARD_BADGES.USED_IN_LESSON;
        });

        return problemsWithBadges;
    }
);

export const getLessonProblemIds = createSelector([getLessonProblems], (problemBlocksInLesson) => {
    const problemIdsInLesson: Array<number> = problemBlocksInLesson.map(
        (block) => block.problem!.id || block.theory?.id || block.id
    );

    return problemIdsInLesson;
});

export const getLessonDeadlineDate = createSelector(getLesson, (lesson) => {
    return lesson?.clesson?.date_deadline || null;
});

export const getLessonCompletedDate = createSelector(getLesson, (lesson) => {
    return lesson?.clesson?.date_completed || null;
});

export const getIsStream = createSelector([getLesson], (lesson): boolean => {
    return Boolean(lesson?.clesson?.stream);
});

export const getAccessibleToTeacher = createSelector([getLessonMeta], (lessonMeta): string | null => {
    if (!lessonMeta) {
        return null;
    }

    return permissonUtils.getAccessibleToTeacher(lessonMeta);
});

export const getExternalHomework = createSelector(
    [getLesson],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (cLesson: FullCLesson | null): ExternalHomework | null => {
        return cLesson?.clesson?.external_homework || null;
    }
);

export const getCanCopyLesson = createSelector(
    [getLessonCourseId, getLessonMeta, getIsStream, getSearchFromProps],
    (courseId, lessonMeta, isStream, search) => {
        const isTeacherFromMes = getIsTeacherFromMes(search);
        if (!lessonMeta) {
            return true;
        }

        const isCourseLesson = Boolean(courseId);
        const isCopiable = permissonUtils.getIsCopiable(lessonMeta);

        return permissonUtils.getCanCopyOwnLesson(isCopiable, isCourseLesson, isStream, isTeacherFromMes);
    }
);

export const getCanAddLessonToCourse = createSelector(
    [getIsPreparedLesson, getIsLessonContentEditable],
    (isPreparedLesson, canEditLessonContent) => {
        return isPreparedLesson && canEditLessonContent;
    }
);

export const getCanDeleteLesson = createSelector(
    [getLessonCourseId, getLessonStatus],
    (courseId, lessonStatus: LESSON_STATUS) => {
        return !isLessonCompleted(lessonStatus) && !isLessonInProgress(lessonStatus) && Boolean(courseId);
    }
);

export const getCanEditLesson = createSelector(
    [getHasNoActiveTeams, getLessonStatus, getIsLessonContentEditable],
    (hasNoTeams, lessonStatus, isLessonContentEditable): boolean => {
        if (hasNoTeams) {
            return false;
        }

        if (!lessonStatus) {
            return isLessonContentEditable;
        }

        return isLessonContentEditable && !isLessonCompleted(lessonStatus) && !isLessonInProgress(lessonStatus);
    }
);

export const getIsLessonNamedByDefault = createSelector(
    [intlSelector, getName, getIsLessonEvaluable],
    (intl: IntlShape, lessonName: string, isEvaluable: boolean): boolean => {
        const defaultName = getLessonDefaultName({ intl, isEvaluable });

        return lessonName === defaultName;
    }
);

export const getAddLessonToCourseStatus = createSelector(storeStateSelector, (state) => state.addLessonToCourseStatus);

export const getLessonModuleId = createSelector(
    [storeStateSelector],
    (state: LessonStore): number | null => state.fromModuleId
);

export const getLessonStyle = createSelector(
    [getLesson],
    (lesson): LESSON_STYLE => lesson?.meta?.style || LESSON_STYLE.DEFAULT
);

export const getLessonBanner = createSelector([getLesson], (lesson): LessonBanner => lesson?.meta?.banners_list || {});

export const getLessonSlug = createSelector([getLessonMeta], (lessonMeta): string => lessonMeta?.slug || '');

export const getShouldShowBanner = createSelector([getLessonBanner], (lessonBanner: LessonBanner): boolean =>
    Boolean(lessonBanner.background_color || lessonBanner.background_img)
);

export const getIsLessonEvaluated = createSelector(
    [getLessonStatus, getIsLessonEvaluable],
    (status: LESSON_STATUS | null, isEvaluable: boolean): boolean => {
        if (!status) {
            return false;
        }

        const isCompleted = isLessonCompleted(status);
        const isInProgress = isLessonInProgress(status);

        if (isEvaluable) {
            return isCompleted && status !== LESSON_STATUS.WAIT_FOR_RESULTS;
        }

        return isCompleted || isInProgress;
    }
);

export const getIsAccessible = createSelector(
    [getAccessibleToTeacher, nowSelector],
    (accessibleToTeacher, now): boolean => {
        if (!accessibleToTeacher) {
            return true;
        }

        return permissonUtils.getIsAccessibleLesson(new Date(accessibleToTeacher), now);
    }
);

export const getCanShowProblemsContent = createSelector(
    [getLessonMeta, getIsLessonEvaluated],
    (lessonMeta, isEvaluated): boolean => {
        if (!lessonMeta) {
            return true;
        }

        return permissonUtils.getCanViewProblemContent(lessonMeta, isEvaluated);
    }
);

export const getIsForceCompleteAllowed = createSelector([getLessonMeta], (lessonMeta): boolean => {
    if (!lessonMeta) {
        return true;
    }

    return permissonUtils.getIsForceCompleteAllowed(lessonMeta);
});

export const getIsLessonScoreAvailable = createSelector(
    [getIsLessonEvaluable, getIsLessonEvaluated],
    (isEvaluable: boolean, isEvaluated: boolean): boolean => !isEvaluable || isEvaluated
);

export const getIsPointsMode = createSelector(
    [getLessonStatsMode],
    (statsMode: LESSON_STATS_MODE): boolean => statsMode === LESSON_STATS_MODE.POINTS
);

export const getIsDefaultMode = createSelector(
    [getLessonStatsMode],
    (statsMode: LESSON_STATS_MODE): boolean => statsMode === LESSON_STATS_MODE.DEFAULT
);

export const getShouldDisplayPoints = createSelector(
    [getIsPointsMode, getIsLessonScoreAvailable],
    (isPointsMode: boolean, isLessonScoreAvailable) => isPointsMode && isLessonScoreAvailable
);

export const getShouldDisplayAttempts = createSelector(
    [getIsDefaultMode, getIsLessonEvaluable],
    (isDefaultMode: boolean, isEvaluable: boolean) => isDefaultMode && !isEvaluable
);

export const getCreateLessonStatus = createSelector(
    [storeStateSelector],
    (state: LessonStore): LOADING_STATUSES => state.createLessonStatus
);

export const getEvaluableProblemsNumber = createSelector(
    [getLessonProblems],
    (lessonProblems: Array<LessonProblem>) => {
        return lessonProblems.filter((pl) => pl.problem && !isTheory(pl.problem) && !isOpenAnswer(pl.problem)).length;
    }
);

export const getLessonSource = createSelector(
    [storeStateSelector],
    (state: LessonStore): string | null => state.lessonSource
);

export const getLessonFeedbackUrl = createSelector([getLesson, intlSelector], (lesson, intl): string | undefined => {
    const commonFeedbackUrl = intl.formatMessage({ id: 'lesson.informationBanner.feedback.formUrl' });
    const feedbackFromMeta = lesson?.meta?.feedback?.teacher?.url;

    return feedbackFromMeta ? feedbackFromMeta : commonFeedbackUrl;
});

export const getLessonFeedbackDisabled = createSelector(
    [getLesson],
    (lesson): boolean | undefined => lesson?.meta?.feedback?.teacher?.disabled
);

export const getShouldShowFeedbackBanner = createSelector(
    [getLessonStatus, getLessonFeedbackDisabled, getLessonFeedbackUrl],
    (lessonStatus, feedbackDisabled, feedbackUrl) => {
        const isCompleted = lessonStatus && isLessonCompleted(lessonStatus);

        return isCompleted && !feedbackDisabled && Boolean(feedbackUrl);
    }
);

export const getCustomLessonViewSettings = createSelector([getLessonMeta], (lessonMeta): LessonViewSettings | null => {
    if (!lessonMeta) {
        return null;
    }

    return getLessonViewSettings(lessonMeta);
});

export const getCustomLessonViewDescription = createSelector(
    [getCustomLessonViewSettings],
    (lessonViewSettings): string | null => lessonViewSettings?.desc || null
);

export const getCustomLessonViewImageSrc = createSelector(
    [getCustomLessonViewSettings],
    (lessonViewSettings): string | null => lessonViewSettings?.image_url || null
);

export const getCustomLessonViewLandingSrc = createSelector(
    [getCustomLessonViewSettings],
    (lessonViewSettings): string | null => lessonViewSettings?.landing_url || null
);

export const getCustomLessonViewTags = createSelector(
    [getCustomLessonViewSettings],
    (lessonViewSettings): Array<CustomLessonViewTag> => lessonViewSettings?.tags || []
);

export const getLessonResultShowSeenCountColumnStats = createSelector(
    [getCustomLessonViewSettings],
    (lessonViewSettings): boolean => {
        const value = lessonViewSettings?.show_seen_count_column_stats;

        return isNil(value) ? true : value;
    }
);

export const getLessonResultShowTimeColumnStats = createSelector(
    [getCustomLessonViewSettings],
    (lessonViewSettings): boolean => {
        const value = lessonViewSettings?.show_time_column_stats;

        return isNil(value) ? true : value;
    }
);

export const getIsClessonMetaHasStickers = createSelector([getLessonMeta], (meta): boolean => {
    return Boolean(meta && meta.stickers_slugs && meta.stickers_slugs.length > 0);
});

// так как эксперимент обратный, то все кто в него попал как раз не должны видеть модалку с хгз
export const getShouldShowCustomLessonViewInfoIcon = createSelector(
    [getCustomLessonViewSettings, getIsInCustomLessonExperiment],
    (lessonViewSettings, isInCustomLessonExperiment): boolean =>
        Boolean(lessonViewSettings?.show_info_icon) && !isInCustomLessonExperiment
);

export const getSubscriptionBySlug = createSelector([getLessonSubscriptions, getSlugFromProps], (subscriptions, slug) =>
    (subscriptions || []).find((subscription: LessonSubscription) => subscription.subscription.slug === slug)
);

export const getLessonActiveSubscriptionSlug = createSelector(
    [subscriptionSelectors.getCourseActiveSubscriptions, getLessonLongSubscriptions],
    (activeSubscriptions, lessonSubscriptions) => {
        if (!lessonSubscriptions) {
            return null;
        }

        const activeSubscriptionsSlugs = activeSubscriptions.map(
            (subscription: CourseSubscription) => subscription.subscription.slug
        );

        const lessonSubscriptionsSlugs = lessonSubscriptions.map(
            (subscription: LessonSubscription) => subscription.subscription.slug
        );
        return (
            lessonSubscriptionsSlugs.find((subscriptionSlug: string) =>
                activeSubscriptionsSlugs.includes(subscriptionSlug)
            ) || null
        );
    }
);

export const getLessonInactiveSubscriptionSlug = createSelector(
    [subscriptionSelectors.getCourseInactiveSubscriptions, getLessonActiveSubscriptionSlug, getLessonLongSubscriptions],
    (inactiveSubscriptions, activeSubscriptionSlug, lessonSubscriptions) => {
        if (!lessonSubscriptions) {
            return null;
        }

        const inactiveSubscriptionsSlugs = inactiveSubscriptions.map(
            (subscription: CourseSubscription) => subscription.subscription.slug
        );

        const lessonSubscriptionsSlugs = lessonSubscriptions.map(
            (subscription: LessonSubscription) => subscription.subscription.slug
        );
        const inactiveSubscription =
            lessonSubscriptionsSlugs.find((subscriptionSlug: string) =>
                inactiveSubscriptionsSlugs.includes(subscriptionSlug)
            ) || null;

        // возвращаем неактивную подписку, если она есть
        if (inactiveSubscription) {
            return inactiveSubscription;
        }

        // возвращаем первую подписку, если она неактивна
        return activeSubscriptionSlug ? null : lessonSubscriptionsSlugs[0];
    }
);

export const getCanLessonBeCopiedAsRNO = createSelector(
    [getCanCopyLesson, getIsLessonContentEditable],
    (canCopyLesson, isLessonContentEditable) => canCopyLesson && isLessonContentEditable
);

export const getShouldShowRNOBanner = createSelector(
    [getLessonStatus, getCanLessonBeCopiedAsRNO, getIsInRNOExperiment, getIsLessonCourseClosed, getSubjectFromProps],
    // eslint-disable-next-line max-params
    (lessonStatus, canLessonBeCopiedAsRNO, isInRNOExperiment, isLessonCourseClosed, subject) => {
        if (subject === SUBJECTS_SLUGS.informatics) {
            return false;
        }

        const isLessonCompletedOrInProgress =
            lessonStatus && (isLessonCompleted(lessonStatus) || isLessonInProgress(lessonStatus));

        return (
            !isInRNOExperiment && // эксперимент задуман как обратный - экспериментальная группа НЕ видит баннер
            isLessonCompletedOrInProgress &&
            !isLessonCourseClosed &&
            canLessonBeCopiedAsRNO
        );
    }
);

export const getCanLessonResultBeChanged = createSelector([getLessonStatus], (status): boolean => {
    return status === LESSON_STATUS.ASSIGNED || status === LESSON_STATUS.IN_PROGRESS;
});

export const getIsLessonAssigned = createSelector(
    [getLessonStatus],
    (status): boolean => status === LESSON_STATUS.ASSIGNED
);

export const getIsCustomFlowLesson = createSelector([getLesson], (lesson) => Boolean(lesson?.structure));

export const getMainStacksFromLessonStructure = createSelector([getLesson], (lesson) => {
    const mainStacks: SkillsLessonStructureCardStacks = [];
    const lessonStructure = lesson?.structure;
    if (lessonStructure && isSkillsLessonStructure(lessonStructure)) {
        if (!lessonStructure.items) {
            return mainStacks;
        }
        return lessonStructure.items.reduce<SkillsLessonStructureCardStacks>(
            (stacks, item) => [...stacks, ...item.main],
            mainStacks
        );
    }

    return mainStacks;
});

// первые карточки стопок из поля main занятия по навыкам (для отображения в статистике)
const getFirstProblemsFromMainStacks = createSelector(
    [getMainStacksFromLessonStructure],
    (mainStacks) => mainStacks?.map((stack) => stack[0])
);

export const getCustomFlowLessonProblems = createSelector(
    [getLessonProblems, getFirstProblemsFromMainStacks],
    (lessonProblems, firstProblemsFromMainStacks) => {
        if (!isEmpty(firstProblemsFromMainStacks)) {
            return lessonProblems.filter(({ problem }) => problem && firstProblemsFromMainStacks.includes(problem.id));
        }
        return lessonProblems;
    }
);

// todo: пересмотреть алгоритм добавления задачи по ID. Чтобы в lesson.problems всегда были только загруженные задачи.
export const getLoadedLessonProblems = createSelector([getLessonProblems], filterLoadedLessonProblems);

export const getIsEmptyLesson = createSelector([getLoadedLessonProblems], (problems: Array<LessonProblem>): boolean => {
    return problems.length === 0;
});
