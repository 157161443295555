import PropTypes from 'prop-types';
import React from 'react';

import './select-suggest__option.scss';

class Option extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        highlighted: PropTypes.bool,
        onClick: PropTypes.func.isRequired,
        onMouseEnter: PropTypes.func.isRequired,
        onMouseLeave: PropTypes.func.isRequired,
        children: PropTypes.node,
        scrollOptionIntoView: PropTypes.func,
        value: PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: PropTypes.string,
        }).isRequired,
    };

    componentDidUpdate() {
        const { scrollOptionIntoView, highlighted } = this.props;

        if (highlighted && scrollOptionIntoView) {
            scrollOptionIntoView(this.self);
        }
    }

    selfRef = (ref) => {
        this.self = ref;
    };

    handleClick = () => {
        const { onClick, value } = this.props;
        onClick(value);
    };

    handleMouseEnter = () => {
        const { onMouseEnter, value } = this.props;
        onMouseEnter(value);
    };

    handleMouseLeave = () => {
        const { onMouseLeave, value } = this.props;
        onMouseLeave(value);
    };

    render() {
        const { children, className } = this.props;

        return (
            <div
                className={className}
                onClick={this.handleClick}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                ref={this.selfRef}
            >
                {children}
            </div>
        );
    }
}

export default Option;
