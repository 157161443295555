import cx from 'classnames';
import { SimpleCallback } from 'platform-components';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import type { iClass } from 'common.components/typings';
import { getIsCollegeTeamUtil } from 'common.components/user/utils';

import { ClassNameEditorControl } from '../class-name-editor/class-name-editor';
import { getLetterErrorMessage, getNumberErrorMessage } from '../class-name-editor/utils';
// @ts-ignore
import FormView from '../form-view/form-view';
// @ts-ignore
import HeadedFormViewCard from '../headed-form-view-card';

import './class-change-form.scss';

interface Props {
    backButton?: React.ReactElement;
    disabledLetter?: boolean;
    disabledNumber?: boolean;
    buttonTextId: string;
    descriptionTextId: string;
    headerTextId: string;
    letter?: string;
    number: number | null;
    onSubmit: SimpleCallback;
    onChange: (formData: iClass) => void;
    mix?: string;
    isLoading?: boolean;
    isSuccess?: boolean;
}

export const ClassChangeForm = ({
    backButton,
    buttonTextId,
    descriptionTextId,
    headerTextId,
    letter,
    number,
    onSubmit,
    mix,
    onChange,
    isLoading,
    isSuccess,
}: Props) => {
    const handleLetterChange = (newLetter: string) => {
        onChange({ letter: newLetter, number });
    };

    const handleNumberChange = (newNumber: number) => {
        onChange({ letter, number: Number(newNumber) });
    };

    const disabled = Boolean(getLetterErrorMessage(letter || null) || getNumberErrorMessage(number));

    const isCollegeTeam = getIsCollegeTeamUtil(number);

    return (
        <HeadedFormViewCard
            descriptionId={descriptionTextId}
            headerId={headerTextId}
            mix={cx('class-change-form', mix)}
        >
            <FormView.CardSection mix="class-change-form__class-name-section">
                <ClassNameEditorControl
                    disabledNumber
                    isCollegeTeam={isCollegeTeam}
                    letter={letter}
                    mix="class-change-form__class-name-controls"
                    number={number}
                    onLetterChange={handleLetterChange}
                    onNumberChange={handleNumberChange}
                />
            </FormView.CardSection>
            <FormView.CardSection>
                <FormView.Button
                    disabled={disabled || isSuccess}
                    mix="qa_team-change-form-submit-button"
                    onClick={onSubmit}
                    progress={isLoading}
                >
                    <FormattedMessage id={buttonTextId} />
                </FormView.Button>
            </FormView.CardSection>
            {backButton && <FormView.Footer mix="class-change-form__footer-section">{backButton}</FormView.Footer>}
        </HeadedFormViewCard>
    );
};
