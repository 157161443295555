import get from 'lodash/get';

export const getPathName = (): string | null => {
    // TODO: вместо null возвращать правильный pathname на ssr
    // TODO: https://st.yandex-team.ru/EDUCATION-10717
    if (isSSR) {
        return null;
    }

    const pathname = window.location.pathname;
    const prefix = get(window, '_data.config.prefix', '');

    return pathname.replace(prefix, '');
};
