import { postVerifyPromocodes } from '@yandex-int/k-common/client/api/defs/post-verify-promocodes';
import b_ from 'b_';
import { H1, H4, showNetworkError } from 'platform-components';
import { useLocalStorage } from 'platform-components/src/utils/hooks/useLocalStorage';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { withUserLogin } from '../../hocs/withUserLogin';

import './promocode-page-success.scss';

const b = b_.with('promocode-page-success');

export const PromocodePageSuccessComponent = () => {
    const { get: getFromLocalStorage, remove: removeFromLocalStorage } = useLocalStorage();
    const dispatch = useDispatch();

    const activatePromocode = useCallback(async () => {
        const promocode = getFromLocalStorage('promocode');

        if (promocode) {
            try {
                const response = await postVerifyPromocodes([promocode], 'activate');

                if (!response.length) {
                    dispatch(showNetworkError());
                    return;
                }

                const { promocodes } = response[0];

                if (!promocodes.hasOwnProperty(promocode)) {
                    dispatch(showNetworkError());
                    return;
                }

                removeFromLocalStorage('promocode');
            } catch (error) {
                dispatch(showNetworkError());
            }

            return;
        }

        dispatch(showNetworkError());
    }, [dispatch, getFromLocalStorage, removeFromLocalStorage]);

    useEffect(() => {
        activatePromocode();
    }, [activatePromocode]);

    return (
        <div className={b('wrapper')}>
            <H1 id="promocodePage.success.title" mix={b('title')} />
            <H4 id="promocodePage.success.description" mix={b('description')} />
        </div>
    );
};

export const PromocodePageSuccess = withUserLogin(PromocodePageSuccessComponent);
