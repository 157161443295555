import cx from 'classnames';
import React from 'react';

import './form-view__footer-section.scss';

interface Props {
    children: React.ReactNode | Array<React.ReactNode>;
    mix?: string;
}
const FormViewFooterSection = React.forwardRef((props: Props, ref: (ref: HTMLDivElement | null) => void) => {
    return (
        <div className={cx(props.mix, 'form-view__footer-section')} ref={ref}>
            {props.children}
        </div>
    );
});

FormViewFooterSection.displayName = 'FormViewFooterSection';

export default FormViewFooterSection;
