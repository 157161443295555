import { SimpleCallback } from 'platform-components';
import React from 'react';
import { useSelector } from 'react-redux';
import { useMount } from 'react-use';
import ym from 'react-yandex-metrika';

import { getStudents, getSchoolCode } from 'common.components/classes/selectors';
import { LoginsFormContent } from 'common.components/logins-form/__content/logins-form__content';
import { getTeamIdFromPath } from 'utils/path';

interface Props {
    addedStudentsIds: Array<number> | null;
    onPrint: SimpleCallback;
    createdClassId: number | null;
}

export const LoginsFormContainer = ({ onPrint, createdClassId, addedStudentsIds }: Props) => {
    const classId = createdClassId || getTeamIdFromPath();

    const teamStudents = useSelector((store) => getStudents(store, { classId }));
    const schoolCode = useSelector((store) => getSchoolCode(store, { classId }));

    useMount(() => {
        ym('reachGoal', 'teacher_olymp_students_logins_modal', { action: 'view' });
    });

    let studentsWereAdded = false;
    let students = teamStudents || [];
    if (addedStudentsIds && teamStudents) {
        studentsWereAdded = addedStudentsIds?.length < teamStudents.length;
        students = teamStudents.filter((student) => addedStudentsIds.includes(student.id));
    }

    return (
        <LoginsFormContent
            classId={classId}
            onPrint={onPrint}
            schoolCode={schoolCode}
            students={students}
            studentsWereAdded={studentsWereAdded}
        />
    );
};
