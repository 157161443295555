import b from 'b_';
import cx from 'classnames';
import { omit } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { isInternetExplorer, isEdge } from '../../utils/client-browser';
import withUatraits from '../../utils/hocs/with-uatraits';
import { INPUT_THEMES } from '../constants';
import Input from '../input/input';
import { inputPropTypes } from '../input/input.propTypes';

import './bordered-input.scss';

const CELL_WIDTH = 27;
const NARROW_CELL_WIDTH = 23;
const BORDER_WIDTH = 1;

export class BorderedInput extends React.Component {
    static propTypes = {
        ...inputPropTypes,
        uatraits: PropTypes.object,
        /**
         * Если значение value из props отличается от введённого пользователем только регистром,
         * то сохранять введённое пользователем значение до потери фокуса input'ом
         * Значение в input'е будет обновлено в onBlur до вызова коллбэка
         * Нужно для корректной работы клавиатуры на телефоне
         */
        keepUserValueIfCaseMismatch: PropTypes.bool,
    };

    static defaultProps = {
        maxLength: 1,
        type: 'text',
    };

    // список полей, которых нет в инпуте и которые не надо передавать в input
    static customProps = ['dispatch'];

    constructor(props) {
        super(props);

        const { focused } = this.props;

        this.state = {
            cursorPosition: focused ? 0 : null,
            userValue: '',
        };
    }

    setControlRef = (ref) => {
        const { onControlRef } = this.props;

        this.control = ref;

        if (onControlRef) {
            onControlRef(ref);
        }
    };

    handleFocus = (e) => {
        const { onFocus } = this.props;
        const { selectionStart } = this.control;

        this.setState({ cursorPosition: selectionStart });

        if (onFocus) {
            onFocus(e);
        }

        this.isBlurred = false;
    };

    handleBlur = (e) => {
        const { onBlur, value, keepUserValueIfCaseMismatch } = this.props;

        this.setState({
            cursorPosition: null,
            userValue: '', // Если пользователь будет что-то вводить, оно обновится, а пока пусть не совпадает
        });

        if (keepUserValueIfCaseMismatch && e.target.value !== value) {
            e.target.value = value;
        }

        if (onBlur) {
            onBlur(e);
        }

        this.isBlurred = true;
    };

    handleKeyUp = (e) => {
        const { onKeyUp } = this.props;
        const { selectionStart } = this.control;

        this.setState({ cursorPosition: this.isBlurred ? null : selectionStart });

        if (onKeyUp) {
            onKeyUp(e);
        }
    };

    handleChange = (e) => {
        const { onChange } = this.props;
        const { selectionStart } = this.control;
        const userValue = e.target.value;

        this.setState({ cursorPosition: this.isBlurred ? null : selectionStart, userValue });

        if (onChange) {
            onChange(e);
        }
    };

    handleClick = (e) => {
        const { onClick } = this.props;
        const { selectionStart } = this.control;

        this.setState({ cursorPosition: this.isBlurred ? null : selectionStart });
        this.control.focus(); // дополнительно руками ставим фокус, потому что на мобилке (pwa) иногда не срабатывает

        if (onClick) {
            onClick(e);
        }
    };

    getBackgroundWidth = () => {
        const { maxLength } = this.props;

        if (maxLength === 1) {
            return NARROW_CELL_WIDTH;
        }

        // ширина всей подложки: крайняя ячейка + внутренние + крайняя + границы (на 1 меньше, чем ячеек)
        return NARROW_CELL_WIDTH * 2 + CELL_WIDTH * (maxLength - 2) + BORDER_WIDTH * (maxLength - 1);
    };

    render() {
        const {
            defaultValue,
            value = '',
            maxLength,
            mix,
            type,
            uatraits,
            keepUserValueIfCaseMismatch,
            ...otherProps
        } = this.props;
        const { cursorPosition, userValue } = this.state;
        const filteredProps = omit(otherProps, BorderedInput.customProps);

        let processedValue = (value ?? defaultValue).toString() || '';

        if (processedValue.length > maxLength) {
            processedValue = value.substring(0, maxLength);
        }

        const valueArray = processedValue.split('').concat(new Array(maxLength - processedValue.length).fill(''));
        const shouldShowDefaultCursor = isInternetExplorer(uatraits) || isEdge(uatraits);

        const inputValue =
            keepUserValueIfCaseMismatch && typeof value === 'string' && value.toLowerCase() === userValue.toLowerCase()
                ? userValue
                : value;

        return (
            <div className={cx('bordered-input', mix)} style={{ width: this.getBackgroundWidth() }}>
                <Input
                    {...filteredProps}
                    autoComplete="off"
                    defaultValue={defaultValue}
                    maxLength={maxLength}
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                    onClick={this.handleClick}
                    onControlRef={this.setControlRef}
                    onFocus={this.handleFocus}
                    onKeyUp={this.handleKeyUp}
                    placeholder=""
                    theme={INPUT_THEMES.STUDENT}
                    type={type}
                    value={inputValue}
                />
                <div className="bordered-input__display">
                    {valueArray.map((val, index) => {
                        let isLeftCaret = index === cursorPosition;
                        let isRightCaret = index === maxLength - 1 && cursorPosition === maxLength;

                        // В IE и Edge отключить мигание курсора в инпуте нельзя
                        // поэтому отключаем наш курсор
                        if (shouldShowDefaultCursor) {
                            isLeftCaret = false;
                            isRightCaret = false;
                        }

                        return (
                            <>
                                {isLeftCaret && <div className={b('bordered-input', 'caret', { left: true })} />}
                                <span
                                    className={b('bordered-input', 'display-cell', { 'with-caret': isLeftCaret })}
                                    key={`inner-span-${index}`}
                                >
                                    {val}
                                </span>
                                {isRightCaret && <div className={b('bordered-input', 'caret', { right: true })} />}
                            </>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default withUatraits(BorderedInput);
