import { ContainerElement } from '@yandex-lego/components/Button';
import b from 'b_';
import cx from 'classnames';
import { Copy as CopyIcon, Checkmark as IconCheckmark } from 'education-icons';
import React, { useCallback, useMemo, useRef, useState } from 'react';

import { copyTextToClipboard } from '../../utils/copy-text-to-clipboard/copy-text-to-clipboard';
import { isTouchDevice } from '../../utils/device';
import { useLatestTimeout } from '../../utils/hooks';
import { Button } from '../button/button';
// @ts-ignore
import Input from '../input/input';
import { Tooltip } from '../LegoTooltip/LegoTooltip';
import Text from '../text/text';

import './copy.scss';

interface CopyProps {
    className?: string;
    onClick?: (e: React.MouseEvent<ContainerElement>) => void;
    text: string;
}

export const Copy = ({ className, onClick, text }: CopyProps) => {
    const buttonRef = useRef<HTMLDivElement | null>(null);
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [linkCopied, setLinkCopied] = useState(false);
    const takeLatestTimeout = useLatestTimeout();

    const showTooltip = useCallback(() => {
        setTooltipVisible(true);
        setLinkCopied(false);
    }, []);

    const hideTooltip = useCallback(() => {
        setTooltipVisible(false);
    }, []);

    const onLinkCopied = useCallback(() => {
        setTooltipVisible(true);
        setLinkCopied(true);
    }, []);

    const copyLink = useCallback(
        (e: React.MouseEvent<ContainerElement>) => {
            copyTextToClipboard(text);
            onLinkCopied();

            if (onClick) {
                onClick(e);
            }

            if (isTouchDevice) {
                takeLatestTimeout(() => {
                    hideTooltip();
                }, 1000);
            }
        },
        [hideTooltip, onClick, onLinkCopied, takeLatestTimeout, text]
    );

    const hoverHandlers = useMemo(() => {
        if (isTouchDevice) {
            return {};
        }

        return {
            onMouseEnter: showTooltip,
            onMouseLeave: hideTooltip,
        };
    }, [hideTooltip, showTooltip]);

    return (
        <div className={cx('copy', className)}>
            <div className="copy__wrapper">
                <Input mix="copy__input" value={text} />

                <Button
                    className="copy__button"
                    icon={<CopyIcon />}
                    innerRef={buttonRef}
                    onClick={copyLink}
                    size={Button.SIZE.S}
                    view={Button.VIEW.CLEAR}
                    {...hoverHandlers}
                />

                <div className="copy__popup-wrapper">
                    <Tooltip
                        anchor={buttonRef}
                        className="copy__tooltip"
                        direction={isTouchDevice ? 'top-end' : 'top'}
                        hasTail
                        mainOffset={15}
                        size="m"
                        visible={tooltipVisible}
                    >
                        {linkCopied ? (
                            <div className="copy__tooltip-content">
                                <IconCheckmark className={b('copy__tooltip-icon', { success: true })} />
                                <Text id={'copyComponent.linkCopied'} />
                            </div>
                        ) : (
                            <span className="copy__tooltip-content">
                                <Text id={'copyComponent.copyLink'} />
                            </span>
                        )}
                    </Tooltip>
                </div>
            </div>
        </div>
    );
};
