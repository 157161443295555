import {
    Button,
    H3,
    Input,
    Modal,
    ModalCloseProps,
    showIntlMessageNotification as showIntlMessageNotificationAction,
    SimpleText,
    Text,
    useIntl,
} from 'platform-components';
import { GRAVITIES, NOTIFICATION_THEMES } from 'platform-components/constants';
import { CloseButton } from 'platform-components/src/components/close-button/_view/close-button_view_outer';
import { InputChangeEvent } from 'platform-components/src/typings';
import { convertString, isValidSalesCode, RUS_TO_ENG_LAYOUT_EXTRA_MAP } from 'platform-components/utils';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { MAX_PROMO_LENGTH } from 'common.components/constants';
import { SALES_CODE_SCENARIO } from 'common.components/sales-code-modal/constants';

import * as actions from './actions';
import { getSalesCode } from './selectors';
import { SalesCodeModalStoreState } from './typings';

import './sales-code-modal.scss';

export const NOTIFICATION_DELAY = 3000;

type StoreProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

interface OwnProps {
    existedCodes?: string[];
    opened: boolean;
    savedSalesCode: string | null;
    onSave: (salesCode: string | null) => void;
    disabled: boolean;
    scenario: SALES_CODE_SCENARIO;
}

export interface Props extends StoreProps, DispatchProps, OwnProps {}

/* eslint-disable complexity */
function getCodeValidness(
    convertedSalesCode: string | null,
    existedCodes: string[] | undefined,
    scenario: SALES_CODE_SCENARIO
): string | null {
    const isEmpty = !convertedSalesCode;
    const isValid = isValidSalesCode(convertedSalesCode ? convertedSalesCode : '');
    const isExisted = existedCodes?.includes(convertedSalesCode ? convertedSalesCode : '');

    switch (scenario) {
        case SALES_CODE_SCENARIO.FROM_ACCOUNT: {
            if (isEmpty) {
                return 'salesCodeModal.input.error';
            } else if (isExisted) {
                return 'salesCodeModal.input.codeExists';
            }
            break;
        }
        case SALES_CODE_SCENARIO.FROM_REGISTRATION: {
            if (isEmpty) {
                return 'ok';
            }
            break;
        }
    }
    if (!isValid) {
        return 'salesCodeModal.input.invalidCode';
    }
    return 'ok';
}

export const SalesCodeModalComponent = (props: Props) => {
    const {
        existedCodes,
        setSalesCode,
        toggleSalesCodeModal,
        currentSalesCode,
        onSave,
        showIntlMessageNotification,
        disabled,
        opened,
        scenario,
        savedSalesCode,
    } = props;
    const intl = useIntl();
    const [errorMessageId, setErrorMessageId] = useState<string | null>(null);

    useEffect(() => {
        if (scenario === SALES_CODE_SCENARIO.FROM_REGISTRATION) {
            setSalesCode(savedSalesCode);
        }
    }, [savedSalesCode]);

    const handleClose = () => {
        setErrorMessageId(null);
        toggleSalesCodeModal(false);
        setSalesCode(savedSalesCode);
    };

    const handleChange = ({ target: { value } }: InputChangeEvent) => {
        const convertedSalesCode = value ? convertString(value.toUpperCase(), RUS_TO_ENG_LAYOUT_EXTRA_MAP) : null;

        setSalesCode(convertedSalesCode);
        validateCode(convertedSalesCode);
    };

    const validateCode = (convertedSalesCode: string | null) => {
        const codeStatus = getCodeValidness(convertedSalesCode, existedCodes, scenario);
        const errorMessage = codeStatus === 'ok' ? null : codeStatus;
        setErrorMessageId(errorMessage);
        return !errorMessage;
    };

    const handleSaveCode = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (validateCode(currentSalesCode)) {
            onSave(currentSalesCode);
            handleClose();
            showIntlMessageNotification({
                closeDelay: NOTIFICATION_DELAY,
                messageId: currentSalesCode ? 'salesCodeModal.toast' : 'salesCodeModal.clear',
                withExplicitClose: true,
                gravity: GRAVITIES.BOTTOM,
                theme: NOTIFICATION_THEMES.TOAST,
            });
        } else {
            event.preventDefault();
        }
    };

    return (
        <Modal
            Close={(closeProps: ModalCloseProps) => (
                <CloseButton {...closeProps} position={CloseButton.POSITION.ABSOLUTE} />
            )}
            hasClose
            mix="sales-code-modal"
            onRequestClose={handleClose}
            opened={opened}
            theme={Modal.THEMES.TEACHER}
        >
            <Modal.Header>
                <H3 id="salesCodeModal.title" type={Text.TYPE.MARKDOWN} weight={Text.WEIGHT.M} />
            </Modal.Header>
            <Modal.Content>
                <Text id="salesCodeModal.description" type={Text.TYPE.MARKDOWN} />
                <Input
                    disabled={disabled}
                    errorMessage={errorMessageId ? intl.formatMessage({ id: errorMessageId }) : null}
                    maxLength={MAX_PROMO_LENGTH}
                    mix="sales-code-modal__input"
                    onBlur={() => validateCode(currentSalesCode)}
                    onChange={handleChange}
                    placeholder={intl.formatMessage({ id: 'salesCodeModal.input.placeholder' })}
                    resetValidAfterFocus={false}
                    valid={errorMessageId ? 'no' : 'uncertain'}
                    value={currentSalesCode || ''}
                />
            </Modal.Content>
            <Modal.Footer>
                <Button className="sales-code-modal__button" onClick={handleSaveCode}>
                    <SimpleText id="salesCodeModal.button" type={SimpleText.TYPE.MARKDOWN} />
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

const mapStateToProps = <T extends { salesCodeModal: SalesCodeModalStoreState }>(storeState: T) => ({
    currentSalesCode: getSalesCode(storeState),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    const { setSalesCode, toggleSalesCodeModal } = actions;

    return bindActionCreators(
        {
            setSalesCode,
            toggleSalesCodeModal,
            showIntlMessageNotification: showIntlMessageNotificationAction,
        },
        dispatch
    );
};

export const SalesCodeModal = connect(mapStateToProps, mapDispatchToProps)(SalesCodeModalComponent);
