import PropTypes from 'prop-types';
import React from 'react';

import Text from '../../text/text';

const SimpleStringNotificationBody = ({ notification: { message } }) => <Text>{message}</Text>;

SimpleStringNotificationBody.propTypes = {
    notification: PropTypes.shape({
        message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    }),
};

export default SimpleStringNotificationBody;
