import React from 'react';

import { AddNewChildSuccessCallback } from 'common.components/child-management/typings';

import StudentCode from './__student-code/child-management__student-code';

interface Props {
    bottomSection?: React.ReactElement;
    childName?: string;
    isSubmitting?: boolean;
    mix?: string;
    onSuccess: AddNewChildSuccessCallback;
    parentCode?: string;
    showAgreement?: boolean;
}

const ChildManagement = ({
    bottomSection,
    childName,
    isSubmitting,
    mix,
    onSuccess,
    parentCode,
    showAgreement,
}: Props) => {
    return (
        <StudentCode
            bottomSection={bottomSection}
            childName={childName}
            isSubmitting={isSubmitting}
            mix={mix}
            onSuccess={onSuccess}
            parentCode={parentCode}
            showAgreement={showAgreement}
        />
    );
};

export default ChildManagement;
