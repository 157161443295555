import { Team } from '@yandex-int/k-common/typings';
import { ClassTeacher } from '@yandex-int/k-common/typings/api/defs/get-class-teachers';
import { SimpleCallback } from 'platform-components';

import { iClass } from 'common.components/typings';

import * as constants from './constants';

export const getTeam = (classId: number) => <const>{ type: constants.GET_TEAM, classId };

export const getClassRequest = (classId: number) => <const>{ type: constants.GET_CLASS_REQUEST, classId };

export const getClassSuccess = (classId: number, classData: iClass) =>
    <const>{ type: constants.GET_CLASS_SUCCESS, classId, classData };

export const getClassError = (classId: number, error: Error) =>
    <const>{ type: constants.GET_CLASS_ERROR, classId, error };

export const setNameRequest = (classId: number, letter: string, number: number) =>
    <const>{ type: constants.SET_NAME_REQUEST, classId, letter, number };

export const setNameSuccess = (classId: number, letter: string, number: number) =>
    <const>{ type: constants.SET_NAME_SUCCESS, classId, letter, number };

export const setNameError = (classId: number, error: Error) =>
    <const>{ type: constants.SET_NAME_ERROR, classId, error };

export const getTeachersRequest = (classId: number) => <const>{ type: constants.GET_TEACHERS_REQUEST, classId };

export const getTeachersSuccess = (classId: number, teachersList: Array<ClassTeacher>) =>
    <const>{ type: constants.GET_TEACHERS_SUCCESS, classId, teachersList };

export const getTeachersError = (classId: number) => <const>{ type: constants.GET_TEACHERS_ERROR, classId };

export const removeTeacher = (
    classData: Team,
    teacherId: number,
    teacherName: string,
    restoreCallback: SimpleCallback
) => <const>{ type: constants.REMOVE_TEACHER, classData, teacherId, teacherName, restoreCallback };

export const restoreTeacher = (classData: Team, teacherId: number, teacherName: string) =>
    <const>{ type: constants.RESTORE_TEACHER, classData, teacherId, teacherName };
