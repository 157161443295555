import PropTypes from 'prop-types';
import React from 'react';

import Text from '../../text/text';

const SelectSuggestDefaultOptionRenderer = ({ text }) => {
    return (
        <div style={{ padding: '8px' }}>
            <Text>{text}</Text>
        </div>
    );
};

SelectSuggestDefaultOptionRenderer.propTypes = {
    text: PropTypes.string,
};

export default SelectSuggestDefaultOptionRenderer;
