import PropTypes from 'prop-types';
import React from 'react';

const ClassNameEditorHelpMessage = ({ helpMessage }) => {
    if (helpMessage) {
        return <div className="class-name-editor__help-message">{helpMessage}</div>;
    }

    return null;
};

ClassNameEditorHelpMessage.propTypes = {
    helpMessage: PropTypes.string,
};

export default ClassNameEditorHelpMessage;
