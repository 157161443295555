import { Text } from 'platform-components';
import { TEXT_MARKUP_TYPE } from 'platform-components/constants';
import getStudentName from 'platform-components/src/utils/get-student-name';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import Logo from 'common.components/logo/logo';
import { StudentsPrintInvitationCardStudent } from 'common.components/students-print/__invitation-card-data/students-print__invitation-card-student';
import { BaseStudent } from 'common.components/typings';

import './students-print__invitation-card.scss';

interface Props extends WrappedComponentProps {
    schoolCode?: string | null;
    shouldShowParentInvitation: boolean;
    shouldUsePassportAccounts?: boolean;
    student: BaseStudent;
}

const Component = (props: Props) => {
    const { schoolCode, student, shouldShowParentInvitation, shouldUsePassportAccounts } = props;

    return (
        <section className="students-print__invitation-card">
            <div className="students-print__invitation-logo-wrap">
                <Logo mix="students-print__invitation-logo" />
            </div>
            <div className="students-print__invitation-user">
                <Text ellipsis size={Text.SIZE.S} weight={Text.WEIGHT.L}>
                    {getStudentName(student.firstName, student.lastName)}
                </Text>
            </div>
            <StudentsPrintInvitationCardStudent
                schoolCode={schoolCode}
                shouldUsePassportAccounts={shouldUsePassportAccounts}
                student={student}
            />
            {shouldShowParentInvitation && (
                <React.Fragment>
                    <div className="students-print__invitation-parent">
                        <Text id="class.invitationParent" size={Text.SIZE.S} type={TEXT_MARKUP_TYPE.HTML} />
                    </div>
                    <div className="students-print__invitation-instruction qa_parent-instruction">
                        <Text id="class.invitationInstructionParent" size={Text.SIZE.S} type={TEXT_MARKUP_TYPE.HTML} />
                    </div>
                </React.Fragment>
            )}
        </section>
    );
};

export const StudentsPrintInvitationCard = injectIntl(Component);
