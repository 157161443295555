import b from 'b_';
import cx from 'classnames';
import { BackgroundImage } from 'platform-components';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';

import ErrorPopup from '../__error-popup/start-screen__error-popup';
import StudentLogin from '../__student-login/start-screen__student-login';
import { getLogin, getPassword } from '../selectors';

import './start-screen__student.scss';

export const StartScreenStudent = ({ intl, mix }) => {
    const topImage = intl.formatMessage({ id: 'startScreen.studentImage.top' });

    // @todo сейчас этого ключа нет в бункере – проверить нужно ли оно здесь
    const bottomImageId = 'startScreen.studentImage.bottom';
    const formattedBottomImage = intl.formatMessage({ id: bottomImageId });
    const bottomImage = formattedBottomImage === bottomImageId ? undefined : formattedBottomImage;

    return (
        <div className={cx('start-screen__student', mix)}>
            <div className={b('start-screen', 'student-picture', { top: true })}>
                <BackgroundImage objectFit="contain" src={topImage} />
            </div>
            <div className={b('start-screen', 'student-picture', { bottom: true })}>
                <BackgroundImage objectFit="contain" src={bottomImage} />
            </div>

            <div className="start-screen__student-content">
                <StudentLogin />
            </div>
            <ErrorPopup />
        </div>
    );
};

StartScreenStudent.propTypes = {
    intl: PropTypes.object.isRequired,
    login: PropTypes.string,
    mix: PropTypes.string,
    onAdultClick: PropTypes.func.isRequired,
    password: PropTypes.string,
};

const mapStateToProps = (storeState) => ({
    login: getLogin(storeState),
    password: getPassword(storeState),
});

export default compose(injectIntl, connect(mapStateToProps))(StartScreenStudent);
