export enum SELECT_THEMES {
    CLEAN = 'clean',
    LINK = 'link',
    MARKER = 'marker',
    NORMAL = 'normal',
    SCHOOL = 'school',
    FILTER = 'filter',
    TEACHER = 'teacher',
    ROUNDED = 'rounded',
}

export enum SELECT_SIZES {
    M = 'm',
    L = 'l',
    XL = 'xl',
}
