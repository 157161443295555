import b from 'b_';
import { MarkdownExtXContent, Checkbox, Text } from 'platform-components';
import { BackgroundImage } from 'platform-components';
import { CHECKBOX_TYPES, TEXT_SIZE, TEXT_WEIGHT } from 'platform-components/constants';
import React from 'react';
import { accessibleClick } from '@edu-frontend/modules/src/shared/lib/a11y';

import './subject-form__subject.scss';

interface Props {
    id: number;
    disabled?: boolean;
    coverUrl: string | null;
    name: string;
    description?: string;
    selected: boolean;
    title?: string;
    onSelect: (subjectId: number) => void;
}

export class Subject extends React.PureComponent<Props> {
    handleClick = () => {
        const { disabled, id, onSelect } = this.props;

        if (!disabled) {
            onSelect(id);
        }
    };

    handleChange = () => {
        const { disabled, id, onSelect } = this.props;

        if (!disabled) {
            onSelect(id);
        }
    };

    render() {
        const { coverUrl, disabled, name, selected, title, description } = this.props;

        return (
            <div
                className={b('subjects-form', 'subject', { disabled })}
                title={title}
                {...accessibleClick(this.handleClick)}
            >
                <div className="subjects-form__subject-cover">
                    <BackgroundImage mix="subjects-form__subject-image" objectFit="cover" src={coverUrl} />
                </div>
                <div className="subjects-form__subject-text">
                    <div className="subjects-form__subject-name">
                        <Text size={TEXT_SIZE.XL} weight={TEXT_WEIGHT.M}>
                            {name}
                        </Text>
                    </div>
                    <div className="subjects-form__subject-description">
                        <Text size={TEXT_SIZE.S} weight={TEXT_WEIGHT.S}>
                            <MarkdownExtXContent>{description as string}</MarkdownExtXContent>
                        </Text>
                    </div>
                </div>
                <div {...accessibleClick((event) => event.stopPropagation())}>
                    <Checkbox
                        checked={selected}
                        disabled={disabled}
                        mix="subjects-form__subject-checkbox"
                        onChange={this.handleChange}
                        type={CHECKBOX_TYPES.GREY}
                    />
                </div>
            </div>
        );
    }
}

export default Subject;
