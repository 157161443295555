import React from 'react';

import type { SimpleCallback } from '../../typings';

export enum MODAL_FADE_THEMES {
    DARK = 'dark',
    LIGHT = 'light',
    TEACHER_DARK = 'teacher-dark',

    /** След темы могут медленно работать, тк используют backdrop-filter: blur() */
    BLUR = 'blur',
    BLUR_ANIMATED = 'blur_animated',
}

export enum MODAL_THEMES {
    STUDENT = 'student',
    STUDENT_ADAPTIVE = 'student-adaptive',
    TEACHER = 'teacher',
}

export interface ModalProps {
    /** id элемента с текстом, который скринридер озвучит при открытии модалки */
    'aria-labelledby'?: string;
    'aria-describedby'?: string;

    /** Цвет подложки под контентом модалки (дефолтно белый) */
    backgroundColor?: string;

    /** Есть ли у компонента Modal иконка закрытия */
    hasClose?: boolean;

    /** Компонент с кастомной иконкой закрытия */
    Close?: (closeProps: ModalCloseProps) => JSX.Element | null;

    /** Сколько ждать перед уходом из DOM (дает время на анимацию закрытия) (дефолтно 0) */
    closeDelay?: number;

    /** Вызывается при нажатии на иконку закрытия, esc и тень от модалки */
    onRequestClose?: SimpleCallback;

    /** Нужно ли использовать scale для мобильных устройств (дефолтно true) */
    useScale?: boolean;

    /** Тема модалки */
    theme?: MODAL_THEMES;

    /** Тема тени от модалки */
    fadeTheme?: MODAL_FADE_THEMES;

    autoclosable?: boolean;
    mix?: string;
    opened?: boolean;
    portalAutoFocus?: boolean;
    children?: React.ReactNode | Array<React.ReactNode>;
    scrollToTop?: boolean;
    onOpened?: () => void;
}

export interface ModalCloseProps {
    'aria-label'?: string;
    className?: string;
    onClick: () => void;
}
