import b from 'b_';
import cx from 'classnames';
import React, { ReactElement } from 'react';

import { MenuContext, ContextProps } from '../context';

import './menu__item.scss';

type HTMLLiElementCallback = (e: React.MouseEvent<HTMLLIElement>) => void;

interface Props {
    disabled?: boolean;
    focused?: boolean;
    mix?: string;
    onClick?: HTMLLiElementCallback;
    onMouseEnter?: HTMLLiElementCallback;
    onMouseLeave?: HTMLLiElementCallback;
    selected?: boolean;
    value?: unknown;
    role?: string;

    /** компонент используется только для скринридера */
    isA11y?: boolean;
}

class MenuItem extends React.PureComponent<React.PropsWithChildren<Props>> {
    static contextType = MenuContext;

    state = {
        focused: this.props.focused,
    };

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (nextProps.focused !== undefined) {
            this.setState({ focused: nextProps.focused });
        }
    }

    render() {
        const { focused } = this.state;
        const { selected, disabled: itemDisabled, mix, role, isA11y } = this.props;
        const { disabled: menuDisabled, size, theme } = this.context as ContextProps;

        const disabled = itemDisabled || menuDisabled;
        const className = b('menu', 'item', { disabled, focused, selected, size, theme });

        return (
            <li
                className={isA11y ? undefined : cx(className, mix)}
                onClick={disabled ? undefined : this.props.onClick}
                onMouseEnter={this.props.onMouseEnter}
                onMouseLeave={this.props.onMouseLeave}
                role={role}
            >
                {React.Children.map(this.props.children, (child) => {
                    if (React.isValidElement(child)) {
                        return React.cloneElement(child as ReactElement<{ isA11y?: boolean }>, { isA11y });
                    }
                    return child;
                })}
            </li>
        );
    }
}

export default MenuItem;
