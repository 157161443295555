import { Text } from 'platform-components';
import {
    getDisplayName,
    getFirstName,
    getLastName,
    getUserAvatarUrl,
    // @ts-ignore
} from 'platform-components/src/components/user/selectors';
import React from 'react';
import { connect } from 'react-redux';

import { getUserName } from 'utils/get-user-name';

import { UserIcon } from '../__icon/user__icon';

import './user__info.scss';

interface StoreProps {
    avatarUrl: string;
    displayName: string | null;
    firstName: string;
    lastName: string;
}

interface OwnProps {
    withAvatar?: boolean;
}

interface Props extends OwnProps, StoreProps {}

export const UserInfo = (props: Props) => {
    const { displayName, firstName, lastName, withAvatar } = props;

    return (
        <div className="user__info">
            <span className="user__name">
                <Text weight={Text.WEIGHT.M}>{getUserName(firstName, lastName, displayName)}</Text>
            </span>
            {withAvatar && <UserIcon />}
        </div>
    );
};

const mapStateToProps = (storeState: any): StoreProps => {
    return {
        avatarUrl: getUserAvatarUrl(storeState),
        displayName: getDisplayName(storeState),
        firstName: getFirstName(storeState),
        lastName: getLastName(storeState),
    };
};

export default connect(mapStateToProps)(UserInfo);
