import { toggleSelection } from '../toggle-selection';

type Success = boolean;

export const copyTextToClipboard = (text: string): Success => {
    if (isSSR) {
        return false;
    }

    const reselectPrevious = toggleSelection();
    const selection = document.getSelection();
    const range = document.createRange();
    const mark = document.createElement('span');

    // @ts-ignore
    mark.style.all = 'unset';
    mark.style.position = 'fixed';
    mark.style.top = '0';
    mark.style.clip = 'rect(0, 0, 0, 0)';
    mark.style.whiteSpace = 'pre';

    // @ts-ignore
    mark.style.MozUserSelect = 'text';
    mark.style.webkitUserSelect = 'text';
    // @ts-ignore
    mark.style.msUserSelect = 'text';
    mark.style.userSelect = 'text';

    mark.textContent = text;

    mark.addEventListener('copy', function (e) {
        e.stopPropagation();
    });

    document.body.appendChild(mark);
    range.selectNodeContents(mark);
    selection?.addRange(range);

    const success = document.execCommand('copy');

    if (selection) {
        if (typeof selection.removeRange === 'function') {
            selection.removeRange(range);
        } else {
            selection.removeAllRanges();
        }
    }

    if (mark) {
        document.body.removeChild(mark);
    }

    reselectPrevious();

    return success;
};
