// вспомогательные функции напрямую не связанные
// с реализацией компонента select

import isNil from 'lodash/isNil';

export const heightValidate = (props, propName, componentName) => {
    if (!isNil(props[propName])) {
        const expectedValues = ['adaptive'];
        const isExpectedValue = expectedValues.includes(props[propName]);
        const isNumber = !Number.isNaN(Number(props[propName]));

        if (!isExpectedValue && !isNumber) {
            return new Error(
                `Invalid prop '${propName}' of value ` +
                    `'${props[propName]}' supplied to '${componentName}'` +
                    `, expected one of '${JSON.stringify(expectedValues)}'` +
                    ' or string of number.'
            );
        }
    }
    return null;
};
