import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import { getSpecCourses } from '@yandex-int/k-common/utils/get-spec-courses';
import { createSelector } from 'reselect';

import { getBindingLoadingStatus } from 'common.components/child-management/selectors';
import { getFirstChild } from 'common.components/user/selectors';

import { ParentRegistrationStoreState } from './typings';

const parentRegistrationSelector = (storeState: any): ParentRegistrationStoreState => storeState.parentRegistration;

export const getChildName = createSelector(
    parentRegistrationSelector,
    (storeState: ParentRegistrationStoreState): string => storeState.childName
);

export const getStudentGrade = createSelector(
    parentRegistrationSelector,
    (storeState: ParentRegistrationStoreState): number | null => storeState.studentGrade
);

export const getCreateChildLoadingStatus = createSelector(
    parentRegistrationSelector,
    (storeState: ParentRegistrationStoreState): LOADING_STATUSES => storeState.createChildLoadingStatus
);

export const getIsChildCreationSuccess = createSelector(
    [getBindingLoadingStatus],
    (binding: LOADING_STATUSES): boolean => binding === LOADING_STATUSES.SUCCESS
);

export const getChildId = createSelector([getFirstChild], (firstChild): number | null => {
    return firstChild?.id || null;
});

export const getChildCourseId = createSelector([getFirstChild], (firstChild): number | null => {
    const courses = firstChild?.courses || [];

    return courses.length ? courses[0].id : null;
});

export const getChildSpecCourseId = createSelector([getFirstChild], (firstChild): number | null => {
    const courses = firstChild?.courses || [];

    return getSpecCourses(courses)[0]?.id || null;
});
