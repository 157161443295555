import { ROLES } from '@yandex-int/k-common/constants';
import b from 'b_';
import cx from 'classnames';
import { Copyright } from 'platform-components';
import { COPYRIGHT_TYPES } from 'platform-components/constants';
import PropTypes from 'prop-types';
import React from 'react';

import Help from '../__help/footer__help';
import { THEMES } from '../constants';

import './footer_theme_narrow.scss';

const FooterThemeNarrow = ({ children, mix, role }) => (
    <footer className={cx(b('footer', { theme: THEMES.NARROW }), mix)}>
        {children ? (
            children
        ) : (
            <React.Fragment>
                <Copyright key="copyright" type={COPYRIGHT_TYPES.YANDEX} />
                <Help key="help" role={role} />
            </React.Fragment>
        )}
    </footer>
);

FooterThemeNarrow.propTypes = {
    children: PropTypes.node,
    mix: PropTypes.string,
    role: PropTypes.oneOf(Object.values(ROLES)),
};

export default FooterThemeNarrow;
