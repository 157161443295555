import React from 'react';

import { StudentsPrintInvitationCredentials } from 'common.components/students-print/__invitation-credentials/students-print__invitation-credentials';
import { getStudentPasswordFromLocalStorageWithExpireTime } from 'common.components/students/utils';
import { BaseStudent } from 'common.components/typings';

interface Props {
    schoolCode?: string | null;
    shouldUsePassportAccounts?: boolean;
    student: BaseStudent;
}

const SCHOOL_CODE_LENGTH = 10;
const STUDENT_LOGIN_LENGTH = 10;
const STUDENT_PASSWORD_LENGTH = 10;

export const StudentsPrintInvitationCardStudent = (props: Props) => {
    const { schoolCode, shouldUsePassportAccounts, student } = props;

    const { id, login, no_yandex_account: noYandexAccount, username } = student;

    if (shouldUsePassportAccounts) {
        const password = getStudentPasswordFromLocalStorageWithExpireTime(id) || '******';

        return (
            <StudentsPrintInvitationCredentials
                firstLabelId="class.invitationLabelLogin"
                firstLabelLength={STUDENT_LOGIN_LENGTH}
                firstValue={(noYandexAccount === false && username) || '******'}
                instructionId="class.invitationPassportInstruction"
                secondLabelId="class.invitationLabelPassword"
                secondLabelLength={STUDENT_PASSWORD_LENGTH}
                secondValue={password || '******'}
            />
        );
    }

    return (
        <StudentsPrintInvitationCredentials
            firstLabelId="class.invitationLabelLogin"
            firstLabelLength={STUDENT_LOGIN_LENGTH}
            firstValue={login || '******'}
            instructionId="class.invitationInstruction2"
            secondLabelId="class.invitationLabelCode"
            secondLabelLength={SCHOOL_CODE_LENGTH}
            secondValue={schoolCode || '******'}
        />
    );
};
