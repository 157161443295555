import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useMount } from 'react-use';

import { Tabs, Tab, TABS_THEME } from '@edu-frontend/modules/src/shared/ui/Tabs';
import b_ from 'b_';
import cx from 'classnames';
import { PersonalAgreement, SimpleCallback } from 'platform-components';

import { getTypeOfFirstCreatedTeam } from 'common.components/user/selectors';
import {
    PRESELECTED_CLASS_LETTER,
    PRESELECTED_CLASS_NUMBER,
    PRESELECTED_COLLEGE_GROUP_LETTER,
} from 'common.components/user/class.constants';

import { ClassNameEditorControl } from '../class-name-editor/class-name-editor';
import { getLetterErrorMessage, getNumberErrorMessage } from '../class-name-editor/utils';
// @ts-ignore
import FormView from '../form-view/form-view';
// @ts-ignore js-file
import { HeadedFormViewCard } from '../headed-form-view-card';

import './class-form.scss';

interface Props {
    additionalButton?: React.ReactElement;

    /** Название класса */
    letter?: string;

    /** Грейд класса */
    number: number | null;
    onSubmit: SimpleCallback;
    onChange: (formData: { letter?: string; number: number | null } | null) => void;
    bottomSection?: React.ReactElement;
    mix?: string;
    isLoading?: boolean;
    isSuccess?: boolean;
    shouldShowPersonalDataLink?: boolean;
    disabledNumber?: boolean;
}

const b = b_.with('class-form');

/** Часть модалки создания класса про "Назовите ваш класс" */
// eslint-disable-next-line complexity
export const ClassForm = ({
    additionalButton,
    bottomSection,
    letter,
    onSubmit,
    mix,
    isLoading,
    isSuccess,
    shouldShowPersonalDataLink,
    disabledNumber,
    number,
    onChange,
}: Props) => {
    const intl = useIntl();

    const typeOfFirstCreatedTeam = useSelector(getTypeOfFirstCreatedTeam);
    const [currentTab, setCurrentTab] = useState<'School' | 'College'>('School');

    useMount(() => {
        if (disabledNumber) {
            // Выставляем нужный таб, если отключено изменение грейда
            setCurrentTab(number === 99 ? 'College' : 'School');
        } else if (typeOfFirstCreatedTeam === 'College') {
            // Выставляем нужный таб, если первая созданная сущность – колледж
            // Не получается это сделать дефолтным значением в useState,
            // потому что случается какая-то гонка и в поле имени встает "А", вместо "СПО-1"
            setCurrentTab('College');
        }
    });

    useEffect(() => {
        // Ставим дефолтное имя группы в поле
        if (currentTab === 'College') {
            onChange({ number: 99, letter: PRESELECTED_COLLEGE_GROUP_LETTER });
        } else {
            onChange({ number: disabledNumber ? number : PRESELECTED_CLASS_NUMBER, letter: PRESELECTED_CLASS_LETTER });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTab]);

    const handleLetterChange = (newLetter: string) => {
        onChange({ letter: newLetter, number });
    };

    const handleNumberChange = (newNumber: Props['number'] | string) => {
        onChange({ letter, number: Number(newNumber) });
    };

    const isSchoolTab = currentTab === 'School';
    const textIds = {
        button: isSchoolTab ? 'addClassForm.sendButton.createClass' : 'addClassForm.sendButton.createClassCollege',
        header: isSchoolTab ? 'addClassForm.header.class' : 'addClassForm.header.classCollege',
        description: isSchoolTab ? 'addClassForm.description.class' : 'addClassForm.description.classCollege',
    };

    const disabled = Boolean(getLetterErrorMessage(letter || null) || getNumberErrorMessage(number));
    const submitButtonText = intl.formatMessage({ id: textIds.button });

    return (
        <HeadedFormViewCard descriptionId={textIds.description} headerId={textIds.header} mix={cx(b(), mix)}>
            {!disabledNumber && (
                <Tabs className={b('tabs')} equalWidths={true} theme={TABS_THEME.RADIO}>
                    <Tab id="School" onTabSelect={(id) => setCurrentTab(id)} selected={isSchoolTab}>
                        {intl.formatMessage({ id: 'addClassForm.tab.school' })}
                    </Tab>
                    <Tab id="College" onTabSelect={(id) => setCurrentTab(id)} selected={!isSchoolTab}>
                        {intl.formatMessage({ id: 'addClassForm.tab.college' })}
                    </Tab>
                </Tabs>
            )}

            <FormView.CardSection mix={b('class-name-section')}>
                <ClassNameEditorControl
                    disabledNumber={disabledNumber}
                    isCollegeTeam={!isSchoolTab}
                    letter={letter}
                    mix={b('class-name-controls')}
                    number={number}
                    onLetterChange={handleLetterChange}
                    onNumberChange={handleNumberChange}
                />
            </FormView.CardSection>
            <FormView.CardSection>
                <FormView.Button
                    disabled={disabled || isSuccess}
                    mix="qa_team-form-submit-button"
                    onClick={onSubmit}
                    progress={isLoading}
                >
                    <FormattedMessage id={textIds.button} />
                </FormView.Button>
                {additionalButton}
                {shouldShowPersonalDataLink && (
                    <FormView.CardDescription mix={b('pd')}>
                        <PersonalAgreement
                            buttonText={submitButtonText}
                            linksConfig={[
                                PersonalAgreement.personalAgreementLinks.teacherPdLink,
                                PersonalAgreement.personalAgreementLinks.olympLink,
                            ]}
                        />
                    </FormView.CardDescription>
                )}
            </FormView.CardSection>
            {bottomSection && <FormView.Footer mix={b('footer-section')}>{bottomSection}</FormView.Footer>}
        </HeadedFormViewCard>
    );
};
