import { parseUrl } from 'query-string';

import { Pixel } from '../pixels/types';

interface PixelConfig {
    url: string;
    referer?: Array<string>;
    utmSource?: Array<string>;
}

export const getHost = (url: string) => {
    return parseUrl(url).url;
};

export const getPixels = (pixels: Record<string, PixelConfig>): Record<string, Pixel> => {
    const pixelIds = Object.keys(pixels);
    return pixelIds.reduce(
        (res: Record<string, Pixel>, pixelId: string) => {
            const pixel = pixels[pixelId];
            res[pixelId] = {
                id: pixelId,
                pixelUrl: pixel.url,
                referers: pixel.referer,
                utmSources: pixel.utmSource,
                host: getHost(pixel.url),
            };

            return res;
        },
        {} as Record<string, Pixel>
    );
};

export function prepareTankerMessageValue(value: string) {
    if (typeof value === 'undefined' || value === null) {
        return value;
    }
    return value.replace(/&nbsp;/g, '\u00A0').replace(/&mdash;/g, '\u2014');
}

export function processTankerMessages(tankerMessages?: Record<string, string>) {
    if (!tankerMessages) {
        return {};
    }

    const entries = Object.entries(tankerMessages).map(([key, value]) => {
        return [key, prepareTankerMessageValue(value)];
    });

    return entries.reduce<Record<string, string>>((acc, [key, value]) => {
        acc[key] = value;

        return acc;
    }, {});
}
