import { getDay, isWeekend, isFriday, set, addMinutes, addDays } from 'date-fns';

import { LESSON_DATES, WEEK_DAYS } from 'common.components/constants';
import { DateFunction } from 'utils/lesson/lesson-time/types';

import { getAfternoon, fridayAfternoon } from './getFridayAfternoon';
import { mondayMorning, nextMondayMorning } from './getMondayMorning';

export const LESSON_TIME_MARKS: Record<LESSON_DATES, DateFunction | null> = {
    // для вычисления этих дат используется серверное время - момент "после выдачи" нельзя определить на клиенте
    [LESSON_DATES.AFTER_ASSIGN]: (now: Date) => now,
    [LESSON_DATES.IN_FIFTEEN_MINUTES]: (now: Date) => {
        return addMinutes(now, 15);
    },
    // для вычисления этих дат используется клиентское время - за название "скрывается" точная дата и время
    [LESSON_DATES.TODAY_MORNING]: () => getMorning(new Date()),
    [LESSON_DATES.NEXT_WEEK_MONDAY]: () => mondayMorning(new Date()),
    [LESSON_DATES.MONDAY_IN_TWO_WEEKS]: () => nextMondayMorning(new Date()),
    [LESSON_DATES.TODAY_AFTERNOON]: () => getAfternoon(new Date()),
    [LESSON_DATES.FRIDAY_AFTERNOON]: () => fridayAfternoon(new Date()),
    [LESSON_DATES.TOMORROW_MORNING]: () => {
        const tomorrow = addDays(new Date(), 1);

        return getMorning(tomorrow);
    },
    // заглушка, custom не вычисляется по текущей дате
    [LESSON_DATES.CUSTOM]: (now: Date) => now,
    [LESSON_DATES.NONE]: null,
};

export const getMorning = (date: Date) => {
    return set(date, {
        hours: 8,
        minutes: 30,
        seconds: 0,
    });
};

export const getIsMondayMorning = (now: Date) => {
    return getDay(now) === WEEK_DAYS.MONDAY && now < getMorning(now);
};

export const getIsWeekend = (now: Date): boolean => {
    const isFridayAfternoon = isFriday(now) && now >= getAfternoon(now);

    // после обеда пятницы или в выходные
    return isWeekend(now) || isFridayAfternoon;
};

export const getLessonDateTime = (now: Date, lessonDate?: LESSON_DATES): Date | null => {
    const dateModifier = lessonDate ? LESSON_TIME_MARKS[lessonDate] : null;

    return dateModifier ? dateModifier(now) : null;
};

export default getLessonDateTime;
export { default as fridayAfternoon } from './getFridayAfternoon';
export { mondayMorning, nextMondayMorning } from './getMondayMorning';
export { default as roundDateTime } from './roundDateTime';
export { default as createTimeoutModifier } from './createTimeoutModifier';
