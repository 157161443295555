import { useStaticUrls } from 'platform-components';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router';

import AddChildForm from 'common.components/add-child-form/add-child-form';
import { getIsParentRegistrationAvailable } from 'common.components/spec-project.common/selectors';

import RegistrationWrapper from '../__wrapper/parent-registration__wrapper';

export const ParentRegistrationOptions = () => {
    const staticUrls = useStaticUrls();
    const history = useHistory();
    const { search } = useLocation();
    const isParentRegistrationAvailable = useSelector(getIsParentRegistrationAvailable);

    if (!isParentRegistrationAvailable) {
        return <Redirect to={`${staticUrls.parentRegistrationByCode}${search}`} />;
    }

    return (
        <RegistrationWrapper textId="parentRegistration.welcomeText">
            <AddChildForm
                handleAddByCode={() => history.push(`${staticUrls.parentRegistrationByCode}${search}`)}
                handleCreateChild={() => history.push(`${staticUrls.parentRegistrationCreateChild}${search}`)}
                mix="parent-registration__form"
            />
        </RegistrationWrapper>
    );
};
