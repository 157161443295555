import b from 'b_';
import cx from 'classnames';
import { Button, Link, SimpleText } from 'platform-components';
import PropTypes from 'prop-types';
import React from 'react';

import Logo from 'common.components/logo/logo';
import User from 'common.components/user/user';

import './index__header.scss';

const SCROLL_LIMIT = 50;

class IndexHeader extends React.Component {
    static propTypes = {
        mix: PropTypes.string,
        signInStudent: PropTypes.func.isRequired,
        signInAdult: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {};

        this.toggleFilled();
    }

    componentDidMount() {
        window.addEventListener('scroll', this.toggleFilled, { passive: true });
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.toggleFilled, { passive: true });
    }

    toggleFilled = () => {
        const { filled } = this.state;

        if (!isSSR) {
            if (window.pageYOffset > SCROLL_LIMIT) {
                if (!filled) {
                    this.setState({ filled: true });
                }
            } else if (filled) {
                this.setState({ filled: false });
            }
        }
    };

    handleSignInStudentClick = () => {
        const { signInStudent } = this.props;

        signInStudent();
    };

    handleSignInAdultClick = () => {
        const { signInAdult } = this.props;

        signInAdult();
    };

    handleMenuClick = ({ external, href }) => {
        if (external) {
            window.open(href, '_blank', 'noopener');
        } else {
            window.location.href = href;
        }
    };

    render() {
        const { mix } = this.props;
        const { showMainMenu, filled, mainMenuItems } = this.state;

        return (
            <div className={cx(b('index', 'header', { filled }), mix)}>
                <Logo mix="index__header-logo" />
                <div className="index__header-main-menu">
                    {showMainMenu &&
                        mainMenuItems.map((item, itemIdx) => (
                            <Link
                                key={`main-menu-link-${itemIdx}`}
                                mix={b('index', 'header-link', { external: item.external })}
                                onClick={() => {
                                    this.handleMenuClick(item);
                                }}
                                pseudo
                                theme={Link.THEME.STEALTHY}
                            >
                                {item.label}
                            </Link>
                        ))}
                </div>
                {!filled && <User mix="index__header-user" />}
                {filled && (
                    <div className="index__header-user-menu">
                        <Button className="index__header-button" onClick={this.handleSignInAdultClick}>
                            <SimpleText id="header.signInAdult" />
                        </Button>
                        <Button
                            className="index__header-button"
                            onClick={this.handleSignInStudentClick}
                            view={Button.VIEW.SECONDARY}
                        >
                            <SimpleText id="header.signInStudent" />
                        </Button>
                    </div>
                )}
            </div>
        );
    }
}

export default IndexHeader;
