import * as React from 'react';
const SvgPlay = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 12 14" fill="none" {...props}>
        <path
            d="M1.843.177A1.222 1.222 0 00.166.588C.058.771 0 .978 0 1.191v11.618c0 .212.058.42.166.603.11.183.266.333.453.437a1.223 1.223 0 001.224-.026l9.582-5.809c.175-.107.32-.256.421-.433a1.178 1.178 0 00-.421-1.596L1.843.177z"
            fill="currentColor"
        />
    </svg>
);
export default SvgPlay;
