import b from 'b_';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect, useState } from 'react';
import 'string.prototype.matchall/auto';
import { v4 as uuid } from 'uuid';

import Formula from '../formula/formula';
import InlineInput from '../inline-input/inline-input';
import { PortalsRenderer } from '../portals-renderer/portals-renderer';

import { InputIdOptions, FractionFormulaProps } from './types';

import './fraction-formula.scss';

// Определяет что КМ должен ввести для отображения в формуле инпута, сейчас {input:1}
const inlineInputRegEx = /{input:(\d+)}/g;

const defaultSettings = {
    portalRootRegExStringBase: '\\htmlId{([^{}]+)}',
    createPortalRootQuerySelector: (matchId: string) => `#${matchId}`,
    getMatchIdFromRegExpMatchArray: ([, matchGroupValue]: RegExpMatchArray) => matchGroupValue,
};

export const FractionFormula = ({ code, mdState, ...props }: FractionFormulaProps) => {
    const [uid] = useState(uuid);
    const [inputById, setInputById] = useState<{ [replacedId: string]: string }>({});
    const [katexCode, setKatexCode] = useState<string>(code);

    const generateInputId = useCallback(
        ({ inputId, formulaId, matchPosition }: InputIdOptions) =>
            `replacedInput-${uid}-${inputId}-${formulaId}-${matchPosition}`,
        [uid]
    );

    // Функция \htmlId{id_attribute_to_markup} добавит атрибут id
    const portalsRendererReplacer = useCallback(
        (options: InputIdOptions) => `\\htmlId{${generateInputId(options)}}{}`,
        [generateInputId]
    );

    useEffect(() => {
        const formulaId = mdState.id;
        const matches = Array.from(code.matchAll(inlineInputRegEx));

        const newInputById = matches.reduce<{
            [replacedId: string]: string;
        }>((acc, match) => {
            const [, inputId] = match;
            const { index: matchPosition } = match;
            const replacedInputId = generateInputId({ inputId, formulaId, matchPosition });

            acc[replacedInputId] = inputId;
            return acc;
        }, {});

        setInputById(newInputById);

        setKatexCode(
            code.replace(inlineInputRegEx, (_, inputId, matchPosition) =>
                portalsRendererReplacer({
                    inputId,
                    formulaId,
                    matchPosition,
                })
            )
        );
    }, [code, generateInputId, mdState.id, portalsRendererReplacer]);

    const renderPortalContentByMatch = useCallback(
        (replacedInputId: string) => {
            const inputId = inputById[replacedInputId];
            const {
                id,
                options,
                key,
                InputWrapper,
                answer_status: answerStatus = {},
                answer,
                theme: markerTheme,
                noAttemptsLeft,
                onInputPressEnter,
                readOnly,
                setInputAnswer,
                showCorrectAnswer,
                statusMode,
                showCurrentAnswersStatus,
                userAnswer,
                onInputClick,
            } = mdState;

            const onClick = (event: React.MouseEvent) => {
                event.stopPropagation();
                onInputClick(event, inputId);
            };

            const inputData = options.inputs[inputId] || {};

            const Input = (
                <InlineInput
                    {...{
                        ...inputData,
                        answer: answer && answer[inputId],
                        groupCorrect: answerStatus[inputData.group],
                        id: inputId,
                        key,
                        mdState,
                        markerTheme,
                        noAttemptsLeft,
                        onInputPressEnter,
                        readOnly,
                        setInputAnswer,
                        showCorrectAnswer,
                        statusMode,
                        showCurrentAnswersStatus,
                        userAnswer: userAnswer[inputId],
                    }}
                />
            );
            return InputWrapper ? (
                <InputWrapper group={inputData.group} id={inputId} key={key} markerId={Number(id)} onClick={onClick}>
                    {Input}
                </InputWrapper>
            ) : (
                Input
            );
        },
        [inputById, mdState]
    );

    const appendFractionalStyle = !isEmpty(inputById);

    const [katexReady, setKatexReady] = useState(false);

    return (
        <PortalsRenderer
            // если katex загружен можно рисовать порталы
            code={katexReady ? katexCode : ''}
            renderPortalContentByMatch={renderPortalContentByMatch}
            {...defaultSettings}
        >
            <Formula
                className={b('fraction-formula', { 'with-fraction': appendFractionalStyle })}
                code={katexCode}
                onReady={setKatexReady}
                {...props}
            />
        </PortalsRenderer>
    );
};
