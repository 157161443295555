import chunk from 'lodash/chunk';

export function preFormat(inputValue: string): string {
    const regExp = /(^|\s)(\d{5,})/g;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function replacer(match: string, g1: any, g2: any): string {
        return g1 + '{' + g2 + '}';
    }

    return inputValue.replace(regExp, replacer);
}

export function addThousandsSeparators(number: string, separator = '\u00A0'): string {
    // '\u00A0' = no-break space
    const chunks = chunk(number.split('').reverse(), 3).map((chunkEl) => chunkEl.join(''));
    const result = chunks.join(separator);

    return result.split('').reverse().join('');
}
