import * as React from 'react';
const SvgClose = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
        <path
            d="M6.115 8L1.724 3.61a1.333 1.333 0 011.885-1.886L8 6.114l4.392-4.39a1.333 1.333 0 011.885 1.885L9.886 8l4.39 4.392a1.333 1.333 0 01-1.885 1.885L8 9.886l-4.39 4.39a1.333 1.333 0 01-1.886-1.885L6.114 8z"
            fill="currentColor"
        />
    </svg>
);
export default SvgClose;
