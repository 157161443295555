import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import { PlusSubscriptionInfo } from '@yandex-int/k-common/typings/api/models/plus-subscription';

export interface PlusSubscriptionStoreState {
    loadingStatus: LOADING_STATUSES;
    subscriptionInfo: PlusSubscriptionInfo | null;
}

export interface ReduxPlusSubscriptionStore {
    plusSubscription: PlusSubscriptionStoreState;
}

export enum PLUS_SUBSCRIPTION_TYPES {
    PLUS = 'plus',
    FREE = 'free',
}
