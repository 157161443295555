import b from 'b_';
import cx from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';

import './inline-input_type_separator.scss';
import { KEY_CODES } from '../../constants';
import { INLINE_INPUT_DISPLAY_TYPES } from '../constants';

const NEW_LINE_CHOICE = 'new-line';

class InlineInputTypeSeparator extends React.Component {
    static propTypes = {
        answer: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        className: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        onKeyDown: PropTypes.func,
        options: PropTypes.shape({
            choices: PropTypes.array.isRequired,
            type_display: PropTypes.string, // eslint-disable-line camelcase
        }).isRequired,
        readOnly: PropTypes.bool,
        setAnswer: PropTypes.func.isRequired,
        showCorrectAnswer: PropTypes.bool,
        theme: PropTypes.string,
        userAnswer: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    };

    constructor(props) {
        super(props);

        this.state = {
            correcting: false,
        };
    }

    componentDidMount() {
        const { setAnswer, userAnswer } = this.props;
        const userAnswerAsNumber = typeof userAnswer === 'number' ? userAnswer : null;

        setAnswer(userAnswerAsNumber);
    }

    getInputValue = ({ answer: correctAnswer, userAnswer, showCorrectAnswer }) => {
        let filteredUserAnswer = userAnswer;

        // по умолчанию работаем как с видимым
        const typeDisplay = get(this.props, 'options.type_display', INLINE_INPUT_DISPLAY_TYPES.VISIBLE);

        if (typeof userAnswer !== 'number') {
            if (typeDisplay === INLINE_INPUT_DISPLAY_TYPES.INVISIBLE) {
                filteredUserAnswer = 0;
            }

            if (typeDisplay === INLINE_INPUT_DISPLAY_TYPES.VISIBLE) {
                filteredUserAnswer = null;
            }
        }

        return showCorrectAnswer ? correctAnswer : filteredUserAnswer;
    };

    handleClick = () => {
        const {
            options: { choices },
            readOnly,
            setAnswer,
        } = this.props;
        const value = this.getInputValue(this.props);

        if (readOnly) {
            return;
        }

        const nextValue = typeof value !== 'number' || value === choices.length - 1 ? 0 : value + 1;

        setAnswer(nextValue);
    };

    handleKeyDown = (e) => {
        const { onKeyDown } = this.props;
        if (e.keyCode === KEY_CODES.SPACE) {
            this.handleClick();
        }
        onKeyDown(e);
    };

    render() {
        const {
            answer,
            className,
            options: { choices, type_display: typeDisplay },
            theme,
        } = this.props;
        const value = this.getInputValue(this.props);
        const toggleAnswer = typeof answer === 'number' && theme === 'incorrect';
        const typeDisplayWithDefaultVisible = typeDisplay || INLINE_INPUT_DISPLAY_TYPES.VISIBLE;
        const controlClass = b('inline-input', {
            'toggle-answer': toggleAnswer,
            'type-display': typeDisplayWithDefaultVisible,
        });
        const newLine = choices[value] === NEW_LINE_CHOICE;

        return (
            <span className={cx(className, controlClass)}>
                <span
                    className={b('inline-input', 'separate-control', {
                        state: choices[value],
                        'type-display': typeDisplayWithDefaultVisible,
                    })}
                    data-switchable="true"
                    onClick={this.handleClick}
                    onKeyDown={this.handleKeyDown}
                    onMouseEnter={toggleAnswer ? this.onMouseEnter : null}
                    onMouseLeave={toggleAnswer ? this.onMouseLeave : null}
                    tabIndex="0"
                />
                {newLine && <br />}
            </span>
        );
    }
}

export default InlineInputTypeSeparator;
