import { GrantRightsRequest, ParentProfileData, ParentProfile } from '../../../typings';
import { apiConfig } from '../config';
import { postFetch } from '../fetch';

export function patchGrantParentRights(data: GrantRightsRequest): Promise<ParentProfile> {
    const { apiPatchParentProfile } = apiConfig.getStaticUrls();
    const { meId, userRights } = data;

    const param: ParentProfileData = { parent_profile: { granted_rights: userRights } };

    return postFetch(`${apiPatchParentProfile}${meId}/`, param);
}
