import padEnd from 'lodash/padEnd';
import { Input, Text } from 'platform-components';
import { INPUT_SIZES, INPUT_THEMES, KEY_CODES } from 'platform-components/constants';
import { InputChangeEvent } from 'platform-components/src/typings';
import { RUS_TO_ENG_LAYOUT_EXTRA_MAP, convertString } from 'platform-components/utils';
import React from 'react';

// @ts-ignore

import './class-sharing-code.scss';

interface Props {
    code: string | null;
    onChange: (code: string) => void;
    errorMsgId: string | null;
}

export class ClassSharingCode extends React.PureComponent<Props> {
    inputRefs: Array<HTMLInputElement> = [];

    getInputSize = (index: number): number => {
        return index === 0 ? 2 : 4;
    };

    handleChange = (event: InputChangeEvent, index: number) => {
        const { code, onChange } = this.props;
        const codeParts = sliceCode(code);
        const isLastInput = index === this.inputRefs.length - 1;
        const nextInputIsEmpty = !isLastInput && !codeParts[index + 1].trim().length;
        const { value: inputValue } = event.target;

        let value = convertString(inputValue, RUS_TO_ENG_LAYOUT_EXTRA_MAP)
            .toUpperCase()
            .replace(new RegExp('[^0-9A-Z]', 'g'), '');

        if (!nextInputIsEmpty) {
            value = padEnd(value, this.getInputSize(index));
        }

        codeParts[index] = value;

        onChange(codeParts.join(''));

        if (inputValue.length === this.getInputSize(index) && !isLastInput && nextInputIsEmpty) {
            this.inputRefs[index + 1].focus();
        }
    };

    // eslint-disable-next-line complexity
    handleKeyDown = (event: KeyboardEvent, index: number) => {
        const isFirstInput = index === 0;
        const currentInput = this.inputRefs[index];
        const isLastInput = index === this.inputRefs.length - 1;

        // eslint-disable-next-line default-case
        switch (event.keyCode) {
            case KEY_CODES.ARROW_LEFT:
            case KEY_CODES.BACKSPACE: {
                if (!isFirstInput && currentInput.selectionStart === 0) {
                    const prevInput = this.inputRefs[index - 1];

                    // картетку стоит менять только после того как все стандартыне действия отработают
                    setTimeout(() => {
                        prevInput.focus();
                        prevInput.setSelectionRange(prevInput.value.length, prevInput.value.length);
                    }, 0);
                }

                break;
            }

            case KEY_CODES.ARROW_RIGHT: {
                if (!isLastInput && currentInput.selectionStart === this.getInputSize(index)) {
                    const nextInput = this.inputRefs[index + 1];

                    setTimeout(() => {
                        nextInput.focus();
                        nextInput.setSelectionRange(0, 0);
                    }, 0);
                }

                break;
            }
        }
    };

    setInputRef = (ref: HTMLInputElement, index: number) => {
        this.inputRefs[index] = ref;
    };

    getInputProps = (index: number) => {
        const { code, errorMsgId } = this.props;
        const values = sliceCode(code);
        const value = values[index].trim();

        return {
            mix: 'class-sharing-code__input',
            onChange: (event: InputChangeEvent) => this.handleChange(event, index),
            onKeyDown: (event: KeyboardEvent) => this.handleKeyDown(event, index),
            size: INPUT_SIZES.L,
            theme: INPUT_THEMES.BORDERLESS,
            valid: errorMsgId ? 'no' : 'uncertain',
            value,
            onControlRef: (ref: HTMLInputElement) => this.setInputRef(ref, index),
            maxLength: value ? this.getInputSize(index) : undefined,
            shouldHideValidationOnFocus: false,
        };
    };

    render() {
        const { errorMsgId } = this.props;

        return (
            <div className="class-sharing-code">
                <div className="class-sharing-code__inputs">
                    <Input {...this.getInputProps(0)} />
                    <span className="class-sharing-code__splitter" />
                    <Input {...this.getInputProps(1)} />
                    <span className="class-sharing-code__splitter" />
                    <Input {...this.getInputProps(2)} />
                    <span className="class-sharing-code__splitter" />
                    <Input {...this.getInputProps(3)} />
                </div>
                {errorMsgId && (
                    <div className="class-sharing-code__error">
                        <Text id={errorMsgId} />
                    </div>
                )}
            </div>
        );
    }
}

function sliceCode(code: string | null): Array<string> {
    const str = code || '';

    return [str.slice(0, 2), str.slice(2, 6), str.slice(6, 10), str.slice(10, 14)];
}

export default ClassSharingCode;
