import { withBemMod } from '@bem-react/core';
import { cnButton } from '@yandex-lego/components/Button/desktop';

import './Button_view_outline.css';

export type WithViewOutlineProps = {
    view?: 'outline';
};

export const withViewOutline = withBemMod<WithViewOutlineProps>(cnButton(), {
    view: 'outline',
});
