import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { getErrorType, getShouldShowErrorPopup } from '../selectors';
import { ERROR_TYPES } from '../start-screen.constants';

import './start-screen__error-popup.scss';

export class StartScreenErrorPopup extends React.Component {
    static propTypes = {
        errorType: PropTypes.oneOf(Object.values(ERROR_TYPES)),
        shouldShowErrorPopup: PropTypes.bool,
    };

    render() {
        const { errorType, shouldShowErrorPopup } = this.props;

        if (!shouldShowErrorPopup) {
            return null;
        }

        const errorTextId =
            errorType === ERROR_TYPES.WRONG_SCHOOL_CODE ? 'startScreen.wrongSchoolCode' : 'startScreen.wrongLogin';

        return (
            <div className="start-screen__error-popup">
                <span className="start-screen__emoji" />
                <FormattedMessage id={errorTextId} />
            </div>
        );
    }
}

const mapStateToProps = (storeState) => ({
    errorType: getErrorType(storeState),
    shouldShowErrorPopup: getShouldShowErrorPopup(storeState),
});

export default connect(mapStateToProps)(StartScreenErrorPopup);
