export enum PROCESSABLE_ERRORS {
    DEADLINE_BEFORE_ASSIGNMENT = 'date_deadline is less than date_assignment',
    NOT_ENOUGH_TIME_TO_PREPARE = 'start_deadline is less than 15 minutes',
}

export enum LESSON_MODAL_TABS {
    PROBLEMS = 'problems',
    STATS = 'stats',
}

export enum LESSON_CONTENT_MODE {
    CUSTOM_VIEW = 'custom_view',
    CUSTOM_READONLY_VIEW = 'custom_readonly_view',
    LOADING = 'loading',
    READONLY = 'readonly',
    EDIT = 'edit',
    EDIT_EMPTY = 'edit_empty',
    PROBLEM_VIEW = 'problem_view',
    CUSTOM_PROBLEM_VIEW = 'custom_problem_view',
    COPY = 'copy',
    STUDENT_RESULTS = 'student_results',
    STREAM = 'stream',
}

export enum LESSON_VIEW_MODE {
    DEFAULT = 'default',
    AFTER_COPYING_FROM_OWN = 'after_copying_from_own',
    AFTER_COPYING_AS_RNO = 'after_copying_as_rno',
}
