export function enableDevTools(): boolean | undefined {
    // @ts-ignore
    return typeof window !== 'undefined' && window?._data?.config?.enableReduxDevTools;
}

export function reduxDevTools() {
    return (
        (enableDevTools() &&
            // @ts-ignore
            window.__REDUX_DEVTOOLS_EXTENSION__ &&
            // @ts-ignore
            window.__REDUX_DEVTOOLS_EXTENSION__()) ||
        // @ts-ignore
        ((f) => f)
    );
}
