import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import { createSelector } from 'reselect';

import { ExternalLmsConnectStore } from './typings';

interface StoreStateWithExternalLmsStore {
    externalLmsConnect: ExternalLmsConnectStore;
}

const externalLmsStoreSelector = <T extends StoreStateWithExternalLmsStore>(storeState: T) =>
    storeState.externalLmsConnect;

export const getLmsByRegionList = createSelector(
    externalLmsStoreSelector,
    (state: ExternalLmsConnectStore): Array<string> => state.externalLms
);

export const getLmsLoadingStatus = createSelector(
    externalLmsStoreSelector,
    (state: ExternalLmsConnectStore): LOADING_STATUSES => state.loadingStatus
);

export const getUserExternalLmsListLoadingStatus = createSelector(
    externalLmsStoreSelector,
    (state: ExternalLmsConnectStore): LOADING_STATUSES => state.userExternalLmsListLoadingStatus
);

export const getIsLmsLoading = createSelector(
    [getLmsLoadingStatus, getUserExternalLmsListLoadingStatus],
    (lmsByRegionLoadingStatus: LOADING_STATUSES, lmsListLoadingStatus): boolean =>
        lmsByRegionLoadingStatus === LOADING_STATUSES.LOADING || lmsListLoadingStatus === LOADING_STATUSES.LOADING
);

export const getIsLmsError = createSelector(
    getLmsLoadingStatus,
    (loadingStatus: LOADING_STATUSES): boolean => loadingStatus === LOADING_STATUSES.ERROR
);

export const getShouldLoadLmsByRegionList = createSelector(
    [getLmsByRegionList, getLmsLoadingStatus],
    (externalLms: Array<string>, loadingStatus: LOADING_STATUSES): boolean =>
        externalLms.length === 0 &&
        loadingStatus !== LOADING_STATUSES.SUCCESS &&
        loadingStatus !== LOADING_STATUSES.LOADING
);

export const getUserExternalLmsList = createSelector([externalLmsStoreSelector], (state) => state.userExternalLmsList);

export const getShouldLoadUserExternalLms = createSelector(
    [getUserExternalLmsList, getUserExternalLmsListLoadingStatus],
    (list, status) => list === null && status === LOADING_STATUSES.UNSENT
);
