import { postFetch } from '../fetch';
import { apiConfig } from '../config';

interface DiagnosticUnassignedRequest {
    courseLessonLinkId: number;
}

export function postDiagnosticUnassigned(
    request: DiagnosticUnassignedRequest
): Promise<void> {
    const { apiPostDiagnosticUnassigned } = apiConfig.getStaticUrls();

    return postFetch(apiPostDiagnosticUnassigned, request);
}
