/*
 * Есть необходимость использовать специфичные для бандлов (lab, lesson, ...)
 * переменные за пределами компонентов (например в отдельном сетевом слое src/client/api)
 *
 * Этот синглтон хранит указанные перменные и получает их при маунте бандла
 */
class ApiConfig {
    staticUrls = [];
    secretKey = '';

    setStaticUrls(staticUrls) {
        this.staticUrls = staticUrls;
    }

    getStaticUrls() {
        return this.staticUrls;
    }

    setSecretKey(secretKey) {
        this.secretKey = secretKey;
    }

    getSecretKey() {
        return this.secretKey;
    }
}

export const apiConfig = new ApiConfig();

