import cx from 'classnames';
import { SimpleCallback, useAppContext, useRouterPath, useStaticUrls } from 'platform-components';
import React from 'react';
import { useSelector } from 'react-redux';

import { ConnectMesButton, ConnectTeacherCodeButton } from 'common.components/connect-external-button';
import { INTEGRATION_SCENARIO } from 'common.components/constants';
import { getSchoolId } from 'common.components/user/selectors';

import './team-builder__external-buttons.scss';

interface Props {
    isCollegeTeam?: boolean;
    shouldShowConnectMesControl: boolean;
    shouldShowCodeControl: boolean;
    onCodeControlClick?: SimpleCallback;
}

const BUTTON_CLS = 'team-builder__external-button';

export const TeamBuilderConnectExternalButtons = (props: Props) => {
    const { isCollegeTeam, shouldShowConnectMesControl, shouldShowCodeControl, onCodeControlClick } = props;

    const schoolId = useSelector(getSchoolId);
    const { basePath, prefix } = useAppContext();
    const { labClasses } = useStaticUrls();
    const routerPath = useRouterPath(labClasses);

    const retpath = `${basePath}${prefix}${routerPath}`;

    const buttons: JSX.Element[] = [];

    if (shouldShowConnectMesControl) {
        buttons.push(
            <ConnectMesButton
                buttonTextId="addClass.fromMes"
                isAsyncClassImport={true}
                key="mes"
                mix={cx(BUTTON_CLS, 'team-builder__connect-mes-button')}
                rejectRetpath={retpath}
                retpath={retpath}
                scenario={INTEGRATION_SCENARIO.ADD_CLASS}
                schoolId={schoolId}
            />
        );
    }

    if (shouldShowCodeControl && onCodeControlClick) {
        buttons.push(
            <ConnectTeacherCodeButton
                buttonTextId={isCollegeTeam ? 'addClass.hasGroupCode' : 'addClass.hasCode'}
                key="teacher-code"
                mix={cx(BUTTON_CLS, 'team-builder__footer-section-link', 'qa_add-team-by-code')}
                onClick={onCodeControlClick}
            />
        );
    }

    return <>{buttons}</>;
};
