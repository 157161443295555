import { Button, SimpleText } from 'platform-components';
import { useAppContext } from 'platform-components/src/components/app-factory/context';
import React from 'react';
import { useLocation } from 'react-router';
import ym from 'react-yandex-metrika';

import { getAuthPath } from '../../../utils/get-auth-path';

interface UserLoginProps {
    retpath?: string;
}

const UserLogin = (props: UserLoginProps) => {
    const { retpath } = props;

    const { basePath, prefix } = useAppContext();
    const { pathname, search } = useLocation();

    const handleLogInClick = () => {
        const backpath = `${basePath}${prefix}${pathname}${search}`;

        ym('reachGoal', 'header_entrance');
        window.location.href = getAuthPath({
            retpath: retpath || `${basePath}${prefix}${pathname}${search}`,
            backpath,
        });
    };

    return (
        <Button onClick={handleLogInClick}>
            <SimpleText id="user.enter" />
        </Button>
    );
};

export default UserLogin;
