import * as React from 'react';
const SvgCancelClear = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 10c0 5.523-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0s10 4.477 10 10zm-2 0a8 8 0 11-16.001 0A8 8 0 0118 10zM5.756 7.167a1 1 0 011.415-1.414L10 8.585l2.828-2.828a1 1 0 111.414 1.414L11.415 10l2.828 2.83a1 1 0 01-1.415 1.414L10 11.414l-2.83 2.83a1 1 0 01-1.414-1.414L8.586 10l-2.83-2.833z"
            fill="currentColor"
        />
    </svg>
);
export default SvgCancelClear;
