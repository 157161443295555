import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import { CourseSubscription } from '@yandex-int/k-common/typings';
import { createSelector } from 'reselect';

import { courseIdSelector } from 'utils/selectors/selectors';

import { SubscriptionsStore } from './typings';

const subscriptionsStateSelector = <T extends { subscriptions: SubscriptionsStore }>(store: T) => store.subscriptions;

export const getCourseActiveSubscriptions = createSelector(
    [courseIdSelector, subscriptionsStateSelector],
    (courseId, store) => {
        if (!courseId || !store.courseSubscriptions[courseId]?.subscriptions) {
            return [];
        }

        return store.courseSubscriptions[courseId].subscriptions.filter(
            (subscription: CourseSubscription) => subscription.active
        );
    }
);

export const getCourseInactiveSubscriptions = createSelector(
    [courseIdSelector, subscriptionsStateSelector],
    (courseId, store) => {
        if (!courseId || !store.courseSubscriptions[courseId]?.subscriptions) {
            return [];
        }
        return store.courseSubscriptions[courseId].subscriptions.filter(
            (subscription: CourseSubscription) => !subscription.active
        );
    }
);

export const getAvailableSubscriptions = createSelector(
    [courseIdSelector, subscriptionsStateSelector],
    (courseId, store) => {
        if (!courseId) {
            return [];
        }

        return store.availableSubscriptions[courseId]?.subscriptions || [];
    }
);

export const getAvailableSubscriptionsLoadingStatus = createSelector(
    [courseIdSelector, subscriptionsStateSelector],
    (courseId, store) => {
        if (!courseId) {
            return LOADING_STATUSES.UNSENT;
        }

        return store.availableSubscriptions[courseId]?.loadingStatus || LOADING_STATUSES.UNSENT;
    }
);
