import { connect } from 'react-redux';

import { PixelsComponent } from './pixels';
import { getPixelByRefererOrUtm } from './selectors';
import { PixelsStore } from './types';

const mapStateToProps = <T extends { pixelStore: PixelsStore }>(store: T) => ({
    pixels: getPixelByRefererOrUtm(store),
});

export const PixelsContainer = connect(mapStateToProps)(PixelsComponent);
