import chunk from 'lodash/chunk';
import React from 'react';

import KeyboardBlock from '../__block/keyboard__block';
import { Key } from '../types';

interface Props {
    keys: Key[];
    rows?: number;
    onPress: (key: Key) => void;
    track?: boolean;
}

/**
 * Клавиши для клавиатуры в несколько рядов
 */
const KeyboardGroup = ({ keys, onPress, rows = 2, track }: Props) => {
    const size = Math.ceil(keys.length / rows);
    const chunks = chunk(keys, size);

    return (
        <div className="keyboard__group">
            {chunks.map((chunkKeys, rowIndex) => (
                //@ts-ignore
                <KeyboardBlock key={rowIndex} keys={chunkKeys} onPress={onPress} track={track} />
            ))}
        </div>
    );
};

export default KeyboardGroup;
