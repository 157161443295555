import b from 'b_';
import { InputChangeEvent } from 'platform-components/common';
import React from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';

//@ts-ignore
import FormViewInput from 'common.components/form-view/__input/form-view__input';

import { isAddressValid, isFullnameValid } from './utils';

import './school-form__school-adding-controls.scss';

interface Props extends WrappedComponentProps {
    currentSchool: string;
    disabled: boolean;
    onChange: (schoolInfo: string, isValid: boolean) => void;
    onMouseEnter: (event: React.MouseEvent) => void;
    onMouseLeave: (event: React.MouseEvent) => void;
}

interface State {
    address: string;
    fullname: string;
    showAddressHelpMessage: boolean;
    showFullnameHelpMessage: boolean;
}

export class SchoolFormSchoolAddingControls extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const { address = '', fullname = '' } = this.parseSchoolInfo(props.currentSchool);

        this.state = {
            address,
            fullname,
            showAddressHelpMessage: false,
            showFullnameHelpMessage: false,
        };
    }

    parseSchoolInfo = (schoolInfo: string) => {
        if (!schoolInfo) {
            return {};
        }
        const parsed = schoolInfo.split(';');

        return { address: parsed[0], fullname: parsed[1] };
    };

    handleChangeAddress = ({ target: { value: address } }: InputChangeEvent) => {
        this.setState(
            {
                address,
                showAddressHelpMessage: !isAddressValid(address),
            },
            this.handleChange
        );
    };

    handleChangeFullname = ({ target: { value: fullname } }: InputChangeEvent) => {
        this.setState(
            {
                fullname,
                showFullnameHelpMessage: !isFullnameValid(fullname),
            },
            this.handleChange
        );
    };

    handleChange = () => {
        const { onChange } = this.props;

        onChange(this.getSchoolInfo(), this.isValid());
    };

    getSchoolInfo() {
        const { address, fullname } = this.state;

        return `${address.trim()};${fullname.trim()}`;
    }

    isValid() {
        const { address, fullname } = this.state;

        return Boolean(isAddressValid(address) && isFullnameValid(fullname));
    }

    render() {
        const { disabled, intl, onMouseEnter, onMouseLeave } = this.props;
        const { address, fullname, showAddressHelpMessage, showFullnameHelpMessage } = this.state;

        return (
            <div className="school-form__school-adding-control" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                <FormViewInput
                    disabled={disabled}
                    helpMessage={intl.formatMessage({ id: 'schoolForm.schoolAdding.addressIsRequired' })}
                    mix={b('school-form', 'school-adding-control', { position: 1 })}
                    name="school-address"
                    onBlur={this.handleChangeAddress}
                    onChange={this.handleChangeAddress}
                    placeholder={intl.formatMessage({ id: 'schoolForm.schoolAdding.address' })}
                    valid={!disabled && showAddressHelpMessage ? 'no' : 'uncertain'}
                    value={address}
                />
                <FormViewInput
                    descriptionId="schoolForm.schoolAdding.schoolExample"
                    disabled={disabled}
                    helpMessage={intl.formatMessage({ id: 'schoolForm.schoolAdding.nameIsRequired' })}
                    mix={b('school-form', 'school-adding-control', { position: 3 })}
                    name="school-fullname"
                    onBlur={this.handleChangeFullname}
                    onChange={this.handleChangeFullname}
                    placeholder={intl.formatMessage({ id: 'schoolForm.schoolAdding.schoolName' })}
                    valid={!disabled && showFullnameHelpMessage ? 'no' : 'uncertain'}
                    value={fullname}
                />
            </div>
        );
    }
}

export default injectIntl(SchoolFormSchoolAddingControls);
