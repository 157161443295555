import useLockBodyScroll from '../../utils/hooks/useLockBodyScroll';

interface Props {
    locked?: boolean;
}

const LockBodyScroll = ({ locked = true }: Props) => {
    useLockBodyScroll(locked);

    return null;
};

export default LockBodyScroll;
