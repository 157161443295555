import { postFetch } from '../fetch';
import { apiConfig } from '../config';
import { Team } from '../../../typings';

export function putAuthByCode(code: string): Promise<Team> {
    const { apiPutAuthByCode } = apiConfig.getStaticUrls();

    return postFetch(apiPutAuthByCode, {
        code,
    });
}
