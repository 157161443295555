import { postFetch } from '../fetch';

import { apiConfig } from '../config';
import { Award } from '../../../typings';

interface TakeAwardRequest {
    awardItemId: number;
}

export function postTakeAward(requestData: TakeAwardRequest): Promise<Award> {
    const { awardItemId } = requestData;
    const { apiPutTakeAward } = apiConfig.getStaticUrls();

    return postFetch(`${apiPutTakeAward}${awardItemId}/`);
}
