import b from 'b_';
import { Link } from 'platform-components';
import { mePropType } from 'platform-components/utils';
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import Header from 'common.components/header/header';

import 'education-components/dist/header/index.css';

import './error-page.scss';

class ErrorPage extends React.Component {
    static propTypes = {
        me: mePropType,
        intl: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        route: PropTypes.object.isRequired,
    };

    render() {
        const {
            intl,
            location: { search },
            route: { type },
        } = this.props;

        const {
            retpath,

            /** Если есть – заменяет дефолтный текст (кейсет index в танкере) */
            errorTextId,
        } = parse(search);

        return (
            <div className="error-page-wrapper">
                <Header mix="error-page__header" retpath={retpath} theme="white" />
                <div className="error-page">
                    <div className={b('error-page', 'picture', { type })} />
                    <div className={b('error-page', 'message', { type })}>
                        {errorTextId ? (
                            <FormattedMessage id={errorTextId} />
                        ) : (
                            <FormattedMessage
                                id={`${type}.message`}
                                values={{
                                    errorLinkText: (
                                        <Link
                                            theme={Link.THEME.PRIMARY_ON_DARK}
                                            to={intl.formatMessage({ id: `error.${type}.errorLinkUrl` })}
                                        >
                                            <FormattedMessage id={`error.${type}.errorLinkText`} />
                                        </Link>
                                    ),
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ user: { me } }) => ({ me });

export default connect(mapStateToProps)(injectIntl(ErrorPage));
