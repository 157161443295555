import get from 'lodash/get';
import qs, { stringify } from 'query-string';

import { SEARCH_PARAMS_INTEGRATION, SEARCH_PARAMS_MES } from 'common.components/constants';

export const getMesTeamsPageUrl = (params: {
    mesHost: string;
    backUrl: string;
    checkedTeamIds?: Array<string>;
    classId?: string;
}): string => {
    const { mesHost, backUrl, checkedTeamIds, classId } = params;
    const searchParams = stringify({
        ...(checkedTeamIds &&
            checkedTeamIds.length && { [SEARCH_PARAMS_MES.CHECKED_GROUP_IDS]: checkedTeamIds.join(',') }),
        ...(classId && { [SEARCH_PARAMS_MES.CLASS_ID]: classId }),
        [SEARCH_PARAMS_INTEGRATION.BACK_URL]: encodeURI(backUrl),
    });

    return `${mesHost}/partner/ya-uchebnik/transfer?${searchParams}`;
};

export const redirectToMesHomeworkPageUrl = (
    mesBackUrl: string,
    studentLink: string,
    teacherLink: string,
    materialName: string
) => {
    // mesBackUrl приходит от МЭШа закодированным
    mesBackUrl = decodeURIComponent(mesBackUrl);

    window.location.href =
        mesBackUrl +
        (mesBackUrl.includes('?') ? '&' : '?') +
        stringify({ student_link: studentLink, material_name: materialName, teacher_link: teacherLink });
};

export const getMesHost = (): string => {
    if (!isSSR) {
        return get(window, '_data.config.externalLms.mes.host');
    }
    return '';
};

export const redirectToMesTeamsPage = (backUrl: string, checkedTeamIds?: Array<string>, classId?: string): void => {
    const mesHost = getMesHost();
    if (mesHost) {
        window.location.href = getMesTeamsPageUrl({ mesHost, backUrl, checkedTeamIds, classId });
    }
};
export const getIsTeacherFromMes = (search: string) => {
    return qs.parse(search).lms === 'mes';
};
