import { addWeeks, set, setDay } from 'date-fns';

import { WEEK_DAYS } from 'common.components/constants';

import ru from 'date-fns/locale/ru';
import { DateFunction } from './types';

export const getAfternoon = (date: Date) => {
    return set(date, {
        hours: 14,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
    });
};

const getCurrentWeekFridayAfternoon = (nowDate: Date) => {
    const friday = setDay(nowDate, WEEK_DAYS.FRIDAY, { locale: ru });
    return getAfternoon(friday);
};

const getNextWeekFridayAfternoon = (nowDate: Date) => {
    return addWeeks(getCurrentWeekFridayAfternoon(nowDate), 1);
};

export const fridayAfternoon: DateFunction = (nowDate) => {
    const friday = getCurrentWeekFridayAfternoon(nowDate);

    if (nowDate > friday) {
        return getNextWeekFridayAfternoon(nowDate);
    }

    return friday;
};

export default fridayAfternoon;
