import b from 'b_';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import YouTubePlayer from 'react-youtube';

import constants from '../../design/constants.scss';

import './youtube.scss';

const VIDEO_WIDTH = constants.problemVideoWidth;
const VIDEO_HEIGHT = constants.problemVideoHeight;

const YouTube = (props) => {
    const { mix, videoId } = props;
    const wrong = !videoId;
    const className = cx(b('youtube', { wrong }), mix);

    return wrong ? (
        <div className={className}>Wrong YouTube source</div>
    ) : (
        <YouTubePlayer className={className} {...props} />
    );
};

YouTube.propTypes = {
    mix: PropTypes.string,
    videoId: PropTypes.string.isRequired,
};

YouTube.defaultProps = {
    opts: {
        height: VIDEO_HEIGHT,
        width: VIDEO_WIDTH,
        playerVars: {
            rel: 0,
            showinfo: 0,
        },
    },
};

export default YouTube;
