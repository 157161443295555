import 'education-components/dist/footer/index.css';
import 'education-components/dist/header/index.css';
import { Page, PixelsContainer } from 'platform-components';
import { awardsReducer } from 'platform-components/src/components/awards/actions';
import { plusSubscriptionReducer } from 'platform-components/src/components/plus-subscription/actions';
import { Route, Switch, Redirect } from 'platform-components/src/components/router/router';
import PropTypes from 'prop-types';
import React from 'react';

import App from 'common.components/app/app';
import childManagementReducer from 'common.components/child-management/reducer';
import { deleteCLessonReducer } from 'common.components/lesson';
import salesCodeModalReducer from 'common.components/sales-code-modal/reducer';
import schoolFormReducer from 'common.components/school-form/reducers';
import coursesTemplatesReducer from 'store/courses-templates/reducers';
import { externalLmsConnectReducer } from 'store/external-lms-connect/actions';

import { teamBuilderReducer } from '../../../../common.components/team-builder/actions';
import { indexSaga } from '../../../../sagas';
import ApplyCodePage from '../apply-code-page/apply-code-page';
import ApplyCodeReducer from '../apply-code-page/reducer';
import ErrorPage from '../error-page/error-page';
import ExternalClasses from '../external-classes/external-classes';
import { ExternalDeleteLessonContainer } from '../external-delete-lesson/external-delete-lesson.container';
import ExternalLibraryParams from '../external-library-params/external-library-params';
import { reducer as externalLmsReducer } from '../external-lms/actions';
import ExternalLms from '../external-lms/external-lms';
import ExternalRegistration from '../external-registration/external-registration';
import IndexPage from '../index/index';
import { reducer as parentRegistrationReducer } from '../parent-registration/actions';
import ParentRegistration from '../parent-registration/parent-registration';
import { PromocodePageSuccess } from '../promocode-page.success/promocode-page-success';
import { PromocodePage } from '../promocode-page/promocode-page';
import Registration from '../registration/registration';
import startScreenReducer from '../start-screen/reducer';
import subscriptionHandlerReducer from '../subscription-handler/reducers';
import SubscriptionHandler from '../subscription-handler/subscription-handler';
import { reducer as teacherRegistrationPageReducer } from '../teacher-registration-page/actions';
import teacherRegistrationReducer from '../teacher-registration/reducers';
import TeacherRegistration from '../teacher-registration/teacher-registration';

import './app.scss';

class IndexApp extends React.Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
    };

    static contextTypes = {
        store: PropTypes.object,
    };

    UNSAFE_componentWillMount() {
        const { store } = this.context;

        store.addReducers({
            childManagement: childManagementReducer,
            deleteCLesson: deleteCLessonReducer,
            teacherRegistrationPage: teacherRegistrationPageReducer,
            teacherRegistration: teacherRegistrationReducer,
            parentRegistration: parentRegistrationReducer,
            subscriptionHandler: subscriptionHandlerReducer,
            start: startScreenReducer,
            schoolForm: schoolFormReducer,
            salesCodeModal: salesCodeModalReducer,
            coursesTemplates: coursesTemplatesReducer,
            applyTeacherCode: ApplyCodeReducer,
            externalLms: externalLmsReducer,
            externalLmsConnect: externalLmsConnectReducer,
            awards: awardsReducer,
            plusSubscription: plusSubscriptionReducer,
            teamBuilder: teamBuilderReducer,
        });
    }

    render() {
        const { children } = this.props;

        return (
            <Page mix="teacher-registration-page" theme="wide">
                {children}
            </Page>
        );
    }
}

export const routes = (
    <Route
        path="/"
        render={(appProps) => (
            <App {...appProps} rootSaga={indexSaga}>
                <Route
                    path="/"
                    render={(props) => (
                        <IndexApp {...props}>
                            <Switch>
                                <Route component={ApplyCodePage} exact path="/apply-code/:code/" />

                                {/* Урлы, на которые приходят пользователи из МЭШ */}
                                <Route component={ExternalLms} exact path="/external/reg/" />
                                <Route component={ExternalRegistration} exact path="/external/:lms/registration/" />
                                {/**/}

                                {/* TODO: не используется? EDUCATION-34116 */}
                                <Route component={ExternalLibraryParams} exact path="/external/:lms/library/params/" />
                                <Route component={ExternalClasses} exact path="/external/classes/" />
                                <Route
                                    component={ExternalDeleteLessonContainer}
                                    exact
                                    path="/external/:lms/delete-lesson/"
                                />
                                {/**/}

                                <Route component={ErrorPage} exact path="/not-found/" type="not-found" />
                                <Route component={ErrorPage} exact path="/forbidden/" type="forbidden" />
                                <Route component={Registration} exact path="/registration/" />
                                <Route component={ParentRegistration} path="/parent-registration/" />
                                <Route component={TeacherRegistration} path="/teacher-registration/" />
                                <Route component={SubscriptionHandler} exact path="/mailing-list/:action/" />
                                <Route component={PromocodePage} exact path="/promocode/" />
                                <Route component={PromocodePageSuccess} exact path="/promocode/success/" />
                                <Route component={IndexPage} exact path="/" />
                                <Redirect from="*" to="/not-found/" />
                            </Switch>
                            <PixelsContainer />
                        </IndexApp>
                    )}
                />
            </App>
        )}
    />
);
