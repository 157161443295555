import b from 'b_';
import { Text } from 'platform-components';
import PropTypes from 'prop-types';
import React from 'react';
import { accessibleClick } from '@edu-frontend/modules/src/shared/lib/a11y';

import './form-view__suggest-option.scss';

export const OPTION_TYPE = {
    ACTION: 'action',
    INFO: 'info',
};

class FormViewSuggestOption extends React.Component {
    static propTypes = {
        value: PropTypes.shape({
            title: PropTypes.string,
            subtitle: PropTypes.string,
            onClick: PropTypes.func,
            type: PropTypes.oneOf(Object.values(OPTION_TYPE)),
        }),
    };

    handleClick = (e) => {
        e.stopPropagation();

        const {
            value: { onClick },
        } = this.props;

        if (onClick) {
            onClick(e);
        }
    };

    render() {
        const {
            value: { title, type, subtitle },
        } = this.props;

        if (type === OPTION_TYPE.ACTION || type === OPTION_TYPE.INFO) {
            return (
                <div className={b('form-view', 'suggest-option', { type })} {...accessibleClick(this.handleClick)}>
                    <Text>{title}</Text>
                </div>
            );
        }

        return (
            <div className="form-view__suggest-option">
                <Text>{title}</Text>
                <div className="form-view__suggest-option-subtitle">
                    <Text>{`${subtitle}`}</Text>
                </div>
            </div>
        );
    }
}

export default FormViewSuggestOption;
