export const STUDENT_NAME_MAX_LENGTH = 255;

export default function getStudentName(firstName?: string | null, lastName?: string | null): string {
    if (!firstName && !lastName) {
        return '';
    }

    return `${lastName || ''} ${firstName || ''}`.trim();
}

export function getStudentInitials(firstName?: string | null, lastName?: string | null) {
    const words = getStudentName(firstName, lastName).trim().split(' ');
    const firstLetter = words[0]?.charAt(0) || '';
    const lastLetter = words.length > 1 ? words[words.length - 1].charAt(0) : '';

    return `${firstLetter}${lastLetter}`;
}
