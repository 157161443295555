export function transform(transformation) {
    return {
        WebkitTransform: transformation,
        msTransform: transformation,
        transform: transformation,
    };
}

export function transition(trans) {
    return {
        WebkitTransition: trans,
        msTransition: trans,
        transition: trans,
    };
}

export function transformOrigin(transformOriginValue) {
    return {
        WebkitTransformOrigin: transformOriginValue,
        msTransformOrigin: transformOriginValue,
        transformOrigin: transformOriginValue,
    };
}

export function cutPxUnits(value) {
    return value && parseInt(value, 10);
}
