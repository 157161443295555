import b from 'b_';
import cx from 'classnames';
import React, { RefObject } from 'react';

import { POPUP_DIRECTIONS, POPUP_THEMES } from '../constants';
import Popup from '../popup/popup';
import { Props } from '../popup/popup';

import { TOOLTIP_THEME } from './constants';

import './tooltip.scss';

export interface TooltipProps {
    children?: React.ReactNode;
    mix?: string;
    onPopupRef: RefObject<Popup> | ((ref?: React.ReactInstance | null) => void);
    popupProps?: Props;
    theme?: TOOLTIP_THEME;
}

class Tooltip extends React.Component<TooltipProps> {
    static defaultProps = {
        popupProps: {
            direction: POPUP_DIRECTIONS.BOTTOM,
            maxWidth: 400,
            offsetTop: 5,
            theme: POPUP_THEMES.DARK,
        },
        theme: TOOLTIP_THEME.DEFAULT,
    };

    static THEME = TOOLTIP_THEME;

    render() {
        const { children, mix, onPopupRef, popupProps, theme } = this.props;

        return (
            <Popup
                autoclosable
                hasArrow
                mix={cx(b('tooltip', 'popup', { theme }), mix)}
                ref={onPopupRef}
                {...popupProps}
            >
                {children}
            </Popup>
        );
    }
}

export default Tooltip;
