export const MUSIC_SOURCE_TYPES = {
    TRACK: 'track',
    PLAYLIST: 'playlist',
};

export const MUSIC_SOURCE_TYPES_ARRAY = Object.values(MUSIC_SOURCE_TYPES);

export const MUSIC_HEIGHT = {
    SMALL: '181px',
    MEDIUM: '370px',
};
