import { withBemMod } from '@bem-react/core';
import { cnButton } from '@yandex-lego/components/Button/desktop';

import './Button_view_ghost.css';

export type WithViewGhostProps = {
    view?: 'ghost';
};

export const withViewGhost = withBemMod<WithViewGhostProps>(cnButton(), {
    view: 'ghost',
});
