import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import { CourseTemplate, FullCourseSubject } from '@yandex-int/k-common/typings';
import { createSelector } from 'reselect';

import { getCoursesSubjectsByTeam } from 'store/courses/selectors';
import { getClassIdFromProps, getGradeFromProps } from 'utils/selectors/selectors';

import {
    ClassCoursesTemplates,
    CoursesTemplatesStoreState,
    CourseTemplateWithCourseData,
    ReduxCourseTemplatesStore,
} from './typings';

const getCoursesTemplatesStore = <T extends ReduxCourseTemplatesStore>(storeState: T): CoursesTemplatesStoreState =>
    storeState.coursesTemplates;

const coursesTemplatesSelector = createSelector(getCoursesTemplatesStore, (store) => store);

const getCoursesTemplatesByClassOrGrade = createSelector(
    [coursesTemplatesSelector, getClassIdFromProps, getGradeFromProps],
    (storeState, classId, grade): ClassCoursesTemplates | null => {
        if (classId) {
            return storeState.classes[classId];
        }

        if (grade) {
            return storeState.grades[grade];
        }

        return null;
    }
);

export const getCoursesTemplatesList = createSelector(
    getCoursesTemplatesByClassOrGrade,
    (courseTemplatesByClassOrGrade: ClassCoursesTemplates): Array<CourseTemplate> => {
        if (!courseTemplatesByClassOrGrade) {
            return [];
        }

        return courseTemplatesByClassOrGrade.list;
    }
);

export const getUnassignedCoursesTemplatesList = createSelector(
    getCoursesTemplatesList,
    (list: Array<CourseTemplate>): Array<CourseTemplate> => list.filter((template) => !template.is_assigned)
);

export const getCoursesTemplatesLoadingStatus = createSelector(
    getCoursesTemplatesByClassOrGrade,
    (courseTemplatesByClassOrGrade: ClassCoursesTemplates): LOADING_STATUSES => {
        if (!courseTemplatesByClassOrGrade) {
            return LOADING_STATUSES.UNSENT;
        }

        return courseTemplatesByClassOrGrade.loadingStatus;
    }
);

export const getCoursesTemplatesWithExternalCourseData = createSelector(
    [getCoursesTemplatesList, getCoursesSubjectsByTeam],
    (
        templates: Array<CourseTemplate>,
        coursesSubjects: Array<FullCourseSubject>
    ): Array<CourseTemplateWithCourseData> => {
        return templates.map((template) => {
            const courseSubject = coursesSubjects.find(({ subject }) => subject === template.subject);

            const externalCourse = courseSubject?.external_course || null;

            return { ...template, externalCourse };
        });
    }
);
