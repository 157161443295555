import { Text } from 'platform-components';
import PropTypes from 'prop-types';
import React from 'react';

import './form-view__suggest-error.scss';

const FormViewSuggestError = ({ message }) => (
    <div className="form-view__suggest-error">
        <Text size={Text.SIZE.S}>{message}</Text>
    </div>
);

FormViewSuggestError.propTypes = {
    message: PropTypes.string.isRequired,
};

export default FormViewSuggestError;
