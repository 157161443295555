import { Spin, useStaticUrls } from 'platform-components';
import React from 'react';
import { useMount } from 'react-use';

export const TeacherRegistration = () => {
    const staticUrls = useStaticUrls();

    useMount(() => {
        window.location.href = staticUrls.labLibraryMain;
    });

    return <Spin center={true} />;
};

export default TeacherRegistration;
