import { LessonProblem } from '@yandex-int/k-common/typings';
import { createSelector } from 'reselect';

import { getCurrentYearCourseId } from 'common.components/course/selectors';
import { LessonInfo, ProblemInCourse, ProblemsStore } from 'store/problems/typings';

interface StoreStateWithProblems {
    problems: ProblemsStore;
}

interface PropsWithStackId {
    stackId: number | null;
}

const storeStateSelector = <T extends StoreStateWithProblems>(storeState: T): ProblemsStore => storeState.problems;
const getStackIdFromProps = (_storeState: any, ownProps: PropsWithStackId): number | null => {
    if (ownProps && ownProps.stackId) {
        return Number.isNaN(ownProps.stackId) ? null : ownProps.stackId;
    }

    return null;
};

export const getProblemsLoadingStatus = createSelector(storeStateSelector, (state) => state.problemsLoadingStatus);

const getAllProblems = createSelector(
    storeStateSelector,
    (state: ProblemsStore): Record<number, Array<LessonProblem>> => state.problems || {}
);

export const getProblemsInCourse = createSelector(
    [getCurrentYearCourseId, storeStateSelector],
    (courseId: number | null, state: ProblemsStore): Record<number, Array<LessonInfo>> => {
        if (!courseId) {
            return [];
        }

        const all = state.problemsInCourses || {};
        return all[courseId] || {};
    }
);

export const getProblemsByStackId = createSelector(
    [getAllProblems, getStackIdFromProps],
    (problems: Record<number, Array<LessonProblem>>, stackId): Array<LessonProblem> => {
        if (!stackId) {
            return [];
        }

        return problems[stackId] || [];
    }
);

export const getProblemsWithUsage = createSelector(
    [getProblemsByStackId, getProblemsInCourse],
    (problems: Array<LessonProblem>, problemsInCourse: Record<number, Array<LessonInfo>>): Array<ProblemInCourse> => {
        return problems.map((problem) => ({
            ...problem,
            ...(problemsInCourse[problem.id] ? { inCourse: problemsInCourse[problem.id] } : {}),
        }));
    }
);
