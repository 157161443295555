import { Lesson } from '@yandex-int/k-common';
import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import get from 'lodash/get';
import { LessonInfo } from 'platform-components';
import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';

import { PropsWithLessonsId } from 'common.components/lesson/selectors';
import { PrepLessonsDetails } from 'store/prep-lessons/typings';
import {
    getLessonIdFromProps,
    getCourseIdFromProps,
    getLessonsIdsFromProps,
    getThemeIdFromProps,
    PropsWithCourseId,
} from 'utils/selectors/selectors';

import { PrepLessonsStoreState } from './typings';
import { getPrepLessonsDetailsLoadingStatusKey } from './utils';

const storeStateSelector = <T extends { prepLessons: PrepLessonsStoreState }>(storeState: T) => storeState.prepLessons;

interface PropsWithThemeId {
    themeId: number;
}

export const getPrepLessons = createCachedSelector(
    [storeStateSelector, getThemeIdFromProps],
    (state, themeId): Array<Lesson> => {
        if (themeId && state?.prepLessons) {
            return state?.prepLessons[themeId] || [];
        }

        return [];
    }
)((state, props: PropsWithThemeId) => {
    return props.themeId.toString();
});

export const getPrepLessonsLoadingStatus = createCachedSelector(
    [storeStateSelector, getThemeIdFromProps],
    (state, themeId: number): LOADING_STATUSES => {
        return state.prepLessonsLoadingStatus[themeId] || LOADING_STATUSES.UNSENT;
    }
)((state, props: PropsWithThemeId) => {
    return props.themeId.toString();
});

export const getPrepLessonsDetails = createSelector(
    [storeStateSelector, getCourseIdFromProps],
    (state, courseId): PrepLessonsDetails => {
        if (courseId && state.prepLessonsDetails) {
            return state.prepLessonsDetails[courseId] || ({} as PrepLessonsDetails);
        }

        return {} as PrepLessonsDetails;
    }
);

export const getPrepLessonDetails = createSelector(
    [getPrepLessonsDetails, getLessonIdFromProps],
    (details: PrepLessonsDetails, lessonId: number) => {
        return details[lessonId];
    }
);

export const getPrepLessonsDetailsLoadingStatus = createCachedSelector(
    [storeStateSelector, getCourseIdFromProps, getLessonsIdsFromProps],
    (state, courseId, lessonsIds): LOADING_STATUSES => {
        if (!courseId || lessonsIds.length === 0) {
            return LOADING_STATUSES.UNSENT;
        }
        const key = getPrepLessonsDetailsLoadingStatusKey(courseId, lessonsIds);

        return state.prepLessonsDetailsLoadingStatus[key] || LOADING_STATUSES.UNSENT;
    }
)((state, props: { courseId: number | null } & PropsWithLessonsId) => {
    return `${props.courseId?.toString()}:${props.lessonsIds.sort().join(':')}`;
});

export const getPrepLessonsWithDetails = createCachedSelector(
    [getPrepLessons, getPrepLessonsDetails],
    (prepLessons, prepLessonsDetails): LessonInfo[] => {
        return prepLessons.map((lesson) => {
            const details = prepLessonsDetails[lesson.id];

            return {
                ...lesson,
                assignedCount: get(details, 'assignedCount', 0),
                problemsCount: details?.problemsCount,
            } as unknown as LessonInfo;
        });
    }
)((state, props: PropsWithThemeId & PropsWithCourseId) => {
    const { themeId, courseId } = props;

    return `${themeId}` + courseId ? `:${courseId}` : '';
});

export const getCanLoadMorePrepLessons = createSelector(
    [storeStateSelector, getThemeIdFromProps],
    (state, themeId): boolean => {
        return get(state, `canLoadMorePrepLessons.${themeId}`, true);
    }
);

export const getLastPrepLessonsPageLoaded = createSelector(
    [storeStateSelector, getThemeIdFromProps],
    (state, themeId) => {
        return get(state, `prepLessonsLoadedPages.${themeId}`);
    }
);
