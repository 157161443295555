import { ISODateTime, UnixTime } from '../../../common';
import { LessonResult } from '../../models';
import { CourseShort } from '../course';
import { EXTERNAL_LMS_SLUGS } from '../../models';
import { LessonProblem } from '../problems';
import { BaseCLesson } from './base-clesson';
import { WORK_TYPE, LessonMeta } from './common';
import { Lesson, LessonInCourse } from './lesson';
import { CustomFlowLessonAppearance, CustomFlowLessonStructure } from './custom-flow-lesson';

// странная фигня для Сириуса
export interface ProgressIndicator {
    slug: string;
    palette: [
        {
            progress: 0,
            color: '#000000',
        },
        {
            progress: 1,
            color: '#FFFFFF',
        },
    ]
}

export enum REWARD {
    CERTIFICATE = 'certificate',
}

export interface CertificateReward {
    date_created: ISODateTime;
    url: string;
    slug: string;
}

export type ClessonRewards = Record<REWARD, Array<CertificateReward>>;

// бэковская сущность courseLessonLink, в нее может быть вложена сущность Lesson
// если вложен в FullCLesson, то lesson-а не будет;
// если приходит как объект верхнего уровня, то будет
export interface CLesson extends BaseCLesson<Lesson | undefined> {
    is_viewed?: boolean | null;
    order?: number; // сортировочный номер занятия в курсе
    date_assignment_passed: boolean;
    date_read?: ISODateTime | null; // дата прочтения учителем
    evaluation_date: ISODateTime | null; // дата объявления результатов КР
    course: CourseShort;
    progress_indicator: ProgressIndicator | null;
    work_type: WORK_TYPE;
    rewards: ClessonRewards;
}

// бэковская сущность Lesson, некоторые ручки возвращают ее как объект верхнего уровня;
// тогда внутри будет сущность CLesson
export interface FullCLesson {
    id: number;
    name: string; // имя, которое дал пользователь
    full_name: string | null; // полное имя, настраивается в админке
    description: string | null; // описание, которое дал пользователь
    date_upated: UnixTime;
    owner: number; // userId
    clesson?: CLesson;
    meta: LessonMeta;
    problems: Array<LessonProblem>;
    appearance: CustomFlowLessonAppearance | null;
    structure: CustomFlowLessonStructure | null;
    subject?: string;
}

export interface ExternalHomework {
    lms: EXTERNAL_LMS_SLUGS;
    status: string;
    attachment_id: number;
    homework_id: number;
    homework_entry_id: number;
}

// Сериализатор данных занятия в таблице занятий
export interface CLessonInCourse extends BaseCLesson<LessonInCourse> {
    date_read: ISODateTime;
    is_viewed: boolean | null;
    order: number;
}

export interface StudentClessonResults {
    count:    number;
    next:     null;
    previous: null;
    results:  LessonResult[];
}
