import { AVATARS_THUMBNAILS, AVATARS_URL_REGEXP, ScaleStrategy, SCREEN_RESOLUTIONS } from './constants';

export const getSrcSetForResolutions = (resolutionMapper: Record<string, string>): string => {
    return Object.keys(resolutionMapper)
        .map((resolution) => `${resolutionMapper[resolution]} ${resolution}`)
        .join(', ');
};

export const getAvatarsUrlMatch = (url: string) => {
    return url.match(AVATARS_URL_REGEXP);
};

export const getAvatarsThumbnail = (url: string, thumbnail: string) => `${url}/${thumbnail}`;

const getResolutionStrategySrcMap = (src: string): Record<string, string> => ({
    [SCREEN_RESOLUTIONS.X1]: getAvatarsThumbnail(src, AVATARS_THUMBNAILS.X1),
    [SCREEN_RESOLUTIONS.X2]: getAvatarsThumbnail(src, AVATARS_THUMBNAILS.X2),
    [SCREEN_RESOLUTIONS.X3]: getAvatarsThumbnail(src, AVATARS_THUMBNAILS.X3),
    [SCREEN_RESOLUTIONS.X4]: getAvatarsThumbnail(src, AVATARS_THUMBNAILS.ORIG),
});

const getImageSizeStrategySrcMap = (src: string, width: number): Record<string, string> => ({
    [`${width}w`]: getAvatarsThumbnail(src, AVATARS_THUMBNAILS.X1),
    [`${2 * width}w`]: getAvatarsThumbnail(src, AVATARS_THUMBNAILS.X2),
    [`${3 * width}w`]: getAvatarsThumbnail(src, AVATARS_THUMBNAILS.X3),
    [`${4 * width}w`]: getAvatarsThumbnail(src, AVATARS_THUMBNAILS.ORIG),
});

export const getSrcSet = (src: string, strategy: ScaleStrategy = 'resolution', width?: number): string | null => {
    const avatarsUrlMatch = getAvatarsUrlMatch(src);

    if (!avatarsUrlMatch) {
        return null;
    }

    if (strategy === 'resolution') {
        return getSrcSetForResolutions(getResolutionStrategySrcMap(avatarsUrlMatch[0]));
    }

    if (strategy === 'imageSize' && width) {
        return getSrcSetForResolutions(getImageSizeStrategySrcMap(avatarsUrlMatch[0], width));
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let __strategy: never;
    return null;
};

export const getSrcForResolution = (src: string, resolution: SCREEN_RESOLUTIONS): string => {
    const avatarsUrlMatch = getAvatarsUrlMatch(src);

    if (avatarsUrlMatch) {
        return getResolutionStrategySrcMap(avatarsUrlMatch[0])[resolution];
    }

    return src;
};
