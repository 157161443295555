export function scrollIntoView(parent, child, options = {}) {
    const { block } = options;

    switch (block) {
        case 'start':
            parent.scrollTop = parent.offsetTop;
            return;
        case 'end':
            parent.scrollTop = child.offsetTop - parent.clientHeight + child.clientHeight;
            return;
        case 'nearest':
        default: {
            const scrollDownDiff = child.offsetTop + child.clientHeight - (parent.scrollTop + parent.clientHeight);
            const scrollUpDiff = parent.scrollTop - child.offsetTop;
            const shouldScrollDiff = 2;

            if (scrollDownDiff > shouldScrollDiff) {
                parent.scrollTop += scrollDownDiff;
            } else if (scrollUpDiff > shouldScrollDiff) {
                parent.scrollTop -= scrollUpDiff;
            }
            return;
        }
    }
}
