import * as constants from './constants';
import {
    GetCoursesTemplatesRequestData,
    GetCoursesTemplatesErrorData,
    GetCoursesTemplatesSuccessData,
    isTemplatesRequestByTeamId,
    isTemplatesRequestByGrade,
} from './typings';

export const getCoursesTemplatesRequest = (requestData: GetCoursesTemplatesRequestData) => {
    if (isTemplatesRequestByGrade(requestData)) {
        return <const>{
            type: constants.GET_COURSES_TEMPLATES_REQUEST,
            grade: requestData.grade,
            onSuccess: requestData.onSuccess,
        };
    } else if (isTemplatesRequestByTeamId(requestData)) {
        return <const>{
            type: constants.GET_COURSES_TEMPLATES_REQUEST,
            classId: requestData.classId,
            onSuccess: requestData.onSuccess,
        };
    }
    throw Error('Некорректный формат запроса за шаблонами курсов');
};

export const getCoursesTemplatesSuccess = (requestData: GetCoursesTemplatesSuccessData) =>
    <const>{
        type: constants.GET_COURSES_TEMPLATES_SUCCESS,
        coursesTemplates: requestData.coursesTemplates,
        classId: requestData.classId,
        grade: requestData.grade,
    };

export const getCoursesTemplatesError = (requestData: GetCoursesTemplatesErrorData) =>
    <const>{
        type: constants.GET_COURSES_TEMPLATES_ERROR,
        classId: requestData.classId,
        grade: requestData.grade,
    };
