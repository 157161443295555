import { PayloadAction } from '@reduxjs/toolkit';

import { YM_LOCATION } from 'utils/ym-constants';

import { LessonStore } from '../typings';

export const finishAddingStudentsRequest = (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    state: LessonStore,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    payload: PayloadAction<{
        studentsIds: Array<number> | null;
        ymLocation: YM_LOCATION;
    }>
) => {
    // saga only
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const finishAddingStudentsSuccess = (state: LessonStore) => {
    // saga only
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const finishAddingStudentsError = (state: LessonStore) => {
    // saga only
};
