import b from 'b_';
import cx from 'classnames';
import CSS from 'csstype';
import React, { forwardRef, useMemo } from 'react';

import Image, { ImageProps } from '../image/image';

import { BackgroundImageContainer } from './background-image-container';

import './background-image.scss';

export interface BackgroundImageProps extends Omit<ImageProps, 'src' | 'className'> {
    disableSrcSet?: boolean;
    src?: string | null;
    mix?: string;
    layout?: IMAGE_LAYOUT;
    objectFit?: CSS.ObjectFitProperty;
    objectPosition?: CSS.ObjectPositionProperty<string>;
}

export enum IMAGE_LAYOUT {
    STATIC = 'static',
    FILL = 'fill',
}

const BackgroundImage = forwardRef((props: BackgroundImageProps, ref?: React.Ref<HTMLImageElement>) => {
    const {
        layout = IMAGE_LAYOUT.FILL,
        mix,
        objectFit,
        objectPosition,
        style,
        src,
        disableSrcSet,
        ...restProps
    } = props;

    const styles = useMemo(
        () => ({
            ...style,

            '--background-image-object-fit': objectFit,
            '--background-image-object-position': objectPosition,
        }),
        [objectFit, objectPosition, style]
    );

    if (!src) {
        return null;
    }

    return (
        <Image
            alt="" // Чтобы скринридер не считывал картинку
            {...restProps}
            className={cx(b('background-image', { layout }), mix)}
            disableSrcSet={disableSrcSet}
            ref={ref}
            src={src}
            style={styles}
        />
    );
});

const BackgroundComponents = Object.assign(BackgroundImage, {
    LAYOUT: IMAGE_LAYOUT,
    Container: BackgroundImageContainer,
});

export { BackgroundComponents as BackgroundImage };
