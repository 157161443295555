import { BackspaceIcon } from 'education-icons';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export function createSimpleKeyItem(value, content = value) {
    return {
        value,
        content,
    };
}

export const NUMPAD = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map(String).map((item) => createSimpleKeyItem(item));

export const COMPARATORS = ['>', '<', '=', '≥', '≤'].map((item) => createSimpleKeyItem(item));

export const OPERATORS = {
    plus: createSimpleKeyItem('+'),
    minus: createSimpleKeyItem('-', '\u2212'),
    multiply: createSimpleKeyItem('*', '\u00D7'),
    divide: createSimpleKeyItem('/', '\u00F7'),
};

export const BACKSPACE = [
    {
        value: 'Backspace',
        content: <BackspaceIcon />,
        width: 'wide',
        mode: 'backspace',
    },
];

export const DECIMAL_SEPARATOR = [
    {
        value: ',',
        content: ',',
        mode: 'decimal',
    },
];

export const ARROW_NEXT = [
    {
        value: 'Enter',
        content: <FormattedMessage id="keyboard.key_next" />,
        width: 'wide',
        mode: 'yellow',
    },
];

const LEFTWARDS_ARROW = '\u2190'; // ←
export const ARROW_BACK = [
    {
        value: -1,
        displayName: LEFTWARDS_ARROW,
    },
];

export const ARROWS = [...ARROW_BACK, ...ARROW_NEXT];
