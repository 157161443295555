import { useCallback } from 'react';
import ym from 'react-yandex-metrika';

export type GoalParams = Record<string, string | number | object | undefined>;

export function useMetrika(defaultParams?: GoalParams) {
    const sendGoal = useCallback(
        (goal: string, params?: GoalParams) => {
            ym('reachGoal', goal, { ...defaultParams, ...params });
        },
        [defaultParams]
    );

    return { sendGoal };
}
