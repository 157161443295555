import { EXTERNAL_LMS_SLUGS } from '@yandex-int/k-common';
import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import { postCreateTeams } from '@yandex-int/k-common/client/api/defs/post-create-teams';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { ExternalLmsErrorMessages } from '@yandex-int/k-common/typings/external';
import { actions } from './actions';

export function* externalLmsSaga() {
    yield all([takeLatest(actions.createMesClass, createMesClassSaga)]);
}

function* createMesClassSaga(action: ReturnType<typeof actions.createMesClass>) {
    const { externalClassId, subjectId, classLevelId, onSuccess, onError } = action.payload;

    try {
        const createTeamsData: { team_ids: Record<string, number> } | undefined = yield call(
            postCreateTeams,
            [externalClassId],
            EXTERNAL_LMS_SLUGS.MES,
            subjectId,
            classLevelId
        );

        yield put(actions.setCreateMesClassLoadingStatus(LOADING_STATUSES.SUCCESS));

        const createdExternalClassId = createTeamsData?.team_ids?.[externalClassId];

        if (!createdExternalClassId) {
            throw ReferenceError('createdExternalClassId is not defined');
        }

        onSuccess(createdExternalClassId);
    } catch (error) {
        console.error(error);
        if (error.status === 409) {
            // Эту ошибку мы показываем, если МЭШ привязан к паспорту, но id урока принадлежит другому паспорту
            onError(ExternalLmsErrorMessages.duplicateClass);
        } else if (error.status === 400) {
            // других 400 не может быть
            // ошибка несоответствия грейда с предметом
            onError(ExternalLmsErrorMessages.mismatchGradeWithSubject);
        } else {
            onError();
        }
        yield put(actions.setCreateMesClassLoadingStatus(LOADING_STATUSES.ERROR));
    }
}
