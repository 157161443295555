import { FullCLesson, LessonProblem } from '@yandex-int/k-common/typings';
import { showNetworkError } from 'platform-components';
import { reportMessage } from 'platform-components/utils';
import { put, select } from 'redux-saga/effects';

import { getTeam } from 'common.components/classes/actions';
import { getStudents } from 'common.components/classes/selectors';
import { getCurrentYearCourseId } from 'common.components/course/selectors';
import { toggleLessonNotification } from 'common.components/lesson-view.store/actions';
import lessonActions from 'common.components/lesson/actions';
import * as lessonSelectors from 'common.components/lesson/selectors';
import { getTeamIdFromPath } from 'utils/path';

import { LESSON_EDIT_ERRORS } from '../typings';

// eslint-disable-next-line complexity
export function* handleDeleteOrEditError(error: any, clessonId: number) {
    switch (error.status) {
        case 400:
            if (!error.data.code) {
                yield put(showNetworkError(error));
            } else if (error.data.code === LESSON_EDIT_ERRORS.LESSON_UNPUBLISHED) {
                // Урок уже удалили/распубликовали
                yield put(toggleLessonNotification(true, 'lesson.failedDeleteNotification.deleted'));
            } else if (error.data.code === LESSON_EDIT_ERRORS.LESSON_HAS_ANSWERS) {
                // Урок начали проходить
                yield put(toggleLessonNotification(true, 'lessonView.notification.lessonCannotBeModified'));
                const courseId: number = yield select(getCurrentYearCourseId);
                yield put(lessonActions.getClessonWithResultsRequest({ courseId, clessonId }));
                yield put(lessonActions.lessonUpdated());
            }

            break;
        case 404: // Урок не найден
            yield put(toggleLessonNotification(true, 'lesson.failedDeleteNotification.notfound'));

            break;
        case 409:
            if (error.data?.course === 'Contains lesson copy already') {
                yield put(toggleLessonNotification(true, 'lessonView.notification.alreadyAssigned'));
            } else {
                yield put(showNetworkError(error));
            }

            break;
        default:
            yield put(showNetworkError(error));

            break;
    }
}

export function* mergeClesson(clesson: FullCLesson) {
    const clessonData: FullCLesson = yield select(lessonSelectors.getLesson);
    const changedClesson = {
        ...clesson,
        // поля, которые могли измениться
        problems: clessonData.problems.map((lessonProblem) => {
            const cLessonProblem = clesson.problems?.find(
                (problem) => problem.problem?.id === lessonProblem.problem?.id
            );

            // в карточках при копировании меняется problemLessonLink, поэтому id нужно взять у копии-clesson,
            // а все остальное(могло измениться при редактировании) из оригинала-lesson
            return {
                ...lessonProblem,
                id: cLessonProblem?.id,
            } as LessonProblem;
        }),
        name: clessonData.name || clesson.name,
        description: clessonData.description || clesson.description,
    };

    if (clessonData?.clesson?.mode) {
        changedClesson.clesson!.mode = clessonData?.clesson.mode;
    }

    yield put(lessonActions.setLesson({ lesson: changedClesson }));
}

export function* loadTeam() {
    const teamId = getTeamIdFromPath();
    const students: ReturnType<typeof getStudents> = yield select(getStudents, { classId: teamId });

    if (!students || !students.length) {
        if (!teamId) {
            reportMessage('Ошибка запроса за классом: отсутствует id');

            return;
        }

        yield put(getTeam(teamId));
    }
}
