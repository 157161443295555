import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import produce from 'immer';

import * as constants from './constants';
import { ApplyCodeAction, StoreType } from './typings';

export const initialState: StoreType = {
    loadingStatus: LOADING_STATUSES.UNSENT,
};

const startScreenReducer = (state = initialState, action: ApplyCodeAction) => {
    return produce(state, (draft: StoreType) => {
        switch (action.type) {
            case constants.AUTH_BY_CODE_REQUEST:
                draft.loadingStatus = LOADING_STATUSES.LOADING;
                break;
            case constants.AUTH_BY_CODE_SUCCESS:
                draft.loadingStatus = LOADING_STATUSES.SUCCESS;
                break;
            case constants.AUTH_BY_CODE_ERROR:
                draft.loadingStatus = LOADING_STATUSES.ERROR;
                break;

            default:
                const _exhaustiveCheck: never = action;
                break;
        }
    });
};

export default startScreenReducer;
