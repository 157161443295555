import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { LOADING_STATUSES } from '@yandex-int/k-common/client/api/constants';
import b from 'b_';
import cx from 'classnames';

import { getAvailableClassNumbers } from '../user/selectors';
import { getIsCollegeTeamUtil } from '../user/utils';
// @ts-ignore js-file
import NumberEditor from '../class-number-selector/class-number-selector';

// @ts-ignore js-file
import HelpMessage from './__help-message/class-name-editor__help-message';
// @ts-ignore js-file
import LetterEditor from './__letter-editor/class-name-editor__letter-editor';
import { letterEditorViewMap, numberEditorModeMap, numberEditorViewMap } from './constants';
import { CLASSNAME_EDITOR_MODE, CLASSNAME_EDITOR_VIEW } from './types';
import { getLetterErrorMessage, getNumberErrorMessage } from './utils';

import './class-name-editor.scss';

interface Props {
    number?: number | null;
    letter?: string | null;

    disabledNumber?: boolean;
    disabledLetter?: boolean;
    onNumberChange: (number: number) => void;
    onLetterChange: (letter: string) => void;

    isCollegeTeam?: boolean;
    nameSettingStatus?: LOADING_STATUSES;
    mode?: CLASSNAME_EDITOR_MODE;
    view?: CLASSNAME_EDITOR_VIEW;
    mix?: string | string[];
}

/** Ввод грейда и название класса/группы */
// eslint-disable-next-line complexity
export const ClassNameEditorControl = ({
    number,
    letter,

    disabledNumber,
    disabledLetter,
    onNumberChange,
    onLetterChange,

    isCollegeTeam,
    nameSettingStatus,
    view = CLASSNAME_EDITOR_VIEW.DEFAULT,
    mode = CLASSNAME_EDITOR_MODE.CREATE,
    mix,
}: Props) => {
    const intl = useIntl();

    const [shouldShowNumberHelp, setShouldShowNumberHelp] = useState(false);
    const [shouldShowLetterHelp, setShouldShowLetterHelp] = useState(false);

    const handleBlur = () => {
        setShouldShowNumberHelp(true);
        setShouldShowLetterHelp(!getNumberErrorMessage(number));
    };

    const handleNumberEditorHide = () => {
        setShouldShowNumberHelp(true);
        setShouldShowLetterHelp(false);
    };

    const handleFocus = () => {
        setShouldShowLetterHelp(Boolean(!getNumberErrorMessage(number) && getLetterErrorMessage(letter) && letter));
    };

    const getHelpMessage = () => {
        const numberHelpMessage = getNumberErrorMessage(number);
        const letterHelpMessage = getLetterErrorMessage(letter);

        if (shouldShowNumberHelp && numberHelpMessage) {
            return intl.formatMessage({ id: numberHelpMessage.id });
        }
        if (shouldShowLetterHelp && letterHelpMessage) {
            return intl.formatMessage({ id: letterHelpMessage.id }, letterHelpMessage.values);
        }

        return null;
    };

    const loading = nameSettingStatus === LOADING_STATUSES.LOADING;

    const availableNumbers = useSelector(getAvailableClassNumbers);

    if (number && !availableNumbers.includes(number) && !getIsCollegeTeamUtil(number)) {
        console.error(`Пытаемся установить неразрешенный номер класса ${number}
                при разрешенных ${availableNumbers.join(', ')}`);
    }

    const helpMessage = getHelpMessage();

    return (
        <div className={cx(b('class-name-editor', { view }), mix)}>
            <div className="class-name-editor__controls-wrapper">
                {!isCollegeTeam && (
                    <NumberEditor
                        availableNumbers={availableNumbers}
                        disabled={disabledNumber || loading}
                        mix="class-name-editor__number-editor"
                        mode={numberEditorModeMap[mode]}
                        onChange={onNumberChange}
                        onHide={handleNumberEditorHide}
                        shouldPreventAutoopen={true}
                        valid={shouldShowNumberHelp && Boolean(getNumberErrorMessage(number)) ? 'no' : 'uncertain'}
                        value={number}
                        view={numberEditorViewMap[view]}
                    />
                )}
                <LetterEditor
                    disabled={disabledLetter || loading}
                    isCollegeTeam={isCollegeTeam}
                    onBlur={handleBlur}
                    onChange={onLetterChange}
                    onFocus={handleFocus}
                    valid={shouldShowLetterHelp && Boolean(getLetterErrorMessage(letter)) ? 'no' : 'uncertain'}
                    value={letter}
                    view={letterEditorViewMap[view]}
                />
            </div>
            <HelpMessage helpMessage={helpMessage} />
        </div>
    );
};

export default ClassNameEditorControl;
