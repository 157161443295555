import { postFetch } from '../fetch';

import { apiConfig } from '../config';

export function postClassBulkCreateStudents(classId: any, students: any) {
    const { apiPostClassBulkCreateStudents } = apiConfig.getStaticUrls();

    return postFetch(
        `${apiPostClassBulkCreateStudents}${classId}/`,
        {
            students,
        }
    );
}
