// @ts-ignore
import { PROBLEM_TYPES } from '@yandex-int/k-common/constants';
import { LessonProblem } from '@yandex-int/k-common/typings/index';
import { showIntlMessageNotification } from 'platform-components';
import { NOTIFICATION_THEMES, GRAVITIES } from 'platform-components/constants';
import { Dispatch } from 'redux';

import { COMMON_NOTIFICATION_DELAY } from 'common.components/constants';

import { slice } from './slice';

export function showProblemDeletedToast(options: { problemId: number; index: number; stackId?: number | null }) {
    const { problemId, index, stackId } = options;

    return function (dispatch: Dispatch<any>) {
        const problem = {
            type: PROBLEM_TYPES.PROBLEM,
            problem: { id: problemId },
            from_theme: stackId,
        };

        dispatch(
            showIntlMessageNotification({
                closeDelay: COMMON_NOTIFICATION_DELAY,
                gravity: GRAVITIES.BOTTOM,
                messageId: 'toast.lessonView.problemRemoved',
                notificationAction: () => {
                    dispatch(
                        slice.actions.insertProblems({
                            index,
                            problems: [problem as LessonProblem],
                        })
                    );
                    dispatch(slice.actions.saveLesson());
                },
                notificationMessageId: 'toast.lessonView.problemRemoved.restore',
                withExplicitClose: true,
                theme: NOTIFICATION_THEMES.TOAST,
            })
        );
    };
}
