import { v4 as uuid } from 'uuid';

const NAVIGATION_EVENT_ID = 'navigation-event-' + uuid();

export function dispatchNavigationEvent() {
    document.dispatchEvent(new Event(NAVIGATION_EVENT_ID));
}

export function addNavigationEventListener(listener: EventListenerOrEventListenerObject) {
    document.addEventListener(NAVIGATION_EVENT_ID, listener);
}

export function removeNavigationEventListener(listener: EventListenerOrEventListenerObject) {
    document.removeEventListener(NAVIGATION_EVENT_ID, listener);
}

export const getKeyboardGroupRows = (elemsLength: number, rowLength = 5) => {
    return Math.ceil(elemsLength / rowLength);
};
