import { withBemMod } from '@bem-react/core';
import { cnButton } from '@yandex-lego/components/Button';

import { BUTTON_VIEW } from '../types';

export interface ButtonViewSecondaryProps {
    view?: BUTTON_VIEW.SECONDARY;
}

export const withViewSecondary = withBemMod<ButtonViewSecondaryProps>(cnButton(), { view: BUTTON_VIEW.SECONDARY });
