import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

class AnimatedMountUnmount extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        mix: PropTypes.string,
        name: PropTypes.string.isRequired,
        // staticProps сохраняют пропсы на вермя всего жизненного цикла анимируемого компонента
        staticProps: PropTypes.object,
        unmountAfterAnimation: PropTypes.string.isRequired,
        visible: PropTypes.bool,
    };

    state = {
        mounted: false,
    };

    UNSAFE_componentWillReceiveProps(newProps) {
        // если анимация маунтинга компонента должна начаться
        if (newProps.visible === true && this.props.visible !== true) {
            this.setState({
                mounted: true,
                staticProps: newProps.staticProps,
            });
        }
    }

    onAnimationEnd(e) {
        const { unmountAfterAnimation } = this.props;

        // если анимация анмаутинга закончилась
        if (e.animationName === unmountAfterAnimation) {
            this.setState({
                mounted: false,
                staticProps: {},
            });
        }
    }

    render() {
        const { visible, mix, name, children } = this.props;

        return (
            <div
                className={cx(
                    {
                        [`${name}-show-animation`]: visible,
                        [`${name}-hide-animation`]: !visible,
                    },
                    mix
                )}
                onAnimationEnd={this.onAnimationEnd.bind(this)}
            >
                {this.state.mounted && React.cloneElement(children, this.state.staticProps)}
            </div>
        );
    }
}

export default AnimatedMountUnmount;
