import b from 'b_';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './button2.scss';

export const BUTTON_SIZES = {
    S: 's',
    M: 'm',
};

export const BUTTON_THEMES = {
    ACTION: 'action',
    BLUE: 'blue',
    GRAY: 'gray',
    ORANGE: 'orange',
    SOFTBLUE: 'softblue',
    LESSON: 'lesson',
};

export const BUTTON_VIEWS = {
    ICON: 'icon',
    RECTANGLE: 'rectangle',
    ROUNDED: 'rounded',

    /**
     * Чисто для a11y, чтобы обернуть элемент без каких-либо визуальных изменений
     */
    A11Y: 'a11y',
};

export class Button2 extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        disabled: PropTypes.bool,
        mix: PropTypes.string,
        size: PropTypes.oneOf(Object.values(BUTTON_SIZES)),
        theme: PropTypes.oneOf(Object.values(BUTTON_THEMES)),
        view: PropTypes.oneOf(Object.values(BUTTON_VIEWS)),
    };

    static defaultProps = {
        size: BUTTON_SIZES.M,
        theme: BUTTON_THEMES.ACTION,
        view: BUTTON_VIEWS.RECTANGLE,
    };

    static SIZES = BUTTON_SIZES;
    static THEMES = BUTTON_THEMES;
    static VIEWS = BUTTON_VIEWS;

    render() {
        const { children, disabled, mix, size, theme, view, ...otherProps } = this.props;

        const className = b(
            'button2',
            view === BUTTON_VIEWS.A11Y ? { disabled, view } : { disabled, size, theme, view }
        );

        return (
            <button className={cx(className, mix)} disabled={disabled} type="button" {...otherProps}>
                {children}
            </button>
        );
    }
}

export default Button2;
