export const TOGGLE_MODAL = 'LESSON_VIEW.TOGGLE_MODAL';
export const ASSIGN_LESSON_REQUEST = 'LESSON_VIEW.ASSIGN_LESSON_REQUEST';
export const ADD_STUDENTS_TO_LESSON_REQUEST = 'LESSON_VIEW.ADD_STUDENTS_TO_LESSON_REQUEST';
export const ADD_STUDENTS_TO_LESSON_SUCCESS = 'LESSON_VIEW.ADD_STUDENTS_TO_LESSON_SUCCESS';
export const ADD_STUDENTS_TO_LESSON_ERROR = 'LESSON_VIEW.ADD_STUDENTS_TO_LESSON_ERROR';
export const FORCE_COMPLETE_LESSON = 'LESSON_VIEW.FORCE_COMPLETE_LESSON';
export const SET_SELECTED_PROBLEM_ID = 'LESSON_VIEW.SET_SELECTED_PROBLEM_ID';
export const SET_SELECTED_STUDENT_ID = 'LESSON_VIEW.SET_SELECTED_STUDENT_ID';
export const SET_SHOULD_SHOW_ASSIGNMENT_SETTINGS = 'LESSON_VIEW.SET_SHOULD_SHOW_ASSIGNMENT_SETTINGS';
export const SET_SELECTED_TAB = 'LESSON_VIEW.SET_SELECTED_TAB';

export const TOGGLE_LESSON_NOTIFICATION = 'LESSON_VIEW.TOGGLE_LESSON_NOTIFICATION';
export const CHANGE_PROBLEM_VARIANT = 'LESSON_VIEW.CHANGE_PROBLEM_VARIANT';
export const SET_LESSON_NAME_BY_MODE = 'LESSON_VIEW.SET_LESSON_NAME_BY_MODE';

export const GET_PROBLEM_STACKS_REQUEST = 'LESSON_VIEW.GET_PROBLEM_STACKS_REQUEST';
export const GET_PROBLEM_STACKS_SUCCESS = 'LESSON_VIEW.GET_PROBLEM_STACKS_SUCCESS';
export const GET_PROBLEM_STACKS_ERROR = 'LESSON_VIEW.GET_PROBLEM_STACKS_ERROR';

export const SET_PROBLEM_SIDEPANEL_MODE = 'LESSON_VIEW.SET_PROBLEM_SIDEPANEL_MODE';

export const SET_SHOULD_ASSIGN_IN_EXTERNAL_LMS = 'LESSON_ASSIGNMENT.SET_SHOULD_ASSIGN_IN_EXTERNAL_LMS';
export const HANDLE_EXTERNAL_LMS_CHECKBOX_TOGGLE = 'LESSON_ASSIGNMENT.HANDLE_EXTERNAL_LMS_CHECKBOX_TOGGLE';
export const FORCE_SHOULD_ASSIGN_IN_EXTERNAL_LMS = 'LESSON_ASSIGNMENT.FORCE_SHOULD_ASSIGN_IN_EXTERNAL_LMS';

export const SET_MODE = 'LESSON_VIEW.SET_MODE';

export const SET_IS_HEADLESS = 'LESSON_VIEW.SET_IS_HEADLESS';

export const LOAD_LESSON_VIEW_DATA = 'LESSON_VIEW.LOAD_LESSON_VIEW_DATA';

export const SET_LESSON_COURSE_ID = 'LESSON_VIEW.SET_LESSON_COURSE_ID';
