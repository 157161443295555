// @ts-ignore
import { SUBJECTS_SLUGS } from '@yandex-int/k-common/constants';
import { SubjectConfigItem, SubjectLibraryTab } from '@yandex-int/k-common/typings';
import { getInitialSubjectFromConfig } from '@yandex-int/k-common/utils/bunker-nodes/get-initial-subject-from-config';
import { getSubjectConfig } from 'platform-components/src/components/app-factory/selectors';
import { createSelector } from 'reselect';

import { getGradeFromProps, getSubjectFromProps } from 'utils/selectors/selectors';

export const getInitialSubject = createSelector([getSubjectConfig], (subjectConfig): string =>
    getInitialSubjectFromConfig(subjectConfig)
);

export const getSubjectConfigList = createSelector(
    [getSubjectConfig],
    (subjectConfig): Array<SubjectConfigItem> => subjectConfig?.list || []
);

export const getSubjectsWithStacks = createSelector(
    [getSubjectConfigList],
    (configList): Array<SUBJECTS_SLUGS> => configList.filter((item) => item.has_stacks).map(({ subject }) => subject)
);

export const getSubjectsWithGradeDifference = createSelector(
    [getSubjectConfigList],
    (configList): Array<SUBJECTS_SLUGS> =>
        configList.filter((item) => item.has_grade_difference).map(({ subject }) => subject)
);

export const getHasGradeDifference = createSelector(
    [getSubjectConfigList, getSubjectFromProps],
    (configList: Array<SubjectConfigItem>, currentSubject: string): boolean => {
        const subjectConfig = configList.filter(({ subject }) => subject === currentSubject);
        return (subjectConfig[0] || {}).has_grade_difference;
    }
);

export const getPreselectedSubjectsByGrade = createSelector(
    [getSubjectConfigList, getGradeFromProps],
    (configList, grade): Array<SUBJECTS_SLUGS> => {
        if (!grade) {
            return [];
        }

        return configList.filter((item) => item.grades_to_preselect?.includes(grade)).map(({ subject }) => subject);
    }
);

export const getSubjectLibraryConfig = createSelector(
    [getSubjectConfigList, getSubjectFromProps],
    (configList, subject): SubjectConfigItem | null => configList.find((item) => item.subject === subject) || null
);

export const getSubjectLibraryTabs = createSelector(
    [getSubjectLibraryConfig, getGradeFromProps],
    (config, grade): SubjectLibraryTab[] => {
        return config?.tabs_config?.find((item) => grade && item.grades.includes(grade))?.list || [];
    }
);
