// @ts-ignore
import { COURSES_MODES } from '@yandex-int/k-common/constants';
import { Course, Me } from '@yandex-int/k-common/typings';
import { getSpecCourse } from '@yandex-int/k-common/utils/get-spec-courses';
import isNil from 'lodash/isNil';
import { parse } from 'query-string';

export const getCurrentYearCourseUtil = (courses: Array<Course>): Course | null => {
    if (!Array.isArray(courses) || courses.length === 0) {
        return null;
    }

    return courses.find((course) => course.closed === false && course.mode !== COURSES_MODES.OLYMP) || null;
};

export const getCoursesBySubjectUtil = (courses: Array<Course>, subject: string): Array<Course> => {
    return courses.filter((course: Course) => course.subject === subject && !getSpecCourse(course));
};

export function getCourseIdFromSearchString(me: Me, searchString?: string): number | null {
    const windowSearch = !isSSR ? window.location.search : null;
    const search = searchString || windowSearch;

    if (!me.is_content_manager || !search) {
        return null;
    }
    const searchCourseId = parse(search).course;

    return isNil(searchCourseId) ? null : Number(searchCourseId);
}

export function getSearchStringCourseIdOrDefault(me: Me, defaultCourseId: number | null): number | null {
    const searchCourseId = getCourseIdFromSearchString(me);

    if (!isNil(searchCourseId)) {
        return searchCourseId;
    }

    return defaultCourseId || null;
}

export function getCurrentYearOrSearchStringCourseId(me: Me, courses: Array<Course>): number | null {
    return getSearchStringCourseIdOrDefault(me, getCurrentYearCourseUtil(courses)?.id || null);
}
