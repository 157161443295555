import React from 'react';

import { KEYBOARD_TYPES } from '../../components/constants';
import KeyboardContext from '../../components/keyboard/keyboard-context';

/**
 * TODO: удалить, использовать contextType API для получения контекста.
 *
 * HOC для получения KeyboardContext.
 *
 * Добавлен для InlineInputTypeChoice, где не удавалось использовать injectIntl и contextType вместе
 * Здесь пишут, что обновление babel может решить проблему https://github.com/formatjs/react-intl/issues/1212
 */
function withKeyboardContext(WrappedComponent) {
    class WithKeyboardContext extends React.Component {
        static contextType = KeyboardContext;

        render() {
            return <WrappedComponent keyboardContext={this.context[KEYBOARD_TYPES.PRIMARY]} {...this.props} />;
        }
    }

    WithKeyboardContext.displayName = `withKeyboardContext(${WrappedComponent.displayName})`;

    return WithKeyboardContext;
}

export default withKeyboardContext;
