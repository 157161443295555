import { Theme } from '@yandex-lego/components/Theme';

import '../../build/hrLight/color.css';
import '../../build/theme_palettes.css';
import '../../build/hrLight/typo.css';
import '../../build/hrLight/root.css';
import '../legoOverrides.css';
import '../font.css';

export const theme: Theme = {
    color: 'hrLight',
    root: 'hrLight',
};
