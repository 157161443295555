import { getPlusSubscriptionType } from 'platform-components/src/components/plus-subscription/selectors';
import { PLUS_SUBSCRIPTION_TYPES } from 'platform-components/src/components/plus-subscription/typings';
import { UserPic } from 'platform-components/src/components/user-pic/user-pic';
import { getMe } from 'platform-components/src/components/user/selectors';
import React from 'react';
import { useSelector } from 'react-redux';

interface Props {
    mix?: string;
}

export const UserIcon = ({ mix }: Props) => {
    const me = useSelector(getMe);
    const avatarId = me?.avatar || undefined;
    const subscriptionType = useSelector(getPlusSubscriptionType);
    const hasPlus = subscriptionType === PLUS_SUBSCRIPTION_TYPES.PLUS;

    return <UserPic avatarId={avatarId} className={mix} plus={hasPlus} size={'m'} />;
};
