import { withBemMod } from '@bem-react/core';
import { cnLink } from '@yandex-lego/components/Link/desktop';

import './Link_theme_text.css';

export type WithThemeTextProps = {
    theme?: 'text';
};

export const withThemeText = withBemMod<WithThemeTextProps>(cnLink(), {
    theme: 'text',
});
