import React, { useContext } from 'react';

import { SCREEN_RESOLUTIONS } from './constants';

interface ImageContext {
    resolution?: SCREEN_RESOLUTIONS;
}

export const imageContext = React.createContext<ImageContext>({});

export const ImageContextProvider = imageContext.Provider;

export const useImageResolution = () => useContext(imageContext);
