import { matchPath, match as Match } from 'react-router-dom';

export function getExactPathMatch(
    urls: Record<string, string>,
    pathname: string
): Match<Record<string, string>> | null {
    for (const path of Object.values(urls)) {
        const match = matchPath(pathname, { path, exact: true });
        if (match) {
            return match;
        }
    }
    return null;
}
