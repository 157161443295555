import { SpecProjectConfigItem } from '@yandex-int/k-common/typings';
import { findConfigByProject } from '@yandex-int/k-common/utils/get-redirect-url-by-project';
import { getSpecProjectConfig } from 'platform-components/src/components/app-factory/selectors';
import {
    getHints,
    // @ts-ignore
} from 'platform-components/src/components/user/selectors';
import { createSelector } from 'reselect';

import { getSourceProject } from 'utils/get-data-from-search-parameters';
import { getSpecProjectSlugFromProps } from 'utils/selectors/selectors';

import { SPEC_PROJECTS_DATA_HINT, SPEC_PROJECT_SLUGS } from './constants';

export const getSpecProjectData = createSelector(
    [getHints],
    (hints: Record<string, any>): Record<SPEC_PROJECT_SLUGS, any> => {
        return hints?.[SPEC_PROJECTS_DATA_HINT] || {};
    }
);

export const getSpecProjectConfigBySlug = createSelector(
    [getSpecProjectConfig, getSpecProjectSlugFromProps],
    (config, specProjectSlug): SpecProjectConfigItem | null => {
        return config?.list[specProjectSlug] || null;
    }
);

export const getSpecProjectConfigBySourceProject = createSelector(
    [getSpecProjectConfig],
    (config): SpecProjectConfigItem | null => {
        const fromUrl = getSourceProject() as SPEC_PROJECT_SLUGS;

        const specProjectConfigItem = config ? findConfigByProject(config, fromUrl) : null;

        return specProjectConfigItem || null;
    }
);

export const getIsParentRegistrationAvailable = createSelector(getSpecProjectConfig, (config): boolean =>
    Boolean(config?.isParentRegistrationAvailable)
);
