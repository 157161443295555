import { Me } from '@yandex-int/k-common/typings';
import { Text, Popup, useIntl } from 'platform-components/common';
import { POPUP_DIRECTIONS, POPUP_THEMES } from 'platform-components/constants';
import { withUatraits, WithUatraitsProps } from 'platform-components/hocs';
import React, { useEffect, useState } from 'react';
import ym from 'react-yandex-metrika';

//@ts-ignore
import FormView, { SUGGEST_TYPES, OPTION_TYPE } from 'common.components/form-view/form-view';
import { School } from 'common.components/typings';
import { getSourceProject } from 'utils/get-data-from-search-parameters';
import { YM_LOCATION } from 'utils/ym-constants';

import SchoolAddingControls from '../__school-adding-controls/school-form__school-adding-controls';
import * as actions from '../actions';
import { MODES } from '../school-form.constants';

import './school-form__school-section.scss';

const SCHOOL_NOT_FOUND_ID = -1;

interface Props extends WithUatraitsProps {
    currentSchool: School | null;
    isCurrentAddressSet: boolean;
    isCurrentSchoolSet: boolean;
    isLoading: boolean;
    me: Me | null;
    mode: MODES;
    schools: Array<School>;
    getSchoolSuggests: typeof actions.getSchoolSuggests;
    setCurrentSchool: typeof actions.setCurrentSchool;
    setMode: typeof actions.setMode;
    setSchools: typeof actions.setSchools;
    canAddOwnSchool: boolean;
    onPopupTargetRef?: (ref: HTMLDivElement | null) => void;
    location?: YM_LOCATION;
}

const SchoolSection = (props: Props) => {
    const [errorMsg, setErrorMsg] = useState<string | null>(null);
    const [isEmpty, setIsEmpty] = useState(true);
    const [schoolValue, setSchoolValue] = useState<string | null>(null);
    const [isPopupShown, setPopupShown] = useState(false);
    const intl = useIntl();
    const {
        mode,
        isLoading,
        schools,
        isCurrentSchoolSet,
        isCurrentAddressSet,
        me,
        canAddOwnSchool,
        currentSchool,
        onPopupTargetRef,
        uatraits: { isMobile },
        setMode,
        setCurrentSchool,
        getSchoolSuggests,
        location,
    } = props;

    useEffect(
        function onLoadingChanged() {
            if (schoolValue && !isLoading) {
                if (!schools.length) {
                    setErrorMsg(intl.formatMessage({ id: 'schoolForm.schoolIsNotFound' }));
                } else if (errorMsg) {
                    setErrorMsg(null);
                }
            }
        },
        [schools, isLoading, schoolValue, intl, setErrorMsg, errorMsg]
    );

    useEffect(
        function onModeChange() {
            setErrorMsg(null);
            setSchoolValue(null);
        },
        [mode]
    );

    const handleSchoolSuggestChange = (school: School) => {
        if (school.id === SCHOOL_NOT_FOUND_ID) {
            (school as School & { onClick: () => void }).onClick();
        } else {
            setErrorMsg(null);
            setSchoolValue(school.title);

            setCurrentSchool(school);
        }
    };

    const handleSchoolInfoChange = (schoolInfo: string, isValid: boolean) => {
        setSchoolValue(schoolInfo);
        setCurrentSchool({ id: null, title: isValid ? schoolInfo : '' } as School);
    };

    const handleSchoolInputChange = (value: string) => {
        const val = value.trim();

        if (!val) {
            setIsEmpty(true);
            setErrorMsg(null);
            setSchoolValue(null);
        } else {
            setSchoolValue(val);
            setIsEmpty(false);
        }

        getSchoolSuggests(val);
    };

    const validateSchool = () => {
        if (!isCurrentSchoolSet) {
            if (!schoolValue || schoolValue.length < 1) {
                return intl.formatMessage({ id: 'schoolForm.schoolIsEmpty' });
            }

            return intl.formatMessage({ id: 'schoolForm.schoolIsNotFound' });
        }

        return null;
    };

    const handleOnSuggestBlur = () => {
        setErrorMsg(validateSchool());
    };

    const handleSchoolsMouseEnter = () => {
        if (isCurrentAddressSet) {
            return;
        }
        setPopupShown(true);
    };

    const handleSchoolsMouseLeave = () => {
        setPopupShown(false);
    };

    const handleSchoolInputFocus = () => {
        if (!schoolValue) {
            setErrorMsg(null);

            getSchoolSuggests('');
        }
    };

    const handleSelectSchoolNotFound = () => {
        ym('reachGoal', 'teacher_onboarding_new_school', {
            id: me?.id,
            from_project: getSourceProject(),
            location,
        });

        setMode(MODES.NOT_FOUND);
    };

    const isModeNotFound = mode === MODES.NOT_FOUND;
    const schoolNotFoundOption = {
        id: SCHOOL_NOT_FOUND_ID,
        onClick: handleSelectSchoolNotFound,
        title: intl.formatMessage({ id: 'schoolForm.schoolNotFoundOption' }),
        type: OPTION_TYPE.ACTION,
    };
    const schoolInfoOption = {
        id: 'info',
        title: intl.formatMessage({ id: 'schoolForm.typeSchoolNumberOption' }),
        type: OPTION_TYPE.INFO,
    };

    let schoolsWithFirstOption;

    if (canAddOwnSchool) {
        schoolsWithFirstOption = [isEmpty ? schoolInfoOption : schoolNotFoundOption, ...schools];
    } else {
        schoolsWithFirstOption = isEmpty ? [schoolInfoOption, ...schools] : schools;
    }

    const hint = <Text id="schoolForm.address.schoolExample" size={Text.SIZE.S} />;

    return (
        <div className="school-form__school-section-wrapper">
            <FormView.CardSection mix="school-form__school-section" secondary>
                <div ref={onPopupTargetRef}>
                    {isModeNotFound ? (
                        <SchoolAddingControls
                            currentSchool={currentSchool ? currentSchool.title : ''}
                            disabled={!isCurrentAddressSet}
                            onChange={handleSchoolInfoChange}
                            onMouseEnter={handleSchoolsMouseEnter}
                            onMouseLeave={handleSchoolsMouseLeave}
                        />
                    ) : (
                        <FormView.Suggest
                            disabled={!isCurrentAddressSet}
                            errorMessage={errorMsg}
                            hint={hint}
                            labelKey="title"
                            onBlur={handleOnSuggestBlur}
                            onChange={handleSchoolSuggestChange}
                            onFocus={handleSchoolInputFocus}
                            onInputChange={handleSchoolInputChange}
                            onMouseEnter={handleSchoolsMouseEnter}
                            onMouseLeave={handleSchoolsMouseLeave}
                            options={isLoading ? [] : schoolsWithFirstOption}
                            placeholder={intl.formatMessage({ id: 'schoolForm.inputSchoolPlaceholder' })}
                            scrollOnOpen={isMobile}
                            type={SUGGEST_TYPES.SELECT}
                            value={currentSchool && currentSchool.id}
                            valueKey="id"
                        />
                    )}
                </div>
            </FormView.CardSection>
            <Popup
                direction={POPUP_DIRECTIONS.BOTTOM}
                hasArrow
                mix="school-form__school-popup"
                offsetLeft={151}
                offsetTop={73}
                shown={isPopupShown}
                theme={POPUP_THEMES.DARK}
                width="100%"
            >
                <Text id="schoolForm.schoolPopup" size={Text.SIZE.XL} />
            </Popup>
        </div>
    );
};

const wrapped = withUatraits(SchoolSection);

wrapped.displayName = 'SchoolSection';

export default wrapped;
