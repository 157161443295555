import React from 'react';

const SvgVk = (props) => (
    <svg fill="#201E1E" height={20} width={20} {...props}>
        <path d="M18 0H2a2 2 0 00-2 2v16a2 2 0 002 2h16a2 2 0 002-2V2a2 2 0 00-2-2z" />
        <path
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M10.542 13.534c.075 0 .15-.002.221-.022a.547.547 0 00.255-.14c.108-.118.11-.337.11-.354v-.002c-.001-.024-.01-1.088.48-1.246.49-.158 1.117 1.05 1.783 1.515.292.203.543.263.702.278.123.01.246-.004.37-.006l1.547-.022a.816.816 0 00.096-.006c.185-.024.822-.157.442-.798-.036-.06-.257-.55-1.324-1.557-1.117-1.054-.967-.883.378-2.706.819-1.11 1.146-1.787 1.044-2.077-.07-.2-.407-.217-.586-.211-.076.002-.152.008-.228.008l-1.746.011c-.097 0-.194-.011-.289.006a.339.339 0 00-.115.042.478.478 0 00-.143.157.78.78 0 00-.058.127c-.083.213-.363.913-.716 1.523-.893 1.542-1.25 1.623-1.396 1.527-.34-.223-.254-.896-.254-1.374 0-1.493.223-2.116-.434-2.277-.218-.054-.379-.09-.936-.095-.715-.007-1.32.002-1.664.173-.228.114-.404.367-.296.381.132.018.432.082.591.303.162.223.192.664.197.844l.006.156c.017.343.064 1.708-.28 1.898-.27.15-.641-.156-1.437-1.554a13.082 13.082 0 01-.712-1.498l-.008-.02a.59.59 0 00-.161-.217.635.635 0 00-.141-.077.89.89 0 00-.341-.048l-1.709.011a.69.69 0 00-.044.002c-.07.004-.282.028-.369.133-.07.083-.04.232-.02.304a.633.633 0 00.03.08c.168.395 1.579 3.62 3.163 5.296 1.38 1.461 2.926 1.535 3.247 1.534l.121-.002h.624z"
            fill="#fff"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgVk;
