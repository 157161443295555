import { CSSProperties, useMemo } from 'react';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { TEXT_TAG, TEXT_MARKUP_TYPE } from '../constants';
import MarkdownExtXContent from '../markdown/_extension/markdown_extension_xcontent';
import TextMarkup from '../text-markup/text-markup';

const DEFAULT_MARKDOWN_TAG = 'div';

export interface SimpleTextProps {
    children?: React.ReactNode;
    className?: string;
    id?: string;
    style?: CSSProperties;
    tagName?: TEXT_TAG;
    title?: string;
    type?: TEXT_MARKUP_TYPE;
    values?: React.ComponentProps<typeof FormattedMessage>['values'];
}

export const SimpleText = ({
    children,
    className,
    id,
    style,
    tagName = TEXT_TAG.SPAN,
    type,
    values,
    title,
    ...props
}: SimpleTextProps) => {
    const isMarkdown = type === TEXT_MARKUP_TYPE.MARKDOWN;
    const elementTag = isMarkdown ? DEFAULT_MARKDOWN_TAG : tagName;

    const content: React.ReactNode = useMemo(() => {
        let childContent = children;

        if (childContent && isMarkdown) {
            childContent = <MarkdownExtXContent>{childContent as string}</MarkdownExtXContent>;
        }

        return id ? <TextMarkup id={id} type={type} values={values} {...props} /> : childContent;
    }, [children, isMarkdown, id, type, values, props]);

    return React.createElement(elementTag || DEFAULT_MARKDOWN_TAG, { id, className, title, style }, content);
};

SimpleText.TAG = TEXT_TAG;
SimpleText.TYPE = TEXT_MARKUP_TYPE;
