import b from 'b_';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { convertRationalToKatex, validateRational } from '../../../utils/math';
import { POPUP_AXIS, POPUP_DIRECTIONS } from '../../constants';
import Formula from '../../formula/formula';
import { IconQuestionCircle } from '../../icon/icon';
import Popup from '../../popup/popup';
import { LETTER_WIDTH } from '../constants';

import './inline-input_type_rational.scss';

const SPECIAL_KEY_CODES = [8, 9, 13, 37, 38, 39, 40, 46];
const RE_INPUT_FILTER = /[^\d.,/ -]/;

class InlineInputTypeRational extends React.Component {
    static propTypes = {
        answer: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
        className: PropTypes.string,
        forceFocus: PropTypes.bool,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        intl: PropTypes.object.isRequired,
        onKeyDown: PropTypes.func.isRequired,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
        options: PropTypes.object,
        readOnly: PropTypes.bool,
        setAnswer: PropTypes.func.isRequired,
        showCorrectAnswer: PropTypes.bool,
        theme: PropTypes.string,
        userAnswer: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    };

    constructor(props) {
        super(props);

        this.state = {
            showPreview: false,
        };

        this.inputComponent = null;
    }

    componentDidMount() {
        const { forceFocus } = this.props;

        if (forceFocus) {
            this.focus();
        }

        if (this.writingRulesPopup && this.writingRulesIcon) {
            this.writingRulesPopup.setOwner(this.writingRulesIcon);
        }
    }

    componentDidUpdate(prevProps) {
        const { forceFocus } = this.props;
        const { forceFocus: oldForceFocus } = prevProps;

        if (forceFocus && forceFocus !== oldForceFocus) {
            this.focus();
        }

        if (this.writingRulesIconChanged && this.writingRulesPopup && this.writingRulesIcon) {
            this.writingRulesIconChanged = false;
            this.writingRulesPopup.setOwner(this.writingRulesIcon);
        }
    }

    formatAnswer = (answer) => {
        return Array.isArray(answer) ? answer[0] : answer;
    };

    getInputValue = ({ answer, userAnswer, showCorrectAnswer }) => {
        const formattedAnswer = this.formatAnswer(answer);

        return showCorrectAnswer ? formattedAnswer : userAnswer || null;
    };

    focus() {
        this.inputComponent.focus();
    }

    onKeyDown = (e) => {
        const { onKeyDown } = this.props;
        const { keyCode, key } = e;
        const isCtrl = e.ctrlKey || e.metaKey;

        if (SPECIAL_KEY_CODES.includes(keyCode)) {
            onKeyDown(e);
        } else if (!isCtrl && key.match(RE_INPUT_FILTER)) {
            e.preventDefault();
        }
    };

    onChange = ({ target: { value } }) => {
        value = value || null;
        this.props.setAnswer(value);
    };

    onFocus = () => {
        const { readOnly, onFocus: callback } = this.props;

        if (!readOnly) {
            this.setState({ showPreview: true });
        }

        if (callback) {
            callback();
        }
    };

    onBlur = () => {
        const { readOnly, onBlur: callback } = this.props;

        if (!readOnly) {
            this.setState({ showPreview: false });
        }

        if (callback) {
            callback();
        }
    };

    refInput = (inputComponent) => {
        this.inputComponent = inputComponent;
    };

    refWritingRulesIconCb = (ref) => {
        this.writingRulesIconChanged = this.writingRulesIcon !== ref;
        this.writingRulesIcon = ref;
    };

    refWritingRulesPopupCb = (ref) => {
        if (this.writingRulesPopup && !ref) {
            this.writingRulesPopup.setOwner(null);
        }

        this.writingRulesPopup = ref;
    };

    showWritingRules = () => {
        if (this.writingRulesPopup) {
            this.writingRulesPopup.show();
        }
    };

    hideWritingRules = () => {
        if (this.writingRulesPopup) {
            this.writingRulesPopup.hide();
        }
    };

    render() {
        const { className, intl, options, readOnly, theme } = this.props;
        const { showPreview } = this.state;
        let value = this.getInputValue(this.props);
        value = String(value === 0 ? 0 : value || '');

        const isEmpty = value.trim() === '';
        const isWrong = !validateRational(value);
        const showPreviewMessage = isEmpty || isWrong;

        const valueInKatex = !isWrong ? convertRationalToKatex(value) : null;

        const style = options && options.width ? { width: options.width * LETTER_WIDTH + 'em' } : undefined;
        const controlClass = b('inline-input', {
            center: options.width > 0 && options.width <= 3,
            wrong: isWrong && theme === 'normal',
        });

        return (
            <span className={cx(className, controlClass)}>
                <div className="inline-input__instance">
                    <div className="inline-input__input-wrap">
                        <input
                            autoComplete="off"
                            className="inline-input__input"
                            data-switchable="true"
                            onBlur={this.onBlur}
                            onChange={this.onChange}
                            onFocus={this.onFocus}
                            onKeyDown={this.onKeyDown}
                            placeholder={intl.formatMessage({ id: 'inlineInput.rational.placeholder' })}
                            readOnly={readOnly}
                            ref={this.refInput}
                            style={style}
                            tabIndex="0"
                            value={value}
                        />
                        {showPreview && (
                            <div className="inline-input__preview">
                                {showPreviewMessage ? (
                                    <span className={b('inline-input', 'preview-message', { wrong: isWrong })}>
                                        {isEmpty && <FormattedMessage id="inlineInput.rational.dataIsEmpty" />}
                                        {isWrong && <FormattedMessage id="inlineInput.rational.dataIsWrong" />}
                                    </span>
                                ) : (
                                    <Formula code={valueInKatex} />
                                )}
                            </div>
                        )}
                    </div>
                    <div className="inline-input__controls">
                        {(isEmpty || showPreview) && (
                            <div className="inline-input__writing-rules">
                                <div
                                    className="inline-input__icon-wrap"
                                    onMouseEnter={this.showWritingRules}
                                    onMouseLeave={this.hideWritingRules}
                                    ref={this.refWritingRulesIconCb}
                                >
                                    <IconQuestionCircle />
                                </div>
                                <Popup
                                    autoclosable
                                    axis={POPUP_AXIS.START}
                                    direction={POPUP_DIRECTIONS.TOP}
                                    hasArrow
                                    mix="inline-input__hint"
                                    offsetLeft={-4}
                                    offsetTop={-24}
                                    ref={this.refWritingRulesPopupCb}
                                    width="300px"
                                >
                                    <FormattedMessage id="inlineInput.rational.writingRules" />
                                </Popup>
                            </div>
                        )}
                    </div>
                </div>
            </span>
        );
    }
}

export default injectIntl(InlineInputTypeRational, { withRef: true });
