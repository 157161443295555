import { postFetch } from '../fetch';
import { apiConfig } from '../config';

import { PlusSubscriptionInfo } from '../../../typings/api/models/plus-subscription';

export async function getPlusSubscriptionStatus(): Promise<PlusSubscriptionInfo> {
    const { apiPostPlusTrial } = apiConfig.getStaticUrls();
    
    return postFetch(apiPostPlusTrial);
}
